import React, { useState } from 'react';
import css from './InfoPromptSlider.css';
import Slider from 'react-slick';

const InfoPromptSlider = props => {
  const { showAsPopup, itemsList, listing, currentAuthor, ...rest } = props;
  const [activeSlide, setActiveSlide] = useState(1);
  const host =
    currentAuthor &&
    currentAuthor.attributes &&
    currentAuthor.attributes.profile &&
    currentAuthor.attributes.profile.displayName;

  const numberOfDrives =
    listing &&
    listing.attributes &&
    listing.attributes.publicData &&
    listing.attributes.publicData.numberTripDone;
  const sliderItems = itemsList || [
    {
      itemText: `You will not be charged till ${host} accepts your request. You can withdraw anytime before that.`,
    },
  ];
  if (numberOfDrives && numberOfDrives.length <= 1) {
  // if (listing) {
    const slide = {
      itemText:
        'You are eligible for full refund. Go ahead and make the booking, cancel anytime later.',
    };
    sliderItems.push(slide);
  }

  function CustomArrowNext(props) {
    const { style, onClick } = props;
    return (
      <div className={css.sliderNext} style={{ ...style, display: 'block' }} onClick={onClick}>
        <svg width="14" height="9" viewBox="0 0 14 9" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6.53 7.472c.26.26.68.26.94 0l5.335-5.333c.26-.263.26-.684 0-.944-.26-.26-.683-.26-.943 0L7 6.056l-4.862-4.86c-.26-.26-.683-.26-.943 0-.26.26-.26.68 0 .943L6.53 7.47z"
            stroke="##000000"
            fill="##000000"
            fill-rule="evenodd"
          />
        </svg>
      </div>
    );
  }

  function CustomArrowPrev(props) {
    const { style, onClick } = props;
    return (
      <div className={css.sliderPrev} style={{ ...style, display: 'block' }} onClick={onClick}>
        <svg width="14" height="9" viewBox="0 0 14 9" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6.53 7.472c.26.26.68.26.94 0l5.335-5.333c.26-.263.26-.684 0-.944-.26-.26-.683-.26-.943 0L7 6.056l-4.862-4.86c-.26-.26-.683-.26-.943 0-.26.26-.26.68 0 .943L6.53 7.47z"
            stroke="##000000"
            fill="##000000"
            fill-rule="evenodd"
          />
        </svg>
      </div>
    );
  }

  const sliderConfig = {
    autoplay: true,
    dots: false,
    arrows: true,
    infinite: true,
    autoplaySpeed: 5000,
    afterChange: current => setActiveSlide(current + 1),
    nextArrow: <CustomArrowNext />,
    prevArrow: <CustomArrowPrev />,
  };
  return (
    <div className={css.root}>
      <div className={css.sliderWrapper}>
        <div className={css.sliderRight}>
          <Slider {...sliderConfig}>
            {sliderItems &&
              sliderItems.length > 0 &&
              sliderItems.map(item => {
                return <div className={css.infoItem}>{item.itemText}</div>;
              })}
          </Slider>
        </div>
      </div>
    </div>
  );
};
export default InfoPromptSlider;
