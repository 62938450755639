import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { LinkTabNavHorizontal } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';

import css from './InboxNav.css';

const InboxNav = props => {
  const { className, rootClassName, selectedPageName } = props;
  const classes = classNames(rootClassName || css.root, className);

  const tabs = [
    {
      text: <FormattedMessage id="InboxNav.normalTrips" />,
      selected: selectedPageName === 'InboxBasePage',
      linkProps: {
        name: 'InboxBasePage',
      },
    },
    {
      text: <FormattedMessage id="InboxNav.longTermTrips" />,
      selected: selectedPageName === 'InboxLongTermBasePage',
      disabled: false,
      linkProps: {
        name: 'InboxLongTermBasePage',
      },
    },
  ];

  return (
    <LinkTabNavHorizontal className={classes} tabRootClassName={css.tab} tabs={tabs} skin="dark" />
  );
};

InboxNav.defaultProps = {
  className: null,
  rootClassName: null,
};

const { string } = PropTypes;

InboxNav.propTypes = {
  className: string,
  rootClassName: string,
  selectedPageName: string.isRequired,
};

export default InboxNav;
