import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import config from '../../config';

import insurance from '../../assets/landingpage/insurance.png';
import landTransport from '../../assets/landingpage/landTransport.jpeg';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
} from '../../components';
import { EVENT_BROWSE_VISITED_LANDING_PAGE } from '../../util/gtm/gtmConstants';
import { initiateEventViewedFromLandingPage } from '../../util/gtm/gtmHelpers';
import { checkSignUpCode } from '../../util/referralProgram';
import { types } from '../../util/sdkLoader';
import { parse } from '../../util/urlHelpers';
import SectionCars from '../LandingPage/SectionCars';
import SectionHelp from '../LandingPage/SectionHelp';
import SectionStories from '../LandingPage/SectionStories';
import { fetchFeaturedCar } from '../SearchPage/SearchPage.duck';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import css from './GuestPromoGroundPlan.css';
import SectionChannels from './SectionChannels';
import SectionHero from './SectionHero';

const { Money } = types;

const SignUpPromoLandingPage = ({ scrollingDisabled, location, props }) => {
  const intl = useIntl();
  const isScoot = location.pathname === '/scoot-drivelah-signup';
  const [signUpCodeAmount, setSignUpCodeAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const siteTitle = config.siteTitle;
  const schemaTitle = "Get up to 50% off on your first drive | Drive lah";
  const description = "Rent a car on Drive lah now"
  useEffect(() => {
    const { code } = parse(location.search);
    initiateEventViewedFromLandingPage({
      props,
      location,
      event: EVENT_BROWSE_VISITED_LANDING_PAGE,
    });
    if (code) {
      setLoading(true);
      checkSignUpCode(code).then(res => {
        if (res.valid) {
          setSignUpCodeAmount(res.credits);
        }
        setLoading(false);
      });
    }
  }, []);

  return (
    <Page
      title={schemaTitle}
      description={description}
      scrollingDisabled={scrollingDisabled}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        name: schemaTitle,
        description: description,
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <SectionHero isScoot={isScoot} signUpCodeAmount={signUpCodeAmount} loading={loading} />
          <div className={css.sectionProvider}>
            <div>
              <div className={css.providerTitle}>Insurance provided by</div>
              <img src={insurance} className={css.insuranceImage} />
            </div>
            <div>
              <div className={css.providerTitle}>Approved by</div>
              <img src={landTransport} className={css.landTransport} />
            </div>
          </div>
          <div className={css.desktopChannels}>
            <SectionChannels />
          </div>
          <div className={css.sectionCars}>
            <SectionCars />
          </div>
          <div className={css.mobileChannels}>
            <SectionChannels />
          </div>
          <div className={css.sectionStories}>
            <SectionStories />
          </div>
          <SectionHelp />
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

SignUpPromoLandingPage.loadData = () => {
  return fetchFeaturedCar();
};

export default SignUpPromoLandingPage;
