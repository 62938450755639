import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { compose } from 'redux';
import { FieldUploadFile, Form } from '../../components';
import { ensureCurrentUser } from '../../util/data';
import { injectIntl, intlShape } from '../../util/reactIntl';

import css from './DrivingLicenseForm.css';

class DrivingLicenseFormComponent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      currentUser
    } = this.props;

    const initialUrls = {
      drivingLicenseFrontUrl: !currentUser ? null : currentUser.attributes.profile.protectedData.drivingLicenseFront,
      drivingLicenseBackUrl: !currentUser ? null : currentUser.attributes.profile.protectedData.drivingLicenseBack,
      drivingLicenseStatusUrl: !currentUser ? null : currentUser.attributes.profile.protectedData.drivingLicenseStatus,
      internationalDrivingLicenseFrontUrl: !currentUser ? null : currentUser.attributes.profile.protectedData.internationalDrivingLicenseFront,
      internationalDrivingLicenseBackUrl: !currentUser ? null : currentUser.attributes.profile.protectedData.internationalDrivingLicenseBack,
    }

    return (
      <FinalForm
        {...this.props}
        render={fieldRenderProps => {
          const {
            rootClassName,
            className,
            currentUser,
            handleSubmit,
            formId,
            intl,
            values,
            isFromTripPage
          } = fieldRenderProps;

          const user = ensureCurrentUser(currentUser);

          if (!user.id) {
            return null;
          }

          const classes = classNames(
            rootClassName || css.root,
            className,
            isFromTripPage ? css.tripPageForm : ''
          );


          return (
            <Form
              className={classes}
              onSubmit={e => {

              }}
            >
              <FieldUploadFile
                id='file-upload-front-driving-license'
                name='file-upload-front-driving-license'
                typeFile='front-driving-license'
                id2='file-upload-back-driving-license'
                name2='file-upload-back-driving-license'
                typeFile2='back-driving-license'
                label={() => 'Driving license (SG only)'}
                twoColumns={true}
                currentUser={currentUser}
                initialValues={[
                  initialUrls.drivingLicenseFrontUrl,
                  initialUrls.drivingLicenseBackUrl
                ]}
                previewFileClassName={css.previewFile}
                hideUpload={true}
              />
              {/* <FieldUploadFile
                id='file-upload-status-driving-license'
                name='file-upload-status-driving-license'
                label={() => 'Driving license status (only applicable if SG based Driving license)'}
                typeFile='status-driving-license'
                currentUser={currentUser}
                twoColumns={true}
                initialValues={[
                  initialUrls.drivingLicenseStatusUrl
                ]}
                previewFileClassName={css.previewFile}
                hideUpload={true}
              /> */}
              <FieldUploadFile
                id='file-upload-international-front-driving-license'
                name='file-upload-international-front-driving-license'
                typeFile='international-font-driving-license'
                id2='file-upload-international-back-driving-license'
                name2='file-upload-international-back-driving-license'
                typeFile2='international-back-driving-license'
                label={() => 'International driving license copy (only applicable for foreigners)'}
                twoColumns={true}
                currentUser={currentUser}
                initialValues={[
                  initialUrls.internationalDrivingLicenseFrontUrl,
                  initialUrls.internationalDrivingLicenseBackUrl,
                ]}
                previewFileClassName={css.previewFile}
                hideUpload={true}
              />
            </Form>
          );
        }}
      />
    );
  }
}

DrivingLicenseFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  formId: null,
};

const { bool, func, string } = PropTypes;

DrivingLicenseFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,
  intl: intlShape.isRequired,
};

const DrivingLicenseForm = compose(injectIntl)(DrivingLicenseFormComponent);

DrivingLicenseForm.displayName = 'DrivingLicenseForm';

export default DrivingLicenseForm;
