import React from 'react';
import css from './MasterCardLandingPage.css';

import drivelahMasterCardLogo from '../../assets/masterCardLandingPage/DrivelahMasterCardLogo.png';
import { NamedLink } from '../../components';

export const SectionOffers = ({ intl }) => {
  return (
    <div className={css.sectionOffers}>
      <div className={css.sectionOffersHeading}>
        <img
          className={css.drivelahMasterCardLogo}
          src={drivelahMasterCardLogo}
          alt="Drivelah | Master Card"
        />
      </div>
      <div className={css.sectionOffersContent}>
        <div className={css.left}>
          <div className={css.sectionOffersTitle}>
            {intl.formatMessage({ id: 'MasterCardLandingPage.SectionOffers.title' })}
          </div>
          <div className={css.sectionOffersDescription}>
            {intl.formatMessage({ id: 'MasterCardLandingPage.SectionOffers.description' })}
          </div>
        </div>
        <NamedLink className={css.joinDriveLahMobile} name="SignupPage">
          Join Drive Lah!
        </NamedLink>

        <div className={css.right}>
          <div className={css.offers}>
            <div className={css.offer}>
              <div className={css.offerIndex}>
                {intl.formatMessage({
                  id: 'MasterCardLandingPage.SectionOffers.offer1',
                })}
              </div>
              <div className={css.offerInfo}>
                <div className={css.offerTitle}>
                  {intl.formatMessage({
                    id: 'MasterCardLandingPage.SectionOffers.offer1.title',
                  })}
                </div>
                <div className={css.offerDescription}>
                  {intl.formatMessage({
                    id: 'MasterCardLandingPage.SectionOffers.offer1.description',
                  })}
                </div>
              </div>
            </div>
            <div className={css.offer}>
              <div className={css.offerIndex}>
                {intl.formatMessage({
                  id: 'MasterCardLandingPage.SectionOffers.offer2',
                })}
              </div>
              <div className={css.offerInfo}>
                <div className={css.offerTitle}>
                  {intl.formatMessage({
                    id: 'MasterCardLandingPage.SectionOffers.offer2.title',
                  })}
                </div>
                <div className={css.offerDescription}>
                  {intl.formatMessage({
                    id: 'MasterCardLandingPage.SectionOffers.offer2.description',
                  })}
                </div>
              </div>
            </div>
          </div>

          <div className={css.youMayEnjoy}>
            <div className={css.youMayEnjoyHighlight}>
              {intl.formatMessage({
                id: 'MasterCardLandingPage.SectionOffers.youMayEnjoy.highlight',
              })}
            </div>
            <div className={css.youMayEnjoyExample}>
              {intl.formatMessage({
                id: 'MasterCardLandingPage.SectionOffers.youMayEnjoy.example',
              })}
            </div>
          </div>
          <div className={css.termOfConditionApply}>
            {intl.formatMessage({
              id: 'MasterCardLandingPage.SectionOffers.termOfConditionApply',
            })}
          </div>

        </div>
        <NamedLink className={css.joinDriveLah} name="SearchPage">
          Join Drive Lah!
        </NamedLink>
      </div>
    </div>
  );
};
