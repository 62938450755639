import PropTypes from 'prop-types';
import React from 'react';

const SearchIconNew = props => {
  return (
    <svg width="20.665" height="22" viewBox="0 0 20.665 22">
      <defs>
        <style></style>
        <clip-path id="a">
          <path className="a" fill="#026786" d="M0,0H20.665V22H0Z" />
        </clip-path>
      </defs>
      <path className="a" d="M20.665,25.5h0Z" transform="translate(0 -3.501)" />
      <g className="b" clip-path="url(#a)">
        <path
          className="a"
          fill="#026786"
          d="M19.64,17.263a7.151,7.151,0,0,0-4.989-3.481,5.806,5.806,0,0,0-3.4.792,7.071,7.071,0,0,1-1.269.5,6.921,6.921,0,1,1,3.839-11c.5.638,1.975,3.043.616,7.645a10.868,10.868,0,0,1,1.43.191c.515-1.888,1.247-5.945-.925-8.715A8.362,8.362,0,0,0,.062,7.36a8.331,8.331,0,0,0,10.232,9.109,10.065,10.065,0,0,0,2.555-1.015c1.441-.664,3.809-.137,5.6,2.59.89,1.356.885,2.288.686,2.464-.164.146-.738.051-1.477-.407A6.512,6.512,0,0,1,15,17.008a1.887,1.887,0,0,0-1.491-.147c.26,1.694,1.963,3.56,3.4,4.451a3.889,3.889,0,0,0,2,.688,1.717,1.717,0,0,0,1.175-.424c.921-.813.75-2.506-.437-4.314"
          transform="translate(0 0)"
        />
      </g>
    </svg>
  );
};

const { string } = PropTypes;

SearchIconNew.defaultProps = {
  className: null,
  rootClassName: null,
};

SearchIconNew.propTypes = {
  className: string,
  rootClassName: string,
};

export default SearchIconNew;
