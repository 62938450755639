export const BUCKETS = {
  signup: 'signup',
  ui: 'ui',
  guestverify: 'guestverify',
  hostverify: 'hostverify',
  search: 'search',
  browsing: 'browsing',
  listacar: 'listacar',
  book: 'book',
};

//Sign up properties
export const SIGNUP_MODE = 'signup_mode';
export const SIGNUP_ROLE = 'signup_role';
export const SIGNUP_USEDCODE = 'signup_code_used';
export const SIGNUP_CODE = 'signup_code';
export const SIGNUP_REFERRAL_CODE = 'referral_code';

//User properties
export const USER_ATTR_EMAIL = 'email';
export const USER_ATTR_FIRST_NAME = 'firstname';
export const USER_ATTR_LAST_NAME = 'lastname';
export const USER_ATTR_FULL_NAME = 'fullname';
export const USER_ATTR_STRIPE_ID = 'stripe_id';
export const USER_ATTR_FLEX_ID = 'flex_id';
export const USER_ATTR_HOST_STATUS = 'hoststatus';
export const USER_ATTR_GUEST_STATUS = 'gueststatus';
export const USER_ATTR_AGE = 'age';
export const USER_ATTR_ADDRESS = 'address';
export const USER_ATTR_POSTAL_CODE = 'postalcode';
export const USER_ATTR_CITY = 'city';
export const USER_ATTR_COUNTRY = 'country';
export const USER_ATTR_DOB = 'dateofbirth';
export const USER_ATTR_PHOTO = 'photo';
export const USER_ATTR_PHONE = 'phone';
export const USER_ATTR_PHONE_VERIFIED = 'phoneverified';
export const USER_ATTR_RESIDENCY_NO = 'residencyno';
export const USER_ATTR_NRIC_UPLOADED = 'nricuploaded';
export const USER_ATTR_BOOKING_ACCEPTED_RATE = 'bookingacceptancerate';
export const USER_ATTR_RESPONSE_RATE = 'responserate';
export const USER_ATTR_SIGN_UP_AT = 'signedupdate';
export const USER_ATTR_APPROVAL_DATE_HOST = 'approvaldatehost';
export const USER_ATTR_APPROVAL_DATE_GUEST = 'approvaldateguest';
export const USER_ATTR_STATUS = 'status';
export const USER_UTM_SOURCE = 'utm_source';
export const USER_UTM_MEDIUM = 'utm_medium';
export const USER_UTM_CAMPAIGN = 'utm_campaign';
export const USER_UTM_CONTENT = 'utm_content';
export const USER_ATTR_SUCCESSFUL_TRIPS_GUEST = 'successful_trips_guest';
export const USER_ATTR_SUCCESSFUL_TRIPS_GUEST_DAYS =
  'successful_trips_guest_days';
export const USER_ATTR_SUCCESSFUL_TRIPS_HOST = 'successful_trips_host';
export const USER_ATTR_SUCCESSFUL_TRIPS_HOST_DAYS =
  'successful_trips_host_days';
export const USER_ATTR_RESPONSE_TIME = 'responsetime';
export const USER_ATTR_BOOKING_ACCEPTED_HOST = 'bookingaccepted_host';
export const USER_ATTR_BOOKING_ACCEPTED_HOST_DAYS = 'bookingaccepted_host_days';
export const USER_ATTR_BOOKING_ACCEPTED_GUEST = 'bookingaccepted_guest';
export const USER_ATTR_BOOKING_ACCEPTED_GUEST_DAYS =
  'bookingaccepted_guest_days';
export const USER_ATTR_BOOKING_DECLINED_HOST = 'bookingdeclined_host';
export const USER_ATTR_BOOKING_DECLINED_HOST_DAYS = 'bookingdeclined_host_days';
export const USER_ATTR_BOOKING_DECLINED_GUEST = 'bookingdeclined_guest';
export const USER_ATTR_BOOKING_DECLINED_GUEST_DAYS =
  'bookingdeclined_guest_days';
export const USER_ATTR_BOOKING_EXPIRED_HOST = 'bookingexpired_host';
export const USER_ATTR_BOOKING_EXPIRED_HOST_DAYS = 'bookingexpired_host_days';
export const USER_ATTR_BOOKING_EXPIRED_GUEST = 'bookingexpired_guest';
export const USER_ATTR_BOOKING_EXPIRED_GUEST_DAYS = 'bookingexpired_guest_days';
export const USER_ATTR_ENQUIRIESRECEIVED = 'enquiriesreceived';
export const USER_ATTR_ENQUIRIESSENT = 'enquiriessent';
export const USER_ATTR_MESSAGE_SENT_HOST = 'messagesent_host';
export const USER_ATTR_MESSAGE_SENT_GUEST = 'messagesent_host';
export const USER_ATTR_TRIP_HOST_CANCEL_HOST = 'triphostcancel_host';
export const USER_ATTR_TRIP_HOST_CANCEL_HOST_DAYS = 'triphostcancel_host_days';
export const USER_ATTR_TRIP_HOST_CANCEL_GUEST = 'triphostcancel_guest';
export const USER_ATTR_TRIP_HOST_CANCEL_GUEST_DAYS =
  'triphostcancel_guest_days';
export const USER_ATTR_TRIP_GUEST_CANCEL_GUEST = 'tripguestcancel_guest';
export const USER_ATTR_TRIP_GUEST_CANCEL_GUEST_DAYS =
  'tripguestcancel_guest_days';
export const USER_ATTR_TRIP_GUEST_CANCEL_HOST = 'tripguestcancel_host';
export const USER_ATTR_TRIP_GUEST_CANCEL_HOST_DAYS = 'tripguestcancel_host';
export const USER_ATTR_BOOKINGS_DECLINED_AS_HOST =
  'bookingsdeclined_as_host_days';
export const USER_ATTR_BOOKING_FAILED_GUEST = 'bookingfailed_guest';
export const USER_ATTR_BOOKING_FAILED_GUEST_DAYS = 'bookingfailed_guest_days';
export const USER_ATTR_BOOKING_RECEIVED_HOST = 'bookingrecd_host';
export const USER_ATTR_BOOKING_SEND_GUEST = 'bookingsent_guest';
export const USER_ATTR_EMAIL_VERIFIED = 'email_verified';
export const USER_ATTR_PUBLISHED_LISTINGS = 'published_listings';
export const USER_ATTR_LIVE_LISTINGS = 'live_listings';
export const USER_ATTR_PENDING_LISTINGS = 'pending_listings';
export const USER_ATTR_DRAFT_LISTINGS = 'draft_listings';
export const USER_ATTR_DRIVING_LICENSE = 'driving_license';
export const USER_ATTR_ID = 'id';
export const USER_ATTR_REFERRAL_CODE_USED = 'referralcode_used';
export const USER_ATTR_UNIQUE_REFERRAL_CODE_ = 'unique_referralcode';
export const USER_ATTR_REFFERED_BOOK_TRIP = 'reffered_booked_trip';
export const USER_ATTR_REFFERED_HOST_TRIP = 'reffered_hosted_trip';
export const USER_ATTR_CREDITS = 'credits';
export const USER_ATTR_AVERAGE_RATING = 'average_rating';
export const USER_ATTR_NO_OF_REVIEWS = 'number_of_reviews';
export const USER_ATTR_SUBURB = 'suburb';

//Listing properties
export const LISTING_TITLE = 'listing_title';
export const LISTING_ID = 'listing_id';
export const LISTING_AGE = 'listing_age';
export const LISTING_CREATE_DATE = 'listing_created_date';
export const LISTING_APPROVAL_DATE = 'listing_approval_date';
export const LISTING_STATUS = 'listing_status';
export const LISTING_BRAND_NAME = 'listing_brand_name';
export const LISTING_CAN_DRIVE_TO_MALAYSIA = 'listing_can_drive_to_malaysia';
export const LISTING_MODEL_NAME = 'listing_model_name';
export const LISTING_INSURANCE_TYPE = 'listing_insurance_type';
export const LISTING_COMPANY_OWNED = 'listing_company_owned';
export const LISTING_CATEGORY = 'listing_category';
export const LISTING_DATE_OF_REGISTRATION = 'listing_date_of_registration';
export const LISTING_ENGINE_CAPACITY = 'listing_engine_capacity';
export const LISTING_FUEL_TYPE = 'listing_fuel_type';
export const LISTING_IS_DRIVELAH_GO = 'listing_is_drivelah_go';
export const LISTING_DELIVERY = 'listing_is_delivery';
export const LISTING_IS_INSTANT_BOOKING = 'listing_is_instantbooking';
export const LISTING_IS_PHV = 'listing_is_phv';
export const LISTING_IS_TRACKING_DEVICE = 'listing_is_tracking_device';
export const LISTING_KMS_DRIVEN = 'listing_kms_driven';
export const LISTING_LICENSE_PLATE = 'listing_license_plate';
export const LISTING_LOCATION = 'listing_location';
export const LISTING_MILEAGE_PER_DAY = 'listing_mileage_per_day';
export const LISTING_MONTHLY_DISCOUNT = 'listing_monthlydiscount';
export const LISTING_PEAK_PRICE = 'listing_peakprice';
export const LISTING_PICS = 'listing_pics';
export const LISTING_REGULAR_PRICE = 'listing_regularprice';
export const LISTING_REVIEWS = 'listing_reviews';
export const LISTING_SEARCH_RESULTS = 'listing_search_results';
export const LISTING_SUCCESSFUL_TRIPS = 'listing_successful_trips';
export const LISTING_SUCCESSFUL_TRIPS_DAYS = 'listing_successful_trips_days';
export const LISTING_VEHICLE_TYPE = 'listing_vehicle_type';
export const LISTING_VIEWS = 'listing_views';
export const LISTING_WEEKLY_DISCOUNT = 'listing_weeklydiscount';
export const LISTING_REGISTRATION_NUMBER = 'listing_registration';
export const LISTING_BOOKINGS_RECEIVED = 'listing_bookings_received';
export const LISTING_BOOKINGS_RECEIVED_DAYS = 'listing_bookings_received_days';
export const LISTING_BOOKING_ACCEPTED = 'listing_booking_accepted';
export const LISTING_BOOKING_ACCEPTED_DAYS = 'listing_booking_accepted_days';
export const LISTING_TRIP_CANCELLED_HOST = 'listing_trip_cancelled_host';
export const LISTING_TRIP_CANCELLED_HOST_DAYS =
  'listing_trip_cancelled_host_days';
export const LISTING_TRIP_CANCELLED_GUEST = 'listing_trip_cancelled_guest';
export const LISTING_TRIP_CANCELLED_GUEST_DAYS =
  'listing_trip_cancelled_guest_days';
export const LISTING_ENQUIRIES_RECVD = 'listing_enquiries_recvd';
export const LISTING_BOOKING_DECLINED = 'listing_booking_declined';
export const LISTING_BOOKING_DECLINED_DAYS = 'listing_booking_declined_days';
export const LISTING_LAST30DAY_CALUPDATED = 'listing_last30day_calupdated';
export const LISTING_LAST60DAY_CALUPDATED = 'listing_last60day_calupdated';
export const LISTING_REGULARPRICE = 'listing_regularprice';
export const LISTING_PEAKPRICE = 'listing_peakprice';
export const LISTING_WEEKLYDISCOUNT = 'listing_weeklydiscount';
export const LISTING_MONTHLYDISCOUNT = 'listing_monthlydiscount';
export const LISTING_RATING = 'listing_rating';
export const LISTING_SOURCE = 'listing_viewed_source';
export const LISTING_URL = 'listing_url';
export const LISTING_SEATS = 'listing_seats';
export const LISTING_ATTRACTIVENESSSCORE = 'listing_attractivenessscore';
export const LISTING_PRICESCORE = 'listing_pricescore';
export const LISTING_LONGTERM = 'listing_longterm';
export const LISTING_AVAILABILITYSCORE = 'listing_availabilityscore';
export const LISTING_SUBURB = 'listing_suburb';
export const LISTING_SUCCESSFULL_TRIP_DAYS = 'listing_successful_trips_days';
export const LISTING_POSTAL_DISTRICT = 'listing_postal_district';
export const LISTING_SUPERHOST = 'listing_superhost';
export const LISTING_SUBMITTED_DATE = 'listing_submitted_date';
export const LISTING_LIVE_DATE = 'listing_live_date';
export const LISTING_IS_NEW = 'listing_is_new';
export const LISTING_HOURLY_ENABLED = 'listing_hourly_enabled';
export const LISTING_NEAREST_STOP = 'listing_nearest_stop';
export const LISTING_PRIVATE_INSURER = 'listing_private_insurer';
export const LISTING_BAR = 'listing_bar';
export const LISTING_RMS = 'listing_rms';
export const LISTING_CARETAKER = 'listing_caretaker';
export const LISTING_PICTURE_SCORE = 'listing_picture_score';

//Search properties
export const SEARCH_LOCATION = 'search_location';
export const SEARCH_PICKUPDATE = 'search_pickupdate';
export const SEARCH_DROPOFFDATE = 'search_dropoffdate';
export const SEARCH_CAR_CATEGORY_FILTER = 'search_car_category_filter';
export const SEARCH_CAR_CATEGORY_VALUE = 'search_car_category_value';
export const SEARCH_PRICE_FILTER = 'search_price_filter';
export const SEARCH_PRICE_VALUE = 'search_price_value';
export const SEARCH_KEYWORD_FILTER = 'search_keyword_filter';
export const SEARCH_KEYWORD_VALUE = 'search_keyword_value';
export const SEARCH_DL_GO_FILTER = 'search_dl_go_filter';
export const SEARCH_BRAND_FILTER = 'search_brand_filter';
export const SEARCH_BRAND_VALUE = 'search_brand_value';
export const SEARCH_FEATURES_FILTER = 'search_features_filter';
export const SEARCH_FEATURES_VALUE = 'search_features_value';
export const SEARCH_NUMBEROFPEOPLE_FILTER = 'search_numberofpeople_filter';
export const SEARCH_NUMBEROFPEOPLE_VALUE = 'search_numberofpeople_value';
export const SEARCH_TRANSMISSION_FILTER = 'search_transmission_filter';
export const SEARCH_TRANSMISSION_VALUE = 'search_transmission_value';
export const SEARCH_PHV_FILTER = 'search_PHV_filter';
export const SEARCH_MALAYSIA_FILTER = 'search_malaysia_filter';
export const SEARCH_FUEL_FILTER = 'search_fuel_filter';
export const SEARCH_FUEL_VALUE = 'search_fuel_value';
export const SEARCH_NUMBEROFRESULTS = 'search_numberofresults';
export const SEARCH_NUMBEROFPAGESSEEN = 'search_numberofpagesseen';
export const SEARCH_PICKUPTIME = 'search_pickuptime';
export const SEARCH_DROPOFFTIME = 'search_dropofftime';
export const SEARCH_USED_SORT = 'search_used_sort';
export const SEARCH_USED_SORT_VALUE = 'search_used_sort_value';
export const SEARCH_DELIVERY_FILTER = 'search_delivery_filter';
export const SEARCH_TYPE = 'search_type';
export const SEARCH_SOURCE = 'search_source';
export const SEARCH_INPUT_STRING = 'search_input_string';
export const SEARCH_INSTANTBOOKING_FILTER = 'search_instantbooking_filter';
export const SEARCH_SUPERHOST_FILTER = 'search_superhost_filter';
export const SEARCH_TOPOFFER_FILTER = 'search_topoffer_filter';

//Booking properties
export const BOOKING_PICK_UP_DATE = 'booking_pickup_date';
export const BOOKING_PICK_UP_TIME = 'booking_pickup_time';
export const BOOKING_DROP_OFF_DATE = 'booking_dropoff_date';
export const BOOKING_DROP_OFF_TIME = 'booking_dropoff_time';
export const BOOKING_PROMO_CREDITS = 'booking_promo_credits';
export const BOOKING_GUEST_CREDITS_VALUE = 'booking_guestcredits_value';
export const BOOKING_GUEST_PROMO_VALUE = 'booking_guestpromo_value';
export const BOOKING_TOTAL_DISCOUNT = 'booking_totaldiscount';
export const BOOKING_PROMO_CODE = 'booking_promocode';
export const BOOKING_TRIP_PRICE = 'booking_tripprice';
export const BOOKING_TRIP_FEES = 'booking_tripfees';
export const BOOKING_YOUNG_DRIVER_FEES = 'booking_youngdriverfees';
export const BOOKING_TOTAL_PRICE = 'booking_totalprice';
export const BOOKING_TRIP_DAYS = 'booking_trip_days';
export const BOOKING_TRIP_ID = 'booking_trip_id';
export const BOOKING_INSTANT = 'booking_instant_booking';
export const BOOKING_UNVERIFIED = 'booking_unverified';
export const BOOKING_DL_GO = 'booking_dlgo';
export const BOOKING_VAS_DELIVERY = 'booking_vas_delivery';
export const BOOKING_EXCESS_PRICE = 'booking_excess_price';
export const BOOKING_EXCESS2_PRICE = 'booking_excess2_price';
export const BOOKING_HOMEDELIVERY_PRICE = 'booking_homedelivery_price';
export const BOOKING_VAS_EXCESS = 'booking_vas_excess';
export const BOOKING_VAS_EXCESS_2 = 'booking_vas_excess2';
export const BOOKING_VAS_FUEL = 'booking_vas_fuel';
export const BOOKING_GUEST_ID = 'booking_guest_id';
export const BOOKING_HOST_ID = 'booking_host_id';
export const BOOKING_LISTING_ID = 'booking_listing_id';
export const BOOKING_LISTING_NAME = 'booking_listing_name';
export const BOOKING_ERROR = 'booking_error';
export const BOOKING_HOSTNAME = 'booking_hostname';

//Trip properties
export const TRIP_BOOKING_TRIP_ID = 'trip_booking_trip_id';
export const TRIP_DL_GO = 'trip_dlgo';
export const TRIP_VAS_DELIVERY = 'trip_vas_delivery';
export const TRIP_DROP_OFF_DATE = 'trip_dropoff_date';
export const TRIP_DROP_OFF_TIME = 'trip_dropoff_time';
export const TRIP_HOST_CREDITS = 'trip_host_credits';
export const TRIP_HOST_EARNINGS = 'trip_host_earnings';
export const TRIP_INSTANT_BOOKING = 'trip_instant_booking';
export const TRIP_NO_OF_DAYS = 'trip_noofdays';
export const TRIP_PICKUP_DATE = 'trip_pickup_date';
export const TRIP_PICKUP_TIME = 'trip_pickup_time';
export const TRIP_TOTAL_PRICE = 'trip_totalprice';
export const TRIP_TRIP_FEES = 'trip_tripfees';
export const TRIP_YOUNG_DRIVER_FEES = 'trip_youngdriverfees';
export const TRIP_ID = 'trip_id';
export const TRIP_DLGO = 'trip_dl_go';
export const TRIP_NOOFDAYS = 'trip_noofdays';
export const TRIP_VAS_EXCESS = 'trip_vas_excess';
export const TRIP_VAS_EXCESS_2 = 'trip_vas_excess2';
export const TRIP_VAS_FUEL = 'trip_vas_fuel';
export const TRIP_VAS_EXCESS_FROM = 'trip_vas_excess_from';
export const TRIP_VAS_EXCESS_2_FROM = 'trip_vas_excess2_from';
export const TRIP_VAS_FUEL_FROM = 'trip_vas_fuel_from';
export const TRIP_GUEST_ID = 'trip_guest_id';
export const TRIP_HOST_ID = 'trip_host_id';
export const TRIP_LISTING_ID = 'trip_listing_id';
export const TRIP_LISTING_NAME = 'trip_listing_name';
export const TRIP_PICKUP_SENT = 'trip_pickup_sent';
export const TRIP_DROPOFF_SENT = 'trip_dropoff_sent';


export const events = {
  //APP
  APP_OPENED: 'App Opened',

  //AUTH
  LOGGED_IN: 'Logged In',
  LOGGED_OUT: 'Logged Out',

  //SIGNUP
  SIGN_UP_FORM_OPEN: 'Signup Open Form',
  SIGN_UP_EMAIL_FORM_OPEN: 'Signup Open Email Form',
  SIGN_UP_COMPLETED: 'Signup Completed',
  SIGN_UP_COMPLETED_STEP2: 'Signup Completed Step 2',
  SIGN_UP_SUBMIT_EMAIL_FORM: 'Signup Submit Email Form',
  SIGN_UP_ADD_CODE: 'Add Signup Code',
  SIGN_UP_CREATE_VOUCHERIFY: 'Voucherify Account Created',
  SIGN_UP_SELECT_CAR_RENT: 'Select Rent A Car Option',
  SIGN_UP_SELECT_CAR_HOST: 'Select Host A Car Option',
  SIGN_UP_SOCIAL_FACEBOOK: 'Signup Social Facebook',
  SIGN_UP_SOCIAL_GOOGLE: 'Signup Social Google',
  SIGN_UP_SOCIAL_APPLE: 'Signup Social Apple',
  SIGN_UP_VERIFIED_EMAIL: 'Signup Verified Email',
  SIGN_UP_VERIFIED_PHONE: 'Signup Verified Phone',

  //GUEST VERIFY
  GUEST_SAVED_CONTACT_INFO: 'Saved Contact Information',
  GUEST_VERIFY_SAVED_ADDRESS: 'Guestverify Add Address',
  GUEST_VERIFY_OPEN_FORM: 'Guestverify Open Driverform',
  GUEST_VERIFY_DOB: 'Guestverify Add Dob',
  GUEST_VERIFY_COMPLETE_ONFIDO: 'Guestverify Complete Onfido',
  GUEST_VERIFY_SUBMIT_FORM: 'Guestverify Submit Driverform',
  GUEST_VERIFY_ADD_CARD: 'Guestverify Add Creditcard',
  GUEST_VERIFY_DRIVER_VERIFED: 'Guestverify Driver Verified',
  GUEST_VERIFY_DRIVER_REJECTED: 'Guestverify Driver Rejected',
  GUEST_VERIFY_COMPLETE_SINGPASS: 'Guestverify Complete Singpass',

  //HOST VERIFY
  HOST_VERIFY_NRIC: 'Hostverify Entered Nric',
  HOST_VERIFY_PICTURE: 'Hostverify Uploaded Pic',
  HOST_VERIFY_SUBMIT: 'Hostverify Pressed Submit',
  HOST_VERIFY_STRIPE_SUCCESS: 'Hostverify Stripe Success',
  HOST_VERIFY_STRIPE_FAIL: 'Hostverify Stripe Failure',

  //SEARCH NON AUTH
  SEARCH_WITHOUT_LOGIN: 'Press Search Without Login',

  //HOST FORM
  HOST_LEAD_FORM_OPEN: 'Host Lead Form Open',
  HOST_LEAD_FORM_SUBMITTED: 'Host Lead Form Submitted',
  HOST_LEAD_FORM_GOTO_DASHBOARD: 'Host Lead Form Go To Host Dashboard',

  //BROWSING
  FAVOURITES_ADDED_TO_FAVOURITES: 'Favourites Added To Favourites',
  FAVOURITES_REMOVED_FROM_FAVOURITES: 'Favourites Removed From Favourites',
  BROWSE_CLICK_IMP_LINKS: 'Browse Click Important Links',
  BROWSE_CLICK_HOW_IT_WORKS: 'Browse Click How It Works',

  //SEARCH
  SEARCH_EXPLORE: 'Search Explore',
  SEARCH_PERFORMED: 'Search Performed',
  SEARCH_USED_MAP: 'Search Used Map',
  SEARCH_CLICK_SEARCHBAR: 'Search Click Search Bar',

  SEARCH_SENT_ENQUIRY_GUEST: 'Search Sent Enquiry Guest',
  SEARCH_VIEWED_LISTING_GUEST: 'Search Viewed Listing Guest',
  SEARCH_VIEWED_LISTING_HOST: 'Search Viewed Listing Host',
  SEARCH_RECV_ENQUIRY_HOST: 'Search Recv Enquiry Host',

  SEARCH_CLICK_PIC_LISTING: 'Search Clicked Pic Listing',
  SEARCH_USED_MAP_LISTING: 'Search Used Map Listing',

  SEARCH_VIEWED_PROFILE_GUEST: 'Search Viewed Profile Guest',
  SEARCH_READ_INSURANCE: 'Search Read Insurance',

  SEARCH_PRESSED_BOOK_GUEST: 'Search Pressed Book Guest',
  SEARCH_PRESSED_BOOK_GUEST_ERROR: 'Search Pressed Book Guest Error',

  //BOOKING
  BOOK_CONTINUE_ADDONS: 'Book Pressed Continue Add-Ons Step',
  BOOK_DELIVERY_ADDON: 'Delivery Addon Added',
  BOOK_DELIVERY_ADDON_REMOVED: 'Delivery Addon Removed',
  BOOK_EXCESS_ADDON: 'Excess Addon Added',
  BOOK_EXCESS_ADDON_REMOVED: 'Excess Addon Removed',
  BOOK_EXCESS_2_ADDON: 'Excess 2 Addon Added',
  BOOK_EXCESS_2_ADDON_REMOVED: 'Excess 2 Addon Removed',
  BOOK_EXCESS_2_ADDON_SUCCESS: 'Book Excess 2 Success Guest',
  BOOK_FUEL_ADDON: 'Fuel Addon Added',
  BOOK_FUEL_ADDON_REMOVED: 'Fuel Addon Removed',
  BOOK_FUEL_ADDON_SUCCESS: 'Book fuel success guest',
  BOOK_MILEAGE_ADDON: 'Mileage Addon 4 Added',
  BOOK_MILEAGE_ADDON_REMOVED: 'Mileage Addon 4 Added Removed',

  BOOK_ENTERED_CARD_DETAILS: 'Book Entered Card Details',
  BOOK_SAVED_CARD_DETAILS: 'Book Saved Card Details',
  BOOK_PREV_SAVED_CARD_DETAILS: 'Book Prev Saved Card Details',
  BOOK_CREATED_REQUEST_GUEST: 'Book Created Request Guest',
  BOOK_SENT_REQUEST_SUCCESS_GUEST: 'Book Sent Request Success Guest',
  BOOK_RECEIVED_REQUEST_SUCCESS_HOST: 'Book Received Request Success Host',
  BOOK_CREATED_REQUEST_FAIL_GUEST: 'Book Created Request Fail Guest',
  BOOK_CREATED_REQUEST_FAIL_HOST: 'Book Created Request Fail Host',

  //TRIPS
  BOOK_REQUEST_ACCEPTED_HOST: 'Book Request Accepted Host',
  BOOK_REQUEST_WITHDRAWN_GUEST: 'Book Request Withdrawn Guest',
  BOOK_REQUEST_DECLINE_HOST: 'Book Request Declined Host',
  TRIPS_CANCEL_GUEST: 'Trip Guestcancel Guest',
  TRIPS_CANCEL_HOST: 'Trip Hostcancel Host',
  TRIPS_PICKUP_SENT: 'Trip Pickup Request Sent',
  TRIPS_PICKUP_CONFIRM: 'Trip Pickup Request Confirm',
  TRIPS_DROPOFF_SENT: 'Trip Dropoff Request Sent',
  TRIPS_DROPOFF_CONFIRM: 'Trip Dropoff Request Confirm',
  TRIPS_DISPUTE_HOST: 'Trip Disputed Host',
  TRIPS_REVIEW_HOST: 'Trip Hostreview Host',
  TRIPS_REVIEW_GUEST: 'Trip Guestreview Guest',
  TRIPS_MESSAGE_HOST: 'Trip Messagesent Host',
  TRIPS_MESSAGE_GUEST: 'Trip Messagesent Guest',
  TRIPS_MODIFICATION_REQUEST_SENT: 'Trip Modification Request Sent Guest',
  TRIPS_MODIFICATION_REQUEST_ACCEPT: 'Trip Modification Request Accepted Host',
  TRIPS_MODIFICATION_REQUEST_DECLINE: 'Trip Modification Request Declined Host',

  //LISTING PROCESS
  LIST_CAR_CLICKED: 'Listacar Clicked List A Car',
  LIST_CAR_DRAFT: 'Listacar Created Draft',
  LIST_CAR_COMPLETED_LOCATION: 'Listacar Completed Location',
  LIST_CAR_COMPLETED_DETAILS: 'Listacar Completed Cardetails',
  LIST_CAR_COMPLETED_FEATURES: 'Listacar Completed Carfeatures',
  LIST_CAR_COMPLETED_RULES: 'Listacar Completed Carrules',
  LIST_CAR_COMPLETED_PRICING: 'Listacar Completd Pricing',
  LIST_CAR_COMPLETED_PICTURES: 'Listacar Completed Pictures',
  LIST_CAR_COMPLETED_INSURANCE: 'Listacar Completed Insurance',
  LIST_CAR_COMPLETED_AVAILABILITY: 'Listacar Completed Availbility',
  LIST_CAR_UPDATED_AVAILABILITY: 'Listacar Updated Availability',
  LIST_CAR_SELECTED_DLGO: 'Listacar Selected Drivelahgo',
  LIST_CAR_SELECTED_IB: 'Listacar Selected Instantbook',
  LIST_CAR_COMPLETED_NEXTLEVEL: 'Listacar Completed Nextlevelsharing',
  LIST_CAR_COMPLETED: 'Listacar Completed',
  //addons
  Line_Item_Experiment_Case_A: 'Line Item Experiment Case A',
  Line_Item_Experiment_Case_B: 'Line Item Experiment Case B',
  // trip modification
  EVENT_TRIP_MODIFICATION_REQUEST_SENT_GUEST:
    'EVENT_TRIP_MODIFICATION_REQUEST_SENT_GUEST',

  //Mileage Exp
  MILEAGE_EXP_CASE_A: 'Mileage VAS Exp A',
  MILEAGE_EXP_CASE_B: 'Mileage VAS Exp B',

  SEARCH_SIMILAR_CARS: 'Trip Search Similar Cars',
  // Trip fee exp
  // TRIP_FEE_EXPERIMENT_CASE_A: 'Trip Fees Experiment Case A',
  // TRIP_FEE_EXPERIMENT_CASE_B: 'Trip Fees Experiment Case B',
  // TRIP_FEE_EXPERIMENT_CASE_C: 'Trip Fees Experiment Case C',
  // TRIP_FEE_EXPERIMENT_CASE_D: 'Trip Fees Experiment Case D',
  SEARCH_CLICKED_SEND_ENQUIRY: 'Search Clicked Send Enquiry',
  SEARCH_CLICKED_SEND_ENQUIRY_STEP_2: 'Search Clicked Send Enquiry Step 2',
  GUEST_VERIFY_ADD_CONTACT: ' Guestverify Add Contact',
  GUEST_VERIFY_ADD_ADDRESS: 'Guestverify Add Address',
  GUEST_VERIFY_Add_Dob: 'Guestverify Add Dob',
  GUEST_VERIFY_Add_Credit_Card: 'Guestverify Add Creditcard',
  GUEST_VERIFY_DRIVER_VERIFIED: 'Guestverify Driver Verified',
  GUEST_VERIFY_DRIVER_DECLINED: 'Guestverify Driver Declined',
  SEARCH_CLICK_SEARCH_BAR: 'Search Click Search Bar',
  SEARCH_SHARED_LISTING_GUEST: 'Search Shared Listing Guest',
  SEARCH_FAVOURITE_LISTING_GUEST: 'Search Favourite Listing Guest',
  SEARCH_OPEN_CALENDAR: 'Search Open Calendar',
  SEARCH_SET_TRIP_DATES: 'Search Set Trip Dates',
  SEARCH_SET_TRIP_DATES_ERROR: 'Search Set Trip Dates Error',
  SEARCH_RECIEVED_ENQUIRY_HOST: 'Search Recieved Enquiry Host',
  SEARCH_PRESSED_BOOK_HOST: 'Search Pressed Book Host',
  BOOK_PRESSED_CONTINUE_ADDONS_STEP: 'Book Pressed Continue Addons Step',
  BOOK_DELIVERY_ADDON_ADDED: 'Book Delivery Addon Added',
  BOOK_DELIVERY_SUCCESS_GUEST: 'Book Delivery Success Guest',
  BOOK_EXCESS_ADDON_ADDED: 'Book Excess Addon Added',
  BOOK_EXCESS_SUCCESS_GUEST: 'Book Excess Success Guest',
  BOOK_EXCESS_2_ADDON_ADDED: 'Book Excess 2 Addon Added',
  BOOK_EXCESS_2_SUCCESS_GUEST: 'Book Excess 2 Success Guest',
  BOOK_FUEL_ADDON_ADDED: 'Book Fuel Addon Added',
  BOOK_FUEL_SUCCESS_GUEST: 'Book Fuel Success Guest',
  BOOK_MILEAGE_ADDON_ADDED: 'Book Mileage Addon Added',
  BOOK_MILEAGE_SUCCESS_GUEST: 'Book Mileage Success Guest',
  BOOK_ADD_NEW_CARD: 'Book Add New Card',
  BOOK_USED_PRESAVEDDETAILS: 'Book Used Presaved Details',
  BOOK_USED_PROMO_CODE: 'Book Used Promo Code',
  BOOK_USED_PROMO_CODE_ERROR: 'Book Used Promo Code Error',
  BOOK_USED_CREDITS: 'Book Used Credits',
  BOOK_REQUEST_ACCEPTED_GUEST: 'Book Request Accepted Guest',
  BOOK_CANCELLED_GUEST_BY_GUEST: 'Book Cancelled Guest By Guest',
  BOOK_CANCELLED_HOST_BY_GUEST: 'Book Cancelled Host By Guest',
  BOOK_CANCELLED_GUEST_BY_HOST: 'Book Cancelled Guest By Host',
  BOOK_CANCELLED_HOST_BY_HOST: 'Book Cancelled Host By Host',
  BOOK_REQUEST_DECLINED_GUEST: 'Book Request Declined Guest',
  BOOK_REQUEST_DECLINED_HOST: 'Book Request Declined Host',
  BOOK_REQUEST_EXPIRED_GUEST: 'Book Request Expired Guest',
  BOOK_REQUEST_EXPIRED_HOST: 'Book Request Expired Host',
  BOOK_CREATED_MODIFICATION_GUEST: 'Book Created Modification Guest',
  BOOK_SENT_MODIFICATION_SUCCESS_GUEST: 'Book Sent Modification Success Guest',
  BOOK_RECEIVED_MODIFICATION_SUCCESS_HOST:
    'Book Received Modification Success Host',
  BOOK_MODIFICATION_ACCEPTED_GUEST: 'Book Modification Accepted Guest',
  BOOK_MODIFICATION_ACCEPTED_HOST: 'Book Modification Accepted Host',
  BOOK_MODIFICATION_DECLINED_GUEST: 'Book Modification Declined Guest',
  BOOK_MODIFICATION_DECLINED_HOST: 'Book Modification Declined Host',
  BOOK_MODIFICATION_EXPIRED_GUEST: 'Book Modification Expired Guest',
  BOOK_MODIFICATION_EXPIRED_HOST: 'Book Modification Expired Host',
  BOOK_FUEL_CHARGE_ATTEMPTED_GUEST: 'Book Fuel Charge Attempted Guest',
  BOOK_FUEL_CHARGE_FAIL_GUEST: 'Book Fuel Charge Fail Guest',
  BOOK_FUEL_CHARGE_SUCCESS_GUEST: 'Book Fuel Charge Success Guest',
  REVENUE: 'Revenue',
  TRIP_START_GUEST_BY_ADMIN: 'Trip Start Guest By Admin',
  TRIP_START_HOST_BY_ADMIN: 'Trip Start Host By Admin',
  TRIP_PICKUP_REQUEST_SENT: 'Trip Pickup Request Sent',
  TRIP_PICKUP_REQUEST_RECEIVED: 'Trip Pickup Request Received',
  TRIP_PICKUP_REQUEST_CONFIRM: 'Trip Pickup Request Confirm',
  TRIP_PICKUP_REQUEST_CONFIRM_GUEST: 'Trip Pickup Request Confirm Guest',
  TRIP_DROPOFF_REQUEST_SENT: 'Trip Dropoff Request Sent',
  TRIP_DROPOFF_REQUEST_RECEIVED: 'Trip Dropoff Request Received',
  TRIP_DROPOFF_REQUEST_CONFIRM: 'Trip Dropoff Request Confirm',
  TRIP_DROPOFF_REQUEST_CONFIRM_GUEST: 'Trip Dropoff Request Confirm Guest',
  TRIP_DISPUTED_HOST: 'Trip Disputed Host',
  TRIP_DISPUTED_GUEST: 'Trip Disputed Guest',
  TRIP_HOST_REVIEW_BY_HOST: 'Trip Host Review By Host',
  TRIP_HOST_REVIEW_BY_GUEST: 'Trip Host Review By Guest',
  TRIP_GUEST_REVIEW_BY_GUEST: 'Trip Guest Review By Guest',
  TRIP_GUEST_REVIEW_BY_HOST: 'Trip Guest Review By Host',
  TRIP_EXPIREREVIEW_HOST: 'Trip Expirereview Host',
  TRIP_EXPIREREVIEW_GUEST: 'Trip Expirereview Guest',
  TRIP_AUTOCOMPLETE_HOST: 'Trip Autocomplete Host',
  TRIP_AUTOCOMPLETED_GUEST: 'Trip Autocompleted Guest',
  TRIP_END_GUEST_BY_ADMIN: 'Trip End Guest By Admin',
  TRIP_END_HOST_BY_ADMIN: 'Trip End Host By Admin',
  TRIP_MESSAGESENT_HOST: 'Trip Messagesent Host',
  TRIP_MESSAGESENT_GUEST: 'Trip Messagesent Guest',
  TRIP_VIEW_MODIFICATION_PAGE_GUEST: 'Trip View Modification Page Guest',
  TRIP_VIEW_ADD_ONS_GUEST: 'Trip View Add Ons Guest',
  TRIP_EXCESS_ADDON_ADDED: 'Trip Excess Addon Added',
  TRIP_EXCESS_ADDON_REMOVED: 'Trip Excess Addon Removed',
  TRIP_EXCESS_2_ADDON_ADDED: 'Trip Excess 2 Addon Added',
  TRIP_EXCESS_2_ADDON_REMOVED: 'Trip Excess 2 Addon Removed',
  TRIP_FUEL_ADDON_ADDED: 'Trip Fuel Addon Added',
  TRIP_FUEL_ADDON_REMOVED: 'Trip Fuel Addon Removed',
  TRIP_MILEAGE_ADDON_ADDED: 'Trip Mileage Addon Added',
  TRIP_MILEAGE_ADDON_REMOVED: 'Trip Mileage Addon Removed',
  TRIP_PRESS_PAY_ADD_ONS_GUEST: 'Trip Press Pay Add Ons Guest',
  TRIP_VIEW_FAQ_GUEST: 'Trip View FAQ Guest',
  TRIP_VIEW_PRICE_BREAKDOWN_GUEST: 'Trip View Price Breakdown Guest',
  TRIP_VIEW_IMPORTANT_DOCUMENTS_GUEST: 'Trip View Important Documents Guest',
  TRIP_VIEW_CANCEL_GUEST: 'Trip View Cancel Guest',
  TRIP_VIEW_DETAILS_TAB_HOST: 'Trip View Details Tab Host',
  TRIP_VIEW_MESSAGES_TAB_HOST: 'Trip View Messages Tab Host',
  TRIP_VIEW_HELP_TAB_HOST: 'Trip View Help Tab Host',
  TRIP_VIEW_FAQ_HOST: 'Trip View FAQ Host',
  TRIP_VIEW_PRICE_BREAKDOWN_HOST: 'Trip View Price Breakdown Host',
  TRIP_VIEW_IMPORTANT_DOCUMENTS_HOST: 'Trip View Important Documents Host',
  TRIP_VIEW_CANCEL_HOST: 'Trip View Cancel Host',
  HOSTVERIFY_OPEN_FORM: 'Hostverify Open Form',
  HOSTVERIFY_ADD_CONTACT_DETAILS: 'Hostverify Add Contact Details',
  HOSTVERIFY_ADD_ADDRESS: 'Hostverify Add Address',
  HOSTVERIFY_ADD_DOB: 'Hostverify Add DOB',
  HOSTVERIFY_COMPLETE_ONFIDO: 'Hostverify Complete Onfido',
  HOSTVERIFY_SUBMIT_VERIFICATION: 'Hostverify Submit Verification',
  HOSTVERIFY_STRIPE_SUCCESS: 'Hostverify Stripe Success',
  HOSTVERIFY_STRIPE_FAILURE: 'Hostverify Stripe Failure',
  HOSTVERIFY_HOST_APPROVED: 'Hostverify Host Approved',
  HOSTVERIFY_HOST_REJECTED: 'Hostverify Host Rejected',
  HOSTVERIFY_HOST_ONBOARDED: 'Hostverify Host Onboarded',
  LISTACAR_CLICKED_LIST_A_CAR: 'Listacar Clicked List A Car',
  LISTACAR_CREATED_DRAFT: 'Listacar Created Draft',
  LISTACAR_ABANDON_FORM: 'Listacar Abandon Form',
  LISTACAR_COMPLETED_CARDETAILS: 'Listacar Completed Cardetails',
  LISTACAR_COMPLETED_LOCATION: 'Listacar Completed Location',
  LISTACAR_COMPLETED_CARFEATURES: 'Listacar Completed Carfeatures',
  LISTACAR_COMPLETED_CARRULES: 'Listacar Completed Carrules',
  LISTACAR_COMPLETED_PICTURES: 'Listacar Completed Pictures',
  LISTACAR_COMPLETED_INSURANCE: 'Listacar Completed Insurance',
  LISTACAR_COMPLETED_SUBSCRIPTION: 'Listacar Completed Subscription',
  LISTACAR_COMPLETD_PRICING: 'Listacar Completd Pricing',
  LISTACAR_COMPLETED_NEXTLEVELSHARING: 'Listacar Completed Nextlevelsharing',
  LISTACAR_SENT_FOR_APPROVAL: 'Listacar Sent For Approval',
  LISTACAR_LISTING_APPROVED: 'Listacar Listing Approved',
  LISTACAR_LISTING_REJECTED: 'Listacar Listing Rejected',
  LISTACAR_LISTING_LIVE: 'Listacar Listing Live',
  APP_CLOSED: 'App Closed',
  LOGIN_OPEN_LOGIN_FORM: 'Login Open Login Form',
  /////
  LOGIN_FORGOT_PASSWORD: 'Login Forgot Password',
  LOGING_FORGOT_PASSWORD_SUBMIT: 'Loging Forgot Password Submit',

  APP_UNINSTALLED: 'App Uninstalled',
  PRESS_SEARCH_WITHOUT_LOGIN: 'Press Search Without Login',
  HOST_LEAD_FORM_GO_TO_HOST_DASHBOARD: 'Host Lead Form Go To Host Dashboard',
  FAVOURITES_VIEW_FAVOURITES: 'Favourites View Favourites',
  PROFILE_CLICK_PROFILE: 'Profile Click Profile',
  PROFILE_CLICK_EDIT_PROFILE: 'Profile Click Edit Profile',
  PROFILE_EDIT_PROFILE_SAVE: 'Profile Edit Profile Save',
  PROFILE_CHANGE_PICTURE: 'Profile Change Picture',
  PROFILE_CLICK_DELETE_YOUR_PROFILE: 'Profile Click Delete Your Profile',
  PROFILE_SEND_DELETE_REQUEST: 'Profile Send Delete Request',
  PROFILE_VIEW_NOTIFICATIONS: 'Profile View Notifications',
  PROFILE_VIEW_CONTACT_INFORMATION: 'Profile View Contact Information',
  PROFILE_VIEW_ADDRESS_DETAILS: 'Profile View Address Details',
  PROFILE_CHANGE_PASSWORD: 'Profile Change Password',
  PROFILE_CHANGE_PASSWORD_SAVED: 'Profile Change Password Saved',
  REFER_CLICK_INVITE_NOW: 'Refer Click Invite Now',
  REFER_PRESS_SHARE_ON_SHARE_MODAL: 'Refer Press Share On Share Modal',
  REFER_VIEW_REFER_AND_EARN_PAGE: 'Refer View Refer And Earn Page',
  MILES_VIEW_MILES_CLUB: 'Miles View Miles Club',
  MILES_VIEW_BENEFITS: 'Miles View Benefits',
  MILES_VIEW_FAQ: 'Miles View FAQ',
  MILES_VIEW_TERMS: 'Miles View Terms',
  PAYMENTS_VIEW_PAYMENTS_PAYOUTS: 'Payments View Payments Payouts', //Confirm
  PAYMENTS_VIEW_PAYMENT_METHOD: 'Payments View Payment Method',
  PAYMENTS_SAVED_CARD_DETAILS: 'Payments Saved Card Details',
  PAYMENT_REMOVED_CARD_DETAILS: 'Payment Removed Card Details',
  PAYMENTS_CLICK_HELP_ARTICLE_VIA_PAYMENT_METHOD:
    'Payments Click Help Article Via Payment Method',
  PAYMENTS_VIEW_PAST_PAYMENTS: 'Payments View Past Payments',
  PAYMENTS_PAST_PAYMENT_EXPORT: 'Payments Past Payment Export',
  PAYMENTS_PAST_PAYMENT_VIEW_RECEIPT: 'Payments Past Payment View Receipt',
  PAYMENTS_VIEW_CREDITS: 'Payments View Credits',
  PAYMENTS_VIEW_PAYOUT_PREFERENCES: 'Payments View Payout Preferences',
  PAYMENTS_ADD_PAYOUT_DETAILS: 'Payments Add Payout Details',
  PAYMENTS_EDIT_PAYOUT_DETAILS: 'Payments Edit Payout Details',
  PAYMENTS_PAST_PAYOUTS: 'Payments Past Payouts',
  PAYMENTS_PAST_PAYOUTS_EXPORT: 'Payments Past Payouts Export',
  BROWSE_CLICK_NEED_HELP: 'Browse Click Need Help',
  BROWSE_NEED_HELP_INTERCOM_CHAT: 'Browse Need Help Intercom Chat',
  BROWSE_NEED_HELP_WHATSAPP_CHAT: 'Browse Need Help Whatsapp Chat',
  BROWSE_NEED_HELP_CALL: 'Browse Need Help Call',
  BROWSE_NEED_HELP_EMAIL: 'Browse Need Help Email',
  BROWSE_CLICK_POLICIES: 'Browse Click Policies',
  BROWSE_CLICK_FAQ: 'Browse Click FAQ',
  BROWSE_VIEW_PRIVACY_POLICY: 'Browse View Privacy Policy',
  BROWSE_CLICK_TAKE_APP_TOUR_GUEST: 'Browse Click Take App Tour Guest',
  BROWSE_CLICK_TAKE_APP_TOUR_HOST: 'Browse Click Take App Tour Host',
  BROWSE_CLICK_LOGOUT_HOST: 'Browse Click Logout Host',
  BROWSE_CLICK_LOGOUT_GUEST: 'Browse Click Logout Guest',
  BROWSE_SWITCH_TO_GUEST_MODE: 'Browse Switch To Guest Mode',
  BROWSE_SWITCH_TO_HOST_MODE: 'Browse Switch To Host Mode',
  BROWSE_NAVIGATION_BAR_CLICK: 'Browse Navigation Bar Click',
};

export const event_trigger_ids = {
  SIGN_UP_FORM_OPEN: 'signup_open_form',
  SIGN_UP_EMAIL_FORM_OPEN: 'signup_open_email_form',
  SIGN_UP_SOCIAL_FACEBOOK: 'signup_social_facebook',
  SIGN_UP_SOCIAL_GOOGLE: 'signup_social_google',
  SIGN_UP_COMPLETED_STEP2: 'signup_completed_step_2',
  SIGN_UP_SOCIAL_APPLE: 'signup_social_apple',
  SIGN_UP_VERIFIED_EMAIL: 'signup_verified_email',
  SIGN_UP_VERIFIED_PHONE: 'signup_verified_phone',
  GUEST_VERIFY_OPEN_FORM: 'guestverify_open_driverform',
  GUEST_VERIFY_ADD_CONTACT: 'guestverify_add_contact',
  GUEST_VERIFY_ADD_ADDRESS: 'guestverify_add_address',
  GUEST_VERIFY_Add_Dob: 'guestverify_add_dob',
  GUEST_VERIFY_Add_CARD: 'guestverify_add_creditcard',
  GUEST_VERIFY_COMPLETE_SINGPASS: 'guestverify_complete_singpass',
  GUEST_VERIFY_COMPLETE_ONFIDO: 'guestverify_complete_onfido',
  GUEST_VERIFY_SUBMIT_FORM: 'guestverify_submit_driverform',
  GUEST_VERIFY_DRIVER_VERIFIED: 'guestverify_driver_verified',
  GUEST_VERIFY_DRIVER_DECLINED: 'guestverify_driver_declined',
  SEARCH_EXPLORE: 'search_explore',
  SEARCH_PERFORMED: 'search_performed',
  SEARCH_USED_MAP: 'search_used_map',
  SEARCH_VIEWED_LISTING_GUEST: 'search_viewed_listing_guest',
  SEARCH_CLICK_PIC_LISTING: 'search_clicked_pic_listing',
  SEARCH_USED_MAP_LISTING: 'search_used_map_listing',
  SEARCH_CLICK_SEARCH_BAR: 'search_click_search_bar',
  SEARCH_CLICKED_SEND_ENQUIRY: 'search_clicked_send_enquiry',
  SEARCH_CLICKED_SEND_ENQUIRY_STEP_2: 'search_clicked_sendenquiry_step_2',
  SEARCH_SENT_ENQUIRY_GUEST: 'search_sent_enquiry_guest',
  SEARCH_VIEWED_PROFILE_GUEST: 'search_viewed_profile_guest',
  SEARCH_SHARED_LISTING_GUEST: 'search_shared_listing_guest',
  SEARCH_FAVOURITE_LISTING_GUEST: 'search_favourite_listing_guest',
  SEARCH_READ_INSURANCE: 'search_read_insurance',
  SEARCH_OPEN_CALENDAR: 'search_open_calendar',
  SEARCH_SET_TRIP_DATES: 'search_set_trip_dates',
  SEARCH_SET_TRIP_DATES_ERROR: 'search_set_trip_dates_error',
  SEARCH_PRESSED_BOOK_GUEST: 'search_pressed_book_guest',
  SEARCH_PRESSED_BOOK_GUEST_ERROR: 'search_pressed_book_guest_error',
  SEARCH_VIEWED_LISTING_HOST: 'search_viewed_listing_host',
  SEARCH_RECIEVED_ENQUIRY_HOST: 'search_recieved_enquiry_host',
  SEARCH_PRESSED_BOOK_HOST: 'search_pressed_book_host',
  BOOK_PRESSED_CONTINUE_ADDONS_STEP: 'book_pressed_continue_add-ons_step',
  BOOK_DELIVERY_ADDON_ADDED: 'book_delivery_addon_added',
  BOOK_DELIVERY_ADDON_REMOVED: 'book_delivery_addon_removed',
  BOOK_DELIVERY_SUCCESS_GUEST: 'book_delivery_success_guest',
  BOOK_EXCESS_ADDON_ADDED: 'book_excess_addon_added',
  BOOK_EXCESS_ADDON_REMOVED: 'book_excess_addon_removed',
  BOOK_EXCESS_SUCCESS_GUEST: 'book_excess_success_guest',
  BOOK_EXCESS_2_ADDON_ADDED: 'book_excess_2_addon_added',
  BOOK_EXCESS_2_ADDON_REMOVED: 'book_excess_2_addon_removed',
  BOOK_EXCESS_2_SUCCESS_GUEST: 'book_excess_2_success_guest',
  BOOK_FUEL_ADDON_ADDED: 'book_fuel_addon_added',
  BOOK_FUEL_ADDON_REMOVED: 'book_fuel_addon_removed',
  BOOK_FUEL_SUCCESS_GUEST: 'book_fuel_success_guest',
  BOOK_MILEAGE_ADDON_ADDED: 'book_mileage_addon_added',
  BOOK_MILEAGE_ADDON_REMOVED: 'book_mileage_addon_removed',
  BOOK_MILEAGE_SUCCESS_GUEST: 'book_mileage_success_guest',
  BOOK_ADD_NEW_CARD: 'book_add_new_card',
  BOOK_ENTERED_CARD_DETAILS: 'book_entered_card_details',
  BOOK_SAVED_CARD_DETAILS: 'book_saved_card_details',
  BOOK_USED_PRESAVEDDETAILS: 'book_used_presaveddetails',
  BOOK_USED_PROMO_CODE: 'book_used_promo_code',
  BOOK_USED_PROMO_CODE_ERROR: 'book_used_promo_code_error',
  BOOK_USED_CREDITS: 'book_used_credits',
  BOOK_CREATED_REQUEST_GUEST: 'book_created_request_guest',
  BOOK_SENT_REQUEST_SUCCESS_GUEST: 'book_sent_request_success_guest',
  BOOK_RECEIVED_REQUEST_SUCCESS_HOST: 'book_received_request_success_host',
  BOOK_CREATED_REQUEST_FAIL_GUEST: 'book_created_request_fail_guest',
  BOOK_CREATED_REQUEST_FAIL_HOST: 'book_created_request_fail_host',
  BOOK_REQUEST_ACCEPTED_GUEST: 'book_request_accepted_guest',
  BOOK_REQUEST_ACCEPTED_HOST: 'book_request_accepted_host',
  BOOK_CANCELLED_GUEST_BY_GUEST: 'book_cancelled_guest_by_guest',
  BOOK_CANCELLED_HOST_BY_GUEST_: 'book_cancelled_host_by_guest_',
  BOOK_CANCELLED_GUEST_BY_HOST: 'book_cancelled_guest_by_host',
  BOOK_CANCELLED_HOST_BY_HOST: 'book_cancelled_host_by_host',
  BOOK_REQUEST_DECLINED_GUEST: 'book_request_declined_guest',
  BOOK_REQUEST_DECLINED_HOST: 'book_request_declined_host',
  BOOK_REQUEST_EXPIRED_GUEST: 'book_request_expired_guest',
  BOOK_REQUEST_EXPIRED_HOST: 'book_request_expired_host',
  BOOK_CREATED_MODIFICATION_GUEST: 'book_created_modification_guest',
  BOOK_SENT_MODIFICATION_SUCCESS_GUEST: 'book_sent_modification_success_guest',
  BOOK_RECEIVED_MODIFICATION_SUCCESS_HOST:
    'book_received_modification_success_host',
  BOOK_MODIFICATION_ACCEPTED_GUEST: 'book_modification_accepted_guest',
  BOOK_MODIFICATION_ACCEPTED_HOST: 'book_modification_accepted_host',
  BOOK_MODIFICATION_DECLINED_GUEST: 'book_modification_declined_guest',
  BOOK_MODIFICATION_DECLINED_HOST: 'book_modification_declined_host',
  BOOK_MODIFICATION_EXPIRED_GUEST: 'book_modification_expired_guest',
  BOOK_MODIFICATION_EXPIRED_HOST: 'book_modification_expired_host',
  BOOK_FUEL_CHARGE_ATTEMPTED_GUEST: 'book_fuel_charge_attempted_guest',
  BOOK_FUEL_CHARGE_FAIL_GUEST: 'book_fuel_charge_fail_guest',
  BOOK_FUEL_CHARGE_SUCCESS_GUEST: 'book_fuel_charge_success_guest',
  REVENUE: 'revenue',
  TRIP_START_GUEST_BY_ADMIN: 'trip_start_guest_by_admin',
  TRIP_START_HOST_BY_ADMIN: 'trip_start_host_by_admin',
  TRIP_PICKUP_REQUEST_SENT: 'trip_pickup_request_sent',
  TRIP_PICKUP_REQUEST_RECEIVED: 'trip_pickup_request_received',
  TRIP_PICKUP_REQUEST_CONFIRM: 'trip_pickup_request_confirm',
  TRIP_PICKUP_REQUEST_CONFIRM_GUEST: 'trip_pickup_request_confirm_guest',
  TRIP_DROPOFF_REQUEST_SENT: 'trip_dropoff_request_sent',
  TRIP_DROPOFF_REQUEST_RECEIVED: 'trip_dropoff_request_received',
  TRIP_DROPOFF_REQUEST_CONFIRM: 'trip_dropoff_request_confirm',
  TRIP_DROPOFF_REQUEST_CONFIRM_GUEST: 'trip_dropoff_request_confirm_guest',
  TRIP_DISPUTED_HOST: 'trip_disputed_host',
  TRIP_DISPUTED_GUEST: 'trip_disputed_guest',
  TRIP_HOST_REVIEW_BY_HOST: 'trip_host_review_by_host',
  TRIP_HOST_REVIEW_BY_GUEST: 'trip_host_review_by_guest',
  TRIP_GUEST_REVIEW_BY_GUEST: 'trip_guest_review_by_guest',
  TRIP_GUEST_REVIEW_BY_HOST: 'trip_guest_review_by_host',
  TRIP_EXPIREREVIEW_HOST: 'trip_expirereview_host',
  TRIP_EXPIREREVIEW_GUEST: 'trip_expirereview_guest',
  TRIP_AUTOCOMPLETE_HOST: 'trip_autocomplete_host',
  TRIP_AUTOCOMPLETED_GUEST: 'trip_autocompleted_guest',
  TRIP_END_GUEST_BY_ADMIN: 'trip_end_guest_by_admin',
  TRIP_END_HOST_BY_ADMIN: 'trip_end_host_by_admin',
  TRIP_MESSAGESENT_HOST: 'trip_messagesent_host',
  TRIP_MESSAGESENT_GUEST: 'trip_messagesent_guest',
  TRIP_VIEW_MODIFICATION_PAGE_GUEST: 'trip_view_modification_page_guest',
  TRIP_VIEW_ADD_ONS_GUEST: 'trip_view_add_ons_guest',
  TRIP_EXCESS_ADDON_ADDED: 'trip_excess_addon_added',
  TRIP_EXCESS_ADDON_REMOVED: 'trip_excess_addon_removed',
  TRIP_EXCESS_2_ADDON_ADDED: 'trip_excess_2_addon_added',
  TRIP_EXCESS_2_ADDON_REMOVED: 'trip_excess_2_addon_removed',
  TRIP_FUEL_ADDON_ADDED: 'trip_fuel_addon_added',
  TRIP_FUEL_ADDON_REMOVED: 'trip_fuel_addon_removed',
  TRIP_MILEAGE_ADDON_ADDED: 'trip_mileage_addon_added',
  TRIP_MILEAGE_ADDON_REMOVED: 'trip_mileage_addon_removed',
  TRIP_PRESS_PAY_ADD_ONS_GUEST: 'trip_press_pay_add_ons_guest',
  TRIP_VIEW_FAQ_GUEST: 'trip_view_faq_guest',
  TRIP_VIEW_PRICE_BREAKDOWN_GUEST: 'trip_view_price_breakdown_guest',
  TRIP_VIEW_IMPORTANT_DOCUMENTS_GUEST: 'trip_view_important_documents_guest',
  TRIP_VIEW_CANCEL_GUEST: 'trip_view_cancel_guest',
  TRIP_VIEW_DETAILS_TAB_HOST: 'trip_view_details_tab_host',
  TRIP_VIEW_MESSAGES_TAB_HOST: 'trip_view_messages_tab_host',
  TRIP_VIEW_HELP_TAB_HOST: 'trip_view_help_tab_host',
  TRIP_VIEW_FAQ_HOST: 'trip_view_faq_host',
  TRIP_VIEW_PRICE_BREAKDOWN_HOST: 'trip_view_price_breakdown_host',
  TRIP_VIEW_IMPORTANT_DOCUMENTS_HOST: 'trip_view_important_documents_host',
  TRIP_VIEW_CANCEL_HOST: 'trip_view_cancel_host',
  HOSTVERIFY_OPEN_FORM: 'hostverify_open_form',
  HOSTVERIFY_ADD_CONTACT_DETAILS: 'hostverify_add_contact_details',
  HOSTVERIFY_ADD_ADDRESS: 'hostverify_add_address',
  HOSTVERIFY_ADD_DOB: 'hostverify_add_dob',
  HOSTVERIFY_COMPLETE_ONFIDO: 'hostverify_complete_onfido',
  HOSTVERIFY_SUBMIT_VERIFICATION: 'hostverify_submit_verification',
  HOSTVERIFY_STRIPE_SUCCESS: 'hostverify_stripe_success',
  HOSTVERIFY_STRIPE_FAILURE: 'hostverify_stripe_failure',
  HOSTVERIFY_HOST_APPROVED: 'hostverify_host_approved',
  HOSTVERIFY_HOST_REJECTED: 'hostverify_host_rejected',
  HOSTVERIFY_HOST_ONBOARDED: 'hostverify_host_onboarded',
  LISTACAR_CLICKED_LIST_A_CAR: 'listacar_clicked_list_a_car',
  LISTACAR_CREATED_DRAFT: 'listacar_created_draft',
  LISTACAR_ABANDON_FORM: 'listacar_abandon_form',
  LISTACAR_COMPLETED_CARDETAILS: 'listacar_completed_cardetails',
  LISTACAR_COMPLETED_LOCATION: 'listacar_completed_location',
  LISTACAR_COMPLETED_CARFEATURES: 'listacar_completed_carfeatures',
  LISTACAR_COMPLETED_CARRULES: 'listacar_completed_carrules',
  LISTACAR_COMPLETED_PICTURES: 'listacar_completed_pictures',
  LISTACAR_COMPLETED_INSURANCE: 'listacar_completed_insurance',
  LISTACAR_COMPLETED_SUBSCRIPTION: 'listacar_completed_subscription',
  LISTACAR_COMPLETD_PRICING: 'listacar_completd_pricing',
  LISTACAR_COMPLETED_NEXTLEVELSHARING: 'listacar_completed_nextlevelsharing',
  LISTACAR_SENT_FOR_APPROVAL: 'listacar_sent_for_approval',
  LISTACAR_LISTING_APPROVED: 'listacar_listing_approved',
  LISTACAR_LISTING_REJECTED: 'listacar_listing_rejected',
  LISTACAR_LISTING_LIVE: 'listacar_listing_live',
  ////from here
  APP_OPENED: 'app_opened',
  APP_CLOSED: 'app_closed',
  LOGIN_OPEN_LOGIN_FORM: 'login_open_login_form',
  LOGIN_FORGOT_PASSWORD: 'login_forgot_password',
  LOGING_FORGOT_PASSWORD_SUBMIT: 'loging_forgot_password_submit',
  LOGGED_IN: 'logged_in',
  LOGGED_OUT: 'logged_out',
  APP_UNINSTALLED: 'app_uninstalled',
  PRESS_SEARCH_WITHOUT_LOGIN: 'press_search_without_login',
  HOST_LEAD_FORM_OPEN: 'host_lead_form_open',
  HOST_LEAD_FORM_SUBMITTED: 'host_lead_form_submitted',
  HOST_LEAD_FORM_GO_TO_HOST_DASHBOARD: 'host_lead_form_go_to_host_dashboard',
  FAVOURITES_ADDED_TO_FAVOURITES: 'favourites_added_to_favourites',
  FAVOURITES_REMOVED_FROM_FAVOURITES: 'favourites_removed_from_favourites',
  FAVOURITES_VIEW_FAVOURITES: 'favourites_view_favourites',
  BROWSE_CLICK_HOW_IT_WORKS: 'browse_click_how_it_works',
  PROFILE_CLICK_PROFILE: 'profile_click_profile',
  PROFILE_CLICK_EDIT_PROFILE: 'profile_click_edit_profile',
  PROFILE_EDIT_PROFILE_SAVE: 'profile_edit_profile_save',
  PROFILE_CHANGE_PICTURE: 'profile_change_picture',
  PROFILE_CLICK_DELETE_YOUR_PROFILE: 'profile_click_delete_your_profile',
  PROFILE_SEND_DELETE_REQUEST: 'profile_send_delete_request',
  PROFILE_VIEW_NOTIFICATIONS: 'profile_view_notifications',
  PROFILE_VIEW_CONTACT_INFORMATION: 'profile_view_contact_information',
  PROFILE_VIEW_ADDRESS_DETAILS: 'profile_view_address_details',
  PROFILE_CHANGE_PASSWORD: 'profile_change_password',
  PROFILE_CHANGE_PASSWORD_SAVED: 'profile_change_password_saved',
  REFER_CLICK_INVITE_NOW: 'refer_click_invite_now',
  REFER_PRESS_SHARE_ON_SHARE_MODAL: 'refer_press_share_on_share_modal',
  REFER_VIEW_REFER_AND_EARN_PAGE: 'refer_view_refer_and_earn_page',
  MILES_VIEW_MILES_CLUB: 'miles_view_miles_club',
  MILES_VIEW_BENEFITS: 'miles_view_benefits',
  MILES_VIEW_FAQ: 'miles_view_faq',
  MILES_VIEW_TERMS: 'miles_view_terms',
  PAYMENTS_VIEW_PAYMENTS_PAYOUTS: 'payments_view_payments_payouts',
  PAYMENTS_VIEW_PAYMENT_METHOD: 'payments_view_payment_method',
  PAYMENTS_SAVED_CARD_DETAILS: 'payments_saved_card_details',
  PAYMENT_REMOVED_CARD_DETAILS: 'payment_removed_card_details',
  PAYMENTS_CLICK_HELP_ARTICLE_VIA_PAYMENT_METHOD:
    'payments_click_help_article_via_payment_method',
  PAYMENTS_VIEW_PAST_PAYMENTS: 'payments_view_past_payments',
  PAYMENTS_PAST_PAYMENT_EXPORT: 'payments_past_payment_export',
  PAYMENTS_PAST_PAYMENT_VIEW_RECEIPT: 'payments_past_payment_view_receipt',
  PAYMENTS_VIEW_CREDITS: 'payments_view_credits',
  PAYMENTS_VIEW_PAYOUT_PREFERENCES: 'payments_view_payout_preferences',
  PAYMENTS_ADD_PAYOUT_DETAILS: 'payments_add_payout_details',
  PAYMENTS_EDIT_PAYOUT_DETAILS: 'payments_edit_payout_details',
  PAYMENTS_PAST_PAYOUTS: 'payments_past_payouts',
  PAYMENTS_PAST_PAYOUTS_EXPORT: 'payments_past_payouts_export',
  BROWSE_CLICK_NEED_HELP: 'browse_click_need_help',
  BROWSE_NEED_HELP_INTERCOM_CHAT: 'browse_need_help_intercom_chat',
  BROWSE_NEED_HELP_WHATSAPP_CHAT: 'browse_need_help_whatsapp_chat',
  BROWSE_NEED_HELP_CALL: 'browse_need_help_call',
  BROWSE_NEED_HELP_EMAIL: 'browse_need_help_email',
  BROWSE_CLICK_POLICIES: 'browse_click_policies',
  BROWSE_CLICK_FAQ: 'browse_click_faq',
  BROWSE_VIEW_PRIVACY_POLICY: 'browse_view_privacy_policy',
  BROWSE_CLICK_TAKE_APP_TOUR_GUEST: 'browse_click_take_app_tour_guest',
  BROWSE_CLICK_TAKE_APP_TOUR_HOST: 'browse_click_take_app_tour_host',
  BROWSE_CLICK_LOGOUT_HOST: 'browse_click_logout_host',
  BROWSE_CLICK_LOGOUT_GUEST: 'browse_click_logout_guest',
  BROWSE_SWITCH_TO_GUEST_MODE: 'browse_switch_to_guest_mode',
  BROWSE_SWITCH_TO_HOST_MODE: 'browse_switch_to_host_mode',
  BROWSE_NAVIGATION_BAR_CLICK: 'browse_navigation_bar_click',
  EXPERIMENT_NORMALISED_ATTRACTIVENESS_EXPERIMENT_A: 'experiment_normalised_attractiveness_experiment_case_a',
  EXPERIMENT_NORMALISED_ATTRACTIVENESS_EXPERIMENT_B: 'experiment_normalised_attractiveness_experiment_case_b',
};

export const UI_PROPERTY_CATEGORY = 'ui_category';
export const UI_PROPERTY_PAGE = 'ui_page';
export const UI_PROPERTY_BUTTON_LABEL = 'ui_button';



export const DEFAULT_UI = {
  page: '',
  category: '',
  button: '',
};
