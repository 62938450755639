import React, { Component } from 'react';
import { Modal } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';

import videoLogo from '../../assets/landingpage/videoLogo.png';
import css from './BAGHowItWork.css';

const ModalVideo = props => {
  return (
    <div className={css.videoContentWrapper}>
      <iframe
        id="videoIframe"
        className={css.iframeVideo}
        allowFullScreen={true}
        src="https://www.youtube.com/embed/OaOzjZiVaws">
      </iframe>
    </div>
  )
}

class BAGHowItWord extends Component {
  constructor(props) {
    super(props)
    this.state = { isOpen: false };
    this.handleOpen = this.handleOpen.bind(this);
  }

  handleOpen() {
    this.setState({ isOpen: true })
  }

  render() {
    const {
      onManageDisableScrolling
    } = this.props;

    return (
      <section className={css.howItWordSection}>
        <div className={css.innerWrapper}>
          <div className={css.textBox}>
            <h3 className={css.title}>
              <FormattedMessage id="BecomeAGuestPage.howItWork" values={{ newline: (<br />) }} />
            </h3>
            <div className={css.description}>
              <FormattedMessage id="BecomeAGuestPage.howItWorkDesc" values={{ newline: (<br />) }} />
            </div>
          </div>
          <div className={css.videoWrapper}>
            <div
              onClick={this.handleOpen}
              className={css.video}
            >
              <img className={css.videoLogo} src={videoLogo} alt="Why rent a car for a day from Drive lah?" />
            </div>
          </div>
          <Modal
            id="videoLandingpage"
            {...this.props}
            containerClassName={css.modalContainer}
            closeButtonClassName={css.modalCloseButton}
            contentClassName={css.contentModal}
            isOpen={this.state.isOpen}
            onClose={() => {
              const videoIframe = document.querySelector("#videoIframe");
              if (videoIframe) {
                const iframeSrc = videoIframe.src;
                videoIframe.src = iframeSrc;
              }
              this.setState({ isOpen: false });
            }}
            onManageDisableScrolling={onManageDisableScrolling}
          >
            <ModalVideo />
          </Modal>
        </div>
      </section>
    );
  }
}

export default BAGHowItWord;
