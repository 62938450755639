import React from 'react';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { arrayOf, string } from 'prop-types';
import classNames from 'classnames';
import { Avatar, UserDisplayName } from '../../components';
import { propTypes } from '../../util/types';
import arrowDown from '../../assets/arrow-down-blue.svg';
import css from './LongTermListingReviews.css';
import Slider from 'react-slick';

const SMALL_MOBILE_SCREEN_WIDTH = 550;
const MEDIUM_SCREEN_WIDTH = 768;
const LARGE_SCREEN_WIDTH = 1025;

const LongTermListingReview = props => {
  const { review, intl } = props;

  const date = review.attributes.createdAt;
  const dateString = intl.formatDate(date, { month: 'long', year: 'numeric' });
  return (
    <div className={css.review}>
      <Avatar className={css.avatar} user={review.author} />
      <div className={css.reviewContentAuthorWrapper}>
        <div className={css.authorName}>
          <UserDisplayName user={review.author} intl={intl} />
        </div>
        <div className={css.reviewDate}>
          <span className={css.listingReview}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18.806"
              height="18"
              viewBox="0 0 18.806 18"
            >
              <path
                id="star-solid"
                d="M28.894.613,26.6,5.268l-5.136.749a1.125,1.125,0,0,0-.622,1.919l3.716,3.621-.879,5.115a1.124,1.124,0,0,0,1.631,1.185L29.9,15.441,34.5,17.855a1.125,1.125,0,0,0,1.631-1.185l-.879-5.115,3.716-3.621a1.125,1.125,0,0,0-.622-1.919l-5.136-.749L30.912.613a1.126,1.126,0,0,0-2.018,0Z"
                transform="translate(-20.5 0.013)"
                fill="#FCC314"
              />
            </svg>
            {review.attributes.rating}
          </span>
          {/* <ReviewRating
            rating={review.attributes.rating}
            className={css.mobileReviewRating}
            reviewStarClassName={css.reviewRatingStar}
          /> */}
          <span className={css.separator}>•</span>
          {dateString}
        </div>
      </div>
      <div className={css.reviewContentWrapper}>
        <p className={css.reviewContent}>
          Good and fuel efficient car. Alice is a Responsible host. Very accommodating and
          understanding. Will rent again...
        </p>
        {/* <p className={css.reviewInfo}>
          <span className={css.desktopSeparator}>•</span>
          <span className={css.desktopReviewRatingWrapper}>
            <ReviewRating
              rating={review.attributes.rating}
              className={css.desktopReviewRating}
              reviewStarClassName={css.reviewRatingStar}
            />
          </span>
        </p> */}
      </div>
    </div>
  );
};

LongTermListingReview.propTypes = {
  review: propTypes.review.isRequired,
  intl: intlShape.isRequired,
};

const LongTermListingReviewsComponent = props => {
  const { className, rootClassName, reviews, intl, viewport } = props;
  const classes = classNames(rootClassName || css.root, className);

  const getSlidesToShow = () => {
    if (viewport.width <= SMALL_MOBILE_SCREEN_WIDTH) return 1.25;
    if (viewport.width <= MEDIUM_SCREEN_WIDTH) return 2;
    return 3;
  };

  const prevArrow = (
    <img
      width="30px"
      height="100px"
      style="background-color: black"
      src='data:image/svg+xml;utf8,<svg width="7" height="10" viewBox="0 0 7 10" xmlns="http://www.w3.org/2000/svg"><path d="M5.667 1c.094 0 .188.04.254.118.12.14.102.35-.038.47L1.85 5l4.032 3.412c.14.12.158.33.04.47-.12.14-.33.158-.47.04L1.117 5.254C1.043 5.19 1 5.098 1 5s.043-.19.118-.255L5.45 1.08c.064-.054.14-.08.217-.08" stroke="%23FFF" fill="%23FFF" fill-rule="evenodd"/></svg>'
    />
  );
  const nextArrow = (
    <img
      width="30px"
      height="100px"
      style="background-color: black"
      src='data:image/svg+xml;utf8,<svg width="7" height="10" viewBox="0 0 7 10" xmlns="http://www.w3.org/2000/svg"><path d="M1.333 9c-.094 0-.188-.04-.254-.118-.12-.14-.102-.35.038-.47L5.15 5 1.118 1.588c-.14-.12-.158-.33-.04-.47.12-.14.33-.158.47-.04l4.334 3.667C5.957 4.81 6 4.902 6 5s-.043.19-.118.255L1.55 8.92c-.064.054-.14.08-.217.08" stroke="%23FFF" fill="%23FFF" fill-rule="evenodd"/></svg>'
    />
  );

  const settings = {
    infinite: false,
    speed: 500,
    dots: false,
    slidesToShow: getSlidesToShow(),
    slidesToScroll: 2.25,
    arrows: viewport.width > LARGE_SCREEN_WIDTH,
    nextArrow,
    prevArrow,
    // centerMode: viewport.width < MEDIUM_SCREEN_WIDTH
  };

  return (
    <ul className={classes}>
      <Slider {...settings} className={css.slider}>
        {reviews.map(r => {
          return (
            <li key={`Review_${r.id.uuid}`} className={css.reviewItem}>
              <div className={css.reviewItemWrapper}>
                <LongTermListingReview review={r} intl={intl} />
                <div className={css.readMoreButton}>
                  Read more <img src={arrowDown} alt="Right Tick Icon" />
                </div>
              </div>
            </li>
          );
        })}
      </Slider>
    </ul>
  );
};

LongTermListingReviewsComponent.defaultProps = {
  className: null,
  rootClassName: null,
  reviews: [],
};

LongTermListingReviewsComponent.propTypes = {
  className: string,
  rootCalssName: string,
  reviews: arrayOf(propTypes.review),

  // from injectIntl
  intl: intlShape.isRequired,
};

const LongTermListingReviews = injectIntl(LongTermListingReviewsComponent);

export default LongTermListingReviews;
