import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withViewport } from '../../util/contextHelpers';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { injectIntl, intlShape } from '../../util/reactIntl';
import config from '../../config';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  LoginModal,
  Page,
} from '../../components';
import { TopbarContainer } from '../../containers';
import SectionHero from './SectionHero';
import SectionIntroducing from './SectionIntroducing';
import SectionVision from './SectionVision';
import SectionDirectAccess from './SectionDirectAccess';

import facebookImage from '../../assets/facebook_drivelah_2.jpg';
import twitterImage from '../../assets/facebook_drivelah_2.jpg';
import css from './LandingPageGo.css';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { login } from '../../ducks/Auth.duck';

export const LandingPageComponent = props => {
  const { history, intl, scrollingDisabled,
    currentUser, isAuthenticated, onManageDisableScrolling, onLogin, loginError, loginInProgress } = props;

  const [showLoginModal, setShowLoginModal] = useState(false);
  const [isGoToHost, setIsGoToHost] = useState(false);

  useEffect(() => {
    return () => {
      setShowLoginModal(false);
    }
  }, []);
  useEffect(() => {
    if (!!(currentUser && currentUser.id && isAuthenticated) && isGoToHost) {
      const routes = routeConfiguration();
      const url = createResourceLocatorString('LandingPageGoHost', routes);
      history.push(url);
    }
  })

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitleGo' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescriptionGo' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  const isLogined = !!(currentUser && currentUser.id && isAuthenticated);

  const handleLearnMore = () => {
    if (isLogined) {
      const routes = routeConfiguration();
      const url = createResourceLocatorString('LandingPageGoHost', routes);
      history.push(url);
    } else {
      setShowLoginModal(true);
    }
  }

  const handleLogin = values => {
    const { email, password } = values;
    setIsGoToHost(true);
    return onLogin(email, password);
  }

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={[
        {
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          description: schemaDescription,
          name: schemaTitle,
          image: [schemaImage],
        },
        {
          '@context': 'https://schema.org',
          '@type': ['VideoObject'],
          'name': 'See how Drive lah works',
          'description': 'Drive lah makes it simple and secure to rent cars from real people. Watch our short how it works video.',
          'contentUrl': 'https://www.youtube.com/watch?v=OaOzjZiVaws&ab_channel=DrivelahCarRental%26CarSharinginSingapore',
          'thumbnailUrl': [
            'https://www.drivelah.sg/static/media/videoLogo.6f135bad.png',
          ],
          "uploadDate": "2019-05-24T08:00:00+08:00",
        },
      ]}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <SectionHero />
          <SectionIntroducing />
          <SectionVision
            handleLearnMore={handleLearnMore}
          />
          <SectionDirectAccess />
          <LoginModal
            onManageDisableScrolling={onManageDisableScrolling}
            isOpen={showLoginModal}
            onClose={() => setShowLoginModal(false)}
            handleSubmit={handleLogin}
            loginError={loginError}
            loginInProgress={loginInProgress}
          />
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool, object } = PropTypes;

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const { isAuthenticated, loginError, loginInProgress } = state.Auth;

  return {
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
    isAuthenticated,
    loginError,
    loginInProgress
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onLogin: (email, password) => dispatch(login(email, password))
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withViewport,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(LandingPageComponent);

export default LandingPage;
