import React, { Fragment, useEffect, useRef, useState } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import get from 'lodash/get';
import {
  FieldCheckbox,
  FieldRadioButton,
  Form,
  PrimaryButton,
  SavedCardDetails,
} from '../../components';
import config from '../../config';
import { ensurePaymentMethodCard } from '../../util/data';
import css from './TripDonationPanelForm.css';
import classNames from 'classnames';
import tripCss from './../../components/TripPanel/TripPanel.css';

const stripeElementsOptions = {
  fonts: [
    {
      family: 'sofiapro',
      fontSmoothing: 'antialiased',
      src:
        'local("sofiapro"), local("SofiaPro"), local("Sofia Pro"), url("https://assets-sharetribecom.sharetribe.com/webfonts/sofiapro/sofiapro-medium-webfont.woff2") format("woff2")',
    },
  ],
};

const cardStyles = {
  base: {
    fontFamily: '"sofiapro", Helvetica, Arial, sans-serif',
    fontSize: '18px',
    fontSmoothing: 'antialiased',
    lineHeight: '24px',
    letterSpacing: '-0.1px',
    borderBottom: 'solid 2px red',
    color: '#4A4A4A',
    '::placeholder': {
      color: '#B2B2B2',
    },
  },
};

const stripeErrorTranslation = (intl, stripeError) => {
  const { message, code, type } = stripeError;

  if (!code || !type) {
    return intl.formatMessage({ id: 'StripePaymentForm.genericError' });
  }

  const translationId =
    type === 'validation_error'
      ? `StripePaymentForm.stripe.validation_error.${code}`
      : `StripePaymentForm.stripe.${type}`;

  return intl.formatMessage({
    id: translationId,
    defaultMessage: message,
  });
};

const getPaymentMethod = (selectedPaymentMethod, hasDefaultPaymentMethod) => {
  return selectedPaymentMethod == null && hasDefaultPaymentMethod
    ? 'defaultCard'
    : selectedPaymentMethod == null
    ? 'onetimeCardPayment'
    : selectedPaymentMethod;
};

const OneTimePaymentWithCardElement = props => {
  const { formId, handleStripeElementRef, hasCardError, error, label } = props;
  return (
    <React.Fragment>
      <div className={css.card} id={`${formId}-card`} ref={handleStripeElementRef} />
      {hasCardError ? <span className={css.error}>{error}</span> : null}
      {label ? (
        <div className={css.saveForLaterUse}>
          <FieldCheckbox
            className={css.saveForLaterUseCheckbox}
            textClassName={css.saveForLaterUseLabel}
            id="replaceCurrentCard"
            name="replaceCurrentCard"
            label={label}
            useSuccessColor
          />
        </div>
      ) : null}
      {
        // <div className={css.smallText}>
        //   This payment method will be used for applicable monthly subscription fees
        // </div>
      }
    </React.Fragment>
  );
};

const PaymentMethodSelector = props => {
  const {
    cardClasses,
    formId,
    changePaymentMethod,
    defaultPaymentMethod,
    handleStripeElementRef,
    hasCardError,
    currentUser,
    error,
    paymentMethod,
    intl,
  } = props;
  const last4Digits = defaultPaymentMethod.attributes.card.last4Digits;
  const labelText = intl.formatMessage(
    { id: 'PaymentDepositForm.replaceAfterOnetimePayment' },
    { last4Digits }
  );

  return (
    <React.Fragment>
      <h3 className={css.paymentHeading}>
        <FormattedMessage id="StripePaymentForm.payWithHeading" />
      </h3>
      <SavedCardDetails
        className={css.paymentMethodSelector}
        currentUser={currentUser}
        card={defaultPaymentMethod.attributes.card}
        onChange={changePaymentMethod}
      />
      {/* {paymentMethod !== 'replaceCard' ? (
        <div className={css.smallText}>
          This payment method will be used for applicable monthly subscription fees
        </div>
      ) : null} */}
      {paymentMethod === 'replaceCard' ? (
        <OneTimePaymentWithCardElement
          cardClasses={cardClasses}
          formId={formId}
          handleStripeElementRef={handleStripeElementRef}
          hasCardError={hasCardError}
          error={error}
          label={labelText}
          intl={intl}
        />
      ) : null}
    </React.Fragment>
  );
};

const TripDonationPanelForm = props => {
  const {
    onSubmit,
    inProgress,
    initiateOrderError,
    defaultPaymentMethod,
    currentUser,
    intl,
    handleGoBack,
    transactInModal,
    onOpenTermAndConditions,
    isDonationPaymentSuccessful,
  } = props;

  const [paymentMethod, setPaymentMethod] = useState(null);
  const [state, setState] = useState({});
  const [selectAmount, setSelectedAmount] = useState(1);
  const cardContainerRef = useRef(null);
  const cardRef = useRef(null);
  const stripeRef = useRef(null);

  useEffect(() => {
    if (!window.Stripe) {
      throw new Error('Stripe must be loaded for StripePaymentForm');
    }

    if (config.stripe.publishableKey) {
      const { hasHandledCardPayment, defaultPaymentMethod } = props;
      stripeRef.current = window.Stripe(config.stripe.publishableKey);

      // if (!(hasHandledCardPayment || defaultPaymentMethod )) {
      //   this.initializeStripeElement();
      // }
    }
    return () => {
      if (cardRef.current) {
        cardRef.current.removeEventListener('change', handleCardValueChange);
        cardRef.current.unmount();
      }
    };
  }, []);

  const ensuredDefaultPaymentMethod = ensurePaymentMethodCard(defaultPaymentMethod);
  const showPaymentMethodSelector = ensuredDefaultPaymentMethod.id;
  const selectedPaymentMethod = getPaymentMethod(paymentMethod, showPaymentMethodSelector);

  const showOnetimePaymentFields = ['onetimeCardPayment', 'replaceCard'].includes(
    selectedPaymentMethod
  );

  const selectViroQLabel = (
    <FormattedMessage id={'PaymentCovidForm.SelectViroQ.selectViroQTitle'} />
  );
  const paymentViroQTitle = (
    <FormattedMessage id={'PaymentCovidForm.SelectViroQ.paymentViroQTitle'} />
  );
  const selectViroQDescription = (
    <FormattedMessage id={'PaymentCovidForm.SelectViroQ.selectViroQDescription'} />
  );
  const optionOneLabel1 = <FormattedHTMLMessage id={'PaymentDonationForm.SelectViroQ.option1'} />;
  const optionOneLabel2 = <FormattedHTMLMessage id={'PaymentDonationForm.SelectViroQ.option2'} />;
  const optionOneLabel3 = <FormattedHTMLMessage id={'PaymentDonationForm.SelectViroQ.option3'} />;
  const optionOneLabel4 = <FormattedHTMLMessage id={'PaymentDonationForm.SelectViroQ.option4'} />;
  const optionOneLabel5 = <FormattedHTMLMessage id={'PaymentDonationForm.SelectViroQ.option5'} />;
  const optionOneLabel6 = <FormattedHTMLMessage id={'PaymentDonationForm.SelectViroQ.option6'} />;

  const handleCardValueChange = event => {
    const { intl } = props;
    const { error, complete } = event;

    setState(prevState => {
      return {
        ...prevState,
        error: error ? stripeErrorTranslation(intl, error) : null,
        cardValueValid: complete,
      };
    });
  };

  const setupStripe = () => {
    if (!window.Stripe) {
      throw new Error('Stripe must be loaded for StripePaymentForm');
    }

    if (config.stripe.publishableKey && !stripeRef.current) {
      stripeRef.current = window.Stripe(config.stripe.publishableKey);
    }
  };

  const initializeStripeElement = element => {
    if (!cardRef.current) {
      const elements = stripeRef.current.elements(stripeElementsOptions);
      cardRef.current = elements.create('card', { style: cardStyles });
      cardRef.current.mount(element || cardContainerRef.current);
      cardRef.current.addEventListener('change', handleCardValueChange);
      // EventListener is the only way to simulate breakpoints with Stripe.
      window.addEventListener('resize', () => {
        if (cardRef.current) {
          if (window.innerWidth < 1024) {
            cardRef.current.update({ style: { base: { fontSize: '18px', lineHeight: '24px' } } });
          } else {
            cardRef.current.update({ style: { base: { fontSize: '24px', lineHeight: '29px' } } });
          }
        }
      });
    }
  };

  const handleStripeElementRef = el => {
    cardContainerRef.current = el;
    setupStripe();
    if (stripeRef.current && el) {
      initializeStripeElement(el);
    }
  };

  const handleSubmit = values => {
    onSubmit({
      ...values,
      card: cardRef.current,
      stripe: stripeRef.current,
      paymentMethod: selectedPaymentMethod,
    });
  };

  const initialValues = {
    replaceCurrentCard: true,
    totalAmount: '2',
    name: get(currentUser, 'attributes.profile.displayName', ''),
  };

  const donateTitle = <FormattedHTMLMessage id={'PaymentDonationForm.donateForm.title'} />;
  const donateDescription = (
    <FormattedHTMLMessage id={'PaymentDonationForm.donateForm.description'} />
  );
  const donateSuccessTitle = (
    <FormattedHTMLMessage id={'PaymentDonationForm.donateForm.success.title'} />
  );
  const donateSuccessDescription = (
    <FormattedHTMLMessage id={'PaymentDonationForm.donateForm.success.description'} />
  );

  const donationFormTitle = isDonationPaymentSuccessful ? donateSuccessTitle : donateTitle;
  const donationFormDescription = isDonationPaymentSuccessful
    ? donateSuccessDescription
    : donateDescription;

    const formClasses = transactInModal ? classNames(css.form, tripCss.modalForm) : css.form
  return (
    <FinalForm
      initialValues={initialValues}
      onSubmit={handleSubmit}
      render={({ handleSubmit }) => {
        return (
          <Form onSubmit={handleSubmit} className={formClasses}>
            <FormSpy
              subscription={{ values: true, active: true }}
              onChange={({ values, active }) => {

                if (values.totalAmount === '2') {
                  setSelectedAmount(2);
                }
                if (values.totalAmount === '5') {
                  setSelectedAmount(5);
                }
                if (values.totalAmount === '10') {
                  setSelectedAmount(10);
                }
                if (values.totalAmount === '15') {
                  setSelectedAmount(15);
                }
                if (values.totalAmount === '20') {
                  setSelectedAmount(20);
                }
                if (values.totalAmount === '50') {
                  setSelectedAmount(50);
                }
              }}
            />
            <div className={tripCss.titleContainer}>
              {!transactInModal && (
                <p className={tripCss.bookingStatusText} onClick={handleGoBack}>
                  Back
                </p>
              )}
              <h2 className={tripCss.listingTitle}>{donationFormTitle}</h2>
              <div className={tripCss.donationFormWrapper}>
                <div className={tripCss.formDescription}>
                  <p>{donationFormDescription}</p>
                </div>
                {!isDonationPaymentSuccessful && (
                  <div className={tripCss.formWrapper}>
                    <div className={tripCss.formTitle}>Select your donation amount</div>
                    <div className={classNames(tripCss.customButtons, tripCss.fields)}>
                      <div className={tripCss.customRadio}>
                        <FieldRadioButton
                          id="covidBottle2"
                          name="totalAmount"
                          label={optionOneLabel1}
                          value="2"
                          showAsRequired={true}
                          labelClass={css.selectViroQLabel}
                          inputWrapperClass={css.selectInputWrapper}
                        />
                      </div>
                      <div className={tripCss.customRadio}>
                        <FieldRadioButton
                          id="covidBottle5"
                          name="totalAmount"
                          label={optionOneLabel2}
                          value="5"
                          showAsRequired={true}
                          labelClass={css.selectViroQLabel}
                          inputWrapperClass={css.selectInputWrapper}
                        />
                        {/* <input type="radio" name="donationAmount" id="amount2" />
                        <label htmlFor="amount2">$5</label> */}
                      </div>
                      <div className={tripCss.customRadio}>
                        <FieldRadioButton
                          id="covidBottle10"
                          name="totalAmount"
                          label={optionOneLabel3}
                          value="10"
                          showAsRequired={true}
                          labelClass={css.selectViroQLabel}
                          inputWrapperClass={css.selectInputWrapper}
                        />
                      </div>
                      <div className={tripCss.customRadio}>
                        <FieldRadioButton
                          id="covidBottle15"
                          name="totalAmount"
                          label={optionOneLabel4}
                          value="15"
                          showAsRequired={true}
                          labelClass={css.selectViroQLabel}
                          inputWrapperClass={css.selectInputWrapper}
                        />
                      </div>
                      <div className={tripCss.customRadio}>
                        <FieldRadioButton
                          id="covidBottle20"
                          name="totalAmount"
                          label={optionOneLabel5}
                          value="20"
                          showAsRequired={true}
                          labelClass={css.selectViroQLabel}
                          inputWrapperClass={css.selectInputWrapper}
                        />
                      </div>
                      <div className={tripCss.customRadio}>
                        <FieldRadioButton
                          id="covidBottle50"
                          name="totalAmount"
                          label={optionOneLabel6}
                          value="50"
                          showAsRequired={true}
                          labelClass={css.selectViroQLabel}
                          inputWrapperClass={css.selectInputWrapper}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {!isDonationPaymentSuccessful && currentUser ? (
                  <Fragment>
                    {showPaymentMethodSelector ? (
                      <PaymentMethodSelector
                        defaultPaymentMethod={ensuredDefaultPaymentMethod}
                        changePaymentMethod={v => setPaymentMethod(v)}
                        handleStripeElementRef={handleStripeElementRef}
                        paymentMethod={selectedPaymentMethod}
                        intl={intl}
                        formId="paymentDepositForm"
                      />
                    ) : (
                      <React.Fragment>
                        <h3 className={css.paymentHeading}>
                          <FormattedMessage id="StripePaymentForm.paymentHeading" />
                        </h3>
                        <OneTimePaymentWithCardElement
                          handleStripeElementRef={handleStripeElementRef}
                          intl={intl}
                          formId="paymentDepositForm"
                        />
                      </React.Fragment>
                    )}
                  </Fragment>
                ) : null}
              </div>
              {!isDonationPaymentSuccessful && (
                <div className={tripCss.submitSection}>
                  <div className={tripCss.submitSectionInner}>
                    {/* {transaction ? ( */}
                    <div className={tripCss.submitSectionPrice}>
                      <span className={tripCss.totalAmount}>
                        {/* <LineItemTotalPriceNew
                            currentUser={currentUser}
                            transaction={currentTransaction}
                            isProvider={false}
                            intl={intl}
                          /> */}
                        ${selectAmount}
                      </span>
                      Total Amount
                    </div>
                    {/* ) : null} */}
                    <div className={tripCss.submitSectionPrice}>
                      <div className={tripCss.submitContainer}>
                        {/* <span className={css.errorMessage}>
                          <FormattedMessage id="StripePaymentForm.stripe.cardError" />
                        </span> */}
                        {/* {!isInstantBooking ? <p className={css.paymentInfo}>{paymentInfo}</p> : null} */}
                        <PrimaryButton
                          className={tripCss.submit}
                          type="submit"
                          inProgress={inProgress}
                          disabled={showOnetimePaymentFields && !state.cardValueValid}
                          // inProgress={submitInProgress}
                          // disabled={submitDisabled}
                        >
                          Donate
                        </PrimaryButton>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {initiateOrderError && (
              <div className={css.error}>
                <FormattedMessage id="PaymentDepositForm.error" />
              </div>
            )}
          </Form>
        );
      }}
    />
  );
};

export default TripDonationPanelForm;
