import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import css from './AutoAccordion.css';

let count = 1;
const AutoAccordion = ({ content, autoplaySpeedInSeconds }) => {
  const [accordrionContent, setAccordionContent] = useState(content);
  useEffect(() => {
    const interval = setInterval(() => {
      const updatedAccordion =
        accordrionContent &&
        accordrionContent.length > 0 &&
        accordrionContent.map(content => {
          if (content && content.id === count) {
            content['isSelected'] = true;
          } else {
            content['isSelected'] = false;
          }
          return content;
        });
      if(count === accordrionContent.length) {
        count = 1
      } else {
        count = count + 1
      }
      setAccordionContent(updatedAccordion);
    }, (autoplaySpeedInSeconds + 0.5)*1000);
    return () => clearInterval(interval);
  });

  return (
    <div className={css.accordionWrapper}>
      {accordrionContent &&
        accordrionContent.length > 0 &&
        accordrionContent.map((content, index) => {
          return (
            <div className={classNames(css.accordionbox, content.isSelected ? css.isActive : '')}>
              <div className={css.accordionboxWrapper}>
                <div className={css.accordionTitle}>
                  <span className={css.counter}>
                    {index < 10 ? `0${index + 1}.` : `${index + 1}.`}
                  </span>
                  {content.accordionTitle}
                </div>
                {content.isSelected && (
                  <div className={css.accordionDescription}>
                    <p>{content.accordionDescription}</p>
                  </div>
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
};
export default AutoAccordion;
