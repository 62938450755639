import React, { Component } from 'react';
import { bool, func, string } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import {
  FieldPhoneNumberInput,
  FieldSelect,
  Form,
  NamedLink,
  PrimaryButton,
} from '../../components';
import { required as requiredValidator } from '../../util/validators';
import { propTypes } from '../../util/types';
import config from '../../config';
import classNames from 'classnames';

import css from './MobileVerificationForm.css';

class MobileVerificationFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneCode: config.custom.phoneCode[0].key,
      phoneNumber: config.custom.phoneCode[0].key,
      placeholder: 'xxxxxxxxx',
      openInputForm: !!this.props.currentPhoneNumber,
    }

    this.handleOnChangeCountry = this.handleOnChangeCountry.bind(this);
    this.handleOnChangePhoneNumber = this.handleOnChangePhoneNumber.bind(this);
    this.handleOpenInputForm = this.handleOpenInputForm.bind(this);
  }

  handleOnChangeCountry(input) {
    this.setState({
      phoneCode: input.target.value,
      phoneNumber: input.target.value
    })
  }

  handleOnChangePhoneNumber(input) {
    this.setState({
      phoneNumber: input.target.value
    })
  }

  handleOpenInputForm() {
    this.setState({
      openInputForm: true
    })
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        render={formRenderProps => {
          const { currentUser, inProgress, handleSubmit, verificationError,
            closeModal, isStayHere, openGuestVerify, closeModalForUploadAva,
            onOpenGuestVerify } = formRenderProps;

          const { phoneNumber, pendingPhoneNumber, profile } = currentUser.attributes;
          const { phoneNumberVerified } = profile.protectedData;
          const phoneNumberToVerify = <strong>{pendingPhoneNumber || phoneNumber}</strong>;
          const name = profile.firstName;
          const countryLabel = this.props.intl.formatMessage({ id: 'ModalMissingInformation.mobilePhoneInputCountryLabel' });
          const phoneNumberLabel = this.props.intl.formatMessage({ id: 'ModalMissingInformation.mobilePhoneInputNumberLabel' });
          const phoneNumberValidator = requiredValidator(
            this.props.intl.formatMessage({
              id: 'ModalMissingInformation.mobilePhoneInputNumberRequired',
            })
          )

          const submitInProgress = inProgress;
          const submitDisabled = submitInProgress;

          const emailVerified = (
            <div className={css.root}>
              <div>
                <h1 className={css.modalTitle}>
                  <FormattedMessage id="phoneNumberVerificationForm.emailSuccessTitle" values={{ name }} />
                </h1>

                <p className={css.modalMessage}>
                  <FormattedMessage id="phoneNumberVerificationForm.emailSuccessText" />
                </p>
              </div>

              <div className={css.bottomWrapper}>
                <div
                  className={css.submitButton}
                  onClick={this.handleOpenInputForm}
                >
                  <FormattedMessage id="phoneNumberVerificationForm.emailSuccessButtonText" />
                </div>
              </div>
            </div>
          );

          const verifyPhoneNumber = (
            <div className={css.root}>
              <div>
                <h1 className={css.modalTitle}>
                  <FormattedMessage id="phoneNumberVerificationForm.verifyPhoneNumber" />
                </h1>

                <p className={css.modalMessage}>
                  <FormattedMessage
                    id="phoneNumberVerificationForm.finishAccountSetup"
                    values={{ phoneNumber: phoneNumberToVerify }}
                  />
                </p>

                {!!verificationError ? (
                  <div className={css.error}>
                    <FormattedMessage id={verificationError} />
                  </div>
                ) : null}
              </div>

              <Form onSubmit={handleSubmit}>
                <FormSpy
                  onChange={(input) => {
                    if (!input.values.phoneCode) {
                      input.values.phoneCode = config.custom.phoneCode[0].key
                    }
                    this.setState({
                      phoneCode: input.values.phoneCode || this.state.phoneCode
                    })
                  }}
                  subscription={{ values: true }}
                />
                <FieldSelect
                  id="phoneCode"
                  name="phoneCode"
                  label={countryLabel}
                >
                  {
                    config.custom.phoneCode.map((option, index) => (
                      <option value={option.key} key={option.key}>{option.label}</option>
                    ))
                  }
                </FieldSelect>
                <label className={css.phonenumberLabel}>{phoneNumberLabel}</label>
                <div className={css.phoneNumberWrapper}>
                  <div className={css.phoneCode}>
                    {this.state.phoneCode}
                  </div>
                  <FieldPhoneNumberInput
                    className={classNames(css.field, css.phoneNumber)}
                    name="phoneNumber"
                    id="phoneNumber"
                    placeholder={this.state.placeholder}
                  />
                </div>

                <div className={css.bottomWrapper}>
                  <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
                    {inProgress ? (
                      <FormattedMessage id="phoneNumberVerificationForm.verifying" />
                    ) : (
                        <FormattedMessage id="phoneNumberVerificationForm.verify" />
                      )}
                  </PrimaryButton>
                </div>
              </Form>
            </div>
          );

          const alreadyVerified = (
            <div className={css.root}>
              <div>
                <h1 className={css.modalTitle}>
                  <FormattedMessage id={openGuestVerify ? "phoneNumberVerificationForm.guestVerifyTitle" : "phoneNumberVerificationForm.successTitle"} values={{ name }} />
                </h1>

                <p className={css.modalMessage}>
                  <FormattedMessage id={openGuestVerify ? "phoneNumberVerificationForm.guestVerifyText" : currentUser.profileImage ? "phoneNumberVerificationForm.successTextUploadedPhoto" : "phoneNumberVerificationForm.successText"} />
                </p>
              </div>

              <div className={css.bottomWrapper}>
                {currentUser.profileImage ? (openGuestVerify ? null : (
                  <div className={css.submitButton} onClick={onOpenGuestVerify}>
                    <FormattedMessage id="phoneNumberVerificationForm.successButtonNextVerify" />
                  </div>
                )) : (openGuestVerify ? null : (
                  <NamedLink className={css.submitButton} name="ProfileSettingsPage" newTab={false} onClick={closeModalForUploadAva} doNothingWhenActive={true}>
                    <FormattedMessage id="phoneNumberVerificationForm.successButtonText" />
                  </NamedLink>
                ))}
                {openGuestVerify &&
                  <NamedLink className={css.submitButton} name="AccountSettingDriverVerificationPage" newTab={false} onClick={closeModal} doNothingWhenActive={true}>
                    <FormattedMessage id="phoneNumberVerificationForm.guestVerifyNow" />
                  </NamedLink>
                }
              </div>
            </div>
          );

          return phoneNumberVerified && !pendingPhoneNumber ? alreadyVerified : this.state.openInputForm ? verifyPhoneNumber : emailVerified;
        }}
      />
    );
  }
}

MobileVerificationFormComponent.defaultProps = {
  currentUser: null,
  inProgress: false,
  verificationError: null,
  closeModal: () => { console.log('close modal') },
};

MobileVerificationFormComponent.propTypes = {
  inProgress: bool,
  currentUser: propTypes.currentUser.isRequired,
  verificationError: string,
  closeModal: func,
};

const MobileVerificationForm = compose(injectIntl)(MobileVerificationFormComponent);

export default MobileVerificationForm;
