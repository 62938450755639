import React, { useRef } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Field, Form as FinalForm } from 'react-final-form';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import setFieldTouched from '../../util/setFiledTouched';
import {
  autocompletePlaceSelected,
  autocompleteSearchRequired,
  composeValidators,
  required,
} from '../../util/validators';
import {
  Button,
  FieldCustomNumberInput,
  FieldTextInput,
  Form,
  LocationAutocompleteInputField,
} from '../../components';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import css from './EditListingLocationForm.css';

const identity = v => v;

export const EditListingLocationFormComponent = props => {
  const autoCompleteLocationRef = useRef(null);
  return (<FinalForm
    {...props}
    mutators={{ setFieldTouched }}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values,
        form,
        submitButtonId,
      } = formRenderProps;

      if (values.floorUnit) {
        values.floorUnit.replace(/[^\d|-]/g, '');
        if (values.floorUnit.length > 2 && values.floorUnit[2] !== '-') {
          let tempVal = values.floorUnit[0] + values.floorUnit[1] + '-';
          tempVal = tempVal + values.floorUnit.slice(2, values.floorUnit.length);
          values.floorUnit = tempVal;
        }
      }

      const titleRequiredMessage = intl.formatMessage({ id: 'EditListingLocationForm.address' });
      const addressPlaceholderMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressPlaceholder',
      });
      const addressRequiredMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressRequired',
      });
      const addressNotRecognizedMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressNotRecognized',
      });

      const optionalText = intl.formatMessage({
        id: 'EditListingLocationForm.optionalText',
      });

      const buildingMessage = intl.formatMessage(
        { id: 'EditListingLocationForm.building' },
        { optionalText: optionalText }
      );
      const buildingPlaceholderMessage = intl.formatMessage({
        id: 'EditListingLocationForm.buildingPlaceholder',
      });

      const cityMessage = intl.formatMessage({ id: 'EditListingLocationForm.city' });
      const cityPlaceholderMessage = intl.formatMessage({
        id: 'EditListingLocationForm.cityPlaceholder',
      });

      const countryMessage = intl.formatMessage({ id: 'EditListingLocationForm.country' });
      const countryPlaceholderMessage = intl.formatMessage({
        id: 'EditListingLocationForm.countryPlaceholder',
      });

      const streetMessage = intl.formatMessage({ id: 'EditListingLocationForm.streetName' });
      const streetPlaceholderMessage = intl.formatMessage({
        id: 'EditListingLocationForm.streetNamePlaceholder',
      });

      const blockMessage = intl.formatMessage({ id: 'EditListingLocationForm.blockNo' });
      const blockPlaceholderMessage = intl.formatMessage({
        id: 'EditListingLocationForm.blockNoPlaceholder',
      });

      const floorMessage = intl.formatMessage({ id: 'EditListingLocationForm.floor' });
      const floorPlaceholderMessage = intl.formatMessage({
        id: 'EditListingLocationForm.floorPlaceholder',
      });

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingLocationForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingLocationForm.showListingFailed" />
        </p>
      ) : null;

      const cityRequiredMessage = intl.formatMessage({
        id: 'EditListingLocationForm.cityRequired',
      });

      const countryRequiredMessage = intl.formatMessage({
        id: 'EditListingLocationForm.countryRequired',
      });

      const postalCodeLabel = intl.formatMessage({ id: 'PayoutDetailsForm.postalCodeLabel' });
      const postalCodePlaceholder = intl.formatMessage({
        id: 'PayoutDetailsForm.postalCodePlaceholder',
      });
      const postalCodeRequired = intl.formatMessage({
        id: 'PayoutDetailsForm.postalCodeRequired',
      });

      const FloorOrUnitNoRequired = intl.formatMessage({
        id: 'PayoutDetailsForm.floorOrUnitNoRequired',
      });

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = disabled || submitInProgress;

      const fillUpFormBasedOnLocation = (location, form) => {
        const keepLocation = autoCompleteLocationRef.current;
        const selectedPlace = get(location, 'selectedPlace');
        if (!selectedPlace) {
          return null;
        }

        const address = get(location, 'selectedPlace.address');
        if (!address) {
          return null;
        }
        const isChanged = !isEqual(keepLocation, address);
        if (isChanged) {
          const addressComponents = get(selectedPlace, 'address_components', []);
          const postalCode = addressComponents.find(c => c.types.includes('postal_code'));
          const neighborhood = addressComponents.find(c => c.types.includes('neighborhood'));
          form.batch(() => {
            postalCode && form.change('postalCode', postalCode.short_name);
          });
          form.batch(() => {
            neighborhood ? form.change('neighborhood', neighborhood.short_name) : form.change('neighborhood', '');
          });
          autoCompleteLocationRef.current = address;
        }
        return null;
      };



      return (
        <Form
          className={classes}
          onSubmit={e => {
            e.preventDefault();
            e.stopPropagation();
            if (!invalid) handleSubmit(e);
            const formState = form.getState();
            const { errors } = formState || {};
            Object.keys(errors).forEach(key => form.mutators.setFieldTouched(key, 'true'));
          }}
        >
          {errorMessage}
          {errorMessageShowListing}

          <div className={css.displayInlineContainer}>
            <div className={classNames(css.column, css.column12)}>
              <Field
                name="location"
                render={({ input, meta }) => {
                  const { onChange, ...restInput } = input;

                  // Merge the standard onChange function with custom behaviur. A better solution would
                  // be to use the FormSpy component from Final Form and pass this.onChange to the
                  // onChange prop but that breaks due to insufficient subscription handling.
                  // See: https://github.com/final-form/react-final-form/issues/159
                  const searchOnChange = value => {

                    fillUpFormBasedOnLocation(value, form);
                    onChange(value);
                  };

                  const searchInput = { ...restInput, onChange: searchOnChange };
                  return (
                    <LocationAutocompleteInputField
                      input={searchInput}
                      rootClassName={classNames(css.streetName, css.newInput)}
                      className={css.streetName}
                      inputClassName={css.locationAutocompleteInput}
                      iconClassName={css.locationAutocompleteInputIcon}
                      predictionsClassName={css.predictionsRoot}
                      validClassName={css.validLocation}
                      validationClassName={css.validationClassName}
                      autoFocus
                      name="location"
                      label={'Address*'}
                      placeholder={streetPlaceholderMessage}
                      useDefaultPredictions={false}
                      format={identity}
                      valueFromForm={values.location}
                      validate={composeValidators(
                        autocompleteSearchRequired(addressRequiredMessage),
                        autocompletePlaceSelected(addressNotRecognizedMessage)
                      )}
                      meta={meta}
                    />

                  );
                }}
              />
            </div>
            {/* <div className={classNames(css.column, css.column6)}>
                  <FieldTextInput
                    className={classNames(css.country, css.newInput)}
                    type="text"
                    name="blockNo"
                    id="blockNo"
                    label={blockMessage}
                    placeholder={blockPlaceholderMessage}
                  />
                </div> */}
            <div className={classNames(css.column, css.column6)}>
              <FieldTextInput
                className={classNames(css.country, css.newInput)}
                type="text"
                name="building"
                id="building"
                label={buildingMessage}
                placeholder={buildingPlaceholderMessage}
              />
            </div>

            <div className={classNames(css.column, css.column6)}>
              <FieldCustomNumberInput
                className={classNames(css.floor, css.newInput)}
                type="text"
                name="floorUnit"
                id="floorUnit"
                label={floorMessage}
                placeholder={floorPlaceholderMessage}
              //validate={composeValidators(required(FloorOrUnitNoRequired))}
              />
            </div>
            <div className={classNames(css.column, css.column6)}>
              <FieldTextInput
                className={classNames(css.country, css.newInput)}
                type="text"
                name="postalCode"
                id="postalCode"
                label={postalCodeLabel}
                placeholder={postalCodePlaceholder}
                validate={composeValidators(required(postalCodeRequired))}
              />
            </div>
            {/* <FieldTextInput
            className={css.streetName}
            type="text"
            name="street_name"
            id="street_name"
            label={streetMessage}
            placeholder={streetPlaceholderMessage}
          /> */}
          </div>

          {/* <div className={css.displayInlineContainer}>
            <FieldTextInput
              className={css.building}
              type="text"
              name="building"
              id="building"
              label={buildingMessage}
              placeholder={buildingPlaceholderMessage}
            />

            <FieldCustomNumberInput
              className={css.floor}
              type="text"
              name="floorUnit"
              id="floorUnit"
              label={floorMessage}
              placeholder={floorPlaceholderMessage}
            />
          </div> */}

          {/* <FieldTextInput
            className={css.city}
            type="text"
            name="city"
            id="city"
            label={cityMessage}
            placeholder={cityPlaceholderMessage}
            validate={composeValidators(required(cityRequiredMessage))}
          /> */}
          {/* <FieldTextInput
            className={css.country}
            type="text"
            name="country"
            id="country"
            label={countryMessage}
            placeholder={countryPlaceholderMessage}
            validate={composeValidators(required(countryRequiredMessage))}
          />  */}

          {/* <FieldTextInput
            className={css.country}
            type="text"
            name="postalCode"
            id="postalCode"
            label={postalCodeLabel}
            placeholder={postalCodePlaceholder}
            validate={composeValidators(required(postalCodeRequired))}
          /> */}
          <div className={css.stickyButtons}>
            <div className={css.stickButtonsContainer}>
              <div className={css.stickButtonsDescription}>              </div>
              <div className={css.stickButton}>
                <Button
                  className={css.submitButton}
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                  ready={submitReady}
                  id={submitButtonId}
                >
                  Next
                </Button>
              </div>
            </div>
          </div>
        </Form>
      );
    }}
  />
  )
};

EditListingLocationFormComponent.defaultProps = {
  selectedPlace: null,
  fetchErrors: null,
};

EditListingLocationFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  submitButtonId: string,
};

export default compose(injectIntl)(EditListingLocationFormComponent);
