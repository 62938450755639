import React, { useEffect, useState } from 'react';
import { object, string } from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

import css from './BeginnerResourcesPage.css';


const SectionAccordion = props => {
  const [hidden, setHidden] = useState([]);

  const {
    rootClassName,
    isCustomerRole
  } = props;

  const items = isCustomerRole ? [
    {
      title: 'I just sent a booking request, what will be the next step?',
      description: '<p>If you have chosen an instant booking car, then your booking is auto accepted. Just proceed to pick up your car on the time and day of pick up.</p> <p>If it is not an instant booking car, then just relax. Your host will revert to your booking request shortly. Once booking request is accepted, you will receive a notification.</p>'

    },
    {
      title: isCustomerRole ? 'Do I need to bring anything for my trip?' : 'Can I cancel a confirmed trip?',
      description: '<p>Bring your original driving license. This is to ensure that you are the rightful driver. Cashcard topped up with sufficient funds / NETS flash pay card to plug into the UI device for ERP/ tolls.</p>'
    },
    {
      title: 'I booked a Drive lah Go trip, how will I be able to access the car?',
      description: '<p>At the time of pick up, you will see a Drive lah Go button on your trip page through which you will able to access the car.</p>'
    },
    {
      title: 'What happens when I go to pickup the car?',
      description: '<p>For a Drive lah Go trip (keyless access), please proceed to the location of the car. Upload pictures of the car and your selfie holding your driving license. You can now send a pick up request to the host. Once the host confirms, you can access the car. Then please proceed to upload pictures of the fuel gauge and odometer. You are now ready to start your Drive lah trip.</p><p>For a physical key handover trip, please proceed to the location of the car and meet your host. Upload pictures of the car, fuel gauge and odometer and send a pick up request to the host. Once the host confirms, you are ready to start your Drive lah trip.</p><p>If there are any concerns, feel free to reach out to our customer team on 64328307 / chat with us via the app.</p>'
    },
    {
      title: 'What happens when I drop off the car?',
      description: '<p>For a Drive lah Go trip (keyless access), once your trip is over, please upload pictures of the car, fuel gauge and odometer. Before sending the drop off request, please ensure you have parked the car at the designated location and taken all your belongings. proceed to the location of the car. Send a drop off request to the host and wait for them to confirm drop off. Once the host confirms drop off, your trip is completed. Leave a review for your host.</p><p>For a physical key handover trip, once your trip is over, please upload pictures of the car, fuel gauge and odometer. Before sending the drop off request, please ensure you have parked the car at the designated location and taken all your belongings. Meet your host at the drop off location and let him confirm the drop off request. Once the host confirms drop off, your trip is completed. Leave a review for your host.</p><p>If there are any concerns, feel free to reach out to our customer team on 64328307 / chat with us via the app.</p>'
    },
    {
      title: 'What do I do in case of an accident?',
      description: '<p>In case of an accident, call us at 64328307 and we take care of everything. Nothing to worry.</p>'
    },
    {
      title: 'Can I cancel my trip anytime?',
      description: '<p>Before the start of the trip, yes you can cancel your trip. Do remember that there can be cancellation charges depending upon when the trip is cancelled. Refer to the cancellation policy for details.</p>'
    },

  ] : [
    {
      title: 'Are there any consequences to declining a booking?',
      description: '<p>It is strongly advised to keep your calendar up to date always so that you only receive bookings for time when your car is available. In case you decline a booking request, it affects your BAR (Booking Acceptance Rate). Hosts are expected to maintain a minimum BAR of 80%. It will adversely impact visibility of your car on the platform and may even lead to removal of the listing.</p>'
    },
    {
      title: 'Can I cancel a confirmed trip?',
      description: '<p>Before a trip starts, you can cancel the same. Please only cancel if you absolutely need to as it will be hugely inconvenient for the guest. There may be cancellation charges depending upon the time of cancellation.</p>'
    },
    {
      title: 'What are the steps to follow when a guest comes to pickup the car?',
      description: '<p>For a Drive lah Go trip (keyless access), the guest will send you a pick up request with the pictures of the car and their selfie holding their driving license. Do verify the uploaded pictures and once all ok, you can approve the pick up request. Once you do so, the guest will be able to access the car.</p><p>For a physical key handover trip, please proceed to meet the guest at the location of the car at the time of pick up. The guest will send a pick up request with pictures of the car, fuel gauge, odometer. Do verify the pictures and once all ok, you can confirm the pick up request.</p> <p>If there are any concerns, feel free to reach out to our customer team on 64328307 / chat with us via the app.</p>'
    },
    {
      title: 'What are the steps to follow when a guest comes to drop off the car?',
      description: '<p>For a Drive lah Go trip (keyless access), once the trip is over, guest will send you a drop off request along with pictures of the car, fuel gauge and odometer. Once you have verified the pictures, confirm the drop off request. At this point, the access to the car will be revoked for the guest. Leave a review for your guest.</p><p>For a physical key handover trip, meet your guest at the drop off time at your designated parking location. Guest will send you a drop off request with the pictures of the car, fuel gauge and odometer. Once you have verified, you can approve the request. Leave a review for your guest.</p><p>If there are any concerns, feel free to reach out to our customer team on 64328307 / chat with us via the app.</p>'
    },
    {
      title: 'How can I raise a dispute?',
      description: '<p>If you want to raise a dispute, you can choose to press “dispute” instead of confirming drop off. Alternatively you can confirm drop off and raise a dispute (via email/ chat/ phone) within 24 hrs of the trip getting completed.</p>'
    },
    {
      title: 'My car was in an accident. What are the next steps?',
      description: '<p>In case of an accident, the guest is expected to contact Drive lah. Once that happens, Drive lah team takes over and keeps you informed on next steps. Do note that Drive lah team will take care of it from start to end.</p><p>However, in case the guest fails to report an accident to us and you realise that there has been accident, then please contact us and we will take care of it. In both cases, you have nothing to worry as we take care of the process.</p>'
    },
  ]


  useEffect(() => {
    items.map((l, index) => {
      hidden[index] = true;
      setHidden([...hidden]);
    });
  }, []);


  const showHideDescription = (e, index) => {
    e.preventDefault();
    hidden[index] = !hidden[index];
    setHidden([...hidden]);
  };

  return (
    <div className={rootClassName}>
      {items.map((item, index) => {
        return (
          <div key={index} className={css.accordionWrapper}>
            <div onClick={e => showHideDescription(e, index)} className={css.accordionTitleHolder} >
              <h3 className={css.accordionTitle}>{item.title}</h3>
              <span className={hidden[index] === true ? css.hiddenAccordionCaret : css.accordionCaret} />
            </div>

            <div className={hidden[index] === true ? css.hiddenAccordionDescription : css.accordionDescription}>
              {ReactHtmlParser(item.description)}
            </div>
          </div>
        );
      })}
    </div>
  );
};

SectionAccordion.defaultProps = {
  rootClassName: null,
  publicData: {},
};

SectionAccordion.propTypes = {
  rootClassName: string,
  publicData: object.isRequired,
};

export default SectionAccordion;
