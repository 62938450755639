import React, { useState } from 'react';
import { default as CarsSlider } from 'react-slick';
import css from './AntiCompetitionLandingPage.css';
import { default as RangeSlider } from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import ComparisonGraph from './ComparisonGraph';
import { comparitionOptions } from './comparisonStatistics';
import classNames from 'classnames';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const CompetitionCompareSection = ({
  handleSignupRedirect,
  loading,
  signUpCodeAmount,
  onManageDisableScrolling,
  isMobileLayout,
}) => {
  const [selectedCategory, setSelectedCategory] = useState(comparitionOptions[0]);
  const [durationFilter, setDurationFilter] = useState(6);
  const [durationToggle, setDurationToggle] = useState(false);
  const [distanceFilter, setDistanceFilter] = useState(50);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    variableWidth: false,
    draggable: false,
    afterChange: current => {
      if (comparitionOptions && comparitionOptions.length > 0) {
        comparitionOptions.find(item => {
          if (item.id === current) {
            item.isCategorySelected = true;
          } else {
            item.isCategorySelected = false;
          }
        });
      }
      const getSelectedSlide =
        comparitionOptions &&
        comparitionOptions.length > 0 &&
        comparitionOptions.filter(i => i.id === current);
      setSelectedCategory(getSelectedSlide[0]);
    },
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          arrows: true,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          dots: false,
          arrows: true,
          variableWidth: false,
          draggable: true,
          infinite: false,
        },
      },
    ],
  };

  const mobileSettings = {
    variableWidth: false,
    draggable: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    dots: false,
    arrows: true,
    variableWidth: false,
    draggable: true,
    infinite: false,
    afterChange: current => {
      if (comparitionOptions && comparitionOptions.length > 0) {
        comparitionOptions.find(item => {
          if (item.id === current) {
            item.isCategorySelected = true;
          } else {
            item.isCategorySelected = false;
          }
        });
      }
      const getSelectedSlide =
        comparitionOptions &&
        comparitionOptions.length > 0 &&
        comparitionOptions.filter(i => i.id === current);
      setSelectedCategory(getSelectedSlide[0]);
    },
  };

  const handleChangeStart = () => {
    //
  };

  const handleChange = (value, type) => {
    switch (type) {
      case 'duration':
        setDurationFilter(value);
        if (value > 6 && distanceFilter < 100) {
          setDistanceFilter(100);
        } else if (value < 24 && distanceFilter > 75) {
          setDistanceFilter(50);
        }
        setDurationFilter(value);
        break;
      case 'distance':
        if (isMobileLayout) {
          if (value < 100 && durationFilter > 6) {
            setDurationToggle(false);
            setDurationFilter(6);
          } else if (value > 75 && durationFilter < 24) {
            setDurationToggle(true);
            setDurationFilter(24);
          }
        } else {
          if (value < 100 && durationFilter > 6) {
            setDurationFilter(6);
          } else if (value > 75 && durationFilter < 24) {
            setDurationFilter(24);
          }
        }
        setDistanceFilter(value);
        break;
      default:
        break;
    }
  };

  const handleChangeComplete = () => {
    //
  };

  const onCategorySelect = option => {
    setDurationFilter(6);
    setDistanceFilter(50);
  };

  const _handleToggleChange = e => {
    const selectedValue = e.target.checked ? 24 : 6;
    if (selectedValue > 6 && distanceFilter < 100) {
      setDistanceFilter(100);
    } else if (selectedValue < 24 && distanceFilter > 75) {
      setDistanceFilter(50);
    }
    setDurationFilter(selectedValue);
    setDurationToggle(e.target.checked);
  };

  const durationAndDistanceValue = `${durationFilter}to${distanceFilter}`;
  const graphData = selectedCategory.statistics[durationAndDistanceValue];
  return (
    <div className={css.competitionWrapper}>
      <div className={css.container}>
        <div className={css.sectionTitle}>
          <h2>Try our interactive pricing comparison</h2>
        </div>
        <div className={css.comparitionOptionsSlider}>
          <div className={css.sliderWrapper}>
            {isMobileLayout ? (
              <CarsSlider {...mobileSettings}>
                {comparitionOptions &&
                  comparitionOptions.length > 0 &&
                  comparitionOptions.map((option, index) => {
                    return (
                      <div>
                        <div key={option.id} className={css.optionWrapper}>
                          <div
                            key={option.id}
                            className={classNames(
                              css.optionPill,
                              option.isCategorySelected && css.isSelected
                            )}
                            onClick={() => onCategorySelect(option)}
                          >
                            <div key={option.id} className={css.optionThumb}>
                              <img
                                src={option.thumbUrl}
                                alt={`${option.categoryType} thumbnails`}
                              />
                            </div>
                            <div key={option.id} className={css.optionCaption}>
                              <span className={css.categoryType}>{option.categoryType}</span>
                              {option.isCategorySelected && (
                                <span className={css.exampleCars}>{option.exampleCars}</span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </CarsSlider>
            ) : (
              <CarsSlider {...settings}>
                {comparitionOptions &&
                  comparitionOptions.length > 0 &&
                  comparitionOptions.map((option, index) => {
                    return (
                      <div>
                        <div key={option.id} className={css.optionWrapper}>
                          <div
                            key={option.id}
                            className={classNames(
                              css.optionPill,
                              option.isCategorySelected && css.isSelected
                            )}
                            onClick={() => onCategorySelect(option)}
                          >
                            <div key={option.id} className={css.optionThumb}>
                              <img
                                src={option.thumbUrl}
                                alt={`${option.categoryType} thumbnails`}
                              />
                            </div>
                            <div key={option.id} className={css.optionCaption}>
                              <span className={css.categoryType}>{option.categoryType}</span>
                              {option.isCategorySelected && (
                                <span className={css.exampleCars}>{option.exampleCars}</span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </CarsSlider>
            )}
          </div>
        </div>
        <div className={css.rangeFilters}>
          <div className={css.rangeFiltersLeft}>
            <div className={classNames(css.filterTitle, css.mobileFilterTitle)}>
              <span>Duration</span>
              <span className={css.selectedValues}>
                {durationFilter}
                <i>hr</i>
              </span>
            </div>

            <div className={css.filterInput}>
              {isMobileLayout ? (
                <div className={css.mobileDurationToggle}>
                  <span className={css.toggleCaption}>6hr</span>
                  <div className={css.toggleInput}>
                    <input
                      type="checkbox"
                      onChange={_handleToggleChange}
                      checked={durationToggle}
                      id="durationToggleMobile"
                    />
                    <label htmlFor="durationToggleMobile"></label>
                  </div>
                  <span className={css.toggleCaption}>24hr</span>
                </div>
              ) : (
                <RangeSlider
                  min={6}
                  max={24}
                  value={durationFilter}
                  step={24}
                  onChangeStart={handleChangeStart}
                  onChange={value => handleChange(value, 'duration')}
                  onChangeComplete={handleChangeComplete}
                />
              )}
            </div>
          </div>
          <div className={css.rangeFiltersRight}>
            <div className={css.filterTitle}>
              <span>Distance</span>
              <span className={css.selectedValues}>
                {distanceFilter}
                <i>km</i>
              </span>
            </div>
            <div className={css.filterInput}>
              <RangeSlider
                min={50}
                max={150}
                value={distanceFilter}
                step={25}
                onChangeStart={handleChangeStart}
                onChange={value => handleChange(value, 'distance')}
                onChangeComplete={handleChangeComplete}
              />
            </div>
          </div>
        </div>
        <ComparisonGraph
          selectedData={graphData}
          selectedCategory={selectedCategory}
          loading={loading}
          signUpCodeAmount={signUpCodeAmount}
          handleSignupRedirect={handleSignupRedirect}
          onManageDisableScrolling={onManageDisableScrolling}
          assumptions={selectedCategory.assumptions}
          isMobileLayout={isMobileLayout}
          durationFilter={durationFilter}
        />
      </div>
    </div>
  );
};

export default CompetitionCompareSection;
