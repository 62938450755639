import React, { memo, useEffect } from 'react';
import PropTypes, { func } from 'prop-types';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { BListingCardMobile, PaginationLinks, SearchContactForm } from '../../components';
import css from './SearchResultsPanel.css';
import BlandingSearchResultsPanelCSS from './BlandingSearchResultsPanel.css';
import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';
import NewHostSlider from '../../containers/SearchPage/NewHostSlider';
import { emitter, Experiment, Variant } from '@marvelapp/react-ab-test';
import { getPUSHTELLSearchResultPage } from '../../util/emailNotify';
import { initiateExperimentEventFromListing } from '../../util/gtm/gtmHelpers';
import { types as sdkTypes } from '../../util/sdkLoader';

const { Money } = sdkTypes;
let EXPERIMENT_DATA = {};
let initialPageData = null;
const FIELDS = [
  'listings',
  'pagination',
  'search',
  'searchDate',
  'location',
  'searchTimes',
  'visibilityMap',
  'currentUser',
  'selectedFilters',
];

const SearchResultsPanel = memo(
  props => {
    const {
      className,
      rootClassName,
      listings,
      newCarPageListings,
      pagination,
      search,
      setActiveListing,
      searchDate,
      location,
      onSelectCard,
      searchTimes,
      isSearchingLongTerm,
      isMobileLayout,
      resultsCount,
      visibilityMap,
      isAuthenticated,
      onManageDisableScrolling,
      currentUser,
      viewport,
      selectedFilters,
      center
    } = props;

    const classes = classNames(rootClassName || css.root, className);
    // const [showFormModal, setShowFormModal] = useState(false);
    const paginationLinks =
      pagination && pagination.totalPages > 1 ? (
        <PaginationLinks
          className={css.pagination}
          pageName="SearchPage"
          pageSearchParams={search}
          pagination={pagination}
        />
      ) : null;

    useEffect(() => {
      // mapAbSegment = 'B';
      // if (!mapAbSegment) {
      //   emitter.defineVariants('Search result page', ['SR-A', 'SR-B'], [50, 50]);
      //   emitter.addPlayListener((experimentName, variantName) => {

      //     EXPERIMENT_DATA.experimentName = experimentName;
      //     EXPERIMENT_DATA.variantName = variantName;
      //     initialPageData = variantName;
      //   });
      // }

  
    }, []);

    // Panel width relative to the viewport
    const panelMediumWidth = 50;
    const panelLargeWidth = 62.5;
    const cardRenderSizes = [
      '(max-width: 767px) 100vw',
      `(max-width: 1023px) ${panelMediumWidth}vw`,
      `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
      `${panelLargeWidth / 3}vw`,
    ].join(', ');
    const showMonthlyPrice = (search && search.pub_longTermRental) || isSearchingLongTerm;

    let LARGE_VIEW_LENGTH = 6;
    let MEDIUM_VIEW_LENGTH = 4;
    let MOBILE_VIEW_LENGTH = 3;
    const ifTopOfferFilterApplied = selectedFilters && !selectedFilters.meta_isNewCar;
    const largeDesktopView = viewport && viewport.width >= 1920;
    const listingLength = listings && listings.length;
    const showAfter = largeDesktopView
      ? listingLength < LARGE_VIEW_LENGTH
      : !largeDesktopView
      ? listingLength < MEDIUM_VIEW_LENGTH
      : isMobileLayout
      ? listingLength < MOBILE_VIEW_LENGTH
      : false;

    const showAfterRow = ifTopOfferFilterApplied && showAfter;

    return (
      <div className={classes}>
        <div className={classNames(css.listingCards)}>
          {isMobileLayout && (
            <div className={css.mobileProductCount}>
              <div className={css.numberOfResults}>
                {resultsCount} {resultsCount > 1 ? 'cars' : 'car'} available
              </div>
              <div className={css.placeOfResults}>{search && search.address}</div>
            </div>
          )}
          {listings.map((l, index) => {
            return (isMobileLayout && index === MOBILE_VIEW_LENGTH) ||
              (isMobileLayout && index === MOBILE_VIEW_LENGTH) ||
              (!largeDesktopView && index === MEDIUM_VIEW_LENGTH) ||
              (largeDesktopView && index === LARGE_VIEW_LENGTH) ? (
              <>
                {newCarPageListings && newCarPageListings.length > 0 && (
                  <NewHostSlider
                    listings={newCarPageListings}
                    renderSizes={cardRenderSizes}
                    setActiveListing={setActiveListing}
                    searchDate={searchDate}
                    location={location}
                    searchTimes={searchTimes}
                    showMonthlyPrice={showMonthlyPrice}
                    currentUser={currentUser}
                    onSelectCard={onSelectCard}
                  />
                )}
                <BListingCardMobile
                  onSelectCard={onSelectCard}
                  className={classNames(BlandingSearchResultsPanelCSS.listingCard, {
                    [BlandingSearchResultsPanelCSS.fullWidthListingCard]:
                      !visibilityMap || search.pub_longTermRental,
                  })}
                  key={l.id.uuid}
                  listing={l}
                  center={center}
                  renderSizes={cardRenderSizes}
                  setActiveListing={setActiveListing}
                  searchDate={searchDate}
                  location={location}
                  searchTimes={searchTimes}
                  showMonthlyPrice={showMonthlyPrice}
                  currentUser={currentUser}
                />
              </>
            ) : (
              <>
                <BListingCardMobile
                  onSelectCard={onSelectCard}
                  className={classNames(BlandingSearchResultsPanelCSS.listingCard, {
                    [BlandingSearchResultsPanelCSS.fullWidthListingCard]:
                      !visibilityMap || search.pub_longTermRental,
                  })}
                  key={l.id.uuid}
                  listing={l}
                  center={center}
                  renderSizes={cardRenderSizes}
                  setActiveListing={setActiveListing}
                  searchDate={searchDate}
                  location={location}
                  searchTimes={searchTimes}
                  showMonthlyPrice={showMonthlyPrice}
                  currentUser={currentUser}
                />
              </>
            );
          })}
          {showAfterRow && newCarPageListings && newCarPageListings.length > 0 ? (
            (largeDesktopView && listingLength < LARGE_VIEW_LENGTH) ||
            (!largeDesktopView && listingLength < MEDIUM_VIEW_LENGTH) ||
            (isMobileLayout && listingLength < MOBILE_VIEW_LENGTH) ? (
              <NewHostSlider
                listings={newCarPageListings}
                renderSizes={cardRenderSizes}
                setActiveListing={setActiveListing}
                searchDate={searchDate}
                location={location}
                searchTimes={searchTimes}
                showMonthlyPrice={showMonthlyPrice}
                currentUser={currentUser}
                onSelectCard={onSelectCard}
              />
            ) : (
              ''
            )
          ) : (
            ''
          )}
          {props.children}
          <SearchContactForm
            isAuthenticated={isAuthenticated}
            onManageDisableScrolling={onManageDisableScrolling}
          />
          {props.children}
        </div>
        {paginationLinks}
      </div>
    );
  },
  (prev, next) => isEqual(pick(prev, FIELDS), pick(next, FIELDS))
);

SearchResultsPanel.defaultProps = {
  children: null,
  className: null,
  listings: [],
  pagination: null,
  rootClassName: null,
  search: null,
};

const { array, node, object, string } = PropTypes;

SearchResultsPanel.propTypes = {
  children: node,
  className: string,
  listings: array,
  pagination: propTypes.pagination,
  rootClassName: string,
  search: object,
  onSelectCard: func,
};

export default SearchResultsPanel;
