import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { PrimaryButton, SecondaryButton } from '../index';
import {
  TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP,
  txIsAwaitUpdateBookingBeforeDropOff,
  txIsAwaitUpdateBookingBeforePickUp,
} from '../../util/transaction';
import css from './TripPanel.css';

import {
  EVENT_TRIP_MODIFICATION_REQUEST_ACCEPTED_HOST,
  EVENT_TRIP_MODIFICATION_REQUEST_CANCELLED_HOST,
} from '../../util/gtm/gtmConstants';
import { initiateEventFromTransaction } from '../../util/gtm/gtmHelpers';
import { getLastUpdateObj, getUpdateObjs } from '../../util/updateTransaction';
import { event_trigger_ids } from '../../util/analyticsConstants';
import { triggerAnalyticsEvent } from '../../util/amplitudeMapEvents';
import { getEventsByBucket } from '../../util/gtm/gtmCreateProperties';

// Functional component as a helper to build ActionButtons for
// provider when state is preauthorized
const UpdateBookingButtonsMaybe = props => {
  const {
    isCancelUpdateBookingByAdminPending,
    isAcceptUpdateBookingByAdminPending,
    isLimitedUser,
    showButtons,
    onRequestToUpdateBooking,
    transaction,
    acceptInProgress,
    declineInProgress,
    customerUpdateBookingError,
    onCancelUpdateBookingByAdmin,
    onAcceptUpdateBookingByAdmin,
  } = props;
  const lastUpdateObj = getLastUpdateObj(transaction);
  if (!lastUpdateObj) {
    return null;
  }

  const updateError = customerUpdateBookingError && customerUpdateBookingError.apiErrors[0].code === "transaction-booking-time-not-available" ? <p className={css.actionError}>Some other user have a reservation already made for some part of your modified trip dates. Please select other dates and try again</p> : null
  const cancelButtonInProgress = declineInProgress || isCancelUpdateBookingByAdminPending;
  const acceptButtonInProgress = acceptInProgress || isAcceptUpdateBookingByAdminPending;
  let currentCancelTransition = '';
  if(txIsAwaitUpdateBookingBeforePickUp(transaction)){
    currentCancelTransition = TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP
  }
  if(txIsAwaitUpdateBookingBeforeDropOff(transaction)){
    currentCancelTransition = TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF
  }

  let currentAcceptTransition = '';
  if(txIsAwaitUpdateBookingBeforePickUp(transaction)){
    currentAcceptTransition = TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP
  }
  if(txIsAwaitUpdateBookingBeforeDropOff(transaction)){
    currentAcceptTransition = TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF
  }

  const updateBookingParams = {
    transaction,
    transactionId: transaction.id,
    listing: transaction.listing,
    bookingStart: new Date(lastUpdateObj.bookingStartTimestamp),
    bookingEnd: new Date(lastUpdateObj.bookingEndTimestamp),
    protectedData: {
      childTransaction: getUpdateObjs(transaction),
    }
  }

  return showButtons ? (
    <div className={css.buttonSectionContainer}>
      <div className={css.buttonSectionInner}>
        <div className={css.buttonSectionText}>
            <span>
              {/*Declining too many bookings can have a negative impact on your positioning on search.*/}
            </span>
          <div>
            {updateError}
          </div>
        </div>

        <div className={css.buttonSectionWrapper}>
          <PrimaryButton
            inProgress={cancelButtonInProgress}
            className={css.buttonDisabled}
            onClick={ async () => {
              if (isLimitedUser) {
                onCancelUpdateBookingByAdmin(transaction);
                return ;
              }
              onRequestToUpdateBooking({...updateBookingParams, transition: currentCancelTransition });
              // initiateEventFromTransaction({
              //   props: props,
              //   trip: transaction,
              //   event:  EVENT_TRIP_MODIFICATION_REQUEST_CANCELLED_HOST
              // });
              try {
                const response = await getEventsByBucket('book');
                // console.log('Events fetched successfully:', response.data);
                triggerAnalyticsEvent({
                  event_id: event_trigger_ids.BOOK_MODIFICATION_DECLINED_HOST,
                  eventData: response.data,
                  props: {
                    button:  'Book ',
                  },
                  userId: transaction.customer ? transaction.customer.id.uuid : null,
                });
                triggerAnalyticsEvent({
                  event_id: event_trigger_ids.BOOK_MODIFICATION_DECLINED_GUEST,
                  eventData: response.data,
                  props: {
                    button:  'Book ',
                  },
                  userId: transaction.customer ? transaction.customer.id.uuid : null,
                });
              } catch (error) {
                console.log('Error fetching events:', error);
              }
            }}
          >
            <FormattedMessage id="TransactionPanel.declineButtonUpdateBooking" />
            {isLimitedUser && ' as admin'}
          </PrimaryButton>

          <SecondaryButton
            inProgress={acceptButtonInProgress}
            className={css.buttonAccepted}
            onClick={async () => {
              if (isLimitedUser) {
                onAcceptUpdateBookingByAdmin(transaction);
                return ;
              }
              onRequestToUpdateBooking({...updateBookingParams, transition: currentAcceptTransition });
              // initiateEventFromTransaction({
              //   props: props,
              //   trip: transaction,
              //   event: EVENT_TRIP_MODIFICATION_REQUEST_ACCEPTED_HOST
              // });
              try {
                const response = await getEventsByBucket('book');
                // console.log('Events fetched successfully:', response.data);
                triggerAnalyticsEvent({
                  event_id: event_trigger_ids.BOOK_MODIFICATION_ACCEPTED_HOST,
                  eventData: response.data,
                  props: {
                    button:  'Book ',
                  },
                  userId: transaction.customer ? transaction.customer.id.uuid : null,
                });
                triggerAnalyticsEvent({
                  event_id: event_trigger_ids.BOOK_MODIFICATION_ACCEPTED_GUEST,
                  eventData: response.data,
                  props: {
                    button:  'Book ',
                  },
                  userId: transaction.customer ? transaction.customer.id.uuid : null,
                });
              } catch (error) {
                console.log('Error fetching events:', error);
              }
            }}
          >
            <FormattedMessage id="TransactionPanel.acceptButtonUpdateBooking" />
            {isLimitedUser && ' as admin'}
          </SecondaryButton>
        </div>
      </div>
    </div>
  ) : null;
};

export default UpdateBookingButtonsMaybe;
