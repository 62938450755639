import React, { useEffect, useRef } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Button, FieldToggleSwitch, Form } from '../../components';
import css from './EditListingNextLevelForm.css';

export const EditListingNextLevelFormComponent = props => {
  const submitRef = useRef(null);
  const submitedRef = useRef(false);
  useEffect(() => {
    if (
      props.shouldSubmit &&
      submitRef.current &&
      !props.updateInProgress &&
      !submitedRef.current
    ) {
      setTimeout(() => {
        submitRef.current.click();
        submitedRef.current = true;
      }, 1000);
    }
  }, [props.shouldSubmit, submitRef.current, props.updateInProgress, submitedRef.current]);
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          className,
          disabled,
          ready,
          handleSubmit,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
          submitButtonId,
          onChangeInstantBooking,
          onChangeDeliveryBooking
        } = formRenderProps;

        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;
        const { updateListingError, showListingsError } = fetchErrors || {};

        return (
          <Form onSubmit={handleSubmit} className={classes}>
            <div className={css.displayInlineContainer}>
              <div className={classNames(css.column, css.column12)}>
                {updateListingError ? (
                  <p className={css.error}>
                    <FormattedMessage id="EditListingNextLevelForm.updateFailed" />
                  </p>
                ) : null}
                {showListingsError ? (
                  <p className={css.error}>
                    <FormattedMessage id="EditListingNextLevelForm.showListingFailed" />
                  </p>
                ) : null}
              </div>
              <div className={classNames(css.column, css.column12)}>
                <div className={classNames(css.customToggleInput, css.field)}>
                  <div className={css.toggleLabels}>
                    <label htmlFor="instantBooking">Instant booking</label>
                  </div>
                  <div className={css.toggleInput}>
                    <FieldToggleSwitch
                      id="instantBooking"
                      name="instantBooking"
                      // label="Instant booking"
                      value="allowInstantBooking"
                      onChangeInstantBooking={onChangeInstantBooking}
                    />
                  </div>
                  <div className={css.infoText}>
                    <p>
                      Guests will be able to book your car without having to wait for your approval.
                      Instant booking cars usually see 54% higher demand than other cars.
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className={classNames(css.column, css.column12)}>
                <div className={classNames(css.customToggleInput, css.field)}>
                  <div className={css.toggleLabels}>
                    <label htmlFor="instantBooking">Drive lah Go</label>
                  </div>
                  <div className={css.toggleInput}>
                    <FieldToggleSwitch
                      id="instantBooking"
                      name="instantBooking"
                      // label="Drive lah Go"
                      value="allowInstantBooking"
                      onChangeInstantBooking={onChangeInstantBooking}
                    />
                  </div>
                  <div className={css.infoText}>
                    <p>
                      Hosts with Delivery enabled get higher demand as Home delivery is a popular requirement for guests. You will earn $16 for extra for trips booked with Delivery
                    </p>
                  </div>
                </div>
              </div> */}
              <div className={classNames(css.column, css.column12)}>
                <div className={classNames(css.customToggleInput, css.field)}>
                  <div className={css.toggleLabels}>
                    <label htmlFor="delivery">Home delivery &amp; pickup</label>
                  </div>
                  <div className={css.toggleInput}>
                    <FieldToggleSwitch
                      id="delivery"
                      name="delivery"
                      // label="Drive lah Go"
                      value="allowInstantBooking"
                      onChangeDeliveryBooking={onChangeDeliveryBooking}
                    />
                  </div>
                  <div className={css.infoText}>
                    <p>
                      Hosts with delivery enabled get higher demand as delivery is a popular requirement for guests. You will earn $16 extra for trips booked with Delivery.
                    </p>
                  </div>
                </div>
              </div>
            </div>


            {/* <div className={css.moreDesc}>
              <FormattedMessage id="EditListingNextLevelForm.moreDescription" />
            </div>
            <div className={css.moreDescSmall}>
              <FormattedMessage id="EditListingNextLevelForm.moreDescriptionSmallerPrint" />
            </div> */}
            <div className={css.stickyButtons}>
              <div className={css.stickButtonsContainer}>
                <div className={css.stickButtonsDescription}></div>
                <div className={css.stickButton}>
                  <Button
                    buttonRef={submitRef}
                    className={css.submitButton}
                    type="submit"
                    inProgress={submitInProgress}
                    disabled={submitDisabled}
                    ready={submitReady}
                    id={submitButtonId}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
};

EditListingNextLevelFormComponent.defaultProps = { fetchErrors: null };

EditListingNextLevelFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  submitButtonId: string,
  onChangeInstantBooking: func,
};

export default compose(injectIntl)(EditListingNextLevelFormComponent);
