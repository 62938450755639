import React, { useRef, useState } from 'react';
import {
  Button,
  FieldCustomNumberInput,
  FieldPhoneNumberInput,
  FieldSelect,
  FieldTextInput,
  Form,
  LocationAutocompleteInputField,
} from '../../components';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import camelCase from 'lodash/camelCase';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import css from './SingpassProfileForm.css';
import * as validators from '../../util/validators';
import {
  autocompletePlaceSelected,
  autocompleteSearchRequired,
  composeValidators,
  required,
} from '../../util/validators';
import { Field, Form as FinalForm, FormSpy } from 'react-final-form';
// import { required } from '../../util/validators';
import classNames from 'classnames';
import { first } from 'lodash';
import config from '../../config';
import { compose } from 'redux';


const SingpassProfileFormComponent = props => {
  const { intl, initialValues, onSubmitSingPassForm, userId,singPassDataClear,handleClearData,step, handleSteps, modifyStep } = props;

  let submittedValues = {}
  const  locationValue = (initialValues &&
    initialValues.location &&
    initialValues.location.selectedPlace &&
    initialValues.location.selectedPlace.address) ||
  null;
  const autoCompleteLocationRef = useRef(locationValue); // ref
  const [phoneCode, setPhoneCode] = useState(first(config.custom.phoneCode).key)

  React.useEffect(() => {
    modifyStep && handleSteps(step, 'Singpass Connected');
  }, []);



  const fillUpFormBasedOnLocation = (location, form) => {
    const keepLocation = autoCompleteLocationRef.current;
    const selectedPlace = get(location, 'selectedPlace');
    if (!selectedPlace) {
      return null;
    }

    const address = get(location, 'selectedPlace.address');
    if (!address) {
      return null;
    }
    const isChanged = !isEqual(keepLocation, address);
    if (isChanged) {
      const addressComponents = get(selectedPlace, 'place.address_components', []);
      const postalCode = addressComponents.find(c => c.types.includes('postal_code'));
      const state = addressComponents.find(c => c.types.includes('administrative_area_level_1'));
      const city = addressComponents.find(
        c => c.types.includes('administrative_area_level_2') || c.types.includes('colloquial_area')
      );
      const neighborhood = addressComponents.find(c => c.types.includes('neighborhood'));
      form.batch(() => {
        postalCode && form.change('postalCode', postalCode.short_name);
        state && form.change('state', camelCase(state.long_name));
        city && form.change('city', city.short_name);
      });
      form.batch(() => {
        neighborhood ? form.change('neighborhood', neighborhood.short_name) : form.change('neighborhood', '');
      });
      autoCompleteLocationRef.current = address;
    }
    return null;
  };

  // There will be two steps on the bases of cases


  return (
    <>

    <div className={classNames(css.SingpassProfileFormPage)}>
      {/* {JSON.stringify(props.initialValues)} */}
      <p>
        <FormattedMessage id="Verification.SinpassProfileFetchdDescription" />
      </p>
      <FinalForm
        {...props}
        onSubmit={onSubmitSingPassForm}
        initialValues={{ ...(initialValues || {}), ...submittedValues }}
        render={fieldRenderProps => {
          const {
          className,
          intl,
          saveActionMsg,
          updated,
          fetchErrors,
            form,
            handleSubmit,
            formId,
            disabled,
            invalid,
            values,
            updateInProgress,
            initialValues,
            ready,
            submitButtonId,
            shouldHideSubmitButton,
            pristine,

          } = fieldRenderProps;

          const streetMessage = intl.formatMessage({ id: 'EditListingLocationForm.streetName' });
          const streetPlaceholderMessage = intl.formatMessage({
            id: 'EditListingLocationForm.streetNamePlaceholder',
          });

          const buildingMessage = intl.formatMessage({ id: 'EditListingLocationForm.building' });
          const buildingPlaceholderMessage = intl.formatMessage({
            id: 'EditListingLocationForm.buildingPlaceholder',
          });

          const addressRequiredMessage = intl.formatMessage({
            id: 'EditListingLocationForm.addressRequired',
          });
          const addressNotRecognizedMessage = intl.formatMessage({
            id: 'EditListingLocationForm.addressNotRecognized',
          });

          const postalCodeMessage = intl.formatMessage({
            id: 'EditListingLocationForm.postalCode',
          });

          const postalCodePlaceholderMessage = intl.formatMessage({
            id: 'EditListingLocationForm.postalCodePlaceholder',
          });
          const postalCodeRequiredMsg = intl.formatMessage({
            id: 'AddressDetailsForm.postalCodeRequired',
          });

          const floorMessage = intl.formatMessage({ id: 'EditListingLocationForm.unitNumber' });
          const nationalityLabel = intl.formatMessage({ id: 'EditListingLocationForm.nationality' });

          const nationalityPlaceholderMessage = intl.formatMessage({
            id: 'EditListingLocationForm.nationalityPlaceholder',
          });

          const licensePlateNumberLabel = intl.formatMessage({ id: 'EditListingLocationForm.licensePlateNumber' });

          const licensePlateNumberPlaceholder = intl.formatMessage({
            id: 'EditListingLocationForm.licensePlateNumberPlaceholder'
          });


          const expiryDateLabel = intl.formatMessage({ id: 'EditListingLocationForm.expiryDate' });

          const expiryDatePlaceholder = intl.formatMessage({
            id: 'EditListingLocationForm.expiryDatePlaceholder'
          });

          const singPassStatusLabel = intl.formatMessage({ id: 'EditListingLocationForm.singPassStatus' });

          const singPassStatusPlaceholder = intl.formatMessage({
            id: 'EditListingLocationForm.singPassStatusPlaceholder'
          });
          const nricNumberLabel = intl.formatMessage({ id: 'EditListingLocationForm.nricNumber' });

          const nricNumberPlaceholder = intl.formatMessage({
            id: 'EditListingLocationForm.nricNumberPlaceholder'
          });

          const dobLabel = intl.formatMessage({ id: 'EditListingLocationForm.dobLabel' });

          const dobPlaceholder = intl.formatMessage({
            id: 'EditListingLocationForm.dobPlaceholder'
          });

          const singPassClassLabel = intl.formatMessage({ id: 'EditListingLocationForm.singPassClass' });

          const singPassClassPlaceholder = intl.formatMessage({
            id: 'EditListingLocationForm.singPassClassPlaceholder',
          });

          const issueDateLabel = intl.formatMessage({ id: 'EditListingLocationForm.issueDate' });

          const issueDatePlaceholder = intl.formatMessage({
            id: 'EditListingLocationForm.issueDatePlaceholder',
          });

          const passTypeLabel = intl.formatMessage({ id: 'EditListingLocationForm.passType' });

          const passTypeLabelPlaceholder = intl.formatMessage({
            id: 'EditListingLocationForm.passTypeHolder',
          });


          let phoneNumberRequiredMessage = intl.formatMessage({
            id: 'ContactDetailsForm.phoneNumberRequired',
          });

          const phoneNumberRequired = validators.required(phoneNumberRequiredMessage);


          const floorPlaceholderMessage = intl.formatMessage({
            id: 'EditListingLocationForm.floorPlaceholder',
          });

          const countryLabel = intl.formatMessage({
            id: 'ModalMissingInformation.mobilePhoneInputCountryLabel',
          });

          const phonePlaceholder = intl.formatMessage({
            id: 'PhoneNumberForm.phonePlaceholder',
          });
          const phoneLabel = intl.formatMessage({ id: 'PhoneNumberForm.phoneLabel' });

          const submittedOnce = Object.keys(submittedValues).length > 0;

          const pristineSinceLastSubmit = submittedOnce && isEqual(values, submittedValues);

          const submitInProgress = updateInProgress;

          const submitDisabled = disabled || submitInProgress;

          const submitReady = ready;

          const classes = classNames(css.singpassForm || css.root,  css.tripPageRoot);

          return (
            <Form
              onSubmit={e => {
                submittedValues = values;
                handleSubmit(e);
              }} className={classes}>
              <FormSpy
                onChange={input => {
                  if (!input.values.phoneCode) {
                    input.values.phoneCode = first(config.custom.phoneCode).key;
                  }
                  setPhoneCode(input.values.phoneCode || phoneCode);
                }}
                subscription={{ values: true }}
              />
               <div className={css.titleSection}>
                   <h1 className={css.title}>Address</h1>
               </div>
               <div className={css.displayInlineContainer}>
                <Field
                  name="location"
                  render={({ input, meta }) => {
                    const { onChange, ...restInput } = input;

                    // Merge the standard onChange function with custom behaviur. A better solution would
                    // be to use the FormSpy component from Final Form and pass this.onChange to the
                    // onChange prop but that breaks due to insufficient subscription handling.
                    // See: https://github.com/final-form/react-final-form/issues/159
                    const searchOnChange = value => {
                      fillUpFormBasedOnLocation(value, form);
                      onChange(value);
                    };

                    const searchInput = { ...restInput, onChange: searchOnChange };
                    return (
                      <LocationAutocompleteInputField
                        input={searchInput}
                        rootClassName={css.streetName}
                        className={classNames(css.newInput, css.streetName)}
                        inputClassName={css.locationAutocompleteInput}
                        iconClassName={css.locationAutocompleteInputIcon}
                        predictionsClassName={css.predictionsRoot}
                        validClassName={css.validLocation}
                        validationClassName={css.validationClassName}
                        name="location"
                        label={streetMessage}
                        placeholder={streetPlaceholderMessage}
                        useDefaultPredictions={false}
                        format={v => v}
                        valueFromForm={values.location}
                        validate={composeValidators(
                          autocompleteSearchRequired(addressRequiredMessage),
                          autocompletePlaceSelected(addressNotRecognizedMessage)
                        )}
                        disabled={shouldHideSubmitButton}
                        meta={meta}
                      />
                    );
                  }}
                />
              </div>


              <div className={classNames(css.displayInlineContainer, css.halfInputs)}>
                <FieldTextInput
                  className={classNames(css.floor, css.newInput)}
                  type="text"
                  name="floorUnit"
                  id="floorUnit"
                  label={floorMessage}
                  placeholder={floorPlaceholderMessage}
                  // disabled={shouldHideSubmitButton}
                />
                 <FieldTextInput
                  className={classNames(css.floor, css.newInput)}
                  type="text"
                  name="postalCode"
                  id="postalCode"
                  label={postalCodeMessage}
                  placeholder={postalCodePlaceholderMessage}
                  validate={required(postalCodeRequiredMsg)}
                  disabled={false}
                />
              </div>

              <div className={classNames(css.displayInlineContainer, css.column6)}>
{/*
                <FieldTextInput
                  className={classNames(css.city, css.newInput)}
                  type="text"
                  name="building"
                  // id="building"
                  label={buildingMessage}
                  required={false}
                  placeholder={buildingPlaceholderMessage}
                //   validate={required(buildingRequiredMsg)}
                  disabled={false}
                /> */}

                <FieldTextInput
                  className={classNames(css.country, css.newInput)}
                  type="text"
                  name="building"
                  id="building"
                  label={buildingMessage}
                  placeholder={buildingPlaceholderMessage}
                  required={false}
                />

              </div>

              {/* Phone Section */}
              <div className={css.titleSection}>
                   <h1 className={css.title}>Phone number</h1>
               </div>
               <div className={classNames(css.displayInlineContainer, css.halfInputs)}>
                <FieldSelect
                  id="phoneCode"
                  name="phoneCode"
                  label={countryLabel}
                  className={css.newInputCountry}
                  disabled={initialValues.phoneCode && initialValues.phoneNumber ? true: false}
                >
                  {config.custom.phoneCode.map((option, index) => (
                    <option value={option.key} key={option.key}>
                      {option.label}
                    </option>
                  ))}
                </FieldSelect>
                <div className={css.phoneNumberFieldWrapper}>
                  <label className={css.phoneNumberLabel}>{phoneLabel}</label>
                  <div className={css.phoneNumberWrapper}>
                    <div className={css.newInputPhoneCode}>{phoneCode}</div>
                    <FieldPhoneNumberInput
                      className={classNames(css.floor, css.newInput, css.phoneNumberNumberInput)}
                      name="phoneNumber"
                      id={formId ? `${formId}.phoneNumber` : 'phoneNumber'}
                      placeholder={phonePlaceholder}
                      validate={phoneNumberRequired}
                      disabled={initialValues.phoneNumber ? true: false}
                    />
                  </div>
                </div>
              </div>

              {/* Status Section */}
              {initialValues.nationality || initialValues.passType ? <div className={css.titleSection}>
                  <h1 className={css.title}>Status</h1>
              </div>: null}

              {initialValues.nationality || initialValues.passType ?   <div className={classNames(css.displayInlineContainer, css.halfInputs)}>
                {initialValues.nationality &&  <FieldTextInput
                  className={classNames(css.floor, css.newInput)}
                  type="text"
                  name="nationality"
                  id="nationality"
                  label={nationalityLabel}
                  placeholder={nationalityPlaceholderMessage}
                  disabled={true}
                />}
                {initialValues.passType &&  <FieldTextInput
                  className={classNames(css.floor, css.newInput)}
                  type="text"
                  name="passType"
                  id="passType"
                  label={passTypeLabel}
                  placeholder={passTypeLabelPlaceholder}
                  // validate={required(postalCodeRequiredMsg)}
                  disabled={true}
                />}
              </div>: null}

              {initialValues.licenseNumber || initialValues.licenseIssueDate || initialValues.licenseExpiryDate || initialValues.status ?   <div className={css.titleSection}>
                  <h1 className={css.title}>Driving licence</h1>
              </div>: null}

              {initialValues.licenseNumber || initialValues.licenseIssueDate ?   <div className={classNames(css.displayInlineContainer, css.halfInputs)}>
                {initialValues.licenseNumber &&   <FieldCustomNumberInput
                  className={classNames(css.floor, css.newInput)}
                  type="text"
                  name="licenseNumber"
                  id="licenseNumber"
                  label={licensePlateNumberLabel}
                  placeholder={licensePlateNumberPlaceholder}
                  disabled={true}
                />}
                {initialValues.licenseIssueDate && <FieldTextInput
                  className={classNames(css.floor, css.newInput)}
                  type="text"
                  name="licenseIssueDate"
                  id="licenseIssueDate"
                  label={issueDateLabel}
                  placeholder={issueDatePlaceholder}
                  // validate={required(postalCodeRequiredMsg)}
                  disabled={true}
                />}
              </div>: null}

              {initialValues.licenseExpiryDate || initialValues.status ? <div className={classNames(css.displayInlineContainer, css.halfInputs)}>
                {initialValues.licenseExpiryDate &&    <FieldCustomNumberInput
                  className={classNames(css.floor, css.newInput)}
                  type="text"
                  name="licenseExpiryDate"
                  id="licenseExpiryDate"
                  label={expiryDateLabel}
                  placeholder={expiryDatePlaceholder}
                  // disabled={shouldHideSubmitButton}
                />}
                {initialValues.status &&  <FieldTextInput
                  className={classNames(css.floor, css.newInput)}
                  type="text"
                  name="status"
                  id="status"
                  label={singPassStatusLabel}
                  placeholder={singPassStatusPlaceholder}
                  // validate={required(postalCodeRequiredMsg)}
                  disabled={true}
                />}
              </div>: null}

              {initialValues.class &&  <div className={classNames(css.displayInlineContainer, css.column6)}>
                <FieldTextInput
                  className={classNames(css.floor, css.newInput)}
                  type="text"
                  name="class"
                  id="class"
                  label={singPassClassLabel}
                  placeholder={singPassClassPlaceholder}
                  disabled={true}
                />
              </div> }

          {initialValues.expiryDate || initialValues.nricNumber ? <div className={css.titleSection}>
                  <h1 className={css.title}>NRIC / FIN</h1>
              </div> : null }

              {initialValues.expiryDate || initialValues.nricNumber ?  <div className={classNames(css.displayInlineContainer, css.halfInputs)}>

                {initialValues.expiryDate && <FieldCustomNumberInput
                  className={classNames(css.floor, css.newInput)}
                  type="text"
                  name="expiryDate"
                  id="expiryDate"
                  label={expiryDateLabel}
                  placeholder={expiryDatePlaceholder}
                  // disabled={shouldHideSubmitButton}
                />}
                 {initialValues.nricNumber && <FieldTextInput
                  className={classNames(css.floor, css.newInput)}
                  type="text"
                  name="nricNumber"
                  id="nricNumber"
                  label={nricNumberLabel}
                  placeholder={nricNumberPlaceholder}
                  // validate={required(postalCodeRequiredMsg)}
                  disabled={true}
                />}

              </div>: null}

              {initialValues.dateOfBirth &&  <div className={css.titleSection}>
                  <h1 className={css.title}>Date of Birth</h1>
              </div>}

              {initialValues.dateOfBirth &&  <div className={classNames(css.displayInlineContainer, css.column6)}>
                <FieldTextInput
                  className={classNames(css.floor, css.newInput)}
                  type="text"
                  name="dateOfBirth"
                  id="dateOfBirth"
                  label={dobLabel}
                  placeholder={dobPlaceholder}
                  // validate={required(postalCodeRequiredMsg)}
                  disabled={true}
                />
              </div>}

              <div className={classNames(css.verificationModalButton, css.singpassFormSubmit)}>
              <Button
                  className={classNames(css.submitButton, css.singpassFormButton)}
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                  ready={submitReady}
                  id={submitButtonId}
                >
                 <FormattedMessage id="Singpass.saveInfo" />
                </Button>
                {singPassDataClear ?  <div className={classNames(css.singPassSmallLoader)}><div></div><div></div><div></div><div></div></div>: <div className={css.cancelSingPassButton} onClick={() => handleClearData(userId)}>
                  Cancel & fill manually
                </div>}
              </div>
            </Form>
          );
        }}
      />
      {/* <div className={css.formSection}>

            </div> */}
    </div></>
  );
};
SingpassProfileFormComponent.propTypes = {
  intl: intlShape.isRequired,
};

const SingpassProfileForm = compose(injectIntl)(SingpassProfileFormComponent);

SingpassProfileForm.displayName = 'SingpassProfileForm';

export default SingpassProfileForm;
