import config from '../config';

import {
  REQUEST_STATUS__ERROR,
  REQUEST_STATUS__NULL,
  REQUEST_STATUS__PENDING,
  REQUEST_STATUS__SUCCESS,
} from '../util/constants';
import { get } from 'lodash';

const apiUrl = config.apiUrl;

// ================ Action types ================ //

export const GET_FUEL_PRICE__REQUEST = 'app/fuelPrice/GET_FUEL_PRICE__REQUEST';
export const GET_FUEL_PRICE__SUCCESS = 'app/fuelPrice/GET_FUEL_PRICE__SUCCESS';
export const GET_FUEL_PRICE__ERROR = 'app/fuelPrice/GET_FUEL_PRICE__ERROR';

// ================ Reducer ================ //

const initialState = {
  fuelPrice: {},
  getFuelPriceRequestStatus: REQUEST_STATUS__NULL,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case GET_FUEL_PRICE__REQUEST:
      return { ...state, getFuelPriceRequestStatus: REQUEST_STATUS__PENDING };

    case GET_FUEL_PRICE__SUCCESS:
      return { ...state, getFuelPriceRequestStatus: REQUEST_STATUS__SUCCESS, fuelPrice: payload };

    case GET_FUEL_PRICE__ERROR:
      return { ...state, getFuelPriceRequestStatus: REQUEST_STATUS__ERROR };

    default:
      return state;
  }
};

// ================ Selectors ================ //

export const $fuelPriceConfig = state => {
  return state.FuelPrice.fuelPrice;
};

export const $fuelPriceByListing = state => listing => {
  const fuelPriceConfig = $fuelPriceConfig(state);
  const brandName = get(listing, 'attributes.publicData.brandName', null);
  const modelName = get(listing, 'attributes.publicData.modelName', null);
  const key = brandName && modelName ? `${brandName}_${modelName}` : 'default';
  return fuelPriceConfig[key.toLowerCase()] || fuelPriceConfig['default'];
};

// ================ Action creators ================ //

export const getFuelPrice = () => ({
  type: GET_FUEL_PRICE__REQUEST,
});

export const getFuelPriceSuccess = fuelPrice => ({
  type: GET_FUEL_PRICE__SUCCESS,
  payload: fuelPrice,
});

export const getFuelPriceError = () => ({
  type: GET_FUEL_PRICE__ERROR,
});

// ================ Thunks ================ //

export const getFuelPriceConfig = () => async dispatch => {
  dispatch(getFuelPrice());
  try {
    const response = await fetch(apiUrl + "/api/car-prices/fuel-prices", {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    });
    const json = await response.json();
    dispatch(getFuelPriceSuccess(json.data));
  } catch (e) {
    dispatch(getFuelPriceError());
  }
};
