import React from 'react';
import { bool } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_IS_MILEAGE_PACKAGE, propTypes } from '../../util/types';

import css from './BookingBreakdownNew.css';

const LineItemNewMileagePackageRefundMaybe = props => {
  const { transaction, isCustomer, intl } = props;
  if (!transaction || transaction === null) return null;
  let mileagePaackageLineItem = null;

  if (isCustomer) {
    mileagePaackageLineItem = transaction.attributes.lineItems.find(
      item => item.code === LINE_ITEM_IS_MILEAGE_PACKAGE && item.reversal
    );
  }
  else {
    mileagePaackageLineItem = transaction.attributes.lineItems.find(
      item => item.code === LINE_ITEM_IS_MILEAGE_PACKAGE && item.reversal && 
      (item.includeFor &&item.includeFor.includes('provider'))
    );
  }
  
  if (!mileagePaackageLineItem || mileagePaackageLineItem.length === 0) {
    return null;
  }

  const commission = mileagePaackageLineItem.lineTotal;

  const formattedCommission = commission ? formatMoney(intl, commission, 1) : null;

  return formattedCommission ? (
    <div className={css.addOnLineItem}>
      <div className={css.totalLabel}>
        <FormattedMessage id="BookingBreakdown.refundMileagePackage" />
        {/* <Explanation text="Reduced liability with savings up to $6420" /> */}
      </div>
      <div className={css.itemValue}>{formattedCommission}</div>
    </div>
  ) : null;
};

LineItemNewMileagePackageRefundMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isCustomer: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemNewMileagePackageRefundMaybe;
