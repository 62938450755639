export const REQUEST_STATUS__NULL = 'null';
export const REQUEST_STATUS__PENDING = 'pending';
export const REQUEST_STATUS__SUCCESS = 'success';
export const REQUEST_STATUS__ERROR = 'error';

export const SignupPhoneExperiment = {
    name: 'SignupPhoneExperiment',
    A: 'CASE_A',
    B: 'CASE_B',
};

// export const TripFeesExperiment = {
//   name: 'TripFeesExperiment',
//   A: 'CASE_A',
//   B: 'CASE_B',
//   C: 'CASE_C',
//   D: 'CASE_D',
// };
