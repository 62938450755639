import classNames from 'classnames';
import React, { Component } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { FieldSelect, FieldTextAutoFillInput, Form, NamedLink } from '../../components';
import { fetchBrandlist, fetchModellist, fetchPricelist } from '../../util/carPriceHelper';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import * as validators from '../../util/validators';

import _ from 'lodash';
import css from './BAHHeroSection.css';

class BAHHeroSection extends Component {
  constructor (props) {
    super(props);
    this.state = {
      brandList: [],
      brandListOpen: false,
      modelList: [],
      modelListOpen: false,
      priceList: [],
      carAge: ''
    }
    this.agesOfCar = [
      "0-6 years old",
      "6-10 years old",
      "10+ years old"
    ]
  }


  componentDidMount = () => {
    fetchBrandlist()
      .then((response) => { return response.json() })
      .then((jsonData) => {
        this.setState({
          brandList: jsonData.map(x => ({ label: x.original_brand, key: x.normalized_brand }))
        })
      })
  }

  removeDuplicate = (data) => {
    data = data && data.length > 0 && data.filter((value, index, self) =>
      index === self.findIndex((t) => (
        t.normalized_model === value.normalized_model
      ))
    )
    return data;
  }

  getModellist = (brandName) => {
    fetchModellist(brandName)
      .then((response) => { return response.json() })
      .then((jsonData) => {
        if (jsonData) {
          const filteredData = this.removeDuplicate(jsonData);
          this.setState({
            modelList: filteredData.map(x => ({ label: x.original_model, key: x.normalized_model }))
          })
        }
      })
  }

  getPricelist = (brandName, modelName) => {
    const { onUseRentalCalculator } = this.props;
    onUseRentalCalculator();
    let normalized_brand = brandName.split(' ').join('_').toLowerCase();
    fetchPricelist(normalized_brand, modelName)
      .then((response) => { return response.json() })
      .then((jsonData) => {
        this.setState({
          priceList: jsonData
        })
      })
  }

  handleBrandList = (state) => {
    this.setState({
      brandListOpen: state,
    })
  }

  handleModelList = (state) => {
    this.setState({
      modelListOpen: state,
    })
  }

  handleHoverBug = (e) => {
    if (e.type === 'touchstart') {
      // Don't trigger mouseenter even if they hold
      if (e.stopImmediatePropagation) {
        e.stopImmediatePropagation();
      }
      // If $item is a link (<a>), don't go to said link on mobile, show menu instead
      e.preventDefault();
    }
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        onSubmit={(e) => { }}
        render={fieldRenderProps => {
          const {
            className,
            handleSubmit,
            intl,
            form,
            values,
            listYourCarButtonId,
            onListYourCar,
          } = fieldRenderProps;

          if (form.getFieldState('brand') && form.getFieldState('brand').active && !this.state.brandListOpen) {
            this.handleBrandList(true);
          } else if (form.getFieldState('brand') && !form.getFieldState('brand').active && this.state.brandListOpen) {
            this.handleBrandList(false);
          }

          if (form.getFieldState('model') && form.getFieldState('model').active && !this.state.modelListOpen) {
            this.handleModelList(true);
          } else if (form.getFieldState('model') && !form.getFieldState('model').active && this.state.modelListOpen) {
            this.handleModelList(false);
          }

          const brandPlaceholder = intl.formatMessage({
            id: 'BecomeAHostPage.brandPlaceholder',
          });
          const brandRequiredMessage = intl.formatMessage({
            id: 'BecomeAHostPage.brandRequired',
          });
          const brandRequired = validators.required(brandRequiredMessage);

          const modelPlaceholder = intl.formatMessage({
            id: 'BecomeAHostPage.modelPlaceholder',
          });
          const modelRequiredMessage = intl.formatMessage({
            id: 'BecomeAHostPage.modelRequired',
          });
          const modelRequired = validators.required(modelRequiredMessage);

          const ageOfCarPlaceholder = intl.formatMessage({
            id: 'BecomeAHostPage.ageOfCarPlaceholder',
          });
          const ageOfCarRequiredMessage = intl.formatMessage({
            id: 'BecomeAHostPage.ageOfCarRequired',
          });
          const ageOfCarRequired = validators.required(ageOfCarRequiredMessage);

          const milesPlaceholder = intl.formatMessage({
            id: 'BecomeAHostPage.milesPlaceholder',
          });

          const isThereRentalPrice = values && values.brand && values.model && this.state.priceList && this.state.priceList.price;
          const rentalPrice = isThereRentalPrice && this.state.priceList.price;

          return (
            <section
              className={css.heroSection}
            >
              <div className={css.mobileHero}>

              </div>
              <div className={css.searchBox}>
                <h1 className={css.searchBoxTitle}>
                  <FormattedMessage id="BecomeAHostPage.heroTitle" values={{ newline: (<br />) }} />
                </h1>
                <div className={css.searchBoxDesc}>
                  <FormattedMessage id="BecomeAHostPage.heroDesc" />
                </div>
                <Form
                  className={css.form}
                  onSubmit={e => {
                    this.submittedValues = values;
                    handleSubmit(e);
                  }}
                >
                  <div className={css.fieldsWrapper}>
                    <FieldTextAutoFillInput
                      inputClassName={css.input}
                      className={css.inputField}
                      type="text"
                      id="brandName"
                      name="brand"
                      placeholder={brandPlaceholder}
                      optionsList={this.state.brandList.filter(x => values.brand ? x.label.toLowerCase().includes(values.brand.toLowerCase()) : true)}
                      isOpenSuggest={this.state.brandListOpen}
                      chooseOption={(option) => {
                        form.change('brand', option.label)
                        form.change('model', null)
                        this.getModellist(option.key);
                        this.handleBrandList(false)
                      }}
                    />
                    <FieldTextAutoFillInput
                      inputClassName={css.input}
                      className={classNames(css.inputField)}
                      type="text"
                      id="modelName"
                      name="model"
                      placeholder={modelPlaceholder}
                      optionsList={this.state.modelList
                        .filter(x =>
                          values.model
                            ? x.label
                              .toString()
                              .toLowerCase()
                              .includes(values.model.toString().toLowerCase())
                            : true
                        )
                        .sort((a, b) =>
                          a.label
                            .toString()
                            .toLowerCase()
                            .localeCompare(b.label.toString().toLowerCase())
                        )}
                      isOpenSuggest={this.state.modelListOpen}
                      chooseOption={(option) => {
                        form.change('model', option.label)
                        this.getPricelist(values.brand, option.key);
                        this.handleModelList(false)
                      }}
                    />
                    <FieldSelect
                      selectClassName={css.selectField}
                      className={classNames(css.inputField, css.yearField)}
                      id="ageOfCar"
                      name="ageOfCar"
                      onChange={(e) => this.setState({ carAge: e.target.value })}
                    >
                      <option value="" disabled={true}>{ageOfCarPlaceholder}</option>
                      {
                        this.agesOfCar.map(age => <option value={age}>{age}</option>)
                      }
                    </FieldSelect>
                    {/* <FieldSelect
                      selectClassName={css.selectField}
                      className={classNames(css.inputField, css.yearField)}
                      id="ageOfCar"
                      name="ageOfCar"
                    >
                      <option value="" disabled={true}>{ageOfCarPlaceholder}</option>
                      <option value="under_5_years_old">0-5 year old</option>
                      <option value="under_10_years_old">6-10 year old</option>
                      <option value="more_than_10_years_old">10+ year old</option>
                    </FieldSelect> */}
                    {/* <FieldTextInput
                      inputClassName={css.input}
                      className={classNames(css.inputField, css.milesField)}
                      id="miles"
                      name="miles"
                      type="text"
                      placeholder={milesPlaceholder}

                    /> */}
                  </div>
                  <div className={css.footerBox}>
                    <div className={css.breakDown}>
                      ${values.brand && values.model && this.state.carAge && `${this.state.priceList}` &&
                        `${this.agesOfCar.indexOf(this.state.carAge) === 0 ?
                          this.state.priceList.price :
                          this.agesOfCar.indexOf(this.state.carAge) === 1 || this.agesOfCar.indexOf(this.state.carAge) === 2 ?
                          _.get(this.state.priceList,'price-old') : ""}`}
                      <span
                        className={css.explaination}
                        onTouchStart={this.handleHoverBug}
                        onMouseEnter={this.handleHoverBug}
                        onFocus={this.handleHoverBug}
                      >
                        <span className={css.explainationText}>
                          This estimate is based on the price recommended for your car by Drive lah for a full day trip.
                        </span>
                      </span>
                    </div>
                    <NamedLink
                      name="NewListingPage"
                      className={css.listYourCar}
                      onClick={onListYourCar}
                      id={listYourCarButtonId}
                    >
                      <FormattedMessage id="BecomeAHostPage.listYourCar" />
                    </NamedLink>
                  </div>
                </Form>
              </div>
            </section>
          );
        }}
      />
    );
  }
}

export default injectIntl(BAHHeroSection);
