import React from 'react';
import { NamedLink } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';

import css from './BAGHowToRent.css';
import carHowToRent from '../../assets/becomeAGuest/car_how_to_rent.png';

const BAGHowToRent = props => {
  const {
    isAuthenticated,
    onLoginOrSignupClick,
  } = props;

  return (
    <section className={css.whyDriveLahSection}>
      <div className={css.textBox}>
        <h2 className={css.title}>
          <FormattedMessage id="BecomeAGuestPage.howToRentTitle" values={{ newline: (<br />) }} />
        </h2>
        <div className={css.description}>
          <FormattedMessage id="BecomeAGuestPage.howToRentDesc" values={{newline: (<br />)}}/>
        </div>
        <div className={css.joinNowRow}>
          {isAuthenticated &&
          <NamedLink
            name="SearchPage"
            to={{
              search: `bounds=1.54840194%2C103.93560548%2C1.20243284%2C103.68841309`
            }}
            className={css.joinNow}
          >
            <FormattedMessage id="BecomeAGuestPage.searchForCars" />
          </NamedLink>
          }
          {!isAuthenticated &&
          <NamedLink
            name="SignupPage"
            className={css.joinNow}
            onClick={() => onLoginOrSignupClick('signup')}
          >
            <FormattedMessage id="BecomeAGuestPage.joinUsNowButton" />
          </NamedLink>
          }
          <img src={carHowToRent} className={css.image}/>
        </div>
      </div>
    </section>
  );
}

export default BAGHowToRent;
