import React, { Component } from 'react';
import { HelpLineNumber } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';

import css from './BAGFAQ.css';

class BAGFAQ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabOpen: -1
    }
  }

  openTab = (index) => {
    this.setState({
      tabOpen: index !== this.state.tabOpen ? index : -1
    })
  }

  render() {
    return (
      <section className={css.faqSection}>
        <div className={css.innerWrapper}>
          <div className={css.title}>
            <FormattedMessage id="BecomeAHostPage.faqTitle" values={{ newline: (<br />) }} />
          </div>
          <div className={css.listTextBox}>
            <div className={css.subBox}>
              <div className={css.question} onClick={() => this.openTab(0)}>
                What does peer to peer car sharing mean?
              </div>
              {this.state.tabOpen === 0 &&
                <div className={css.answer}>
                  This means that individuals that own a car can rent out their car to other
                  individuals when they don't need their car. The trip will be comprehensively
                  insured for the host's and guest's peace of mind
                </div>
              }
            </div>
            <div className={css.subBox}>
              <div className={css.question} onClick={() => this.openTab(1)}>
                Am I eligible to drive?
              </div>
              {this.state.tabOpen === 1 &&
                <div className={css.answer}>
                  All Singapore citizens/ PRs above 22 years with a valid SG license for 2 years or more are eligible. EP holders above 30 years of age with a min 5 year driving experience are eligible as well. For detailed conditions, please click
                  <a className={css.link}
                    href="https://help.drivelah.sg/en/articles/2776896-am-i-eligible-to-rent-a-car-on-drive-lah"> here.
                  </a>
                </div>
              }
            </div>
            <div className={css.subBox}>
              <div className={css.question} onClick={() => this.openTab(2)}>
                What are my key benefits with Drive lah?
              </div>
              {this.state.tabOpen === 2 &&
                <div className={css.answer}>
                  Drive lah is the most convenient access to cars in Singapore. We offer the largest variety of cars nearby at a click of a button. Drive lah utilizes unused car hours of individual car owners and that makes rentals on Drive lah typically 30-40% cheaper than traditional rental companies
                </div>
              }
            </div>
            <div className={css.subBox}>
              <div className={css.question} onClick={() => this.openTab(3)}>
                Can I take pets with me in the car?
              </div>
              {this.state.tabOpen === 3 &&
                <div className={css.answer}>
                  This decision is that of the host. In the car rules you can see whether the owner allows you take pets. Under each listing details, it is mentioned whether pets are allowed. You can also directly check with the host just in case.
                </div>
              }
            </div>
            <div className={css.subBox}>
              <div className={css.question} onClick={() => this.openTab(4)}>
                What protection do I have against car damage?
              </div>
              {this.state.tabOpen === 4 &&
                <div className={css.answer}>
                  All trips are comprehensively insured, which means full cover for 3rd party damages (people & property), and includes damages to the rental car and theft, and any injuries.
                </div>
              }
            </div>
            <div className={css.subBox}>
              <div className={css.question} onClick={() => this.openTab(5)}>
                Can I travel to Malaysia?
              </div>
              {this.state.tabOpen === 5 &&
                <div className={css.answer}>
                  This decision is that of the host. In the car rules you can see whether the owner allows you to drive to Malaysia. Under each listing details, it is mentioned whether the car is allowed to be driven to Malaysia or not.
                </div>
              }
            </div>
            <div className={css.subBox}>
              <div className={css.question} onClick={() => this.openTab(6)}>
                Can I cancel booking?
              </div>
              {this.state.tabOpen === 6 &&
                <div className={css.answer}>
                  Absolutely. You can cancel the booking in line with our <a className={css.link} href='https://help.drivelah.sg/en/articles/2776909-trip-cancellation-policy'
                  >cancellation policy</a>. There may be charges depending upon when you cancel the booking.
                </div>
              }
            </div>
          </div>
          <HelpLineNumber className={css.helpline} isWhiteText={true} />
        </div>
        <div className={css.seperateLine}></div>
      </section>
    )
  }
}

export default BAGFAQ;
