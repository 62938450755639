import React from 'react';
import { NamedLink } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';

import css from './BAHReadyToShare.css';

const BAHReadyToShare = props => {
  return (
    <section className={css.root}>
      <div className={css.readyToShareSection}>
        <div className={css.textBox}>
          <h6 className={css.title}>
            <FormattedMessage id="BecomeAHostPage.readyToShareTitle" values={{ newline: (<span className={css.newlineMobile}><br /></span>) }} />
          </h6>
          <NamedLink name="NewListingPage" className={css.listYourCar}>
            <FormattedMessage id="BecomeAHostPage.listYourCar" />
          </NamedLink>
        </div>
      </div>
      <div className={css.contactLineWrapper}>
        <div className={css.contactLine}>
          <span className={css.boldText}>
            <FormattedMessage className id="BecomeAHostPage.commercialCars"/>
          </span>
          <FormattedMessage id="BecomeAHostPage.pleaseCallUs" />
        </div>
      </div>
    </section>
  );
}

export default BAHReadyToShare;
