import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { get } from 'lodash';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { getDefaultTimeZoneOnBrowser } from '../../util/dates';
import BookingBreakdownNew from '../../components/BookingBreakdown/BookingBreakdownNew';
import config from '../../config';
import { getUserTxRole } from '../../util/transaction';
import { ensureBooking, ensureUser, restoreTransaction } from '../../util/data';
import { IconSpinner, Logo, NamedLink, Page } from '../../components';

import css from '../../containers/TripDetailsPage/TripDetailsPage.css';
import FuelInclusionBreakdown from '../BookingBreakdown/FuelInclusionBreakdown';
import { CUSTOMER, PROVIDER } from '../../util/constants/users';
import HostFuelPackageBlock from '../BookingBreakdown/HostFuelPackageBlock';
import UpdateBookingTxs from './UpdateBookingTxs';
import { getDefaultMileage } from './InfoSection';

const MAX_MOBILE_SCREEN_WIDTH = 768;
const MAX_TABLET_SCREEN_WIDTH = 1024;

// TransactionPage handles data loading for Sale and Order views to transaction pages in Inbox.
export const TripDetailsSection = props => {
  const {
    lastUpdateBookingTx,
    updateBookingTxs,
    fuelPrice,
    scrollingDisabled,
    transaction: parentTransaction,
    intl,
    transactionRole,
    viewport,
    currentUser,
    history,
    fuelTransaction,
  } = props;

  const [dataLoaded, setDataLoaded] = useState(false);
  const [isUsingMastercard, setIsUsingMastercard] = useState(false);

  useEffect(() => {
    if (parentTransaction && !dataLoaded) {
      setDataLoaded(true);
    }
  }, [parentTransaction, dataLoaded]);

  const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
  const isTabletLayout = viewport.width < MAX_TABLET_SCREEN_WIDTH;

  const currentListing = parentTransaction && parentTransaction.listing;
  const currentProvider = parentTransaction && ensureUser(parentTransaction.provider);
  const currentCustomer = parentTransaction && ensureUser(parentTransaction.customer);
  const listingTitle = currentListing && currentListing.attributes.title;
  const title = intl.formatMessage({ id: 'TripDetailsPage.title' }, { title: listingTitle });
  const isLoading = !dataLoaded;
  const parentCreatedAt = parentTransaction && parentTransaction.attributes.createdAt;
  const parentDateCreated = parentCreatedAt && moment(parentCreatedAt).format('Do MMM, YYYY');
  const isFuelInclusion = get(parentTransaction, 'attributes.protectedData.isFuelInclusion')
    || get(parentTransaction, 'attributes.metadata.isFuelInclusion')
  ;

  const currentUserRole =
    currentUser &&
    currentUser.id &&
    parentTransaction &&
    getUserTxRole(currentUser.id, parentTransaction);
  const isProviderRole = currentUserRole === PROVIDER;
  const isCustomerRole = currentUserRole === CUSTOMER;
  const addonstTransactions = get(parentTransaction, 'attributes.metadata.addonsTransactions');

  const pageProps = {
    title,
    scrollingDisabled,
    className: css.root,
  };

  const topbar = (
    <div className={css.topbar}>
      <NamedLink className={css.home} name="LandingPage">
        <Logo
          className={css.logoMobile}
          title={intl.formatMessage({
            id: 'CheckoutPage.goToLandingPage',
          })}
          format="mobile"
        />
        <Logo
          className={css.logoDesktop}
          alt={intl.formatMessage({
            id: 'CheckoutPage.goToLandingPage',
          })}
          format="desktop"
        />
      </NamedLink>
    </div>
  );

  if (isLoading) {
    return (
      <Page {...pageProps}>
        {topbar}
        <div className={css.loading}>
          <IconSpinner />
          <div>
            <FormattedMessage id={'TripDetailsPage.pleaseWait'} />
          </div>
        </div>
      </Page>
    );
  }

  //Handled timezone before and passed to the function.
  const tz = parentTransaction
    && parentTransaction.attributes
    && parentTransaction.attributes.protectedData
    &&  parentTransaction.attributes.protectedData.transactionTimezone

  const timeZone = getDefaultTimeZoneOnBrowser(tz) || 'Asia/Singapore';

  const txBooking = parentTransaction && ensureBooking(parentTransaction.booking);

  const breakdown = parentTransaction && parentTransaction.id && txBooking.id && (
    <BookingBreakdownNew
      lastUpdateBookingTx={lastUpdateBookingTx}
      timeZone={timeZone}
      className={css.bookingBreakdown}
      userRole={currentUserRole}
      unitType={config.bookingUnitType}
      transaction={restoreTransaction(parentTransaction, isUsingMastercard, transactionRole)}
      booking={txBooking}
      shouldShowMastercardPromoLineItem={isUsingMastercard}
      isTripDetailsPage={true}
      fuelPrice={fuelPrice}
    />
  );

  return (
    <div className={css.contentContainer}>
      <div className={css.contentRow}>
        <div className={css.detailsContainerMain}>
          <h3 className={css.containerTitle}>
            <FormattedMessage
              id={'TripDetailsPage.parentTransactionTitle'}
              values={{ date: parentDateCreated }}
            />
          </h3>
          {breakdown}
        </div>
        <UpdateBookingTxs
          lastUpdateBookingTx={lastUpdateBookingTx}
          updateBookingTxs={updateBookingTxs}
          timeZone={timeZone}
          currentUserRole={currentUserRole}
          isUsingMastercard={isUsingMastercard}
        />
        <FuelInclusionBreakdown
          transaction={restoreTransaction(fuelTransaction, isUsingMastercard)}
          fuelTransaction={fuelTransaction}
          timeZone={timeZone}
          currentUserRole={currentUserRole}
          booking={txBooking}
          intl={intl}
        />
        <HostFuelPackageBlock
          isVisible={isProviderRole && isFuelInclusion && !fuelTransaction}
        />
        {addonstTransactions &&
        addonstTransactions.map(i => {
          const childTransaction = i.childTransaction && JSON.parse(i.childTransaction);
          console.log("childTransaction", childTransaction);
          const txBooking = childTransaction && ensureBooking(childTransaction.booking);
          const createdAt = childTransaction && childTransaction.attributes.createdAt;
          const dateCreated = createdAt && moment(createdAt).format('Do MMM, YYYY');
          const isDelivery = i.isDelivery;
          const isExcessReduction = i.isExcessReduction;
          const isFuelInclusion = i.isFuelInclusion;

          return childTransaction ? (
            !isDelivery && isExcessReduction && isProviderRole ? null : (
              <div className={css.detailsContainerMain}>
                <h3 className={css.containerTitle}>
                  <FormattedMessage
                    id={'TripDetailsPage.addonsTitle'}
                    values={{ date: dateCreated }}
                  />
                </h3>
                <BookingBreakdownNew
                  lastUpdateBookingTx={lastUpdateBookingTx}
                  timeZone={timeZone}
                  className={css.bookingBreakdown}
                  userRole={currentUserRole}
                  unitType={config.bookingUnitType}
                  transaction={restoreTransaction(childTransaction, isUsingMastercard, transactionRole)}
                  booking={txBooking}
                  isAddons={true}
                  isTripDetailsPage={true}
                  fuelPrice={fuelPrice}
                />
              </div>
            )
          ) : null;
        })}
        {isProviderRole && (
          <div className={css.detailsContainerMain}>
            <div style={{
              display: "flex",
              justifyContent: "space-between"
            }}>
              <div>
                Total mileage
              </div>
              <div>
                {parentTransaction && getDefaultMileage(parentTransaction, true)}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

TripDetailsSection.defaultProps = {
  currentUser: null,
  transaction: null,
  parentTransaction: null,
};

const { bool, func, oneOf, shape, string, arrayOf, number } = PropTypes;

TripDetailsSection.propTypes = {
  currentUser: propTypes.currentUser,
  scrollingDisabled: bool.isRequired,
  transaction: propTypes.transaction,
  parentTransaction: propTypes.transaction,
  transactionRole: oneOf([PROVIDER, CUSTOMER]).isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default TripDetailsSection;
