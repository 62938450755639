import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NamedLink } from '../../components';
import css from './TabNav.css';
import IconCheck from '../IconCheck/IconCheck';
import { getEventsByBucket } from '../../util/gtm/gtmCreateProperties';
import { triggerAnalyticsEvent } from '../../util/amplitudeMapEvents';
import { event_trigger_ids } from '../../util/analyticsConstants';

const Tab = props => {
  const {
    className,
    id,
    disabled,
    text,
    selected,
    linkProps,
    linkClass,
    selectedClass,
    customLinkClass,
    customSelectedLinkClass,
    isCompleted,
    user
  } = props;

  const [eventData, setEventData] = useState(null);

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const response = await getEventsByBucket('browsing');
        setEventData(response.data);
      } catch (error) {
        console.error('Error fetching event data:', error);
      }
    };

    fetchEventData();
  }, []);

  const onTabClickEventHandle = () => {
    try {
      if (!linkProps || !linkProps.name) return;

      const eventConfig = {
        ContactDetailsPage: {
          event_id: event_trigger_ids.PROFILE_VIEW_CONTACT_INFORMATION,
          button: 'Contact information',
          page: 'ContactInfoScreen'
        },
        AddressDetailsPage: {
          event_id: event_trigger_ids.PROFILE_VIEW_ADDRESS_DETAILS,
          button: 'Address Details',
          page: 'AddressDetailScreen'
        },
        PaymentMethodsPage: [
          {
            event_id: event_trigger_ids.PAYMENTS_VIEW_PAST_PAYMENTS,
            button: 'Payment details',
            page: 'PaymentMethodsScreen'
          },
          {
            event_id: event_trigger_ids.PAYMENTS_VIEW_PAYMENT_METHOD,
            button: 'Payment Method details',
            page: 'PaymentMethodsScreen'
          }
        ],
        StripePayoutPage: {
          event_id: event_trigger_ids.PAYMENTS_VIEW_PAYOUT_PREFERENCES,
          button: 'Payouts',
          page: 'PayementsScreen'
        },
        CreditsDetailsPage: {
          event_id: event_trigger_ids.PAYMENTS_VIEW_CREDITS,
          button: 'Credits',
           page: 'CreditScreen'
        },
        PasswordChangePage: {
          event_id: event_trigger_ids.PROFILE_CHANGE_PASSWORD,
          button: 'Security',
          page: 'PasswordChangeScreen'
        }
      };

      const eventsToTrigger = eventConfig[linkProps.name];
      // console.log("Events to trigger", eventsToTrigger);
      if(!eventsToTrigger) {
        return;
      }
      const triggerEvents = Array.isArray(eventsToTrigger) ? eventsToTrigger : [eventsToTrigger];

      // console.log("Trigger event", triggerEvents);

      triggerEvents.forEach(event => {
        // console.log("event to be triggered AAAA", event);
        triggerAnalyticsEvent({
          event_id: event.event_id,
          eventData,
          props: {
            ui: { button: event.button, page: event.page || undefined }
          },
          userId: user ? user.id.uuid : null
        });
      });

    } catch (error) {
      console.error('Error triggering analytics event:', error);
    }
  };

  const linkClasses = classNames(css.link, linkClass, customLinkClass, {
    [css.selectedLink]: selected,
    [selectedClass]: selected,
    [customSelectedLinkClass]: selected,
    [css.disabled]: disabled,
  });

  return (
    <div id={id} className={className}>
      <NamedLink className={linkClasses} {...linkProps} onClick={onTabClickEventHandle}>
        {text}
      </NamedLink>
      {isCompleted && <IconCheck />}
    </div>
  );
};

Tab.defaultProps = {
  className: null,
  disabled: false,
  selected: false,
  isCompleted: false,
  user: null,
};

Tab.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  text: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  linkProps: PropTypes.object.isRequired,
  linkClass: PropTypes.string,
  selectedClass: PropTypes.string,
  customLinkClass: PropTypes.string,
  customSelectedLinkClass: PropTypes.string,
  isCompleted: PropTypes.bool,
  user: PropTypes.object,
};

const TabNav = props => {
  const {
    className,
    rootClassName,
    tabRootClassName,
    tabs,
    linkClass,
    selectedClass,
    customLinkClass,
    customSelectedLinkClass,
    user
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const tabClasses = tabRootClassName || css.tab;

  return (
    <nav className={classes}>
      {tabs.map((tab, index) => (
        <Tab
          key={tab.id || index}
          id={tab.id || index.toString()}
          user={user}
          className={tabClasses}
          linkClass={linkClass}
          selectedClass={selectedClass}
          customLinkClass={customLinkClass}
          customSelectedLinkClass={customSelectedLinkClass}
          {...tab}
        />
      ))}
    </nav>
  );
};

TabNav.defaultProps = {
  className: null,
  rootClassName: null,
  tabRootClassName: null,
};

TabNav.propTypes = {
  className: PropTypes.string,
  rootClassName: PropTypes.string,
  tabRootClassName: PropTypes.string,
  tabs: PropTypes.arrayOf(PropTypes.object).isRequired,
  linkClass: PropTypes.string,
  selectedClass: PropTypes.string,
  customLinkClass: PropTypes.string,
  customSelectedLinkClass: PropTypes.string,
  user: PropTypes.object,
};

export default TabNav;
