import React, { useState } from 'react';
import css from './AntiCompetitionLandingPage.css';
import { IconSpinner, Modal, NamedLink } from '../../components';
import { formatMoney } from '../../util/currency';
import config from '../../config';
import { types } from '../../util/sdkLoader';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { initiateEventFromListing } from '../../util/gtm/gtmHelpers';
import { gSend, GTAG_ACTIONS } from '../../util/gtag';
import { EVENT_SEARCH_PERFORMED } from '../../util/gtm/gtmConstants';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import imageSource from '../../util/imageSource';

const { Money } = types;

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const ComparisonGraph = ({
  selectedData = [],
  handleSignupRedirect,
  loading,
  signUpCodeAmount,
  onManageDisableScrolling,
  assumptions,
  isMobileLayout,
  durationFilter,
  selectedCategory,
}) => {
  const labels =
    selectedData &&
    selectedData.numbers &&
    selectedData.numbers.length > 0 &&
    selectedData.numbers.map(i => {
      if (i === 0) {
        return isMobileLayout ? 'N/A' : 'Not available';
      }
      return '$' + i;
    });
  const data = {
    labels,
    datasets: [
      {
        id: 1,
        // label: ['Drive lah', 'Shariot', 'Getgo', 'Tribecar'],
        data: selectedData.numbers,
        backgroundColor: ['#18A3AD', '#16A23B', '#18F8B8', '#127ABF', "#059EE3"],
        borderRadius: isMobileLayout ? 10 : 15,
      },
    ],
  };
  const options = {
    responsive: true,
    plugins: {
      labels: {
        font: {
          size: 20,
        },
      },
      legend: {
        display: false,
        labels: {
          font: {
            size: 20,
          },
        },
      },
      title: {
        display: false,
      },
    },
    barThickness: isMobileLayout ? 45 : 110,
    scales: {
      y: {
        suggestedMin: isMobileLayout ? 80 : 0,
        suggestedMax: 80,
        borderColor: 'red',
        display: !isMobileLayout,
        ticks: {
          callback: function(value, index, ticks) {
            return '$' + value;
          },
          font: {
            size: 20,
            weight: 'normal',
            family: "'sofiapro', Helvetica, Arial, sans-serif",
          },
        },
      },
      x: {
        ticks: {
          font: {
            size: isMobileLayout ? 16 : 30,
            weight: 'bold',
            family: "'sofiapro', Helvetica, Arial, sans-serif",
          },
          color: 'rgba(13, 103, 134, 1)',
        },
      },
    },
  };
  const [isOpen, setOpen] = useState(false);

  const intl = useIntl();
  const currentUser = useSelector(state => state.user.currentUser);
  const buttonText = loading ? (
    <div className={css.loadingWrapper}>
      <IconSpinner className={css.loadingSpinner} />
    </div>
  ) : currentUser && currentUser.id ? (
    'Find your ideal car'
  ) : signUpCodeAmount && signUpCodeAmount > 0 ? (
    `Sign up ${
      signUpCodeAmount
        ? `& get ${formatMoney(intl, new Money(signUpCodeAmount, config.currency))} credits`
        : '$0'
    }`
  ) : (
    <FormattedMessage id={'HostSignUpPromoLandingPage.signUpNow'} />
  );

  const carCompanies = selectedCategory.carCompanies || [];
  return (
    <div className={css.comparisonGraphWrapper}>
      <div className={css.comparisonGraph}>
        {selectedData.numbers ? (
          <>
            <div className={css.comparisonHighlight}>
              <span className={css.highlightIcon}>
                <img src={imageSource.antiCompetition1.hotSaleIcon} alt="Closest competitor" />
              </span>
              <p>{selectedData.stateConclusion}</p>
            </div>
            <div className={css.comparisonGraphWrapper}>
              <div className={css.comparisonLegends}>
                <div className={css.comparisonLegendsRow}>
                  {carCompanies &&
                    carCompanies.length > 0 &&
                    carCompanies.map(company => {
                      const gridClassName =
                        carCompanies.length === 5
                          ? css.comparisonLegendsColumn5
                          : css.comparisonLegendsColumn4;
                      return (
                        <div className={gridClassName}>
                          <div className={css.legendThumbs}>
                            <img src={company.companyLogo} alt={company.companyName} />
                          </div>
                        </div>
                      );
                    })}
                  {/* <div className={css.comparisonLegendsColumn}>
                    <div className={css.legendThumbs}>
                      <img src={drivelahSVG} alt="" />
                    </div>
                  </div>
                  <div className={css.comparisonLegendsColumn}>
                    <div className={css.legendThumbs}>
                      <img src={shariot} alt="" />
                    </div>
                  </div>
                  <div className={css.comparisonLegendsColumn}>
                    <div className={css.legendThumbs}>
                      <img src={getGo} alt="" />
                    </div>
                  </div>
                  <div className={css.comparisonLegendsColumn}>
                    <div className={css.legendThumbs}>
                      <img src={tribecar} alt="" />
                    </div>
                  </div> */}
                </div>
              </div>
              <Bar options={options} data={data} />
            </div>
          </>
        ) : (
          <div className={css.noDataAvailable}>No comparison data available</div>
        )}
      </div>
      <div className={css.comparisonGraphBottomSection}>
        <button type="button" className={css.namedLink} onClick={() => setOpen(true)}>
          View Assumptions{' '}
          <i className={css.buttonIcon}>
            <img src={imageSource.antiCompetition.arrowRight} alt="" />
          </i>
        </button>
        {currentUser && currentUser.id ? (
          <div className={css.signUpTitle}>
            <NamedLink
              name="SearchPage"
              to={{
                search:
                  '?address=Singapore&bounds=1.4708809%2C104.04157989999999%2C1.216611%2C103.60650989999999',
              }}
              className={css.btnMore}
              onClick={() => {
                // initiateEventFromListing({
                //   props: {
                //     searchParams: {
                //       bounds: {
                //         _sdkType: 'LatLngBounds',
                //         ne: {
                //           _sdkType: 'LatLng',
                //           lat: 1.4708809,
                //           lng: 104.04157989999999,
                //         },
                //         sw: {
                //           _sdkType: 'LatLng',
                //           lat: 1.216611,
                //           lng: 103.60650989999999,
                //         },
                //       },
                //     },
                //   },
                //   event: EVENT_SEARCH_PERFORMED,
                // });
                gSend(GTAG_ACTIONS.ACTION_SEARCH, {
                  // TODO: Assign proper details.
                  search_location: `address=Singapore&bounds=1.4708809%2C104.04157989999999%2C1.216611%2C103.60650989999999`,
                  search_pickupdate: `NA`,
                  search_pickuptime: `NA`,
                  search_dropoffdate: `NA`,
                  search_dropofftime: `NA`,
                });
              }}
            >
              {buttonText}
            </NamedLink>
          </div>
        ) : (
          <div className={css.signUpTitle}>
            <NamedLink name="SignupPage" onClick={handleSignupRedirect} className={css.btnMore}>
              {buttonText}
            </NamedLink>
          </div>
        )}
      </div>
      <AssumptionModal
        assumptions={assumptions}
        onManageDisableScrolling={onManageDisableScrolling}
        isOpen={isOpen}
        setOpen={setOpen}
        durationFilter={durationFilter}
      />
    </div>
  );
};

const AssumptionModal = ({
  assumptions,
  isOpen,
  setOpen,
  onManageDisableScrolling,
  durationFilter,
}) => {
  const getBasicAssumptions = assumptions && assumptions.basicAssumptions.split('&&&');
  return (
    <Modal
      id="AssumptionModal"
      isOpen={isOpen}
      onClose={() => setOpen(false)}
      containerClassName={css.modalContainer}
      onManageDisableScrolling={onManageDisableScrolling}
      closeButtonMessage={<FormattedMessage id="ModalMissingInformation.closeStaticModal" />}
    >
      <div className={css.insuranceListingWrapper}>
        <h3>Assumptions</h3>
        <ul className={css.insurancePointsList}>
          {getBasicAssumptions &&
            getBasicAssumptions.length > 0 &&
            getBasicAssumptions.map((assumption, index) => {
              return <li>{assumption}</li>;
            })}
          {/* {durationFilter === 6 ? (
            <li>{assumptions['6HourAssumption']}</li>
          ) : durationFilter === 24 ? (
            <li>{assumptions['24HourAssumption']}</li>
          ) : (
            ''
          )} */}
        </ul>
      </div>
    </Modal>
  );
};

export default ComparisonGraph;
