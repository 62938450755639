/*
 * Marketplace specific configuration.
 */
import React from 'react';
import { FormattedMessage } from './util/reactIntl';
import moment from 'moment';

export const defaultTitle = 'e.g. Jay’s Toyota Altis 2016';
export const defaultDescription = '';

export const yesNo = [
  {
    key: 'yes',
    label: 'Yes',
  },
  {
    key: 'no',
    label: 'No',
  },
];

export const privateInsurerList = [
  {
    key: 'tokio',
    label: 'TOKIO',
  },
  {
    key: 'ntuc',
    label: 'NTUC',
  },
];

export const insurances = [
  {
    key: 'private',
    label: 'Private',
  },
  {
    key: 'commercial',
    label: 'Commercial',
  },
];

export const keyRules = [
  {
    key: 'No Pets',
    label: 'No Pets',
  },
  {
    key: 'Not suitable for kids',
    label: 'Not suitable for kids',
  },
  {
    key: 'No smoking',
    label: 'No smoking',
  },
  {
    key: 'Not permitted to drive to Malaysia',
    label: 'Not permitted to drive to Malaysia',
  },
];

export const keyFeatures = [
  {
    key: 'all_wheel_drive',
    label: 'All wheel drive',
  },
  {
    key: 'aux_input',
    label: 'Aux Input',
  },
  {
    key: 'bluetooth',
    label: 'Bluetooth',
  },
  {
    key: 'child_seat',
    label: 'Child seat',
  },
  {
    key: 'cruise_control',
    label: 'Cruise control',
  },
  {
    key: 'gps',
    label: 'GPS',
  },
  {
    key: 'rear_camera',
    label: 'Rear Camera',
  },
  {
    key: 'sunroof',
    label: 'Sunroof',
  },
  {
    key: 'usb_charger',
    label: 'USB Charger',
  },
  {
    key: 'usb_input',
    label: 'USB Input',
  },
];

export const transmissions = [
  {
    key: 'automatic',
    label: 'Automatic',
  },
  {
    key: 'manual',
    label: 'Manual',
  },
];

export const typeOfFuel = [
  {
    key: 'petrol',
    label: 'Petrol',
  },
  {
    key: 'diesel',
    label: 'Diesel',
  },
  {
    key: 'lpg',
    label: 'LPG',
  },
  {
    key: 'electric',
    label: 'Electric',
  },
  {
    key: 'hybrid',
    label: 'Hybrid',
  },
];

export const fuelTypeFilters = [
  {
    key: 'petrol',
    label: 'Petrol',
  },
  {
    key: 'diesel',
    label: 'Diesel',
  },
  {
    key: 'lpg',
    label: 'LPG',
  },
  {
    key: 'electric',
    label: 'Electric',
  },
  {
    key: 'hybrid',
    label: 'Hybrid',
  },
];

export const fuelTypes = typeOfFuel;

export const canDriveToMalaysia = [
  {
    key: 'true',
    label: 'Yes',
  },
];

export const isInstantBooking = [
  {
    key: 'true',
    label: 'Yes',
  },
];

export const isPHV = [
  {
    key: 'true',
    label: 'Yes',
  },
  {
    key: 'false',
    label: 'No',
  },
];

// export const numOfSeats = [
//   {
//     key: "4",
//     label: "4 or more",
//   },
//   {
//     key: "5",
//     label: "5 or more",
//   },
//   {
//     key: "6",
//     label: "6 or more",
//   },
//   {
//     key: "7",
//     label: "7 or more",
//   },
// ]

export const categories = [
  { key: 'compact', label: 'Compact' },
  { key: 'standard_suv', label: 'Standard SUV' },
  { key: 'standard_sedan', label: 'Standard Sedan' },
  { key: 'luxury_sedan', label: 'Luxury Sedan' },
  { key: 'luxury_suv', label: 'Luxury SUV' },
  { key: 'supercar', label: 'Supercar' },
  { key: 'mpv', label: 'Multi purpose car' },
];

export const brands = [
  {
    key: 'alfa_romeo',
    label: 'Alfa Romeo',
  },
  {
    key: 'aston_martin',
    label: 'Aston Martin',
  },
  {
    key: 'audi',
    label: 'Audi ',
  },
  {
    key: 'bentley',
    label: 'Bentley',
  },
  {
    key: 'bmw',
    label: 'BMW ',
  },
  {
    key: 'bugatti',
    label: 'Bugatti',
  },
  {
    key: 'byd',
    label: 'BYD',
  },
  {
    key: 'chevrolet',
    label: 'Chevrolet',
  },
  {
    key: 'chrysler',
    label: 'Chrysler ',
  },
  {
    key: 'citoen',
    label: 'Citroen',
  },
  {
    key: 'citroen',
    label: 'Citroen',
  },
  {
    key: 'daewoo',
    label: 'Daewoo',
  },
  {
    key: 'daihatsu',
    label: 'Daihatsu',
  },
  {
    key: 'datsun',
    label: 'Datsun',
  },
  {
    key: 'dodge',
    label: 'Dodge ',
  },
  {
    key: 'ferrari',
    label: 'Ferrari',
  },
  {
    key: 'fiat',
    label: 'Fiat',
  },
  {
    key: 'ford',
    label: 'Ford',
  },
  {
    key: 'hafei',
    label: 'Hafei',
  },
  {
    key: 'honda',
    label: 'Honda',
  },
  {
    key: 'hyundai',
    label: 'Hyundai',
  },
  {
    key: 'infiniti',
    label: 'Infiniti',
  },
  {
    key: 'isuzu',
    label: 'Isuzu',
  },
  {
    key: 'jaguar',
    label: 'Jaguar',
  },
  {
    key: 'jeep',
    label: 'Jeep',
  },
  {
    key: 'kia',
    label: 'Kia',
  },
  {
    key: 'lada',
    label: 'Lada',
  },
  {
    key: 'lamborghini',
    label: 'Lamborghini',
  },
  {
    key: 'lancia',
    label: 'Lancia',
  },
  {
    key: 'land_rover',
    label: 'Land Rover',
  },
  {
    key: 'lexus',
    label: 'Lexus',
  },
  {
    key: 'lotus',
    label: 'Lotus',
  },
  {
    key: 'maserati',
    label: 'Maserati',
  },
  {
    key: 'maybach',
    label: 'Maybach',
  },
  {
    key: 'mazda',
    label: 'Mazda ',
  },
  {
    key: 'mclaren',
    label: 'McLaren',
  },
  {
    key: 'mercedes_benz',
    label: 'Mercedes Benz',
  },
  {
    key: 'mg',
    label: 'MG',
  },
  {
    key: 'mini',
    label: 'Mini',
  },
  {
    key: 'mitsubishi',
    label: 'Mitsubishi',
  },
  {
    key: 'nissan',
    label: 'Nissan',
  },
  {
    key: 'opel',
    label: 'Opel',
  },
  {
    key: 'perodua',
    label: 'Perodua',
  },
  {
    key: 'peugeot',
    label: 'Peugeot',
  },
  {
    key: 'porsche',
    label: 'Porsche',
  },
  {
    key: 'proton',
    label: 'Proton',
  },
  {
    key: 'renault',
    label: 'Renault',
  },
  {
    key: 'rolls_royce',
    label: 'Rolls Royce',
  },
  {
    key: 'rover',
    label: 'Rover',
  },
  {
    key: 'saab',
    label: 'Saab',
  },
  {
    key: 'seat',
    label: 'Seat',
  },
  {
    key: 'skoda',
    label: 'Skoda',
  },
  {
    key: 'ssanyong',
    label: 'Ssanyong',
  },
  {
    key: 'subaru',
    label: 'Subaru',
  },
  {
    key: 'suzuki',
    label: 'Suzuki',
  },
  {
    key: 'tesla',
    label: 'Tesla',
  },
  {
    key: 'toyota',
    label: 'Toyota',
  },
  {
    key: 'volkswagen',
    label: 'Volkswagen',
  },
  {
    key: 'volvo',
    label: 'Volvo',
  },
];

export const drivenKm = [
  { key: 'zero_to_ten', label: '0-10,000 kms' },
  { key: 'ten_to_thirty', label: '10 – 30,000 kms' },
  { key: 'thiry_to_sixty', label: '30 – 60,000 kms' },
  { key: 'sixty_to_ninety', label: '60 – 90,000 kms' },
  { key: 'ninety_one_thirty', label: '90 – 130,000 kms' },
  { key: 'more_than_thirty', label: '130,000 kms+' },
];

export const getManufactureYearData = () => {
  let currentYear = new Date().getFullYear();
  let startYear = 2001;
  let years = [];
  while (startYear <= currentYear) {
    let dict = {
      key: startYear.toString(),
      label: startYear.toString(),
      isSelected: false,
    };
    startYear = startYear + 1;
    years.push(dict);
  }
  return years.reverse();
};

export const timeToNearestStop = [
  { key: 'zero_to_five', label: '0-5 minutes away' },
  { key: 'five_to_ten', label: '5-10 minutes away' },
  { key: 'ten_plus', label: '10+ minutes away' },
];

export const phoneCode = [
  { key: '+65', label: 'Singapore (+65)', cc: 'SG' },
  { key: '+84', label: 'Vietnam (+84)', cc: 'VN' },
  { key: '+48', label: 'Poland (+48)', cc: 'PL' },
  { key: '+44', label: 'England (+44)', cc: 'GB' },
  { key: '+61', label: 'Australia (+61)', cc: 'AU' },
];

export const roles = [
  { key: 'renter', label: 'Rent a car'},
  { key: 'host', label: 'Share your car'}
]

// Price filter configuration
// Note: unlike most prices this is not handled in subunits
export const priceFilterConfig = {
  min: 0,
  max: 1000,
  step: 1,
};

const timeSlots = Array.apply(null, { length: 48 })
  .map(Function.call, Number)
  .map(x => {
    let hour = parseInt(x / 2);
    let prefix = hour > 11 ? 'pm' : 'am';
    hour = hour > 12 ? hour - 12 : hour;
    let min = (x % 2) * 30;
    let value = `${(hour < 10 ? '0' : '') + hour}:${(min < 10 ? '0' : '') + min} ${prefix}`;
    return {
      key: value,
      label: value,
      hour: prefix === 'am' ? hour : hour === 12 ? hour : hour + 12,
      minutes: min,
    };
  });

export const timeSetInput = timeSlots;
// Distance filter configuration
export const distanceFilterConfig = {
  min: 0,
  max: 25,
  step: 1,
};

export const numberPassengerConfig = {
  min: 1,
  max: 8,
  step: 1,
};

export const reviews = [
  { key: '2', label: '2 stars' },
  { key: '3', label: '3 stars' },
  { key: '4', label: '4 stars' },
  { key: '5', label: '5 stars' },
];

export const genders = [{ key: 'male', label: 'Male' }, { key: 'female', label: 'Female' }];

export const tabs = [
  {
    text: <FormattedMessage id="AccountSettingPage.contactDetailsTabTitle" />,
    selected: false,
    linkProps: {
      name: 'ContactDetailsPage',
    },
  },
  {
    text: <FormattedMessage id="AccountSettingPage.personalDetailsTabTitle" />,
    selected: false,
    linkProps: {
      name: 'PersonalDetailsPage',
    },
  },
  {
    text: <FormattedMessage id="AccountSettingPage.addressDetailsTabTitle" />,
    selected: false,
    linkProps: {
      name: 'AddressDetailsPage',
    },
  },
  {
    text: <FormattedMessage id="AccountSettingPage.passwordTabTitle" />,
    selected: false,
    linkProps: {
      name: 'PasswordChangePage',
    },
  },
  {
    text: <FormattedMessage id="AccountSettingPage.driverVerificationTabTitle" />,
    selected: false,
    linkProps: {
      name: 'AccountSettingDriverVerificationPage',
    },
  },
  {
    text: <FormattedMessage id="AccountSettingPage.paymentsTabTitle" />,
    selected: false,
    linkProps: {
      name: 'PaymentMethodsPage',
    },
  },
  {
    text: <FormattedMessage id="StripePayoutPage.heading" />,
    selected: false,
    linkProps: {
      name: 'StripePayoutPage',
    },
  },
  {
    text: <FormattedMessage id="AccountSettingPage.syncCalenderTabTitle" />,
    selected: false,
    linkProps: {
      name: 'SyncCalenderPage',
    },
  },
  {
    text: <FormattedMessage id="AccountSettingPage.credits" />,
    selected: false,
    linkProps: {
      name: 'CreditsDetailsPage',
    },
  },
];



export const bankCodeReferences = [
  // { label: 'POSB', key: '7171', description: '' },
  { label: 'Maybank', key: '7302', description: '' },
  { label: 'POSB / DBS', key: '7171', description: '' },
  { label: 'UOB', key: '7375', description: '' },
  // { label: 'UOB (for Van accounts only)', key: '7375', description: '' },
  { label: 'FEB', key: '7199', description: '' },
  { label: 'OCBC', key: '7339', description: '' },
  { label: 'HSBC Corp', key: '7232', description: '' },
  { label: 'HSBC Personal', key: '9548', description: '' },
  { label: 'SBI', key: '7991', description: '' },
  { label: 'Standard Chartered', key: '7144', description: '' },
  { label: 'Bangkok Bank Public Company Ltd.', key: '7047', description: '' },
  { label: 'P.T. Bank Negara Indonesia (Persero) Tbk', key: '7056', description: '' },
  { label: 'Bank of America, NA', key: '7065', description: '' },
  { label: 'Bank of China Limited', key: '7083', description: '' },
  { label: 'The Bank of East Asia Ltd', key: '7092', description: '' },
  { label: 'Bank of India Ltd.', key: '7108', description: '' },
  { label: 'Singapore Island Bank Ltd.', key: '7117', description: '' },
  { label: 'JP Morgan Chase', key: '7153', description: '' },
  { label: 'Citibank', key: '7214', description: '' },
  { label: 'Indian Bank', key: '7241', description: '' },
  { label: 'Indian Overseas Bank', key: '7250', description: '' },
  { label: 'Malayan Banking Berhad', key: '7302', description: '' },
  { label: 'CTBC Bank', key: '9353', description: '' },
  { label: 'ICICI Bank Ltd.', key: '9186', description: '' },
  { label: 'Industrial & Commercial Bank of China', key: '8712', description: '' },
  { label: 'Commerzbank AG', key: '8606', description: '' },
  { label: 'Skandinaviska Enskilda Banken AB (publ)', key: '8527', description: '' },
  { label: 'Nordea Bank Finland Plc', key: '8518', description: '' },
  { label: 'Svenska Handelsbanken AB (publ)', key: '8493', description: '' },
  { label: 'Intesa Sanpaolo S.P.A.', key: '8350', description: '' },
  { label: 'National Australia Bank Ltd', key: '8077', description: '' },
  { label: 'CIMB Bank Berhad', key: '7986', description: '' },
  { label: 'Australia & New Zealand Banking Group Ltd', key: '7931', description: '' },
  { label: 'UCO Bank', key: '7357', description: '' },
  { label: 'RHB Bank Berhad', key: '7366', description: '' },
  { label: 'BNP Paribas', key: '7418', description: '' },
  { label: 'Test', key: '1100', description: '' },
];

// Activate booking dates filter on search page
export const dateRangeFilterConfig = {
  active: true,
};

export const hostCancelReasons = [
  { key: 'need_car_on_book_day', label: 'I need the car on the day of the booking' },
  { key: 'not_interested_sharing', label: 'I am no longer interested in sharing my car' },
  { key: 'accept_by_accident', label: 'I accepted the booking by accident' },
  { key: 'uncomfortable_with_guest', label: 'I am uncomfortable with the guest' },
  { key: 'other', label: 'Other' },
];

export const guestCancelReasons = [
  { key: 'do_not_need_car_anymore', label: 'I no longer need a car for rental' },
  { key: 'change_travel_plan', label: 'My travel plans have changed' },
  { key: 'book_by_accident', label: 'I made the booking by accident' },
  { key: 'uncomfortable_with_host', label: 'I am uncomfortable with the host' },
  { key: 'other', label: 'Other' },
];

export const guestWithdrawnReasons = [
  { key: 'do_not_need_car_anymore', label: 'I no longer need a car for rental' },
  { key: 'change_travel_plan', label: 'My travel plans have changed' },
  { key: 'book_by_accident', label: 'I made the booking by accident' },
  { key: 'uncomfortable_with_host', label: 'I am uncomfortable with the host' },
  { key: 'my_host_too_long', label: 'I waited for too long for the host to confirm' },
];

// Activate keyword filter on search page

// NOTE: If you are ordering search results by distance the keyword search can't be used at the same time.
// You can turn off ordering by distance in config.js file
export const keywordFilterConfig = {
  active: true,
};

export const daysOfWeek = [
  { key: 'sun', label: 'Sun' },
  { key: 'mon', label: 'Mon' },
  { key: 'tue', label: 'Tue' },
  { key: 'wed', label: 'Wed' },
  { key: 'thu', label: 'Thu' },
  { key: 'fri', label: 'Fri' },
  { key: 'sat', label: 'Sat' },
];

export const yesNoFilterOptions = [
  {
    key: true,
    label: 'Yes',
  },
  {
    key: false,
    label: 'No',
  },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  options: [
    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevance', longLabel: 'Relevance' },
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },
    { key: 'location', label: 'Distance to me' },
    // { key: 'createdAt', label: 'Newest' },
    // { key: '-createdAt', label: 'Oldest' },
    { key: 'pub_numberTripDone', label: 'Most rented' },
  ],
};

export const guestApplicableCredits = 2000; // in sub-unit

export const PROCESSING_FEE_PERCENTAGE = 0.04;

export const DEFAULT_TEXT_AREA_LIMIT = 500;

export const priceRange = [
  { key: '0,5000', label: '<$50' },
  { key: '4999,7501', label: '$50-$75' },
  { key: '7499,10001', label: '$75-$100' },
  { key: '9999,15001', label: '$100-$150' },
  { key: '14999,', label: '$150+' },
];

// Generate times
let i;
let timesArray = [];
let nulltime = moment('2019-01-01 24:00:00.000');
for (i = 0; i < 48; i++) {
  let obj = {
    timestamp: parseInt(nulltime.format('x')),
    timeOfDay: nulltime.format('hh:mm a'),
  };
  timesArray.push(obj);
  nulltime = moment(nulltime).add(30, 'minutes');
}
export const times = timesArray;

export const availableTimezones = [
  "Asia/Singapore"
];