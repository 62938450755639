import React from 'react';
import { FormattedMessage } from 'react-intl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_PREMIUM_PROCESSING_FEE_DISCOUNT } from '../../util/types';
import css from './BookingBreakdown.css';
import Explanation from './Explaination';
import MilesClubIcon from '../Icons/MilesClubIcon';

const LineItemPremiumProcessingFeeDiscount = ({ transaction, isCustomer, intl }) => {

  if (!transaction || transaction === null) return null;

  const premiumProcessingFeeDiscountLineItem = transaction.attributes.lineItems.find(
    item => (item.code === LINE_ITEM_PREMIUM_PROCESSING_FEE_DISCOUNT && !item.reversal)
  );

  if (!isCustomer || !premiumProcessingFeeDiscountLineItem) return null;

  const unitPrice = premiumProcessingFeeDiscountLineItem.unitPrice;

  const formattedUnitPrice = unitPrice ? formatMoney(intl, unitPrice, 1) : null;

  return formattedUnitPrice ? (
    <div className={`${css.creditsLineItem} ${css.premiumLineItem}`}>
      <span className={css.itemLabel}>
        <div className={css.premiumLineItemLabel}>
          <div>
            <MilesClubIcon color='#026786' />
          </div>
          <div>
            <FormattedMessage id="BookingBreakdown.premiumProcessingFeeDiscount" />
            <Explanation text={<FormattedMessage id="BookingBreakdown.premiumProcessingFeeDiscountHint" />} />
          </div>
        </div>
      </span>
      <span className={css.itemValue}>{formattedUnitPrice}</span>
    </div>
  ) : null;
};

export default LineItemPremiumProcessingFeeDiscount;
