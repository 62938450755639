import React from 'react';
import { bool } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_IS_DELIVERY, LINE_ITEM_IS_DELIVERY_FEE, propTypes } from '../../util/types';
import Explanation from './Explaination';

import css from './BookingBreakdownNew.css';

const LineItemNewHomeDeliveryAddOnMaybe = props => {
  const { transaction, isProvider, isCustomer, intl } = props;

  if(!transaction || transaction === null) return null;

  let deliveryLineItem = null;

  if (isProvider) {
    deliveryLineItem = transaction.attributes.lineItems.find(
      item => item.code === LINE_ITEM_IS_DELIVERY_FEE && !item.reversal
    );
  } else if (isCustomer) {
    deliveryLineItem = transaction.attributes.lineItems.find(
      item => item.code === LINE_ITEM_IS_DELIVERY && !item.reversal
    );
  }

  if (!deliveryLineItem || deliveryLineItem.length === 0) {
    return null;
  }

  const commission = deliveryLineItem.lineTotal;

  const formattedCommission = commission ? formatMoney(intl, commission, 1) : null;

  return formattedCommission ? (
    <div className={css.addOnLineItem}>
      <div className={css.totalLabel}>
        <FormattedMessage id="BookingBreakdown.delivery" />
        <Explanation text="Charges for getting the car delivered & picked up from your home" />
      </div>
      <div className={css.itemValue}>{formattedCommission}</div>
    </div>
  ) : null;
};

LineItemNewHomeDeliveryAddOnMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isProvider: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemNewHomeDeliveryAddOnMaybe;
