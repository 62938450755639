/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { memo, useState } from 'react';
import css from './SeoLandingPage.css';
import isEqual from 'lodash/isEqual';
import { BROWSE_BUTTON_PLAY_VIDEO_ID, EVENT_BROWSE_SAWHTD } from '../../util/gtm/gtmConstants';
import { sendGAEvent } from '../../util/googleAnalytics';
import { Modal } from '../../components';
import imageSource from '../../util/imageSource';

const SeoLandingRentVideo = memo(
  ({ onPushGTMSeeVideoEvent, onManageDisableScrolling }) => {
    const [isOpen, setIsOpen] = useState(false);
    const handleOpenVideo = () => {
      onPushGTMSeeVideoEvent(EVENT_BROWSE_SAWHTD, BROWSE_BUTTON_PLAY_VIDEO_ID);
      sendGAEvent({
        eventCategory: 'Others',
        eventAction: 'See How To Video',
      });
      setIsOpen(true);
    };

    return (
      <div className={css.rentVideoSection}>
        <div className={css.rentVideoSectionContainer}>
          <div className={css.rentVideoSectionTitle}>
            <span>Easily rent nearby cars with Drive lah</span>
          </div>
          <div className={css.rentVideoWrapper}>
            <div className={css.rentVideoOuter} onClick={handleOpenVideo}>
              <img src={imageSource.seoLandingPage.seoLandingVideoBanner} />
              {/* <span className={css.rentVideoPlayButton} ></span> */}
            </div>
          </div>
          <div className={css.rentVideoDescription}>
            Watch a simple intro video to understand how Drive lah works!
          </div>
          <Modal
            id="videoLandingpage"
            containerClassName={css.modalContainer}
            closeButtonClassName={css.modalCloseButton}
            contentClassName={css.contentModal}
            isOpen={isOpen}
            onClose={() => {
              const videoIframe = document.querySelector('#videoIframe');
              if (videoIframe) {
                const iframeSrc = videoIframe.src;
                videoIframe.src = iframeSrc;
              }
              setIsOpen(false);
            }}
            onManageDisableScrolling={onManageDisableScrolling}
          >
            <ModalVideo />
          </Modal>
        </div>
      </div>
    );
  },
  (prev, next) => isEqual(prev, next)
);

const ModalVideo = props => {
  return (
    <div className={css.videoContentWrapper}>
      <iframe
        id="videoIframe"
        className={css.iframeVideo}
        allowFullScreen={true}
        src="https://www.youtube.com/embed/OaOzjZiVaws"
      ></iframe>
    </div>
  );
};

export default SeoLandingRentVideo;
