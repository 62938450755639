import React from 'react';
import flatten from 'lodash/flatten';
import get from 'lodash/get';

import SectionCarPhotos from './SectionCarPhotos';
import SectionCarPhotosOnlyView from './SectionCarPhotosOnlyView';

import css from './TripPanel.css';

const DropOffSection = props => {
  const {
    shouldShowDropOffSection,
    isCustomer,
    photoSectionList,
    photoSectionListCustomer,
    setActivePhotoSection,
    activePhotoSection,
    intl,
    onManageDisableScrolling,
    isProvider,
    isPickUp,
    currentUser,
    setTripPhotos,
    state,
    allTripPhotos,
    inputOdometerInvalid,
    onNoteChange,
    onOdometerEndDataChange,
    isFuelIncluded,
    disableOdometerDataField,
    isDrivelahGo,
  } = props;

  // For Node.js version 11.0.0 and higer
  //
  // const filterTripPhotos = allTripPhotos.flat().filter(i => !i.isPickUp);
  const filterTripPhotos = allTripPhotos
    .reduce((acc, val) => acc.concat(val), [])
    .filter(i => !i.isPickUp);
  const odometerPhotos = filterTripPhotos.filter(i => i.odometerPhotos);
  let odometerEndData = '';
  if (odometerPhotos.length > 0) {
    let tempPic = odometerPhotos[0];
    if (tempPic.odometerEndData) {
      odometerEndData = tempPic.odometerEndData
    } else {
      odometerEndData = tempPic.note;
    }
  }
  const pickUpOdometerPhotos = flatten(allTripPhotos).filter(i => i.isPickUp && i.odometerPhotos);
  let odometerStartData = '';
  if (pickUpOdometerPhotos.length > 0) {
    let odoPics = pickUpOdometerPhotos[0];
    if (odoPics.odometerStartData) {
      odometerStartData = odoPics.odometerStartData;
    } else {
      odometerStartData = odoPics.note;
    }
  }

  return (
    <div className={css.pickUpPhotoSection}>
      <div className={css.titleContainer}>
        <div className={css.bookingStatusText} onClick={shouldShowDropOffSection}>
          <span>&#8249; back</span>
        </div>
        <h2 className={css.listingTitle}>
          {isCustomer ? 'Send drop-off request' : 'Confirm drop-off request'}
        </h2>
      </div>
      {photoSectionList.map(i => {
        // if (!isFuelIncluded && i.data === 'fuelReceiptPhotos') {
        //   return false;
        // }
        // if (i.data === 'odometerPhotos' && isDrivelahGo) {
        //   return false;
        // }
        return (
          <SectionCarPhotos
            id={i.id}
            key={i.id}
            isRemarkVisible={i.isRemarkVisible}
            setActivePhotoSection={setActivePhotoSection}
            // onClick={e => setActivePhotoSection(e, i.id)}
            holderClassName={
              activePhotoSection[i.id] === true ? css.activeSection : css.inactiveSection
            }
            activeSection={activePhotoSection[i.id] === true}
            intl={intl}
            onManageDisableScrolling={onManageDisableScrolling}
            isCustomer={isCustomer}
            isProvider={isProvider}
            currentUser={currentUser}
            isPickUp={false}
            setTripPhotos={data => setTripPhotos({ [i.data]: [...data] })}
            tripPhotos={state[i.data]}
            existedPhotos={filterTripPhotos}
            inputOdometerInvalid={inputOdometerInvalid}
            title={i.title}
            description={i.description}
            type={i.data}
            odometerData={i.odometerData}
            onNoteChange={onNoteChange}
            onOdometerEndDataChange={onOdometerEndDataChange}
            isFuelIncluded={isFuelIncluded}
            disableOdometerDataField={disableOdometerDataField}
            odometerStartData={Number(odometerStartData)}
            odometerEndData={odometerEndData}
          />
        );
      })}

      {isProvider && (
        <div className={css.uploadedTitleContainer}>
          <p className={css.uploadedTitle}>UPLOADED BY THE GUEST</p>
        </div>
      )}

      {isProvider &&
        photoSectionListCustomer.map(i => {
          if (!isFuelIncluded && i.data === 'fuelReceiptPhotos') {
            return false;
          }
          if (i.data === 'odometerPhotos' && isDrivelahGo) {
            return false;
          }
          return (
            <SectionCarPhotosOnlyView
              key={i.id}
              isRemarkVisible={i.isRemarkVisible}
              holderClassName={css.activeSection}
              intl={intl}
              onManageDisableScrolling={onManageDisableScrolling}
              isCustomer={isCustomer}
              isProvider={isProvider}
              currentUser={currentUser}
              isPickUp={isPickUp}
              setTripPhotos={data => setTripPhotos({ [i.data]: [...data] })}
              tripPhotos={state[i.data]}
              existedPhotos={filterTripPhotos}
              inputOdometerInvalid={inputOdometerInvalid}
              title={i.title}
              description={i.description}
              type={i.data}
              onNoteChange={onNoteChange}
            />
          );
        })}
    </div>
  );
};

export default DropOffSection;
