/*
 * Renders a set of options with selected and non-selected values.
 *
 * The corresponding component when selecting the values is
 * FieldCheckboxGroup.
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import includes from 'lodash/includes';
import Automatic from '../../assets/longTermListing/transmission.svg';
import Fuel from '../../assets/fuel.svg';
import Seats from '../../assets/seats.svg';
import Speed from '../../assets/speed.svg';
import Allwheel from '../../assets/longTermListing/Tyre.svg';
import ChildSeat from '../../assets/longTermListing/Path2199.svg';
import GPS from '../../assets/longTermListing/GPS.svg';
import CruiseControl from '../../assets/longTermListing/Cruisecontrol.svg';
import USBCharger from '../../assets/longTermListing/USBCharger.svg';
import USBInput from '../../assets/longTermListing/USBInput.svg';
import Bluetooth from '../../assets/longTermListing/Bluetooth.svg';
import RearCamera from '../../assets/longTermListing/RearCamera.svg';
import Sunroof from '../../assets/longTermListing/Sunroof.svg';
import AuxInput from '../../assets/longTermListing/AuxInput.svg';
import badge from '../../assets/badge.png';

import css from './LongTermPropertyGroup.css';

const checkSelected = (options, selectedOptions) => {
  return options.map(option => ({
    key: option.key,
    label: option.label,
    isSelected: includes(selectedOptions, option.key),
    title: option.title || '',
  }));
};

const IconCheck = props => {
  const isVisible = props.isVisible;
  const classes = isVisible ? css.checkIcon : classNames(css.checkIcon, css.hidden);

  return (
    <svg width="9" height="9" xmlns="http://www.w3.org/2000/svg" className={classes}>
      <path
        className={css.marketplaceFill}
        d="M2.636621 7.7824771L.3573694 5.6447948c-.4764924-.4739011-.4764924-1.2418639 0-1.7181952.4777142-.473901 1.251098-.473901 1.7288122 0l1.260291 1.1254783L6.1721653.505847C6.565577-.0373166 7.326743-.1636902 7.8777637.227582c.5473554.3912721.6731983 1.150729.2797866 1.6951076L4.4924979 7.631801c-.2199195.306213-.5803433.5067096-.9920816.5067096-.3225487 0-.6328797-.1263736-.8637952-.3560334z"
        fillRule="evenodd"
      />
    </svg>
  );
};

const Item = props => {
  const { label, isSelected, keyValue, title } = props;
  const labelClass = isSelected ? css.selectedLabel : css.notSelectedLabel;
  
  if(keyValue === 'zero_to_ten' ||
  keyValue === 'ten_to_thirty' ||
  keyValue === 'thiry_to_sixty' ||
  keyValue === 'sixty_to_ninety' ||
  keyValue === 'ninety_one_thirty' ||
  keyValue === 'more_than_thirty') {
    return  '';
  }
  return (
    <li className={css.item}>
      <div className={css.itemWrapper}>
        {/* <span className={css.iconWrapper}>
          <IconCheck isVisible={isSelected} />
        </span> */}
        <div className={css.icon}>
          {keyValue === 'automatic' ? (
            <img src={Automatic} />
          ) : keyValue === 'manual' ? (
            <img src={Automatic} />
          ) : keyValue === 'automatic' ? (
            <img src={Automatic} />
          ) : keyValue === 'petrol' || keyValue === 'diesel' ? (
            <img src={Fuel} />
          ) : keyValue === 'numberSeats' ? (
            <img src={Seats} />
          ) : keyValue === 'all_wheel_drive' ? (
            <img src={Allwheel} />
          ) : keyValue === 'child_seat' ? (
            <img src={ChildSeat} />
          ) : keyValue === 'gps' ? (
            <img src={GPS} />
          ) : keyValue === 'cruise_control' ? (
            <img src={CruiseControl} />
          ) : keyValue === 'usb_charger' ? (
            <img src={USBCharger} />
          ) : keyValue === 'usb_input' ? (
            <img src={USBInput} />
          ) : keyValue === 'bluetooth' ? (
            <img src={Bluetooth} />
          ) : keyValue === 'rear_camera' ? (
            <img src={RearCamera} />
          ) : keyValue === 'sunroof' ? (
            <img src={Sunroof} />
          ) : keyValue === 'aux_input' ? (
            <img src={AuxInput} />
          ) : keyValue === 'zero_to_ten' ||
            keyValue === 'ten_to_thirty' ||
            keyValue === 'thiry_to_sixty' ||
            keyValue === 'sixty_to_ninety' ||
            keyValue === 'ninety_one_thirty' ||
            keyValue === 'more_than_thirty' ? (
            <img src={Speed} />
          ) : (
            <img src={badge} />
          )}
        </div>
        <div className={css.labelWrapper}>
          <strong>{title}</strong>
          <span className={labelClass}>{label}</span>
        </div>
      </div>
    </li>
  );
};

const LongTermPropertyGroup = props => {
  const { rootClassName, className, id, options, selectedOptions, twoColumns } = props;
  const classes = classNames(rootClassName || css.root, className);
  const listClasses = twoColumns ? classNames(classes, css.twoColumns) : classes;

  const checked = checkSelected(options, selectedOptions);
  return (
    <ul className={listClasses}>
      {checked.map(option => {
        return (
          option.isSelected && (
            <Item
              key={`${id}.${option.key}`}
              label={option.label}
              isSelected={option.isSelected}
              title={option.title}
              keyValue={option.key}
            />
          )
        );
      })}
    </ul>
  );
};

LongTermPropertyGroup.defaultProps = {
  rootClassName: null,
  className: null,
  selectedOptions: [],
  twoColumns: false,
};

const { arrayOf, bool, node, shape, string } = PropTypes;

LongTermPropertyGroup.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  options: arrayOf(
    shape({
      key: string.isRequired,
      label: node.isRequired,
    })
  ),
  selectedOptions: arrayOf(string),
  twoColumns: bool,
};

export default LongTermPropertyGroup;
