import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { ensureCurrentUser } from '../../util/data';
import { FieldNumberInput, FieldTextInput, Form } from '../../components';

import css from './PayoutPrefForm.css';

class PayoutPrefFormComponent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      currentUser
    } = this.props;

    return (
      <FinalForm
        {...this.props}
        initialValues={{
          bankName: !currentUser ? '' : currentUser.attributes.profile.protectedData.bankAccount,
          accountName: !currentUser ? '' : currentUser.attributes.profile.protectedData.bankAccountName,
          bankAccountNumber: !currentUser ? '' : currentUser.attributes.profile.protectedData.bankAccountNumber,
        }}
        render={fieldRenderProps => {
          const {
            rootClassName,
            className,
            currentUser,
            handleSubmit,
            formId,
            intl,
            values,
          } = fieldRenderProps;

          const user = ensureCurrentUser(currentUser);

          if (!user.id) {
            return null;
          }

          const classes = classNames(rootClassName || css.root, className);

          const typeOfCardMessage = intl.formatMessage({ id: 'PayoutPrefPage.typeOfCard' });
          const typeOfCardPlaceholderMessage = intl.formatMessage({
            id: 'PayoutPrefPage.typeOfCardPlaceholder',
          });

          const cardNumberMessage = intl.formatMessage({ id: 'PayoutPrefPage.cardNumber' });
          const cardNumberPlaceholderMessage = intl.formatMessage({
            id: 'PayoutPrefPage.cardNumberPlaceholder',
          });

          const cvcNumberMessage = intl.formatMessage({ id: 'PayoutPrefPage.cvcNumber' });
          const cvcNumberPlaceholderMessage = intl.formatMessage({
            id: 'PayoutPrefPage.cvcNumberPlaceholder',
          });

          const validThruMessage = intl.formatMessage({ id: 'PayoutPrefPage.validThru' });
          const validThruPlaceholderMessage = intl.formatMessage({
            id: 'PayoutPrefPage.validThruPlaceholder',
          });

          return (
            <Form
              className={classes}
              onSubmit={e => {

              }}
            >
              <p className={css.description}>
                <FormattedMessage id="PayoutPrefForm.description" values={{ newline: <br /> }} />
              </p>
              <FieldTextInput
                id="typeOfCard"
                name="typeOfCard"
                className={css.inputField}
                type="textarea"
                label={typeOfCardMessage}
                placeholder={typeOfCardPlaceholderMessage}
                disabled={true}
              />

              <FieldTextInput
                id="cardNumber"
                name="cardNumber"
                className={css.inputField}
                type="textarea"
                label={cardNumberMessage}
                placeholder={cardNumberPlaceholderMessage}
                disabled={true}
              />

              <FieldNumberInput
                id="cvcNumber"
                name="cvcNumber"
                className={css.inputField}
                type="textarea"
                label={cvcNumberMessage}
                placeholder={cvcNumberPlaceholderMessage}
                disabled={true}
              />

              <FieldNumberInput
                id="validThru"
                name="validThru"
                className={css.inputField}
                type="textarea"
                label={validThruMessage}
                placeholder={validThruPlaceholderMessage}
                disabled={true}
              />
            </Form>
          );
        }}
      />
    );
  }
}

PayoutPrefFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  formId: null,
};

const { bool, func, string } = PropTypes;

PayoutPrefFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,
  intl: intlShape.isRequired,
};

const PayoutPrefForm = compose(injectIntl)(PayoutPrefFormComponent);

PayoutPrefForm.displayName = 'PayoutPrefForm';

export default PayoutPrefForm;
