import classNames from 'classnames';
import arrayMutators from 'final-form-arrays';
import { array, arrayOf, bool, func, shape, string } from 'prop-types';
import React, { Component, createRef } from 'react';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { compose } from 'redux';
import getCountryCodes from '../../translations/countryCodes';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import setFieldTouched from '../../util/setFiledTouched';
import { required } from '../../util/validators';

import {
  Button,
  FieldNumberInput,
  FieldSelect,
  FieldTextInput,
  Form,
  NamedLink,
} from '../../components';
import config from '../../config';
import { propTypes } from '../../util/types';

import isEqual from 'lodash/isEqual';
import css from './EditListingPayoutsForm.css';

const ALL_BANKS = config.custom.bankCodeReferences;
const ALL_COUNTRIES = getCountryCodes()

class EditListingPayoutsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: null,
    };

    this.currentFormRef = createRef(null)
  }

  componentDidUpdate(prevProps) {
    const oldInitValues = prevProps.initialValues;
    const newInitValues = this.props.initialValues;

    if(newInitValues && !isEqual(oldInitValues , newInitValues)){
        if(this.currentFormRef.current){
             const currentForm = this.currentFormRef.current;
             currentForm.batch(() => {
              Object.entries(newInitValues).forEach(
                record => currentForm.change(record[0], record[1])
              );
            });
        }
    }

  }

  render() {
    return (
      <FinalForm
        {...this.props}
        initialValues={
          this.state.initialValues ? this.state.initialValues : this.props.initialValues
        }
        mutators={{ ...arrayMutators, setFieldTouched }}
        render={fieldRenderProps => {
          const {
            disabled,
            rootClassName,
            intl,
            className,
            handleSubmit,
            pristine,
            saveActionMsg,
            updated,
            updateInProgress,
            fetchErrors,
            peopleNumber,
            invalid,
            form,
            submitButtonId,
            updateUserLoader,
          } = fieldRenderProps;

          this.currentFormRef.current = form;

          const classes = classNames(rootClassName || css.root, className);
          const submitReady = updated && pristine;
          const submitInProgress = updateInProgress || updateUserLoader;
          const submitDisabled = disabled || submitInProgress;

          const { updateListingError, showListingsError } = fetchErrors || {};

          const countryLabel = intl.formatMessage({
            id: "EditListingPayoutsForm.countryLabel"
          });
          const bankLabel = intl.formatMessage({
            id: "EditListingPayoutsForm.bankLabel"
          });
          const branchCodeLabel = intl.formatMessage({
            id: "EditListingPayoutsForm.branchCodeLabel"
          });
          const accountNumberLabel = intl.formatMessage({
            id: "EditListingPayoutsForm.accountNumberLabel"
          })

          const fieldRequired = required(intl.formatMessage({
            id: "EditListingPayoutsForm.fieldRequired"
          }))

          const initValues = this.props.initialValues || {}
          const bankCountryDisabled = Boolean(initValues.bankCountrySelection)
          const bankNameDisabled = Boolean(initValues.bankNameSelection)
          const branchCodeDisabled = Boolean(initValues.branchCode)
          const bankAccountNumberDisabled = Boolean(initValues.bankAccountNumber)
          const isDisabled = bankCountryDisabled && bankNameDisabled && branchCodeDisabled && bankAccountNumberDisabled


          const errorMessage = updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingFeaturesForm.updateFailed" />
            </p>
          ) : null;

          const errorMessageShowListing = showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingFeaturesForm.showListingFailed" />
            </p>
          ) : null;

          return (
            <Form
              className={classes}
              onSubmit={e => {
                e.preventDefault();
                e.stopPropagation();
                if (!invalid) handleSubmit(e);
                const formState = form.getState();
                const { errors } = formState || {};
                Object.keys(errors).forEach(key => form.mutators.setFieldTouched(key, 'true'));
              }}
            >
              {errorMessage}
              {errorMessageShowListing}

              <FormSpy
                onChange={formState => {
                  this.setState({
                    initialValues: formState.values,
                  });
                }}
              />

              <div className={css.displayInlineContainer}>
                <div>
                  <div className={css.fieldSet}>
                    <div className={css.displayInlineContainer}>
                      <div className={classNames(css.column, css.column6)}>
                        <FieldSelect
                          className={classNames(
                            css.peopleContainer,
                            css.newInput,
                            css.dropDownInput,
                             {[css.noSelection]: isDisabled}
                          )}
                          name="bankCountrySelection"
                          id="bankCountrySelection"
                          label={countryLabel}
                          validate={fieldRequired}
                          disabled={isDisabled}
                        >
                          <option disabled value="">
                            {intl.formatMessage({
                              id: "EditListingPayoutsForm.countryPlaceholder"
                            })}
                          </option>
                          {ALL_COUNTRIES.map(country => {
                            const { code, name } = country;
                            return (
                              <option key={code} value={code}>
                                {name}
                              </option>
                            );
                          })}
                        </FieldSelect>
                      </div>

                      <div className={classNames(css.column, css.column6)}>
                        <FieldSelect
                          className={classNames(
                            css.peopleContainer,
                            css.newInput,
                            css.dropDownInput,
                            {[css.noSelection]: isDisabled}
                          )}
                          name="bankNameSelection"
                          id="bankNameSelection"
                          label={bankLabel}
                          validate={fieldRequired}
                          disabled={isDisabled}
                        >
                          <option disabled value="">
                            {intl.formatMessage({
                              id: "EditListingPayoutsForm.bankPlaceholder"
                            })}
                          </option>
                          {ALL_BANKS.map(bank => {
                            const { label, key } = bank;
                            return (
                              <option key={`${label}_${key}`} value={key}>
                                {label}
                              </option>
                            );
                          })}
                        </FieldSelect>
                      </div>
                      <div className={classNames(css.column, css.column6)}>
                        <FieldTextInput
                          id="branchCode"
                          name="branchCode"
                          className={classNames(css.inputField, css.newInput,
                            {[css.noSelection]: isDisabled}
                            )}
                          type="text"
                          // disabled={
                          //   this.props.initialValues && this.props.initialValues.accountName
                          // }
                          label={branchCodeLabel}
                          placeholder="Enter your branch code"
                          validate={fieldRequired}
                          disabled={isDisabled}
                        />
                      </div>
                      <div className={classNames(css.column, css.column6)}>
                        <FieldNumberInput
                          id="bankAccountNumber"
                          name="bankAccountNumber"
                          className={classNames(css.inputField, css.newInput,
                            {[css.noSelection]: isDisabled}
                            )}
                          type="text"
                          // disabled={
                          //   this.props.initialValues && this.props.initialValues.bankAccountNumber
                          // }
                          label={accountNumberLabel}
                          placeholder="Enter your account number"
                          validate={fieldRequired}
                          disabled={isDisabled}
                        />
                      </div>
                      <div className={classNames(css.column, css.column6, css.referenceLink)}>
                        <NamedLink name="LandingPage">
                           <FormattedMessage id="EditListingPayoutsForm.referToBranchCodes" />
                        </NamedLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={css.stickyButtons}>
                <div className={css.stickButtonsContainer}>
                  <div className={css.stickButtonsDescription}></div>
                  <div className={css.stickButton}>
                    <Button
                      className={css.submitButton}
                      type="submit"
                      inProgress={submitInProgress}
                      disabled={submitDisabled}
                      ready={submitReady}
                      id={submitButtonId}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

EditListingPayoutsForm.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
};

EditListingPayoutsForm.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
  name: string.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  fuelTypes: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
  drivenKm: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
  peopleNumber: array.isRequired,
  submitButtonId: string,
};

export default compose(injectIntl)(EditListingPayoutsForm);
