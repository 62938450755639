import React from 'react';
import { bool } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_PROMOTION_DISCOUNT, propTypes } from '../../util/types';

import css from './BookingBreakdown.css';
import Explanation from './Explaination';

const LineItemPromotionDiscountMaybe = props => {
  const { transaction, isProvider, intl } = props;
  if(isProvider || !transaction || transaction === null) return null;

  const promotionDiscountItem = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_PROMOTION_DISCOUNT
  );



  if (!promotionDiscountItem) return null;

  const label = promotionDiscountItem.label ? promotionDiscountItem.label
   : (
    <FormattedMessage id="BookingBreakdown.promotionDisocunt" />
  );
  const hintText = transaction && transaction.attributes && transaction.attributes.protectedData && transaction.attributes.protectedData.promotion;
  const credits = promotionDiscountItem.lineTotal;
  const formattedPrice = formatMoney(intl, credits, 1);



  return (
    <div className={css.creditsLineItem}>
      <span className={css.itemLabel}>
        {label}
        <Explanation text={hintText} />
      </span>
      <div className={css.totalPrice}>{formattedPrice}</div>
    </div>
  );
};

LineItemPromotionDiscountMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isProvider: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemPromotionDiscountMaybe;
