import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { compose } from 'redux';
import { TopbarContainer } from '..';
import {
  default as facebookImage,
  default as twitterImage,
} from '../../assets/facebook_drivelah_2.jpg';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
} from '../../components';
import config from '../../config';
import { withViewport } from '../../util/contextHelpers';
import { EVENT_BROWSE_HOMEPAGE } from '../../util/gtm/gtmConstants';
import { pushGTMBrowseEvent } from '../../util/gtm/gtmHelpers';
import { injectIntl, intlShape } from '../../util/reactIntl';

// import SectionImages from './SectionImages';
import { formatMoney } from '../../util/currency';
import { checkSignUpCode } from '../../util/referralProgram';
import { types } from '../../util/sdkLoader';
import { parse } from '../../util/urlHelpers';
import { fetchPHVFeaturedCar } from '../SearchPage/SearchPage.duck';
import DriveLahVsCompanies from './DriveLahVsCompanies';
import GojekIntroBanner from './GojekIntroBanner';
import css from './GojekRentACarLandingPage.css';
import HostSectionHeroPartners from './HostSectionHeroPartners';
import SectionCars from './SectionCars';
import SectionHelp from './SectionHelp';
import SectionStories from './SectionStories';

const { Money } = types;
// import SectionSharingYourCar from './SectionSharingYourCar';
// import SectionFeatures from './SectionFeatures';
const MAX_MOBILE_SCREEN_WIDTH = 991;

export const GojekRentACarLandingPageComponent = props => {
  const {
    intl,
    scrollingDisabled,
    onManageDisableScrolling,
    listings,
    fetchCategorisedCarInProgress,
    viewport,
  } = props;
  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  // const schemaTitle = intl.formatMessage({ id: 'SeoLandingPage.schemaTitle' }, { siteTitle });
  const [signUpCodeAmount, setSignUpCodeAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const schemaTitle = intl.formatMessage(
    {
      id: 'Rent a car in Singapore & earn with Gojek | Affordable rentals starting from $4/hr',
    },
    { siteTitle, amount: formatMoney(intl, new Money(signUpCodeAmount, config.currency)) }
  );
  const schemaDescription = intl.formatMessage({
    id:
      "Don't own a car but want to earn with Gojek? We got you covered, rent a car in Singapore with Drive lah. Say no to long leases & mileage cap. Pay per use with flexible rentals.",
  });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;
  const targetRef = useRef(null);
  const location = useLocation();
  const isScoot = location.pathname === '/scoot-drivelah-signup';

  useEffect(() => {
    const { code } = parse(location.search);
    if (code) {
      setLoading(true);
      checkSignUpCode(code).then(res => {
        if (res.valid) {
          setSignUpCodeAmount(res.credits);
        }
        setLoading(false);
      });
    }
  }, []);

  const onPushGTMEvent = (event, buttonId) => {
    // pushGTMBrowseEvent({
    //   props,
    //   event,
    //   buttonId,
    // });
  };

  useEffect(() => {
    onPushGTMEvent(EVENT_BROWSE_HOMEPAGE);
  }, []);

  let isMobileLayout;
  try {
    if (viewport) {
      isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
    }
  } catch (error) {

  }

  const handleGetToCalculatorSection = () => {
    window.scrollTo(0, targetRef.current.offsetTop - 72);
  };

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer notApplySearch={true} currentPage="SeoLandingPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.contentWrapper}>
          <GojekIntroBanner
            isScoot={isScoot}
            signUpCodeAmount={signUpCodeAmount}
            loading={loading}
            isMobileLayout={isMobileLayout}
          />
          {/* <WhyHostWithDrivelah isMobileLayout={isMobileLayout} /> */}
          <DriveLahVsCompanies isMobileLayout={isMobileLayout} />
          <HostSectionHeroPartners
            isScoot={isScoot}
            signUpCodeAmount={signUpCodeAmount}
            loading={loading}
            getToCalculatorSection={handleGetToCalculatorSection}
          />
          <SectionCars
            listings={listings}
            isMobileLayout={isMobileLayout}
            fetchCategorisedCarInProgress={fetchCategorisedCarInProgress}
          />
          {/* <div className={css.rentOutSection}>
            <div className={css.container}>
              <div className={css.row}>
                <div className={css.column12}>
                  <div className={css.contentBlockInner}>
                    <h3>
                      Rent out your car &amp; <br /> Earn money on your rest day!
                    </h3>
                    <button className={css.bannerButton}>Learn More</button>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <SectionStories />
          <SectionHelp />
          {/* <SeoLandingRentalCategories /> */}
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool, object, number, shape } = PropTypes;

GojekRentACarLandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

const GojekRentACarLandingPage = compose(withViewport, injectIntl)(GojekRentACarLandingPageComponent);

GojekRentACarLandingPage.loadData = () => {
  return fetchPHVFeaturedCar();
};

export default GojekRentACarLandingPage;
