import React, { memo } from 'react';
import css from './HostLandingPage.css';
import { Column, Container, Row } from '../../components/Grids/Grid';
import classNames from 'classnames';

const StartEarning = memo(({ openSignUpModal }) => {
  return (
    <div className={css.startEarningWrapper}>
      <Container>
        <Row className={css.sectionTitle}>
          <Column large={12}>
            <h6>Time to start earning. <span>Build your car sharing business with Drive lah</span></h6>
            <p>
              Accelerate your entrepreneurship and start building a small car sharing business on
              Drive lah.
            </p>
            <div className={css.btnWrapper}>
              <button onClick={openSignUpModal} className={classNames(css.btn, css.btnPrimary)}>List your Car</button>
            </div>
          </Column>
        </Row>
      </Container>
    </div>
  );
});

export default StartEarning;
