import React, { Component } from 'react';
import { injectIntl } from 'react-intl';

const TrustBoxElement = ({ trustBoxRef }) => (
  <div
    ref={trustBoxRef}
    className="trustpilot-widget"
    data-locale="en-US"
    data-template-id="53aa8807dec7e10d38f59f32"
    data-businessunit-id="5f9cc167ce6f0b000158c1aa"
    data-style-height="120px"
    data-style-width="100%"
    data-theme="light"
  >
    <a
      href="https://www.trustpilot.com/review/example.com"
      target="_blank"
      rel="noopener noreferrer"
    >
      Trustpilot
    </a>
  </div>
);

class TrustBoxComponent extends Component {
  constructor(props) {
    super(props);
    this.trustBoxRef = React.createRef();
  }

  componentDidMount() {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded just yet.
    // When it is, it will automatically load the TrustBox.
    if (typeof window !== 'undefined' && window.Trustpilot) {
      window.Trustpilot.loadFromElement(this.trustBoxRef.current, true);
    }
  }

  render() {
    return <TrustBoxElement trustBoxRef={this.trustBoxRef} />;
  }
}

const TrustBox = injectIntl(TrustBoxComponent);
export default TrustBox;