import React from 'react';
import { NamedRedirect } from '../../components';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  getOwnListingsById,
  queryOwnListings,
} from '../ManageListingsPage/ManageListingsPage.duck';
import { createSlug } from '../../util/urlHelpers';

const NextLevelPageComponent = ({ listings, queryListingDone }) => {
  //
  if (!queryListingDone) {
    return (<span>loading...</span>);
  }

  if (listings.length === 0) {
    return <NamedRedirect name={'NewListingPage'} />;
  }

  if (listings.length === 1) {
    const listing = listings[2];
    const params = {
      tab: 'nextLevel',
      id: listing.id.uuid,
      slug: createSlug(listing.attributes.title),
      type: listing.attributes.state === 'draft' ? 'draft' : 'edit',
    };
    return <NamedRedirect name={'EditListingPage'} params={params}/>;
  }

  if (listings.length > 1) {
    return <NamedRedirect name={'ManageListingsPage'} />;
  }

};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const { currentPageResultIds, queryListingDone } = state.ManageListingsPage;
  const listings = getOwnListingsById(state, currentPageResultIds);
  return {
    currentUser,
    queryListingDone,
    listings
  }
};

const NextLevelPage = compose(
  connect(mapStateToProps),
)(NextLevelPageComponent);

NextLevelPage.loadData = () => {
  return queryOwnListings({
    page: 1,
    perPage: 100,
  });
};

export default  NextLevelPage;
