import { types as sdkTypes } from './util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
export default [
  {
    id: 'default-Punggol',
    predictionPlace: {
      key: "journeyh",
      address: 'Punggol, Singapore',
      description: 'Punggol, Singapore',
      place_id: "ChIJHcLJyP4V2jERmCZ1xeavWB4",
      origin: new LatLng(1.4036359, 103.9002857),
      bounds: new LatLngBounds(new LatLng(1.42799587, 103.93620414), new LatLng(1.35128581, 103.87440605)),
    },
  },
  {
    id: 'default-Serangoon',
    predictionPlace: {
      key: "journeyhorizon",
      address: 'Serangoon, Singapore',
      place_id: "ChIJs1vOCVUW2jEREKbarqz3AAU",
      description: 'Serangoon, Singapore',
      origin: new LatLng(1.3659974, 103.8533954),
      bounds: new LatLngBounds(new LatLng(1.39371365, 103.89876985), new LatLng(1.31700249, 103.83697175)),
    },
  },
  {
    id: 'default-Tampines',
    predictionPlace: {
      key: "journeyhor",
      address: 'Tampines, Singapore',
      place_id: "ChIJ0e4CNAQ92jERy_T_mqOSB48",
      description: 'Tampines, Singapore',
      origin: new LatLng(1.3451044, 103.9375174),
      bounds: new LatLngBounds(new LatLng(1.38700387, 103.98099215), new LatLng(1.31029249, 103.91919406)),
    },
  },
  {
    id: 'default-Bukit-Batok',
    predictionPlace: {
      key: "journeyhorizo",
      address: 'Bukit Batok, Singapore',
      description: 'Bukit Batok, Singapore',
      place_id: "ChIJyeWclkkQ2jER235lRS1YEq4",
      origin: new LatLng(1.3560767, 103.735932),
      bounds: new LatLngBounds(new LatLng(1.39472573, 103.78402147), new LatLng(1.3180146, 103.72222338)),
    },
  },
  {
    id: 'default-Choa-Chu-Kang',
    predictionPlace: {
      key: 'journey',
      address: 'Choa Chu Kang, Singapore',
      place_id: "ChIJ7Xdp2-sR2jERwKXarqz3AAU",
      description: 'Choa Chu Kang, Singapore',
      origin: new LatLng(1.3873149, 103.7382325),
      bounds: new LatLngBounds(new LatLng(1.40710652, 103.76391266), new LatLng(1.36875145, 103.73301361))
    },
  },
];
