import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, useHistory, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import {
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Modal,
  NamedRedirect,
  Page,
} from '../../components';
import config from '../../config';
import { withViewport } from '../../util/contextHelpers';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';

import bannerIllustration from '../../assets/host-themed-landing-page/banner-illustrator.png';
import { ThemedTopbarContainer } from '../../containers';

import classNames from 'classnames';
import { get } from 'lodash';
import ThemedFooter from '../../components/ThemedFooter/ThemedFooter';
import { authenticationInProgress, signup } from '../../ducks/Auth.duck';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { LoginFormThemed, SignupFormThemed } from '../../forms';
import { EVENT_CONVERSION_SUBMIT_EMAIL_ID } from '../../util/conversions/conversionsConstants';
import { createConversionEvents } from '../../util/conversions/conversionsHelpers';
import { ensureCurrentUser } from '../../util/data';
import {
  EVENT_BROWSE_HOMEPAGE,
  EVENT_BROWSE_LOGGEDIN,
  EVENT_SIGNUP_SUBMIT_FORM,
  SIGNUP_SUBMIT_BUTTON_ID,
} from '../../util/gtm/gtmConstants';
import { createRawPropertiesForGTM } from '../../util/gtm/gtmCreateProperties';
import { pushGTMBrowseEvent, pushGTMSignupForm } from '../../util/gtm/gtmHelpers';
import { checkIfReferralCode } from '../../util/referralProgram';
import { parse } from '../../util/urlHelpers';
import BannerSection from './BannerSection';
import BenefitOfRenting from './BenefitOfRenting';
import ChannelsListings from './ChannelListings';
import CommunitySection from './CommunitySection';
import EarningCalculator from './EarningCalculator';
import FrequentlyAskedQuestions from './FrequentlyAskedQuestions';
import css from './HostLandingPage.css';
import HowSharingWorks from './HowSharingWorks';
import LoveDrivelah from './LoveDrivelah';
import StartEarning from './StarEarning';
import WeHaveGotYourBack from './WeHaveGotYourBack';
import { GTAG_METHODS, GTAG_ACTIONS, gSend } from '../../util/gtag';

const MAX_MOBILE_SCREEN_WIDTH = 768;

export const HostLandingPageComponent = props => {
  const {
    intl,
    scrollingDisabled,
    onManageDisableScrolling,
    viewport,
    currentUser,
    submitSignup,
    authInProgress,
    tab,
    location,
    submitLogin,
    isAuthenticated,
  } = props;

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'HostLandingPageNew.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'HostLandingPageNew.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${bannerIllustration}`;

  const [videoModalOpen, onVideoModalOpen] = useState(false);
  const [isSignupModalOpen, setIsSignupModalOpen] = useState(false);
  const [signUpCodeAmount, setSignUpCodeAmount] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [carryEmail, setCarryEmail] = useState(false);
  const from = location.state && location.state.from ? location.state.from : null;
  const isFromListingPage = location.state && location.state.isFromListingPage;
  // const isFromDonationPage = location.state && location.state.isFromDonationPage;
  const signUpCode = parse(location.search).code;

  const { email: signUpEmail = null, password = null } = get(location, 'state', {}) || {};

  const user = ensureCurrentUser(currentUser);
  const currentUserLoaded = !!user.id;
  const initEmail = parse(location.search).email;
  const showEmailVerification = !isLogin && currentUserLoaded && !user.attributes.emailVerified;
  const history = useHistory();
  const onPushGTMEvent = (event, buttonId) => {
    // pushGTMBrowseEvent({
    //   props,
    //   event,
    //   buttonId,
    // });
  };

  useEffect(() => {
    onPushGTMEvent(EVENT_BROWSE_HOMEPAGE);
    const from = location.state && location.state.from ? location.state.from : null;
    if (isAuthenticated && from) {
      return <Redirect to={from} />;
    } else if (isAuthenticated && currentUserLoaded && !showEmailVerification) {
      if (window && signUpCode && checkIfReferralCode(signUpCode)) {
        window.alert('You can only use the referral link when you are not logged in');
      }
      return <NamedRedirect name="LandingPage" />;
    }
  }, []);

  let isMobileLayout;
  try {
    if (viewport) {
      isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
    }
  } catch (error) {

  }

  const handleSubmit = ({ email, password }) => {
    setIsSignupModalOpen(true);
    setCarryEmail(email);
  };

  const closeModalRedirectToHome = () => {
    setIsSignupModalOpen(false);
    setTimeout(() => {
      history.push('/');
    }, 2000);
  };

  const handleSubmitSignup = values => {
    const { fname, lname, ...rest } = values;
    const params = { firstName: fname.trim(), lastName: lname.trim(), ...rest };
    submitSignup(params);

    // const properties = createRawPropertiesForGTM({
    //   props: props,
    //   button: {
    //     buttonId: SIGNUP_SUBMIT_BUTTON_ID,
    //   },
    // });
    // pushGTMSignupForm(properties, EVENT_SIGNUP_SUBMIT_FORM);
    closeModalRedirectToHome();
  };

  const handleSubmitLogin = values => {
    submitLogin(values).then(res => {
      gSend(GTAG_ACTIONS.ACTION_LOGIN, { method: GTAG_METHODS.WEB_LOGIN });
      // pushGTMBrowseEvent({
      //   props: props,
      //   event: EVENT_BROWSE_LOGGEDIN,
      // });
      closeModalRedirectToHome();
    });
  };

  const isFromCampaign = !!parse(location.search).campaign;
  return (
    <Page
      className={classNames(css.root, css.newTheme)}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: bannerIllustration, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${bannerIllustration}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <ThemedTopbarContainer
            notApplySearch={true}
            currentPage={'HostLandingPage'}
            openSignUpModal={() => setIsSignupModalOpen(!isSignupModalOpen)}
          />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.contentWrapper}>
          <div
            style={{
              zIndex: isMobileLayout && !videoModalOpen && 75,
            }}
          >
            <BannerSection
              intl={intl}
              onSubmit={handleSubmit}
              openSignUpModal={() => setIsSignupModalOpen(!isSignupModalOpen)}
            />
            <ChannelsListings />
            <CommunitySection />
            <BenefitOfRenting openSignUpModal={() => setIsSignupModalOpen(!isSignupModalOpen)} />
            <HowSharingWorks openSignUpModal={() => setIsSignupModalOpen(!isSignupModalOpen)} />
            <WeHaveGotYourBack openSignUpModal={() => setIsSignupModalOpen(!isSignupModalOpen)} />
            <EarningCalculator intl={intl} />
            <LoveDrivelah />
            <FrequentlyAskedQuestions />
            <StartEarning openSignUpModal={() => setIsSignupModalOpen(!isSignupModalOpen)} />
            {/* <RentCarSection intl={intl}/>
            <RentalCounter
              onManageDisableScrolling={onManageDisableScrolling}
              onVideoModalOpen={onVideoModalOpen}
              onPushGTMSeeVideoEvent={onPushGTMEvent}
            />
            <FindCar />
            <DiscoverSection />

            <GetApp />
            <AboutDrivelah /> */}
            {/* <FeaturedOn /> */}
            {/* <ListYourCar /> */}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <ThemedFooter />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
      <Modal
        id="insuranceLandingModal"
        isOpen={isSignupModalOpen}
        onClose={() => setIsSignupModalOpen(false)}
        containerClassName={css.modalContainer}
        onManageDisableScrolling={onManageDisableScrolling}
        closeTextClassName={css.closeButtonText}
        scrollLayerClassName={css.scrollLayerClassName}
        closeIconClassName={css.closeButtonIcon}
        closeButtonMessage={<FormattedMessage id="ModalMissingInformation.closeStaticModal" />}
      >
        <div className={css.modalTitle}>
          {!isLogin ? (
            <h2>
              Sign up using <span>email</span>
            </h2>
          ) : (
            <h2>
              Log in using <span>email</span>
            </h2>
          )}
        </div>
        {isLogin ? (
          <LoginFormThemed
            className={css.form}
            onSubmit={handleSubmitLogin}
            inProgress={authInProgress}
          />
        ) : (
          <SignupFormThemed
            className={css.form}
            onSubmit={data => {
              handleSubmitSignup({ ...data, isFromCampaign: isFromCampaign });
              const properties = createRawPropertiesForGTM({
                props: props,
              });
              createConversionEvents(properties, EVENT_CONVERSION_SUBMIT_EMAIL_ID, 'click');
            }}
            inProgress={authInProgress}
            initEmail={initEmail || signUpEmail}
            signUpCode={signUpCode}
            submitButtonId={SIGNUP_SUBMIT_BUTTON_ID}
            initialValues={{ email: carryEmail || signUpEmail, password }}
          />
        )}

        <div className={css.modalFooter}>
          {isLogin ? (
            <p>
              New to Drive lah?{' '}
              <button type="button" onClick={() => setIsLogin(false)}>
                Sign up
              </button>
            </p>
          ) : (
            <p>
              Already have an account?{' '}
              <button type="button" onClick={() => setIsLogin(true)}>
                Log in
              </button>
            </p>
          )}
        </div>
      </Modal>
    </Page>
  );
};

const { bool, object } = PropTypes;

HostLandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated, loginError, signupError } = state.Auth;
  const { currentUser, sendVerificationEmailInProgress, sendVerificationEmailError } = state.user;

  return {
    authInProgress: authenticationInProgress(state),
    currentUser,
    isAuthenticated,
    loginError,
    scrollingDisabled: isScrollingDisabled(state),
    signupError,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  submitSignup: params => dispatch(signup(params)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const HostLandingPage = compose(
  withViewport,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(HostLandingPageComponent);

HostLandingPage.loadData = () => { };

export default HostLandingPage;
