import React from 'react';
import css from './LandingPageGoHost.css';
import { FormattedMessage } from 'react-intl';
import { NamedLink } from '../../components';

const SectionPricingPlans = () => {
  return (
    <div className={css.sectionPricingPlans}>
      <div className={css.sectionPricingPlansContentWrapper}>
        <div className={css.title}>
          <FormattedMessage id='LangdingPageGoHost.SectionPricingPlaces.title' />
        </div>
        <div className={css.description}>
          <FormattedMessage id='LangdingPageGoHost.SectionPricingPlaces.description1' />
        </div>
        <div className={css.description}>
          <FormattedMessage id='LangdingPageGoHost.SectionPricingPlaces.description2' />
        </div>
        <div className={css.description}>
          <FormattedMessage id='LangdingPageGoHost.SectionPricingPlaces.description3' />
        </div>

        <div className={css.highlight}>
          <FormattedMessage id='LangdingPageGoHost.SectionPricingPlaces.highlight' />
        </div>

        <NamedLink className={css.outlineButton} name='RequestCheckEligibility'>
          <FormattedMessage id='LandingPageGoHost.checkEligibility' />
        </NamedLink>
      </div>
    </div>
  );
};

export default SectionPricingPlans;