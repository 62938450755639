import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import config from '../../config';
import LogoImage from '../../assets/logos/newLogo.png';
import css from './Logo.css';

const Logo = props => {
  const { className, format, logoSrc, ...rest } = props;
  const mobileClasses = classNames(css.logoMobile, className);
  if (format === 'desktop') {
    return (
      <img className={mobileClasses} src={logoSrc || LogoImage} alt={config.siteTitle} {...rest} />
    );
  }
  return <IconLogo classNames={mobileClasses} />;
};

const { oneOf, string } = PropTypes;

Logo.defaultProps = {
  className: null,
  format: 'desktop',
};

Logo.propTypes = {
  className: string,
  format: oneOf(['desktop', 'mobile']),
};

const IconLogo = ({ classNames }) => <img src={LogoImage} className={classNames} alt="Drive lah Logo" />;

export default Logo;
