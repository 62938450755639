import classNames from 'classnames';
import React, { memo } from 'react';
import Trust from '../../assets/landingPageNew/listingImg2.svg';
import Transport from '../../assets/landingPageNew/listingImgAuthority.svg';
import Income from '../../assets/landingPageNew/listingImgIncome.svg';
import Tokio from '../../assets/landingPageNew/listingImgTokio.svg';
import { Column, Container, Row } from '../../components/Grids/Grid';
import css from './HostLandingPage.css';

export const Approved = () => (
  <div className={css.channelOuter}>
    <a href="#" className={css.channelItem} target="_blank">
      <img className={css.channelItemImage} src={Transport} />
    </a>
  </div>
);

export const Rated = () => (
  <div className={css.channelOuter}>
    <a href="#" target="_blank" className={css.channelItem}>
      <img className={css.channelItemImage} src={Trust} />
    </a>
  </div>
);

export const Insured = () => (
  <div className={css.channelOuter}>
    <a href="#" target="_blank" className={css.channelItem}>
      <img className={css.channelItemImage} src={Tokio} />
    </a>
    <a href="#" className={css.channelItem} target="_blank">
      <img className={css.channelItemImage} src={Income} />
    </a>
  </div>
);

const ChannelsListings = memo(() => {
  return (
    <div className={css.channelsListingWrapper}>
      <Container>
        <Row justifyContent="center">
          <Column large={8} medium={12}>
            <div className={css.channelsListingOuter}>
              <div className={css.channelsListing}>
                <p>Approved by</p>
                <Approved />
              </div>

              <div className={classNames(css.channelsListing, css.trustBox)}>
              <p>Rated 4.7/5.0 in</p>
                <Rated />
                {/* <TrustBox height={80} /> */}
              </div>

              <div className={css.channelsListing}>
                <p>Insured by</p>
                <Insured />
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </div>
  );
});

export default ChannelsListings;
