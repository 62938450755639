import classNames from 'classnames';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import { Field, Form as FinalForm, FormSpy } from 'react-final-form';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import {
  Button,
  FieldDateInput,
  FieldPhoneNumberInput,
  FieldSelect,
  FieldTextInput,
  Form,
  LocationAutocompleteInputField,
} from '../../components';
import config from '../../config';
import { drivelahApiPost } from '../../util/apiHelper';
import { DEFAULT_TIMEZONE, sameDay } from '../../util/dates';
import { parse } from '../../util/urlHelpers';
import css from './SearchContactModalForm.css';

const generateTimeOptions = (isToday, limitTime = null) => {
  const now = new Date();
  const currentMinutes = isToday ? now.getHours() * 60 + now.getMinutes() : -1;
  const limitMoment = limitTime ? moment(limitTime, 'hh:mm a') : null;
  const currentTime = limitMoment ? limitMoment.hours() * 60 + limitMoment.minutes() : -1;
  const mustBeLargerThanValue = Math.max(currentMinutes, currentTime);
  return config.custom.timeSetInput.filter(t => t.hour * 60 + t.minutes > mustBeLargerThanValue);
};

const options = [
  { value: '050', label: '0-50' },
  { value: '50100', label: '50-100' },
  { value: '1001000', label: '100-1000' },
];

const checkValidSearchDate = ({ pickUp, pickupTime, dropOff, dropOffTime }) => {
  if (pickUp && pickupTime && dropOff && dropOffTime) {
    const momentPickup = moment(pickupTime, 'hh:mm a');
    const momentDropOff = moment(dropOffTime, 'hh:mm a');
    const start = moment(pickUp.date)
      .startOf('date')
      .hour(momentPickup.hour())
      .minutes(momentPickup.minutes)
      .toDate();
    const end = moment(dropOff.date)
      .startOf('date')
      .hour(momentDropOff.hours())
      .minutes(momentDropOff.minutes())
      .toDate();
    if (end.getTime() > start.getTime()) return undefined;

    return 'Return date must be after Pick-up date';
  }
  return undefined;
};

let setPickUp, setDropOff;
const SearchContactModalForm = props => {
  const { currentUser, setFormStatus, formStatus, ...rest } = props;
  const [startTimeSet, setStartTimeSet] = useState([]);
  const [endTimeSet, setEndTimeSet] = useState([]);
  const [isValid, setIsValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [mounted, setMounted] = useState(false);
  const prevValues = useRef(null);

  const location = useLocation();
  const { search } = location;
  const { dates, hours, address } = parse(search);
  const datesArray = dates && dates.split(',');
  const hoursArray = hours && hours.split(',');

  if (datesArray && datesArray.length > 0 && hoursArray && hoursArray.length > 0) {
    setPickUp =
      moment(datesArray[0] + ' ' + hoursArray[0])
        .tz(DEFAULT_TIMEZONE)
        .toDate() || null;
    setDropOff =
      moment(datesArray[1] + ' ' + hoursArray[1])
        .tz(DEFAULT_TIMEZONE)
        .toDate() || null;
  }

  const searchDateTimeData = {
    location,
    searchLocation: { search: address },
    pickUp: { date: setPickUp },
    pickupTime: null,
    dropOff: { date: setDropOff },
    dropOffTime: null,
  };

  const [searchDateTime, setSearchDateTime] = useState(searchDateTimeData);

  const generatePickUpTimeSlots = pickUpDate => {
    const _startTimeSet = generateTimeOptions(sameDay(pickUpDate, new Date()));
    setStartTimeSet(_startTimeSet);
    return _startTimeSet;
  };

  const generateDropOffTimeSlots = dropOffDate => {
    const _endTimeSet = generateTimeOptions(sameDay(dropOffDate, new Date()));
    setEndTimeSet(_endTimeSet);
    return _endTimeSet;
  };

  const handleSubmit = (e, values, error) => {
    setIsLoading(true);
    e.preventDefault();
    const pickupLocation = values && values.searchLocation && values.searchLocation.search;
    const pickupDate =
      values &&
      values.pickUp &&
      values.pickUp.date &&
      moment(values.pickUp.date).format('DD/MM/YYYY');
    const pickupTime = values && values.pickupTime;
    const dropoffDate =
      values &&
      values.pickUp &&
      values.dropOff.date &&
      moment(values.dropOff.date).format('DD/MM/YYYY');
    const dropoffTime = values && values.dropOffTime;
    const getValues =
      values &&
      values.priceRange &&
      values.priceRange.length > 0 &&
      values.priceRange.map(i => i.label);
    const priceRange = getValues && getValues.join(',');
    const email = values && values.email;
    const phoneNumber = values && values.phone && values.phone.toString();
    const userId = currentUser ? currentUser.id && currentUser.id.uuid : null;
    if (
      pickupLocation &&
      pickupDate &&
      pickupTime &&
      dropoffDate &&
      dropoffTime &&
      // priceRange &&
      email &&
      phoneNumber
    ) {
      setIsValid(true);
      const data = {
        pickupLocation,
        pickupDate,
        pickupTime,
        dropoffDate,
        dropoffTime,
        priceRange: '-',
        email,
        phoneNumber,
        userId,
      };
      const query = 'store-expert-data';
      try {
        drivelahApiPost(query, data).then(res => {
          if (res && res.data) {
            setTimeout(() => {
              setFormStatus(res.data);
              setIsLoading(false);
            }, 1000);
          }
        });
      } catch (e) {
        setIsLoading(false);

      }
    } else {
      setIsValid(false);
      setIsLoading(false);
    }
  };

  return (
    <FinalForm
      {...rest}
      rawInitialValues={searchDateTime}
      render={formRenderProps => {
        const { form, className, values, valid, invalid, rawInitialValues } = formRenderProps;
        const error = valid ? checkValidSearchDate(values) : undefined;
        if (!mounted) {
          if (rawInitialValues) {
            if (rawInitialValues.pickUp.date && rawInitialValues.dropOff.date) {
              if (!values.pickupTime && !values.dropOffTime) {
                generatePickUpTimeSlots(rawInitialValues.pickUp.date);
                generateDropOffTimeSlots(rawInitialValues.dropOff.date);
              }
            }
          }

          form.batch(() => {
            Object.entries(rawInitialValues).forEach(entry => {
              if (entry[0] === 'pickupTime') {
                form.change('pickupTime', moment(rawInitialValues.pickUp.date).format('hh:mm a'));
              } else if (entry[0] === 'dropOffTime') {
                form.change('dropOffTime', moment(rawInitialValues.dropOff.date).format('hh:mm a'));
              } else {
                form.change(entry[0], entry[1]);
              }
              if (currentUser && currentUser.attributes && currentUser.attributes.email) {
                form.change('email', currentUser.attributes.email);
              }
              if (
                currentUser &&
                currentUser.attributes &&
                currentUser.attributes.profile &&
                currentUser.attributes.profile.protectedData &&
                currentUser.attributes.profile.protectedData.phoneNumber
              ) {
                form.change('phone', currentUser.attributes.profile.protectedData.phoneNumber);
              }
              return form;
            });
          });
          setMounted(true);
        }

        return (
          <Form
            className={classNames(css.root, className)}
            onSubmit={e => handleSubmit(e, values, error)}
          >
            <FormSpy
              subscription={{ values: true, active: true }}
              onChange={({ values, active }) => {
                if (active === 'pickUp' && values.pickUp && values.pickUp.date) {
                  const newEndDate = moment(values.pickUp.date)
                    .add(1, 'day')
                    .toDate();
                  if (!values.dropOff) {
                    form.batch(() => {
                      form.change('dropOff', {
                        date: newEndDate,
                      });
                    });
                  }
                  const _startTimeSet = generatePickUpTimeSlots(values.pickUp.date);
                  const currentPickupTimeIsInStartTimeSet = _startTimeSet.find(
                    item => item.key === values.pickupTime
                  );
                  if (!currentPickupTimeIsInStartTimeSet) {
                    const canSet10AM = _startTimeSet.find(t => t.key === '09:00 am');
                    form.batch(() =>
                      form.change(
                        'pickupTime',
                        canSet10AM ? '09:00 am' : _startTimeSet[0] ? _startTimeSet[0].key : null
                      )
                    );
                  }
                  setSearchDateTime(prev => {
                    return {
                      ...prev,
                      pickUp: values.pickUp.date,
                    };
                  });
                }
                if (active === 'dropOff' && values.dropOff && values.dropOff.date) {
                  const endLimit =
                    values.pickUp && sameDay(values.pickUp.date, values.dropOff.date)
                      ? values.pickUp.date
                      : null;
                  const _endTimeSet = generateDropOffTimeSlots(endLimit);
                  const canSet10AMDropOff = _endTimeSet.find(t => t.key === '09:00 am');
                  form.batch(() =>
                    form.change(
                      'dropOffTime',
                      canSet10AMDropOff ? '09:00 am' : _endTimeSet[0] ? _endTimeSet[0].key : null
                    )
                  );
                }
              }}
            />

            <div className={css.wrapper}>
              <div className={css.fields}>
                <div className={css.row}>
                  <div
                    className={classNames(
                      css.fieldLocation,
                      css.field,
                      css.column12,
                      !isValid && !values.searchLocation && css.inputError
                    )}
                  >
                    <div className={classNames(css.formInput)}>
                      <Field
                        name="searchLocation"
                        render={({ input, meta }) => {
                          const { onChange, ...restInput } = input;

                          // Merge the standard onChange function with custom behaviur. A better solution would
                          // be to use the FormSpy component from Final Form and pass this.onChange to the
                          // onChange prop but that breaks due to insufficient subscription handling.
                          // See: https://github.com/final-form/react-final-form/issues/159
                          const searchOnChange = value => {
                            onChange(value);
                          };

                          const searchInput = { ...restInput, onChange: searchOnChange };
                          return (
                            <LocationAutocompleteInputField
                              input={searchInput}
                              name="searchLocation"
                              label="Location"
                              className={classNames(css.locationSearch)}
                              labelClassName={css.label}
                              iconClassName={css.icon}
                              inputClassName={css.inputClassName}
                              validationClassName={css.locationValidation}
                              placeholder="Search location"
                              meta={meta}
                            />
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className={css.row}>
                  <div className={classNames(css.field, css.column6)} style={{ zIndex: 6 }}>
                    <FieldDateInput
                      useMobileMargins={false}
                      className={classNames(
                        css.formInput,
                        css.dateInput,
                        css.pickUp,
                        !isValid && !values.pickUp && css.inputError
                      )}
                      name="pickUp"
                      id="pickUp"
                      labelClassName={css.label}
                      label="Pickup Date"
                      placeholderText={'Select Pickup date'}
                    />
                  </div>
                  <div className={classNames(css.field, css.column6)}>
                    <FieldSelect
                      useMobileMargins={false}
                      className={classNames(
                        css.formInput,
                        css.dateInput,
                        css.dropOff,
                        !isValid && !values.pickupTime && css.inputError
                      )}
                      name="pickupTime"
                      label="Pickup Time"
                      id="pickupTime"
                      disabled={!values.pickUp}
                      labelClassName={css.label}
                      placeholderText={'Select Pickup Time'}
                    >
                      <option selected disabled>
                        Select Pickup Time
                      </option>
                      {startTimeSet &&
                        startTimeSet.length > 0 &&
                        startTimeSet.map(op => (
                          <option value={op.key} key={`pick_up_${op.key}`}>
                            {op.label}
                          </option>
                        ))}
                    </FieldSelect>
                  </div>
                </div>
                <div className={css.row}>
                  <div className={classNames(css.field, css.column6)} style={{ zIndex: 5 }}>
                    <FieldDateInput
                      useMobileMargins={false}
                      className={classNames(
                        css.formInput,
                        css.dateInput,
                        css.pickUp,
                        !isValid && !values.dropOff && css.inputError
                      )}
                      name="dropOff"
                      label="Dropoff Date"
                      id="dropOff"
                      labelClassName={css.label}
                      placeholderText={'Select Drop-off Date'}
                    />
                  </div>
                  <div className={classNames(css.field, css.column6)}>
                    <FieldSelect
                      disabled={!values.dropOff}
                      useMobileMargins={false}
                      className={classNames(
                        css.formInput,
                        css.dateInput,
                        css.dropOff,
                        !isValid && !values.dropOffTime && css.inputError
                      )}
                      name="dropOffTime"
                      label="Dropoff Time"
                      id="dropOffTime"
                      labelClassName={css.label}
                      placeholder={'Select Drop-off Time'}
                    >
                      <option selected disabled>
                        Select Drop-off Time
                      </option>
                      {endTimeSet &&
                        endTimeSet.length > 0 &&
                        endTimeSet.map(op => (
                          <option value={op.key} key={`drop_op_${op.key}`}>
                            {op.label}
                          </option>
                        ))}
                    </FieldSelect>
                  </div>
                </div>
                {/* <div className={css.row}>
                  <div className={classNames(css.field, css.column12)}>
                    <FieldMultiSelect
                      disabled={!values.dropOff}
                      useMobileMargins={false}
                      className={classNames(
                        css.formInput,
                        css.dateInput,
                        !isValid && !values.priceRange && css.inputError
                      )}
                      name="priceRange"
                      label="Price range"
                      id="priceRange"
                      placeholder="Select Price Range"
                      labelClassName={css.label}
                      options={options}
                    />
                  </div>
                </div> */}
                <div className={css.row}>
                  <div className={classNames(css.field, css.column12)}>
                    <div
                      className={classNames(
                        css.formInput,
                        !isValid && !values.phone && css.inputError
                      )}
                    >
                      <label class="" for="price-range">
                        Contact Number
                      </label>
                      <FieldPhoneNumberInput
                        placeholder={'Enter phone number'}
                        name="phone"
                        inputClassName={css.contactField}
                      />
                    </div>
                  </div>
                </div>
                <div className={css.row}>
                  <div className={classNames(css.field, css.column12)}>
                    <div
                      className={classNames(
                        css.formInput,
                        !isValid && !values.email && css.inputError
                      )}
                    >
                      <FieldTextInput
                        type="email"
                        id={'email'}
                        name="email"
                        autoComplete="email"
                        label={'Email'}
                        placeholder={'Enter email address'}
                      />
                    </div>
                  </div>
                </div>
                {error ? <div className={css.errorDesktop}>{error}</div> : null}
              </div>
            </div>

            <div className={css.buttonWrapper}>
              {!isValid ? (
                <div className={css.formError}>
                  One of the field is empty, please fill the required fields
                </div>
              ) : null}
              {formStatus.success ? <p>{formStatus && formStatus.message}</p> : null}
              <Button className={css.findYourCar} type="submit" disabled={error || invalid}>
                {isLoading ? (
                  <div className={css.locationLoader}>
                    <span></span>
                  </div>
                ) : (
                  'Find me a car'
                )}
              </Button>
            </div>
          </Form>
        );
      }}
    />
  );
};
const mapStateToProps = state => {
  const { currentUser } = state.user;
  return {
    currentUser,
  };
};
export default connect(mapStateToProps)(SearchContactModalForm);
