import React, { useState } from 'react';
import css from './DonationPage.css';
import image1 from '../../assets/donationPage/image1.jpg';
import { DonationForm } from '../../forms';

const MakeADonation = ({ isMobileLayout, vaccineCertificateInProgress, setIsDonateModalOpen, currentUser, isAuthenticated }) => {
  const [formStatus, setFormStatus] = useState({ success: false });
  const handleSubmitSearch = () => {};

  return (
    <div className={css.makeDonationSection}>
      <div className={css.landingContainer}>
        <div className={css.landingRow}>
          <div className={css.column6}>
            <div className={css.content}>
              <h2>Make a donation</h2>
              {isMobileLayout && (
                <div className={css.logoThumb}>
                  <img src={image1} alt="" />
                  <span>(Copyright 2022 The Associated Press. All rights reserved.)</span>
                </div>
              )}
              <p>
                Every donation helps our funding stretch further, helping people with critical
                needs. 100% of all donations goes to supporting people in crisis.
              </p>
              <p>Donate now or make your donation as part of your booking.</p>
              <p>
                We don’t allocate donations directly ourselves. We work together with other non
                profit partners who help people with food, shelter, medical care and clean drinking
                water.
              </p>
              <DonationForm
                onSubmit={handleSubmitSearch}
                className={css.searchForm}
                formStatus={formStatus}
                setFormStatus={setFormStatus}
                vaccineCertificateInProgress={vaccineCertificateInProgress}
                setIsDonateModalOpen={setIsDonateModalOpen}
                currentUser={currentUser}
                isAuthenticated={isAuthenticated}
              />
            </div>
          </div>
          <div className={css.column6}>
            {!isMobileLayout && (
              <div className={css.logoThumb}>
                <img src={image1} alt="" />
                <span>(Copyright 2022 The Associated Press. All rights reserved.)</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MakeADonation;
