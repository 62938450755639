import React, { useState, useEffect } from 'react';
import range from 'lodash/range';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { withViewport } from '../../util/contextHelpers';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as validators from '../../util/validators';
import { FieldSelect, FieldTextInput, FieldPhoneNumberInput } from '../../components';
import classNames from 'classnames';
import { EVENT_SIGNUP_PHONE_CASE_A, EVENT_SIGNUP_PHONE_CASE_B } from '../../util/gtm/gtmConstants'
import { pushGTMSignupForm } from '../../util/gtm/gtmHelpers';
import config from '../../config';
import css from './SignupForm.css';

const days = range(1, 32);
const months = range(1, 13);

// Show a certain number of years up to the current year
const currentYear = new Date().getFullYear();
const yearsToShow = 80;
const years = range(currentYear, currentYear - yearsToShow, -1);

const SignUpField2StepComponent = props => {
  const { intl, formId, values, touched, errors, experiment, currentUser } = props;
  const [selected, setSelected] = useState('+65');
  const [isPhoneCodeSelectReadOnly, setIsPhoneCodeSelectReadOnly] = useState(false);
  const [isPhoneVerified, setIsPhoneVerified] = useState(false);

  useEffect(() => {
    if (
      currentUser &&
      currentUser.attributes &&
      currentUser.attributes.profile &&
      currentUser.attributes.profile.protectedData &&
      currentUser.attributes.profile.protectedData.phoneNumberVerified) {
      setIsPhoneVerified(true);
    }
  }, [currentUser]);

  useEffect(() => {
    if (config && config.custom && config.custom.phoneCode.length) {
      let country = config.defaultPhoneCode;
      if (country) {
        config.custom.phoneCode.every(function (element) {
          if (element.cc === country) {
            setSelected(element.key);
            return false;
          } else {
            return true;
          }
        })
      }
    }
    // Check if it's prod, disable phone code change if it is
    if (config.isProd) {
      setIsPhoneCodeSelectReadOnly(true);
    }
  }, []);

  const dateError = () => {
    const requiredFields = ['day', 'month', 'year'];
    let error = '';
    for (let key in errors) {
      if (touched[key] && errors[key] && requiredFields.includes(key)) {
        return (error = errors[key]);
      }
    }
    return error;
  };

  const phonePlaceholder = intl.formatMessage({
    id: 'ContactDetailsForm.phonePlaceholder',
  });
  const phoneLabel = intl.formatMessage({ id: 'ContactDetailsForm.phoneLabel' });

  //Code

  const codeLabel = intl.formatMessage({
    id: 'SignupForm.codeLabel',
  });
  const codePlaceholder = intl.formatMessage({
    id: 'SignupForm.codePlaceholder',
  });

  const required = validators.required(intl.formatMessage({ id: 'SignupForm.doRequired' }));
  const phoneRequired = validators.required(
    intl.formatMessage({ id: 'ConfirmSignupForm.phoneRequired' })
  );
  const genderRequired = validators.required(
    intl.formatMessage({ id: 'ConfirmSignupForm.genderRequired' })
  );
  const roleRequired = validators.required(
    intl.formatMessage({ id: 'ConfirmSignupForm.roleRequired' })
  );
  const minAge = 22;

  const minAgeMessage = intl.formatMessage({ id: 'SignupForm.minAgeRequired' }, { minAge });
  const minAgeRequired = validators.ageAtLeast(minAgeMessage, minAge, values.day, values.month);

  return (
    <div>
      {/* <label className={css.fieldLabel}>Date of Birth</label>
      <div className={css.birthdayWrapper}>
        <FieldSelect
          id={formId ? `${formId}.day` : 'day'}
          name="day"
          className={classNames(css.birthdaySelect, css.day)}
          validate={required}
          hideError={true}
        >
          <option value="" selected={true} disabled={true}>
            Day
          </option>
          {days.map(day => (
            <option value={day} key={day}>
              {day}
            </option>
          ))}
        </FieldSelect>
        <FieldSelect
          id={formId ? `${formId}.month` : 'month'}
          name="month"
          className={classNames(css.birthdaySelect, css.month)}
          validate={required}
          hideError={true}
        >
          <option value="" selected={true} disabled={true}>
            Month
          </option>
          {months.map(item => (
            <option value={item} key={item}>
              {item}
            </option>
          ))}
        </FieldSelect>
        <FieldSelect
          id={formId ? `${formId}.year` : 'year'}
          name="year"
          className={classNames(css.birthdaySelect, css.year)}
          validate={validators.composeValidators(required, minAgeRequired)}
          hideError={true}
        >
          <option value="" selected={true} disabled={true}>
            Year
          </option>
          {years.map(year => (
            <option value={year} key={year}>
              {year}
            </option>
          ))}
        </FieldSelect>
      </div>
      <div className={css.minYearError}>{dateError()}</div>

      <label className={css.fieldLabel}>
        {intl.formatMessage({ id: 'SignupForm.genderLabel' })}
      </label>
      <FieldSelect
        id="gender"
        name="gender"
        validate={genderRequired}
        className={css.roleSelect}
        defaultValue="Male"
      >
        <option value="" selected={true} disabled={true}>
          {intl.formatMessage({ id: 'SignupForm.genderPlaceholder' })}
        </option>
        {config.custom.genders.map(option => (
          <option value={option.key} key={option.key}>
            {option.label}
          </option>
        ))}
      </FieldSelect> */}
      {
         !isPhoneVerified && <div className={css.phoneNumberFieldWrapper}>
          <label className={css.fieldLabel}>{phoneLabel}</label>
          <div className={css.phoneNumberWrapper}>
            <div>
              <FieldSelect
                id="phoneCode"
                name="phoneCode"
                className={css.phoneCodeSelect}
                defaultValue={selected}
                validate={required}
                // disabled={isPhoneCodeSelectReadOnly} //Disabled since we are already setting default value of phone
              >
                <option value={''} key={'_default_'}>+00</option>
                {config.custom.phoneCode.map((option, index) => (
                  <option value={option.key} key={option.key}>
                    {option.key}
                  </option>
                ))}
              </FieldSelect>
            </div>
            <FieldPhoneNumberInput
              className={classNames(css.formInput, css.noMargin, css.phoneNumberField)}
              rootClassName={css.phoneNumberField}
              name="phoneNumber"
              id={formId ? `${formId}.phoneNumber` : 'phoneNumber'}
              placeholder={phonePlaceholder}
              disabled={!values.phoneCode}
              validate={phoneRequired}
            />
          </div>
        </div>
      }
      <label className={css.fieldLabel}>What will you be using Drive lah primarily for</label>
      <FieldSelect
        id="role"
        name="role"
        className={css.roleSelect}
        validate={roleRequired}
        defaultValue="renter"
      >
        <option value="" selected={true} disabled={true}>
          {intl.formatMessage({ id: 'SignupForm.rolePlaceholder' })}
        </option>
        {config.custom.roles.map((option, index) => (
          <option value={option.key} key={option.key}>
            {option.label}
          </option>
        ))}
      </FieldSelect>
      <label className={css.fieldLabel}>{codeLabel}</label>
      <FieldTextInput
        className={classNames(css.formInput, css.noMargin)}
        type="text"
        id={formId ? `${formId}.code` : 'code'}
        name="code"
        label={codeLabel}
        placeholder={codePlaceholder}
      />
    </div>
  );
};

SignUpField2StepComponent.defaultProps = { inProgress: false };

const { bool, func, string } = PropTypes;

SignUpField2StepComponent.propTypes = {
  inProgress: bool,
  submitButtonId: string,
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  return {
    currentUser,
  };
};

const mapDispatchToProps = dispatch => ({
});

// See: https://github.com/ReactTraining/react-router/issues/4671
const SignUpField2Step = compose(
  withViewport,
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(SignUpField2StepComponent);
SignUpField2Step.displayName = 'SignUpField2Step';

export default SignUpField2Step;
