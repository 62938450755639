import React, { Component } from 'react';
import { bool, func, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import config from '../../config';
import { connect } from 'react-redux';

import UploadFile from './UploadFile';
import css from './FieldUploadFile.css';

const { apiUrl } = config;

class FieldUploadFile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fileUrl: null,
      uploadError: null,
      photoUrl: props.initialValues[0]
    }

    this.handleError = this.handleError.bind(this);
    this.handleShowImg = this.handleShowImg.bind(this);
  }

  handleError(error) {
    this.setState({
      uploadError: error
    })
  }

  handleShowImg(url) {
    this.setState({
      fileUrl: url
    })
  }

  render() {
    const {
      rootClassName,
      className,
      id,
      name,
      acceptType,
      label,
      typeFile,
      id2,
      name2,
      typeFile2,
      twoColumns,
      isPhoto,
      initialValues,
      hideUpload,
      previewFileClassName,
      disable,
      disablesPhoto,
      buttonId,
      buttonId2,
      pushEventGTM,
      pushEventGTM2,
      inputClassName
    } = this.props;

    const classes = classNames(twoColumns ? css.twoColumns : css.oneColumn, rootClassName || css.root, className);

    const uploadError = this.state.uploadError ? (
      <p className={css.error}>
        <FormattedMessage id={this.state.uploadError.id} values={{fileType: this.state.uploadError.fileType}} />
      </p>
    ) : null;

    const inputWrapperClasses = classNames(css.inputWrapper, inputClassName)

    return (
      <div className={classes}>
        <label htmlFor={name} className={css.label}>
          {label(isPhoto ? this.props.imageUpload && this.props.imageUpload.uploadedImage ? this.props.imageUpload.uploadedImage.attributes.variants['square-small2x'].url : this.state.photoUrl : this.state.fileUrl)}
        </label>
        <div className={inputWrapperClasses}>
          <UploadFile
            disable={disable}
            disablesPhoto={disablesPhoto}
            id={id}
            name={name}
            acceptType={acceptType}
            typeFile={typeFile}
            buttonClassName={
              classNames(css.buttonUpload, twoColumns || isPhoto ? css.inputFieldLong : css.null)
            }
            button={twoColumns ? name2 ?
              'upload copy front'
              : 'upload copy'
              : isPhoto ? 'upload photo' : 'upload'
            }
            previewClassName={
              classNames(twoColumns ? css.previewFiles : '', css.previewFile, previewFileClassName)
            }
            previewLabel={`Submitted copy ${name2 ? 'front' : ''}`}
            isPhoto={isPhoto}
            setStateUploadData={this.props.setStateUploadData}
            currentUser={this.props.currentUser}
            setUploadState={this.props.setUploadState}
            handleError={this.handleError}
            handleShowImg={this.handleShowImg}
            initialValue={initialValues[0]}
            setPhotoUrl={(url) => {
              this.setState({
                photoUrl: url
              })
            }}
            hideUpload={hideUpload}
            buttonId={buttonId}
            pushEventGTM={pushEventGTM}
          />

          {twoColumns && name2 &&
            <UploadFile
              disable={disable}
              disablesPhoto={disablesPhoto}
              id={id2}
              name={name2}
              acceptType={acceptType}
              typeFile={typeFile2}
              buttonClassName={
                classNames(css.buttonUpload, twoColumns || isPhoto ? css.inputFieldLong : css.null)
              }
              button={twoColumns ? name2 ?
                'upload copy back'
                : 'upload copy'
                : isPhoto ? 'upload photo' : 'upload'
              }
              previewClassName={
                classNames(twoColumns ? css.previewFiles : '', css.previewFile, previewFileClassName)
              }
              previewLabel={`Submitted copy back`}
              isPhoto={isPhoto}
              setStateUploadData={this.props.setStateUploadData}
              currentUser={this.props.currentUser}
              setUploadState={this.props.setUploadState}
              handleError={this.handleError}
              handleShowImg={this.handleShowImg}
              initialValue={initialValues[1]}
              setPhotoUrl={(url) => {
                this.setState({
                  photoUrl: url
                })
              }}
              hideUpload={hideUpload}
              buttonId={buttonId2}
              pushEventGTM={pushEventGTM2}
            />
          }
        </div>
        {uploadError}
      </div>
    )
  }
}

FieldUploadFile.defaultProps = {
  rootClassName: null,
  className: null,
  id: null,
  name: null,
  acceptType: null,
  label: null,
  typeFile: null,
  disable: false,
};

FieldUploadFile.propTypes = {
  rootClassName: string,
  className: string,
  id: string,
  name: string,
  acceptType: string,
  typeFile: string,
  disable: bool,
  buttonId: string,
  buttonId2: string,
  pushEventGTM: func,
  pushEventGTM2: func,
}

const mapStateToProps = state => {
  const {
    image,
  } = state.ProfileSettingsPage;

  return {
    imageUpload: image
  };
};

const mapDispatchToState = dispatch => ({

})

export default connect(mapStateToProps, mapDispatchToState)(FieldUploadFile);
