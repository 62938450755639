import React, { memo } from 'react';
import css from './HostPromoGroundPlan.css';
import vulcanPost from '../../assets/hostLanding/vulcan.png';
import theStraitsTime from '../../assets/hostLanding/straits.png';
import cna from '../../assets/hostLanding/cna.png';
import zaobao from '../../assets/hostLanding/zao.png';

export const Channels = () => (
  <div className={css.channels}>
    <div className={css.channelsRow}>
      <a
        href="https://www.straitstimes.com/singapore/transport/car-not-used-much-rent-it-out-for-cash"
        className={css.channel}
        target="_blank"
      >
        <img className={css.channelImage} src={theStraitsTime} alt="THE STRAITS TIMES" />
      </a>
      <a
        href="https://www.facebook.com/drivelah2018/videos/drive-lah-featured-on-channel-news-asia/991990264536564/"
        target="_blank"
        className={css.channel}
      >
        <img className={css.channelImage} src={cna} alt="CNA" />
      </a>
      <a
        href="https://vulcanpost.com/679151/p2p-car-sharing-drive-lah-singapore/"
        target="_blank"
        className={css.channel}
      >
        <img className={css.channelImage} src={vulcanPost} alt="Vulcan Post" />
      </a>
      <a
        href="https://www.zaobao.com.sg/news/singapore/story20191116-1005643"
        className={css.channel}
        target="_blank"
      >
        <img className={css.channelImage} src={zaobao} alt="ZAOBAO" />
      </a>
    </div>
  </div>
);

const SectionRealBenefits = memo(() => {
  return (
    <div className={css.sectionRealBenefits}>
      <Channels />
    </div>
  );
});

export default SectionRealBenefits;
