import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import {
  default as facebookImage,
  default as twitterImage,
} from '../../assets/facebook_drivelah_2.jpg';
import leftImage from '../../assets/step_2.jpg';
import {
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
} from '../../components';
import config from '../../config';
import { TopbarContainer } from '../../containers';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { withViewport } from '../../util/contextHelpers';
import { SIGNUP_UPDATE_SUBMIT_BUTTON_ID, EVENT_SIGNUP_COMPLETED_STEP2 } from '../../util/gtm/gtmConstants';
import { injectIntl, intlShape } from '../../util/reactIntl';

import { authenticationInProgress, update } from '../../ducks/Auth.duck';
import { SignupFormStep2 } from '../../forms';
import css from './ConfirmSignUpPage.css';
import { createRawPropertiesForGTM, getEventsByBucket } from '../../util/gtm/gtmCreateProperties';
import { pushGTMSignupForm } from '../../util/gtm/gtmHelpers';
import { get } from 'lodash';
import { triggerAnalyticsEvent } from '../../util/amplitudeMapEvents';
import { event_trigger_ids } from '../../util/analyticsConstants';

export const ConfirmSignUpPageComponent = props => {
  const {
    intl,
    scrollingDisabled,
    currentUser,
    authInProgress,
    submitUpdate,
    confirmError,
  } = props;

  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;
  const [isReferralInvalid, setIsReferralInvalid] = useState(false);
  const [referralCode, setReferralCode] = useState(null);

  useEffect(() => {
    const code = window.localStorage.getItem('code');
    if (code) {
      setReferralCode(code);
    }
  }, []);

  // const handleSubmitUpdate = values => {
  //   const { day, month, year, ...rest } = values;
  //   // const dateOfBirth = {
  //   //   day: Number(day),
  //   //   month: Number(month),
  //   //   year: Number(year),
  //   // };

  //   submitUpdate({ ...rest });
  //   localStorage.setItem('step2', false);
  //   const properties = createRawPropertiesForGTM({
  //     props: props,
  //     button: {
  //       buttonId: SIGNUP_UPDATE_SUBMIT_BUTTON_ID,
  //     },
  //   });
  //   try {
  //     const response = await getEventsByBucket('signup');
  //     // console.log('Events fetched successfully:', response.data);
  //      triggerAnalyticsEvent('signup_completed', response.data, { referralCode: this.state.referralCode, ...this.props, button: {
  //         buttonId: SIGNUP_SUBMIT_BUTTON_ID,
  //       }});
  //   }
  //   catch(error) {
  //     console.log('Error fetching events:', error);
  //   }
    
  //   pushGTMSignupForm(properties, EVENT_SIGNUP_COMPLETED_STEP2);
  // };

  const handleSubmitUpdate = async values => {
    const { day, month, year, ...rest } = values;
  
    try {
      // Submit the update without the date fields
      await submitUpdate({ ...rest });
  
      // Store step2 status in localStorage
      localStorage.setItem('step2', false);
  
      // Create properties for GTM
      // const properties = createRawPropertiesForGTM({
      //   props: props,
      //   button: {
      //     buttonId: SIGNUP_UPDATE_SUBMIT_BUTTON_ID,
      //   },
      // });
  
      // Fetch events from the bucket
      const response = await getEventsByBucket('signup');
      // console.log('Events fetched successfully: signup_completed_step_2', props);
      let  propsSignupData = {

      }
      if(currentUser) {
        propsSignupData.role = get(currentUser, 'attributes.profile.protectedData.role');
        propsSignupData.signupCode = get(currentUser, 'attributes.profile.protectedData.referralCode');
        propsSignupData.referralCode = get(currentUser, 'attributes.profile.metadata.referralCode');
        propsSignupData.signupMode = 'email';
      }
      // Trigger analytics event with additional properties
      // console.log("Current User id", currentUser, propsSignupData);
      triggerAnalyticsEvent({event_id: event_trigger_ids.SIGN_UP_COMPLETED_STEP2, eventData: response.data, 
        props: {
          guest: currentUser,
          signup:{
            mode: 'email',
            role: currentUser && get(currentUser, 'attributes.profile.protectedData.role'),
            referral: currentUser && get(currentUser, 'attributes.profile.metadata.referralCode'),
            code: currentUser && get(currentUser, 'attributes.profile.protectedData.referralCode')
          },
          ui: {
            button: SIGNUP_UPDATE_SUBMIT_BUTTON_ID,
            page: 'SignupStep2'
          }
        },
        user: currentUser,
        isBackendApiCall: true,
        userId: currentUser && currentUser.id && currentUser.id.uuid
      });

    } catch (error) {
      console.log('Error during handleSubmitUpdate:', error);
    }
  };

  const confirmErrorMessage = confirmError ? <div className={css.error}>{confirmError}</div> : null;
  const getStep2Flag = localStorage.getItem('step2');
  if (currentUser && getStep2Flag === 'false') {
    return <Redirect to={'/'} />;
  }

  const initialValues = {
    code: referralCode,
  };

  return (
    <Page
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn rootClassName={css.rootLayout}>
        <LayoutWrapperTopbar>
          <TopbarContainer notApplySearch={true} currentPage={'ConfirmSignUpPage'} />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain>
          <div className={css.root}>
            <div className={css.authPageContent}>
              <div className={css.leftBannerImage}>
                <img src={leftImage} alt="" />
              </div>
              <div className={css.rightContent}>
                <div className={css.authPageTitle}>Tell us a bit more about yourself</div>
                {confirmErrorMessage}
                <SignupFormStep2
                  initialValues={initialValues}
                  onSubmit={data => handleSubmitUpdate({ ...data })}
                  inProgress={authInProgress}
                  submitButtonId={SIGNUP_UPDATE_SUBMIT_BUTTON_ID}
                />
              </div>
            </div>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>{/*<Footer />*/}</LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool, object } = PropTypes;

ConfirmSignUpPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const { isAuthenticated, confirmError } = state.Auth;
  const { featuredCarIds, fetchFeaturedCarInProgress, fetchFeaturedCarError } = state.SearchPage;
  const featuredCarRefs = featuredCarIds.map(id => ({ type: 'listing', id }));

  const listings = getMarketplaceEntities(state, featuredCarRefs);
  return {
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
    turnoffPromoteBar: state.UI.turnoffPromoteBar,
    isAuthenticated,
    fetchFeaturedCarInProgress,
    fetchFeaturedCarError,
    listings,
    authInProgress: authenticationInProgress(state),
    confirmError,
  };
};

const mapDispatchToProps = dispatch => ({
  submitUpdate: params => dispatch(update(params)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ConfirmSignUpPage = compose(
  withViewport,
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(ConfirmSignUpPageComponent);

// ConfirmSignUpPage.loadData = () => {
//   return fetchFeaturedBCar();
// };

export default ConfirmSignUpPage;
