/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { memo, useEffect } from 'react';
import css from './SeoLandingPage.css';
import isEqual from 'lodash/isEqual';
import { useHistory } from 'react-router';
import { TOP_LOCATIONS } from './seoCategoriesArrays';

const SeoLandingRentOtherBrandsLocation = memo(
  ({ selectedValues, setSelectedValues, locationName }) => {
    const history = useHistory();
    useEffect(() => {
      const getDefaultKey =
        TOP_LOCATIONS.values &&
        TOP_LOCATIONS.values.length > 0 &&
        TOP_LOCATIONS.values.filter(item => item.key === locationName);
      setSelectedValues(getDefaultKey[0]);
    }, []);
    const redirectToDifferentLocation = (location, locationName) => {
      setSelectedValues(location);
      history.push(locationName);
      history.go(0);
    };
    return (
      <div className={css.rentFromOtherBrands}>
        <div className={css.rentFromOtherBrandsContainer}>
          <div className={css.rentFromOtherBrandsTitle}>
            <span>Rent cars in other popular locations in Singapore</span>
            <p>
              Drive lah hosts are from all over Singapore and no matter where you are there will
              always be a Drive lah car nearby for you to rent.
            </p>
          </div>
          <div className={css.rentFromOtherBrandsListing}>
            <ul className={css.listings}>
              {TOP_LOCATIONS.values.map(location => {
                if (selectedValues && selectedValues.key !== location.key) {
                  return (
                    <li
                      key={location.key}
                      className={css.listingsItem}
                      onClick={() =>
                        redirectToDifferentLocation(
                          location,
                          TOP_LOCATIONS.generateRoute(location.key)
                        )
                      }
                    >
                      <div className={css.listingsItemContent}>{location.label}</div>
                    </li>
                  );
                }
              })}
            </ul>
          </div>
        </div>
      </div>
    );
  },
  (prev, next) => isEqual(prev, next)
);

export default SeoLandingRentOtherBrandsLocation;
