import { bool } from 'prop-types';
import React from 'react';
import { formatMoney } from '../../util/currency';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { LINE_ITEM_IS_EXCESS_REDUCTION, propTypes } from '../../util/types';
import Explanation from './Explaination';

import css from './BookingBreakdownNew.css';

const LineItemNewExcessReductionAddOnMaybe = props => {
  const { transaction, isCustomer, intl, unitType } = props;

  if(!transaction || transaction === null) return null;

  let excessReductionLineItem = null;

  if (isCustomer) {
    excessReductionLineItem = transaction.attributes.lineItems.find(
      item => item.code === LINE_ITEM_IS_EXCESS_REDUCTION && !item.reversal
    );
  }

  if (!excessReductionLineItem || excessReductionLineItem.length === 0) {
    return null;
  }

  const commission = excessReductionLineItem.lineTotal;

  const formattedCommission = commission ? formatMoney(intl, commission, 1) : null;

  return formattedCommission ? (
    <div className={css.addOnLineItem}>
      <div className={css.totalLabel}>
        <FormattedMessage id="BookingBreakdown.excessReduction" />
        <Explanation text="Reduced liability with savings up to $4280" />
      </div>
      <div className={css.itemValue}>{formattedCommission}</div>
    </div>
  ) : null;
};

LineItemNewExcessReductionAddOnMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isCustomer: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemNewExcessReductionAddOnMaybe;
