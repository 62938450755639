import config from '../../config';
import { createUIProperties, createUserProperties } from '../gtm/gtmCreateProperties';

const pushConversionAttributes = data => {
  const windowEvent = typeof window !== 'undefined' ? window : [];
  const { events } = data;
  if (windowEvent && config.googleTagId) {
    const conversionObject = {
      send_to: `${config.googleTagId}/${events.eventID}`,
      data,
    };
    windowEvent.gtag('event', events.eventName, conversionObject);
  }
};
const pushConversionAttributesga4 = data => {
  const windowEvent = typeof window !== 'undefined' ? window : [];
  const { events } = data;
  if (windowEvent && config.googleTagId) {
    const conversionObject = {
      send_to: `${config.googleTagId}/${events.eventID}`,
      data,
    };
    windowEvent.gtag4('event', events.eventName, conversionObject);
  }
};

const eventCallbackTrigger = (url = null) => {
  return url;
  // if (typeof url != 'undefined') {
  //   return window.location;
  // }
};

export const createConversionEvents = async (properties, events, eventCallback = null) => {
  const { ui, currentUser } = properties || {};
  const UIData = createUIProperties(ui);
  const dateTime = new Date().toISOString();
  const userData = await createUserProperties(currentUser);
  const { user } = userData;
  const data = {
    events,
    properties: {
      ui: {
        ...UIData,
      },
      user: {
        ...user,
      },
      dateTime,
    },
    eventCallback: eventCallback ? eventCallbackTrigger() : null,
  };
  pushConversionAttributes(data);
  pushConversionAttributesga4(data);
};