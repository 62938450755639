import React from 'react';

import css from './FieldError.module.css';

const FieldError = (props) => {
  const {
    visible,
    children,
  } = props;

  if (!visible) {
    return null;
  }

  return (
    <div className={css.error}>{children}</div>
  );
};

export default FieldError;
