import classNames from 'classnames';
import { bool, func, shape, string } from 'prop-types';
import React, { useRef } from 'react';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { compose } from 'redux';
import infoCircle from '../../assets/updatelisting/info-circle-solid.svg';
import { Button, Form } from '../../components';
import FieldTextInputInfo from '../../components/FieldTextInputInfo/FieldTextInputInfo';
import config from '../../config';
import { formatMoney } from '../../util/currency';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { types as sdkTypes } from '../../util/sdkLoader';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import css from './EditListingPromotionsForm.css';

const { Money } = sdkTypes;

export const EditListingPromotionsFormComponent = ({ initialValues, ...props }) => {
  const currentValues = useRef({});
  const submitedValues = useRef({});
  return (
    <FinalForm
      initialValues={{ ...initialValues, ...currentValues.current }}
      {...props}
      render={formRenderProps => {
        const {
          className,
          disabled,
          ready,
          handleSubmit,
          intl,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
          form,
          values,
          submitButtonId,
        } = formRenderProps;

        const unitType = config.bookingUnitType;
        const isNightly = unitType === LINE_ITEM_NIGHT;
        const isDaily = unitType === LINE_ITEM_DAY;

        // Listing 3 Day Discount field
        const threeDayDiscountLabel = intl.formatMessage({
          id: 'EditListingPromotionsPanel.threeDayDiscount',
        });
        const threeDayDiscountInfo = intl.formatMessage({
          id: 'EditListingPromotionsPanel.threeDayDiscountInfo',
        });

        // Listing 7 Day Discount field
        const sevenDayDiscountLabel = intl.formatMessage({
          id: 'EditListingPromotionsPanel.sevenDayDiscount',
        });
        const sevenDayDiscountInfo = intl.formatMessage({
          id: 'EditListingPromotionsPanel.sevenDayDiscountInfo',
        });

        // Listing Give discount on your first 3 bookings field
        const firstThreeBookingDiscountLabel = intl.formatMessage({
          id: 'EditListingPromotionsPanel.first3BookingDiscountLabel',
        });
        const firstThreeBookingDiscountInfo = intl.formatMessage({
          id: 'EditListingPromotionsPanel.first3BookingDiscountInfo',
        });

        // Listing Discount on first 3 bookings field
        const firstThreeBookingDiscountPercentage = intl.formatMessage({
          id: 'EditListingPromotionsPanel.firstThreeBookingDiscountPercentage',
        });
        const firstThreeBookingRequired = intl.formatMessage({
          id: "EditListingPromotionsPanel.firstThreeBookingDiscountPercentageRequired"
        })

        const translationKey = isNightly
          ? 'EditListingPricingForm.pricePerNight'
          : isDaily
          ? 'EditListingPricingForm.pricePerDay'
          : // : 'EditListingPricingForm.pricePerUnit';
            'EditListingPricingForm.pricePerUnitRegularText';
        const pricePerUnitMessage = intl.formatMessage({
          id: translationKey,
        });

        // Keep this for future reference
        // const pricePlaceholderMessage = intl.formatMessage({
        //   id: 'EditListingPricingForm.priceInputPlaceholder',
        // });

        // New design labels
        const pricePlaceholderMessage = intl.formatMessage({
          id: 'EditListingPricingForm.priceInputRegularPlaceholder',
        });

        // Regular price information
        const valueHighLight = <strong>&nbsp;$60&nbsp;</strong>;
        const regularPriceInputInformation = intl.formatMessage(
          {
            id: 'EditListingPricingForm.pricePerUnitInfoText',
          },
          { price: valueHighLight }
        );

        const priceRequired = validators.required(
          intl.formatMessage({
            id: 'EditListingPricingForm.priceRequired',
          })
        );
        const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
        const minPriceRequired = validators.moneySubUnitAmountAtLeast(
          intl.formatMessage(
            {
              id: 'EditListingPricingForm.priceTooLow',
            },
            {
              minPrice: formatMoney(intl, minPrice),
            }
          ),
          config.listingMinimumPriceSubUnits
        );
        const priceValidators = config.listingMinimumPriceSubUnits
          ? validators.composeValidators(priceRequired, minPriceRequired)
          : priceRequired;

        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;
        const { updateListingError, showListingsError } = fetchErrors || {};

        const discountValidate = (field, values) => value => {
          let message = undefined;
          const { price, peakPrice } = values;
          if (values.price) {
            let totalPrice = 0;
            if (field === 'week') {
              totalPrice = (price.amount * 7 * (100 - parseInt(value))) / 100;
            } else if (field === 'month') {
              totalPrice = (price.amount * 30 * (100 - parseInt(value))) / 100;
            }
            if (totalPrice < 1)
              message = intl.formatMessage({ id: 'EditListingPricingForm.invalidDiscountValue' });
          }
          if (values.peakPrice) {
            let totalPrice = 0;
            if (field === 'week') {
              totalPrice = (peakPrice.amount * 7 * (100 - parseInt(value))) / 100;
            } else if (field === 'month') {
              totalPrice = (peakPrice.amount * 30 * (100 - parseInt(value))) / 100;
            }
            if (totalPrice < 1)
              message = intl.formatMessage({ id: 'EditListingPricingForm.invalidDiscountValue' });
          }

          return message;
        };

        // Calculate demand type {Code here}
        const demandType = 'Good';
        const meterDisplay =
          demandType === 'Bad'
            ? 'rotate(30deg)'
            : demandType === 'Average'
            ? 'rotate(80deg)'
            : demandType === 'Good'
            ? 'rotate(165deg)'
            : 'rotate(0)';
        const demandTypeText = <strong>{demandType} Demand</strong>;
        const demandTypeSmaller = demandType.toLowerCase();
        const demandCalculatorLabel = intl.formatMessage(
          {
            id: 'EditListingPromotionsPanel.demandCalculatorLabel',
          },
          { demandType: demandTypeText, demandTypeSmaller }
        );

        const discountMinValueMessage = intl.formatMessage({
          id: 'EditListingPricingForm.discountMinValueMessage',
        });

        const discountMaxValueMessage = intl.formatMessage({
          id: 'EditListingPricingForm.discountMaxValueMessage',
        });

        return (
          <Form
            onSubmit={e => {
              currentValues.current = values;
              submitedValues.current = values;
              handleSubmit(e);
            }}
            className={classes}
          >
            {updateListingError ? (
              <p className={css.error}>
                <FormattedMessage id="EditListingPricingForm.updateFailed" />
              </p>
            ) : null}

            {showListingsError ? (
              <p className={css.error}>
                <FormattedMessage id="EditListingPricingForm.showListingFailed" />
              </p>
            ) : null}
            <div className={css.displayInlineContainer}>
              {/* <div className={classNames(css.column, css.column12, css.inputWrapper)}>
                <div className={css.customInputLabel}>{threeDayDiscountLabel}</div>
                <div className={css.inputWithPostFix}>
                  <FieldTextInputInfo
                    id="threeDays"
                    name="threeDays"
                    type="number"
                    step={1}
                    min={0}
                    max={100}
                    parse={v => parseInt(v)}
                    className={classNames(css.priceInput, css.field, css.newInput)}
                    errorClassName={css.errorClassName}
                    inputClass={css.inputClass}
                    postFix={<span className={css.inputPostFix}>%</span>}
                    // label={pricePerUnitMessage}
                    validate={validators.composeValidators(
                      discountValidate('week', values),
                      validators.minValue(discountMinValueMessage, 0),
                      validators.maxValue(discountMaxValueMessage, 100)
                    )}
                    placeholder={pricePlaceholderMessage}
                  />
                </div>
                <div className={css.infoText}>
                  <span className={css.infoIcon}>
                    <img src={infoCircle} alt="" />
                  </span>
                  <p>{threeDayDiscountInfo}</p>
                </div>
                </div> */}
              <div className={classNames(css.column, css.column12)}>
                <div className={css.customInputLabel}>{sevenDayDiscountLabel}</div>
                <div className={css.inputWithPostFix}>
                  <FieldTextInputInfo
                    id="week"
                    name="week"
                    type="number"
                    step={1}
                    min={0}
                    max={100}
                    parse={v => parseInt(v)}
                    className={classNames(css.priceInput, css.field, css.newInput)}
                    errorClassName={css.errorClassName}
                    inputClass={css.inputClass}
                    postFix={<span className={css.inputPostFix}>%</span>}
                    // label={pricePerUnitMessage}
                    validate={validators.composeValidators(
                      discountValidate('month', values),
                      validators.minValue(discountMinValueMessage, 0),
                      validators.maxValue(discountMaxValueMessage, 100)
                    )}
                    placeholder={pricePlaceholderMessage}
                  />
                </div>
                <div className={css.infoText}>
                  <span className={css.infoIcon}>
                    <img src={infoCircle} alt="" />
                  </span>
                  <p>{sevenDayDiscountInfo}</p>
                </div>
              </div>
              {/* <div className={classNames(css.column, css.column12)}>
                <div className={classNames(css.customToggleInput, css.field)}>
                  <div className={css.toggleLabels}>
                    <label htmlFor="isThreeBookingDiscount">{firstThreeBookingDiscountLabel}</label>
                    <Explanation text="Once activated, all the public holidays in Singapore will automatically use the peak price set by you. You can turn off or on anytime." />
                  </div>
                  <div className={css.toggleInput}>
                    <FieldToggleSwitch name="isThreeBookingDiscount" id="isThreeBookingDiscount" />
                  </div>
                  <div className={css.infoText}>
                    <span className={css.infoIcon}>
                      <img src={infoCircle} alt="" />
                    </span>
                    <p>{firstThreeBookingDiscountInfo}</p>
                  </div>
                </div>
              </div> */}
              <FormSpy
                subscription={{ values: true }}
                render={({ values }) => {
                  if (values.isThreeBookingDiscount) {
                    return (
                      <div className={classNames(css.column, css.column12)}>
                        <div className={css.customInputLabel}>
                          {firstThreeBookingDiscountPercentage}
                        </div>
                        <div className={css.inputWithPostFix}>
                          <FieldTextInputInfo
                            id="threeBookingDiscount"
                            name="threeBookingDiscount"
                            type="number"
                            step={1}
                            min={0}
                            max={100}
                            parse={v => parseInt(v)}
                            className={classNames(css.priceInput, css.field, css.newInput)}
                            errorClassName={css.errorClassName}
                            inputClass={css.inputClass}
                            postFix={<span className={css.inputPostFix}>%</span>}
                            // label={pricePerUnitMessage}
                            validate={validators.composeValidators(
                              validators.required(firstThreeBookingRequired),
                              discountValidate('month', values),
                              validators.minValue(discountMinValueMessage, 0),
                              validators.maxValue(discountMaxValueMessage, 100)
                            )}
                            placeholder={pricePlaceholderMessage}
                          />
                        </div>
                      </div>
                    );
                  }
                  return null;
                }}
              />
              {/* <div className={classNames(css.column, css.column12)}>
                <div className={css.customInputLabel}>{firstThreeBookingDiscountPercentage}</div>
                <div className={css.inputWithPostFix}>
                  <FieldCurrencyInput
                    id="peakPrice"
                    name="peakPrice"
                    className={classNames(css.priceInput, css.field, css.newInput)}
                    // label={peakPriceLabel}
                    placeholder={pricePlaceholderMessage}
                    currencyConfig={config.currencyConfig}
                    validate={(...priceData) => {
                      const { values } = form.getState();
                      const { peakDays = [], isPublicHolidayPeakDays = false } = values;
                      return peakDays.length > 0 || isPublicHolidayPeakDays
                        ? priceValidators(...priceData)
                        : priceData[0]
                        ? minPriceRequired(priceData[0])
                        : undefined;
                    }}
                  />
                  <span className={css.inputPostFix}>%</span>
                </div>
              </div> */}
            </div>

            {/* <FieldSelectDay
              id="peakDays"
              name="peakDays"
              className={css.field}
              label={peakDaysLabel}
              initialsOnly={true}
              customOnChange={(key, isSelected) => {
                if (!isSelected) {
                  const { values } = form.getState();
                  const { regularDays = [] } = values;
                  setTimeout(() =>
                    form.change(
                      'regularDays',
                      regularDays.filter(day => day !== key)
                    )
                  );
                } else {
                  const { values } = form.getState();
                  const { regularDays = [] } = values;
                  regularDays.push(key);
                  setTimeout(() => form.change('regularDays', regularDays));
                }
              }}
            /> */}

            {/* <label htmlFor="discount">
              <FormattedMessage id="EditListingPricingForm.discountTitle" />
            </label> */}
            <div id="discount" className={css.field}>
              {/* <FieldTextInput
                id="week"
                name="week"
                label={weekLabel}
                placeholder={weekPlaceholder}
                type="number"
                step={1}
                min={0}
                max={100}
                parse={v => parseInt(v)}
                inline
                className={css.field}
                validate={validators.composeValidators(
                  discountValidate('week', values),
                  validators.minValue(discountMinValueMessage, 0),
                  validators.maxValue(discountMaxValueMessage, 100)
                )}
              /> */}
              {/* <FieldTextInput
                id="month"
                name="month"
                label={monthLabel}
                placeholder={monthPlaceholder}
                type="number"
                step={1}
                min={0}
                max={100}
                inline
                parse={v => parseInt(v)}
                validate={validators.composeValidators(
                  discountValidate('month'),
                  validators.minValue(discountMinValueMessage, 0),
                  validators.maxValue(discountMaxValueMessage, 100)
                )}
              /> */}
            </div>
            {/* <FormSpy
              subscription={{ values: true }}
              render={({ values }) => {
                if (values.longTermRental) {
                  return (
                    <FieldCurrencyInput
                      id="longTermPrice"
                      name="longTermPrice"
                      className={classNames(css.priceInput, css.field)}
                      label={
                        <div>
                          Monthly price in Singapore dollars{' '}
                          <Explanation text="We recommend the Monthly price to be at least 10-15% discounted as compared to Daily price" />
                        </div>
                      }
                      placeholder={pricePlaceholderMessage}
                      currencyConfig={config.currencyConfig}
                      validate={priceValidators}
                    />
                  );
                }
                return null;
              }}
            /> */}

            {/* <div className={css.moreDesc}>
              <FormattedMessage id="EditListingPricingForm.moreDescription" />
            </div> */}
            <div className={css.stickyButtons}>
              <div className={css.stickButtonsContainer}>
                <div className={css.stickButtonsDescription}>
                  {/* <span className={css.descIcon}>
                    <img src={meterIcon} alt="" />
                    <img
                      src={meterNeedle}
                      alt=""
                      style={{ transform: meterDisplay }}
                      className={css.needle}
                    />
                  </span>
                  <p>{demandCalculatorLabel}</p> */}
                </div>
                <Button
                  className={css.submitButton}
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                  ready={submitReady}
                  id={submitButtonId}
                >
                  {/* {saveActionMsg} */}
                  Next
                </Button>
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
};

EditListingPromotionsFormComponent.defaultProps = { fetchErrors: null };

EditListingPromotionsFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  submitButtonId: string,
};

export default compose(injectIntl)(EditListingPromotionsFormComponent);
