import React from 'react';
import { NamedLink } from '../../components';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ReadyToGo.css';

const HIWReadyToGoSection = props => {
  const {
    isAuthenticated,
    listYourCarButtonId,
    onListYourCar,
    onLoginOrSignupClick,
  } = props;

  return (
    <section className={classNames(css.readyToGoSection, isAuthenticated ? css.readyToGoSectionLogin : css.readyToGoSectionSmall)}>
      <div className={css.textBox}>
        <h5 className={css.textBoxTitle}>
          <FormattedMessage id="HowItWorkPage.readyToGoTitle" />
        </h5>
        <div className={css.textBoxDesc}>
          <FormattedMessage id="HowItWorkPage.readyToGoDesc" />
        </div>
        {!isAuthenticated &&
          <NamedLink
            name="SignupPage"
            className={css.readyToGoJoinNow}
            onClick={() => onLoginOrSignupClick('signup')}
          >
            <FormattedMessage id="HowItWorkPage.readyToGoJoinNow" />
          </NamedLink>
        }
        {isAuthenticated &&
          <div className={css.buttons}>
            <NamedLink
              name="SearchPage"
              to={{
                search: "bounds=1.54840194%2C103.93560548%2C1.20243284%2C103.68841309"
              }}
              className={css.secondaryButton}>
              <FormattedMessage id="HowItWorkPage.findACar" />
            </NamedLink>
            <NamedLink
              name="NewListingPage"
              className={css.defaultButton}
              id={listYourCarButtonId}
              onClick={() => onListYourCar(listYourCarButtonId)}
              >
              <FormattedMessage id="HowItWorkPage.listYourCar" />
            </NamedLink>
          </div>
        }
      </div>
    </section >
  );
}

export default HIWReadyToGoSection;
