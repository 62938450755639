export const EVENT_CONVERSION_HOST_CONFIRMED_DROPOFF = {
  eventName: 'Car Host - Confirmed Drop-Off',
  eventID: 'SunJCP22noADENHNtPAC',
};
export const EVENT_CONVERSION_DRIVER_CONFIRMED_DROPOFF = {
  eventName: 'Car Drop-Off Confirmed Drop-Off',
  eventID: 'v6SJCLr0yYADENHNtPAC',
};
export const EVENT_CONVERSION_CREATE_ACCOUNT_DRIVER_CREDENTIALS = {
  eventName: 'Create an account with driver credentials',
  eventID: 'OAFnCN3AnoADENHNtPAC',
};
export const EVENT_CONVERSION_MADE_BOOKING_REQUEST = {
  eventName: 'Made a booking request',
  eventID: 'CgCJCPXLy4ADENHNtPAC',
};
export const EVENT_CONVERSION_STARTED_LISTING = {
  eventName: 'Made a booking request',
  eventID: 'mvlXCMvGnoADENHNtPAC',
};
export const EVENT_CONVERSION_COMPLETED_TRIP = {
  eventName: 'Completed a trip',
  eventID: 'JnuDCPPinoADENHNtPAC',
};
export const EVENT_CONVERSION_SUBMIT_EMAIL_ID = {
  eventName: 'Submit email id',
  eventID: 'DyWzCOzknoADENHNtPAC',
};
export const EVENT_CONVERSION_BOOKING_REQUEST_WITHDRAWN = {
  eventName: 'Booking request withdrawn',
  eventID: 'rcc1CPTpnoADENHNtPAC',
};
export const EVENT_CONVERSION_BOOKING_DECLINED = {
  eventName: 'Booking request declined',
  eventID: 'C4knCIH3noADENHNtPAC',
};
export const EVENT_CONVERSION_PERFORMED_SEARCH = {
  eventName: 'Performed a search - New',
  eventID: 'Wp6rCM-An4ADENHNtPAC',
};
export const EVENT_CONVERSION_CREATE_VERIFIED_ACCOUNT = {
  eventName: 'Create a verified account',
  eventID: 'iIiuCIHgxYADENHNtPAC',
};
export const EVENT_CONVERSION_BOOKING_REQUEST_ACCEPTED = {
  eventName: 'Booking request accepted',
  eventID: 'zmY9CPTNy4ADENHNtPAC',
};
export const EVENT_CONVERSION_BOOKING_CANCELLED = {
  eventName: 'Booking cancelled',
  eventID: 'cuGDCNTXy4ADENHNtPAC',
};
export const EVENT_CONVERSION_PUBLISHED_LISTING = {
  eventName: 'Published a listing',
  eventID: 'NgKNCK_Zy4ADENHNtPAC',
};