import React from 'react';
import css from './RentAPHV.css';
import classNames from 'classnames';
import imageSource from '../../util/imageSource';
import insuranceLogo from '../../assets/insurance-logo.svg';
import { TrustBox } from '../../components';

const DriveLahVsCompanies = ({ isMobileLayout }) => {

  return (
    <div className={css.driveLahVsCompanies}>

      <div className={css.container}>
        <div className={classNames(css.row, css.secTitle)}>
          {isMobileLayout && (
            <div className={css.insuranceListingWrapper}>
              <div
                className={css.trustPilotLogoMobile}
                style={{ flex: '0 0 45%', maxWidth: '45%' }}
              >
                <TrustBox />
              </div>
              {/* <div style={{ flex: '0 0 40%', maxWidth: '40%', padding: '0 0 0 15px' }}>
            <div style={{ marginBottom: 10 }}>
              <div className={css.insuranceListingText}>Insurance provided by</div>
              <img
                className={css.insuranceListingImage}
                src={insurance}
                alt="Tokio Marine"
              />
            </div>
            <div>
              <div className={css.insuranceListingText}>Approved by</div>
              <img
                className={css.approvedByCompanyLogo}
                src={landTransport}
                alt="Land Transport"
              />
            </div>
          </div> */}
              <div style={{ flex: '0 0 55%', maxWidth: '55%' }} className={classNames(css.comprehensiveInsuranceBlock, css.channelListingBlock)}>
                <div className={css.comprehensiveInsuranceBlockWrapper}>
                  <div className={css.cILogo}>
                    <img src={insuranceLogo} alt="Drive lah Protection" />
                  </div>
                  <div className={css.cIContent}>
                    <span className={css.channelListingSpan}>
                      Comprehensively insured with Drive lah protection<sup>TM</sup>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className={classNames(css.column6, css.contentHeading)}>
            <h2>
              Drive lah vs <br /> Other car rental Companies
            </h2>
            <p>Why choosing Drive lah is a no-brainer?</p>
          </div>
          <div className={classNames(css.column6, css.contentGrid)}>
            <table className={css.comparisonTable}>
              <thead>
                <tr>
                  <th></th>
                  <th className={css.drivelahPoints} width="255">
                    <span className={css.curveBorder}>Drive lah</span>
                  </th>
                  <th className={css.otherPoints} width="255">
                    <span className={css.curveBorder}>Other Rental Companies</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className={css.pointsTitle} align="right">
                    Price
                  </td>
                  <td className={css.drivelahPoints}>
                    <strong>$4/hr &amp; $29/day</strong> no mileage cap
                  </td>
                  <td className={css.otherPoints}>$8/hr &amp; $60/day limited mileage</td>
                </tr>
                <tr>
                  <td className={css.pointsTitle} align="right">
                    Simple Rent Structure
                  </td>
                  <td className={css.drivelahPoints}>
                    <div className={css.contentData}>
                      <span className={css.icon}>
                        <img src={imageSource.grabBanner.checkBlue} alt="Approved by LTA" />
                      </span>
                      Same all day
                    </div>
                  </td>
                  <td className={css.otherPoints}>
                    <div className={css.contentData}>
                      <span className={css.icon}>
                        <img src={imageSource.grabBanner.crossRed} alt="Other Rental Companies Limitation" />
                      </span>
                      Duration, km &amp; time of the day
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className={css.pointsTitle} align="right">
                    Long Lease Commitment
                  </td>
                  <td className={css.drivelahPoints}>
                    <div className={css.contentData}>
                      <span className={css.icon}>
                        <img src={imageSource.grabBanner.crossBlue} alt="Cross Tick Icon" />
                      </span>
                      None
                    </div>
                  </td>
                  <td className={css.otherPoints}>
                    <div className={css.contentData}>
                      <span className={css.icon}>
                        <img src={imageSource.grabBanner.checkRed} alt="Cross Tick Icon" />
                      </span>
                      Fixed Contract
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className={css.pointsTitle} align="right">
                    Wide Car Variety
                  </td>
                  <td className={css.drivelahPoints}>
                    <div className={css.contentData}>
                      <span className={css.icon}>
                        <img src={imageSource.grabBanner.checkBlue} alt="Approved by LTA" />
                      </span>
                      50+ models
                    </div>
                  </td>
                  <td className={css.otherPoints}>
                    <div className={css.contentData}>
                      <span className={css.icon}>
                        <img src={imageSource.grabBanner.crossRed} alt="Other Rental Companies Limitation" />
                      </span>
                      4-5 models
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className={css.pointsTitle} align="right">
                    Car pickup points
                  </td>
                  <td className={css.drivelahPoints}>
                    <div className={css.contentData}>
                      <span className={css.icon}>
                        <img src={imageSource.grabBanner.checkBlue} alt="Approved by LTA" />
                      </span>
                      Always nearby
                    </div>
                  </td>
                  <td className={css.otherPoints}>
                    <div className={css.contentData}>
                      <span className={css.icon}>
                        <img src={imageSource.grabBanner.crossRed} alt="Other Rental Companies Limitation" />
                      </span>
                      Limited
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className={css.pointsTitle} align="right">
                    Hidden Charges
                  </td>
                  <td className={css.drivelahPoints}>
                    <div className={css.contentData}>
                      <span className={css.icon}>
                        <img src={imageSource.grabBanner.crossBlue} alt="Cross Tick Icon" />
                      </span>
                      None
                    </div>
                  </td>
                  <td className={css.otherPoints}>
                    <div className={css.contentData}>
                      <span className={css.icon}>
                        <img src={imageSource.grabBanner.checkRed} alt="Cross Tick Icon" />
                      </span>
                      Yes
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className={css.pointsTitle} align="right">
                    Late Return Charges
                  </td>
                  <td className={css.drivelahPoints}>
                    <div className={css.contentData}>
                      <span className={css.icon}>
                        <img src={imageSource.grabBanner.crossBlue} alt="Cross Tick Icon" />
                      </span>
                      Grace Period
                    </div>
                  </td>
                  <td className={css.otherPoints}>
                    <div className={css.contentData}>
                      <span className={css.icon}>
                        <img src={imageSource.grabBanner.checkRed} alt="Cross Tick Icon" />
                      </span>
                      Strict Terms
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td className={css.pointsTitle} align="right"></td>
                  <td className={css.drivelahPoints}>
                    <span className={css.curveBorder}></span>
                  </td>
                  <td className={css.otherPoints}>
                    <span className={css.curveBorder}></span>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DriveLahVsCompanies;
