import { FormattedMessage } from 'react-intl';
import css from './VerificationIndicator.css';
import React from 'react';

const VerificationIndicator = props => {
  const {
    showSteps,
    step,
    isHost,
    totalSteps,
  } = props;


  return (
    <>
      <div
        className={css.cardTitle}
        style={{
          borderBottom: !showSteps && '1px solid #E6E6E6',
          margin: !showSteps && '0 0 24px',
          padding: !showSteps && '0 0 20px',
        }}
      >
        <span className={css.title}>
          {isHost ? (
            'Host verification status: '
          ) : step && step.stepTitle === '' ? (
            <FormattedMessage id="AccountSettingDriverVerificationPage.status" />
          ) : (
            <FormattedMessage
              id="AccountSettingDriverVerificationPage.statusTitle"
              values={{ title: step && step.stepTitle ? step && step.stepTitle : 'Driver verification status' }}
            />
          )}{' '}
        </span>
        {/* <span className={css.submissionStatus}>
          {!(isVerified || isPending || isDeclined) && showSteps
            ? `Step ${step.stepNum} of ${totalSteps}`
            : status}
        </span> */}
      </div>
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
          {Array.from(Array(totalSteps).keys()).map(i => (
            <div
              key={i}
              style={{
                border: '4px solid',
                width:  `${100/totalSteps}%`,
                marginRight: '1px',
                borderColor:
                  i + 1 < step.stepNum ? '#fecd2a' : i + 1 === step.stepNum ? '#00a3ad' : '#E6E6E6',
              }}
            >
              {' '}
            </div>
          ))}
        </div>
    </>
  );
};

export default VerificationIndicator;
