import React from 'react';

import classNames from 'classnames';
import { IconSpinner } from '../index';
import { FormattedMessage } from '../../util/reactIntl';
import { timeToNearestStop } from '../../marketplace-custom-config';

import css from './LocationInfo.module.css';

const LocationInfo = ({ distanceToUser, address, publicData }) => {
  const timeToStop =
    publicData.timeFromNearestMRT &&
    timeToNearestStop.find(item => item.key === publicData.timeFromNearestMRT);
  const timeToStopLabel = timeToStop ? `- ${timeToStop.label}` : '';

  return (
    <div className={css.mb}>
      <div className={css.locationItem}>
        <p className={css.locationSubtitle}>
          <FormattedMessage id="ListingPage.listingLocationText" />
        </p>
        {distanceToUser.error ? (
          <span className={css.location}>
            <FormattedMessage id={distanceToUser.error} />
          </span>
        ) : (
          <p className={css.location}>
            {address} -{' '}
            {!distanceToUser.distance ? (
              <IconSpinner className={css.spinner} />
            ) : (
              distanceToUser.distance + 'km'
            )}{' '}
            <FormattedMessage id="ListingPage.fromYourLocationText" />
          </p>
        )}
      </div>
      {publicData.nearestStop ? (
        <div className={css.locationItem}>
          <p className={css.locationSubtitle}>
            <FormattedMessage id="ListingPage.nearestTransportText" />
          </p>
          <p className={css.location}>
            {publicData.nearestStop} {timeToStopLabel}
          </p>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default LocationInfo;
