import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import Explanation from './Explaination';

import { FUEL_INCLUSION_ADDON__UNITS } from '../../util/constants/addons';

import css from './BookingBreakdownNew.css';
import { convertFuelPrice } from '../../util/fuelPrice.helper';

const LineItemFuelInclusionAddOnMaybe = props => {
  const {
    isShow,
    fuelPrice,
  } = props;

  if (!isShow) {
    return null;
  }

  return (
    <div className={css.addOnLineItem}>
      <div className={css.totalLabel}>
        <FormattedMessage id="BookingBreakdown.fuelInclusion" />
        <Explanation text="You will be charged once the trip ends." />
      </div>
      <div className={css.itemValue}>{`$${convertFuelPrice(fuelPrice && fuelPrice.price ? fuelPrice.price : 0)} / ${FUEL_INCLUSION_ADDON__UNITS}`}</div>
    </div>
  );
};

const { bool, object } = PropTypes;

LineItemFuelInclusionAddOnMaybe.propTypes = {
  isShow: bool.isRequired,
  fuelPrice: object.isRequired,
};

export default LineItemFuelInclusionAddOnMaybe;
