import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withViewport } from '../../util/contextHelpers';
import { injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  isScrollingDisabled,
  manageDisableScrolling,
  manageTurnoffPromoteBar,
} from '../../ducks/UI.duck';
import { applyCampaign } from '../../ducks/Campaign.duck';
import config from '../../config';
import { loginOrSignupClick } from '../TopbarContainer/Topbar.duck';
import {
  Footer,
  InsuranceDisclaimer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
  SectionAskAndAnswer,
  SectionFindACard,
  SectionHero,
  SectionLocations,
  SectionPartners,
  SectionReadyToGo,
  SectionUsers,
  SectionVideo,
} from '../../components';
import { TopbarContainer } from '../../containers';

import facebookImage from '../../assets/facebook_drivelah_2.jpg';
import twitterImage from '../../assets/facebook_drivelah_2.jpg';
import css from './LandingPageG.css';

export const LandingPageComponent = props => {
  const {
    history,
    intl,
    viewport,
    location,
    scrollingDisabled,
    turnoffPromoteBar,
    currentUser,
    isAuthenticated,
    onManageDisableScrolling,
    onApplyCampaign,
    applyError,
    applyInProgress,
    applySuccess,
    onLoginOrSignupClick,
  } = props;

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={[
        {
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          description: schemaDescription,
          name: schemaTitle,
          image: [schemaImage],
        },
        {
          '@context': 'https://schema.org',
          '@type': 'FAQPage',
          'mainEntity': [
            {
              '@type': 'Question',
              'name': 'What is Drive lah?',
              'acceptedAnswer': {
                '@type': 'Answer',
                'text': '<p>It is a peer to peer car sharing platform. This means that people who own a car can rent out their car to fellow Singaporeans when they don\'t need their car. The trip will be comprehensively insured for the host\'s and guest\'s peace of mind.</p>',
              },
            },
            {
              '@type': 'Question',
              'name': 'Why should I rent with Drive lah?',
              'acceptedAnswer': {
                '@type': 'Answer',
                'text': '<p>Drive lah is the most convenient access to cars in Singapore. We offer the largest variety of cars nearby at a click of a button. Drive lah utilizes unused car hours of individual car owners and that makes rentals on Drive lah typically 30-40% cheaper than traditional rental companies.</p>',
              },
            },
            {
              '@type': 'Question',
              'name': 'As a host, how is the safety of my car ensured?',
              'acceptedAnswer': {
                '@type': 'Answer',
                'text': '<p><span>We carry stringent checks on guests who rent on Drive lah through their NRIC/FIN and driving license. Though highly unlikely, in the event of any unfortunate incident, your car is comprehensively insured through Drive lah protection<sup>TM</sup> including the following:\n' +
                  '• Death or injury to third party(unlimited)\n' +
                  '• Damage to third party property(up to S$5,000,000)\n' +
                  '• Loss or damage to your vehicle from accident causes (up to market value at the time of loss or damage)\n' +
                  '• Loss of damage by fire or theft (up to market value at the time of loss or damage)</span></p>',
              },
            },
            {
              '@type': 'Question',
              'name': 'What are the regulations with regards to peer to peer car sharing in Singapore?',
              'acceptedAnswer': {
                '@type': 'Answer',
                'text': '<p>The Land Transport Authority(LTA) has given Drive lah special approval which allows rental of private cars on both weekdays & weekends Details here</p>',
              },
            },
          ],
        },
      ]}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer/>
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.heroContainer}>
            <SectionHero
              currentUser={currentUser}
              className={css.hero}
              history={history}
              isGuest={true}
              onApplyCampaign={onApplyCampaign}
              applyInProgress={applyInProgress}
              applySuccess={applySuccess}
              applyError={applyError}
              location={location}
              viewport={viewport}
              isAuthenticated={isAuthenticated}
            />
          </div>
          <ul className={css.sections}>
            <li>
              <div>
                <SectionVideo onManageDisableScrolling={onManageDisableScrolling}/>
              </div>
            </li>
            <li>
              <div>
                <SectionUsers intl={intl} />
              </div>
            </li>
            <li className={css.section}>
              <div className={css.sectionContent}>
                <SectionLocations viewport={viewport}/>
              </div>
            </li>
            <li id="sectionPartners">
              <div className={css.sectionPartnersContainer}>
                <SectionPartners intl={intl} />
              </div>
            </li>
            <li>
              <div className={classNames(css.section, css.findACardSection)}>
                <SectionFindACard
                  className={css.form}
                  onSubmit={() => null}
                  inProgress={false}
                  titleId="SectionFindACard.title"
                  subtitleId="SectionFindACard.subtitle"
                />
              </div>
            </li>
            <li>
              <div className={css.section}>
                <SectionReadyToGo
                  isAuthenticated={isAuthenticated}
                  onLoginOrSignupClick={onLoginOrSignupClick}
                />
              </div>
            </li>
            <li>
              <div className={css.section}>
                <SectionAskAndAnswer />
              </div>
            </li>
            <InsuranceDisclaimer/>
          </ul>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool, object } = PropTypes;

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const { isAuthenticated } = state.Auth;
  const { applyInProgress, applyError, applySuccess } = state.Campaign;

  return {
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
    turnoffPromoteBar: state.UI.turnoffPromoteBar,
    isAuthenticated,
    applyInProgress,
    applyError,
    applySuccess,
  };
};

const mapDispatchToProps = dispatch => ({
  onTurnoffPromoteBar: (flagVal) => dispatch(manageTurnoffPromoteBar(flagVal)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onApplyCampaign: (email, userType) => dispatch(applyCampaign({ email, userType })),
  onLoginOrSignupClick: (page) => dispatch(loginOrSignupClick({page}))
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withViewport,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(LandingPageComponent);

export default LandingPage;
