import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Lottie from 'react-lottie';
import DeclinedAnimation from '../../assets/verificationPage/Verifiaction_Denied_Lottie.json';
import PendingAnimation from '../../assets/verificationPage/Verification_pending.json';
import CompletedAnimation from '../../assets/verificationPage/Verified.json';
import Button from '../Button/Button';
import css from './LottieElementPage.css';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const LottieElementPage = ({
  isPending = false,
  isVerified = false,
  isDeclined = false,
  redirectToHomePage,
  onGetActiveTrips,
  declinedDueToDuplicate,
}) => {
  const [trip, setTrip] = useState(null);
  const [wait, setWait] = useState(true);
  const history = useHistory();

  useEffect(() => {
    checkTrips();
  }, [])

  const checkTrips = async () => {
    setWait(true);
    const result = await onGetActiveTrips({ page: 1 });

    if (result.trips.length > 0) {
      setTrip(result.trips[0]);
      setWait(false);
    } else {
      setWait(false);
    }
  }


  const actionBtn = () => {
    let showSearch = false;
    if (!trip) {
      showSearch = true;
    } else {
      if (trip.attributes && trip.attributes.lastTransition) {
        let tx = trip.attributes.lastTransition;
        if (tx === 'transition/unverified-verification-sent-confirmed' ||
          tx === 'transition/unverified-verification-sent-instant-confirmed' ||
          tx === 'transition/unverified-verification-sent-instant-declined' ||
          tx === 'transition/unverified-verification-sent-declined'
        ) {
          showSearch = false;
        } else {
          showSearch = true;
        }
      }
    }
    if (showSearch)
      return (
        <div className={css.lottieElementButtonContainer} onClick={() => {
          history.push('/')
        }}>
          <Button>Start Searching</Button>
        </div>
      )
    else {
      return (
        <div className={css.lottieElementButtonContainer} onClick={() => {
          history.push('/inbox/normal-trips/orders');
        }}>
          <Button>Go to Trips</Button>
        </div>

      )
    }
  }

  const guideMessage = (
    <FormattedMessage
      id={
        isPending
          ? 'identityVerification.pending'
          : isVerified
            ? 'identityVerification.confirmed'
            : declinedDueToDuplicate ? 'identityVerification.declinedDueToDuplicate' : 'identityVerification.declined'
      }
    ></FormattedMessage>
  );
  let animationComp = isPending
    ? PendingAnimation
    : isVerified
      ? CompletedAnimation
      : DeclinedAnimation;


  const handleRedirectToHomePage = () => {
    if (redirectToHomePage)
      redirectToHomePage();
  };

  return (
    <>
      <div className={css.lottieElementContainer}>
        <p className={css.lottieElementContainerText}>
          {guideMessage}
          <br></br>
        </p>
      </div>
      <div>
        <Lottie
          style={{
            margin: 0,
          }}
          options={{
            loop: true,
            autoplay: true,
            animationData: animationComp,
            renderer: 'svg',
          }}
          height={300}
          width={300}
        />
      </div>
      {isVerified && !wait && (
        <>
          {actionBtn()}
          {/* {trip ? actionBtn() : <div className={css.lottieElementButtonContainer} onClick={() => {
            history.push('/')
          }}>
            <Button>Start Searching</Button>
          </div>} */}
        </>
      )}
    </>
  );
};

export default LottieElementPage