import classNames from 'classnames';
import { get } from 'lodash';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';
import { array, bool, func, shape, string } from 'prop-types';
import React, { Component } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { compose } from 'redux';
import { IconSpinner } from '../../components';
import config from '../../config';
import { AccountSettingDriverVerificationPage } from '../../containers';
import routeConfiguration from '../../routeConfiguration';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { pathByRouteName } from '../../util/routes';
import setFieldTouched from '../../util/setFiledTouched';
import { propTypes } from '../../util/types';
import css from './EditListingVerificationForm.css';

const ACCEPT_IMAGES = 'image/*';

export class EditListingVerificationFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNotUpload: true,
      isUploading: false,
      sentVerification: false,
      disableUploadInternationalLicense: false,
      nricFnFrontUrl: props.nricFnFrontUrl,
      nricFnBackUrl: props.nricFnFrontUrl,
      drivingLicenseFrontUrl: props.drivingLicenseFrontUrl,
      drivingLicenseBackUrl: props.drivingLicenseBackUrl,
      drivingLicenseStatusUrl: props.drivingLicenseStatusUrl,
      internationalDrivingLicenseFrontUrl: props.internationalDrivingLicenseFrontUrl,
      internationalDrivingLicenseBackUrl: props.internationalDrivingLicenseBackUrl,
      photo: props.photo,
      isEditLocation: false,
      hostHoldingDrivingLicenseUrl: props.hostHoldingDrivingLicenseUrl,
      passportFirstPageUrl: props.passportFirstPageUrl,
      passportLastPageUrl: props.passportLastPageUrl,
    };

    this.setUploadState = this.setUploadState.bind(this);
  }

  componentWillUnmount() {
    if (this.timeOutUploading) {
      clearTimeout(this.timeOutUploading);
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      drivingLicenseFrontUrl,
      hostHoldingDrivingLicenseUrl,
      passportFirstPageUrl,
      passportLastPageUrl,
      drivingLicenseBackUrl,
    } = nextProps;
    this.setState(prev =>
      Object.assign(
        {},
        this.state,
        pickBy(
          {
            drivingLicenseFrontUrl,
            drivingLicenseBackUrl,
            hostHoldingDrivingLicenseUrl,
            passportLastPageUrl,
          },
          identity
        )
      )
    );
  }

  setUploadState(data) {
    this.setState({
      isUploading: data.isUploading,
      isNotUpload: data.isNotUpload,
    });
  }

  setStateUploadData = (url, fileType) => {
    const { setData } = this.props;
    if (fileType === 'front-driving-license') {
      setData('drivingLicenseFront', url);
      this.setState(prev => ({
        ...prev,
        drivingLicenseFrontUrl: url,
      }));
    }
    if (fileType === 'back-driving-license') {
      setData('drivingLicenseBack', url);
      this.setState(prev => ({
        ...prev,
        drivingLicenseBackUrl: url,
      }));
    }
    if (fileType === 'host-holding-driving-license') {
      setData('hostHoldingDrivingLicensePhoto', url);
      this.setState(prev => ({
        ...prev,
        hostHoldingDrivingLicenseUrl: url,
      }));
    }
    if (fileType === 'file-upload-passport-first') {
      setData('passportFirstPage', url);
      this.setState(prev => ({
        ...prev,
        passportFirstPageUrl: url,
      }));
    }
    if (fileType === 'file-upload-passport-last') {
      setData('passportLastPage', url);
      this.setState(prev => ({
        ...prev,
        passportLastPageUrl: url,
      }));
    }
    if (fileType === 'photo') {
      setData('photo', url);
      this.setState({
        photo: url,
      });
    }
    // const nricFnStartWithS =
    //   this.state.initialValues &&
    //   this.state.initialValues.nricFn &&
    //   (this.state.initialValues.nricFn.startsWith('s') ||
    //     this.state.initialValues.nricFn.startsWith('S'));
    // if (fileType === 'nric-fin-front') {
    //   this.props.setNricFnFrontUrl(url);
    //   this.setState({
    //     nricFnFrontUrl: url,
    //   });
    // } else if (fileType === 'nric-fin-back') {
    //   this.props.setNricFnBackUrl(url);
    //   this.setState({
    //     nricFnBackUrl: url,
    //   });
    // } else if (fileType === 'front-driving-license') {
    //   this.props.setDrivingLicenseFrontUrl(url);
    //   this.setState({
    //     drivingLicenseFrontUrl: url,
    //     disableUploadInternationalLicense: nricFnStartWithS,
    //   });
    // } else if (fileType === 'back-driving-license') {
    //   this.props.setDrivingLicenseBackUrl(url);
    //   this.setState({
    //     drivingLicenseBackUrl: url,
    //     disableUploadInternationalLicense: nricFnStartWithS,
    //   });
    // } else if (fileType === 'status-driving-license') {
    //   this.props.setDrivingLicenseStatusUrl(url);
    //   this.setState({
    //     drivingLicenseStatusUrl: url,
    //   });
    // } else if (fileType === 'international-front-driving-license') {
    //   this.props.setInternationalDrivingLicenseFrontUrl(url);
    //   this.setState({
    //     internationalDrivingLicenseFrontUrl: url,
    //     disableUploadDrivingLicense: nricFnStartWithS,
    //   });
    // } else if (fileType === 'international-back-driving-license') {
    //   this.props.setInternationalDrivingLicenseBackUrl(url);
    //   this.setState({
    //     internationalDrivingLicenseBackUrl: url,
    //     disableUploadDrivingLicense: nricFnStartWithS,
    //   });
    // } else if (fileType === 'photo') {
    //   this.props.setPhoto(url);
    //   this.setState({
    //     photo: url,
    //   });
    // }
  };

  handleOpenEditLocation = status => {
    this.setState({
      isEditLocation: status,
    });
  };

  render() {
    return (
      <FinalForm
        {...this.props}
        mutators={{ setFieldTouched }}
        onImageUploadHandler={this.onImageUploadHandler}
        imageUploadRequested={this.state.imageUploadRequested}
        initialValues={
          this.state.initialValues ? this.state.initialValues : this.props.initialValues
        }
        render={fieldRenderProps => {
          const {
            form,
            className,
            disabled,
            fetchErrors,
            handleSubmit,
            intl,
            invalid,
            currentUser,
            ready,
            pristine,
            saveActionMsg,
            updated,
            updateInProgress,
            values,
            pushEventGTM,
            submitButtonId,
            updateIdentityError,
            underVerified,
          } = fieldRenderProps;

          if (values.floorUnit) {
            values.floorUnit.replace(/[^\d|-]/g, '');
            if (values.floorUnit.length > 2 && values.floorUnit[2] !== '-') {
              let tempVal = values.floorUnit[0] + values.floorUnit[1] + '-';
              tempVal = tempVal + values.floorUnit.slice(2, values.floorUnit.length);
              values.floorUnit = tempVal;
            }
          }

          const isGuestVerified =
            !!currentUser &&
            !!currentUser.id &&
            currentUser.identityStatus &&
            currentUser.identityStatus.hostVerified;
          const underGuestVerified =
            !!currentUser &&
            !!currentUser.id &&
            currentUser.identityStatus &&
            currentUser.identityStatus.hostUnderVerify;
          const underHostVerification =
            !!currentUser &&
            !!currentUser.id &&
            get(currentUser, 'attributes.profile.publicData.hostIdentityVerificationStatus', '');
          const stripeConnected =
            !!currentUser && !!currentUser.id && currentUser.attributes.stripeConnected;

          const routes = routeConfiguration();
          const yourListingUrl =
            config.canonicalRootURL + pathByRouteName('ManageListingsPage', routes);

          const nricFnMessage = intl.formatMessage({ id: 'EditListingVerificationForm.nricFn' });
          const nricFnPlaceholderMessage = intl.formatMessage({
            id: 'EditListingVerificationForm.nricFnPlaceholder',
          });
          const nricFnRequiredMessage = intl.formatMessage({
            id: 'EditListingVerificationForm.nricFnRequired',
          });
          const nricFnNotValidMessage = intl.formatMessage({
            id: 'EditListingVerificationForm.nricFnNotValid',
          });

          const accountNameMessage = intl.formatMessage({
            id: 'EditListingVerificationForm.accountName',
          });
          const accountNamePlaceholderMessage = intl.formatMessage({
            id: 'EditListingVerificationForm.accountNamePlaceholder',
          });
          const accountNameRequiredMessage = intl.formatMessage({
            id: 'EditListingVerificationForm.accountNameRequired',
          });

          const bankNameMessage = intl.formatMessage({
            id: 'EditListingVerificationForm.bankName',
          });
          const bankNamePlaceholderMessage = intl.formatMessage({
            id: 'EditListingVerificationForm.bankNamePlaceholder',
          });
          const bankNameRequiredMessage = intl.formatMessage({
            id: 'EditListingVerificationForm.bankNameRequired',
          });

          const bankAccountNumberMessage = intl.formatMessage({
            id: 'EditListingVerificationForm.bankAccountNumber',
          });
          const bankAccountNumberPlaceholderMessage = intl.formatMessage({
            id: 'EditListingVerificationForm.bankAccountNumberPlaceholder',
          });
          const bankAccountNumberRequiredMessage = intl.formatMessage({
            id: 'EditListingVerificationForm.bankAccountNumberRequired',
          });

          const addressRequiredMessage = intl.formatMessage({
            id: 'EditListingLocationForm.addressRequired',
          });
          const addressNotRecognizedMessage = intl.formatMessage({
            id: 'EditListingLocationForm.addressNotRecognized',
          });

          const buildingMessage = intl.formatMessage({ id: 'EditListingLocationForm.building' });
          const buildingPlaceholderMessage = intl.formatMessage({
            id: 'EditListingLocationForm.buildingPlaceholder',
          });

          const cityMessage = intl.formatMessage({ id: 'EditListingLocationForm.city' });
          const cityPlaceholderMessage = intl.formatMessage({
            id: 'EditListingLocationForm.cityPlaceholder',
          });

          const stateMessage = intl.formatMessage({ id: 'EditListingLocationForm.state' });
          const statePlaceholderMessage = intl.formatMessage({
            id: 'EditListingLocationForm.statePlaceholder',
          });

          const suburbLabel = intl.formatMessage({ id: 'EditListingLocationForm.suburb' });
          const suburbPlaceholder = intl.formatMessage({
            id: 'EditListingLocationForm.suburbPlaceholder',
          });
          const suburbRequired = intl.formatMessage({
            id: 'EditListingLocationForm.suburbRequired',
          });

          const streetMessage = intl.formatMessage({ id: 'EditListingLocationForm.streetName' });
          const streetPlaceholderMessage = intl.formatMessage({
            id: 'EditListingLocationForm.streetNamePlaceholder',
          });

          const blockMessage = intl.formatMessage({ id: 'EditListingLocationForm.blockNo' });
          const blockPlaceholderMessage = intl.formatMessage({
            id: 'EditListingLocationForm.blockNoPlaceholder',
          });

          const floorMessage = intl.formatMessage({ id: 'EditListingLocationForm.unitNumber' });
          const floorPlaceholderMessage = intl.formatMessage({
            id: 'EditListingLocationForm.floorPlaceholder',
          });

          const cityRequiredMessage = intl.formatMessage({
            id: 'EditListingLocationForm.cityRequired',
          });

          const stateRequiredMessage = intl.formatMessage({
            id: 'EditListingLocationForm.stateRequired',
          });

          const postalCodeLabel = intl.formatMessage({ id: 'PayoutDetailsForm.postalCodeLabel' });
          const postalCodePlaceholder = intl.formatMessage({
            id: 'PayoutDetailsForm.postalCodePlaceholder',
          });
          const postalCodeRequired = intl.formatMessage({
            id: 'PayoutDetailsForm.postalCodeRequired',
          });

          const { publishListingError, showListingsError, updateListingError } = fetchErrors || {};

          // NOTE: These error messages are here since Photos panel is the last visible panel
          // before creating a new listing. If that order is changed, these should be changed too.
          // Create and show listing errors are shown above submit button
          const publishListingFailed = publishListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingVerificationForm.publishListingFailed" />
            </p>
          ) : null;
          const showListingFailed = showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingVerificationForm.showListingFailed" />
            </p>
          ) : null;

          const submitReady = updated && pristine;
          const submitInProgress = updateInProgress || this.state.isUploading;
          const hasProfilePhoto = currentUser && currentUser.profileImage;
          const initialPhoto = this.props.photo
            ? this.props.photo
            : hasProfilePhoto
            ? currentUser.profileImage.attributes.variants['square-small2x'].url
            : null;
          const disablesPhoto = !!this.props.photo;
          const needUploadPhoto = this.state.photo ? false : !hasProfilePhoto;
          const uploadedDrivingLicense =
            !!this.state.drivingLicenseFrontUrl || !!this.props.drivingLicenseBackUrl;
          const uploadedPassportFirstPage =
            !!this.state.passportFirstPageUrl || !!this.props.passportFirstPageUrl;
          const uploadedPassportLastPage =
            !!this.state.passportLastPageUrl || !!this.props.passportLastPageUrl;
          let needUploadFile;
          if (hasProfilePhoto) {
            needUploadFile = !uploadedDrivingLicense;
          } else {
            needUploadFile = !uploadedDrivingLicense || needUploadPhoto;
          }
          const submitDisabled =
            disabled || submitInProgress || underHostVerification === 'pending' || invalid;
          const classes = classNames(css.root, className);

          const pendingVerificationLayout = (
            <div className={css.thankYouTextFirstTime}>
              <FormattedMessage
                id="EditListingVerificationForm.thankYouTextFirstTimeVerified"
                values={{ newline: <br /> }}
              />
              {!stripeConnected ? (
                <div className={css.checkingStripeStatus}>
                  <IconSpinner />
                  <span className={css.checkingStripeStatusText}>
                    {intl.formatMessage({
                      id: 'EditListingVerificationForm.waitingForStripeChecking',
                    })}
                  </span>
                </div>
              ) : null}
            </div>
          );

          // const verifyLayout = isVerified || underVerify ? alreadyVerifiedLayout : underVerifyLayout;
          const finalLayout = this.state.sentVerification ? (
            pendingVerificationLayout
          ) : (
            <AccountSettingDriverVerificationPage
              pageName="verificationPage"
              isHost={true}
              manageDisableSidebar={true}
            />
          );
          // const finalLayout = pendingVerificationLayout;

          return <div className={css.chatContainerProvider}>{finalLayout}</div>;
        }}
      />
    );
  }
}

EditListingVerificationFormComponent.defaultProps = { fetchErrors: null, images: [] };

EditListingVerificationFormComponent.propTypes = {
  fetchErrors: shape({
    publishListingError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  images: array,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  updated: bool.isRequired,
  ready: bool.isRequired,
  stripeConnected: bool.isRequired,
  pushEventGTM: func,
  submitButtonId: string,
};

export default compose(injectIntl)(EditListingVerificationFormComponent);
