import React, { Component } from 'react';
import css from './HostLandingPage.css';
import { Column, Container, Row } from '../../components/Grids/Grid';
import { fetchBrandlist, fetchModellist, fetchPricelist } from '../../util/carPriceHelper';
import { FieldTextAutoFillInputThemed } from '../../components';
import { Form as FinalForm } from 'react-final-form';
import * as validators from '../../util/validators';
import classNames from 'classnames';

class EarningCalculator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      brandList: [],
      brandListOpen: false,
      modelList: [],
      modelListOpen: false,
      priceList: [],
      mounted: false,
    };
  }

  componentDidMount = () => {
    fetchBrandlist()
      .then(response => {
        return response.json();
      })
      .then(jsonData => {
        this.getModellist('honda');
        this.setState({
          brandList: jsonData.map(x => ({ label: x.original_brand, key: x.normalized_brand })),
        });
      });
    this.getPricelist('honda', 'jazz');
  };

  getModellist = brandName => {
    fetchModellist(brandName)
      .then(response => {
        return response.json();
      })
      .then(jsonData => {
        this.setState({
          modelList: jsonData.map(x => ({ label: x.original_model, key: x.normalized_model })),
        });
      });
  };

  getPricelist = (brandName, modelName) => {
    let normalized_brand = brandName
      .split(' ')
      .join('_')
      .toLowerCase();
    fetchPricelist(normalized_brand, modelName)
      .then(response => {
        return response.json();
      })
      .then(jsonData => {
        this.setState({
          priceList: jsonData,
        });
      });
  };

  handleBrandList = state => {
    this.setState({
      brandListOpen: state,
    });
  };

  handleModelList = state => {
    this.setState({
      modelListOpen: state,
    });
  };

  handleHoverBug = e => {
    if (e.type === 'touchstart') {
      // Don't trigger mouseenter even if they hold
      if (e.stopImmediatePropagation) {
        e.stopImmediatePropagation();
      }
      // If $item is a link (<a>), don't go to said link on mobile, show menu instead
      e.preventDefault();
    }
  };

  render() {
    return (
      <div className={css.calcWrapper}>
        <Container className={css.calcContainer}>
          <Row className={css.sectionTitle}>
            <Column large={12}>
              <FinalForm
                {...this.props}
                onSubmit={e => {}}
                render={fieldRenderProps => {
                  const { intl, form, values } = fieldRenderProps;
                  if (!this.state.mounted) {
                    form.change('brand', 'Honda');
                    form.change('model', 'Jazz');
                    this.setState({
                      mounted: true,
                    });
                  }

                  if (
                    form.getFieldState('brand') &&
                    form.getFieldState('brand').active &&
                    !this.state.brandListOpen
                  ) {
                    this.handleBrandList(true);
                  } else if (
                    form.getFieldState('brand') &&
                    !form.getFieldState('brand').active &&
                    this.state.brandListOpen
                  ) {
                    this.handleBrandList(false);
                  }

                  if (
                    form.getFieldState('model') &&
                    form.getFieldState('model').active &&
                    !this.state.modelListOpen
                  ) {
                    this.handleModelList(true);
                  } else if (
                    form.getFieldState('model') &&
                    !form.getFieldState('model').active &&
                    this.state.modelListOpen
                  ) {
                    this.handleModelList(false);
                  }

                  const brandPlaceholder = intl.formatMessage({
                    id: 'BecomeAHostPage.brandPlaceholder',
                  });
                  const brandRequiredMessage = intl.formatMessage({
                    id: 'BecomeAHostPage.brandRequired',
                  });
                  const brandRequired = validators.required(brandRequiredMessage);

                  const modelPlaceholder = intl.formatMessage({
                    id: 'BecomeAHostPage.modelPlaceholder',
                  });
                  const modelRequiredMessage = intl.formatMessage({
                    id: 'BecomeAHostPage.modelRequired',
                  });
                  const modelRequired = validators.required(modelRequiredMessage);

                  const ageOfCarPlaceholder = intl.formatMessage({
                    id: 'BecomeAHostPage.ageOfCarPlaceholder',
                  });
                  const ageOfCarRequiredMessage = intl.formatMessage({
                    id: 'BecomeAHostPage.ageOfCarRequired',
                  });
                  const ageOfCarRequired = validators.required(ageOfCarRequiredMessage);

                  const milesPlaceholder = intl.formatMessage({
                    id: 'BecomeAHostPage.milesPlaceholder',
                  });
                  return (
                    <>
                      <h2>
                        Share your{' '}
                        <strong>
                          <div
                            className={classNames(
                              css.inlineBox,
                              this.state.brandListOpen ? css.selected : ''
                            )}
                          >
                            <FieldTextAutoFillInputThemed
                              inputClassName={css.input}
                              className={css.inputField}
                              type="text"
                              id="brandName"
                              name="brand"
                              label="Brand"
                              // input={{ value: 'Honda' }}
                              placeholder={brandPlaceholder}
                              optionsList={this.state.brandList}
                              isOpenSuggest={this.state.brandListOpen}
                              dropDownTitle="SELECT CAR"
                              chooseOption={option => {
                                form.change('brand', option.label);
                                form.change('model', null);
                                this.getModellist(option.key);
                                this.handleBrandList(false);
                              }}
                            />
                          </div>{' '}
                          <div
                            className={classNames(
                              css.inlineBox,
                              this.state.modelListOpen ? css.selected : ''
                            )}
                          >
                            <FieldTextAutoFillInputThemed
                              inputClassName={css.input}
                              className={classNames(css.inputField)}
                              type="text"
                              id="modelName"
                              name="model"
                              label="Model"
                              dropDownTitle="SELECT CAR"
                              // input={{value: "Jazz"}}
                              placeholder={modelPlaceholder}
                              optionsList={this.state.modelList}
                              isOpenSuggest={this.state.modelListOpen}
                              chooseOption={option => {
                                form.change('model', option.label);
                                this.getPricelist(values.brand, option.key);
                                this.handleModelList(false);
                              }}
                            />
                          </div>
                        </strong>{' '}
                        <br />
                        and earn up to{' '}
                        <div className={css.breakDown}>
                          $
                          {values.brand && values.model && this.state.priceList
                            ? `${this.state.priceList.price * 8}`
                            : '0'}
                        </div>{' '}
                        per month
                      </h2>

                      <div className={css.explaination}>
                        How we estimate your earnings potential
                        <span className={css.explainationText}>
                          This estimate is based on the price recommended for your car by Drive
                          lah, for 8 days of rental per month.
                        </span>
                      </div>
                    </>
                  );
                }}
              />
            </Column>
          </Row>
        </Container>
      </div>
    );
  }
}

export default EarningCalculator;
