import React, { Component } from 'react';
import { ExternalLink, ModalInsurancePolicy } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';

import css from './BAHIntroSection.css';
import imageSource from '../../util/imageSource';

class BAHIntroSection extends Component {
  constructor(props){
    super(props);
    this.state = {
      isOpenInsuranceModal: false
    }
  }

  handleOpenInsuranceModal = () => this.setState({isOpenInsuranceModal: true});
  handleCloseInsuranceModal = () => this.setState({open: false});

  render() {
    const {onManageDisableScrolling} = this.props;
    return (
      <section className={css.introSection}>
        <div className={css.textBoxs}>
          <div className={css.textBox}>
            <div className={css.title}>
              <FormattedMessage id="BecomeAHostPage.introTitle1" values={{ newline: (<span className={css.newline}><br /></span>) }} />
            </div>
            <div className={css.description}>
              <FormattedMessage id="BecomeAHostPage.introDescription1" values={{ newline: (<br />) }} />
            </div>
            <img src={imageSource.becomeAHost.insuranceLogo} className={css.insuranceLogo}/>
            <ExternalLink
              className={css.defaultButton}
              href="https://drivelah-public-files.s3.ap-southeast-1.amazonaws.com/Drive+lah+protect+-+Policy.pdf"
            >
              <FormattedMessage id="BecomeAHostPage.ourInsurancePolicy" />
            </ExternalLink>
          </div>
          <div className={css.textBox}>
            <div className={css.title}>
              <FormattedMessage id="BecomeAHostPage.introTitle2" values={{ newline: (<span className={css.newline}><br /></span>) }} />
            </div>
            <div className={css.description}>
              <FormattedMessage id="BecomeAHostPage.introDescription2" values={{ newline: (<br />) }} />
            </div>
          </div>
        </div>
        <ModalInsurancePolicy
          isOpen={this.state.isOpenInsuranceModal}
          onClose={this.handleCloseInsuranceModal}
          onManageDisableScrolling={onManageDisableScrolling}
        />
      </section>
    );
  }
}

export default BAHIntroSection;
