import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_NEW_CAR_DISCOUNT } from '../../util/types';

import css from './BookingBreakdown.css';

const LineItemNewCarDiscountMaybe = props => {
  const { transaction, intl, isProvider } = props;

  if(isProvider || !transaction || transaction === null) return null;

  const discountNewCarItem = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_NEW_CAR_DISCOUNT
  );
  if (!discountNewCarItem) return null;
  const label = <FormattedMessage id="BookingBreakdown.newCarDiscount" />;
  const discountNewCar = discountNewCarItem.lineTotal || discountNewCarItem.unitPrice;
  const formattedPrice = formatMoney(intl, discountNewCar, 1);

  return (
    <div className={css.creditsLineItem}>
      <div className={css.totalLabel}>{label}</div>
      <div className={css.totalPrice}>{formattedPrice}</div>
    </div>
  );
};

export default LineItemNewCarDiscountMaybe;
