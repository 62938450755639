import classNames from 'classnames';
import React from 'react';
import { StaticPage, TopbarContainer } from '..';
import {
  Footer,
  InsuranceDisclaimer,
  InsurancePlusSign,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
} from '../../components';
import config from '../../config';

import ntucLogo from '../../assets/ntuc-logo.png';
import safeFirst from '../../assets/trustAnfSafety/insuranceLogo.svg';
import copyCarImage from './copy.png';
import crashCarImage from './crashcar.png';
import loopHandImage from './handloop.png';
import infinityImage from './infinity.png';
import liftCarImage from './liftcar.png';
import moneyImage from './money.png';
import ncdImage from './ncd.png';
import theftImage from './thieft.png';
import truckCarImage from './truckcar.png';
import css from './TrustAndSafetyPage.css';
import verifyImage from './verify.png';
import { default as WhenYouDriveItem } from './WhenYouDriveItem';

const TrustAndSafetyPage = () => {
  const { siteInstagramPage, siteFacebookPage } = config;

  // prettier-ignore
  return (
    <StaticPage
      title="Book a car or rent out your car with utmost trust and safety with Drive lah"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'TrustAndSafetyPage',
        description: 'Book a car with full insurance coverage. Get 24/7 road assistance on your trip. Share with Drive lah for a fully insured and safe rent out experience',
        name: 'Trust and safety page',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <div className={css.coverImageContaienr}>
            <img className={css.coverImage} src={infinityImage} alt="Secure monthly car rental services with Drive lah" />
            <h1 style={{ height: 0, width: 0, overFlow: "hidden", visibility: "hidden", margin: 0 }}>
              Share safely, drive confidently
            </h1>
          </div>
          <div className={css.backgroundWhite}>
            <div className={css.sloganWrapper}>
              <div className={classNames(css.sloganMain, css.firstSlogan)}>
                <div>
                  <h2 className={css.lightText}>Your safety is our priority</h2>
                  <p className={css.darkTextMaxWidth}>Drive lah Protection<sup>TM</sup> is offered by bundling power with some of the most trusted insurance companies in the world.</p>
                </div>
                <div className={css.safeFirstImageContainer}>
                  <img src={safeFirst} className={css.safeFirstImage} />
                  <img src={ntucLogo} className={css.safeNtucImage} />
                </div>
              </div>
            </div>
          </div>

          <div className={css.backgroundDark}>
            <div className={css.sloganWrapper}>
              <div className={css.sloganMain}>
                <h3 className={css.yellowText}>When you host you car</h3>
                <p className={css.whiteTextMobile}>
                  Together with our insurance partners Tokio Marine Insurance Group and NTUC Income we are there for you.
                  For every booking you are well covered by Drive lah Protection<sup>TM</sup>. This way your risk is minimized when you share your car with others.
                </p>
                <p className={css.whiteText}>
                  Together with our insurance partners Tokio Marine Insurance Group and NTUC Income we are there for you.
                  For every booking you are well covered by Drive lah Protection<sup>TM</sup>. This way your risk is minimized when you share your car with others.
                </p>
                <div className={css.whenYouHostContainer}>
                  <div className={css.whenYouHostItem}>
                    <img className={css.whenYouHostImage} src={verifyImage} />
                    <p className={css.whenYouHostItemText}>Unlimited 3rd party liability coverage</p>
                  </div>

                  <div className={css.whenYouHostItem}>
                    <img className={css.whenYouHostImage} src={theftImage} />
                    <p className={css.whenYouHostItemText}>
                      Comprehensive
                      insurance
                      <div>cover<InsurancePlusSign /></div></p>
                  </div>

                  <div className={css.whenYouHostItem}>
                    <img className={css.whenYouHostImage} src={moneyImage} />
                    <p className={css.whenYouHostItemText}>Security deposit for incidentals & excess</p>
                  </div>

                  <div className={css.whenYouHostItem}>
                    <img className={css.whenYouHostImage} src={ncdImage} />
                    <p className={css.whenYouHostItemText}>No impact on the NCD in case of any accident during the Drive lah trip</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={css.backgroundLight}>
            <div className={css.sloganWrapper}>
              <div className={css.sloganMain}>
                <h4 className={css.yellowText}>When you drive a rented car</h4>
                <div className={css.whenYouDriveContainer}>
                  <div className={css.whenYouDriveItem}>
                    <WhenYouDriveItem
                      imageSource={safeFirst}
                      secondSource={ntucLogo}
                      altSource={"Full insurance coverage"}
                      title={<span>Full insurance<span className={css.newlineMobile}><br /></span> coverage<InsurancePlusSign /></span>}
                      content={<span>With Drive lah Protection<sup>TM</sup> we offer comprehensive insurance for the duration of every trip.</span>}
                    />
                  </div>

                  <div className={css.whenYouDriveItem}>
                    <WhenYouDriveItem
                      imageSource={crashCarImage}
                      altSource={"Unlimited 3rd party liability"}
                      title={<span>Unlimited 3rd<span className={css.newlineMobile}><br /></span> party liability</span>}
                      content={"Our insurance cover has been designed to protect you in the event of damage or injury to a third party caused during the rental."}
                    />
                  </div>
                  <div className={css.whenYouDriveItem}>
                    <WhenYouDriveItem
                      imageSource={truckCarImage}
                      altSource={"24x7 roadside assistance"}
                      title={<span>24x7 roadside<span className={css.newlineMobile}><br /></span> assistance.</span>}
                      content={"Don’t worry! You are not alone. We offer 24/7 roadside assistance when your car breaks down during a trip. Our community team is there to help you."}
                    />
                  </div>

                  <div className={css.whenYouDriveItem}>
                    <WhenYouDriveItem
                      imageSource={copyCarImage}
                      altSource={"NCD Protection"}
                      title={<span>NCD<span className={css.newlineMobile}><br /></span> Protection</span>}
                      content={
                        <>
                          Because Drive lah Protection<sup>TM</sup> works as an additional layer on top of your existing insurance, you’re NCD will not be impacted in case of any repair.
                        </>
                      }
                    />
                  </div>

                  <div className={css.whenYouDriveItem}>
                    <WhenYouDriveItem
                      imageSource={loopHandImage}
                      altSource={"Trusted & verified community"}
                      title={<span>Trusted & verified<span className={css.newlineMobile}><br /></span> community</span>}
                      content={"We ensure that verification of hosts & their listings is done at our end. Moreover, there is a transparent review system which helps build a trusted community."}
                    />
                  </div>

                  <div className={css.whenYouDriveItem}>
                    <WhenYouDriveItem
                      imageSource={liftCarImage}
                      altSource={"Local support"}
                      title={<span>Local<span className={css.newlineMobile}><br /></span> support</span>}
                      content={"We closely work together with over 100 specialized car repair centers throughout Singapore and Malaysia, which are selected based on their quality, speed and service."}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={css.backgroundWhite}>
            <div className={css.sloganWrapper}>
              <div className={classNames(css.sloganMain, css.someTips)}>
                <h5 className={css.darkText}>Some tips you must follow</h5>
                <div className={css.tipsAndTricksContainer}>
                  <div className={css.tipsAndTricksItem}>
                    <span>Read before you meet</span>
                    <p>As we are a sharing community built on trust,
                      we ask all our members to complete their profile before
                      making a booking request. Review the profile and connect
                      with those you feel comfortable with meeting</p>
                  </div>

                  <div className={css.tipsAndTricksItem}>
                    <span>Review and rate</span>
                    <p>Help other members make informed decisions by reviewing
                      and rating car hosts and drivers after each trip.</p>
                  </div>

                  <div className={css.tipsAndTricksItem}>
                    <span>Protect your privacy</span>
                    <p>Never share your email, home address or phone number online.
                      We keep your contact details private and we only
                      reveal these once a trip is booked.</p>
                  </div>

                  <div className={css.tipsAndTricksItem}>
                    <span>R.E.S.P.E.C.T.</span>
                    <p>What goes around comes around. We all like to be treated with respect.
                      Therefore anyone who is not respectful will be
                      removed from the Drive lah community.</p>
                  </div>
                  <div className={css.divider}></div>
                </div>
              </div>
            </div>
          </div>

          <InsuranceDisclaimer />
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default TrustAndSafetyPage;
