import { triggerAnalyticsEvent } from '../../util/amplitudeMapEvents';
import { event_trigger_ids } from '../../util/analyticsConstants';
import { storableError } from '../../util/errors';

// ================ Action types ================ //

export const CHANGE_PASSWORD_REQUEST = 'app/PasswordChangePage/CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'app/PasswordChangePage/CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR = 'app/PasswordChangePage/CHANGE_PASSWORD_ERROR';

export const CHANGE_PASSWORD_CLEAR = 'app/PasswordChangePage/CHANGE_PASSWORD_CLEAR';

export const SEND_INSTRUCTION_REQUEST = 'app/PasswordChangePage/SEND_INSTRUCTION_REQUEST';
export const SEND_INSTRUCTION_SUCCESS = 'app/PasswordChangePage/SEND_INSTRUCTION_SUCCESS';
export const SEND_INSTRUCTION_ERROR = 'app/PasswordChangePage/SEND_INSTRUCTION_ERROR';

// ================ Reducer ================ //

const initialState = {
  changePasswordError: null,
  changePasswordInProgress: false,
  passwordChanged: false,
  sendInstructionError: null,
  sendInstructionInProgress: false,
  sendInstructionSuccess: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        changePasswordInProgress: true,
        changePasswordError: null,
        passwordChanged: false,
      };
    case CHANGE_PASSWORD_SUCCESS:
      return { ...state, changePasswordInProgress: false, passwordChanged: true };
    case CHANGE_PASSWORD_ERROR:
      return { ...state, changePasswordInProgress: false, changePasswordError: payload };

    case CHANGE_PASSWORD_CLEAR:
      return { ...initialState };

    case SEND_INSTRUCTION_REQUEST:
      return { ...state, sendInstructionInProgress: true, sendInstructionError: null };
    case SEND_INSTRUCTION_SUCCESS:
      return {
        ...state,
        sendInstructionInProgress: false,
        sendInstructionSuccess: true,
        sendInstructionError: null,
      };
    case SEND_INSTRUCTION_ERROR:
      return { ...state, sendInstructionInProgress: false, sendInstructionError: true };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const changePasswordRequest = () => ({ type: CHANGE_PASSWORD_REQUEST });
export const changePasswordSuccess = () => ({ type: CHANGE_PASSWORD_SUCCESS });
export const changePasswordError = error => ({
  type: CHANGE_PASSWORD_ERROR,
  payload: error,
  error: true,
});

export const changePasswordClear = () => ({ type: CHANGE_PASSWORD_CLEAR });

export const sendInstructionRequest = () => ({ type: SEND_INSTRUCTION_REQUEST });
export const sendInstructionSuccess = () => ({ type: SEND_INSTRUCTION_SUCCESS });
export const sendInstructionError = () => ({ type: SEND_INSTRUCTION_ERROR });

// ================ Thunks ================ //

export const changePassword = (params, eventData) => (dispatch, getState, sdk) => {
  dispatch(changePasswordRequest());
  // console.log("Password change request", params,  eventData)
  const { newPassword, currentPassword } = params;
  
  const state = getState();
  const currentUser = state.user.currentUser;
  try {
    triggerAnalyticsEvent({
      event_id: event_trigger_ids.PROFILE_CHANGE_PASSWORD_SAVED,
      eventData: eventData,
      props: {
        ui: {
           button: 'Change Password',
           page: 'ChangePasswordScreen'
        }
      },
      userId: currentUser ? currentUser.id.uuid : null,
    });
  } catch (error) {
    console.error('Error triggering analytics event:', error);
  }
  return sdk.currentUser
    .changePassword({ newPassword, currentPassword })
    .then(() => dispatch(changePasswordSuccess()))
    .catch(e => {
      dispatch(changePasswordError(storableError(storableError(e))));
      // This is thrown so that form can be cleared
      // after a timeout on changePassword submit handler
      throw e;
    });
};

export const sendInstructionToEmail = params => (dispatch, getState, sdk) => {
  try {
    dispatch(sendInstructionRequest());
    const { email } = params;
    return sdk.passwordReset
      .request({
        email,
      })
      .then(() => {
        dispatch(sendInstructionSuccess());
      })
      .catch(() =>
        dispatch(sendInstructionError())
      );
  } catch (e) {
    dispatch(sendInstructionError());
  }
};
