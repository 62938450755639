import moment from 'moment';

const diffInHours = (startDate, endDate) => {
  return moment(endDate).diff(startDate, 'hours', true);
}

const diffInDays = (startDate, endDate) => {
  return Math.ceil(diffInHours(startDate, endDate) / 24);
}

export const isFuelAddonVisible = (startDate, endDate) => {
  return diffInDays(startDate, endDate) <= 3;
};
