import React, { useState } from 'react';
import css from './Accordion.css';
import arrorDown from '../../assets/host-themed-landing-page/arrow-down.svg';
import Button from '../Button/Button';
import classNames from 'classnames';

const Accordion = ({ content }) => {
  const [accordrionContent, setAccordionContent] = useState(content);
  const _handleAccordion = (index) => {
    const updatedAccordion = accordrionContent && accordrionContent.length > 0 && accordrionContent.map(content => {
      if((content.id - 1) === index) {
        if(content.isSelected) {
          content['isSelected'] = false;
        } else {
          content['isSelected'] = true;
        }
      } else {
        content['isSelected'] = false;
      }
      return content;
    })
    setAccordionContent(updatedAccordion);
  }

  return (
    <div className={css.accordionWrapper}>
      {accordrionContent &&
        accordrionContent.length > 0 &&
        accordrionContent.map((content, index) => {
          const activeAccordion = content.isSelected ? css.isActive : '';
          return (
            <div className={classNames(css.accordionbox, activeAccordion)}>
              <div className={css.accordionTitle} onClick={() => _handleAccordion(index)}>
                {content.accordionTitle}
                <Button className={css.accordionButton}>
                  <img src={arrorDown} alt="" />
                </Button>
              </div>
              {content.isSelected && (
                <div className={css.accordionDescription}>
                  <p>{content.accordionDescription}</p>
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
};
export default Accordion;
