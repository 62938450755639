import classNames from 'classnames';
import { string } from 'prop-types';
import React, { Component } from 'react';
import tick from '../../assets/tripPage/tick.svg';
import { FormattedMessage } from '../../util/reactIntl';
import css from './SectionsCarPhotos.css';

const ACCEPT_TYPE = 'image/*';

/**
 * isPickUp: boolean
 * Show that modal is pickup modal
 * isShowUpload: boolean
 * Show that transaction is show upload or not
 */

class UploadSection extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      existedPhotos,
      intl,
      type,
      isRemarkVisible,
      isPickUp,
    } = this.props;

    const customerTripPhotos = existedPhotos.filter(
      photo => !!photo.isCustomer && photo[type] === true
    );
    const providerTripPhotos = existedPhotos.filter(
      photo => !photo.isCustomer && photo[type] === true
    );
    const showYourPhotos = customerTripPhotos;
    const pickUpPhotos = [...showYourPhotos];

    const uploadTextLabel = <FormattedMessage id="SectionUploadCarStatus.remarks" />;
    const odometerDataLabel = intl.formatMessage({ id: 'SectionUploadCarStatus.odometerDataLabel' });
    const uploadPlaceholder = intl.formatMessage({
      id: 'SectionUploadCarStatus.uploadPlaceholder',
    });

    return (
      <div className={css.content}>
        <div className={css.pickUpPhotosHolder}>
          <div className={classNames(css.photosContainer, css.pickUpPhotosContainer)}>
            {pickUpPhotos.map(photo => (
              <div className={css.photoWrapper} key={photo.fileUrl}>
                <div className={css.photoWrapperInner}>
                  <img
                    src={photo.fileUrl}
                    className={css.tripPhoto}
                    onClick={() => window.open(photo.fileUrl, '_blank')}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        {customerTripPhotos[0] && customerTripPhotos[0].note && isRemarkVisible && (
          <div>
            <label className={css.inputTextLabel}>{uploadTextLabel}</label>
            <input
              type="text"
              className={css.inputText}
              id="noteCarPhotoStatus"
              name="noteCarPhotoStatus"
              value={customerTripPhotos[0] && customerTripPhotos[0].note}
              disabled={true}
              readOnly={true}
              placeholder={uploadPlaceholder}
            />
          </div>
        )}
        {(isPickUp && type === 'odometerPhotos' && customerTripPhotos[0] && customerTripPhotos[0].odometerStartData) && (
          <div>
            <label className={css.inputTextLabel}>{odometerDataLabel}</label>
            <input
              type="text"
              className={css.inputText}
              id="odometerCarPhotoStatus"
              name="odometerCarPhotoStatus"
              value={customerTripPhotos[0] && customerTripPhotos[0].odometerStartData}
              disabled={true}
              readOnly={true}
              placeholder={uploadPlaceholder}
            />
          </div>
        )}
        {(!isPickUp && type === 'odometerPhotos' && customerTripPhotos[0] && customerTripPhotos[0].odometerEndData) && (
          <div>
            <label className={css.inputTextLabel}>{odometerDataLabel}</label>
            <input
              type="text"
              className={css.inputText}
              id="odometerCarPhotoStatus"
              name="odometerCarPhotoStatus"
              value={customerTripPhotos[0] && customerTripPhotos[0].odometerEndData}
              disabled={true}
              readOnly={true}
              placeholder={uploadPlaceholder}
            />
          </div>
        )}
      </div>
    );
  }
}

class SectionCarPhotosOnlyView extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      rootClassName,
      className,
      intl,
      existedPhotos,
      onClick,
      holderClassName,
      title,
      description,
      type,
      isCustomer,
      isRemarkVisible,
      isPickUp,
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);

    return (
      <div className={classNames(classes, holderClassName)}>
        <div onClick={onClick} className={classNames(css.sectionPhotoHolder)}>
          <div className={css.tickContainer}>
            <img src={tick} alt="tick" className={css.tick} />
          </div>

          <div>
            <h2 className={css.photoTitle}>{title}</h2>
            { isCustomer && <p className={css.photoDescription}>{description}</p> }
          </div>
        </div>
        <UploadSection
          isPickUp={isPickUp}
          isRemarkVisible={isRemarkVisible}
          existedPhotos={existedPhotos.filter(i => type in i)}
          intl={intl}
          type={type}
        />
      </div>
    );
  }
}

SectionCarPhotosOnlyView.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionCarPhotosOnlyView.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionCarPhotosOnlyView;
