import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { get, pickBy } from 'lodash';
import classNames from 'classnames';
import config from '../../config';
import routeConfiguration, { getRouteName } from '../../routeConfiguration';
import { withViewport } from '../../util/contextHelpers';
import { parse, stringify } from '../../util/urlHelpers';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import { propTypes } from '../../util/types';
import { isMobile, isSafari } from 'react-device-detect';
import {
  Avatar,
  Button,
  IconArrowDown,
  LimitedAccessBanner,
  Logo,
  MobileAppBanner,
  Modal,
  ModalMissingInformation,
  ModalMissingPostalCode,
  NamedLink,
  SearchPageTopbarDesktop,
  TopbarDesktop,
  TopbarMobileMenu,
  ErrorBoundary,
} from '../../components';
import { TopbarSearchForm } from '../../forms';
import TopbarSearchModal from './TopbarSearchModal';
import { pushGTMBrowseEvent } from '../../util/gtm/gtmHelpers';
import {
  EVENT_BROWSE_LOGGEDOUT,
  EVENT_BROWSE_OWNPROFILE,
  EVENT_BROWSE_PHONEICON,
  EVENT_BROWSE_WHATSAPPICON,
} from '../../util/gtm/gtmConstants';
import SearchIcon from './SearchIcon';
import css from './Topbar.css';
import { WarningAlert } from '../WarningAlert/WarningAlert';

const MAX_MOBILE_SCREEN_WIDTH = 768;

const redirectToURLWithModalState = (props, modalStateParam) => {
  const { history, location } = props;
  const { pathname, search, state } = location;
  const searchString = `?${stringify({ [modalStateParam]: 'open', ...parse(search) })}`;
  history.push(`${pathname}${searchString}`, state);
};

const redirectToURLWithoutModalState = (props, modalStateParam) => {
  const { history, location } = props;
  const { pathname, search, state } = location;
  const queryParams = pickBy(parse(search), (v, k) => {
    return k !== modalStateParam;
  });
  const stringified = stringify(queryParams);
  const searchString = stringified ? `?${stringified}` : '';
  history.push(`${pathname}${searchString}`, state);
};

const GenericError = props => {
  const { show } = props;
  const classes = classNames(css.genericError, {
    [css.genericErrorVisible]: show,
  });
  return (
    <div className={classes}>
      <div className={css.genericErrorContent}>
        <p className={css.genericErrorText}>
          <FormattedMessage id="Topbar.genericError" />
        </p>
      </div>
    </div>
  );
};

const { bool } = PropTypes;

GenericError.propTypes = {
  show: bool.isRequired,
};

class TopbarComponent extends Component {
  constructor(props) {
    super(props);
    this.handleMobileMenuOpen = this.handleMobileMenuOpen.bind(this);
    this.handleMobileMenuClose = this.handleMobileMenuClose.bind(this);
    this.handleMobileSearchOpen = this.handleMobileSearchOpen.bind(this);
    this.handleMobileSearchClose = this.handleMobileSearchClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.setIntercomSettings = false;
    this.previousLoggedState = false;
    this.topbar = createRef();
    this.topbarContainer = createRef();
    this.contactUs = createRef();

    this.state = {
      showBanner: false,
    };
  }

  handleShowBanner = () => {
    const { isAuthenticated, currentUser, currentPage } = this.props;
    if (currentPage !== 'LandingPage') return;
    this.id = !isAuthenticated
      ? 'showMobileAppBanner'
      : isAuthenticated && currentUser && currentUser.id
      ? `${currentUser.id.uuid}_showMobileAppBanner`
      : null;

    if (this.id && isMobile && isSafari) {
      const showBannerValue = window.localStorage.getItem(this.id);
      if ((this.state.showBanner !== showBannerValue) !== 'hide')
        this.setState({ showBanner: showBannerValue !== 'hide' });
    }
  };

  handleScroll = () => {
    if (window.pageYOffset > 100) {
      if (this.topbar.current) {
        this.topbar.current.style.backgroundColor = '#00a3ad';
      }
      if (this.topbar.current) {
        this.topbarContainer.current.style.backgroundColor = '#00a3ad';
      }
      if (this.contactUs.current) {
        this.contactUs.current.style.display = 'flex';
        this.contactUs.current.style.flexDirection = 'row';
        this.contactUs.current.style.justifyContent = 'space-evenly';
      }
    } else {
      if (this.topbar.current) {
        this.topbar.current.style.backgroundColor = 'transparent';
      }
      if (this.topbar.current) {
        this.topbarContainer.current.style.backgroundColor = 'transparent';
      }
      if (this.contactUs.current) {
        this.contactUs.current.style.display = 'none';
      }
    }
  };

  componentDidMount() {
    this.handleShowBanner();
    if (this.props.transparent) {
      if (this.topbar.current) {
        this.topbar.current.style.backgroundColor = 'transparent';
      }
      if (this.topbar.current) {
        this.topbarContainer.current.style.backgroundColor = 'transparent';
      }
      if (this.contactUs.current) {
        this.contactUs.current.style.display = 'none';
      }
      document.addEventListener('scroll', this.handleScroll);
    } else {
      if (this.topbar.current) {
        this.topbar.current.style.backgroundColor = '#00a3ad';
      }
      if (this.topbar.current) {
        this.topbarContainer.current.style.backgroundColor = '#00a3ad';
      }
      if (this.contactUs.current) {
        this.contactUs.current.style.display = 'flex';
        this.contactUs.current.style.flexDirection = 'row';
        this.contactUs.current.style.justifyContent = 'space-evenly';
      }
    }
  }

  componentWillUnmount() {
    if (this.props.transparent) {
      document.removeEventListener('scroll', this.handleScroll);
    }
  }

  componentWillReceiveProps(props) {
    this.handleShowBanner();
  }

  handleMobileMenuOpen() {
    redirectToURLWithModalState(this.props, 'mobilemenu');
  }

  handleMobileMenuClose() {
    redirectToURLWithoutModalState(this.props, 'mobilemenu');
  }

  handleMobileSearchOpen() {
    redirectToURLWithModalState(this.props, 'mobilesearch');
  }

  handleMobileSearchClose() {
    redirectToURLWithoutModalState(this.props, 'mobilesearch');
  }

  handleSubmit(values) {
    const { currentSearchParams } = this.props;
    const { search, selectedPlace } = values.location;
    const { history } = this.props;
    const { origin, bounds } = selectedPlace;
    const originMaybe = config.sortSearchByDistance ? { origin } : {};
    const searchParams = {
      ...currentSearchParams,
      ...originMaybe,
      address: search,
      bounds,
    };
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  }

  handleLogout() {
    const { onLogout, history } = this.props;
    onLogout().then(() => {
      const path = pathByRouteName('LandingPage', routeConfiguration());
      // pushGTMBrowseEvent({
      //   props: this.props,
      //   event: EVENT_BROWSE_LOGGEDOUT,
      //   eventCallback: () => {
      //     // In production we ensure that data is really lost,
      //     // but in development mode we use stored values for debugging
      //     if (config.dev) {
      //       history.push(path);
      //     } else if (typeof window !== 'undefined') {
      //       window.location = path;
      //     }


      //   },
      // });
    });
  }

  onViewOwnProfile = () => {
    const { currentUser } = this.props;
    if (currentUser) {
      // pushGTMBrowseEvent({
      //   props: this.props,
      //   event: EVENT_BROWSE_OWNPROFILE,
      // });
    }
  };

  closeHourleyBanner = () => {
    this.setState({ showBanner: false });
    if (this.id) {
      window.localStorage.setItem(this.id, 'hide');
    }
  };

  pushGTMBrowse = (eventGTM, eventAttribute) => {
    let eventCallback;
    if (eventAttribute) {
      eventAttribute.preventDefault();
      const href = eventAttribute.currentTarget.href;
      eventCallback = () => {
        if (typeof window !== 'undefined') {
          window.location.href = href;
        }
      };
    }
    pushGTMBrowseEvent({
      props: this.props,
      event: eventGTM,
      eventCallback,
    });
  };

  render() {
    const {
      className,
      rootClassName,
      notApplySearch,
      desktopClassName,
      mobileRootClassName,
      mobileClassName,
      isAuthenticated,
      authScopes,
      authInProgress,
      currentUser,
      currentUserHasListings,
      currentUserHasOrders,
      currentPage,
      notificationCount,
      viewport,
      intl,
      location,
      history,
      onManageDisableScrolling,
      showGenericError,
      modalMissingInfomationProps,
      waitingForUploadAva,
      onSetupWaitingForUploadAvaThenVerifyGuest,
      contactUsClassName,
      searchSelectedPrediction,
      onLoginOrSignupClick,
      currentUserStripeAccountRestricted,
      onCloseAlert,
      logoutInProgress
    } = this.props;

    const { mobilemenu, mobilesearch, address, origin, bounds, code } = parse(location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });

    const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

    const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
    const isMobileMenuOpen = isMobileLayout && mobilemenu === 'open';
    const isMobileSearchOpen = isMobileLayout && mobilesearch === 'open';

    const mobileMenu = (
      <TopbarMobileMenu
        isAuthenticated={isAuthenticated}
        currentUserHasListings={currentUserHasListings}
        currentUser={currentUser}
        onLogout={this.handleLogout}
        notificationCount={notificationCount}
        currentPage={currentPage}
        location={location}
        onClose={this.handleMobileMenuClose}
      />
    );

    // Only render current search if full place object is available in the URL params
    const locationFieldsPresent = config.sortSearchByDistance
      ? address && origin && bounds
      : address && bounds;
    const initialSearchFormValues = {
      location: locationFieldsPresent
        ? {
            search: address,
            selectedPlace: { address, origin, bounds },
          }
        : null,
    };

    const classes = classNames(rootClassName || css.root, className, {
      [css.topbarRootMobileWithBanner]: this.state.showBanner,
    });

    const hasShowMissingInformationModal = !logoutInProgress && get(currentUser, "attributes.profile.protectedData.phoneNumber");

    return (
      <div className={classes} id="topbar" ref={this.topbar}>
        <MobileAppBanner
          closeHourleyBanner={this.closeHourleyBanner}
          showBanner={this.state.showBanner}
          className={classNames({ [css.bannerInMobile]: this.state.showBanner })}
        />
        <LimitedAccessBanner
          isAuthenticated={isAuthenticated}
          authScopes={authScopes}
          currentUser={currentUser}
          onLogout={this.handleLogout}
          currentPage={currentPage}
        />
        <div
          ref={this.topbarContainer}
          className={classNames(mobileRootClassName || css.container, mobileClassName, {
            [css.mobileWithBanner]: this.state.showBanner,
          })}
        >
          <NamedLink name={`${getRouteName(history)}`}>
            <Logo format="mobile" />
          </NamedLink>

          <div className={css.avatarTopbarWrapper} onClick={this.handleMobileMenuOpen}>
            <Avatar user={currentUser} />
            <IconArrowDown className={css.arrowDown} />
          </div>
          {notApplySearch ? null : (
            <Button
              rootClassName={css.searchMenu}
              onClick={this.handleMobileSearchOpen}
              title={intl.formatMessage({ id: 'Topbar.searchIcon' })}
            >
              {!notApplySearch && <SearchIcon className={css.searchMenuIcon} />}
            </Button>
          )}
        </div>
        <div className={css.desktop}>
          {!isMobileLayout && currentPage === 'SearchPage' ? (
            <SearchPageTopbarDesktop
              className={desktopClassName}
              notApplySearch={notApplySearch}
              currentUserHasListings={currentUserHasListings}
              currentUser={currentUser}
              currentPage={currentPage}
              initialSearchFormValues={initialSearchFormValues}
              intl={intl}
              isAuthenticated={isAuthenticated}
              notificationCount={notificationCount}
              onLogout={this.handleLogout}
              onSearchSubmit={this.handleSubmit}
              location={location}
              history={history}
              searchSelectedPrediction={searchSelectedPrediction}
              onLoginOrSignupClick={onLoginOrSignupClick}
            />
          ) : (
            <TopbarDesktop
              className={desktopClassName}
              notApplySearch={notApplySearch}
              currentUserHasListings={currentUserHasListings}
              currentUser={currentUser}
              currentPage={currentPage}
              initialSearchFormValues={initialSearchFormValues}
              intl={intl}
              isAuthenticated={isAuthenticated}
              notificationCount={notificationCount}
              onLogout={this.handleLogout}
              onSearchSubmit={this.handleSubmit}
              location={location}
                history={history}
              onLoginOrSignupClick={onLoginOrSignupClick}
            />
          )}
        </div>
        <Modal
          closeButtonClassName={css.closeButtonClassName}
          closeTextClassName={css.closeTextClassName}
          id="TopbarMobileMenu"
          isOpen={isMobileMenuOpen}
          onClose={this.handleMobileMenuClose}
          onManageDisableScrolling={onManageDisableScrolling}
          containerClassName={css.mobileMenuContainer}
          closeIconClassName={css.closeIconClassName}
          scrollLayerClassName={css.mobileMenuScrollLayer}
        >
          {authInProgress ? null : mobileMenu}
        </Modal>
        {!notApplySearch && (
          <Modal
            id="TopbarMobileSearch"
            containerClassName={css.modalContainer}
            isOpen={isMobileSearchOpen}
            onClose={this.handleMobileSearchClose}
            onManageDisableScrolling={onManageDisableScrolling}
          >
            <div className={css.searchContainer}>
              <TopbarSearchForm
                formId="TopbarSearchForm"
                onSubmit={this.handleSubmit}
                initialValues={initialSearchFormValues}
                isMobile
              />
              <p className={css.mobileHelp}>
                <FormattedMessage id="Topbar.mobileSearchHelp" />
              </p>
            </div>
          </Modal>
        )}
        <ErrorBoundary>
          <ModalMissingPostalCode
            id="ModalMissingPostalCode"
            currentUser={currentUser}
            location={location}
            history={history}
            onManageDisableScrolling={onManageDisableScrolling}
            containerClassName={css.missingPostalCodeModal}
            modalServicesProps={modalMissingInfomationProps}
          />
        </ErrorBoundary>
        {hasShowMissingInformationModal &&
          <ModalMissingInformation
            id="MissingInformationReminder"
            containerClassName={css.missingInformationModal}
            currentUser={currentUser}
            currentUserHasListings={currentUserHasListings}
            currentUserHasOrders={currentUserHasOrders}
            location={location}
            history={history}
            onManageDisableScrolling={onManageDisableScrolling}
            modalMissingInfomationProps={modalMissingInfomationProps}
            waitingForUploadAva={waitingForUploadAva}
            onSetupWaitingForUploadAvaThenVerifyGuest={onSetupWaitingForUploadAvaThenVerifyGuest}
          />
        }
        {isMobileLayout && (
          <div>
            <div
              ref={this.contactUs}
              id="contactUsWrapper"
              className={classNames(css.contactUsWrapper, contactUsClassName, {
                [css.contactInWithBanner]: this.state.showBanner,
                [css.isHiddenForSearchPage]: isMobileLayout && currentPage === 'SearchPage',
              })}
            >
              <a href="tel:+6564328307" onClick={() => this.pushGTMBrowse(EVENT_BROWSE_PHONEICON)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <path
                    id="phone-solid"
                    d="M23.128,1.154,18.253.029A1.132,1.132,0,0,0,16.964.68l-2.25,5.25a1.122,1.122,0,0,0,.323,1.312l2.841,2.325a17.372,17.372,0,0,1-8.306,8.306L7.247,15.033a1.124,1.124,0,0,0-1.312-.323L.685,16.96a1.138,1.138,0,0,0-.656,1.294l1.125,4.875A1.125,1.125,0,0,0,2.25,24,21.747,21.747,0,0,0,24,2.25,1.124,1.124,0,0,0,23.128,1.154Z"
                    transform="translate(24) rotate(90)"
                    fill="#fff"
                  />
                </svg>
              </a>
              <a
                href="https://api.whatsapp.com/send?phone=+6531384785"
                onClick={event => this.pushGTMBrowse(EVENT_BROWSE_WHATSAPPICON, event)}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <path
                    id="whatsapp-brands"
                    d="M20.405,3.487A11.9,11.9,0,0,0,1.687,17.839L0,24l6.305-1.655a11.857,11.857,0,0,0,5.684,1.446h.005A12.007,12.007,0,0,0,24,11.9,11.94,11.94,0,0,0,20.405,3.487Zm-8.411,18.3a9.867,9.867,0,0,1-5.036-1.377L6.6,20.2l-3.739.98,1-3.648-.236-.375A9.9,9.9,0,1,1,21.991,11.9,10,10,0,0,1,11.995,21.787Zm5.421-7.4c-.295-.15-1.757-.868-2.03-.964s-.471-.15-.67.15-.766.964-.943,1.168-.348.225-.643.075a8.089,8.089,0,0,1-4.045-3.536c-.305-.525.305-.488.873-1.623a.551.551,0,0,0-.027-.52c-.075-.15-.67-1.613-.916-2.207-.241-.579-.488-.5-.67-.509s-.37-.011-.568-.011a1.1,1.1,0,0,0-.793.37,3.339,3.339,0,0,0-1.039,2.48,5.822,5.822,0,0,0,1.211,3.075,13.279,13.279,0,0,0,5.079,4.489c1.886.814,2.625.884,3.568.745a3.044,3.044,0,0,0,2-1.414,2.485,2.485,0,0,0,.171-1.414C17.909,14.6,17.711,14.529,17.416,14.384Z"
                    transform="translate(24) rotate(90deg)"
                    fill="#fff"
                  />
                </svg>
              </a>
            </div>
          </div>
        )}
        <GenericError show={showGenericError} />
        {/* <WarningAlert
          show={currentUserStripeAccountRestricted}
          onClose={onCloseAlert}>
          <FormattedMessage id="Topbar.restrictedStripeAccountAlert" />
        </WarningAlert> */}
        {isMobileLayout && currentPage === 'SearchPage' && (
          <TopbarSearchModal
            currentPage={currentPage}
            onManageDisableScrolling={onManageDisableScrolling}
          />
        )}
      </div>
    );
  }
}

TopbarComponent.defaultProps = {
  className: null,
  rootClassName: null,
  desktopClassName: null,
  mobileRootClassName: null,
  mobileClassName: null,
  notificationCount: 0,
  currentUser: null,
  currentUserHasOrders: null,
  currentPage: null,
  sendVerificationEmailError: null,
  authScopes: [],
  notApplySearch: true,
  transparent: false,
};

const { array, func, number, shape, string } = PropTypes;

TopbarComponent.propTypes = {
  className: string,
  rootClassName: string,
  desktopClassName: string,
  mobileRootClassName: string,
  mobileClassName: string,
  isAuthenticated: bool.isRequired,
  authScopes: array,
  authInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentUserHasListings: bool.isRequired,
  currentUserHasOrders: bool,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  showGenericError: bool.isRequired,
  onLoginOrSignupClick: func.isRequired,

  // These are passed from Page to keep Topbar rendering aware of location changes
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const Topbar = compose(withViewport, injectIntl)(TopbarComponent);

Topbar.displayName = 'Topbar';

export default Topbar;
