import classNames from 'classnames';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { types } from '../../util/sdkLoader';
import { parse } from '../../util/urlHelpers';
import css from './HostSectionHeroPartners.css';

const { Money } = types;

const CheckMark = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16" {...props}>
      <path
        d="M16 8a8 8 0 11-8-8 8 8 0 018 8zm-8.925 4.236L13.01 6.3a.516.516 0 000-.73l-.73-.73a.516.516 0 00-.73 0L6.71 9.681l-2.26-2.26a.516.516 0 00-.73 0l-.73.73a.516.516 0 000 .73l3.355 3.355a.516.516 0 00.73 0z"
        fill="#ffcd05"
      />
    </svg>
  );
};

const Content = ({ className }) => {
  return (
    <div className={classNames(css.heroContent, className)}>
      <div className={css.row}>
        <CheckMark />
        <div className={css.heroContentText}>Additional income</div>
      </div>
      <div className={css.row}>
        <CheckMark />
        <div className={css.heroContentText}>Comprehensively insured</div>
      </div>
      <div className={css.row}>
        <CheckMark />
        <div className={css.heroContentText}>Fully verified guests</div>
      </div>
      <div className={css.row}>
        <CheckMark />
        <div className={css.heroContentText}>GPS guidance</div>
      </div>
    </div>
  );
};

const HostSectionHeroPartners = ({
  isScoot,
  signUpCodeAmount,
  loading,
  getToCalculatorSection,
}) => {
  const location = useLocation();
  const history = useHistory();
  const { code } = parse(location.search);
  const currentUser = useSelector(state => state.user.currentUser);
  const intl = useIntl();
  const handleSubmit = ({ email, password }) => {
    const url = createResourceLocatorString('SignupPage', routeConfiguration(), {}, {});
    history.push(
      code
        ? {
          pathname: url,
          search: `?code=${code}`,
          state: {
            email,
            password,
            code,
          },
        }
        : {
          pathname: url,
          state: {
            email,
            password,
          },
        }
    );
  };

  return (
    <div className={css.sectionHero}>
      <div className={css.landingContainer}>
        <div className={classNames(css.landingRow, css.alignItemsCenter)}>
          {/* <div className={css.column12}>
            <h1 className={css.heroTitle}>Rent your neighbour’s car</h1>
            <Content className={css.heroContentMobile} />
          </div> */}
          <div className={classNames(css.column6, css.contentLeft)}>
            <h3>Follow these 4 easy steps to start driving</h3>
            <ul>
              <li>
                <span className={css.listNumber}>1</span>
                <p>Sign up or log in via the Drive lah platform</p>
              </li>
              <li>
                <span className={css.listNumber}>2</span>
                <p>Choose a car and book instantly</p>
              </li>
              <li>
                <span className={css.listNumber}>3</span>
                <p>Register the car on Grab/ Gojek app in minutes</p>
              </li>
              <li>
                <span className={css.listNumber}>4</span>
                <p>Unlock the car with the app and start driving</p>
              </li>
            </ul>
          </div>
          <div className={css.column6}>

          </div>
        </div>
      </div>
    </div>
  );
};

export default HostSectionHeroPartners;
