import React from 'react';
import css from './InstantBookingLandingPage.css';
import instantPolicies from '../../assets/instantBookingLandingPage/instantPolicies.jpg';
import instantPolicies2x from '../../assets/instantBookingLandingPage/instantPolicies@2x.jpg';
import { FormattedMessage } from 'react-intl';
import { ExternalLink } from '../../components';
import classNames from 'classnames';

export const SectionInstantBookingPolicies = () => {
  return (
    <div className={css.SectionInstantPolicies}>
      <div className={css.SectionInstantPoliciesWrapperContent}>
        <div className={css.sectionInstantPoliciesImageWrapper}>
          <img className={css.sectionInstantPoliciesImage} srcSet={`${instantPolicies} 1x, ${instantPolicies2x} 2x`} alt='Instant booking policies'/>
        </div>
        <div className={css.sectionInstantPoliciesContentWrapper}>
          <div className={css.sectionInstantPoliciesContent}>
            <FormattedMessage id='InstantBookingLandingPage.SectionInstantBookingPolicies.description'/>
          </div>
          <ExternalLink href='https://help.drivelah.sg/en/articles/3957506-what-is-instant-booking' target='_blank' className={classNames(css.button, css.learnmore)}>
            <span className={css.hiddenMobileText}>Learn more about </span>
            Instant booking policies
          </ExternalLink>
        </div>
      </div>
    </div>
  )
}
