import classNames from 'classnames';
import { array, arrayOf, func, shape, string } from 'prop-types';
import React, { Component } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { compose } from 'redux';
import UploadImageSvg from '../../assets/verificationPage/UploadImageSvg.svg';
import { Button, Form, IconCheckmark } from '../../components';
import { uploadFiles } from '../../util/fileUpload';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import css from './LOSForm.css';

export class LOSForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      insuranceType: null,
      companyOwned: null,
      isNotUpload: true,
      isUploading: false,
      uploadError: null,
      losDocumentUrl: props.losDocumentUrl,
    };
    this.handleselectedFile = this.handleselectedFile.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.props.updateLosDocumentUrl(props.losDocumentUrl);
  }

  handleselectedFile = (event, fileType) => {
    if (!event.target.files || !event.target.files.length || event.target.files.length === 0)
      return;
    this.handleUpload(event.target.files, fileType);
  };

  handleUpload = (files, fileType) => {
    const user = this.props.currentUser;
    if (!user.id) {
      return;
    }
    this.setState({
      uploadError: null,
    });
    this.props.updateLosDocumentUrl([]);
    this.setState({
      losDocumentUrl: [],
    });

    for (let file of files) {
      let data = new FormData();
      data.append('file', file, file.name);
      data.append('userId', user.id.uuid);
      data.append('userEmail', user.attributes.email);
      if (fileType === 'los') {
        this.setState({
          isUploading: true,
        });
      }
      uploadFiles(data)
        .then(response => {
          const code = response.status;
          if (code !== 200) {
            return null;
          }
          return response.json();
        })
        .then(jsonResponse => {
          if (!jsonResponse.url) {
            const error = {
              id: 'EditListingInsuranceForm.genericUploadError',
              fileType: fileType,
            };
            this.setState({
              isUploading: false,
              uploadError: error,
            });
          } else {
            this.setState({
              isUploading: false,
            });
            this.setState(
              {
                losDocumentUrl: jsonResponse.url,
              },
              () => {
                this.props.updateLosDocumentUrl(this.state.losDocumentUrl);
              }
            );
          }
        })
        .catch(e => {
          const error = {
            id: 'EditListingInsuranceForm.genericUploadError',
            fileType: fileType,
          };

          this.setState({
            isUploading: false,
            uploadError: error,
          });
        });
    }
  };

  render() {
    
    return (
      <FinalForm
        {...this.props}
        render={fieldRenderProps => {
          const { className, disabled, handleSubmit, intl, invalid, pristine } = fieldRenderProps;

          const losFilePlaceHolder = intl.formatMessage({
            id: 'LOSForm.fileUploadText',
          });

          const classes = classNames(css.root, className);
          const submitReady = this.props.ready;
          const submitInProgress = this.state.isUploading || this.props.inProgress;

          const needUploadFileCommercialInsurance = !this.state.losDocumentUrl;
          const needUploadFileCommercial = needUploadFileCommercialInsurance;
          const needUploadFile = needUploadFileCommercial;
          const submitDisabled = invalid || disabled || submitInProgress || needUploadFile;

          const uploadError = this.state.uploadError ? (
            <p className={css.error}>
              <FormattedMessage
                id={this.state.uploadError.id}
                values={{ fileType: this.state.uploadError.fileType }}
              />
            </p>
          ) : null;

          return (
            <Form className={classes} onSubmit={handleSubmit}>
              {uploadError}
              <div className={css.identityVerificationGuide}>
                <p className={css.identityVerificationGuideText}>
                  <FormattedMessage id="LosForm.guideLinet1" />
                  <br></br>
                </p>
                <div className={css.category}>
                  <Button
                    type="button"
                    className={css.uploadButton}
                    children={
                      <div className={css.uploadButtonContent}>
                        {losFilePlaceHolder}
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        {this.state.losDocumentUrl ? (
                          <IconCheckmark className={css.checkmark} />
                        ) : (
                          <div className={css.stepIconContainer}>
                            <img src={UploadImageSvg} alt="Alt text" />
                          </div>
                        )}
                      </div>
                    }
                    inProgress={this.state.isUploading}
                    onClick={() => {
                      this.inputElement.click();
                    }}
                  />
                  <input
                    className={classNames(css.uploadInput, css.field)}
                    type="file"
                    name="file-upload-insurance"
                    id="file-upload-insurance"
                    onChange={e => {
                      this.handleselectedFile(e, 'los');
                    }}
                    multiple="multiple"
                    ref={input => (this.inputElement = input)}
                  />
                </div>
              </div>
              <div className={css.verificationModalButton}>
                <Button
                  type="submit"
                  inProgress={submitInProgress}
                  ready={submitReady}
                  disabled={submitDisabled}
                >
                  <FormattedMessage id="LOSForm.completeVerification" />
                </Button>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

LOSForm.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  insurances: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
  losDocumentUrl: array,
  updateLosDocumentUrl: func.isRequired,
  submitButtonId: string,
};

export default compose(injectIntl)(LOSForm);
