import React from 'react';
import classNames from 'classnames';
import { InsurancePlusSign, NamedLink } from '../../components';

import infinityImage from '../../assets/howitwork/hiw-infinity-min.png';
import { FormattedMessage } from '../../util/reactIntl';

import css from './HIWList.css';

const HIWListSection = props => {
  const { onListYourCar, listYourCarButtonId } = props;
  return (
    <section className={css.readyToGoSection}>
      <div className={css.topImage}>
        <div className={css.infinityImgWrapper}>
          <img src={infinityImage} className={css.infinityImg} alt="Car sharing made simple with Drive lah" />
          <h3 style={{ height: 0, width: 0, overFlow: "hidden", visibility: "hidden", margin: 0 }}>
            Renting a car/Sharing your car
          </h3>
        </div>
        <div className={css.leftImageSide}>

        </div>
        <div className={css.rightImageSide}>

        </div>
      </div>
      <div className={css.wrapperBox}>
        <div className={classNames(css.textBox, css.leftSideBox)}>
          <div className={css.textBoxTitle}>
            <FormattedMessage id="HowItWorkPage.leftList1Title" />
          </div>
          <div className={css.textBoxDesc}>
            <FormattedMessage id="HowItWorkPage.leftList1Desc" />
          </div>
        </div>
        <div className={classNames(css.textBox, css.rightSideBox)}>
          <div className={css.textBoxTitle}>
            <FormattedMessage id="HowItWorkPage.rightList1Title" />
          </div>
          <div className={css.textBoxDesc}>
            <FormattedMessage id="HowItWorkPage.rightList1Desc" />
          </div>
        </div>
        <div className={classNames(css.textBox, css.leftSideBox)}>
          <div className={css.textBoxTitle}>
            <FormattedMessage id="HowItWorkPage.leftList2Title" />
          </div>
          <div className={css.textBoxDesc}>
            <FormattedMessage id="HowItWorkPage.leftList2Desc" values={{ plus: <InsurancePlusSign /> }} />
          </div>
        </div>
        <div className={classNames(css.textBox, css.rightSideBox)}>
          <div className={css.textBoxTitle}>
            <FormattedMessage id="HowItWorkPage.rightList2Title" />
          </div>
          <div className={css.textBoxDesc}>
            <FormattedMessage id="HowItWorkPage.rightList2Desc" />
          </div>
        </div>
        <div className={classNames(css.textBox, css.leftSideBox)}>
          <div className={css.textBoxTitle}>
            <FormattedMessage id="HowItWorkPage.leftList3Title" />
          </div>
          <div className={css.textBoxDesc}>
            <FormattedMessage id="HowItWorkPage.leftList3Desc" />
          </div>
        </div>
        <div className={classNames(css.textBox, css.rightSideBox)}>
          <div className={css.textBoxTitle}>
            <FormattedMessage id="HowItWorkPage.rightList3Title" />
          </div>
          <div className={css.textBoxDesc}>
            <FormattedMessage id="HowItWorkPage.rightList3Desc" />
          </div>
        </div>
        <div className={classNames(css.textBox, css.leftSideBox)}>
          <div className={css.textBoxTitle}>
            <FormattedMessage id="HowItWorkPage.leftList4Title" />
          </div>
          <div className={css.textBoxDesc}>
            <FormattedMessage id="HowItWorkPage.leftList4Desc" />
          </div>
        </div>
        <div className={classNames(css.textBox, css.rightSideBox)}>
          <div className={css.textBoxTitle}>
            <FormattedMessage id="HowItWorkPage.rightList4Title" />
          </div>
          <div className={css.textBoxDesc}>
            <FormattedMessage id="HowItWorkPage.rightList4Desc" />
          </div>
        </div>
        <div className={classNames(css.textBox, css.leftSideBox)}>
          <div className={css.textBoxTitle}>
            <FormattedMessage id="HowItWorkPage.leftList5Title" />
          </div>
          <div className={css.textBoxDesc}>
            <FormattedMessage id="HowItWorkPage.leftList5Desc" />
          </div>
        </div>
        <div className={classNames(css.textBox, css.rightSideBox)}>
          <div className={css.textBoxTitle}>
            <FormattedMessage id="HowItWorkPage.rightList5Title" />
          </div>
          <div className={css.textBoxDesc}>
            <FormattedMessage id="HowItWorkPage.rightList5Desc" />
          </div>
        </div>
        <div className={classNames(css.textBox, css.leftSideBox)}>
          <div className={css.textBoxTitle}>
            <FormattedMessage id="HowItWorkPage.leftList6Title" />
          </div>
          <div className={css.textBoxDesc}>
            <FormattedMessage id="HowItWorkPage.leftList6Desc" />
          </div>
        </div>
        <div className={classNames(css.textBox, css.rightSideBox)}>
          <div className={css.textBoxTitle}>
            <FormattedMessage id="HowItWorkPage.rightList6Title" />
          </div>
          <div className={css.textBoxDesc}>
            <FormattedMessage id="HowItWorkPage.rightList6Desc" />
          </div>
        </div>
      </div>
      <div className={css.wrapperBox}>
        <div className={classNames(css.leftSideButton)}>
          <NamedLink
            name="SearchPage"
            to={{
              search: "bounds=1.54840194%2C103.93560548%2C1.20243284%2C103.68841309"
            }}
            className={css.findACar}>
            <FormattedMessage id="HowItWorkPage.findACar" />
          </NamedLink>
        </div>
        <div className={classNames(css.rightSideButton)}>
          <NamedLink
            name="NewListingPage"
            className={css.listYourCar}
            id={listYourCarButtonId}
            onClick={() => onListYourCar(listYourCarButtonId)}
          >
            <FormattedMessage id="HowItWorkPage.listYourCar" />
          </NamedLink>
        </div>
      </div>
    </section>
  );
}

export default HIWListSection;
