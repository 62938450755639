import React, { useState } from 'react';
import { FormattedDate, useIntl } from 'react-intl';
import classNames from 'classnames';
import get from 'lodash/get';
import moment from 'moment';
import {
  ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_NON_REFUNDABLE,
  ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_ONE_HOUR,
  ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_PICK_UP_REQUESTED_NON_REFUNDABLE,
  ADMIN_ACCESS_AFTER_ACCEPTED_REFUNDABLE,
  TRANSITION_ACCEPT,
  TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD,
  TRANSITION_REVIEW_2_BY_PROVIDER,
  TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD,
  TRANSITION_MARK_RESOLVED,
  TRANSITION_REVIEW_2_BY_CUSTOMER,
  TRANSITION_EXPIRE_REVIEW_PERIOD,
  TRANSITION_CONFIRM_PAYMENT_INSTANT,
  TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE,
  TRANSITION_DISPUTE,
  TRANSITION_DROPOFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU,
  TRANSITION_MARK_ACCEPTED_AFTER_ONE_HOUR,
  TRANSITION_MARK_ACCEPTED_NON_REFUNDABLE,
  TRANSITION_REQUEST_DROP_OFF,
  TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED,
  TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE,
  TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE,
  TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_RETURN_BACK_AFTER_EXPIRE_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_RETURN_BACK_AFTER_EXPIRE_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_UNVERIFIED_ACCEPTED_PENDING_STATE_INSTANT,
  TRANSITION_UNVERIFIED_VERIFICATION_INSTANT_ACCEPTED,
  txIsAccepted,
  TRANSITION_REQUEST_DROP_OFF_DLGO,
  TRANSITION_ADMIN_ACCESS_AFTER_ACCEPTED_REFUNDABLE,
  TRANSITION_ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_ONE_HOUR,
  TRANSITION_ADMIN_ACCESS_AFTER_ACCEPTED_NON_REFUNDABLE,
  TRANSITION_ADMIN_ACCESS_AFTER_PICK_UP_REQUESTED_NON_REFUNDABLE,
  TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED,
  TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_AFTER_AWAIT_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_RETURN_BACK_AFTER_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF_AFTER_ACCEPTED,
  TRANSITION_UPDATE_BOOKING_CHILD_TX_DECLINE_BY_ADMIN,
  TRANSITION_UPDATE_BOOKING_CHILD_TX_EXPIRE_PAYMENT,
  TRANSITION_UPDATE_BOOKING_CHILD_TX_EXPIRE,
  TRANSITION_UPDATE_BOOKING_CHILD_TX_DECLINE,
  TRANSITION_UPDATE_BOOKING_CHILD_TX_ACCEPT_BY_ADMIN,
  TRANSITION_UPDATE_BOOKING_CHILD_TX_ACCEPT,
  TRANSITION_EXPIRE_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_ADMIN_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_CONFIRM_DROP_OFF,
  TRANSITION_AUTO_COMPLETE,
  TRANSITION_REVIEW_1_BY_PROVIDER,
  TRANSITION_EXPIRE_PICK_UP_REQUESTED_NON_REFUNDABLE,
  TRANSITION_EXPIRE_ACCEPTED_NON_REFUNDABLE,
  TRANSITION_EXPIRE_ADMIN_ACCESS_FOR_SHU,
  TRANSITION_EXPIRE_PICK_UP_CONFIRMED,
  TRANSITION_ADMIN_CONFIRM_PICK_UP_NON_REFUNDABLE,
  TRANSITION_CONFIRM_PICK_UP_REFUNDABLE,
  TRANSITION_ACCEPT_BY_ADMIN,
  txGetPromoLineItemsTotal,
  txIsCanceled,
} from '../../util/transaction';
import { TRANSITION_LTF_REQUEST_PICK_UP } from '../../util/transactionLongTermFirst';

import { displayDateForUser } from '../../util/dates';
import { ReviewRating } from '../../components';
import tick from '../../assets/tripPage/tick.svg';

import css from './TripPanel.css';
import { getPickUpTransition } from './PickupButtonsMaybe';
import { formatMoney } from '../../util/currency';
import {
  RefactorStatusMessage,
  RefactorStatusTitleMessage,
  transition45MinPickupHost,
  transition60MinBeforeGuest,
  transition45MinPickupGuest,
  transition60MinEndTripGuest,
  transition60MinBeforeHost,
  transition60MinEndTripHost,
} from './transitionHelper';
import { getLastUpdateObj } from '../../util/updateTransaction';
import { FormattedMessage } from '../../util/reactIntl';

export const checkTimeToShowDropOff = transaction => {
  const isHourlyBooking = get(transaction, 'attributes.protectedData.hourlyBooking');
  const displayEnd = get(transaction, 'booking.attributes.displayEnd');

  const dislayEndDateForUser = new Date(displayDateForUser(displayEnd));

  // const dateShowDropOff = dislayEndDateForUser.setMinutes(dislayEndDateForUser.getMinutes() - 60);
  const dateShowDropOff = isHourlyBooking
    ? dislayEndDateForUser.setMinutes(dislayEndDateForUser.getMinutes() - 15)
    : dislayEndDateForUser.setMinutes(dislayEndDateForUser.getMinutes() - 30);
  const timeToShowDropOff = new Date(dateShowDropOff);
  const isTimeToShowTransitionDropoff = new Date() >= timeToShowDropOff;
  return isTimeToShowTransitionDropoff;
};

export const checkDropOffTimeAfterPickUpOneHour = transaction => {
  const transitions = get(transaction, 'attributes.transitions', []);
  const pickUpTransition = transitions.find(transition =>
    [
      TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE,
      TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED,
      TRANSITION_DROPOFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU,
    ].includes(transition.transition)
  );

  const createdAt = pickUpTransition && pickUpTransition.createdAt;

  let dateShowDropOff = createdAt && new Date(createdAt);
  dateShowDropOff =
    dateShowDropOff && dateShowDropOff.setMinutes(dateShowDropOff.getMinutes() + 60);
  const timeToShowDropOff = new Date(dateShowDropOff);

  const isTimeToShowTransitionDropoff = new Date() > timeToShowDropOff;
  return isTimeToShowTransitionDropoff;
};

const ItemStatus = ({
  title,
  cbFunction,
  buttonText,
  withoutButton,
  reviewRaiting,
  statusText,
  isDispute,
  showCheckMark,
}) => {
  return (
    <div className={css.bookingStatusContainer}>
      <div className={css.statusTitle}>
        <div className={css.bookingStatusWrapper}>
          {title}
          {((buttonText === 'View details' && showCheckMark) || reviewRaiting) && !isDispute ? (
            <div className={css.tickContainer}>
              <img src={tick} alt="tick" className={css.tick} />
            </div>
          ) : null}
        </div>
        {withoutButton ? null : (
          <span className={css.pickupLink} onClick={e => cbFunction(e)}>
            {buttonText}
          </span>
        )}
        {reviewRaiting ? (
          <ReviewRating
            reviewStarClassName={css.reviewStar}
            className={css.reviewStars}
            rating={reviewRaiting}
          />
        ) : null}
      </div>
      {statusText && <p className={css.statusText}>{statusText}</p>}
    </div>
  );
};

const BookingStatus = ({
  transaction: rawTransaction,
  shouldShowPickUpSection,
  shouldShowDropOffSection,
  timeZone,
  isCustomer,
  isProvider,
  providerName,
  customerName,
  currentUserRole,
  openModalReview,
  isMobileLayout,
  openDisputeModal,
  isDrivelahGo,
  UserInfoForDisplay,
  isDelivery,
}) => {
  const [showPickUpSection, setShowPickUpSection] = useState(false);
  const [showDropOffSection, setShowDropOffSection] = useState(false);

  const transaction = rawTransaction;
  const intl = useIntl();
  const { guestIdentityVerificationStatus } =
    (transaction && transaction.customer && transaction.customer.attributes.profile.publicData) ||
    {};

  const lastTransition = transaction && transaction.attributes.lastTransition;
  const allTransitions = transaction && transaction.attributes.transitions;

  const { bookingEnd: updatedBookingEnd, bookingStart: updatedBookingStart } = getLastUpdateObj(
    transaction
  );

  const currentTimeZone =
    (transaction &&
      transaction.attributes &&
      transaction.attributes.protectedData &&
      transaction.attributes.protectedData.transactionTimezone) ||
    'Asia/Singapore';

  const isDispute =
    allTransitions && allTransitions.filter(i => i.transition === TRANSITION_DISPUTE).length > 0;
  let statusTitle, statusText, additionalText;

  const reviews = get(transaction, 'reviews');
  const customerReview = reviews && reviews.filter(i => i.attributes.type === 'ofProvider');
  const providerReview = reviews && reviews.filter(i => i.attributes.type === 'ofCustomer');

  const dateFormatOptions = {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
  };

  const isHourlyBooking = get(transaction, 'attributes.protectedData.hourlyBooking');
  const { displayStart, displayEnd } = get(transaction, 'booking.attributes', {});
  const realStartDate = moment(displayStart).toDate();
  const timeZoneMaybe = timeZone ? { timeZone } : null;
  const isTimeToShowTransitionPickup = moment().isSameOrAfter(
    moment(displayStart).add(isHourlyBooking ? -15 : -60, 'minutes')
  );

  // const isTimeToShowTransitionPickup = true;

  const isTimeToHideTransitionPickup = moment().isSameOrAfter(moment(displayEnd));
  const isRequestPickupTransition =
    allTransitions &&
    allTransitions.find(
      i =>
        i.transition === TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED ||
        i.transition === TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE
    );
  const isRequestDropoffTransition =
    allTransitions &&
    allTransitions.find(
      i =>
        i.transition === TRANSITION_REQUEST_DROP_OFF ||
        i.transition === TRANSITION_DROPOFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU
    );

  const date = <FormattedDate value={realStartDate} {...dateFormatOptions} {...timeZoneMaybe} />;

  const handlePickUpSection = e => {
    e.preventDefault();
    setShowPickUpSection(!showPickUpSection);
    shouldShowPickUpSection(!showPickUpSection);
  };

  const handleDropOffSection = e => {
    e.preventDefault();
    setShowDropOffSection(!showDropOffSection);
    shouldShowDropOffSection(!showDropOffSection);
  };

  const onOpenModalReview = e => {
    e.preventDefault();
    openModalReview();
  };

  const handleDispute = e => {
    e.preventDefault();
    openDisputeModal();
  };

  const isShowDropoffBasOnDropOffTime = checkTimeToShowDropOff(transaction);
  const isTransitionAccept =
    lastTransition === TRANSITION_ACCEPT ||
    lastTransition === TRANSITION_ACCEPT_BY_ADMIN ||
    lastTransition === TRANSITION_MARK_ACCEPTED_NON_REFUNDABLE ||
    lastTransition === TRANSITION_MARK_ACCEPTED_AFTER_ONE_HOUR ||
    lastTransition === TRANSITION_CONFIRM_PAYMENT_INSTANT ||
    lastTransition === TRANSITION_UNVERIFIED_ACCEPTED_PENDING_STATE_INSTANT ||
    lastTransition === TRANSITION_UNVERIFIED_VERIFICATION_INSTANT_ACCEPTED;
  const isPickupRequestSend =
    lastTransition === TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED ||
    lastTransition === TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE;
  const isPickupConfirm =
    lastTransition === TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE ||
    lastTransition === TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE ||
    lastTransition === TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED ||
    lastTransition === ADMIN_ACCESS_AFTER_ACCEPTED_REFUNDABLE ||
    lastTransition === ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_ONE_HOUR ||
    lastTransition === ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_NON_REFUNDABLE ||
    lastTransition === ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_PICK_UP_REQUESTED_NON_REFUNDABLE;
  const isDropOffrequestSend =
    lastTransition === TRANSITION_REQUEST_DROP_OFF ||
    lastTransition === TRANSITION_DROPOFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU;

  let isTimeToShowTransitionDropoff;
  if (
    lastTransition === ADMIN_ACCESS_AFTER_ACCEPTED_REFUNDABLE ||
    lastTransition === ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_ONE_HOUR ||
    lastTransition === ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_NON_REFUNDABLE ||
    lastTransition === ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_PICK_UP_REQUESTED_NON_REFUNDABLE
  ) {
    if (isShowDropoffBasOnDropOffTime) {
      isTimeToShowTransitionDropoff = true;
    } else {
      isTimeToShowTransitionDropoff = false;
    }
  } else {
    const isShowDropOffBaseOnPickUpTime = checkDropOffTimeAfterPickUpOneHour(transaction);

    isTimeToShowTransitionDropoff = isShowDropoffBasOnDropOffTime || isShowDropOffBaseOnPickUpTime;
  }

  // isTimeToShowTransitionDropoff = true;

  const needUploadPhotoTransitions = [
    TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED,
    TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE,
    TRANSITION_LTF_REQUEST_PICK_UP,
    TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE,
    TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED,
  ];

  const viewDetailPickup = [
    TRANSITION_EXPIRE_REVIEW_PERIOD,
    TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD,
    TRANSITION_REVIEW_2_BY_PROVIDER,
    TRANSITION_REVIEW_2_BY_CUSTOMER,
    TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD,
    TRANSITION_MARK_RESOLVED,
    TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED,
    TRANSITION_ADMIN_ACCESS_AFTER_ACCEPTED_REFUNDABLE,
    TRANSITION_ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_ONE_HOUR,
    TRANSITION_ADMIN_ACCESS_AFTER_ACCEPTED_NON_REFUNDABLE,
    TRANSITION_ADMIN_ACCESS_AFTER_PICK_UP_REQUESTED_NON_REFUNDABLE,
    TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED,
    TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE,
    TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_AFTER_AWAIT_UPDATE_BOOKING_BEFORE_PICK_UP,
    TRANSITION_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP,
    TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF,
    TRANSITION_RETURN_BACK_AFTER_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF,
    TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF,
    TRANSITION_RETURN_BACK_AFTER_EXPIRE_UPDATE_BOOKING_BEFORE_DROP_OFF,
    TRANSITION_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF,
    TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF,
    TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF,
    TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF,
    TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF_AFTER_ACCEPTED,
    TRANSITION_UPDATE_BOOKING_CHILD_TX_DECLINE_BY_ADMIN,
    TRANSITION_UPDATE_BOOKING_CHILD_TX_EXPIRE_PAYMENT,
    TRANSITION_UPDATE_BOOKING_CHILD_TX_EXPIRE,
    TRANSITION_UPDATE_BOOKING_CHILD_TX_DECLINE,
    TRANSITION_UPDATE_BOOKING_CHILD_TX_ACCEPT_BY_ADMIN,
    TRANSITION_UPDATE_BOOKING_CHILD_TX_ACCEPT,
    TRANSITION_EXPIRE_UPDATE_BOOKING_BEFORE_DROP_OFF,
    TRANSITION_ADMIN_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF,
    TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE,
    TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE,
    TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED,
    TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE,
    TRANSITION_REQUEST_DROP_OFF,
    TRANSITION_AUTO_COMPLETE,
    TRANSITION_REVIEW_1_BY_PROVIDER,
    TRANSITION_EXPIRE_PICK_UP_REQUESTED_NON_REFUNDABLE,
    TRANSITION_CONFIRM_DROP_OFF,
    TRANSITION_REQUEST_DROP_OFF_DLGO,
    TRANSITION_EXPIRE_ACCEPTED_NON_REFUNDABLE,
    TRANSITION_EXPIRE_ADMIN_ACCESS_FOR_SHU,
    TRANSITION_EXPIRE_PICK_UP_CONFIRMED,
  ];

  const viewDetailDropOf = [
    TRANSITION_EXPIRE_REVIEW_PERIOD,
    TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD,
    TRANSITION_REVIEW_2_BY_PROVIDER,
    TRANSITION_REVIEW_2_BY_CUSTOMER,
    TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD,
    TRANSITION_MARK_RESOLVED,
    TRANSITION_REQUEST_DROP_OFF,
    TRANSITION_REQUEST_DROP_OFF_DLGO,
    TRANSITION_CONFIRM_DROP_OFF,
    TRANSITION_AUTO_COMPLETE,
    TRANSITION_REVIEW_1_BY_PROVIDER,
    TRANSITION_EXPIRE_PICK_UP_REQUESTED_NON_REFUNDABLE,
    TRANSITION_EXPIRE_ACCEPTED_NON_REFUNDABLE,
    TRANSITION_EXPIRE_ADMIN_ACCESS_FOR_SHU,
    TRANSITION_EXPIRE_PICK_UP_CONFIRMED,
  ];

  const dropOffButtonShow = [
    TRANSITION_ADMIN_ACCESS_AFTER_ACCEPTED_REFUNDABLE,
    TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED,
    TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE,
    TRANSITION_ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_ONE_HOUR,
    TRANSITION_ADMIN_ACCESS_AFTER_ACCEPTED_NON_REFUNDABLE,
    TRANSITION_ADMIN_ACCESS_AFTER_PICK_UP_REQUESTED_NON_REFUNDABLE,
    TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE,
    TRANSITION_ADMIN_CONFIRM_PICK_UP_NON_REFUNDABLE,
    TRANSITION_CONFIRM_PICK_UP_REFUNDABLE,
  ];
  const transitionMaybe = get(getPickUpTransition(transaction), `${currentUserRole}`, null);

  const photoObjects = get(transaction, 'attributes.protectedData.photoObjects');

  const displayStartTime =
    transaction &&
    transaction.booking &&
    transaction.booking.attributes &&
    transaction.booking.attributes.displayStart;
  const displayEndTime =
    transaction &&
    transaction.booking &&
    transaction.booking.attributes &&
    transaction.booking.attributes.displayEnd;
  const lastTransitionedAtVal =
    transaction && transaction.attributes && transaction.attributes.lastTransitionedAt;

  const startTimeDiff = moment(displayStartTime)
    .tz(currentTimeZone)
    .diff(moment(), 'minutes');
  // console.log(startTimeDiff, 'startTimeDiff')

  const endTimeDiff = moment(displayEndTime)
    .tz(currentTimeZone)
    .diff(moment(), 'minutes');

  const pickUpTimeDiff = moment()
    .tz(currentTimeZone)
    .diff(moment(lastTransitionedAtVal), 'minutes');

  const progressBarClasses =
    photoObjects && photoObjects.length === 1
      ? css.progressBar25
      : photoObjects && photoObjects.length === 2
        ? css.progressBar50
        : photoObjects && photoObjects.length === 3
          ? css.progressBar75
          : photoObjects && photoObjects.length === 4
            ? css.progressBar100
            : null;

  const progressBarClassesDrivelahGo =
    photoObjects && photoObjects.length === 1
      ? css.progressBar50
      : photoObjects && photoObjects.length === 2
        ? css.progressBar100
        : null;

  const percentageText =
    photoObjects && photoObjects.length === 1
      ? '25% done'
      : photoObjects && photoObjects.length === 2
        ? '50% done'
        : photoObjects && photoObjects.length === 3
          ? '75% done'
          : photoObjects && photoObjects.length === 4
            ? null
            : null;

  const percentageTextDrivelahGo =
    photoObjects && photoObjects.length === 1
      ? '50% done'
      : photoObjects && photoObjects.length === 2
        ? null
        : null;

  const needUploadPickUpPhoto =
    (needUploadPhotoTransitions.includes(transitionMaybe) && isTimeToShowTransitionPickup) ||
    (isProvider && transitionMaybe && !needUploadPhotoTransitions.includes(transitionMaybe)) ||
    (isCustomer &&
      txIsAccepted(transaction) &&
      lastTransition === TRANSITION_RETURN_BACK_AFTER_EXPIRE_UPDATE_BOOKING_BEFORE_PICK_UP &&
      isTimeToShowTransitionPickup) ||
    (isCustomer &&
      txIsAccepted(transaction) &&
      lastTransition === TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP &&
      isTimeToShowTransitionPickup) ||
    (isCustomer &&
      txIsAccepted(transaction) &&
      lastTransition === TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP &&
      isTimeToShowTransitionPickup);

  const needAcceptPickUprequest = isProvider && isPickupRequestSend;
  const needUploadDropOffPhoto =
    (isCustomer && isTimeToShowTransitionDropoff && isPickupConfirm) ||
    (isCustomer &&
      lastTransition === TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF &&
      isTimeToShowTransitionDropoff) ||
    (isCustomer &&
      lastTransition === TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF &&
      isTimeToShowTransitionDropoff) ||
    (isCustomer &&
      lastTransition === TRANSITION_RETURN_BACK_AFTER_EXPIRE_UPDATE_BOOKING_BEFORE_DROP_OFF &&
      isTimeToShowTransitionDropoff)
    || (isCustomer && dropOffButtonShow.includes(lastTransition) && endTimeDiff < 60)
    || (isCustomer && dropOffButtonShow.includes(lastTransition) && moment()
      .tz(currentTimeZone)
      .diff(moment(displayStartTime), 'minutes') > 45)

  const needAcceptDropOffRequest = isProvider && isDropOffrequestSend;

  const StatusData = ({ title, text, subText }) => {
    let statusTitle = title;
    let statusText = text;

    return (
      <div className={css.bookingStatusContainer}>
        <h4 className={css.statusTitle}>
          {statusTitle}
          {needUploadPickUpPhoto || needAcceptPickUprequest ? (
            <span className={css.pickupLink} onClick={e => handlePickUpSection(e)}>
              {isCustomer ? 'Complete Now' : 'Confirm Now'}
            </span>
          ) : null}
          {needUploadDropOffPhoto || needAcceptDropOffRequest ? (
            <span className={css.pickupLink} onClick={e => handleDropOffSection(e)}>
              {isCustomer ? 'Complete Now' : 'Confirm Now'}
            </span>
          ) : null}
        </h4>
        <p className={css.statusText}>
          {statusText}
          {isTransitionAccept && subText && <span className={css.subText}>{subText}</span>}
        </p>

        {isCustomer ? (
          needUploadPickUpPhoto || needUploadDropOffPhoto ? (
            <div className={css.progressBarHolder}>
              <span className={css.progressBarOuter}>
                <span
                  className={
                    isDrivelahGo
                      ? classNames(css.progressBarInner, progressBarClassesDrivelahGo)
                      : classNames(css.progressBarInner, progressBarClasses)
                  }
                ></span>
                <span className={css.progressBarText}>
                  {isDrivelahGo ? percentageTextDrivelahGo : percentageText}
                </span>
              </span>
            </div>
          ) : null
        ) : null}
      </div>
    );
  };

  const payTotalvalTemp = transaction && transaction.attributes && transaction.attributes.payoutTotal;

  // Getting promo amount to add to the payoutTotal
  const promoItem = txGetPromoLineItemsTotal(transaction);
  const promoAmount = promoItem && !txIsCanceled(transaction) ? promoItem : 0;

  // Adding promo amount to payoutTotal 
  const payTotalval = {...payTotalvalTemp} // To prevent change of main amount > hence preventing change at other places.
  payTotalval.amount += (promoAmount || 0);

  const totalPriceHost = payTotalval && payTotalval.amount > 0 ? formatMoney(intl, payTotalval) : 0;
  // const displayStartTime =
  //   transaction &&
  //   transaction.booking &&
  //   transaction.booking.attributes &&
  //   transaction.booking.attributes.displayStart;
  // const displayEndTime =
  //   transaction &&
  //   transaction.booking &&
  //   transaction.booking.attributes &&
  //   transaction.booking.attributes.displayEnd;
  // const lastTransitionedAtVal =
  //   transaction && transaction.attributes && transaction.attributes.lastTransitionedAt;

  // const startTimeDiff = moment(displayStartTime)
  //   .tz(currentTimeZone)
  //   .diff(moment(), 'minutes');

  // const endTimeDiff = moment(displayEndTime)
  //   .tz(currentTimeZone)
  //   .diff(moment(), 'minutes');

  // const pickUpTimeDiff = moment()
  //   .tz(currentTimeZone)
  //   .diff(moment(lastTransitionedAtVal), 'minutes');

  let headingStatus =
    UserInfoForDisplay &&
    RefactorStatusTitleMessage(
      transaction,
      UserInfoForDisplay,
      isDelivery,
      currentTimeZone,
      totalPriceHost
    );

  let statusMessage =
    UserInfoForDisplay &&
    RefactorStatusMessage(
      transaction,
      UserInfoForDisplay,
      isDelivery,
      currentTimeZone,
      totalPriceHost
    );

  if (isCustomer) {
    let isless60 = transition60MinBeforeGuest(transaction, isDelivery, currentTimeZone);
    let isless45 = transition45MinPickupGuest(transaction, isDelivery, currentTimeZone);
    let isless60EndTrip = transition60MinEndTripGuest(transaction, isDelivery, currentTimeZone);

    if (startTimeDiff < 60 && isless60 && isless60.isEqual) {
      return (
        <div>
          <StatusData
            title={startTimeDiff < 15 ? 'Send pickup request' : isless60.title}
            text={isless60.message}
          />
          {viewDetailPickup.includes(lastTransition) ? (
            <ItemStatus
              title={<FormattedMessage id="TransactionPanel.bookingRequestPickUp" />}
              cbFunction={handlePickUpSection}
              buttonText={'View details'}
              showCheckMark={false}
            />
          ) : null}
          {viewDetailDropOf.includes(lastTransition) ? (
            <ItemStatus
              title={<FormattedMessage id="TransactionPanel.bookingRequestDropOff" />}
              cbFunction={handleDropOffSection}
              buttonText={'View details'}
              isDispute={isDispute}
            />
          ) : null}
        </div>
      );
    } else if (endTimeDiff < 60 && isless60EndTrip && isless60EndTrip.isEqual) {
      return (
        <div>
          <StatusData title={isless60EndTrip.title} text={isless60EndTrip.message} />
          {viewDetailPickup.includes(lastTransition) ? (
            <ItemStatus
              title={<FormattedMessage id="TransactionPanel.bookingRequestPickUp" />}
              cbFunction={handlePickUpSection}
              buttonText={'View details'}
              showCheckMark={false}
            />
          ) : null}
          {viewDetailDropOf.includes(lastTransition) ? (
            <ItemStatus
              title={<FormattedMessage id="TransactionPanel.bookingRequestDropOff" />}
              cbFunction={handleDropOffSection}
              buttonText={'View details'}
              isDispute={isDispute}
            />
          ) : null}
        </div>
      );
    } else if (pickUpTimeDiff > 45 && isless45 && isless45.isEqual) {
      return (
        <div>
          <StatusData title={isless45.title} text={isless45.message} />
          {viewDetailPickup.includes(lastTransition) ? (
            <ItemStatus
              title={<FormattedMessage id="TransactionPanel.bookingRequestPickUp" />}
              cbFunction={handlePickUpSection}
              buttonText={'View details'}
              showCheckMark={false}
            />
          ) : null}
          {viewDetailDropOf.includes(lastTransition) ? (
            <ItemStatus
              title={<FormattedMessage id="TransactionPanel.bookingRequestDropOff" />}
              cbFunction={handleDropOffSection}
              buttonText={'View details'}
              isDispute={isDispute}
            />
          ) : null}
        </div>
      );
    } else {
      return (
        <div>
          <StatusData title={headingStatus} text={statusMessage} />
          {viewDetailPickup.includes(lastTransition) ? (
            <ItemStatus
              title={<FormattedMessage id="TransactionPanel.bookingRequestPickUp" />}
              cbFunction={handlePickUpSection}
              buttonText={'View details'}
              showCheckMark={false}
            />
          ) : null}
          {viewDetailDropOf.includes(lastTransition) ? (
            <ItemStatus
              title={<FormattedMessage id="TransactionPanel.bookingRequestDropOff" />}
              cbFunction={handleDropOffSection}
              buttonText={'View details'}
              isDispute={isDispute}
            />
          ) : null}
        </div>
      );
    }
  } else {
    let isless60 = transition60MinBeforeHost(transaction, isDelivery, currentTimeZone);
    let isless45 = transition45MinPickupHost(transaction, isDelivery, currentTimeZone);
    let isless60EndTrip = transition60MinEndTripHost(transaction, isDelivery, currentTimeZone);

    if (startTimeDiff < 60 && isless60 && isless60.isEqual) {
      return (
        <div>
          <StatusData title={isless60.title} text={isless60.message} />
          {viewDetailPickup.includes(lastTransition) ? (
            <ItemStatus
              title={<FormattedMessage id="TransactionPanel.bookingRequestPickUp" />}
              cbFunction={handlePickUpSection}
              buttonText={'View details'}
              showCheckMark={false}
            />
          ) : null}
          {viewDetailDropOf.includes(lastTransition) ? (
            <ItemStatus
              title={<FormattedMessage id="TransactionPanel.bookingRequestDropOff" />}
              cbFunction={handleDropOffSection}
              buttonText={'View details'}
              isDispute={isDispute}
            />
          ) : null}
        </div>
      );
    } else if (endTimeDiff < 60 && isless60EndTrip && isless60EndTrip.isEqual) {
      return (
        <div>
          <StatusData title={isless60EndTrip.title} text={isless60EndTrip.message} />
          {viewDetailPickup.includes(lastTransition) ? (
            <ItemStatus
              title={<FormattedMessage id="TransactionPanel.bookingRequestPickUp" />}
              cbFunction={handlePickUpSection}
              buttonText={'View details'}
              showCheckMark={false}
            />
          ) : null}
          {viewDetailDropOf.includes(lastTransition) ? (
            <ItemStatus
              title={<FormattedMessage id="TransactionPanel.bookingRequestDropOff" />}
              cbFunction={handleDropOffSection}
              buttonText={'View details'}
              isDispute={isDispute}
            />
          ) : null}
        </div>
      );
    } else if (pickUpTimeDiff < 45 && isless45 && isless45.isEqual) {
      return (
        <div>
          <StatusData title={isless45.title} text={isless45.message} />
          {viewDetailPickup.includes(lastTransition) ? (
            <ItemStatus
              title={<FormattedMessage id="TransactionPanel.bookingRequestPickUp" />}
              cbFunction={handlePickUpSection}
              buttonText={'View details'}
              showCheckMark={false}
            />
          ) : null}
          {viewDetailDropOf.includes(lastTransition) ? (
            <ItemStatus
              title={<FormattedMessage id="TransactionPanel.bookingRequestDropOff" />}
              cbFunction={handleDropOffSection}
              buttonText={'View details'}
              isDispute={isDispute}
            />
          ) : null}
        </div>
      );
    } else {
      return (
        <div>
          <StatusData title={headingStatus} text={statusMessage} />
          {viewDetailPickup.includes(lastTransition) ? (
            <ItemStatus
              title={<FormattedMessage id="TransactionPanel.bookingRequestPickUp" />}
              cbFunction={handlePickUpSection}
              buttonText={'View details'}
              showCheckMark={false}
            />
          ) : null}
          {viewDetailDropOf.includes(lastTransition) ? (
            <ItemStatus
              title={<FormattedMessage id="TransactionPanel.bookingRequestDropOff" />}
              cbFunction={handleDropOffSection}
              buttonText={'View details'}
              isDispute={isDispute}
            />
          ) : null}
        </div>
      );
    }
  }

  // const startDate = moment(displayStart)
  //   .tz(timeZone)
  //   .format('DD MMM');
  // const startTime = moment(displayStart)
  //   .tz(timeZone)
  //   .format('hh:mm A');

  // const endDate = moment(displayEnd)
  //   .tz(timeZone)
  //   .format('DD MMM');
  // const endTime = moment(displayEnd)
  //   .tz(timeZone)
  //   .format('hh:mm A');

  // const homeDelivery = transaction && transaction.listing && transaction.listing.attributes && transaction.listing.attributes.publicData && transaction.listing.attributes.publicData.delivery;
  // const hostName=transaction && transaction.provider && transaction.provider.attributes && transaction.provider.attributes.profile && transaction.provider.attributes.profile.displayName;
  // const guestName=transaction && transaction.customer && transaction.customer.attributes && transaction.customer.attributes.profile && transaction.customer.attributes.profile.displayName;
  // const payTotalval=transaction && transaction.attributes && transaction.attributes.payoutTotal;
  // const totalPriceHost = payTotalval && payTotalval.amount > 0
  //     ? formatMoney(intl,payTotalval)
  //     : 0;

  // const pickupAddress =transaction && transaction.listing && transaction.listing.attributes && transaction.listing.attributes.publicData && transaction.listing.attributes.publicData.location && transaction.listing.attributes.publicData.location.address;

  // if(isCustomer){
  //   const result = computedGuestTripStatusMessages(
  //     lastTransition,
  //     hostName,
  //     startDate,
  //     startTime,
  //     homeDelivery
  //   );
  // return <StatusData title={result.heading} text={result.message} />
  // }else{
  //   const result = computedHostTripStatusMessages(
  //     lastTransition,
  //     startDate,
  //     startTime,
  //     guestName,
  //     endDate,
  //     endTime,
  //     totalPriceHost,
  //     pickupAddress,
  //     homeDelivery,
  //   );
  //   return <StatusData title={result.heading} text={result.message} />
  // }

  // if (needUploadPickUpPhoto && isCustomer) {
  //   statusTitle = <FormattedMessage id="TransactionPanel.bookingRequestPickUp" />;
  //   statusText = <FormattedMessage id="TransactionPanel.requestPickUpText" />;
  //   return <StatusData title={statusTitle} text={statusText} />;
  // }

  // if (needAcceptPickUprequest) {
  //   statusTitle = isMobileLayout ? (
  //     <FormattedMessage id="TransactionPanel.recivedPickupRequestTitleMobile" />
  //   ) : (
  //     <FormattedMessage id="TransactionPanel.recivedPickupRequestTitle" />
  //   );
  //   statusText = <FormattedMessage id="TransactionPanel.recivedPickupRequestText" />;
  //   return <StatusData title={statusTitle} text={statusText} />;
  // }

  // if (needUploadDropOffPhoto && isCustomer) {
  //   statusTitle = <FormattedMessage id="TransactionPanel.bookingRequestDropOff" />;
  //   statusText = <FormattedMessage id="TransactionPanel.requestDropOffText" />;
  //   return (
  //     <div>
  //       <StatusData title={statusTitle} text={statusText} />
  //       <ItemStatus
  //         title={<FormattedMessage id="TransactionPanel.bookingRequestPickUp" />}
  //         cbFunction={handlePickUpSection}
  //         buttonText={'View details'}
  //         showCheckMark={false}
  //       />
  //     </div>
  //   );
  // }

  // if (needAcceptDropOffRequest) {
  //   statusTitle = <FormattedMessage id="TransactionPanel.confirmDropOffRequestTitle" />;
  //   statusText = <FormattedMessage id="TransactionPanel.confirmDropOffRequestText" />;
  //   return (
  //     <div>
  //       <StatusData title={statusTitle} text={statusText} />
  //       <ItemStatus
  //         title={<FormattedMessage id="TransactionPanel.bookingDispute" />}
  //         cbFunction={handleDispute}
  //         buttonText={'Report Dispute'}
  //       />
  //       <ItemStatus
  //         title={<FormattedMessage id="TransactionPanel.bookingRequestPickUp" />}
  //         cbFunction={handlePickUpSection}
  //         buttonText={'View details'}
  //       />
  //     </div>
  //   );
  // }

  // if (
  //   lastTransition === TRANSITION_CONFIRM_PAYMENT ||
  //   lastTransition === TRANSITION_LTF_CONFIRM_PAYMENT
  // ) {
  //   statusTitle = isCustomer ? (
  //     <FormattedMessage id="TransactionPanel.bookingRequestSent" />
  //   ) : (
  //     <FormattedMessage id="TransactionPanel.bookingRequestRecived" />
  //   );
  //   statusText = isCustomer ? (
  //     <FormattedMessage id="TransactionPanel.requestSentText" />
  //   ) : (
  //     <FormattedMessage
  //       id="TransactionPanel.requestRecivedText"
  //       values={{ userName: customerName }}
  //     />
  //   );
  //   return <StatusData title={statusTitle} text={statusText} />;
  // }

  // if (
  //   lastTransition === TRANSITION_CONFIRM_PAYMENT_INSTANT ||
  //   lastTransition === TRANSITION_MARK_ACCEPTED_AFTER_ONE_HOUR ||
  //   lastTransition === TRANSITION_MARK_ACCEPTED_NON_REFUNDABLE ||
  //   lastTransition === TRANSITION_ACCEPT
  // ) {
  //   statusTitle = <FormattedMessage id="TransactionPanel.bookingRequestConfirmed" />;
  //   statusText = isCustomer ? (
  //     <FormattedMessage id="TransactionPanel.requestConfirmedText" />
  //   ) : (
  //     <FormattedMessage id="TransactionPanel.requestConfirmedTextProvider" />
  //   );
  //   additionalText = date;

  //   return <StatusData title={statusTitle} text={statusText} subText={additionalText} />;
  // }
  // if (
  //   lastTransition === TRANSITION_ENQUIRE
  // ) {
  //   statusTitle = <FormattedMessage id="TransactionPanel.enquire" />;
  //   statusText = isCustomer ? (
  //     <FormattedMessage id="TransactionPanel.enquireText" />
  //   ) : (
  //     <FormattedMessage values={{ userName: customerName }} id="TransactionPanel.enquireTextProvider" />
  //   );
  //   additionalText = date;

  //   return <StatusData title={statusTitle} text={statusText} subText={additionalText} />;
  // }

  // if (isCustomer && isPickupRequestSend) {
  //   statusTitle = <FormattedMessage id="TransactionPanel.sendPickupRequestTitle" />;
  //   statusText = <FormattedMessage id="TransactionPanel.sendPickupRequestText" />;
  //   return (
  //     <div>
  //       <ItemStatus
  //         title={<FormattedMessage id="TransactionPanel.bookingRequestPickUp" />}
  //         cbFunction={handlePickUpSection}
  //         buttonText={'View details'}
  //         statusText={statusText}
  //         showCheckMark={txIsShu(transaction) || txIsPickedUp(transaction) || txIsPickedUpLTF(transaction)}
  //       />
  //     </div>
  //   );
  // }

  // if (txIsShu(transaction) || txIsPickedUp(transaction) || txIsPickedUpLTF(transaction)) {
  //   statusTitle = <FormattedMessage id="TransactionPanel.bookingRequestOngoing" />;
  //   statusText = isCustomer ? (
  //     isDrivelahGo ? (
  //       <FormattedMessage id="TransactionPanel.requestOngoingTextDlGo" />
  //     ) : (
  //       <FormattedMessage id="TransactionPanel.requestOngoingText" />
  //     )
  //   ) : (
  //     <FormattedMessage id="TransactionPanel.requestOngoingTextProvider" />
  //   );
  //   return (
  //     <div>
  //       <StatusData title={statusTitle} text={statusText} />
  //       <ItemStatus
  //         title={<FormattedMessage id="TransactionPanel.bookingRequestPickUp" />}
  //         cbFunction={handlePickUpSection}
  //         buttonText={'View details'}
  //         showCheckMark={txIsShu(transaction) || txIsPickedUp(transaction) || txIsPickedUpLTF(transaction)}
  //       />
  //     </div>
  //   );
  // }

  // if (isCustomer &&
  //   lastTransition === TRANSITION_REQUEST_DROP_OFF ||
  //   lastTransition === TRANSITION_DROPOFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU
  // ) {
  //   statusTitle = <FormattedMessage id="TransactionPanel.bookingRequestReview" />;
  //   return (
  //     <div>
  //       {/* <StatusData title={statusTitle} /> */}
  //       <ItemStatus
  //         title={<FormattedMessage id="TransactionPanel.bookingRequestDropOff" />}
  //         cbFunction={handleDropOffSection}
  //         buttonText={'View details'}
  //         isDispute={isDispute}
  //       />
  //       <ItemStatus
  //         title={<FormattedMessage id="TransactionPanel.bookingRequestPickUp" />}
  //         cbFunction={handlePickUpSection}
  //         buttonText={'View details'}
  //         showCheckMark={true}
  //       />
  //     </div>
  //   );
  // }

  // if (
  //   lastTransition === TRANSITION_CONFIRM_DROP_OFF ||
  //   lastTransition === TRANSITION_DISPUTE ||
  //   lastTransition === TRANSITION_AUTO_COMPLETE ||
  //   (lastTransition === TRANSITION_REVIEW_1_BY_CUSTOMER && isProvider) ||
  //   (lastTransition === TRANSITION_REVIEW_1_BY_PROVIDER && isCustomer) ||
  //   lastTransition === TRANSITION_EXPIRE_CONFIRM_PICK_UP ||
  //   lastTransition === TRANSITION_EXPIRE_ACCEPTED_NON_REFUNDABLE ||
  //   lastTransition === TRANSITION_EXPIRE_REQUEST_DROP_OFF ||
  //   lastTransition === TRANSITION_EXPIRE_ADMIN_ACCESS_FOR_SHU
  // ) {
  //   statusTitle = <FormattedMessage id="TransactionPanel.bookingRequestReview" />;
  //   return (
  //     <div>
  //       <ItemStatus
  //         title={<FormattedMessage id="TransactionPanel.bookingRequestReview" />}
  //         cbFunction={onOpenModalReview}
  //         buttonText={'Give Review'}
  //       />
  //       {isRequestDropoffTransition ? <ItemStatus
  //         title={<FormattedMessage id="TransactionPanel.bookingRequestDropOff" />}
  //         cbFunction={handleDropOffSection}
  //         buttonText={'View details'}
  //         isDispute={isDispute}
  //       /> : null}
  //       {isRequestPickupTransition ? (
  //         <ItemStatus
  //           title={<FormattedMessage id="TransactionPanel.bookingRequestPickUp" />}
  //           cbFunction={handlePickUpSection}
  //           buttonText={'View details'}
  //         />
  //       ) : null}
  //     </div>
  //   );
  // }

  // if (
  //   (lastTransition === TRANSITION_REVIEW_1_BY_CUSTOMER && isCustomer) ||
  //   (lastTransition === TRANSITION_REVIEW_1_BY_PROVIDER && isProvider) ||
  //   lastTransition === TRANSITION_REVIEW_2_BY_PROVIDER ||
  //   lastTransition === TRANSITION_REVIEW_2_BY_CUSTOMER ||
  //   lastTransition === TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD ||
  //   lastTransition === TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD ||
  //   lastTransition === TRANSITION_EXPIRE_REVIEW_PERIOD
  // ) {
  //   statusTitle = <FormattedMessage id="TransactionPanel.bookingRequestReview" />;
  //   const customerRating =
  //     customerReview && customerReview[0] && customerReview[0].attributes.rating;
  //   const providerRating =
  //     providerReview && providerReview[0] && providerReview[0].attributes.rating;
  //   return (
  //     <div>
  //       <ItemStatus
  //         title={<FormattedMessage id="TransactionPanel.bookingRequestReview" />}
  //         cbFunction={onOpenModalReview}
  //         buttonText={'Give Review'}
  //         withoutButton={true}
  //         reviewRaiting={isCustomer ? customerRating : providerRating}
  //       />
  //       {isRequestDropoffTransition ? <ItemStatus
  //         title={<FormattedMessage id="TransactionPanel.bookingRequestDropOff" />}
  //         cbFunction={handleDropOffSection}
  //         buttonText={'View details'}
  //         isDispute={isDispute}
  //       /> : null}
  //       {isRequestPickupTransition ? (
  //         <ItemStatus
  //           title={<FormattedMessage id="TransactionPanel.bookingRequestPickUp" />}
  //           cbFunction={handlePickUpSection}
  //           buttonText={'View details'}
  //         />
  //       ) : null}
  //     </div>
  //   );
  // }

  // if (
  //   lastTransition === TRANSITION_DECLINE ||
  //   // lastTransition === TRANSITION_WITHDRAW ||
  //   lastTransition === TRANSITION_ADMIN_WITHDRAW ||
  //   lastTransition === TRANSITION_LTF_DECLINE
  // ) {
  //   statusTitle = <FormattedMessage id="TransactionPanel.bookingRequestDecline" />;
  //   statusText = isCustomer ? (
  //     <p>
  //       <FormattedMessage id="TransactionPanel.bookingRequestDeclineText" />
  //       <a href="/s">
  //         <FormattedMessage id="TransactionPanel.searchLink" />
  //       </a>
  //     </p>
  //   ) : null;
  //   return <StatusData title={statusTitle} text={statusText} />;
  // }

  // if (
  //   lastTransition === TRANSITION_EXPIRE_PAYMENT ||
  //   lastTransition === TRANSITION_EXPIRE ||
  //   lastTransition === TRANSITION_LTF_EXPIRE ||
  //   lastTransition === TRANSITION_LTF_EXPIRE_PAYMENT
  // ) {
  //   statusTitle = <FormattedMessage id="TransactionPanel.bookingExpire" />;
  //   return <StatusData title={statusTitle} />;
  // }

  // if (
  //   lastTransition === TRANSITION_PROVIDER_CANCEL_AFTER_UPDATE_BOOKING ||
  //   lastTransition === TRANSITION_PROVIDER_CANCEL_NON_REFUNDABLE ||
  //   lastTransition === TRANSITION_PROVIDER_CANCEL_AFTER_ACCEPTED_ONE_HOUR ||
  //   lastTransition === TRANSITION_PROVIDER_CANCEL_REFUNDABLE
  // ) {
  //   statusTitle = isCustomer ? (
  //     <FormattedMessage id="TransactionPanel.bookingRequestCancelProviderForCustomer" values={{ providerName }} />
  //   ) : (
  //     <FormattedMessage id="TransactionPanel.bookingRequestCancelProviderForProvider" />
  //   );
  //   statusText = isCustomer ? (
  //     <FormattedMessage
  //       id="TransactionPanel.bookingCancelTextProviderForOther"
  //       values={{ userType: 'provider' }}
  //     />
  //   ) : (
  //     <FormattedMessage id="TransactionPanel.bookingCancelTextProviderForYou" />
  //   );
  //   return <StatusData title={statusTitle} text={statusText} />;
  // }

  // if (
  //   lastTransition === TRANSITION_CUSTOMER_CANCEL_AFTER_UPDATE_BOOKING ||
  //   lastTransition === TRANSITION_CUSTOMER_CANCEL_PICK_UP_REQUESTED_NON_REFUNDABLE ||
  //   lastTransition === TRANSITION_CUSTOMER_CANCEL_NON_REFUNDABLE ||
  //   lastTransition === TRANSITION_CUSTOMER_CANCEL_REFUNDABLE ||
  //   lastTransition === TRANSITION_WITHDRAW ||
  //   lastTransition === TRANSITION_CUSTOMER_CANCEL_AFTER_ACCEPTED_ONE_HOUR
  // ) {
  //   statusTitle = isCustomer ? (
  //     <FormattedMessage id="TransactionPanel.bookingRequestCancelCustomerForCustomer" />
  //   ) : (
  //     <FormattedMessage id="TransactionPanel.bookingRequestCancelCustomerForProvider" values={{ customerName }} />
  //   );
  //   statusText = isCustomer ? (
  //     <FormattedMessage id="TransactionPanel.bookingCancelTextProviderForYou" />
  //   ) : (
  //     <FormattedMessage
  //       id="TransactionPanel.bookingCancelTextProviderForOther"
  //       values={{ userType: 'customer' }}
  //     />
  //   );
  //   return <StatusData title={statusTitle} />;
  // }
  // if (
  //   lastTransition === TRANSITION_RETURN_BACK_AFTER_EXPIRE_UPDATE_BOOKING_BEFORE_PICK_UP ||
  //   lastTransition === TRANSITION_EXPIRE_UPDATE_BOOKING_BEFORE_PICK_UP ||
  //   lastTransition === TRANSITION_EXPIRE_UPDATE_BOOKING_BEFORE_DROP_OFF ||
  //   lastTransition === TRANSITION_RETURN_BACK_AFTER_EXPIRE_UPDATE_BOOKING_BEFORE_DROP_OFF
  // ) {
  //   statusTitle = <FormattedMessage id="TransactionPanel.bookingUpdateExpire" />;
  //   return <StatusData title={statusTitle} />;
  // }
  // if (
  //   lastTransition === TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF ||
  //   lastTransition === TRANSITION_RETURN_BACK_AFTER_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF ||
  //   lastTransition === TRANSITION_RETURN_BACK_AFTER_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP ||
  //   lastTransition === TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP ||
  //   lastTransition === TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF ||
  //   lastTransition === TRANSITION_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF ||
  //   lastTransition === TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP ||
  //   lastTransition === TRANSITION_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP
  // ) {
  //   statusTitle = <FormattedMessage id="TransactionPanel.bookingUpdateAccept" />;
  //   return <StatusData title={statusTitle} />;
  // }
  // if (lastTransition === TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF ||
  //   lastTransition === TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP
  // ) {
  //   statusTitle = <FormattedMessage id="TransactionPanel.bookingUpdateCancel" />;
  //   return <StatusData title={statusTitle} />;
  // }
  // if (
  //   lastTransition === TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_ONE_HOUR ||
  //   lastTransition === TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_REFUNDABLE ||
  //   lastTransition === TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_NON_REFUNDABLE ||
  //   lastTransition === TRANSITION_UPDATE_BOOKING_BEFORE_DROP_OFF
  // ) {
  //   statusTitle = <FormattedMessage id="TransactionPanel.tripModificationRequest" />
  //   statusText = isCustomer ? (
  //     <FormattedMessage
  //       id="TransactionPanel.bookingUpdateRequest"
  //       values={{
  //         providerName,
  //         bookingStart: (
  //           <span className={css.nowrap}>
  //             <FormattedDate value={displayStart} {...dateFormatOptions} {...timeZoneMaybe} />
  //           </span>
  //         ),
  //         bookingEnd: (
  //           <span className={css.nowrap}>
  //             <FormattedDate value={displayEnd} {...dateFormatOptions} {...timeZoneMaybe} />
  //           </span>
  //         ),
  //       }}
  //     />
  //   ) : (
  //     <FormattedMessage
  //       id="TransactionPanel.bookingUpdateRequestForProvider"
  //       values={{
  //         customerName,
  //         bookingStart: (
  //           <span className={css.nowrap}>
  //             <FormattedDate
  //               value={updatedBookingStart}
  //               {...dateFormatOptions}
  //               {...timeZoneMaybe}
  //             />
  //           </span>
  //         ),
  //         bookingEnd: (
  //           <span className={css.nowrap}>
  //             <FormattedDate value={updatedBookingEnd} {...dateFormatOptions} {...timeZoneMaybe} />
  //           </span>
  //         ),
  //       }}
  //     />
  //   );
  //   return <StatusData title={statusTitle} text={statusText} />;
  // }
  // if (lastTransition === TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP || lastTransition === TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF) {
  //   statusTitle = <FormattedMessage id="TransactionPanel.bookingUpdateCanceled" />;
  //   return <StatusData title={statusTitle} />;
  // }
  // if (
  //   lastTransition === TRANSITION_ADMIN_CANCEL_AFTER_UPDATE_BOOKING ||
  //   lastTransition === TRANSITION_ADMIN_CANCEL_AFTER_ACCEPTED_REFUNDABLE ||
  //   lastTransition === TRANSITION_ADMIN_CANCEL_NON_REFUND ||
  //   lastTransition === TRANSITION_ADMIN_REFUND ||
  //   lastTransition === TRANSITION_ADMIN_CANCEL_AFTER_PICK_UP_CONFIRMED
  // ) {
  //   statusTitle = <FormattedMessage id="TransactionPanel.bookingRequestCancel" />;
  //   return <StatusData title={statusTitle} />;
  // } else {
  //   return <StatusData title={'statusTitle'} text={'statusText'} />;
  // }
};

export default BookingStatus;
