import classNames from 'classnames';
import { string } from 'prop-types';
import React, { Component } from 'react';
import disabledTick from '../../assets/tripPage/tick-disabled.svg';
import tick from '../../assets/tripPage/tick.svg';
import { uploadFiles } from '../../util/fileUpload';
import { FormattedMessage } from '../../util/reactIntl';
import { IconCamera, IconClose, IconSpinner } from '../index';
import FieldError from './SectionCarPhotos/FieldError';
import css from './SectionsCarPhotos.css';

const ACCEPT_TYPE = 'image/*';

/**
 * isPickUp: boolean
 * Show that modal is pickup modal
 * isShowUpload: boolean
 * Show that transaction is show upload or not
 */

class UploadSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUploading: false,
      currentIsUploading: [],
      index: 0,
      uploadFileError: false,
      uploadedPhoto: [],
      odometerEndDataState: null,
    };
    this.uploaded = [];
  }

  uploadOtherFiles = (files, currentUser) => {
    const uploadSingleFile = (file, index) => {
      const fileData = files;

      if (!fileData) return Promise.resolve({});
      const { id } = currentUser;
      if(file) {
        const formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('userId', id.uuid);
        formData.append('tripPhotos', true);
        return uploadFiles(formData)
          .then(response => {
            if (response.status !== 200) {
              return Promise.reject(response);
            }
            return response.json();
          })
          // .then(data => {
          //   this.uploaded[index] = data;
          //   return uploadSingleFile();
          // });
      }

    };

    const promises = [];
    files.forEach((file) => {
      promises.push(
        uploadSingleFile(file.file, file.index)
      );
    });
    return Promise.all(promises);
  };

  handleUploadFiles = (files, currentUser) => {
    if (files.length) {
      this.setState({
        isUploading: true,
        uploadFileError: false,
        currentIsUploading: [
          ...this.state.currentIsUploading,
          { id: this.props.index, status: true },
        ],
      });
      // return this.uploadFirstFile(files[0], currentUser)
      //   .then(() => {
          return this.uploadOtherFiles(files, currentUser)
        // })
        .then((response) => {
          const uploadedPhoto = response.map(item => {
            return {
              fileUrl: item.url,
              note: item.note,
              odometerStartData: item.odometerStartData,
              odometerEndData: item.odometerEndData,
              type: this.props.type,
              id: this.props.id,
              isPickUp: true,
              timestamp: new Date().getSeconds(),
              isJustUploaded: true,
            }
          })

          this.props.setActivePhotoSection(uploadedPhoto);

          this.setState({
            isUploading: false,
            uploadedPhoto,
            currentIsUploading: [
              ...this.state.currentIsUploading,
              {
                id: this.props.index,
                status: true,
              },
            ],
          }, () => {
            this.handleAddPhoto();
          });
        })
        .catch(e => {
          this.setState({ uploadFileError: true, isUploading: false });
        });
    }
    return Promise.resolve({});
  };

  handleAddPhoto = currentIndex => {
    const {
      fileUrl,
      note,
      odometerStartData,
      odometerEndData,
      tripPhotos,
      isCustomer,
      isPickUp,
      type,
      files,
      setData,
      existedPhotos,
    } = this.props;
    const {uploadedPhoto} = this.state

    const allPhotos = []
    uploadedPhoto.map(i => {
      const data = {
      fileUrl: i.fileUrl,
      note: note || (tripPhotos[0] && tripPhotos[0].note) || existedPhotos[0] && existedPhotos[0].note,
      odometerStartData: odometerStartData || (tripPhotos[0] && tripPhotos[0].odometerStartData) || existedPhotos[0] && existedPhotos[0].odometerStartData,
      odometerEndData: odometerEndData || (tripPhotos[0] && tripPhotos[0].odometerEndData) || existedPhotos[0] && existedPhotos[0].odometerEndData,
      timestamp: new Date().getTime(),
      isCustomer: isCustomer,
      isPickUp: isPickUp,
      [type]: true,
    };

    allPhotos.push(data);

  })
  this.props.handleAddPhoto(allPhotos);
  };

  handleRemovePhoto = fileUrl => {
    this.props.handleRemovePhoto(fileUrl);
    if (this.props.odometerData) {
      const changeOdometerData = this.props.isPickUp
        ? { odometerStartData: null }
        : { odometerEndData: null }
      ;
      this.props.setData(changeOdometerData);
    }
  };

  handleClickUpload = () => {
    this.inputElement.click();
  };

  handleUploadPhoto = file => {
    const { currentUser } = this.props;

    if (!file) {
      return;
    }
    let data = new FormData();
    data.append('file', file, file.name);
    data.append('userId', currentUser && currentUser.id && currentUser.id.uuid);
    data.append('tripPhotos', true);
    this.setState({
      isUploading: true,
    });

    uploadFiles(data)
      .then(response => {
        const code = response.status;
        if (code !== 200) {
          return null;
        }
        return response.json();
      })
      .then(jsonResponse => {
        if (!jsonResponse.url) {
          this.setState({
            isUploading: false,
          });
        } else {
          this.props.setData({ fileUrl: jsonResponse.directLink });
          this.setState({
            isUploading: false,
          });
        }
      })
      .catch(e => {
        this.setState({
          isUploading: false,
        });
      });
  };

  get isOdometerStartFieldErrorVisible() {
    return !this.props.disableOdometerDataField && !this.props.odometerStartData;
  }

  get isOdometerEndFieldErrorVisible() {
    return !this.props.disableOdometerDataField && !this.props.odometerEndData;
  }

  get isOdometerStartFieldValid() {
    if (!this.props.currentOdometerReading) {
      return true;
    }
    return this.props.odometerStartData >= Number(this.props.currentOdometerReading);
  }

  get isOdometerEndFieldValid() {
    if (!this.props.currentOdometerReading) {
      return this.props.odometerEndData >= this.props.odometerStartData;
    }
    return this.props.odometerEndData >= Number(this.props.currentOdometerReading);
  }

  render() {
    const {
      isShowUpload,
      isCustomer,
      isPickUp,
      setData,
      files,
      file,
      fileUrl,
      note,
      odometerStartData,
      odometerEndData,
      tripPhotos,
      existedPhotos,
      pickUpReading,
      dropOffReading,
      index,
      intl,
      type,
      odometerData,
      onNoteChange,
      onOdometerStartDataChange,
      onOdometerEndDataChange,
      disableOdometerDataField,
      isFuelIncluded,
      isRemarkVisible,
    } = this.props;

    const customerTripPhotos = existedPhotos.filter(
      photo => !!photo.isCustomer && photo[type] === true
    );
    const providerTripPhotos = existedPhotos.filter(
      photo => !photo.isCustomer && photo[type] === true
    );
    const showYourPhotos = isCustomer ? customerTripPhotos : providerTripPhotos;
    const hasJustUploadedTripPhotos = tripPhotos.filter(photo => isPickUp ? !!photo.isPickUp : !photo.isPickUp);
    const pickUpPhotos = showYourPhotos && showYourPhotos.length > 0 ? [...showYourPhotos] : [...hasJustUploadedTripPhotos];

    const odometerDataLabel = intl.formatMessage({ id: 'SectionUploadCarStatus.odometerDataLabel' });
    const odometerDataHint = intl.formatMessage({ id: 'SectionUploadCarStatus.odometerDataHint' });
    const odometerDataInvalidHint = intl.formatMessage({ id: 'SectionUploadCarStatus.odometerDataInvalidHint' });
    const uploadTextLabel = <FormattedMessage id="SectionUploadCarStatus.remarks" />;
    const uploadPlaceholder = intl.formatMessage({
      id: 'SectionUploadCarStatus.uploadPlaceholder',
    });

    const noteChange = (e) => {
      onNoteChange(e.target.value, type);
    }

    const fuelStartDataChange = (e) => {
      onOdometerStartDataChange(e.target.value, type);
    }

    const fuelEndDataChange = (e) => {
      onOdometerEndDataChange(e.target.value, type);
    }

    const handleOdometerKeyDown = (e) => {
      if(!((e.keyCode > 95 && e.keyCode < 106)
        || (e.keyCode > 47 && e.keyCode < 58)
        || e.keyCode == 8 || e.keyCode === 37
        || e.keyCode === 38 || e.keyCode === 39
        || e.keyCode === 40)) {
        e.preventDefault();
      }
    };

    const handleChangeOdometerStartField = (e) => {
      const value = e.target.value;
      if (value.length >= 7) {
        return ;
      }
      setData({ odometerStartData: Math.abs(value) });
    };

    const handleChangeOdometerEndField = (e) => {
      const value = e.target.value;
      if (value.length >= 7) {
        return ;
      }
      setData({ odometerEndData: Math.abs(value) });
    };

    return (
      <div className={css.content}>
        <div className={css.pickUpPhotosHolder}>
          <div className={classNames(css.uploadContainer, css.pickUpPhotosUploadContainer)}>
            <div className={css.uploadBox} onClick={this.handleClickUpload}>
              <div className={css.uploadBoxInner}>
                {file && this.state.isUploading && (
                  <div className={css.uploadLoading}>
                    <IconSpinner />
                  </div>
                )}
                {<IconCamera rootClassName={css.uploadIcon} />}
              </div>
            </div>
            <input
              accept={ACCEPT_TYPE}
              className={css.inputField}
              type="file"
              name="uploadPhotoStatus"
              id="uploadPhotoStatus"
              multiple={true}
              onChange={e => {
                if (e.target.files[0]) {
                  const files = Array.from(e.target.files).map((file, index) => ({ index, file }));
                  this.handleUploadFiles(files, this.props.currentUser);
                  setData({
                    file: Object.values(e.target.files).map(i => URL.createObjectURL(i)),
                    files: e.target.files,
                  });
                }
              }}
              ref={input => (this.inputElement = input)}
            />
          </div>

          {isCustomer && isPickUp && <div className={css.pickUpReading}>{pickUpReading}</div>}
          {isCustomer && !isPickUp && <div className={css.pickUpReading}>{dropOffReading}</div>}
          <div className={classNames(css.photosContainer, css.pickUpPhotosContainer)}>
            {pickUpPhotos.map(photo => (
              <div className={css.photoWrapper} key={photo.fileUrl}>
                <div className={css.photoWrapperInner}>
                  {photo.isJustUploaded && (
                    <div
                      className={css.removePhotoBtn}
                      onClick={() => this.handleRemovePhoto(photo.fileUrl)}
                    >
                      <IconClose size={'small'} />
                    </div>
                  )}
                  <img
                    src={photo.fileUrl}
                    className={css.tripPhoto}
                    onClick={() => window.open(photo.fileUrl, '_blank')}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        {isRemarkVisible && (
          <div>
            <label className={css.inputTextLabel}>{uploadTextLabel}</label>
            <input
              type="text"
              className={css.inputText}
              id="noteCarPhotoStatus"
              name="noteCarPhotoStatus"
              onChange={e => setData({ note: e.target.value })}
              onBlur={noteChange}
              value={note}
              placeholder={uploadPlaceholder}
              ref={input => (this.inputNote = input)}
            />
          </div>
        )}

        {isFuelIncluded && odometerData && isPickUp && <div>
          <label className={css.inputTextLabel}>{odometerDataLabel}</label>
          <input
            disabled={disableOdometerDataField}
            type="number"
            className={css.inputText}
            id="odometerStartData"
            name="odometerStartData"
            onChange={handleChangeOdometerStartField}
            onKeyDown={handleOdometerKeyDown}
            onBlur={fuelStartDataChange}
            value={odometerStartData || ''}
            placeholder={odometerDataLabel}
            ref={input => (this.inputNote = input)}
          />
          <FieldError visible={this.isOdometerStartFieldErrorVisible}>{odometerDataHint}</FieldError>
          <FieldError visible={this.props.odometerStartData && !this.isOdometerStartFieldValid}>{odometerDataInvalidHint}</FieldError>
        </div>}

        {isFuelIncluded && odometerData && !isPickUp && <div>
          <label className={css.inputTextLabel}>{odometerDataLabel}</label>
          <input
            disabled={disableOdometerDataField}
            type="number"
            className={css.inputText}
            id="odometerEndData"
            name="odometerEndData"
            onChange={handleChangeOdometerEndField}
            onKeyDown={handleOdometerKeyDown}
            onBlur={fuelEndDataChange}
            value={odometerEndData || ''}
            placeholder={odometerDataLabel}
            ref={input => (this.inputNote = input)}
          />
          <FieldError visible={this.isOdometerEndFieldErrorVisible}>{odometerDataHint}</FieldError>
          <FieldError visible={this.props.odometerEndData && !this.isOdometerEndFieldValid}>{odometerDataInvalidHint}</FieldError>
        </div>}

        {this.state.uploadFileError ? (
          <p className={css.uploadError}>
            <FormattedMessage id="SectionUploadCarStatus.uploadError" />
          </p>
        ) : null}
      </div>
    );
  }
}

class SectionCarPhotos extends Component {
  constructor(props) {
    super(props);
    const existedPhotos = this.getExistedPhotos(props);
    const tripPhotos = this.getTripPhotos(props);
    this.state = {
      files: [],
      file: null,
      fileUrl: '',
      note: (tripPhotos[0] && tripPhotos[0].note) || (existedPhotos[0] && existedPhotos[0].note),
      odometerStartData: null,
      odometerEndData: null,
    };
  }

  handleUploadPhoto = data => {
    this.props.setTripPhotos([...this.props.tripPhotos, ...data ]);
  };

  handleRemovePhoto = fileUrl => {
    const newTripPhotos = this.props.tripPhotos.filter(photo => photo.fileUrl !== fileUrl);
    this.props.setTripPhotos([...newTripPhotos]);
  };

  getExistedPhotos(props) {
    return props.existedPhotos.filter(i => props.type in i);
  }

  getTripPhotos(props) {
    return props.tripPhotos.map(item => {
      return { ...item, isJustUploaded: true };
    });
  }

  render() {
    const {
      currentOdometerReading,
      rootClassName,
      className,
      isCustomer,
      isProvider,
      isPickUp,
      isShowUpload,
      intl,
      onManageDisableScrolling,
      currentUser,
      tripPhotos,
      existedPhotos,
      inputOdometerInvalid,
      pickUpReading,
      dropOffReading,
      onClick,
      holderClassName,
      title,
      description,
      type,
      odometerData,
      onNoteChange,
      onOdometerStartDataChange,
      onOdometerEndDataChange,
      activeSection,
      disableOdometerDataField,
      id,
      isRemarkVisible,
      isFuelIncluded,
      odometerStartData,
      odometerEndData,
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);

    return (
      <div className={classNames(classes, holderClassName)}>
        <div onClick={onClick} className={classNames(css.sectionPhotoHolder)}>
          <div className={css.tickContainer}>
            <img src={activeSection ? tick : disabledTick} alt="tick" className={css.tick} />
          </div>

          <div>
            <h2 className={css.photoTitle}>{title}</h2>
            <p className={css.photoDescription}>{description}</p>
          </div>
        </div>
        <UploadSection
          currentOdometerReading={currentOdometerReading}
          isShowUpload={isShowUpload}
          isCustomer={isCustomer}
          isProvider={isProvider}
          isPickUp={isPickUp}
          onManageDisableScrolling={onManageDisableScrolling}
          setData={data => this.setState(data)}
          files={this.state.files}
          file={this.state.file}
          fileUrl={this.state.fileUrl}
          note={this.state.note}
          odometerStartData={this.state.odometerStartData || odometerStartData}
          odometerEndData={this.state.odometerEndData || odometerEndData}
          setActivePhotoSection={this.props.setActivePhotoSection}
          handleAddPhoto={this.handleUploadPhoto}
          handleRemovePhoto={this.handleRemovePhoto}
          currentUser={currentUser}
          tripPhotos={this.getTripPhotos(this.props)}
          existedPhotos={this.getExistedPhotos(this.props)}
          inputOdometerInvalid={inputOdometerInvalid}
          pickUpReading={pickUpReading}
          dropOffReading={dropOffReading}
          intl={intl}
          type={type}
          odometerData={odometerData}
          onNoteChange={onNoteChange}
          onOdometerStartDataChange={onOdometerStartDataChange}
          onOdometerEndDataChange={onOdometerEndDataChange}
          disableOdometerDataField={disableOdometerDataField}
          id={id}
          isRemarkVisible={isRemarkVisible}
          isFuelIncluded={isFuelIncluded}
        />
      </div>
    );
  }
}

SectionCarPhotos.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionCarPhotos.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionCarPhotos;
