import isEqual from 'lodash/isEqual';
import moment from 'moment';
import React, { memo } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { daysBetween, displayDateForUser } from '../../util/dates';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, LINE_ITEM_UNITS, propTypes } from '../../util/types';
import css from './BookingBreakdownNew.css';

const BookingPeriod = props => {
  const { isSingleDay, startDate, endDate, timeZone } = props;
  const dateFormatOptions = {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
  };

  const timeZoneMaybe = timeZone ? { timeZone } : null;

  if (isSingleDay) {
    return <FormattedDate value={startDate} {...dateFormatOptions} {...timeZoneMaybe} />;
  }

  return (
    <FormattedMessage
      id="BookingBreakdown.bookingPeriod"
      values={{
        bookingStart: (
          <span className={css.nowrap}>
            <FormattedDate value={startDate} {...dateFormatOptions} {...timeZoneMaybe} />
          </span>
        ),
        newLine: <br />,
        bookingEnd: (
          <span className={css.nowrap}>
            <FormattedDate value={endDate} {...dateFormatOptions} {...timeZoneMaybe} />
          </span>
        ),
      }}
    />
  );
};

const LineItemBookingPeriodNew = memo(
  props => {
    const { booking, unitType, timezone, transaction } = props;
    let startDate = '', endDateRaw = '', displayStart = '', displayEnd = '';

    if(booking && booking.attributes) {
      startDate = booking.attributes.start;
      endDateRaw = booking.attributes.end;
      displayStart = booking.attributes.displayStart;
      displayEnd = booking.attributes.displayEnd;
    } else {
      startDate = transaction.attributes.protectedData && transaction.attributes.protectedData.displayBookingDateStart ? transaction.attributes.protectedData.displayBookingDateStart : null;
      endDateRaw = transaction.attributes.protectedData && transaction.attributes.protectedData.displayBookingDateEnd ? transaction.attributes.protectedData.displayBookingDateEnd : null;
      displayStart = transaction.attributes.protectedData && transaction.attributes.protectedData.displayBookingDateStart ? transaction.attributes.protectedData.displayBookingDateStart : null;
      displayEnd = transaction.attributes.protectedData && transaction.attributes.protectedData.displayBookingDateEnd ? transaction.attributes.protectedData.displayBookingDateEnd : null;
    }

    const currentBookingStart = transaction.attributes.protectedData && transaction.attributes.protectedData.displayBookingDateStart ? transaction.attributes.protectedData.displayBookingDateStart : null;
    const currentBookingEnd = transaction.attributes.protectedData && transaction.attributes.protectedData.displayBookingDateEnd ? transaction.attributes.protectedData.displayBookingDateEnd : null;


    const localStartDate = displayDateForUser(startDate);
    const localEndDateRaw = displayDateForUser(endDateRaw);

    const realStartDate = currentBookingStart ? moment(currentBookingStart).toDate() : moment(displayStart).toDate();
    const realEndDate = currentBookingEnd ? moment(currentBookingEnd).toDate() : moment(displayEnd).toDate();

    const isNightly = unitType === LINE_ITEM_NIGHT;
    const isDaily = unitType === LINE_ITEM_DAY;
    const isUnits = unitType === LINE_ITEM_UNITS;

    const dayCount = daysBetween(localStartDate, localEndDateRaw);
    const isSingleDay = !isNightly && !isUnits && dayCount === 1;
    const endDay = isNightly ? localEndDateRaw : moment(localEndDateRaw).subtract(1, 'days');

    return (
      <div className={css.lineItem}>
        <span className={css.bookingPeriodText}>
          <BookingPeriod
            isSingleDay={isSingleDay}
            startDate={realStartDate}
            endDate={realEndDate}
            timeZone={timezone}
          />
        </span>
      </div>
    );
  },
  (prev, next) => isEqual(prev.booking, next.booking)
);

LineItemBookingPeriodNew.propTypes = {
  transaction: propTypes.transaction.isRequired,
  booking: propTypes.booking.isRequired,
};

export default LineItemBookingPeriodNew;
