import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { propTypes } from '../../util/types';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import {
  Footer,
  LayoutSideNavigation,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  Page,
  UserNav,
} from '../../components';
import config from '../../config';
import { PasswordChangeForm } from '../../forms';
import { TopbarContainer } from '../../containers';

import {
  changePassword,
  changePasswordClear,
  sendInstructionToEmail,
} from './PasswordChangePage.duck';
import css from './PasswordChangePage.css';
import { getConnectCalendarTabStatus } from '../../ducks/TabPanels.duck';
import { getEventsByBucket } from '../../util/gtm/gtmCreateProperties';
import { event_trigger_ids } from '../../util/analyticsConstants';
import { triggerAnalyticsEvent } from '../../util/amplitudeMapEvents';

export const PasswordChangePageComponent = props => {
  const {
    changePasswordError,
    changePasswordInProgress,
    currentUser,
    onChange,
    onSubmitChangePassword,
    passwordChanged,
    scrollingDisabled,
    intl,
    connectCalendarTabVisibility,
    fetchCalendarVisibility,
    onSendInstruction,
    sendInstructionSuccess,
    sendInstructionInProgress,
    sendInstructionError,
  } = props;

  useEffect(() => {
    fetchCalendarVisibility();
  }, []);
  
  const [eventData, setEventData] = useState(null);

  useEffect(() => {
    // Function to fetch events and store in state
    const fetchEvents = async () => {
      try {
        const response = await getEventsByBucket('browsing');
        setEventData(response.data); // Store response in state
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };

    // Call fetchEvents when component mounts
    fetchEvents();
  }, []); // Empty dependency array ensures fetchEvents runs only once


  let tabs = [...config.custom.tabs];

  tabs = tabs
    .map(tab => ({ ...tab }))
    .filter(tab => {
      if (tab.linkProps.name === 'SyncCalenderPage') {
        return Boolean(connectCalendarTabVisibility);
      }
      return true;
    });
  const currentTab = tabs.find(tab => tab.linkProps.name === 'PasswordChangePage');

  if (currentTab) {
    currentTab.selected = true;
  }

  const isCreatedViaForm = get(
    currentUser,
    'attributes.profile.privateData.isCreatedViaForm',
    false
  );
  const isCreatedViaSocial = get(
    currentUser,
    'attributes.profile.privateData.isCreatedViaSocial',
    false
  );

  const isShowChangePasswordForm = isCreatedViaForm || isCreatedViaSocial;
  const isOldRegisteredUser = !isCreatedViaForm && !isCreatedViaSocial;
  const isShowGoogleText =
    get(currentUser, 'attributes.identityProviders[0].idpId', null) === 'google';
  const isShowFacebookText =
    get(currentUser, 'attributes.identityProviders[0].idpId', null) === 'facebook';
  const isShowAppleText =
    get(currentUser, 'attributes.identityProviders[0].idpId', null) === config.appleIdpId;
  const email = get(currentUser, 'attributes.email', null);

  const handleSubmit = () => {
    onSendInstruction({ email });
  };

  const successMessage = (
    <div className={css.successText}>
      <FormattedMessage id="PasswordChangePage.registeredWithSocialSuccessMessage" />
    </div>
  );

  const errorMessage = (
    <div className={css.errorText}>
      <FormattedMessage id="PasswordChangePage.registeredWithSocialErrorMessage" />
    </div>
  );

  const sendingMessage = (
    <div className={css.successText}>
      <FormattedMessage id="PasswordChangePage.sendingText" />
    </div>
  );

  const showMessage = sendInstructionInProgress
    ? sendingMessage
    : sendInstructionSuccess
    ? successMessage
    : sendInstructionError
    ? errorMessage
    : null;

  const changePasswordForm = isShowChangePasswordForm ? (
    <PasswordChangeForm
      className={css.form}
      changePasswordError={changePasswordError}
      currentUser={currentUser}
      onSubmit={values => onSubmitChangePassword(values, eventData)}
      onChange={onChange}
      inProgress={changePasswordInProgress}
      ready={passwordChanged}
    />
  ) : isShowGoogleText ? (
    <h2 className={css.title}>
      <FormattedMessage
        id="PasswordChangePage.registeredWithGoogleMessage"
        values={{
          link: (
            <span className={css.link} onClick={() => handleSubmit()}>
              send the reset password instructions.
            </span>
          ),
        }}
      />
    </h2>
  ) : isShowFacebookText ? (
    <h2 className={css.title}>
      <FormattedMessage
        id="PasswordChangePage.registeredWithFacebookMessage"
        values={{
          link: (
            <span className={css.link} onClick={() => handleSubmit()}>
              send the reset password instructions.
            </span>
          ),
        }}
      />
    </h2>
  ) : isShowAppleText ? (
    <h2 className={css.title}>
      <FormattedMessage
        id="PasswordChangePage.registeredWithAppleMessage"
        values={{
          link: (
            <span className={css.link} onClick={() => handleSubmit()}>
              send the reset password instructions.
            </span>
          ),
        }}
      />
    </h2>
  ) : isOldRegisteredUser ? (
    <PasswordChangeForm
      className={css.form}
      changePasswordError={changePasswordError}
      currentUser={currentUser}
      onSubmit={values => onSubmitChangePassword(values, eventData)}
      onChange={onChange}
      inProgress={changePasswordInProgress}
      ready={passwordChanged}
    />
  ) : null;

  const title = intl.formatMessage({ id: 'PasswordChangePage.title' });

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            currentPage="PasswordChangePage"
            desktopClassName={css.desktopTopbar}
            mobileClassName={css.mobileTopbar}
          />
          <UserNav selectedPageName="PasswordChangePage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperSideNav tabs={tabs} user={currentUser} />
        {/* <LayoutWrapperAccountSettingsSideNav currentTab="PasswordChangePage" /> */}
        <LayoutWrapperMain>
          <div className={css.content}>
            <h1 className={css.title}>
              <FormattedMessage id="PasswordChangePage.heading" />
            </h1>
            {changePasswordForm}
            {showMessage}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

PasswordChangePageComponent.defaultProps = {
  changePasswordError: null,
  currentUser: null,
};

const { bool, func } = PropTypes;

PasswordChangePageComponent.propTypes = {
  changePasswordError: propTypes.error,
  changePasswordInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  onChange: func.isRequired,
  onSubmitChangePassword: func.isRequired,
  passwordChanged: bool.isRequired,
  scrollingDisabled: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  // Topbar needs user info.
  const {
    changePasswordError,
    changePasswordInProgress,
    passwordChanged,
    sendInstructionSuccess,
    sendInstructionInProgress,
    sendInstructionError,
  } = state.PasswordChangePage;
  const { connectCalendarTabVisibility } = state.tabPanels;
  const { currentUser } = state.user;
  return {
    changePasswordError,
    changePasswordInProgress,
    currentUser,
    passwordChanged,
    scrollingDisabled: isScrollingDisabled(state),
    connectCalendarTabVisibility,
    sendInstructionSuccess,
    sendInstructionInProgress,
    sendInstructionError,
  };
};

const mapDispatchToProps = dispatch => ({
  onSendInstruction: params => dispatch(sendInstructionToEmail(params)),
  onChange: () => dispatch(changePasswordClear()),
  // onSubmitChangePassword: (values, eventData) => (dispatch, getState) => {
  //   console.log("onSubmitChangePassword UUUUUUUU");
  //   return new Promise((resolve, reject) => {
      
  //     const state = getState();
  //     const currentUser = state.user.currentUser;
  //     try {
  //       triggerAnalyticsEvent({
  //         event_id: event_trigger_ids.PROFILE_CHANGE_PASSWORD_SAVED,
  //         eventData: eventData,
  //         props: {
  //           button: 'Change Password',
  //           page: 'ChangePasswordScreen'
  //         },
  //         userId: currentUser ? currentUser.id.uuid : null,
  //       });
  //     } catch (error) {
  //       console.error('Error triggering analytics event:', error);
  //     }
  //     dispatch(changePassword(values))
  //       .then(resolve)
  //       .catch(reject);
  //   });
  // },
  // onSubmitChangePassword: (values, eventData) => (dispatch, getState) => {
  //   console.log("onSubmitChangePassword UUUUUUUU")
  //   const state = getState();
  //   const currentUser = state.user.currentUser;
  //   try {
  //     triggerAnalyticsEvent({
  //       event_id: event_trigger_ids.PROFILE_CHANGE_PASSWORD_SAVED,
  //       eventData: eventData,
  //       props: {
  //         button: 'Change Password',
  //         page: 'ChangePasswordScreen'
  //       },
  //       userId: currentUser ? currentUser.id.uuid : null,
  //     });
  //   } catch (error) {
  //     console.error('Error triggering analytics event:', error);
  //   }
  //   dispatch(changePassword(values));
  // },
  onSubmitChangePassword: (values, eventData) => dispatch(changePassword(values, eventData)),
  fetchCalendarVisibility: () => dispatch(getConnectCalendarTabStatus()),
});

const PasswordChangePage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(PasswordChangePageComponent);

export default PasswordChangePage;
