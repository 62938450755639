export const getAllowedMileagePerDay = (hours) => {
  const hoursInt = hours;
  let allowedMileagePerDay = 50;

  if (hoursInt >= 12) {
    allowedMileagePerDay = 125;
  } else if (hoursInt > 8) {
    allowedMileagePerDay = 100;
  } else if (hoursInt > 4) {
    allowedMileagePerDay = 75;
  }

  // console.log("Allowed Mileage Per Day:", allowedMileagePerDay, hoursInt);
  return allowedMileagePerDay;
};

export const getExtraMileagePrice = (hours, days, selectedMileage) => {

  const totalDays = days;
  const allowedMileagePerDay = getAllowedMileagePerDay(hours);
  const payableKmsPerDay = selectedMileage - allowedMileagePerDay;
  const pricePerKms = parseFloat(0.29 * payableKmsPerDay * totalDays).toFixed(2);
  return pricePerKms;
};

// export const sliderOptionsList = totalTripHours => {
//   console.log("total trip hours", totalTripHours)
//   let defaultValue = getAllowedMileagePerDay(totalTripHours);
//   console.log("Default value", defaultValue)
//   let sliderOptionsObj = {
//     min: 0,
//     defaultValue,
//   };
//   let max;
//   let sliderList;

//   if (totalTripHours <= 4) {
//     max = 125;
//     sliderList = [
//       { slideValue: 0, indexMileageValue: 50 },
//       { slideValue: 2, indexMileageValue: 75 },
//       { slideValue: 4, indexMileageValue: 100 },
//       { slideValue: 6, indexMileageValue: 125 }
//     ];
//   } else if (4 < totalTripHours <= 8) {
//     max = 150;
//     sliderList = [
//       { slideValue: 0, indexMileageValue: 75 },
//       { slideValue: 2, indexMileageValue: 100 },
//       { slideValue: 4, indexMileageValue: 125 },
//       { slideValue: 6, indexMileageValue: 150 }
//     ];
//   } else if (8 < totalTripHours <= 12) {
//     max = 250;
//     sliderList = [
//       { slideValue: 0, indexMileageValue: 100 },
//       { slideValue: 2, indexMileageValue: 150 },
//       { slideValue: 4, indexMileageValue: 200 },
//       { slideValue: 6, indexMileageValue: 250 }
//     ];
//   } else {
//     max = 300;
//     sliderList = [
//       { slideValue: 0, indexMileageValue: 125 },
//       { slideValue: 2, indexMileageValue: 200 },
//       { slideValue: 4, indexMileageValue: 250 },
//       { slideValue: 6, indexMileageValue: 300 }
//     ];
//   }

//   sliderOptionsObj.sliderList = sliderList;
//   sliderOptionsObj.max = max;

//   return sliderOptionsObj;
// };

export const sliderOptionsList = totalTripHours => {
  // console.log("Total trip hours", totalTripHours)
  const sliderListByHours = [
    {
      maxHours: 4,
      max: 125,
      sliderList: [
        { slideValue: 0, indexMileageValue: 50 },
        { slideValue: 25, indexMileageValue: 75 },
        { slideValue: 50, indexMileageValue: 100 },
        { slideValue: 75, indexMileageValue: 125 },
      ],
    },
    {
      maxHours: 8,
      max: 150,
      sliderList: [
        { slideValue: 0, indexMileageValue: 75 },
        { slideValue: 25, indexMileageValue: 100 },
        { slideValue: 50, indexMileageValue: 125 },
        { slideValue: 75, indexMileageValue: 150 },
      ],
    },
    {
      maxHours: 12,
      max: 250,
      sliderList: [
        { slideValue: 0, indexMileageValue: 100 },
        { slideValue: 25, indexMileageValue: 150 },
        { slideValue: 50, indexMileageValue: 200 },
        { slideValue: 75, indexMileageValue: 250 },
      ],
    },
    {
      maxHours: 300000000000000000000,
      max: 300,
      sliderList: [
        { slideValue: 0, indexMileageValue: 125 },
        { slideValue: 25, indexMileageValue: 200 },
        { slideValue: 50, indexMileageValue: 250 },
        { slideValue: 75, indexMileageValue: 300 },
      ],
    },
  ];

  const { max, sliderList } = sliderListByHours.find(
    ({ maxHours }) => maxHours === 12 ?  totalTripHours < maxHours : totalTripHours <= maxHours
  );

  const defaultValue = getAllowedMileagePerDay(totalTripHours);

  // console.log("Max value", defaultValue)
  return {
    min: 0,
    max,
    defaultValue,
    sliderList,
  };
};

export const calculateAllowedMillage = ({ totalTripHours, sliderOptions, millagePerDayOld, millagePerDay}) => {
  
  if(totalTripHours > 8) {
    return millagePerDayOld || millagePerDay
  } else {
    if((millagePerDayOld || millagePerDay) === 999 || (millagePerDayOld || millagePerDay) === "Unlimited" || (millagePerDayOld || millagePerDay) === "unlimited") {
      return "Unlimited"
    }
    let dailyKm = Number(millagePerDayOld) || Number(millagePerDay) || 125
    dailyKm = (dailyKm / 12.5) * totalTripHours
    // console.log("Total Trip Hours: XXXXXXX ", totalTripHours, dailyKm);
    return dailyKm
  }
}

// export const getMileageValuefromSliderValue = (sliderValue, totalTripHours) => {

//   const sliderOptionsObj = sliderOptionsList(totalTripHours);
//   const sliderValuesList = sliderOptionsObj.sliderList;
//   const sliderItem = sliderValuesList.find(item => item.slideValue >= sliderValue);
//   return sliderItem;
// };

// export const getMileageValuefromSliderValue = (sliderValue, totalTripHours, oldValue) => {
//   // Call sliderOptionsList with totalTripHours
//   const sliderOptionsObj = sliderOptionsList(totalTripHours);

//   // Extract sliderList from the options object
//   const sliderValuesList = sliderOptionsObj.sliderList;

//   console.log("Slider options list: ", sliderValue, totalTripHours, oldValue, sliderValuesList);

//   // If the slider value is increasing
//   if (sliderValue >= oldValue) {
//     // Find the next slider item with a slide value greater than the given sliderValue
//     const nextSliderItem = sliderValuesList.find(item => item.slideValue >= sliderValue);

//     // If next slider item is found, return it
//     if (nextSliderItem) {
//       return nextSliderItem;
//     }
//     // If no next slider item is found, return null or handle as needed
//     else {
//       // Handle the case where the slider value is already the highest
//       // You can return null or handle it based on your requirements
//       return null;
//     }
//   }
//   // If the slider value is decreasing
//   else if (oldValue >= sliderValue) {
//     // Find the previous slider item with a slide value less than the given sliderValue
//     console.log("Going in decreasing order", sliderValuesList, sliderValuesList.reverse());
//     const prevSliderItem = sliderValuesList.reverse().find(item => oldValue >= item.slideValue && item.slideValue <= sliderValue);

//     // If previous slider item is found, return it
//     if (prevSliderItem) {
//       return prevSliderItem;
//     }
//     // If no previous slider item is found, return null or handle as needed
//     else {
//       // Handle the case where the slider value is already the lowest
//       // You can return null or handle it based on your requirements
//       return null;
//     }
//   }
//   // If the slider value remains unchanged, return null or handle as needed
//   else {
//     // Handle the case where the slider value remains unchanged
//     // You can return null or handle it based on your requirements
//     return null;
//   }
// };

export const getMileageValuefromSliderValue = (sliderValue, totalTripHours, oldValue) => {
  // Call sliderOptionsList with totalTripHours
  const sliderOptionsObj = sliderOptionsList(totalTripHours);

  // Extract sliderList from the options object
  const sliderValuesList = sliderOptionsObj.sliderList;

  // console.log("Slider options list: ", sliderValue, totalTripHours, oldValue, sliderValuesList);

  // Find the next slider item with a slide value greater than or equal to the given sliderValue
  const nextSliderItem = sliderValuesList.find(item => item.slideValue >= sliderValue);

  // Find the previous slider item with a slide value less than or equal to the given sliderValue
  const prevSliderItem = sliderValuesList.reduce((prev, curr) => {
    return curr.slideValue <= sliderValue && curr.slideValue > prev.slideValue ? curr : prev;
  }, { slideValue: -Infinity });

  // If the slider value is increasing and a next slider item is found, return it
  if (sliderValue >= oldValue && nextSliderItem) {
    return nextSliderItem;
  }

  // If the slider value is decreasing
  if (sliderValue < oldValue) {
    // If a previous slider item is found, return it
    if (prevSliderItem.slideValue !== -Infinity) {
      return prevSliderItem;
    }
    // If no previous slider item is found and sliderValue is greater than 0, return the item with slide value 0
    else if (sliderValue > 0) {
      return sliderValuesList.find(item => item.slideValue === 0);
    }
    // If sliderValue is less than or equal to 0, return the first item in the slider list
    else {
      return sliderValuesList[0];
    }
  }

  // Handle cases where no next or previous slider item is found, return null or handle as needed
  return null;
};



export const getMileageValuefromSliderMileageValue = (indexMileageValue, totalTripHours) => {
  // Call sliderOptionsList with totalTripHours
  const sliderOptionsObj = sliderOptionsList(totalTripHours);

  // Extract sliderList from the options object
  const sliderValuesList = sliderOptionsObj.sliderList;

  // console.log("Slider value", sliderOptionsObj, indexMileageValue);

  // Find the slider item with the given indexMileageValue
  const sliderItem = sliderValuesList.find(item => item.indexMileageValue === indexMileageValue);

  // If no slider item is found, return the first item in the slider list
  if (!sliderItem) {
    return sliderValuesList[0];
  }

  // Return the found slider item
  return sliderItem;
};

