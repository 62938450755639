import React, { memo } from 'react';
import { array, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import css from './SectionRulesMaybe.css';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';

const SectionSpecificInstructionsMaybe = memo(
  props => {
    const { className, rootClassName, titleClassName, subTitle, title, listing, field } = props;
    const classes = classNames(rootClassName || css.root, className);
    const fieldValues = (listing.attributes.publicData || {})[field];
    return fieldValues ? (
      <div className={classes}>
        <h2 className={classNames(css.title, titleClassName || css.null)}>
          <FormattedMessage id={title} />
        </h2>
        {subTitle && (
          <p className={css.subTitle}>
            <FormattedMessage id={subTitle} />
          </p>
        )}
        <div className={css.rule}>{`${fieldValues}`}</div>
      </div>
    ) : null;
  },
  (prev, next) => {
    return isEqual(
      pick(prev, ['title', 'field', 'listing']),
      pick(next, ['title', 'field', 'listing'])
    );
  }
);

SectionSpecificInstructionsMaybe.defaultProps = { className: null, rootClassName: null };

SectionSpecificInstructionsMaybe.propTypes = {
  className: string,
  rootClassName: string,
  // publicData: shape({
  //   rules: string,
  // }).isRequired,
  keyRules: array,
};

export default SectionSpecificInstructionsMaybe;
