import React from 'react';
import css from './ListingPageGo.css';
import config from '../../../config';
import { PropertyList } from '../../../components';
import { FormattedMessage } from 'react-intl';

const SectionDescriptionMaybe = props => {
  const { authorDisplayName, listing } = props;
  const { brandName, transmissions, fuelType, peopleNumber: seats, drivenKm } =
    listing.attributes.publicData || {};

  const seatsLabel = typeof seats === "string" || typeof seats === "number" ? `${seats || '0'} Seats` : seats && seats.length > 0 ? `${seats ? seats[0] : '0'} - ${seats ? seats[1] : '0'} Seats` : '';

  let options = [
    ...config.custom.brands,
    ...config.custom.transmissions,
    ...config.custom.fuelTypes,
    { key: 'numberSeats', label: seatsLabel },
    ...config.custom.drivenKm,
  ];

  const selectedOptions = [
    brandName || '',
    ...(transmissions || ''),
    fuelType || '',
    'numberSeats',
    drivenKm || '',
  ];

  options = options.filter(option => {
    return selectedOptions.filter(select => select === option.key).length > 0;
  });

  return (
    <div className={css.section}>
      <div className={css.sectionTitle}>
        <FormattedMessage
          id="ListingPageGo.SectionDescriptionMaybe.title"
          values={{ authorDisplayName }}
        />
      </div>
      <PropertyList id="descriptionMaybe" list={options} />
    </div>
  );
};

export default SectionDescriptionMaybe;
