import React from 'react';
import get from 'lodash/get';
import { bool } from 'prop-types';
import { intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import {
  LINE_ITEM_CUSTOMER_COMMISSION,
  LINE_ITEM_PROCESSING_FEE,
  propTypes,
} from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import { txGetPromoLineItem, txGetPromoLineItemsTotal, txIsCanceled, txIsCanceledByCustomer } from '../../util/transaction';

import css from './BookingBreakdown.css';

const { Money } = sdkTypes;

const LineItemUnitPrice = props => {
  const {
    updateBookingTx,
    transaction,
    isProvider,
    intl,
    isAddons,
    isTripDetailsPage,
  } = props;

  if (!transaction) {
    return null;
  }

  const updatedBookingPayinTotal = get(updateBookingTx, 'attributes.payinTotal', null);
  const updateBookingLineItems = get(updateBookingTx, 'attributes.lineItems', []);

  const customerCommissionLineItem = updateBookingLineItems.find(
    item => item.code === LINE_ITEM_CUSTOMER_COMMISSION
  );
  const processingFeeLineItem = updateBookingLineItems.find(
    item => item.code === LINE_ITEM_PROCESSING_FEE
  );

  const promoItem = txGetPromoLineItemsTotal(transaction);

  const promoAmount = promoItem && !txIsCanceled(transaction) ? promoItem : 0;
  const processingFees = get(
    transaction,
    'attributes.protectedData.commissionObj.commission.processingFees',
    0
  );
  const payoutTotal = transaction.attributes.payoutTotal;
  const totalPrice = isProvider
    ? payoutTotal
    : txIsCanceledByCustomer(transaction) ?
     new Money(0, payoutTotal.currency)
    : transaction.attributes.payinTotal;

  const finalTotalPrice = isProvider
    ? new Money(totalPrice.amount + (promoAmount || 0), totalPrice.currency)
    : totalPrice;

  const finalTotalPriceWithUpdatedBooking = updatedBookingPayinTotal !== null  && isProvider
    ? new Money((finalTotalPrice.amount + ((updatedBookingPayinTotal - processingFeeLineItem.unitPrice - customerCommissionLineItem.unitPrice)*100)), finalTotalPrice.currency)
    : updatedBookingPayinTotal !== null  && !isProvider
    ? new Money((finalTotalPrice.amount + (updatedBookingPayinTotal*100)), finalTotalPrice.currency)
    : finalTotalPrice;

  const formattedTotalPrice = isTripDetailsPage
    ? formatMoney(intl, finalTotalPrice, 1)
    : formatMoney(intl, finalTotalPriceWithUpdatedBooking, 1);

  return (
    <div className={css.lineItemTotal}>
      <div className={css.totalLabel}>{isAddons ? 'Total price' : isTripDetailsPage && isProvider ? 'You Earn' : 'Total trip price'}</div>
      <div className={css.totalPrice}>{formattedTotalPrice}</div>
    </div>
  );
};

LineItemUnitPrice.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isProvider: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemUnitPrice;
