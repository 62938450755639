import classNames from 'classnames';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  Footer,
  LayoutSideNavigation,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  LottieElementPage,
  NamedRedirect,
  Page,
  UserNav,
  UserVerificationStatusIndicator,
  UserVerificationWithOnfido,
  VerificationLanding,
} from '../../components';
import config from '../../config';
import { TopbarContainer } from '../../containers';
import { getConnectCalendarTabStatus } from '../../ducks/TabPanels.duck';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { fetchCurrentUser, updateUserData } from '../../ducks/user.duck';
import { ensureCurrentUser, ensureHostVerified } from '../../util/data';
import { EVENT_GUEST_VERIFY_OPEN_DRIVER_FORM } from '../../util/gtm/gtmConstants';
import { pushGTMGuestVerify } from '../../util/gtm/gtmHelpers';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { types as sdkTypes } from '../../util/sdkLoader';
import { propTypes } from '../../util/types';
import css from './AccountSettingDriverVerificationPage.css';
import { getActiveTrips } from '../TransactionPage/TransactionPage.duck';
import { triggerAnalyticsEvent } from '../../util/amplitudeMapEvents';
import { getEventsByBucket } from '../../util/gtm/gtmCreateProperties';
import { event_trigger_ids } from '../../util/analyticsConstants';

const { LatLng } = sdkTypes;

class AccountSettingDriverVerificationPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allowNavigateToHome: false,
      continueVerification: false,
      verificationFromSingpass:
        this.props.pageName && this.props.pageName === 'verificationPage' ? false : true,
      showSteps: false,
      step: {
        stepNum: 0,
        stepTitle: '',
      },

      isVerificationModalOpen: false,
    };
    this.watchRedirectTimeout = null;
  }

  async componentDidMount() {
    const { currentUser } = this.props;
    // fetchCalendarVisibility();

    if (currentUser) {
      // 
      // if(this.props.isHost) {
      //   try {
      //     const response = await getEventsByBucket('trip');
      //     triggerAnalyticsEvent({
      //       event_id: event_trigger_ids.HOSTVERIFY_OPEN_FORM,
      //       eventData: response.data,
      //       props: {
      //         guest: currentUser,
      //         ui: {
      //           page: 'IdVerificationScreen',
      //           button: "Host verification"
      //        }
      //       },
      //       userId: currentUser ? currentUser.id.uuid : null,
      //     });
          
      //   } catch (error) {
          
      //   }
      // }

      // pushGTMGuestVerify({
      //   props: this.props,
      //   event: EVENT_GUEST_VERIFY_OPEN_DRIVER_FORM,
      // });
    }
    if(window) {
      window.scrollTo(0, 0)
    }
  }

  redirectToHomePage = () => {
    this.setState({
      allowNavigateToHome: true,
    });
  };

  componentWillUnmount() {
    clearTimeout(this.watchRedirectTimeout);
  }

  render() {
    const {
      currentUser,
      scrollingDisabled,
      intl,
      connectCalendarTabVisibility,
      isFromTripPage,
      pageName,
      manageDisableSidebar,
    } = this.props;



    let tabs = [...config.custom.tabs];
    const user = ensureCurrentUser(currentUser);
    tabs = tabs
      .map(tab => ({ ...tab }))
      .filter(tab => {
        if (!(user && ensureHostVerified(user))) {
          if (tab.linkProps.name === 'MonthlyStatementPage') {
            return false;
          }
        }
        if (tab.linkProps.name === 'SyncCalenderPage') {
          return Boolean(connectCalendarTabVisibility);
        }
        return true;
      });
    const currentTab = tabs.find(
      tab => tab.linkProps.name === 'AccountSettingDriverVerificationPage'
    );
    if (currentTab) {
      currentTab.selected = true;
    }

    const unverifiedLayout = this.state.verificationFromSingpass ? (
      <VerificationLanding
        intl={intl}
        key={"Landing page"}
        currentUser={currentUser}
        verificationFromTripPanel={this.props.verificationFromTripPanel}
        selectVerificationProcess={ async () => {
          // console.log("Verification with verification")
          this.setState({
            verificationFromSingpass: false,
          });

          try {
            if(this.props.isHost) {
              const response = await getEventsByBucket('hostverify');
              triggerAnalyticsEvent({
                event_id: event_trigger_ids.HOSTVERIFY_OPEN_FORM,
                eventData: response.data,
                props: {
                  guest: currentUser,
                  ui: {
                    page: 'IdVerificationScreen',
                    button: "Host verification"
                    }
                },
                userId: currentUser ? currentUser.id.uuid : null,
              });
            }
            else {
              const response = await getEventsByBucket('guestverify');
              // console.log('Events fetched successfully:', response.data);
              triggerAnalyticsEvent({
                event_id: event_trigger_ids.GUEST_VERIFY_OPEN_FORM,
                eventData: response.data,
                props: {
                  guest: currentUser,
                  ui: {
                    page: 'IdVerificationScreen',
                    button: "Guest verification"
                    }
                },
                user: currentUser,
                userId: currentUser ? currentUser.id.uuid : null
              });
            }
            // pushGTMVerifiedEmailPhone(properties, EVENT_SIGNUP_VERIFIED_EMAIL);
          } catch (error) {
            console.error('Error fetching events or triggering GTM event:', error);
          }
        }}
      />
    ) : (
      <UserVerificationWithOnfido
        selectVerificationProcess={ async () => {
          this.setState({
            verificationFromSingpass: false,
          });
          if(this.props.isHost) {

          }
          else {
            try {
              const response = await getEventsByBucket('guestverify');
              // console.log('Events fetched successfully:', response.data);
              triggerAnalyticsEvent({
                event_id: event_trigger_ids.GUEST_VERIFY_OPEN_FORM,
                eventData: response.data,
                props: {
                  guest: currentUser
                },
                user: currentUser,
                userId: currentUser ? currentUser.id.uuid : null
              });
              // pushGTMVerifiedEmailPhone(properties, EVENT_SIGNUP_VERIFIED_EMAIL);
            } catch (error) {
              console.error('Error fetching events or triggering GTM event:', error);
            }
          }
          // console.log("Verification with verification in onfido")
         
        }}
        key={"user verification with onfido"}
        fetchCurrentUser={this.props.fetchCurrentUser}
        onComplete={() => { }}
        currentUser={currentUser}
        transactionId={this.props.transactionId}
        transaction={this.props.transaction}
        onTransit={this.props.onTransit}
        haveNextTransit={this.props.haveNextTransit}
        nextInteractableTransitions={this.props.nextInteractableTransitions}
        pageName={this.props.pageName}
        isHost={this.props.isHost}
        redirectToHomePage={this.redirectToHomePage}
        onUpdateUserData={this.onUpdateUserData}
      />
    );

    const verficationstatus = this.props.isHost
      ? get(currentUser, 'attributes.profile.publicData.hostIdentityVerificationStatus')
      : get(currentUser, 'attributes.profile.publicData.guestIdentityVerificationStatus');

    const isVerified = verficationstatus === 'confirmed' ? true : false;
    const isPendingVerification = verficationstatus === 'pending' ? true : false;
    const isDeclinedVerification = verficationstatus === 'declined' ? true : false;
    const isNotSubmittedVerification = !verficationstatus || verficationstatus === 'not submitted';

    const declinedDueToDuplicate = isDeclinedVerification && (get(currentUser, "attributes.profile.protectedData.singpassResult.description","") === "An account with the same NRIC/ FIN already exists");

    const statusLottiePage = <div className={css.titleContainer}>
      <UserVerificationStatusIndicator
        // showSteps={showSteps}
        // step={step}
        isHost={this.props.isHost}
        // totalSteps={totalSteps}
        // continueVerification={continueVerification}
        isVerified={isVerified}
        isPending={isPendingVerification}
        isDeclined={isDeclinedVerification}
        isNotSubmitted={isNotSubmittedVerification}
      />
      {/* Here lottie will come if user is verified and pending and declined */}
      {isVerified || isPendingVerification || isDeclinedVerification ? (
        <LottieElementPage
          isVerified={isVerified}
          onGetActiveTrips={this.props.onGetActiveTrips}
          isPending={isPendingVerification}
          isDeclined={isDeclinedVerification}
          redirectToHomePage={this.props.redirectToHomePage}
          declinedDueToDuplicate={declinedDueToDuplicate}
        />
      ) : null}
    </div>;

    const rightPageLayout = (isVerified || isPendingVerification || isDeclinedVerification) ? statusLottiePage : unverifiedLayout;

    // Here is the verification status will show the pending




    const title = this.props.isHost ? intl.formatMessage({ id: 'AccountSettingDriverVerificationPage.hostTitle' }) : intl.formatMessage({ id: 'AccountSettingDriverVerificationPage.title' });
    const tripPageClassName = this.props.isFromTripPage ? css.tripPageDocumentUploadFormRoot : '';
    if (this.props.manageDisableSidebar || this.props.isFromTripPage) {
      return (
        <Page
          title={title}
          scrollingDisabled={scrollingDisabled}
          className={classNames(css.verificationPage)}
          style={{ zIndex: -1 }}
        >
          <LayoutWrapperMain>
            <div className={css.content}>{rightPageLayout}</div>
          </LayoutWrapperMain>
        </Page>
      );
    }
    if (this.state.allowNavigateToHome) {
      return <NamedRedirect name="LandingPage" />;
    }

    return (
      <Page title={title} scrollingDisabled={scrollingDisabled} className={tripPageClassName}>
        <LayoutSideNavigation>
          <LayoutWrapperTopbar>
            <TopbarContainer
              currentPage="AccountSettingDriverVerificationPage"
              desktopClassName={css.desktopTopbar}
              mobileClassName={css.mobileTopbar}
            />
            <UserNav selectedPageName="AccountSettingDriverVerificationPage" />
          </LayoutWrapperTopbar>
          <LayoutWrapperSideNav tabs={tabs} user={user} />
          <LayoutWrapperMain>
            <div className={css.content}>
              {rightPageLayout}
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSideNavigation>
      </Page>
    );
  }
}

AccountSettingDriverVerificationPageComponent.defaultProps = {
  currentUser: null,
};

const { bool } = PropTypes;

AccountSettingDriverVerificationPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  scrollingDisabled: bool.isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  // Topbar needs user info.
  const { currentUser } = state.user;
  const { connectCalendarTabVisibility } = state.tabPanels;
  return {
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
    connectCalendarTabVisibility,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  fetchCalendarVisibility: () => dispatch(getConnectCalendarTabStatus()),
  onUpdateUserData: data => dispatch(updateUserData(data)),
  fetchCurrentUser: () => dispatch(fetchCurrentUser()),
  onGetActiveTrips: (param) => dispatch(getActiveTrips(param)),
});

const AccountSettingDriverVerificationPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(AccountSettingDriverVerificationPageComponent);

AccountSettingDriverVerificationPage.loadData = () => {
  // Since verify email happens in separate tab, current user's data might be updated
  return fetchCurrentUser();
};

export default AccountSettingDriverVerificationPage;
