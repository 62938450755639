import React, { memo } from 'react';
import car2 from '../../assets/landingPageNew/car2.jpg';
import car3 from '../../assets/landingPageNew/love-drivelah-4.jpg';
import car4 from '../../assets/landingPageNew/car4.jpg';
import dc from '../../assets/landingPageNew/dc.jpg';
import outing from '../../assets/landingPageNew/benefit-image.jpg';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink } from '../../components';
import classNames from 'classnames';
import css from './GuestLandingPage.css';
import { Column, Container, Row } from '../../components/Grids/Grid';
import Slider from 'react-slick';
import config from '../../config';

const FindCar = memo(({ openSignUpModal, getSearchDateParameters }) => {
  const sliderConfig = {
    autoplay: false,
    dots: false,
    arrows: false,
    infinite: false,
    slidesToScroll: 1,
    slidesToShow: 1.25,
  };
  return (
    <div className={css.findCarWrapper}>
      <Container>
        <Row>
          <Column large={12}>
            {/* <h4 className={css.heading}>A mode for every mood</h4> */}
            <h2 className={css.heading}>Find a car for rent that fits your travel needs <span>Drive to Malaysia allowed</span></h2>
          </Column>
        </Row>
        <Row className={classNames(css.carGrid, css.showDesktop)}>
          <Column large={50} medium={50} small={12}>
            <NamedLink
              name="SearchPage"
              to={{
                search: `${config.defaultAddress}${getSearchDateParameters}&pub_canDriveToMalaysia=true`,
              }}
              className={css.card}
            >
              <div className={css.cardThumb}>
                <img src={car4} alt="" />
              </div>
              <div className={css.cardContent}>
                <h3>Drive to Malaysia</h3>
                {/* <span>609+ Cars Available</span> */}
              </div>
            </NamedLink>
          </Column>
          <Column large={50} medium={50} small={12}>
            <NamedLink
              name="SearchPage"
              to={{
                search: `${config.defaultAddress}${getSearchDateParameters}`,
              }}
              className={css.card}
            >
              <div className={css.cardThumb}>
                <img src={car2} alt="" />
              </div>
              <div className={css.cardContent}>
                <h3>Weekend Outing</h3>
                {/* <span>3,032+ Cars Available</span> */}
              </div>
            </NamedLink>
          </Column>
          <Column large={50} medium={50} small={12}>
            <NamedLink
              name="SearchPage"
              to={{
                search: `${config.defaultAddress}${getSearchDateParameters}`,
              }}
              className={css.card}
            >
              <div className={css.cardThumb}>
                <img src={dc} alt="" />
              </div>
              <div className={css.cardContent}>
                <h3>Daily Chores</h3>
                {/* <span>2,340+ Cars Available</span> */}
              </div>
            </NamedLink>
          </Column>
          <Column large={50} medium={50} small={12}>
            <NamedLink
              name="SearchPage"
              to={{
                search: `${config.defaultAddress}${getSearchDateParameters}`,
              }}
              className={css.card}
            >
              <div className={css.cardThumb}>
                <img src={car3} alt="" />
              </div>
              <div className={css.cardContent}>
                <h3>Visit Friends</h3>
                {/* <span>1,232+ Cars Available</span> */}
              </div>
            </NamedLink>
          </Column>
          <Column large={50} medium={50} small={12}>
            <NamedLink
              name="SearchPage"
              to={{
                search: `${config.defaultAddress}${getSearchDateParameters}`,
              }}
              className={css.card}
            >
              <div className={css.cardThumb}>
                <img src={outing} alt="" />
              </div>
              <div className={css.cardContent}>
                <h3>Move Houses</h3>
                {/* <span>1,232+ Cars Available</span> */}
              </div>
            </NamedLink>
          </Column>
        </Row>
        <Row className={classNames(css.carGrid, css.showMobile)}>
          <Slider {...sliderConfig}>
            <Column large={12}>
              <NamedLink
                name="SearchPage"
                to={{
                  search: `${config.defaultAddress}${getSearchDateParameters}&pub_canDriveToMalaysia=true`,
                }}
                className={css.card}
              >
                <div className={css.cardThumb}>
                  <img src={car4} alt="" />
                </div>
                <div className={css.cardContent}>
                  <h3>Drive to Malaysia</h3>
                  {/* <span>609+ Cars Available</span> */}
                </div>
              </NamedLink>
            </Column>
            <Column large={12}>
              <NamedLink
                name="SearchPage"
                to={{
                  search: `${config.defaultAddress}${getSearchDateParameters}`,
                }}
                className={css.card}
              >
                <div className={css.cardThumb}>
                  <img src={car2} alt="" />
                </div>
                <div className={css.cardContent}>
                  <h3>Weekend Outing</h3>
                  {/* <span>3,032+ Cars Available</span> */}
                </div>
              </NamedLink>
            </Column>
            <Column large={12}>
              <NamedLink
                name="SearchPage"
                to={{
                  search: `${config.defaultAddress}${getSearchDateParameters}`,
                }}
                className={css.card}
              >
                <div className={css.cardThumb}>
                  <img src={dc} alt="" />
                </div>
                <div className={css.cardContent}>
                  <h3>Daily Chores</h3>
                  {/* <span>2,340+ Cars Available</span> */}
                </div>
              </NamedLink>
            </Column>
            <Column large={12}>
              <NamedLink
                name="SearchPage"
                to={{
                  search: `${config.defaultAddress}${getSearchDateParameters}`,
                }}
                className={css.card}
              >
                <div className={css.cardThumb}>
                  <img src={car3} alt="" />
                </div>
                <div className={css.cardContent}>
                  <h3>Visit Friends</h3>
                  {/* <span>1,232+ Cars Available</span> */}
                </div>
              </NamedLink>
            </Column>
            <Column large={12}>
              <NamedLink
                name="SearchPage"
                to={{
                  search: `${config.defaultAddress}${getSearchDateParameters}`,
                }}
                className={css.card}
              >
                <div className={css.cardThumb}>
                  <img src={outing} alt="" />
                </div>
                <div className={css.cardContent}>
                  <h3>Move Houses</h3>
                  {/* <span>1,232+ Cars Available</span> */}
                </div>
              </NamedLink>
            </Column>
          </Slider>
        </Row>
        <Row className={css.buttonWrapper}>
          <Column alignItems="center" large={12}>
            <button onClick={openSignUpModal} className={classNames(css.btn, css.btnPrimary)}>
              <FormattedMessage id="LandingPageNew.GetStarted" />
            </button>
          </Column>
        </Row>
      </Container>
    </div>
  );
});

export default FindCar;
