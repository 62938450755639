import classNames from 'classnames';
import React from 'react';
import css from './DownloadAppSection.css';
import { IconLogo } from '../../components';
import imageSource from '../../util/imageSource';

const DownloadAppSection = () => {
  return (
    <div className={classNames(css.sectionGrids)}>
      <div className={classNames(css.landingContainer, css.container)}>
        <div className={css.row}>
          <div
            className={classNames(
              css.column12,
              css.gridBox,
              css.gridHorizontal,
              css.gridImageRight
            )}
          >
            <div className={css.gridBoxInner}>
              <div className={css.gridThumb}>
                <div className={css.thumbContent}>
                  <IconLogo />
                </div>
              </div>
              <div className={css.gridContent}>
                <span className={css.title}>Have you got our apps?</span>
                <p>Available on both iOS and Android, download on your phone now.</p>
                <ul className={css.badgeListing}>
                  <li className={css.heroContentText}>
                    <a
                      target="_blank"
                      href="https://apps.apple.com/us/app/id1500770324"
                      className={css.appButton}
                    >
                      <img src={imageSource.bLanding.appStoreBadge} alt="App store logo" />
                    </a>
                  </li>
                  <li className={css.heroContentText}>
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.drivelah.drivelah"
                      className={css.appButton}
                    >
                      <img src={imageSource.bLanding.googlePlayBadge} alt="Google play store logo" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadAppSection;
