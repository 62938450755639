import { sendApplyNotification } from '../util/campaign';
import { sendGAEvent } from '../util/googleAnalytics';

// ================ Action types ================ //

export const APPLY_CAMPAIGN_REQUEST = 'app/campaign/APPLY_CAMPAIGN_REQUEST';
export const APPLY_CAMPAIGN_ERROR = 'app/campaign/APPLY_CAMPAIGN_ERROR';
export const APPLY_CAMPAIGN_SUCCESS = 'app/campaign/APPLY_CAMPAIGN_SUCCESS';

// ================ Reducer ================ //

const initialState = {
  applyInProgress: false,
  applyError: null,
  applySuccess: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case APPLY_CAMPAIGN_REQUEST:{
      return {
        ...state,
        applyInProgress: true,
      };
    }
    case APPLY_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        applyInProgress: false,
        applySuccess: true,
        applyError: null,
      }
    }
    case APPLY_CAMPAIGN_ERROR: {
      return {
        ...state,
        applyInProgress: false,
        applyError: payload,
      }
    }
    default: {
      return { ...state };
    }
  }
}

// ================ Action creators ================ //

const applyRequest = () => ({ type: APPLY_CAMPAIGN_REQUEST });
const applySuccess = () => ({ type: APPLY_CAMPAIGN_SUCCESS });
const applyError = (error) => ({ type: APPLY_CAMPAIGN_ERROR, payload: error });

// ================ Thunks ================ //

export const applyCampaign = ({ email, userType }) => (dispatch, getState) => {
  dispatch(applyRequest());

  return sendApplyNotification({ email, userType })
    .then((res) => {
      if (res.ok) {
        dispatch(applySuccess());

        sendGAEvent({
          eventAction: 'Submit Email Id',
          eventCategory: 'Campaign'
        });

        window.location.href = `/signup?campaign=true&email=${encodeURIComponent(email)}&userType=${userType}`;
        return res.json();
      }

      if (res.status === 409) {
        
        return dispatch(applyError('applied_email_exists'));
      }
    })
    .catch((error) => {
      
    });
};
