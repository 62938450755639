import React from 'react';
import { NamedLink } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';

import css from './BAHWhyDriveLah.css';

const BAHWhyDriveLah = props => {
  const {
    isAuthenticated,
    onLoginOrSignupClick,
  } = props;

  return (
    <section className={css.whyDriveLahSection}>
      <div className={css.textBox}>
        <h2 className={css.title}>
          <FormattedMessage id="BecomeAHostPage.whyDriveLahTitle" values={{ newline: (<span className={css.mobileNewLine}><br /></span>) }} />
        </h2>
        <div className={css.description}>
          <FormattedMessage id="BecomeAHostPage.whyDriveLahDesc" />
        </div>
        {!isAuthenticated &&
          <NamedLink
            name="SignupPage"
            className={css.joinNow}
            onLoginOrSignupClick={onLoginOrSignupClick}
          >
            <FormattedMessage id="BecomeAHostPage.joinNow" />
          </NamedLink>
        }
      </div>
    </section>
  );
}

export default BAHWhyDriveLah;
