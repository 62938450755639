import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import css from './TripPanel.css';
import { TRANSITION_ENQUIRE } from '../../util/transaction';
import { getAllowedMileagePerDay, sliderOptionsList } from '../AddOnsSection/AddonsUtils';
import { calculateBookingDays } from '../../util/dates';
import { get } from 'lodash';
import moment from 'moment-timezone';


export const getSelectedMileage = (tripDetails) => {
  if (!tripDetails || !tripDetails.attributes) {
    return null;
  }
  const { attributes } = tripDetails;
  const protectedData = attributes.protectedData || {};
  const metadata = attributes.metadata || {};
  let selectedMileage = 0;
  if(protectedData.isMileagePackageIncluded) {
    selectedMileage = protectedData.selectedMileage;
  }
  if(metadata.isMileagePackageIncluded) {
    selectedMileage = metadata.selectedMileage;
  }
  return selectedMileage;
};
const extractNumericValue = value => typeof value === 'string' ? parseFloat(value.replace('km', '')) : value;

export const getDefaultMileage = (tripDetails, newFormat) => {

  const attributes = tripDetails && tripDetails.attributes;
  const protectedData = attributes && attributes.protectedData;
  const hourlyBooking = protectedData && protectedData.hourlyBooking;

  const startTransaction =
    tripDetails && tripDetails.attributes.protectedData.bookingDisplayStart;
  const endTransaction =
    tripDetails && tripDetails.attributes.protectedData.bookingDisplayEnd;
  const totalTripHours = moment(endTransaction).diff(moment(startTransaction), 'hours', true);
  // const hourDuration = hourlyBooking ? protectedData.hourDuration : null;
  const hourDuration = totalTripHours;


  let mileageExperiment = protectedData && protectedData.mileageExperiment;
  // console.log("mileageExperimentFinal", mileageExperiment);

  let millagePerDayOld;
  let millagePerDay;

  const publicData = tripDetails && tripDetails.listing && tripDetails.listing.attributes && tripDetails.listing.attributes.publicData;
  if (publicData) {
    millagePerDayOld = extractNumericValue(publicData.millagePerDayOld);
    millagePerDay = extractNumericValue(publicData.millagePerDay);
  }

  const selectedMileage =
    getSelectedMileage(tripDetails);

  let dailyKm = 125;

  const customer = tripDetails.customer;
  // console.log("Customer ddddddd", customer);
  if(customer) {
    const customerExperiment = get(customer, 'attributes.profile.publicData.mileageExperiment');
    // console.log("Customer experiment", customer);
    if(customerExperiment) {
      mileageExperiment = customerExperiment;
    }
  }

  // console.log("Mileage experiment", mileageExperiment, selectedMileage);


  if (mileageExperiment == 'A') {
    // console.log()
    if (!selectedMileage) {
      if (!hourlyBooking) {
        if (millagePerDay == 'unlimited' || millagePerDay == 'Unlimited' || millagePerDay == 999) {
          return 'Unlimited';
        } else {
          dailyKm = Number(millagePerDayOld) || Number(millagePerDay) || 125;
        }
      } else {
        dailyKm = getAllowedMileagePerDay(hourDuration)
      }
    } else {
      dailyKm = selectedMileage;
      const sliderOptionsMileage = sliderOptionsList(hourDuration);
      if(dailyKm >= sliderOptionsMileage.max ) {
        return 'Unlimited';
      }
    }
  } else {
    if (!hourlyBooking) {
      if (millagePerDayOld == 'unlimited' || millagePerDayOld == 'Unlimited' || millagePerDayOld == 999) {
        return 'Unlimited';
      } else {
        dailyKm = Number(millagePerDayOld) || Number(millagePerDay) || 125;
      }
    } else {
      dailyKm = Number(millagePerDayOld) || Number(millagePerDay) || 125
      if(dailyKm === "unlimited" || dailyKm === "Unlimited" || dailyKm === 999) {
        return "Unlimited"
      }
      dailyKm = hourlyBooking ? (dailyKm / 12.5) * hourDuration : dailyKm;
    }
  }

  if(newFormat && mileageExperiment === 'A') {
    const startDateTimeMaybe =
      tripDetails &&
      tripDetails.attributes &&
      tripDetails.attributes.protectedData &&
      tripDetails.attributes.protectedData.bookingDisplayStart;
    const endDateTimeMaybe =
      tripDetails &&
      tripDetails.attributes &&
      tripDetails.attributes.protectedData &&
      tripDetails.attributes.protectedData.bookingDisplayEnd;

    const bookingDays = calculateBookingDays(startDateTimeMaybe, endDateTimeMaybe);
    let formattedMileage = selectedMileage
    if(!selectedMileage) {
      formattedMileage = getAllowedMileagePerDay(hourDuration)
    }
    const sliderOptions = sliderOptionsList(hourDuration);

    // console.log("Formatted mileage: ", formattedMileage)
    dailyKm = formattedMileage >= sliderOptions.max ? 'Unlimited' : `${formattedMileage * bookingDays} kms (${formattedMileage} km x ${bookingDays} ${bookingDays > 1 ? 'days' : 'day'})`;
    return dailyKm
  }

  return hourlyBooking ?
    dailyKm.toString().concat(' kms')
    : dailyKm.toString().concat(' kms/day');
};

export const getDefaultMileageWithDetails = tripDetails => {
 return getDefaultMileage(tripDetails, true)
};


const InfoSection = ({
  showAddress,
  listing,
  transaction,
  lastTransition,
}) => {
  const [isReadMore, setIsReadMore] = useState(null);

  const rules =
    listing.attributes.publicData && listing.attributes.publicData.rules
      ? listing.attributes.publicData.rules
      : false;
  const keyRules =
    listing.attributes.publicData && listing.attributes.publicData.keyRules
      ? listing.attributes.publicData.keyRules
      : false;
  const parkingInstructions =
    listing.attributes.publicData && listing.attributes.publicData.parkingInstructions
      ? listing.attributes.publicData.parkingInstructions
      : false;
  const pickupDropoffInstructions =
    listing.attributes.publicData && listing.attributes.publicData.pickupDropoffInstructions
      ? listing.attributes.publicData.pickupDropoffInstructions
      : false;
  const carGuide =
    listing.attributes.publicData && listing.attributes.publicData.pickupDropoffInstructions
      ? listing.attributes.publicData.carGuide
      : false;
  const carBrand = listing.attributes.publicData.brandName || '';
  const carModel = listing.attributes.publicData.modelName || '';

  const listCardInfo =
    lastTransition === TRANSITION_ENQUIRE
      ? [
          {
            id: 1,
            title: <FormattedMessage id="TransactionPanel.carInfo" />,
            bodyShow: (
              <div className={css.bodyTextContainer}>
                <p className={css.infotext}>
                  <FormattedMessage id="TransactionPanel.registration" />
                  {listing.attributes.publicData.license_plate_number}
                </p>
                <p className={css.infotext}>
                  <FormattedMessage id="TransactionPanel.fuel" />
                  {listing.attributes.publicData.fuelType}
                </p>
              </div>
            ),
            readMore: false,
            show: showAddress,
          },
          {
            id: 2,
            title: <FormattedMessage id="TransactionPanel.pickupInstruction" />,
            bodyShow: (
              <div className={css.bodyTextContainer}>
                <p className={css.infotext}>{rules}</p>
              </div>
            ),
            bodyHide: <p className={css.infotext}>{rules}</p>,
            readMore: false,
            show: rules,
          },
        ]
      : [
          {
            id: 1,
            title: <FormattedMessage id="TransactionPanel.carInfo" />,
            bodyShow: (
              <div className={css.bodyTextContainer}>
                <p className={css.infotext}>
                  <FormattedMessage id="TransactionPanel.about" />
                  {carBrand + ' ' + carModel}
                </p>
                <p className={css.infotext}>
                  <FormattedMessage id="TransactionPanel.registration" />
                  {listing.attributes.publicData.license_plate_number}
                </p>
                <p className={css.infotext}>
                  <FormattedMessage id="TransactionPanel.fuel" />
                  {listing.attributes.publicData.fuelType}
                </p>
              </div>
            ),
            readMore: false,
            show: showAddress,
          },
          {
            id: 2,
            title: <FormattedMessage id="TransactionPanel.keyInformation" />,
            bodyShow: (
              <div className={css.bodyTextContainer}>
                        {keyRules.length > 0 &&
         [...keyRules, listing.attributes.publicData.fuelType && `Allowed Mileage: ${getDefaultMileageWithDetails(transaction)}`].map((rule, index) => {
            if (rule) {
              return (
                <div className={css.infotext} key={index}>
                  <span className={css.index}>{index + 1}.&nbsp;</span>
                  {rule}
                </div>
              );
            } else {
              return null; // Skip rendering if rule is falsy (e.g., undefined)
            }
          })}

              </div>
            ),
            readMore: false,
            show: keyRules && keyRules.length > 0,
          },
          {
            id: 3,
            title: <FormattedMessage id="TransactionPanel.parkingInstructions" />,
            bodyShow: <div className={css.bodyTextContainer}>{parkingInstructions}</div>,
            bodyHide: <div className={css.bodyTextContainerLess}>{parkingInstructions}</div>,
            readMore: true,
            show: !!parkingInstructions,
          },
          {
            id: 4,
            title: <FormattedMessage id="TransactionPanel.pickupDropoffInstructions" />,
            bodyShow: <div className={css.bodyTextContainer}>{pickupDropoffInstructions}</div>,
            bodyHide: <div className={css.bodyTextContainerLess}>{pickupDropoffInstructions}</div>,
            readMore: true,
            show: !!pickupDropoffInstructions,
          },
          {
            id: 5,
            title: <FormattedMessage id="TransactionPanel.carGuide" />,
            bodyShow: <div className={css.bodyTextContainer}>{carGuide}</div>,
            bodyHide: <div className={css.bodyTextContainerLess}>{carGuide}</div>,
            readMore: true,
            show: !!carGuide,
          },
        ];

  return (
    <div className={css.infoSectionContainer}>
      {listCardInfo.map(i => {
        return i.show ? (
          <div key={i.id} className={css.infoCardContainer}>
            <h4 className={css.infoTitle}>{i.title}</h4>
            {isReadMore === i.id ? i.bodyHide : i.bodyShow}
            {i.readMore ? (
              <p className={css.readMore} onClick={() => setIsReadMore(isReadMore ? null : i.id)}>
                {isReadMore === i.id ? (
                  <FormattedMessage id="TransactionPanel.readLess" />
                ) : (
                  <FormattedMessage id="TransactionPanel.readMore" />
                )}
              </p>
            ) : null}
          </div>
        ) : null;
      })}
    </div>
  );
};

export default InfoSection;
