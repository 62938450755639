import React, { Component } from 'react';
import { FormattedMessage } from '../../util/reactIntl';

import css from './BAGWhyDriveLah.css';
import insuranceLogo from '../../assets//logos/insuranceLogo-min.png';
import { ExternalLink, ModalInsurancePolicy } from '../../components';

class BAGWhyDriveLah extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenInsuranceModal: false,
    };
  }

  handleOpenInsuranceModal = () => this.setState({ isOpenInsuranceModal: true });
  handleCloseInsuranceModal = () => this.setState({ isOpenInsuranceModal: false });

  render() {
    const { onManageDisableScrolling } = this.props;
    return (
      <section className={css.whyDriveLahSection}>
        <div className={css.textBox}>
          <h5 className={css.title}>
            <FormattedMessage
              id="BecomeAGuestPage.whyDriveLahTitle"
              values={{
                newline: (
                  <span className={css.newlineMobile}>
                    <br />
                  </span>
                ),
              }}
            />
          </h5>
          <div className={css.subBoxs}>
            <div className={css.subBoxs1}>
              <div className={css.subBox}>
                <div className={css.subBoxTitle}>Affordable & transparent pricing</div>
                <div className={css.subBoxDesc}>
                  Registration and verification is free. We only charge a small fee to provide a
                  fully secured & convenient experience to you including insurance. In the end, you
                  save up to 30-40% on your car rentals.
                </div>
              </div>
              <div className={css.subBox}>
                <div className={css.subBoxTitle}>
                  Comprehensive insurance for all Drive lah trips
                </div>
                <div className={css.subBoxDesc}>
                  In the unlikely case of an accident/damage, the vehicle is comprehensively insured
                  via our insurance. If the car's age is 12 years or more, then third party only
                  insurance is provided. The insurance starts automatically when you pick up the car
                  and is valid till the time you return it.
                </div>
                <img src={insuranceLogo} className={css.insuranceLogo} />
                <ExternalLink className={css.btnPolicy} href="https://drivelah-public-files.s3.ap-southeast-1.amazonaws.com/Drive+lah+protect+-+Policy.pdf">
                  <FormattedMessage id="BecomeAGuestPage.ourInsurance" />
                </ExternalLink>
              </div>
            </div>
            <div className={css.subBoxs2}>
              <div className={css.subBox}>
                <div className={css.subBoxTitle}>Cars always nearby</div>
                <div className={css.subBoxDesc}>
                  With a growing sharing community you will be sure to find a car in your
                  neighbourhood. Sign up to browse through all nearby cars available and find the
                  perfect fit.
                </div>
              </div>
              <div className={css.subBox}>
                <div className={css.subBoxTitle}>24/7 road assistance</div>
                <div className={css.subBoxDesc}>
                  All Drive lah trips come with full roadside assistance.
                </div>
              </div>
              <div className={css.subBox}>
                <div className={css.subBoxTitle}>Helpdesk available</div>
                <div className={css.subBoxDesc}>
                  24/7 support for every step of the way. Just give us a call and we are more than
                  happy to assist you.
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalInsurancePolicy
          isOpen={this.state.isOpenInsuranceModal}
          onClose={this.handleCloseInsuranceModal}
          onManageDisableScrolling={onManageDisableScrolling}
        />
      </section>
    );
  }
}

export default BAGWhyDriveLah;
