import React from 'react';
import css from './GrabHostYourCarLandingPage.css';
import imageSource from '../../util/imageSource';

const GrabIntroBanner = ({ isMobileLayout, handleSignupRedirect }, ...props) => {
  return (
    <div className={css.bannerIntroSection}>
      <div className={css.container}>
        <div className={css.row}>
          <div className={css.column6}>
            <div className={css.bannerIntroContent}>
              <div className={css.bannerLogoBlock}>
                {!isMobileLayout && <img src={imageSource.grabBanner.bannerLogo} alt="Drive lah and Grab Rentals logo" />}
              </div>
              <h1>Is your car sitting idle?</h1>
              <h2>Rent out your car, and make some extra money, only with Drive lah</h2>
              <ul className={css.bannerListing}>
                <li>
                  <span className={css.pointIcons}>
                    {isMobileLayout ? (
                      <img src={imageSource.grabBanner.approveBlueIconMobile} alt="Right Tick Icon" />
                    ) : (
                      <img src={imageSource.grabBanner.approveBlueIcon} alt="Approved by LTA" />
                    )}
                  </span>
                  Approved by{' '}
                  <span className={css.contentIcon}>
                    {isMobileLayout ? (
                      <img src={imageSource.grabBanner.grabLogoWhite} alt="Grab Rentals logo" />
                    ) : (
                      <img src={imageSource.grabBanner.grabLogo} alt="Grab Rentals logo" />
                    )}
                  </span>
                </li>
                <li>
                  <span className={css.pointIcons}>
                    {isMobileLayout ? (
                      <img src={imageSource.grabBanner.saveMoneyIconMobile} alt="Piggy Saving Icon" />
                    ) : (
                      <img src={imageSource.grabBanner.saveMoneyIcon} alt="Piggy Saving Icon" />
                    )}
                  </span>
                  Earn passive income during your off days
                </li>
                <li>
                  <span className={css.pointIcons}>
                    {isMobileLayout ? (
                      <img src={imageSource.grabBanner.easyAccessIconMobile} alt="Dollar symbol" />
                    ) : (
                      <img src={imageSource.grabBanner.easyAccessIcon} alt="Dollar symbol" />
                    )}
                  </span>
                  Sign up now to get $100 Drive lah credits
                </li>
              </ul>
              <button onClick={e => handleSignupRedirect(e)} className={css.bannerButton}>
                Start earning now
              </button>
              <div className={css.mobileBannerLogo}>
                {isMobileLayout && <img src={imageSource.grabBanner.bannerLogoMobile} alt="Drive lah and Grab Rentals logo" />}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={css.bannerIntroImageSection}>
        <img src={imageSource.grabBanner.bannerBackground} alt="Car sharing with more choice and less hassle | Drive lah" />
      </div>
    </div>
  );
};

export default GrabIntroBanner;
