import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingPromotionsForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';
import isEqual from 'lodash/isEqual';
import css from './EditListingPromotionsPanel.css';
import pick from 'lodash/pick';
import cloneDeep from 'lodash/cloneDeep';

const { Money } = sdkTypes;

const EditListingPromotionsPanel = memo(
  props => {
    const {
      className,
      rootClassName,
      listing,
      disabled,
      ready,
      onSubmit,
      onChange,
      submitButtonText,
      panelUpdated,
      updateInProgress,
      errors,
      submitButtonId,
    } = props;

    const classes = classNames(rootClassName || css.root, className);
    const currentListing = ensureOwnListing(listing);
    const { price, publicData } = currentListing.attributes;
    const {
      pricing = {},
      isThreeBookingDiscount = false,
      longTermRental = false,
      longTermAdminVerified = false,
      longTermPrice,
      threeBookingDiscount = null,
      isPublicHolidayPeakDays = false,
    } = publicData;

    const isPublished =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
    const panelTitle = isPublished ? (
      <FormattedMessage
        id="EditListingPricingPanel.title"
        values={{ listingTitle: <ListingLink listing={listing} /> }}
      />
    ) : (
      <FormattedMessage id="EditListingPricingPanel.createListingTitle" />
    );
    const ensurePricing = useMemo(() => {
      const { peakPrice, discount = {}, ...rest } = pricing;
      return cloneDeep({
        regularDays: ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'],
        peakDays: [],
        ...rest,
        peakPrice:
          peakPrice && peakPrice.amount > 0
            ? new Money(peakPrice.amount, peakPrice.currency)
            : null,
        ...discount,
      });
    }, [pricing]);

    const priceCurrencyValid = price instanceof Money ? price.currency === config.currency : true;
    const longTermPriceMaybe = longTermPrice
      ? { longTermPrice: new Money(longTermPrice.amount, longTermPrice.currency) }
      : {};

    const form = priceCurrencyValid ? (
      <EditListingPromotionsForm
        className={css.form}
        initialValues={{
          price,
          ...ensurePricing,
          longTermRental,
          threeBookingDiscount,
          isThreeBookingDiscount,
          longTermAdminVerified,
          isPublicHolidayPeakDays,
          ...longTermPriceMaybe,
        }}
        onSubmit={values => {
          const {
            price,
            regularDays,
            peakDays,
            peakPrice,
            week = 0,
            month = 0,
            threeDays = 0,
            longTermRental = false,
            threeBookingDiscount = null,
            isThreeBookingDiscount = false,
            longTermPrice = null,
            isPublicHolidayPeakDays = false,
          } = values;
          
          const data = {
            price,
            publicData: {
              isPublicHolidayPeakDays,
              longTermRental,
              longTermPrice: longTermPrice
                ? pick(longTermPrice, ['amount', 'currency'])
                : { amount: 0, currency: config.currency },
              longTermPriceAmount: longTermPrice ? longTermPrice.amount : 0,
              pricing: {
                regularDays,
                peakDays,
                peakPrice: peakPrice
                  ? {
                      amount: peakPrice.amount,
                      currency: peakPrice.currency,
                    }
                  : {
                      amount: 0,
                      currency: config.currency,
                    },
                discount: {
                  week,
                  month,
                  threeDays
                },
              },
              threeBookingDiscount: threeBookingDiscount,
              isThreeBookingDiscount: isThreeBookingDiscount
            },
          };
          
          onSubmit(data);
        }}
        onChange={onChange}
        saveActionMsg={submitButtonText}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        submitButtonId={submitButtonId}
      />
    ) : (
      <div className={css.priceCurrencyInvalid}>
        <FormattedMessage id="EditListingPricingPanel.listingPriceCurrencyInvalid" />
      </div>
    );
    const title = <FormattedMessage id="EditListingPromotionsPanel.title" />;
    const panelDescription = <FormattedMessage id="EditListingPromotionsPanel.panelDescription" />;
    return (
      <div className={classes}>
        <div className={css.titleSection}>
          <h1 className={css.title}>{title}</h1>
          <p>{panelDescription}</p>
        </div>
        {form}
      </div>
    );
  },
  (prev, next) =>
    isEqual(
      pick(prev, ['listing', 'updateInProgress', 'panelUpdated']),
      pick(next, ['listing', 'updateInProgress', 'panelUpdated'])
    )
);

const { func, object, string, bool } = PropTypes;

EditListingPromotionsPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingPromotionsPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
  submitButtonId: string,
};

export default EditListingPromotionsPanel;
