import React from 'react';
import { intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { humanizeLineItemCode } from '../../util/data';
import { LINE_ITEMS, propTypes } from '../../util/types';
import capitalize from 'lodash/capitalize';

import css from './BookingBreakdown.css';

function getUnique(arr, comp) {

  // store the comparison  values in array
  const unique =  arr.map(e => e[comp])

    // store the indexes of the unique objects
    .map((e, i, final) => final.indexOf(e) === i && i)

    // eliminate the false indexes & return unique objects
    .filter((e) => arr[e]).map(e => arr[e]);

  return unique;
}

const LineItemUnknownItemsRefundMaybe = props => {
  const { transaction, intl } = props;

  // resolve unknown non-reversal line items
  const rawItems = transaction.attributes.lineItems.filter(
    item => LINE_ITEMS.indexOf(item.code) === -1 && item.reversal
  );

  const items = getUnique(rawItems, 'code')

  return items.length > 0 ? (
    <React.Fragment>
      {items.map((item, i) => {
        const label = humanizeLineItemCode(item.code);
        const formattedTotal = formatMoney(intl, item.lineTotal, 1);
        return (
          <div key={`${i}-item.code`} className={css.lineItem}>
            <span className={css.itemLabel}>{capitalize(`${label}`)}</span>

            <span className={css.itemValue}>{formattedTotal}</span>
          </div>
        );
      })}
    </React.Fragment>
  ) : null;
};

LineItemUnknownItemsRefundMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemUnknownItemsRefundMaybe;
