import React, { memo } from 'react';
import get from 'lodash/get';
import { BookingBreakdown, BookingBreakdownLongTerm } from '../../components';

import css from './BookingTimeForm.css';
import { restoreTransaction } from '../../util/data';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';

const EstimatedBreakdownMaybe = memo(
  ({ estimating, estimatedTx, unitType }) => {
    const isLongTerm = get(estimatedTx, 'attributes.protectedData.isLongTermRental');
    const estimatedBreakdown = isLongTerm ? (
      <BookingBreakdownLongTerm
        className={css.receipt}
        userRole="customer"
        unitType={unitType}
        transaction={restoreTransaction(estimatedTx)}
        booking={estimatedTx && estimatedTx.booking}
        isOnListingPage
      />
    ) : (
      <BookingBreakdown
        className={css.receipt}
        userRole="customer"
        unitType={unitType}
        transaction={restoreTransaction(estimatedTx)}
        booking={estimatedTx && estimatedTx.booking}
        isOnListingPage
      />
    );
    return estimating ? (
      <div className={css.estimating}>Estimating...</div>
    ) : estimatedTx && estimatedTx.id ? (
      estimatedBreakdown
    ) : null;
  },
  (prev, next) =>
    isEqual(pick(prev, ['estimatedTx', 'estimating']), pick(next, ['estimatedTx', 'estimating']))
);

export default EstimatedBreakdownMaybe;
