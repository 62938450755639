import React, { Fragment, useEffect, useRef, useState } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Form as FinalForm } from 'react-final-form';
import get from 'lodash/get';
import {
  FieldCheckbox,
  FieldRadioButton,
  FieldTextInput,
  Form,
  PrimaryButton,
  SavedCardDetails,
} from '../../components';
import config from '../../config';
import { ensurePaymentMethodCard } from '../../util/data';
import { required } from '../../util/validators';
import css from './PaymentCovidForm.css';

const stripeElementsOptions = {
  fonts: [
    {
      family: 'sofiapro',
      fontSmoothing: 'antialiased',
      src:
        'local("sofiapro"), local("SofiaPro"), local("Sofia Pro"), url("https://assets-sharetribecom.sharetribe.com/webfonts/sofiapro/sofiapro-medium-webfont.woff2") format("woff2")',
    },
  ],
};

const cardStyles = {
  base: {
    fontFamily: '"sofiapro", Helvetica, Arial, sans-serif',
    fontSize: '18px',
    fontSmoothing: 'antialiased',
    lineHeight: '24px',
    letterSpacing: '-0.1px',
    borderBottom: 'solid 2px red',
    color: '#4A4A4A',
    '::placeholder': {
      color: '#B2B2B2',
    },
  },
};

const stripeErrorTranslation = (intl, stripeError) => {
  const { message, code, type } = stripeError;

  if (!code || !type) {
    return intl.formatMessage({ id: 'StripePaymentForm.genericError' });
  }

  const translationId =
    type === 'validation_error'
      ? `StripePaymentForm.stripe.validation_error.${code}`
      : `StripePaymentForm.stripe.${type}`;

  return intl.formatMessage({
    id: translationId,
    defaultMessage: message,
  });
};

const getPaymentMethod = (selectedPaymentMethod, hasDefaultPaymentMethod) => {
  return selectedPaymentMethod == null && hasDefaultPaymentMethod
    ? 'defaultCard'
    : selectedPaymentMethod == null
    ? 'onetimeCardPayment'
    : selectedPaymentMethod;
};

const OneTimePaymentWithCardElement = props => {
  const { formId, handleStripeElementRef, hasCardError, error, label } = props;
  return (
    <React.Fragment>
      <div className={css.card} id={`${formId}-card`} ref={handleStripeElementRef} />
      {hasCardError ? <span className={css.error}>{error}</span> : null}
      {label ? (
        <div className={css.saveForLaterUse}>
          <FieldCheckbox
            className={css.saveForLaterUseCheckbox}
            textClassName={css.saveForLaterUseLabel}
            id="replaceCurrentCard"
            name="replaceCurrentCard"
            label={label}
            useSuccessColor
          />
        </div>
      ) : null}
      {
        // <div className={css.smallText}>
        //   This payment method will be used for applicable monthly subscription fees
        // </div>
      }
    </React.Fragment>
  );
};

const PaymentMethodSelector = props => {
  const {
    cardClasses,
    currentUser,
    formId,
    changePaymentMethod,
    defaultPaymentMethod,
    handleStripeElementRef,
    hasCardError,
    error,
    paymentMethod,
    intl,
  } = props;
  const last4Digits = defaultPaymentMethod.attributes.card.last4Digits;
  const labelText = intl.formatMessage(
    { id: 'PaymentDepositForm.replaceAfterOnetimePayment' },
    { last4Digits }
  );

  return (
    <React.Fragment>
      <h3 className={css.paymentHeading}>
        <FormattedMessage id="StripePaymentForm.payWithHeading" />
      </h3>
      <SavedCardDetails
        currentUser={currentUser}
        className={css.paymentMethodSelector}
        card={defaultPaymentMethod.attributes.card}
        onChange={changePaymentMethod}
      />
      {/* {paymentMethod !== 'replaceCard' ? (
        <div className={css.smallText}>
          This payment method will be used for applicable monthly subscription fees
        </div>
      ) : null} */}
      {paymentMethod === 'replaceCard' ? (
        <OneTimePaymentWithCardElement
          cardClasses={cardClasses}
          formId={formId}
          handleStripeElementRef={handleStripeElementRef}
          hasCardError={hasCardError}
          error={error}
          label={labelText}
          intl={intl}
        />
      ) : null}
    </React.Fragment>
  );
};

const PaymentCovidForm = props => {
  const {
    onSubmit,
    inProgress,
    initiateOrderError,
    defaultPaymentMethod,
    currentUser,
    intl,
    onOpenTermAndConditions,
  } = props;

  const [paymentMethod, setPaymentMethod] = useState(null);
  const [state, setState] = useState({});
  const cardContainerRef = useRef(null);
  const cardRef = useRef(null);
  const stripeRef = useRef(null);

  useEffect(() => {
    if (!window.Stripe) {
      throw new Error('Stripe must be loaded for StripePaymentForm');
    }

    if (config.stripe.publishableKey) {
      const {
        hasHandledCardPayment,
        defaultPaymentMethod,
      } = props;
      stripeRef.current = window.Stripe(config.stripe.publishableKey);

      // if (!(hasHandledCardPayment || defaultPaymentMethod )) {
      //   this.initializeStripeElement();
      // }
    }
    return () => {
      if (cardRef.current) {
        cardRef.current.removeEventListener('change', handleCardValueChange);
        cardRef.current.unmount();
      }
    }
  }, []);

  const ensuredDefaultPaymentMethod = ensurePaymentMethodCard(defaultPaymentMethod);
  const showPaymentMethodSelector = ensuredDefaultPaymentMethod.id;
  const selectedPaymentMethod = getPaymentMethod(paymentMethod, showPaymentMethodSelector);

  const showOnetimePaymentFields = ['onetimeCardPayment', 'replaceCard'].includes(
    selectedPaymentMethod
  );

  const selectViroQLabel = <FormattedMessage id={'PaymentCovidForm.SelectViroQ.selectViroQTitle'}/>;
  const paymentViroQTitle = <FormattedMessage id={'PaymentCovidForm.SelectViroQ.paymentViroQTitle'}/>;
  const selectViroQDescription = <FormattedMessage id={'PaymentCovidForm.SelectViroQ.selectViroQDescription'}/>;
  const optionOneLabel1 = <FormattedHTMLMessage id={'PaymentCovidForm.SelectViroQ.option1'}/>;
  const optionOneLabel2 = <FormattedHTMLMessage id={'PaymentCovidForm.SelectViroQ.option2'}/>;
  const optionOneLabel3 = <FormattedHTMLMessage id={'PaymentCovidForm.SelectViroQ.option3'}/>;

  const handleCardValueChange = event => {
    const { intl } = props;
    const { error, complete } = event;

    setState(prevState => {
      return {
        ...prevState,
        error: error ? stripeErrorTranslation(intl, error) : null,
        cardValueValid: complete,
      };
    });
  };

  const setupStripe = () => {
    if (!window.Stripe) {
      throw new Error('Stripe must be loaded for StripePaymentForm');
    }

    if (config.stripe.publishableKey && !stripeRef.current) {
      stripeRef.current = window.Stripe(config.stripe.publishableKey);
    }
  };

  const initializeStripeElement = element => {
    if (!cardRef.current) {
      const elements = stripeRef.current.elements(stripeElementsOptions);
      cardRef.current = elements.create('card', { style: cardStyles });
      cardRef.current.mount(element || cardContainerRef.current);
      cardRef.current.addEventListener('change', handleCardValueChange);
      // EventListener is the only way to simulate breakpoints with Stripe.
      window.addEventListener('resize', () => {
        if (cardRef.current) {
          if (window.innerWidth < 1024) {
            cardRef.current.update({ style: { base: { fontSize: '18px', lineHeight: '24px' } } });
          } else {
            cardRef.current.update({ style: { base: { fontSize: '24px', lineHeight: '29px' } } });
          }
        }
      });
    }
  };

  const handleStripeElementRef = el => {
    cardContainerRef.current = el;
    setupStripe();
    if (stripeRef.current && el) {
      initializeStripeElement(el);
    }
  };

  const handleSubmit = values => {
    onSubmit({
      ...values,
      card: cardRef.current,
      stripe: stripeRef.current,
      paymentMethod: selectedPaymentMethod,
    });
  };

  const initialValues = {
    replaceCurrentCard: true,
    numberBottles: '1',
    name: get(currentUser, 'attributes.profile.displayName', ''),
  };

  return (
    <FinalForm
      initialValues={initialValues}
      onSubmit={handleSubmit}
      render={({ handleSubmit }) => {
        return (
          <Form onSubmit={handleSubmit} className={css.form}>
            <div className={css.formWrapper}>
              <div className={css.selectViroQ}>
                <div className={css.selectViroQLabelTitle}>
                  {selectViroQLabel}
                </div>
                <div className={css.optionLabel}>
                  <FieldRadioButton
                    id="covidBottle1"
                    name="numberBottles"
                    label={optionOneLabel1}
                    value='1'
                    showAsRequired={true}
                    labelClass={css.selectViroQLabel}
                  />
                  <div className={css.selectViroQDescription}> {selectViroQDescription} </div>
                </div>
                <div className={css.optionLabel}>
                  <FieldRadioButton
                    id="covidBottle3"
                    name="numberBottles"
                    label={optionOneLabel2}
                    value='3'
                    showAsRequired={true}
                    labelClass={css.selectViroQLabel}
                  />
                  <div className={css.selectViroQDescription}> {selectViroQDescription} </div>
                </div>
                <div className={css.optionLabel}>
                  <FieldRadioButton
                    id="covidBottle5"
                    name="numberBottles"
                    label={optionOneLabel3}
                    value='5'
                    showAsRequired={true}
                    labelClass={css.selectViroQLabel}
                  />
                  <div className={css.selectViroQDescription}> {selectViroQDescription} </div>
                </div>
              </div>
              <div className={css.cardPaymentWrapper}>
                <div className={css.selectViroQLabelTitle}>
                  {paymentViroQTitle}
                </div>
                <FieldTextInput
                  type="text"
                  id={'covid.name'}
                  name="name"
                  label="Name:"
                  className={css.textField}
                  errorClassName={css.error}
                  validate={required(intl.formatMessage({ id: 'ProfileSettingsForm.firstNameRequired' }))}
                />
                <FieldTextInput
                  type="text"
                  id={'covid.deliveryAddress'}
                  name="deliveryAddress"
                  label="Delivery Address:"
                  className={css.textField}
                  errorClassName={css.error}
                  validate={required(intl.formatMessage({ id: 'ProfileSettingsForm.firstNameRequired' }))}
                />
                {currentUser && currentUser.id ? (
                  <Fragment>
                    {showPaymentMethodSelector ? (
                      <PaymentMethodSelector
                        currentUser={currentUser}
                        defaultPaymentMethod={ensuredDefaultPaymentMethod}
                        changePaymentMethod={v => setPaymentMethod(v)}
                        handleStripeElementRef={handleStripeElementRef}
                        paymentMethod={selectedPaymentMethod}
                        intl={intl}
                        formId="paymentDepositForm"
                      />
                    ) : (
                      <React.Fragment>
                        <h3 className={css.paymentHeading}>
                          <FormattedMessage id="StripePaymentForm.paymentHeading" />
                        </h3>
                        <OneTimePaymentWithCardElement
                          handleStripeElementRef={handleStripeElementRef}
                          intl={intl}
                          formId="paymentDepositForm"
                        />
                      </React.Fragment>
                    )}
                  </Fragment>
                ) : null}
              </div>
            </div>


            {initiateOrderError && (
              <div className={css.error}>
                <FormattedMessage id="PaymentDepositForm.error" />
              </div>
            )}
            <PrimaryButton
              className={css.submit}
              type="submit"
              inProgress={inProgress}
              disabled={showOnetimePaymentFields && !state.cardValueValid}
            >
              <FormattedMessage id="PaymentCovidForm.SelectViroQ.submitButton" />
            </PrimaryButton>
            <div className={css.termAndConditions}>
              <FormattedMessage id="PaymentCovidForm.conditionDelivery" /> <br />
              <a href="javascript:void(0)" onClick={onOpenTermAndConditions} > <FormattedMessage id="PaymentCovidForm.termAndConditions" /> </a>
            </div>
          </Form>
        );
      }}
    />
  );
};

export default PaymentCovidForm;
