import classNames from 'classnames';
import { array, arrayOf, bool, func, shape, string } from 'prop-types';
import React, { Component } from 'react';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { compose } from 'redux';
import { Button, FieldRadioButton, FieldSelect, Form, IconCheckmark } from '../../components';
import { uploadFiles } from '../../util/fileUpload';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { required } from '../../util/validators';

import css from './EditListingInsuranceForm.css';

export class EditListingInsuranceFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      insuranceType: null,
      companyOwned: null,
      isNotUpload: true,
      isUploading: false,
      isNotUpload2: true,
      isUploading2: false,
      uploadError: null,
      uploadError2: null,
      authorizationDocumentUrl: props.authorizationDocumentUrl,
      insuranceDocumentUrl: props.insuranceDocumentUrl,
    };
    this.handleselectedFile = this.handleselectedFile.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.props.setInsuranceDocumentUrl(props.insuranceDocumentUrl);
    this.props.setAuthorizationDocumentUrl(props.authorizationDocumentUrl);
  }

  handleselectedFile = (event, fileType) => {
    if (!event.target.files || !event.target.files.length || event.target.files.length === 0)
      return;
    this.handleUpload(event.target.files, fileType);
  };

  handleUpload = (files, fileType) => {
    const user = this.props.currentUser;

    if (!user.id) {
      return;
    }
    if (fileType === 'insurance') {
      this.setState({
        uploadError: null,
      });
    } else {
      this.setState({
        uploadError2: null,
      });
    }
    if (fileType === 'insurance') {
      this.props.setInsuranceDocumentUrl([]);
      this.setState({
        insuranceDocumentUrl: [],
      });
    } else {
      this.props.setAuthorizationDocumentUrl([]);
      this.setState({
        authorizationDocumentUrl: [],
      });
    }

    for (let file of files) {
      let data = new FormData();
      data.append('file', file, file.name);
      data.append('userId', user.id.uuid);
      data.append('userEmail', user.attributes.email);
      if (fileType === 'insurance') {
        this.setState({
          isUploading: true,
          isNotUpload: true,
        });
      } else {
        this.setState({
          isUploading2: true,
          isNotUpload2: true,
        });
      }
      uploadFiles(data)
        .then(response => {
          const code = response.status;
          if (code !== 200) {
            return null;
          }
          return response.json();
        })
        .then(jsonResponse => {
          if (!jsonResponse.url) {
            const error = {
              id: 'EditListingInsuranceForm.genericUploadError',
              fileType: fileType,
            };
            if (fileType === 'insurance') {
              this.setState({
                isUploading: false,
                isNotUpload: true,
                uploadError: error,
              });
            } else {
              this.setState({
                isUploading2: false,
                isNotUpload2: true,
                uploadError2: error,
              });
            }
          } else {
            if (fileType === 'insurance') {
              this.setState({
                isUploading: false,
                isNotUpload: false,
              });
            } else {
              this.setState({
                isUploading2: false,
                isNotUpload2: false,
              });
            }
            if (fileType === 'insurance') {
              let insuranceArray = this.state.insuranceDocumentUrl;
              insuranceArray
                ? insuranceArray.push(jsonResponse.url)
                : (insuranceArray = [jsonResponse.url]);
              this.setState({
                insuranceDocumentUrl: insuranceArray,
              });
              this.props.setInsuranceDocumentUrl(insuranceArray);
            } else {
              let authorizeArray = this.state.authorizationDocumentUrl;
              authorizeArray
                ? authorizeArray.push(jsonResponse.url)
                : (authorizeArray = [jsonResponse.url]);
              this.setState({
                authorizationDocumentUrl: authorizeArray,
              });
              this.props.setAuthorizationDocumentUrl(authorizeArray);
            }
          }
        })
        .catch(e => {
          const error = {
            id: 'EditListingInsuranceForm.genericUploadError',
            fileType: fileType,
          };
          if (fileType === 'insurance') {
            this.setState({
              isUploading: false,
              isNotUpload: true,
              uploadError: error,
            });
          } else {
            this.setState({
              isUploading2: false,
              isNotUpload2: true,
              uploadError2: error,
            });
          }
        });
    }
  };

  render() {
    return (
      <FinalForm
        {...this.props}
        render={fieldRenderProps => {
          const {
            className,
            disabled,
            fetchErrors,
            handleSubmit,
            intl,
            invalid,
            saveActionMsg,
            updated,
            updateInProgress,
            pristine,
            insurances,
            yesNo,
            privateInsurerList,
            isNewListingFlow,
            values,
            submitButtonId,
          } = fieldRenderProps;

          const insuranceUploadLabel = intl.formatMessage({
            id: 'EditListingInsuranceForm.insuranceUploadLabel',
          });

          const insuranceUploadButtonLabel = intl.formatMessage({
            id: 'EditListingInsuranceForm.insuranceUploadButtonLabel',
          });

          const authorizeLetterUploadLabel = intl.formatMessage({
            id: 'EditListingInsuranceForm.authorizeLetterUploadLabel',
          });

          const authorizeLetterUploadButtonLabel = intl.formatMessage({
            id: 'EditListingInsuranceForm.authorizeLetterUploadButtonLabel',
          });

          // const insuranceLabel = intl.formatMessage({
          //   id: 'EditListingInsuranceForm.insuranceLabel',
          // });
          const insuranceLabel = intl.formatMessage({
            id: 'EditListingInsuranceForm.insuranceTypeLabel',
          });
          const insuranceTypeInfo = intl.formatMessage({
            id: 'EditListingInsuranceForm.insuranceTypeInfo',
          });
          const insuranceRequired = required(
            intl.formatMessage({
              id: 'EditListingInsuranceForm.insuranceRequired',
            })
          );
          const insurancePlaceholder = intl.formatMessage({
            id: 'EditListingInsuranceForm.insurancePlaceholder',
          });

          const companyOwnedLabel = intl.formatMessage({
            id: 'EditListingInsuranceForm.companyOwnedLabel',
          });
          const privateInsurerLabel = intl.formatMessage({
            id: 'EditListingInsuranceForm.privateInsurerLabel',
          });
          const companyOwnedRequired =  required(
            intl.formatMessage({
              id: 'EditListingInsuranceForm.companyOwnedRequired',
            })
          );
          const companyOwnedPlaceholder = intl.formatMessage({
            id: 'EditListingInsuranceForm.companyOwnedPlaceholder',
          });

          const phvLabel = intl.formatMessage({
            id: 'EditListingInsuranceForm.phvLabel',
          });
          const pvhRequiredMessage = intl.formatMessage({
            id: 'EditListingInsuranceForm.pvhRequiredMessage',
          });
          const pvhRequired = required(pvhRequiredMessage);

          const { updateListingError, showListingsError } = fetchErrors || {};
          const errorMessage = updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingInsuranceForm.updateFailed" />
            </p>
          ) : null;
          const errorMessageShowListing = showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingInsuranceForm.showListingFailed" />
            </p>
          ) : null;

          const classes = classNames(css.root, className);
          const submitReady = updated && pristine;
          const submitInProgress = updateInProgress || this.state.isUploading;
          const needUploadFilePrivate = false;
          const needUploadFileCommercialInsurance =
            this.state.insuranceType === 'commercial' &&
            Array.isArray(this.state.insuranceDocumentUrl) &&
            !this.state.insuranceDocumentUrl.length;
          const needUploadFileCommercialAuthorize =
            this.state.insuranceType === 'commercial' &&
            values.companyOwned === 'yes' &&
            Array.isArray(this.state.authorizationDocumentUrl) &&
            !this.state.authorizationDocumentUrl.length;
          const needUploadFileCommercial =
            needUploadFileCommercialInsurance || needUploadFileCommercialAuthorize;
          const needUploadFile = needUploadFileCommercial || needUploadFilePrivate;
          const submitDisabled =
            invalid || disabled || submitInProgress || !isNewListingFlow || needUploadFile;
          const companyOwnedAnswerLayout =
            this.state.companyOwned === 'yes' ? (
              <div>
                <p>{authorizeLetterUploadLabel}</p>

                <div className={css.category}>
                  <Button
                    type="button"
                    className={css.uploadButton}
                    children={
                      <div className={css.uploadButtonContent}>
                        {authorizeLetterUploadButtonLabel}
                        &nbsp;&nbsp;
                        {!this.state.isNotUpload2 ? (
                          <IconCheckmark className={css.checkmark} />
                        ) : this.props.hasAuthorizationDocumentUrl ? (
                          <IconCheckmark className={css.checkmark} />
                        ) : null}
                      </div>
                    }
                    inProgress={this.state.isUploading2}
                    onClick={() => {
                      this.inputElement2.click();
                    }}
                  />
                  <input
                    className={css.uploadInput}
                    type="file"
                    name="file-upload-authorize"
                    id="file-upload-authorize"
                    onChange={e => {
                      this.handleselectedFile(e, 'authorization');
                    }}
                    multiple="multiple"
                    ref={input => (this.inputElement2 = input)}
                  />
                </div>
              </div>
            ) : null;

          const companyOwnedLayout = (
            <div>
              <FieldSelect
                className={css.category}
                name="companyOwned"
                id="companyOwned"
                label={companyOwnedLabel}
                validate={companyOwnedRequired}
              >
                <option disabled value="">
                  {companyOwnedPlaceholder}
                </option>
                {yesNo.map(c => (
                  <option key={c.key} value={c.key}>
                    {c.label}
                  </option>
                ))}
              </FieldSelect>
              {companyOwnedAnswerLayout}
            </div>
          );

          const privateInsurerLayout = (
            <div>
              <FieldSelect
                  className={css.category}
                  name="privateInsurer"
                  id="privateInsurer"
                  label={privateInsurerLabel}
                  // validate={companyOwnedRequired}
                >
                  {privateInsurerList.map(c => (
                    <option key={c.key} value={c.key}>
                      {c.label}
                    </option>
                  ))}
              </FieldSelect> 
              {companyOwnedAnswerLayout}
            </div>
          )

          const phvSelect = (
            <div>
              <FieldSelect
                className={css.category}
                name="isPHV"
                id="isPHV"
                label={phvLabel}
                validate={pvhRequired}
              >
                <option disabled value="">
                  {companyOwnedPlaceholder}
                </option>
                {yesNo.map(c => (
                  <option key={c.key} value={c.key}>
                    {c.label}
                  </option>
                ))}
              </FieldSelect>
            </div>
          );

          // const privateAnwserLayout = this.state.insuranceType === "private" ? privateInsurerLayout : null;

          const commercialAnwserLayout =
            this.state.insuranceType === 'commercial' ? (
              <div>
                <p>{insuranceUploadLabel}</p>

                <div className={css.category}>
                  <Button
                    type="button"
                    className={css.uploadButton}
                    children={
                      <div className={css.uploadButtonContent}>
                        {insuranceUploadButtonLabel}
                        &nbsp;&nbsp;
                        {!this.state.isNotUpload ? (
                          <IconCheckmark className={css.checkmark} />
                        ) : this.props.hasInsuranceDocumentUrl ? (
                          <IconCheckmark className={css.checkmark} />
                        ) : null}
                      </div>
                    }
                    inProgress={this.state.isUploading}
                    onClick={() => {
                      this.inputElement.click();
                    }}
                  />
                  <input
                    className={css.uploadInput}
                    type="file"
                    name="file-upload-insurance"
                    id="file-upload-insurance"
                    onChange={e => {
                      this.handleselectedFile(e, 'insurance');
                    }}
                    multiple="multiple"
                    ref={input => (this.inputElement = input)}
                  />
                </div>
                {companyOwnedLayout}
                {phvSelect}
              </div>
            ) : null;

          const uploadError = this.state.uploadError ? (
            <p className={css.error}>
              <FormattedMessage
                id={this.state.uploadError.id}
                values={{ fileType: this.state.uploadError.fileType }}
              />
            </p>
          ) : null;
          const uploadError2 = this.state.uploadError2 ? (
            <p className={css.error}>
              <FormattedMessage
                id={this.state.uploadError2.id}
                values={{ fileType: this.state.uploadError2.fileType }}
              />
            </p>
          ) : null;

          return (
            <Form className={classes} onSubmit={handleSubmit}>
              <FormSpy
                onChange={input => {
                  if (this.state.insuranceType !== input.values.insurance) {
                    this.setState({
                      isNotUpload: true,
                      insuranceType: input.values.insurance,
                    });
                  }
                  if (this.state.companyOwned !== input.values.companyOwned) {
                    this.setState({
                      companyOwned: input.values.companyOwned,
                    });
                    if (
                      this.state.companyOwned === 'yes' &&
                      this.state.authorizationDocumentUrl &&
                      this.state.authorizationDocumentUrl.length === 0
                    ) {
                      this.setState({
                        isNotUpload: true,
                      });
                    }
                  }
                }}
              />
              {errorMessage}
              {uploadError}
              {uploadError2}
              {errorMessageShowListing}
              <div className={css.displayInlineContainer}>
                {/* <div className={classNames(css.column, css.column12)}>
                  <FieldSelect
                    className={css.category}
                    name="insurance"
                    id="insurance"
                    label={insuranceLabel}
                    validate={insuranceRequired}
                  >
                    <option disabled value="">
                      {insurancePlaceholder}
                    </option>
                    {insurances.map(c => (
                      <option key={c.key} value={c.key}>
                        {c.label}
                      </option>
                    ))}
                  </FieldSelect>
                </div> */}
                <div className={classNames(css.column, css.column12)}>
                  <div className={css.insuranceSelection}>
                    <div className={css.customRadioOuter}>
                      <div className={css.customRadioLabel}>
                        <label>{insuranceLabel}</label>
                      </div>
                      <div className={css.radioGroup}>
                        {insurances.map(c => (
                          <FieldRadioButton
                            key={c.key}
                            id={c.key}
                            name="insurance"
                            label={
                              c.key === 'private' ? 'Private Insurance' : 'Commercial Insurance'
                            }
                            value={c.key}
                            className={css.customRadio}
                            showAsRequired={true}
                            validate={insuranceRequired}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                {this.state.insuranceType === 'private' && (
                  <div className={classNames(css.column, css.column12)}>
                    <div className={css.bigTip}>{insuranceTypeInfo}</div>
                  </div>
                )}
              </div>

              {/* {privateAnwserLayout}  */}
              {commercialAnwserLayout}
              <div className={css.stickyButtons}>
                <div className={css.stickButtonsContainer}>
                  <div className={css.stickButtonsDescription}></div>
                  <div className={css.stickButton}>
                    <Button
                      className={css.submitButton}
                      type="submit"
                      inProgress={submitInProgress}
                      disabled={submitDisabled}
                      ready={submitReady}
                      id={submitButtonId}
                    >
                      {/* {saveActionMsg} */}
                      Next
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

EditListingInsuranceFormComponent.defaultProps = {
  selectedPlace: null,
  updateError: null,
};

EditListingInsuranceFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  insurances: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
  yesNo: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
  // privateInsurerList:arrayOf(
  //   shape({
  //     key: string.isRequired,
  //     label: string.isRequired,
  //   })
  // ),
  insuranceDocumentUrl: array,
  authorizationDocumentUrl: array,
  setInsuranceDocumentUrl: func.isRequired,
  setAuthorizationDocumentUrl: func.isRequired,
  isNewListingFlow: bool.isRequired,
  submitButtonId: string,
};

export default compose(injectIntl)(EditListingInsuranceFormComponent);
