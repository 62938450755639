import {
  EVENT_TRIP_FEE_EXPERIMENT_CASE_A,
  EVENT_TRIP_FEE_EXPERIMENT_CASE_B,
  EVENT_TRIP_FEE_EXPERIMENT_CASE_C,
  EVENT_TRIP_FEE_EXPERIMENT_CASE_D
} from './gtm/gtmConstants';
/**
 * This method will return the postal District by postalCode
 * @param {string} postalCode
 * @returns {string} postalDistrict
 */
export const getPostalDistrict = (postalCode) => {
    postalCode = postalCode || '';
    const codeList = {
        "01": "1",
        "02": "1",
        "03": "1",
        "04": "1",
        "05": "1",
        "06": "1",
        "07": "2",
        "08": "2",
        "09": "4",
        "10": "4",
        "11": "5",
        "12": "5",
        "13": "5",
        "14": "3",
        "15": "3",
        "16": "3",
        "17": "6",
        "18": "7",
        "19": "7",
        "20": "8",
        "21": "8",
        "22": "9",
        "23": "9",
        "24": "10",
        "25": "10",
        "26": "10",
        "27": "10",
        "28": "11",
        "29": "11",
        "30": "11",
        "31": "12",
        "32": "12",
        "33": "12",
        "34": "13",
        "35": "13",
        "36": "13",
        "37": "13",
        "38": "14",
        "39": "14",
        "40": "14",
        "41": "14",
        "42": "15",
        "43": "15",
        "44": "15",
        "45": "15",
        "46": "16",
        "47": "16",
        "48": "16",
        "49": "17",
        "50": "17",
        "51": "18",
        "52": "18",
        "53": "19",
        "54": "19",
        "55": "19",
        "56": "20",
        "57": "20",
        "58": "21",
        "59": "21",
        "60": "22",
        "61": "22",
        "62": "22",
        "63": "22",
        "64": "22",
        "65": "23",
        "66": "23",
        "67": "23",
        "68": "23",
        "69": "24",
        "70": "24",
        "71": "24",
        "72": "25",
        "73": "25",
        "75": "27",
        "76": "27",
        "77": "26",
        "78": "26",
        "79": "28",
        "80": "28",
        "81": "17",
        "82": "19"
    }
    const twoChars = postalCode.substring(0, 2);
    if (twoChars in codeList) {
        return codeList[twoChars]
    }
    return;
}

/**
 * Generate random number
 * @returns {string}
 */
export const getRandomNumber = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

/**
 * Convert valid numeric string value to number or null
 * @param {string} str
 * @returns {number|null}
 */
export const toNumber = str => {
  const num = Number.parseInt(str, 10);
  return Number.isNaN(num) ? null : num;
};

// /**
//  * This method will return the experiment is being user for trip fees experiment
//  * @param {string} TripFeesExperiment
//  * @returns
//  */
// export const getPUSHTELLTripFeesExperiment = (TripFeesExperiment) => {
//   try {
//     if (typeof window !== 'undefined' && typeof window.localStorage !== 'undefined') {
//       console.log('Trip Fees Experiment getPUSHTELLTripFeesExperiment:', window.localStorage.getItem(`PUSHTELL-${TripFeesExperiment}`));
//       return window.localStorage.getItem(`PUSHTELL-${TripFeesExperiment}`);
//     }
//   } catch (error) {
//   }
//   return null;
// };

/**
 * Trip Fees Experiment Setup
 * @param {*} emitter
 * @param {Function} doEventForTripFeesExperiment
 * @param {Function} cb
 * @returns {void}
 */
// export const doTripFeesExperimentSetup = (emitter, TripFeesExperiment, doEventForTripFeesExperiment, cb) => {
//   if (!emitter) return;
//   // Setup an experiment if not already exists
//   console.log('Setup is in progress for `Trip Fees Experiment`!');
//   let tripFeesExperimentData = getPUSHTELLTripFeesExperiment(TripFeesExperiment.name);
//   console.log(`Trip Fees Experiment tripFeesExperimentData:`, tripFeesExperimentData);
//   let TRIP_FEE_EXPERIMENT_DATA = {
//     experimentName: TripFeesExperiment.name,
//     variantName: tripFeesExperimentData || '',
//   };

//   if (!tripFeesExperimentData) {
//     // Run trip fees experiment
//     emitter.defineVariants(TripFeesExperiment.name,
//       [
//         TripFeesExperiment.A,
//         TripFeesExperiment.B,
//         TripFeesExperiment.C,
//         TripFeesExperiment.D,
//       ],
//       [25, 25, 25, 25]
//     );
//     emitter.addPlayListener(TripFeesExperiment.name, (experimentName, variantName) => {
//       TRIP_FEE_EXPERIMENT_DATA.experimentName = experimentName;
//       TRIP_FEE_EXPERIMENT_DATA.variantName = variantName;
//       console.log(`Trip Fees Experiment(${TripFeesExperiment.name}) Name: ${experimentName}, Variant Name: ${variantName}`);
//       console.log(`Trip Fees Experiment TRIP_FEE_EXPERIMENT_DATA:`, TRIP_FEE_EXPERIMENT_DATA);
//       if (TRIP_FEE_EXPERIMENT_DATA.experimentName === TripFeesExperiment.name) {
//         doEventForTripFeesExperiment((TRIP_FEE_EXPERIMENT_DATA.variantName || '').toLowerCase());
//         const value = getTripFeesExperimentCase(TRIP_FEE_EXPERIMENT_DATA.experimentName);
//         if (value && cb) cb(value);
//       }
//     });
//     emitter.addWinListener(TripFeesExperiment.name, (experimentName, variantName) => {
//       console.log(`Trip Fees Experiment Win Listener: (${TripFeesExperiment.name}) Name: ${experimentName}, Variant Name: ${variantName}`);
//     });
//     emitter.emitWin(TripFeesExperiment.name);
//     console.log(`Trip Fees Experiment emitWin done.`);
//   } else {
//     console.log('Trip Fees Experiment TRIP_FEE_EXPERIMENT_DATA', TRIP_FEE_EXPERIMENT_DATA);
//     if (TRIP_FEE_EXPERIMENT_DATA && TRIP_FEE_EXPERIMENT_DATA.variantName) {
//       doEventForTripFeesExperiment((TRIP_FEE_EXPERIMENT_DATA.variantName || '').toLowerCase());
//       const value = getTripFeesExperimentCase(TRIP_FEE_EXPERIMENT_DATA.experimentName);
//       if (value && cb) cb(value);
//     }
//   }
//   console.log('Setup complete for `Trip Fees Experiment`!');
// }

/**
 * This method will send the gtm event for trip fees experiment
 * @param {string} tripFeesExperimentName
 */
// export const getTripFeesExperimentCase = (tripFeesExperimentName) => {
//   let result = '';
//   if (tripFeesExperimentName) {
//     let _case_ = getPUSHTELLTripFeesExperiment(tripFeesExperimentName);
//     console.log('Trip Fees Experiment getTripFeesExperimentCase:', _case_);
//     if (_case_ && EVENT_TRIP_FEE_EXPERIMENT_CASE_A.includes(_case_.toLowerCase())) {
//       result = 'A';
//     } else if (_case_ && EVENT_TRIP_FEE_EXPERIMENT_CASE_B.includes(_case_.toLowerCase())) {
//       result = 'B';
//     } else if (_case_ && EVENT_TRIP_FEE_EXPERIMENT_CASE_C.includes(_case_.toLowerCase())) {
//       result = 'C';
//     } else if (_case_ && EVENT_TRIP_FEE_EXPERIMENT_CASE_D.includes(_case_.toLowerCase())) {
//       result = 'D';
//     } else {
//       console.log('getTripFeesExperimentCase: No Experiment Match For Trip Fee Experiment, Case:', _case_);
//     }
//   }
//   return result;
// };
