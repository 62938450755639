import React from 'react';
import css from './LandingPageGo.css';
import classNames from 'classnames';

import goLogo from '../../assets/logos/GoLogo.png';
import goLogo2x from '../../assets/logos/GoLogo@2x.png';
import { FormattedMessage } from 'react-intl';
import { ExternalLink } from '../../components';
import Slider from 'react-slick';

const VideoTestimonials = ({}) => {
  const itemObject = [
    {
      id: 1,
      videoUrl:
        'https://drivelah-public-files.s3.ap-southeast-1.amazonaws.com/videos/DL-Go-Testimonials/Drivelah_CT1.mp4',
      videoThumbnails:
        'https://drivelah-public-files.s3.ap-southeast-1.amazonaws.com/videos/DL-Go-Testimonials/dl-go-testimonial-thumbnail-1.png',
    },
    {
      id: 2,
      videoUrl:
        'https://drivelah-public-files.s3.ap-southeast-1.amazonaws.com/videos/DL-Go-Testimonials/Drivelah_CT2.mp4',
      videoThumbnails:
        'https://drivelah-public-files.s3.ap-southeast-1.amazonaws.com/videos/DL-Go-Testimonials/dl-go-testimonials-thumbnail-2.png',
    },
    {
      id: 3,
      videoUrl:
        'https://drivelah-public-files.s3.ap-southeast-1.amazonaws.com/videos/DL-Go-Testimonials/Drivelah_CT3.mp4',
      videoThumbnails:
        'https://drivelah-public-files.s3.ap-southeast-1.amazonaws.com/videos/DL-Go-Testimonials/dl-go-testimonial-thumbnail-3.png',
    },
    {
      id: 4,
      videoUrl:
        'https://drivelah-public-files.s3.ap-southeast-1.amazonaws.com/videos/DL-Go-Testimonials/Drivelah_CT4.mp4',
      videoThumbnails:
        'https://drivelah-public-files.s3.ap-southeast-1.amazonaws.com/videos/DL-Go-Testimonials/dl-go-testimonials-thumbnail-4.png',
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 1500,
    centerMode: true,
    autoplay: false,
    arrows: false,
    // centerPadding: "25px",
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnDotsHover: true,
    pauseOnFocus: true,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          centerMode: false,
          pauseOnDotsHover: true,
          pauseOnFocus: true,
          pauseOnHover: true,
          speed: 1500,
          autoplay: false,
        },
      },
    ],
  };

  return (
    <Slider {...settings} className={css.slider}>
      {itemObject &&
        itemObject.length > 0 &&
        itemObject.map(item => {
          return (
            <div style={{ padding: '0 20px' }}>
              <div key={item.id} className={css.videoWrapper}>
                <video
                  id={`videoIframe-${item.id}`}
                  className={css.iframeVideo}
                  controls
                  allowFullScreen={true}
                  src={item.videoUrl}
                  poster={item.videoThumbnails}
                >
                  <source src={item.videoUrl} type="video/mp4"></source>
                  <source src={item.videoUrl} type="video/ogg"></source>
                </video>
              </div>
            </div>
          );
        })}
    </Slider>
  );
};

const SectionVision = ({ handleLearnMore }) => {
  return (
    <div className={css.sectionVision}>
      <div className={css.visionBackgroundImage}>
        <div className={css.sectionVisionContentWrapper}>
          <img srcSet={`${goLogo} 1x, ${goLogo2x} 2x`} className={css.goLogo} />
          <div className={css.sectionVisionContent}>
            <h3 className={classNames(css.title, css.alignCenter, css.titleDark)}>
              <FormattedMessage id="LandingPageGo.SectionVision.title" />
            </h3>
            <div className={css.description}>
              <FormattedMessage id="LandingPageGo.SectionVision.description1" />
            </div>
            <div className={css.description}>
              <FormattedMessage id="LandingPageGo.SectionVision.description2" />
            </div>
            <div className={css.description}>
              <FormattedMessage id="LandingPageGo.SectionVision.description3" />
            </div>
            <div className={css.videoTestimonialSlider}>
              <VideoTestimonials />
            </div>
            <div className={classNames(css.highlightText, css.alignCenter)}>
              {/* <FormattedMessage id='LandingPageGo.SectionVision.highlightText' /> */}
              To know more about Drive lah Go and get your questions answered,
              block our calendar for a call with the host team.
            </div>
            <ExternalLink
              href="https://calendly.com/meetdrivelah/introduction-to-drive-lah-go-keyless-access-technology"
              className={css.outlineButton}
            >
              Book a Call
            </ExternalLink>
            {/* <div className={css.outlineButton} onClick={handleLearnMore}>
              Book a Call
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionVision;
