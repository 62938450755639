import React from 'react';
import css from './LandingPageGoHost.css';
import { FormattedMessage } from 'react-intl';

const SectionImpact = () => {
  return (
    <div className={css.sectionImpact}>
      <div className={css.sectionImpactContentWrapper}>
        <div className={css.title}>
          <FormattedMessage id='LandingPageGoHost.SectionImpact.title' />
        </div>
        <div className={css.answer}>
          <FormattedMessage id='LandingPageGoHost.SectionImpact.answer' />
        </div>
        <div className={css.description}>
          <FormattedMessage id='LandingPageGoHost.SectionImpact.description' />
        </div>
      </div>
    </div>
  )
};

export default SectionImpact;