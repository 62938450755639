import { get } from 'lodash';
import {
  STRIPE_ACCOUNT_RESTRICTED_SOON_STATUS,
  STRIPE_ACCOUNT_RESTRICTED_STATUS,
} from '../util/constants/stripe.constants';

// ================ Action types ================ //

export const SET_CURRENT_USER_STRIPE_ACCOUNT_RESTRICTED = 'app/user/SET_CURRENT_USER_STRIPE_ACCOUNT_RESTRICTED';
export const CLOSE_ACCOUNT_RESTRICTED_ALERT = 'app/user/CLOSE_ACCOUNT_RESTRICTED_ALERT';

const initialState = {
  currentUserStripeAccountRestricted: false
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_CURRENT_USER_STRIPE_ACCOUNT_RESTRICTED:
      return { ...state, currentUserStripeAccountRestricted: payload }
    case CLOSE_ACCOUNT_RESTRICTED_ALERT:
      return { ...state, currentUserStripeAccountRestricted: false }

    default:
      return state;
  }
}

// ================ Selectors ================ //

export const $currentUserStripeAccountRestricted = state => {
  return state.alert.currentUserStripeAccountRestricted;
};

// ================ Action creators ================ //

export const setCurrentUserStripeAccountRestricted = (value) => ({
  type: SET_CURRENT_USER_STRIPE_ACCOUNT_RESTRICTED,
  payload: value
})

export const closeAccountRestrictedAlert = () => ({
  type: CLOSE_ACCOUNT_RESTRICTED_ALERT
})

// ================ Thunks ================ //

export const checkUserStripeAccountStatus = (isAuthenticated) => (dispatch, state, sdk) => {
  if (!isAuthenticated) {
    return;
  }

  sdk.currentUser.show().then(async res => {
    
    const status = get(
      res,
      'data.data.attributes.profile.metadata.stripeConnectedAccountData.accountStatus'
    );

    if (status) {
      const liveListings = await sdk.ownListings
        .query()
        .then(res => res.data.data.filter(listing => get(listing,'attributes.metadata.live')));

      const isRestricted =
        [STRIPE_ACCOUNT_RESTRICTED_STATUS, STRIPE_ACCOUNT_RESTRICTED_SOON_STATUS].includes(
          status
        ) && liveListings.length >= 1;

      dispatch(setCurrentUserStripeAccountRestricted(isRestricted));
    }
  });
};

export const closeStripeAccountRestrictedAlert = () => dispatch => {
  dispatch(closeAccountRestrictedAlert());
};
