import { bool } from 'prop-types';
import React from 'react';
import { formatMoney } from '../../util/currency';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { types as sdkTypes } from '../../util/sdkLoader';
import { LINE_ITEM_DEDUCTIONS, propTypes } from '../../util/types';

import css from './BookingBreakdown.css';
import Explanation from './Explaination';
import { get } from 'lodash';

const { Money } = sdkTypes;

const isValidCommission = commissionLineItem => {
  return (
    commissionLineItem &&
    commissionLineItem.lineTotal instanceof Money &&
    commissionLineItem.lineTotal.amount <= 0
  );
};

const LineItemProviderDeductionsMaybe = props => {
  const { transaction, isProvider, intl } = props;

  const providerDeductionsLineItem = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_DEDUCTIONS && !item.reversal
  );

  let deductionsItem = null;

  if (isProvider && providerDeductionsLineItem) {
    if (!isValidCommission(providerDeductionsLineItem)) {
      return null;
    }

    const explanationText = get(transaction, 'attributes.protectedData.hostAmountDeductableReason', '')

    const commission = providerDeductionsLineItem.lineTotal;
    const formattedCommission = commission ? formatMoney(intl, commission, 1) : null;

    deductionsItem = (
      <div className={css.lineItem}>
        <span className={css.itemLabel}>
          <FormattedMessage id="BookingBreakdown.deductions" />
          <Explanation text={explanationText} />
        </span>
        <span className={css.itemValue}>{formattedCommission}</span>
      </div>
    );
  }

  return deductionsItem;
};

LineItemProviderDeductionsMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isProvider: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemProviderDeductionsMaybe;
