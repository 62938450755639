import React, { Component, useEffect, useState } from 'react';
import { func, string } from 'prop-types';
import { get } from 'lodash';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions, withViewport } from '../../util/contextHelpers';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { formatHourlyMoney, formatMoney } from '../../util/currency';
import { ensureListing, ensureUser, getListingMonthlyPrice } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import { NamedLink, ResponsiveImage } from '../../components';
import moment from 'moment';
import css from './NewHostListingCard.css';
import { distanceCountHelper } from '../../util/distanceCountHelper';
import { findSelectedDates, timestampToDate } from '../../util/dates';
import dollarSign from '../../assets/newHostSearchSliderImages/dollarSign.svg';

const LIMIT_DISTANCE = 6;
const MIN_LENGTH_FOR_LONG_WORDS = 10;
const priceData = (price, intl, perMonthPrice = false, listing) => {
  if (price && price.currency === config.currency) {
    let priceMaybe = perMonthPrice ? getListingMonthlyPrice(listing) : price;
    let formattedPrice = formatMoney(intl, priceMaybe, 1);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const formattedTime = time => {
  const date = timestampToDate(time);
  return moment(date).format('ha');
};
class ListingImage extends Component {
  render() {

    return (
      <>
        <ResponsiveImage {...this.props} />
        {this.props.isNewCar && <span className={css.newLabel}> 50% off </span>}
      </>
    );
  }
}

const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const NewHostListingCardComponent = props => {
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    searchDate,
    location,
    onSelectCard,
    searchTimes,
    showMonthlyPrice,
    currentUser,
    tripPrice,
    tripPriceInProgress,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const [showBanner, setShowBanner] = useState(false);
  const [showArrows, setShowArrows] = useState(false);
  const { publicData, metadata = {} } = currentListing.attributes;
  const { delivery } = publicData || {};
  const { isDrivelahGo = false, reviews = {} } = metadata;
  const id = currentListing.id.uuid;
  const { title = '', price } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const { isDisinfected } = metadata;

  const currentUserLocation = get(
    currentUser,
    'attributes.profile.protectedData.location.selectedPlace.origin'
  );
  const listingLocation = get(listing, 'attributes.geolocation');
  const distance =
    currentUserLocation &&
    listingLocation &&
    distanceCountHelper(currentUserLocation, listingLocation);
  const editedDistance = distance && parseFloat(distance).toFixed(1);
  const showDelivery = delivery && (distance <= LIMIT_DISTANCE || !currentUserLocation);

  const unitType = config.bookingUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  let unitTranslationKey = showMonthlyPrice
    ? 'ListingCard.monthly'
    : isNightly
    ? 'ListingCard.perNight'
    : isDaily
    ? 'ListingCard.perDay'
    : 'ListingCard.perUnit';

  const pickDropWindows = get(listing, 'attributes.publicData.pickDropWindows');

  const searchDateArr = searchDate ? searchDate.split(',') : null;
  const validSearchDate = searchDateArr && searchDateArr.length === 2;

  const searchTimesArrays = searchTimes ? searchTimes.split(',') : null;
  const isValidSearchTimes = searchTimesArrays && searchTimesArrays.length === 2;

  const startDateMaybe = validSearchDate
    ? new Date(
        moment(searchDateArr[0])
          .startOf('date')
          .format('YYYY-MM-DD')
      )
    : null;

  const endDateMaybe = validSearchDate
    ? new Date(
        moment(searchDateArr[1])
          .startOf('date')
          .format('YYYY-MM-DD')
      )
    : null;

  const pickupTime = isValidSearchTimes
    ? moment(searchTimesArrays[0], 'HH:mm').format('hh:mm a')
    : null;
  const dropoffTime = isValidSearchTimes
    ? moment(searchTimesArrays[1], 'HH:mm').format('hh:mm a')
    : null;

  let startDateTimeMaybe, endDateTimeMaybe, diffHours;
  if (validSearchDate && isValidSearchTimes) {
    startDateTimeMaybe = validSearchDate
      ? new Date(moment(`${searchDateArr[0]} ${searchTimesArrays[0]}`).format('YYYY-MM-DD HH:mm'))
      : null;

    endDateTimeMaybe = validSearchDate
      ? new Date(moment(`${searchDateArr[1]} ${searchTimesArrays[1]}`).format('YYYY-MM-DD HH:mm'))
      : null;
  }
  if (startDateTimeMaybe && endDateTimeMaybe) {
    diffHours = moment(endDateTimeMaybe).diff(moment(startDateTimeMaybe), 'hours', true);
  }
  let { formattedPrice } = priceData(price, intl, showMonthlyPrice, listing);

  let formattedHourlyPrice = formatHourlyMoney(intl, price, 1);
  let hourTranslationKey = 'ListingPage.perHour';

  let hourlyPriceSeen = false;
  if (diffHours && diffHours < 12) {
    formattedPrice = formatHourlyMoney(intl, price, diffHours);
    unitTranslationKey = 'ListingPage.perHour';
    hourlyPriceSeen = true;
  }

  useEffect(() => {}, [pickDropWindows, startDateTimeMaybe, endDateTimeMaybe]);

  const { pickDropWindowsPickUp, pickDropWindowsDropOff } = findSelectedDates(
    pickDropWindows,
    startDateTimeMaybe,
    endDateTimeMaybe
  );

  const image = Array.isArray(listing.images) ? listing.images[0] : null;
  return (
    <NamedLink
      className={classes}
      name={isDrivelahGo ? 'ListingPageGo' : 'ListingPage'}
      onClick={() => onSelectCard(currentListing)}
      params={{ id, slug }}
      to={{
        state: { startDate: startDateMaybe, endDate: endDateMaybe, pickupTime, dropoffTime },
        search: location && location.search,
      }}
    >
      <div className={classNames(css.cardOuter)}>
        <div
          className={css.cardThreeToTwoWrapper}
          onMouseEnter={() => {
            setActiveListing(currentListing.id);
          }}
          onMouseLeave={() => setActiveListing(null)}
        >
          <div onMouseOver={() => setShowArrows(true)} onMouseLeave={() => setShowArrows(false)}>
            <div className={css.cardAspectWrapper}>
              {image && (
                <LazyImage
                  rootClassName={css.cardRootForNoImage}
                  alt={title}
                  image={image}
                  variants={['landscape-crop', 'landscape-crop2x']}
                  sizes={renderSizes}
                  isDisinfected={isDisinfected}
                />
              )}
            </div>
          </div>
        </div>
        <div className={css.cardListingInfoOuter}>
          <div className={css.cardLinkText}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.cardLongWord,
            })}
          </div>
          <div className={css.priceContainer}>
            <div className={css.cardListingPrice}>
              <strong>{formattedPrice}</strong>/<FormattedMessage id={unitTranslationKey} />
              <span className={css.or}>or</span>
              <strong>{formattedHourlyPrice}</strong>/<FormattedMessage id={hourTranslationKey} />
            </div>
          </div>
        </div>
        <div className={classNames(css.cardListingRatingsAndPrice)}>
          <img src={dollarSign} alt="Dollar sign" />
          Flat 50% off
        </div>
      </div>
    </NamedLink>
  );
};

NewHostListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: () => null,
  onSelectCard: () => {},
};

NewHostListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
  onSelectCard: func,
};

export default injectIntl(withViewport(NewHostListingCardComponent));
