import React, { memo } from 'react';
import benefitImage from '../../assets/landingPageNew/rental-counter.jpg';
import dollarSign from '../../assets/host-themed-landing-page/dollar-sign.svg';
import keySign from '../../assets/host-themed-landing-page/key-sign.svg';
import lockSign from '../../assets/host-themed-landing-page/lock-sign.svg';
import shieldSign from '../../assets/host-themed-landing-page/shield-sign.svg';
import css from './HostLandingPage.css';
import { Column, Container, Row } from '../../components/Grids/Grid';
import classNames from 'classnames';

const BenefitOfRenting = memo(({ openSignUpModal }) => {
  return (
    <div className={css.benefitSectionWrapper}>
      <Container>
        <Row className={css.sectionTitle}>
          <Column large={12}>
            <div className={css.benefitSection}>
              <Row className={css.sectionTitle}>
                <Column large={6} medium={12} small={12}>
                  <h2>
                    Benefits of renting <br /> out your car{' '}
                    <span>Additional income & full control</span>
                    {/* Benefits of renting <br />
                    your car on Drive lah */}
                  </h2>
                  <p>
                    Rent out your car with Drive lah. It’s easy, you’re covered and in control. We
                    want you to be part of our growing community.
                  </p>
                  <button onClick={openSignUpModal} className={classNames(css.btn, css.btnPrimary)}>
                    List your Car
                  </button>
                </Column>
                <Column large={6} medium={12} small={12}>
                  <img src={benefitImage} alt="" style={{ borderRadius: 10 }} />
                </Column>
              </Row>
              <Row className={css.benefitGrids}>
                <Column large={3} medium={6} small={6}>
                  <div className={css.imageThumb}>
                    <div className={css.imageWrapper}>
                      <img src={dollarSign} alt="" />
                    </div>
                  </div>
                  <div className={css.content}>
                    <h3>A second income</h3>
                    <p>Rent your car today and earn up to $10,000 per year!</p>
                  </div>
                </Column>
                <Column large={3} medium={6} small={6}>
                  <div className={css.imageThumb}>
                    <div className={css.imageWrapper}>
                      <img src={keySign} alt="" />
                    </div>
                  </div>
                  <div className={css.content}>
                    <h3>Keyless tech</h3>
                    <p>No handover required, rent out your car from anywhere</p>
                  </div>
                </Column>
                <Column large={3} medium={6} small={6}>
                  <div className={css.imageThumb}>
                    <div className={css.imageWrapper}>
                      <img src={shieldSign} alt="" />
                    </div>
                  </div>
                  <div className={css.content}>
                    <h3>Fully Insured</h3>
                    <p>Total peace of mind with our comprehensive insurance</p>
                  </div>
                </Column>
                <Column large={3} medium={6} small={6}>
                  <div className={css.imageThumb}>
                    <div className={css.imageWrapper}>
                      <img src={lockSign} alt="" />
                    </div>
                  </div>
                  <div className={css.content}>
                    <h3>Full control</h3>
                    <p>Set your own dates so you can decide when to share</p>
                  </div>
                </Column>
              </Row>
              <Row className={css.mobileButton}>
                <Column small={12}>
                  <button onClick={openSignUpModal} className={classNames(css.btn, css.btnPrimary)}>
                    List your Car
                  </button>
                </Column>
              </Row>
            </div>
          </Column>
        </Row>
      </Container>
    </div>
  );
});

export default BenefitOfRenting;
