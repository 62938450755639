import React from 'react';
import get from 'lodash/get';
import { bool } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  txGetPromoLineItem,
  txIsCanceled,
  txIsCanceledByCustomer,
  txIsDeclined,
  txIsDelivered,
} from '../../util/transaction';

import css from './BookingBreakdownNew.css';

const { Money } = sdkTypes;

const LineItemTotalPriceNew = props => {
  const { transaction, isProvider, intl, currentUser } = props;

  if (!transaction) {
    return (
      <div className={css.lineItemTotal}>
        <div className={css.totalPrice}>$-</div>
      </div>
    );
  }

  let providerTotalMessageId = 'BookingBreakdown.providerTotalDefault';
  if (txIsDelivered(transaction)) {
    providerTotalMessageId = 'BookingBreakdown.providerTotalDelivered';
  } else if (txIsDeclined(transaction)) {
    providerTotalMessageId = 'BookingBreakdown.providerTotalDeclined';
  } else if (txIsCanceled(transaction)) {
    providerTotalMessageId = 'BookingBreakdown.providerTotalCanceled';
  }

  const isLongTermRental = get(transaction, 'attributes.protectedData.isLongTermRental');
  const totalLabel = isProvider ? (
    <FormattedMessage id={providerTotalMessageId} />
  ) : isLongTermRental ? (
    <FormattedMessage id="BookingBreakdownLongTerm.total" />
  ) : (
    <FormattedMessage id="BookingBreakdown.total" />
  );

  const promoItem = txGetPromoLineItem(transaction);

  const promoAmount = promoItem && !txIsCanceled(transaction) ? promoItem.lineTotal.amount : 0;
  const processingFees = get(
    transaction,
    'attributes.protectedData.commissionObj.commission.processingFees',
    0
  );
  const payoutTotal = transaction.attributes.payoutTotal;
  const totalPrice = isProvider
    ? payoutTotal
    : txIsCanceledByCustomer(transaction)
    ? new Money(0, payoutTotal.currency)
    : transaction.attributes.payinTotal;

  const finalTotalPrice = isProvider
    ? new Money(totalPrice.amount - (promoAmount || 0), totalPrice.currency)
    : totalPrice;

  const formattedTotalPrice = formatMoney(intl, finalTotalPrice, 1);

  const hostCredits =
    !isProvider || !currentUser || !currentUser.attributes
      ? 0
      : currentUser.attributes.credits
      ? currentUser.attributes.credits
      : 0;

  const hostCreditMoney =
    !hostCredits || hostCredits < 20
      ? null
      : formatMoney(intl, new Money(2000, totalPrice.currency), 1);
  return (
    <div className={css.lineItemTotal}>
      <div className={css.totalPrice}>{formattedTotalPrice}</div>
    </div>
  );
};

LineItemTotalPriceNew.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isProvider: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemTotalPriceNew;
