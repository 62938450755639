import React, { memo } from 'react';
import howSharingWorksImage
  from '../../assets/host-themed-landing-page/how-sharing-works-image.png';
import css from './HostLandingPage.css';
import { Column, Container, Row } from '../../components/Grids/Grid';
import { AutoAccordion } from '../../components';
import classNames from 'classnames';

const HowSharingWorks = memo(({ openSignUpModal }) => {
  const accordionContent = [
    {
      id: 1,
      accordionTitle: 'Create your listing',
      accordionDescription:
        'Add your car details, where the guest can pick up the car, your availability schedule and finally the price you want to charge per day.',
    },
    {
      id: 2,
      accordionTitle: 'Connect your bank account',
      accordionDescription:
        'Connect your bank account so you can recieve payments for your trips right away. Your account with be linked with our partner Stripe with full security.',
    },
    {
      id: 3,
      accordionTitle: 'Start earning',
      accordionDescription:
        'That’s it. You are ready to start earning now. Get onboarding sessions with out host success team to learn how to maximise your earnings.',
    },
  ];
  return (
    <div className={css.howSharingWorksWrapper}>
      <Container>
        <Row className={css.sectionTitle}>
          <Column large={12}>
            <h3>How car sharing works <span>List your car, add bank details. Start earning.</span></h3>
          </Column>
        </Row>
        <Row alignItems="center">
          <Column large={6} small={12}>
            <AutoAccordion content={accordionContent} autoplay={true} autoplaySpeedInSeconds={5} />
            <div className={css.btnWrapper}>
              <button onClick={openSignUpModal} className={classNames(css.btn, css.btnPrimary)}>Get Started</button>
            </div>
          </Column>
          <Column large={6} small={12}>
            <img src={howSharingWorksImage} className={css.sharingImage} alt="" />
          </Column>
        </Row>
      </Container>
    </div>
  );
});

export default HowSharingWorks;
