import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withViewport } from '../../util/contextHelpers';
import { injectIntl, intlShape } from '../../util/reactIntl';
import config from '../../config';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
} from '../../components';
import { TopbarContainer } from '..';
import facebookImage from '../../assets/facebook_drivelah_2.jpg';
import twitterImage from '../../assets/facebook_drivelah_2.jpg';
import { initiateListACarEventFromTab, pushGTMBrowseEvent } from '../../util/gtm/gtmHelpers';
import {
  EVENT_BROWSE_HOMEPAGE,
  EVENT_BROWSE_USEDRENTALCAL,
  EVENT_LISTACAR_CLICKED_LIST_A_CAR,
  LISTACAR_CREATE_NEW_BUTTON_ID,
} from '../../util/gtm/gtmConstants';

// import SectionImages from './SectionImages';
import css from './GojekHostYourCarLandingPage.css';
import SectionHelp from './SectionHelp';
import SectionStories from './SectionStories';
import GojekIntroBanner from './GojekIntroBanner';
import WhyHostWithDrivelah from './WhyHostWithDrivelah';
import HostEarningCalculator from './HostEarningCalculator';
import { useHistory, useLocation } from 'react-router-dom';
import { parse } from '../../util/urlHelpers';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { getEventsByBucket } from '../../util/gtm/gtmCreateProperties';
import { triggerAnalyticsEvent } from '../../util/amplitudeMapEvents';
import { event_trigger_ids } from '../../util/analyticsConstants';

const MAX_MOBILE_SCREEN_WIDTH = 991;

export const GojekHostYourCarLandingPageComponent = props => {
  const { intl, scrollingDisabled, viewport, currentUser } = props;
  const location = useLocation();
  const history = useHistory();
  const _handleSignup = async e => {
    e.preventDefault();
    const { search } = location;
    const { code } = parse(search);
    // initiateListACarEventFromTab({
    //   props,
    //   eventButtonParam: [EVENT_LISTACAR_CLICKED_LIST_A_CAR, LISTACAR_CREATE_NEW_BUTTON_ID],
    // });
    try {
      const response = await getEventsByBucket('listacar');
      // console.log('Events fetched successfully:', response.data);
      triggerAnalyticsEvent({
        event_id: event_trigger_ids.LISTACAR_CLICKED_LIST_A_CAR, 
        eventData: response.data, 
        props: {
          ui: {
            button: "Create Listing"
          }
        },
        userId: currentUser && currentUser.id && currentUser.id.uuid
      });
    } catch (error) {
      console.log('Error fetching events:', error);
    }
    const url = createResourceLocatorString('SignupPage', routeConfiguration(), {}, {});
    history.push(
      code
        ? {
          pathname: url,
          search: `?code=${code}`,
        }
        : {
          pathname: url,
        }
    );
  };
  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: "Host your car with Drive lah x GoJek" }, { siteTitle });
  const schemaDescription = intl.formatMessage({
    id:
      'Host your car and earn some extra income with Drive lah x GoJek | Easy to access car sharing platform in Singapore.',
  });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  const onPushGTMEvent = (event, buttonId) => {
    // pushGTMBrowseEvent({
    //   props,
    //   event,
    //   buttonId,
    // });
  };

  useEffect(() => {
    onPushGTMEvent(EVENT_BROWSE_HOMEPAGE);
  }, []);

  let isMobileLayout;
  try {
    if (viewport) {
      isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
    }
  } catch (error) {

  }

  const onUseRentalCalculator = () => {
    // pushGTMBrowseEvent({
    //   props,
    //   event: EVENT_BROWSE_USEDRENTALCAL,
    // });
  };

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer notApplySearch={true} currentPage="SeoLandingPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.contentWrapper}>
          <GojekIntroBanner
            handleSignupRedirect={e => _handleSignup(e)}
            isMobileLayout={isMobileLayout}
          />
          <WhyHostWithDrivelah
            isMobileLayout={isMobileLayout}
            handleSignupRedirect={e => _handleSignup(e)}
          />
          <HostEarningCalculator
            onUseRentalCalculator={onUseRentalCalculator}
            onListYourCar={(e) => _handleSignup(e)}
            listYourCarButtonId={LISTACAR_CREATE_NEW_BUTTON_ID}
          />
          <SectionStories />
          <SectionHelp />
          {/* <SeoLandingRentalCategories /> */}
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool, object, number, shape } = PropTypes;

GojekHostYourCarLandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const { currentUser } = state.user;
  return {
    isAuthenticated,
    currentUser
  };
}

const mapDispatchToProps = dispatch => ({
 
});

const GojekHostYourCarLandingPage = compose(
  withViewport,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(GojekHostYourCarLandingPageComponent);

export default GojekHostYourCarLandingPage;
