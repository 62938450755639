import React from 'react';
import PropTypes from 'prop-types';

import tripDetailsPageCss from '../../containers/TripDetailsPage/TripDetailsPage.css';
import { FormattedMessage } from '../../util/reactIntl';

const HostFuelPackageBlock = (props) => {
  if (!props.isVisible) {
    return null;
  }

  return (
    <div className={tripDetailsPageCss.detailsContainerMain}>
      <h3 className={tripDetailsPageCss.containerTitle}>
        <FormattedMessage id={'TripDetailsPage.hostFuelPackageBlockTitle'} />
      </h3>
      <div>
        <FormattedMessage id={'TripDetailsPage.hostFuelPackageBlockText'} />
      </div>
    </div>
  );
};

const { bool } = PropTypes;

HostFuelPackageBlock.propTypes = {
  isVisible: bool.isRequired,
};

export default HostFuelPackageBlock;
