import React, { Component } from 'react';
import { FormattedMessage } from '../../util/reactIntl';

import css from './BAHFAQ.css';

class BAHFAQ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabOpen: -1
    }
  }

  openTab = (index) => {
    this.setState({
      tabOpen: index !== this.state.tabOpen ? index : -1
    })
  }

  render() {
    return (
      <section className={css.faqSection}>
        <div className={css.innerWrapper}>
          <h5 className={css.title}>
            <FormattedMessage id="BecomeAHostPage.faqTitle" values={{ newline: (<br />) }} />
          </h5>
          <div className={css.listTextBox}>
            <div className={css.subBox}>
              <div className={css.question} onClick={() => this.openTab(0)}>
                How much can I earn as a host?
              </div>
              {this.state.tabOpen === 0 &&
                <div className={css.answer}>
                  It depends on the number of days you rent out your car and the price you set. On average, people who rent out once a week will likely earn around $5000 a year.  Try our earnings estimation tool above.
                </div>
              }
            </div>
            <div className={css.subBox}>
              <div className={css.question} onClick={() => this.openTab(1)}>
                What protection do I have against car damage?
              </div>
              {this.state.tabOpen === 1 &&
                <div className={css.answer}>
                  At Drive lah, we have got you covered fully. Our insurance cover has been
                  designed to protect you and the renter comprehensively during the rental period.
                </div>
              }
            </div>
            <div className={css.subBox}>
              <div className={css.question} onClick={() => this.openTab(2)}>
                Is it safe to rent out my car to a stranger?
              </div>
              {this.state.tabOpen === 2 &&
                <div className={css.answer}>
                  Yes. At Drive lah, your peace of mind is of utmost importance to us. We have a
                  robust driver screening process to ensure that your car is driven by a verified
                  driver only. Finally, you are in control as the decision to accept a booking is
                  always yours.
                </div>
              }
            </div>
            <div className={css.subBox}>
              <div className={css.question} onClick={() => this.openTab(3)}>
                Is it compulsory to rent out if I sign up my car?
              </div>
              {this.state.tabOpen === 3 &&
                <div className={css.answer}>
                  No. As a car owner it is your decision whether to accept or reject a booking
                  request. You set the price and you decide who rents your car.
                </div>
              }
            </div>
            <div className={css.subBox}>
              <div className={css.question} onClick={() => this.openTab(4)}>
                How much does it cost to list a car?
              </div>
              {this.state.tabOpen === 4 &&
                <div className={css.answer}>
                  There is no charge for listing your car on Drive lah. It is free for hosts to list &amp;
                  share their car. Drive lah charges the guest for the trip and not the host.
                </div>
              }
            </div>
            <div className={css.subBox}>
              <div className={css.question} onClick={() => this.openTab(5)}>
                Can the car be driven to Malaysia?
              </div>
              {this.state.tabOpen === 5 &&
                <div className={css.answer}>
                  The decision to allow driving to Malaysia is yours. If you don’t allow, you can put it under the rules for renting your car.
                </div>
              }
            </div>
            <div className={css.subBox}>
              <div className={css.question} onClick={() => this.openTab(6)}>
                Why should I share my car?
              </div>
              {this.state.tabOpen === 6 &&
                <div className={css.answer}>
                  As a car owner, you face a lot of costs such as insurance, depreciation, road
                  tax etc. irrespective of usage. By sharing your car on Drivelah, you get to earn
                  additional income and manage these costs better. Most importantly, car sharing
                  impacts our environment positively by reducing the number of cars on the road.
                </div>
              }
            </div>
          </div>
        </div>
        <div className={css.seperateLine}></div>
      </section>
    )
  }
}

export default BAHFAQ;
