import classNames from 'classnames';
import React from 'react';
import Slider from 'react-slick';
import { compose } from 'redux';
import { NewHostListingCard } from '../../components';
import { withViewport } from '../../util/contextHelpers';
import css from './NewHostSlider.css';

const sliderConfig = {
  autoplay: false,
  infinite: false,
  autoplaySpeed: 5000,
  arrow: true,
  dots: false,
  slidesToScroll: 1,
  slidesToShow: 2.25,
  centerMode: false,
  responsive: [
    {
      breakpoint: 2560,
      settings: {
        slidesToShow: 3.75,
        slidesToScroll: 1,
        arrow: true,
        dots: false,
      },
    },
    {
      breakpoint: 1920,
      settings: {
        slidesToShow: 3.75,
        slidesToScroll: 1,
        arrow: true,
        dots: false,
      },
    },
    {
      breakpoint: 1445,
      settings: {
        slidesToShow: 2.25,
        slidesToScroll: 1,
        arrow: true,
        dots: false,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1.25,
        slidesToScroll: 1,
        arrow: false,
        dots: false,
      },
    },
  ],
};

// Format response to use types from SDK (e.g., UUID, Money, LatLng)
const NewHostSliderComponent = props => {
  const { viewport } = props;
  const removePadding = sliderConfig => {
    const currentWidth = viewport && viewport.width;
    const reverseResponsiveObject =
      sliderConfig &&
      sliderConfig.responsive &&
      sliderConfig.responsive.length > 0 &&
      sliderConfig.responsive.reverse();
    const isValid =
      reverseResponsiveObject.length > 0 &&
      sliderConfig.responsive.find(res => {
        if (currentWidth < res.breakpoint) {
          return res;
        }
      });
    return isValid && isValid.settings ? isValid.settings.slidesToShow : '';
  };

  const countListing = props && props.listings ? props.listings.length : 0;
  return (
    <div
      className={classNames(
        css.listingNewHostSlider,
        countListing < removePadding(sliderConfig) ? css.removeSpacing : ''
      )}
    >
      <h4>Rent from our new hosts and get great discounts</h4>
      <Slider {...sliderConfig}>
        {props &&
          props.listings.map(l => <NewHostListingCard key={l.id.uuid} listing={l} {...props} />)}
      </Slider>
    </div>
  );
};
const NewHostSlider = compose(withViewport)(NewHostSliderComponent);
export default NewHostSlider;
