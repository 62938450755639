import React, { Component } from 'react';
import { array, arrayOf, bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import setFieldTouched from '../../util/setFiledTouched';
import { required } from '../../util/validators';
import { compose } from 'redux';

import { propTypes } from '../../util/types';
import config from '../../config';
import { Button, FieldCheckboxGroup, FieldSelect, Form } from '../../components';

import css from './EditListingFeaturesForm.css';

const numberOfPerson = [
  {
    key: '1',
    label: 1,
  },
  {
    key: '2',
    label: 2,
  },
  {
    key: '3',
    label: 3,
  },
  {
    key: '4',
    label: 4,
  },
  {
    key: '5',
    label: 5,
  },
  {
    key: '6',
    label: 6,
  },
  {
    key: '7',
    label: 7,
  },
  {
    key: '8',
    label: 8,
  },
];

class EditListingFeaturesFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: null,
    };
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        initialValues={
          this.state.initialValues ? this.state.initialValues : this.props.initialValues
        }
        mutators={{ ...arrayMutators, setFieldTouched }}
        render={fieldRenderProps => {
          const {
            disabled,
            rootClassName,
            intl,
            fuelTypes,
            drivenKm,
            transmissionType,
            className,
            name,
            handleSubmit,
            pristine,
            saveActionMsg,
            updated,
            updateInProgress,
            fetchErrors,
            peopleNumber,
            invalid,
            form,
            submitButtonId,
          } = fieldRenderProps;

          const classes = classNames(rootClassName || css.root, className);
          const submitReady = updated && pristine;
          const submitInProgress = updateInProgress;
          const submitDisabled = disabled || submitInProgress;

          const { updateListingError, showListingsError } = fetchErrors || {};

          // Listing Number of Seats field
          const peopleNumberLabel = intl.formatMessage({
            id: 'EditListingFeaturesForm.peopleNumberLabel',
          });
          const peopleNumberPlaceholder = intl.formatMessage({
            id: 'EditListingFeaturesForm.peopleNumberPlaceholder',
          });
          const peopleNumberRequiredMessage = required(
            intl.formatMessage({
              id: 'EditListingFeaturesForm.peopleNumberRequiredMessage',
            })
          );

          // Listing Transmission field
          const transmissionLabel = intl.formatMessage({
            id: 'EditListingFeaturesForm.transmissionLabel',
          });
          const transmissionPlaceholder = intl.formatMessage({
            id: 'EditListingFeaturesForm.transmissionPlaceholder',
          });
          const transmissionRequired = required(
            intl.formatMessage({
              id: 'EditListingFeaturesForm.transmissionsRequired',
            })
          );

          // Listing Type of fuel field
          const fuelLabel = intl.formatMessage({
            id: 'EditListingFeaturesForm.fuelLabel',
          });
          const fuelPlaceholder = intl.formatMessage({
            id: 'EditListingFeaturesForm.fuelPlaceholder',
          });
          const fuelRequired = required(
            intl.formatMessage({
              id: 'EditListingFeaturesForm.fuelRequired',
            })
          );

          // Listing PHV enabled
          const isPHVEnabledLabel = intl.formatMessage({
            id: 'EditListingFeaturesForm.isPHVEnabledLabel',
          });

          // Listing Features available
          const keyFeaturesLabel = intl.formatMessage({
            id: 'EditListingFeaturesForm.keyFeaturesLabel',
          });
          const featuresRequired = required(
            intl.formatMessage({
              id: 'EditListingFeaturesForm.featuresRequired',
            })
          );

          const errorMessage = updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingFeaturesForm.updateFailed" />
            </p>
          ) : null;

          const errorMessageShowListing = showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingFeaturesForm.showListingFailed" />
            </p>
          ) : null;

          const kmDrivenLabel = intl.formatMessage({
            id: 'EditListingFeaturesForm.kmDrivenLabel',
          });
          const kmDrivenPlaceholder = intl.formatMessage({
            id: 'EditListingFeaturesForm.kmDrivenPlaceholder',
          });
          const kmDrivenRequired = required(
            intl.formatMessage({
              id: 'EditListingFeaturesForm.kmDrivenRequired',
            })
          );

          return (
            <Form
              className={classes}
              onSubmit={e => {
                e.preventDefault();
                e.stopPropagation();
                if (!invalid) handleSubmit(e);
                const formState = form.getState();
                const { errors } = formState || {};
                Object.keys(errors).forEach(key => form.mutators.setFieldTouched(key, 'true'));
              }}
            >
              {errorMessage}
              {errorMessageShowListing}

              <FormSpy
                onChange={formState => {
                  this.setState({
                    initialValues: formState.values,
                  });
                }}
              />

              <div className={css.displayInlineContainer}>
                <div className={classNames(css.column, css.column6)}>
                  {/* <CustomFieldRangeSlider
                    className={css.peopleContainer}
                    id="peopleNumber"
                    name="peopleNumber"
                    label={peopleNumberLabel}
                    min={1}
                    max={8}
                    step={1}
                    handles={peopleNumber}
                    oneDirection={true}
                  /> */}
                  <FieldSelect
                    className={classNames(css.peopleContainer, css.newInput, css.dropDownInput)}
                    name="peopleNumber"
                    id="numberOfPerson"
                    label={peopleNumberLabel}
                    validate={peopleNumberRequiredMessage}
                  >
                    <option disabled value="">
                      {peopleNumberPlaceholder}
                    </option>
                    {numberOfPerson.map(c => (
                      <option key={c.key} value={c.key}>
                        {c.label}
                      </option>
                    ))}
                  </FieldSelect>
                </div>
                <div className={classNames(css.column, css.column6)}>
                  <FieldSelect
                    className={classNames(css.features, css.newInput, css.dropDownInput)}
                    name="transmissions"
                    id="transmissions"
                    label={transmissionLabel}
                    validate={transmissionRequired}
                  >
                    <option disabled value="">
                      {transmissionPlaceholder}
                    </option>
                    {transmissionType.map(c => (
                      <option key={c.key} value={c.key}>
                        {c.label}
                      </option>
                    ))}
                  </FieldSelect>
                </div>
                <div className={classNames(css.column, css.column6)}>
                  <FieldSelect
                    className={classNames(css.category, css.newInput, css.dropDownInput)}
                    name="fuelType"
                    id="fuelType"
                    label={fuelLabel}
                    validate={fuelRequired}
                  >
                    <option disabled value="">
                      {fuelPlaceholder}
                    </option>
                    {fuelTypes.map(c => (
                      <option key={c.key} value={c.key}>
                        {c.label}
                      </option>
                    ))}
                  </FieldSelect>
                </div>
                <div className={classNames(css.column, css.column6)}>
                  <FieldSelect
                    className={classNames(css.category, css.newInput, css.dropDownInput)}
                    name="drivenKm"
                    id="drivenKm"
                    label={kmDrivenLabel}
                    validate={kmDrivenRequired}
                  >
                    <option disabled value="">
                      {kmDrivenPlaceholder}
                    </option>
                    {drivenKm &&
                      drivenKm.map(c => (
                        <option key={c.key} value={c.key}>
                          {c.label}
                        </option>
                      ))}
                  </FieldSelect>
                </div>
                <div className={classNames(css.column, css.column12)}>
                  <div className={css.keyFeatures}>
                    <div className={css.customRadioOuter}>
                      <FieldCheckboxGroup
                        className={css.features}
                        customClasses={css.inlineInputs}
                        id={name}
                        name={name}
                        label={keyFeaturesLabel}
                        options={config.custom.keyFeatures.filter(k => k.key !== 'pet_friendly')}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* <FieldSelect
                className={css.category}
                name="drivenKm"
                id="drivenKm"
                label={kmDrivenLabel}
                validate={kmDrivenRequired}
              >
                <option disabled value="">
                  {kmDrivenPlaceholder}
                </option>
                {drivenKm.map(c => (
                  <option key={c.key} value={c.key}>
                    {c.label}
                  </option>
                ))}
              </FieldSelect> */}

              <div className={css.stickyButtons}>
                <div className={css.stickButtonsContainer}>
                  <div className={css.stickButtonsDescription}></div>
                  <div className={css.stickButton}>
                    <Button
                      className={css.submitButton}
                      type="submit"
                      inProgress={submitInProgress}
                      disabled={submitDisabled}
                      ready={submitReady}
                      id={submitButtonId}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

EditListingFeaturesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
};

EditListingFeaturesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
  name: string.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  fuelTypes: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
  drivenKm: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
  peopleNumber: array.isRequired,
  submitButtonId: string,
};

const EditListingFeaturesForm = EditListingFeaturesFormComponent;

export default compose(injectIntl)(EditListingFeaturesForm);
