import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import config from '../../config';
import { ensureCurrentUser } from '../../util/data';
import { get } from 'lodash';
import InviteFriends from '../../assets/invite-friends.svg';
import instagram from '../../assets/instagram-square-brands.svg';
import twitter from '../../assets/twitter-square-brands.svg';
import facebook from '../../assets/fb-share.svg';
import whatsapp from '../../assets/whatsapp-square-brands.svg';
import messenger from '../../assets/messenger.svg';
import BLandingCss from './BLandingPage.css';
import { Button, CopyToClipboard, NamedLink, SocialSharing } from '../../components';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import copyIcon from '../../assets/copy.png';

const InviteSection = ({ currentUser, intl, history, isMobileLayout }) => {
  const [isShareModalOpen, setIsShareModal] = useState(false);
  const [openShareDialog, setOpenShareDialog] = useState(false);
  const ensuredUser = ensureCurrentUser(currentUser);
  const getReferralCode = get(ensuredUser, 'attributes.profile.metadata.referralCode', '');
  const signupReferralLink = `${config.canonicalRootURL}/signup?code=${getReferralCode}`;

  const sharingMsg = intl.formatMessage(
    { id: 'ReferAHostPage.sharingMsg' },
    { signupReferralLink, myCode: getReferralCode }
  );

  const whatsappSharingMsg = intl.formatMessage(
    { id: 'ReferAHostPage.whatsappSharingMsg' },
    { signupReferralLink, myCode: getReferralCode }
  );

  const loadFbSdk = () => {
    if (window) {
      window.fbAsyncInit = function() {
        window.FB.init({
          appId: config.facebookAppId,
          cookie: true, // enable cookies to allow the server to access
          // the session
          autoLogAppEvents: true,
          xfbml: true, // parse social plugins on this page
          version: 'v2.7',
        });
      };

      // Load the SDK asynchronously
      (function(d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s);
        js.id = id;
        js.src = '//connect.facebook.net/en_US/sdk.js';
        fjs.parentNode.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');
    }
  };

  const twitterLink =
    typeof window !== 'undefined' ?
        `https://twitter.com/intent/tweet?text=${window.encodeURI(sharingMsg)}`
      : '';

  const whatsAppLink = isMobileLayout
    ? `https://api.whatsapp.com/send?text=${whatsappSharingMsg}`
    : `https://web.whatsapp.com/send?text=${whatsappSharingMsg}`;

  const onShareToMessenger = link => {
    if (!window) return;

    var desc = 'your caption here';
    var title = 'your title here';
    return isMobileLayout
      ? window.open(`fb-messenger://share/?link=${link}&app_id=${config.facebookAppId}`)
      : window.FB.ui({
          method: 'send',
          link: `${link}`,
          description: link,
          action_properties: JSON.stringify({
            object: {
              'og:title': title,
              'og:description': desc,
            },
          }),
        });
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      loadFbSdk();
    }
  }, []);

  useEffect(() => {
    if (navigator.share) {
      setIsShareModal(true);
    } else {
      setIsShareModal(false);
    }
  }, []);

  const onShareToFacebookTimeline = (message, url) => {
    if (!window) return;

    window.FB.ui(
      {
        method: 'share',
        quote: message,
        href: url,
      },
      function(response) {

      }
    );
  };
  const shareFunctionality = e => {
    if (navigator.share) {
      navigator
        .share({
          title: 'Share',
          text: sharingMsg,
        })
        .then(() => {

        })
        .catch(console.error);
    } else {
      setOpenShareDialog(true);
    }
  };

  const _redirectToReferralPage = () => {
    if (!currentUser) {
      history.push(createResourceLocatorString('ReferralPage', routeConfiguration(), {}, {}));
    }
  };

  const socialSharingLinks = [
    {
      id: 0,
      siteName: 'Facebook',
      siteLogo: facebook,
      sharingUrl: signupReferralLink,
      sharingMsg,
      callback: (sharingMsg, signupReferralLink) => onShareToMessenger(signupReferralLink),
    },
    {
      id: 1,
      siteName: 'Instagram',
      siteLogo: instagram,
      sharingMsg,
      sharingUrl: null,
      callback: () => {},
    },
    {
      id: 2,
      siteName: 'Messenger',
      siteLogo: messenger,
      sharingUrl: null,
      callback: () => onShareToMessenger(),
    },
    {
      id: 3,
      siteName: 'Twitter',
      siteLogo: twitter,
      sharingMsg,
      sharingUrl: twitterLink,
      callback: () => {},
    },
    {
      id: 4,
      siteName: 'Whatsapp',
      siteLogo: whatsapp,
      sharingMsg,
      sharingUrl: whatsAppLink,
      callback: () => {},
    },
  ];

  const copyButton = (
    <Button type="button" className={BLandingCss.copyShareButton}>
      <div className={BLandingCss.copyIconThumb}>
        <img src={copyIcon} alt="Copy" />
      </div>
      <div>Copy link to share</div>
    </Button>
  );

  return (
    <div className={classNames(BLandingCss.inviteSection)}>
      <div className={isMobileLayout ? '' : BLandingCss.landingContainer}>
        <div className={BLandingCss.landingRow}>
          <div
            className={classNames(
              BLandingCss.column12,
              BLandingCss.gridBox,
              BLandingCss.gridHorizontal,
              BLandingCss.gridImageRight
            )}
          >
            <div
              className={classNames(
                BLandingCss.gridBoxInner,
                BLandingCss.sectionWithBg,
                !currentUser ? BLandingCss.referralWrapper : ''
              )}
              onClick={() => _redirectToReferralPage()}
            >
              <div className={BLandingCss.inviteContent}>
                <span className={BLandingCss.title}>Refer friends and earn up to $100</span>
                <p>
                  Share your unique link with friends. They'll get $25 credits when they join Drive
                  lah. You’ll get $25 credits if they rent or $100 credits if they host a trip.
                </p>
                {currentUser ? (
                  <>
                    <div className={BLandingCss.copyReferralCodeSection}>
                      {!isMobileLayout && (
                        <CopyToClipboard
                          text={copyButton}
                          textToCopy={sharingMsg}
                          highlightText="Url copied"
                          className={BLandingCss.copyReferralCode}
                        />
                      )}
                    </div>
                    <div className={BLandingCss.shareIcons}>
                      {isMobileLayout && isShareModalOpen ? (
                        <a
                          className={classNames(
                            BLandingCss.logo,
                            BLandingCss.shareMoreOptions,
                            BLandingCss.copyShareButton
                          )}
                          onClick={shareFunctionality}
                          target="_blank"
                        >
                          Share
                        </a>
                      ) : (
                        <>
                          <a
                            className={BLandingCss.logo}
                            onClick={() =>
                              onShareToFacebookTimeline(sharingMsg, signupReferralLink)
                            }
                            target="_blank"
                          >
                            <img src={facebook} alt="Facebook" />
                          </a>
                          <a
                            className={BLandingCss.logo}
                            onClick={() => onShareToMessenger(signupReferralLink)}
                            target="_blank"
                          >
                            <img src={messenger} alt="Messenger" />
                          </a>

                          {/* <a className={BLandingCss.logo} href={twitterLink} target="_blank">
                            <img src={instagram} alt="Instagram-icon" />
                          </a> */}

                          <a className={BLandingCss.logo} href={twitterLink} target="_blank">
                            <img src={twitter} alt="Twitter-icon" />
                          </a>

                          <a className={BLandingCss.logo} href={whatsAppLink} target="_blank">
                            <img src={whatsapp} alt="Whatsapp-icon" />
                          </a>
                        </>
                      )}
                    </div>
                  </>
                ) : (
                  <NamedLink
                    type="button"
                    name="ReferralPage"
                    className={BLandingCss.copyShareButton}
                  >
                    <div>Learn more</div>
                  </NamedLink>
                )}
              </div>
              <div className={BLandingCss.thumbImg}>
                <img src={InviteFriends} alt="invite-friends" />
              </div>
            </div>
          </div>
        </div>
        <SocialSharing
          socialSharingLinks={socialSharingLinks}
          openShareDialog={openShareDialog}
          setOpenShareDialog={setOpenShareDialog}
        />
      </div>
    </div>
  );
};

export default InviteSection;
