import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { format } from '../FieldPhoneNumberInput/fiFormatter';

import css from './ModalMissingInformation.css';
import SignupStep3Form from '../../forms/SignupForm/SignupStep3Form';

const MobileValidate = props => {
  const {
    className,
    sendMobileCodeInProgress, //State that show code is sending
    sendMobileCodeError, //Send code error flag
    onSendMobileCodeEmail, //Send mobile code handler
    onCallMobileCodeEmail, //Call handler
    onChangeMobileNumber, // Pres the change button
    checkValidCode,
    phoneNumber,
    verifyPhoneSuccess,
    loadingPhoneValidate,
    resendCodeSuccess,
    resendCodeError,
  } = props;

  useEffect(() => {
    if (!sendMobileCodeInProgress) {
      onSendMobileCodeEmail();
    }
  }, []);

  const resendErrorTranslationId = 'ModalMissingInformation.sendCodeError';

  const sendErrorMessage = sendMobileCodeError ? (
    <p className={css.error}>
      <FormattedMessage
        id={resendErrorTranslationId}
        values={{ error: <FormattedMessage id={sendMobileCodeError.message} /> }}
      />
    </p>
  ) : null;

  const resendErrorMessage = resendCodeError ? (
    <p className={css.error}>
      <FormattedMessage
        id={resendErrorTranslationId}
        values={{ error: <FormattedMessage id={'ModalMissingInformation.resendErrorMessage'} /> }}
      />
    </p>
  ) : null;

  const resendSuccessMessage = resendCodeSuccess ? (
    <div className={css.resendSuccessMessage}>
      <FormattedMessage id="ModalMissingInformation.resendSuccessMessage" />
    </div>
  ) : null;

  const getFormattedPhoneNumber = phoneNumber => {
    const removePhoneCode = phoneNumber.substring(3);
    return format(removePhoneCode);
  };

  return (
    <div className={className}>
      {verifyPhoneSuccess ? (
        <p className={css.modalTitle}>
          <FormattedMessage id="ModalMissingInformation.mobileValidatedTitle" />
        </p>
      ) : (
        <h1 className={css.modalTitleConfirmMobile}>
          <FormattedMessage id="ModalMissingInformation.mobileValidateTitle" />
        </h1>
      )}

      <p className={css.modalMessage}>
        <FormattedMessage
          id={
            verifyPhoneSuccess
              ? 'ModalMissingInformation.mobileValidatedDescription'
              : 'ModalMissingInformation.mobileValidateDescription'
          }
          values={{ phoneNumber: getFormattedPhoneNumber(phoneNumber) }}
        />
      </p>
      {sendErrorMessage}
      {resendErrorMessage}
      {resendSuccessMessage}

      <SignupStep3Form
        resendCode={onSendMobileCodeEmail}
        onChangeNumber={onChangeMobileNumber}
        submitCode={checkValidCode}
        inProgress={(loadingPhoneValidate && !verifyPhoneSuccess) || sendMobileCodeInProgress}
      />
    </div>
  );
};

export default MobileValidate;
