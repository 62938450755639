import config from '../config';

const apiUrl = config.apiUrl;

export const USER_SEND_VERIFY = 'USER_SEND_VERIFY';
export const USER_SEND_VERIFY_WHEN_BOOKING = 'USER_SEND_VERIFY_WHEN_BOOKING';
export const LISTING_WAITING_TO_BE_VERIFIED = 'LISTING_WAITING_TO_BE_VERIFIED';

export const BOOKING_ENQUIRY_MADE = 'BOOKING_ENQUIRY_MADE';
export const BOOKING_REQUEST_SENT = 'BOOKING_REQUEST_SENT';
export const BOOKING_REQUEST_DECLINED = 'BOOKING_REQUEST_DECLINED';
export const BOOKING_REQUEST_ACCEPTED = 'BOOKING_REQUEST_ACCEPTED';
export const BOOKING_REQUEST_CANCELLED = 'BOOKING_REQUEST_CANCELLED';
export const EMAIL_PICKUP_CONFIRMED = 'PICKUP_CONFIRMED';
export const EMAIL_DROPOFF_CONFIRMED = 'DROPOFF_CONFIRMED';
export const EMAIL_PICKUP_REQUEST = 'PICKUP_REQUESTED';
export const EMAIL_DROPOFF_REQUEST = 'DROPOFF_REQUESTED';
export const EMAIL_TRIP_CANCELLED = 'TRIP_CANCELLED';
export const USER_SEND_MESSAGE = 'USER_SEND_MESSAGE';
export const SIGNUP_REFERRAL_CODE_SUCCESSFUL = 'events/SIGNUP_REFERRAL_CODE_SUCCESSFUL';
export const SEND_EMAIL_CONFIRM_REFERRAL_CODE_TO_REFERRED =
  'events/SEND_EMAIL_CONFIRM_REFERRAL_CODE_TO_REFERRED';
export const SEND_EMAIL_CONFIRM_REFERRAL_CODE_TO_REFERRER =
  'events/SEND_EMAIL_CONFIRM_REFERRAL_CODE_TO_REFERRER';
export const SEND_REFERRAL_INVITATION = 'events/SEND_REFERRAL_INVITATION';

export const BOOKING_REQUEST_PAYMENT_COMPLETED = 'BOOKING_REQUEST_PAYMENT_COMPLETED';

export const sendEmail = (data, eventType) => {
  const getBody = eventType => {
    switch (eventType) {
      case USER_SEND_VERIFY: {
        const { userId, userEmail } = data;
        return { userId, userEmail, eventType };
      }
      case SEND_EMAIL_CONFIRM_REFERRAL_CODE_TO_REFERRED: {
        const { userName, userEmail, referrerId } = data;
        return { userName, userEmail, referrerId, eventType };
      }
      case SEND_EMAIL_CONFIRM_REFERRAL_CODE_TO_REFERRER: {
        const { referrerId, referredName } = data;
        return { referrerId, referredName, eventType };
      }
      case SEND_REFERRAL_INVITATION: {
        const { profileImage, referrerName, referredEmail, referralCode } = data;
        return { profileImage, referrerName, referredEmail, referralCode, eventType };
      }
      default: {
        const { userId, userEmail, hostEmail, listingName, listingId, transactionId } = data;
        return { userId, userEmail, hostEmail, listingName, listingId, transactionId, eventType };
      }
    }
  };
  // const testHost = "http://localhost:3001";
  return fetch(apiUrl + '/api/events/receive', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(getBody(eventType)),
  }).catch(err => {
    console.error(`Send email failed, ${data}`, err);
  });
};

export const sendEventGeneral = data => {
  // const testHost = "http://localhost:3001";
  return fetch(apiUrl + '/api/events/receive', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...data,
      uvk: getUserVerificationKey(),
    }),
  });
};

const objToQueryString = obj => {
  const keyValuePairs = [];
  for (const key in obj) {
    keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
  }
  return keyValuePairs.join('&');
};

const getUserVerificationKey = () => {
  if (typeof window.localStorage !== 'undefined') {
    return window.localStorage.getItem('uvk');
  }
  return null;
};

export const saveSearchedPlaces = (place, userId = null) => {
  try {
    let oldSearches = getSearchedPlaces(userId);
    if (oldSearches && oldSearches.length) {
      if (!oldSearches.some(item => item.place_id === place.place_id)) {
        oldSearches = [place].concat(oldSearches);
      }
    } else {
      oldSearches = [place];
    }
    if (typeof window.localStorage !== 'undefined') {
      window.localStorage.setItem('searches', JSON.stringify(oldSearches));
    }
    return null;
  } catch (error) {

  }
  return null;
};

export const saveSearchedPlacesMobile = (place, userId = null) => {
  try {
    let oldSearches = getSearchedPlaces(userId);
    if (oldSearches && oldSearches.length) {
      if (!oldSearches.some(item => item.place_id === place.place_id)) {
        oldSearches = [place].concat(oldSearches);
      }
    } else {
      oldSearches = [place];
    }
    if (typeof window.localStorage !== 'undefined') {
      if (oldSearches && oldSearches.length > 5) {
        oldSearches.splice(-1, 1);
      }
      window.localStorage.setItem('searches', JSON.stringify(oldSearches));
    }
    return null;
  } catch (error) {

  }
  return null;
};

export const getSearchedPlaces = (userId = null) => {
  try {
    if((typeof window === 'undefined')) {
      return [];
    }
    let searchKey = userId ? userId : 'searches';
    if ((typeof window !== 'undefined') &&  (typeof window.localStorage !== 'undefined')) {
      return window.localStorage.getItem(searchKey)
        ? JSON.parse(window.localStorage.getItem(searchKey))
        : [];
    }
  } catch (error) {

    return [];
  }
  return [];
};

export const getPUSHTELLMapPage = () => {
  try {
    if (typeof window.localStorage !== 'undefined') {
      return window.localStorage.getItem('PUSHTELL-Map Page');
    }
  } catch (error) {

  }
  return null;
};

export const getPUSHTELLResultPage = () => {
  try {
    if (typeof window !== 'undefined' && typeof window.localStorage !== 'undefined') {
      return window.localStorage.getItem('PUSHTELL-Attractiveness Score Experiment Page');
    }
  } catch (error) {

  }
  return null;
};

export const getPUSHTELLSearchResultPage = () => {
  try {
    if (typeof window !== 'undefined' && typeof window.localStorage !== 'undefined') {
      return window.localStorage.getItem('PUSHTELL-Search result page');
    }
  } catch (error) {

  }
  return null;
};

export const getPUSHTELLMileageExperimentPage = () => {
  try {
    if (typeof window !== 'undefined' && typeof window.localStorage !== 'undefined') {

      return window.localStorage.getItem('PUSHTELL-mileageExperiment');
    }
  } catch (error) {

  }
  return null;
};


export const getPUSHTELLSearchExperimentPage = () => {
  try {
    if (typeof window !== 'undefined' && typeof window.localStorage !== 'undefined') {

      return window.localStorage.getItem('PUSHTELL-newSearchExperimentAttractiveScore');
    }
  } catch (error) {

  }
  return null;
};
