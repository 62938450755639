/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { memo, useState, useEffect } from 'react';
import css from './FooterCategoriesSection.css';
import isEqual from 'lodash/isEqual';
import { useHistory } from 'react-router-dom';
import { TOP_BRANDS, TOP_CATEGORIES, TOP_LOCATIONS } from './seoCategoriesArrays';
import { Column, Container, Row } from '../Grids/Grid';
import {
  EVENT_BROWSE_ABOUTUS,
  EVENT_BROWSE_HOWITWORKS,
  EVENT_BROWSE_RENTACAR,
  EVENT_BROWSE_SHAREACAR,
  EVENT_BROWSE_TRUSTANDSAFE,
} from '../../util/gtm/gtmConstants';
import NamedLink from '../NamedLink/NamedLink';
import { FormattedMessage } from 'react-intl';
import ExternalLink from '../ExternalLink/ExternalLink';
import classNames from 'classnames'; // Ensure this import matches your API utility
import { getEventsByBucket } from '../../util/gtm/gtmCreateProperties';
import { triggerAnalyticsEvent } from '../../util/amplitudeMapEvents';
import { event_trigger_ids } from '../../util/analyticsConstants';

const redirectToDifferentLocation = (history, locationName) => {
  history.push(locationName);
  history.go(0);
};

const FooterCategoriesSection = memo(
  ({ pushGTMBrowse, currentUser }) => {
    // console.log('Logged in user', currentUser);
    const history = useHistory();
    const [eventData, setEventData] = useState(null);

    useEffect(() => {
      const fetchEventData = async () => {
        try {
          const response = await getEventsByBucket('browsing');
          setEventData(response.data);
        } catch (error) {
          console.error('Error fetching event data:', error);
        }
      };

      // console.log("Current user logged in", currentUser);

      fetchEventData();
    }, []);

    const handleLinkClick = (eventId, label) => {
      triggerAnalyticsEvent({
        event_id: eventId,
        eventData,
        props: {
          ui: {
            button: label,
            page: 'ProfileScreen'
          },
        },
        userId: currentUser && currentUser.id ? currentUser.id.uuid : null,
        user: currentUser
      });
    };



    const footerLinks = [
      {
        button: (
          <NamedLink
            name="BecomeAGuestPage"
            className={css.linkName}
            // onClick={() => pushGTMBrowse(EVENT_BROWSE_RENTACAR)}
          >
            <FormattedMessage id="Footer.rentACar" />
          </NamedLink>
        ),
      },
      {
        button: (
          <ExternalLink href={'https://help.drivelah.sg/our-policies'} className={css.linkName}>
            <FormattedMessage id="Footer.policies" />
          </ExternalLink>
        ),
      },
      {
        button: (
          <NamedLink
            name="BecomeAHostPage"
            className={css.linkName}
            // onClick={() => pushGTMBrowse(EVENT_BROWSE_SHAREACAR)}
          >
            <FormattedMessage id="Footer.shareYourCar" />
          </NamedLink>
        ),
      },
      {
        button: (
          <ExternalLink 
          href={'https://help.drivelah.sg/faq'} 
          className={css.linkName}
          onClick={() =>
            handleLinkClick(event_trigger_ids.BROWSE_CLICK_FAQ, "F.A.Q.")
          }
           >
            <FormattedMessage id="Footer.faq" />
          </ExternalLink>
        ),
      },
      {
        button: (
          <NamedLink
            name="HowItWorkPage"
            className={css.linkName}
            onClick={() =>
              handleLinkClick(event_trigger_ids.BROWSE_CLICK_HOW_IT_WORKS, "How it works")
            }
            // onClick={() => pushGTMBrowse(EVENT_BROWSE_HOWITWORKS)}
          >
            <FormattedMessage id="Footer.howItWork" />
          </NamedLink>
        ),
      },
      {
        button: (
          <ExternalLink href={'https://help.drivelah.sg'} className={css.linkName}>
            <FormattedMessage id="Footer.help" />
          </ExternalLink>
        ),
      },
      {
        button: (
          <NamedLink
            name="TrustAndSafetyPage"
            className={css.linkName}
            // onClick={() => pushGTMBrowse(EVENT_BROWSE_TRUSTANDSAFE)}
          >
            <FormattedMessage id="Footer.trustAndSafety" />
          </NamedLink>
        ),
      },
      {
        button: (
          <NamedLink name="ContactUsPage" className={css.linkName}>
            <FormattedMessage id="Footer.contactUs" />
          </NamedLink>
        ),
      },
      {
        button: (
          <NamedLink
            name="LandingPageGo"
            className={css.linkName}
            // onClick={() => pushGTMBrowse(EVENT_BROWSE_TRUSTANDSAFE)}
          >
            Drive lah Go™
          </NamedLink>
        ),
      },
      {
        button: (
          <NamedLink name="Career" className={css.linkName}>
            <FormattedMessage id="Footer.career" />
          </NamedLink>
        ),
      },
      {
        button: (
          <NamedLink name="InstantBookingLandingPage" className={css.linkName}>
            Instant Booking
          </NamedLink>
        ),
      },
      {
        button: (
          <ExternalLink className={css.linkName} href="https://blog.drivelah.sg">
            Blog
          </ExternalLink>
        ),
      },
      {
        button: (
          <NamedLink
            name="AboutPage"
            className={css.linkName}
            // onClick={() => pushGTMBrowse(EVENT_BROWSE_ABOUTUS)}
          >
            <FormattedMessage id="Footer.aboutUs" />
          </NamedLink>
        ),
      },
    ];

    const partnersLinks = [
      {
        button: (
          <ExternalLink href={'https://www.tokiomarine.com/'} className={css.linkName}>
            Tokio Marine
          </ExternalLink>
        ),
      },
      {
        button: (
          <ExternalLink href={'https://www.income.com.sg/'} className={css.linkName}>
            NTUC Income
          </ExternalLink>
        ),
      },
    ];

    const learnMoreLinks = [
      {
        button: (
          <NamedLink name="TermsOfServicePage" className={css.linkName}>
            <FormattedMessage id="Footer.termsOfUse" />
          </NamedLink>
        ),
      },
      {
        button: (
          <NamedLink 
          name="PrivacyPolicyPage" 
          onClick={() =>
            handleLinkClick(event_trigger_ids.BROWSE_VIEW_PRIVACY_POLICY, 'Privacy policy')
          }
          className={css.linkName} >
            <FormattedMessage id="Footer.privacyPolicy" />
          </NamedLink>
        ),
      },
    ];

    return (
      <div className={css.rentalCarCategories}>
        <Container>
          <Row>
            <Column large={7} small={12}>
              <Row>
                <div className={css.columnEight}>
                  <div className={css.columnTitle}>Car Rental by top brands</div>
                  <ul className={css.categoriesListing}>
                    {TOP_BRANDS.values.map(carBrand => {
                      return (
                        <li
                          key={carBrand.key}
                          onClick={() =>
                            redirectToDifferentLocation(
                              history,
                              TOP_BRANDS.generateRoute(carBrand.key)
                            )
                          }
                        >
                          <p className={css.linkName}>{carBrand.label}</p>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className={css.columnEight}>
                  <div className={css.columnTitle}>Car Rental by top locations</div>
                  <ul className={css.categoriesListing}>
                    {TOP_LOCATIONS.values.map(location => {
                      return (
                        <li
                          key={location.key}
                          onClick={() =>
                            redirectToDifferentLocation(
                              history,
                              TOP_LOCATIONS.generateRoute(location.key)
                            )
                          }
                        >
                          <p className={css.linkName}>{location.label}</p>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className={css.columnTwo}>
                  <div className={css.columnTitle}>Car Rental by type</div>
                  <ul className={css.categoriesListing}>
                    {TOP_CATEGORIES.values.map(category => {
                      return (
                        <li
                          key={category.key}
                          onClick={() =>
                            redirectToDifferentLocation(
                              history,
                              TOP_CATEGORIES.generateRoute(category.key)
                            )
                          }
                        >
                          <p className={css.linkName}>{category.label}</p>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </Row>
            </Column>
            <Column large={5} small={12}>
              <Row>
                <Column large={7} small={12}>
                  <div className={css.columnTitle}>Explore Drive Lah</div>
                  <ul className={css.categoriesListing}>
                    {footerLinks.map((link, index) => {
                      return <li key={index}>{link.button}</li>;
                    })}
                  </ul>
                </Column>
                <Column
                  large={5}
                  small={12}
                  className={classNames(css.singleColumn, css.mobileHalf)}
                >
                  <Row>
                    <Column large={12} small={6} className={css.mobileBox}>
                      <div className={css.columnTitle}>Our Partners</div>
                      <ul className={css.categoriesListing}>
                        {partnersLinks.map((link, index) => {
                          return <li key={index}>{link.button}</li>;
                        })}
                      </ul>
                    </Column>
                    <Column large={12} small={6} className={css.mobileBox}>
                      <div className={css.columnTitle}>Learn more</div>
                      <ul className={css.categoriesListing}>
                        {learnMoreLinks.map((link, index) => {
                          return <li key={index}>{link.button}</li>;
                        })}
                      </ul>
                    </Column>
                  </Row>
                </Column>
              </Row>
            </Column>
          </Row>
        </Container>
      </div>
    );
  },
  (prev, next) => isEqual(prev, next)
);

export default FooterCategoriesSection;
