import React, { Component } from 'react';
import { object, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { TransactionVerificationForm } from '../../forms';
import { updateIdentity } from '../../ducks/user.duck';
import { updateProfile } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';
import { types as sdkTypes } from '../../util/sdkLoader';

import css from './TransactionIdentityVerification.css';

const { LatLng } = sdkTypes;

class TransactionIdentityVerificationComp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: null,
    };
    this.currentInitialValues = {
      nricFnFrontUrl: !props.currentUser ? null : props.currentUser.attributes.profile.protectedData.residencyNumberFront,
      nricFnBackUrl: !props.currentUser ? null : props.currentUser.attributes.profile.protectedData.residencyNumberBack,
      drivingLicenseFrontUrl: !props.currentUser ? null : props.currentUser.attributes.profile.protectedData.drivingLicenseFront,
      drivingLicenseBackUrl: !props.currentUser ? null : props.currentUser.attributes.profile.protectedData.drivingLicenseBack,
      drivingLicenseStatusUrl: !props.currentUser ? null : props.currentUser.attributes.profile.protectedData.drivingLicenseStatus,
      internationalDrivingLicenseFrontUrl: !props.currentUser ? null : props.currentUser.attributes.profile.protectedData.internationalDrivingLicenseFront,
      internationalDrivingLicenseBackUrl: !props.currentUser ? null : props.currentUser.attributes.profile.protectedData.internationalDrivingLicenseBack,
      photo: !props.currentUser ? null : props.currentUser.attributes.profile.protectedData.frontalPhoto
    }
    this.setNricFnFrontUrl = this.setNricFnFrontUrl.bind(this);
    this.setNricFnBackUrl = this.setNricFnBackUrl.bind(this);
    this.setDrivingLicenseFrontUrl = this.setDrivingLicenseFrontUrl.bind(this);
    this.setDrivingLicenseBackUrl = this.setDrivingLicenseBackUrl.bind(this);
    this.setDrivingLicenseStatusUrl = this.setDrivingLicenseStatusUrl.bind(this);
    this.setInternationalDrivingLicenseFrontUrl = this.setInternationalDrivingLicenseFrontUrl.bind(this);
    this.setInternationalDrivingLicenseBackUrl = this.setInternationalDrivingLicenseBackUrl.bind(this);
    this.setPhoto = this.setPhoto.bind(this);
  }

  setNricFnFrontUrl(data) {
    this.currentInitialValues.nricFnFrontUrl = data;
  }
  setNricFnBackUrl(data) {
    this.currentInitialValues.nricFnBackUrl = data;
  }
  setDrivingLicenseFrontUrl(data) {
    this.currentInitialValues.drivingLicenseFrontUrl = data;
  }
  setDrivingLicenseBackUrl(data) {
    this.currentInitialValues.drivingLicenseBackUrl = data;
  }
  setDrivingLicenseStatusUrl(data) {
    this.currentInitialValues.drivingLicenseStatusUrl = data;
  }
  setInternationalDrivingLicenseFrontUrl(data) {
    this.currentInitialValues.internationalDrivingLicenseFrontUrl = data;
  }
  setInternationalDrivingLicenseBackUrl(data) {
    this.currentInitialValues.internationalDrivingLicenseBackUrl = data;
  }
  setPhoto(data) {
    this.currentInitialValues.photo = data;
  }
  render() {
    const {
      className,
      rootClassName,
      currentUser,
      updateIdentityError
    } = this.props;

    const rootClass = rootClassName || css.root;
    const classes = classNames(rootClass, className);

    const isVerified = !!currentUser && !!currentUser.id && currentUser.identityStatus && currentUser.identityStatus.guestVerified;
    const underVerified = !!currentUser && !!currentUser.id && currentUser.identityStatus && currentUser.identityStatus.guestUnderVerify;
    const panelTitle = isVerified || underVerified ? (
      <FormattedMessage id="TransactionIdentityVerification.createListingTitlePendingVerify" values={{ username: currentUser.attributes.profile.displayName }} />
    ) : (
        <FormattedMessage id="TransactionIdentityVerification.createListingTitleFirstTime" values={{ newline: (<br />) }} />
      );
    //
    const userName = !!currentUser && !!currentUser.id ? currentUser.attributes.profile.displayName : this.state.title
    const title = this.state.title ? userName : panelTitle;

    const userLocation = !this.props.currentUser ? null : this.props.currentUser.attributes.profile.protectedData.location;
    const location = userLocation ? {
      ...userLocation,
      selectedPlace: {
        ...userLocation.selectedPlace,
        origin: new LatLng(userLocation.selectedPlace.origin.lat, userLocation.selectedPlace.origin.lng)
      }
    } : null;

    return (
      <div className={classes}>
        {/* <h1 className={css.title}>{title}</h1> */}
        <TransactionVerificationForm
          className={css.form}
          initialValues={{
            nricFn: !this.props.currentUser ? '' : this.props.currentUser.attributes.profile.protectedData.residencyNumber,
            bankName: !this.props.currentUser ? '' : this.props.currentUser.attributes.profile.protectedData.bankAccount,
            accountName: !this.props.currentUser ? '' : this.props.currentUser.attributes.profile.protectedData.bankAccountName,
            postalCode: !this.props.currentUser ? '' : this.props.currentUser.attributes.profile.protectedData.postalCode,
            bankAccountNumber: !this.props.currentUser ? '' : this.props.currentUser.attributes.profile.protectedData.bankAccountNumber,
            // location: !this.props.currentUser ? '' : this.props.currentUser.attributes.profile.protectedData.location,
            location: location ? {
              search: location.search, selectedPlace: {
                address: location.selectedPlace.address,
                origin: location.selectedPlace.origin
              }
            } : null,
            building: !this.props.currentUser ? '' : this.props.currentUser.attributes.profile.protectedData.building,
            floorUnit: !this.props.currentUser ? '' : this.props.currentUser.attributes.profile.protectedData.floorUnit,
            city: !this.props.currentUser ? 'Singapore' : this.props.currentUser.attributes.profile.protectedData.city,
            country: !this.props.currentUser ? 'Singapore' : this.props.currentUser.attributes.profile.protectedData.country,
            blockNo: !this.props.currentUser ? '' : this.props.currentUser.attributes.profile.protectedData.blockNo
          }}
          onSubmit={values => {
            const hasProfilePhoto = this.props.currentUser && this.props.currentUser.profileImage;
            const initialPhoto = this.currentInitialValues.photo ? this.currentInitialValues.photo : hasProfilePhoto ? [this.props.currentUser.profileImage.attributes.variants['square-small2x'].url] : null;
            if (this.props.imageUpload) {
              this.props.onUpdateProfile({ profileImageId: this.props.imageUpload.imageId })
            }
            this.props.updateIdentity({ ...values, ...this.currentInitialValues, email: this.props.currentUser.attributes.email, photo: initialPhoto }, "GUEST");
            this.setState({
              title: userName + ','
            });

          }}
          saveActionMsg={(<FormattedMessage id="TransactionIdentityVerification.submitVerify" />)}
          currentUser={this.props.currentUser}
          nricFnFrontUrl={!this.props.currentUser ? null : this.props.currentUser.attributes.profile.protectedData.residencyNumberFront}
          nricFnBackUrl={!this.props.currentUser ? null : this.props.currentUser.attributes.profile.protectedData.residencyNumberBack}
          drivingLicenseFrontUrl={!this.props.currentUser ? null : this.props.currentUser.attributes.profile.protectedData.drivingLicenseFront}
          drivingLicenseBackUrl={!this.props.currentUser ? null : this.props.currentUser.attributes.profile.protectedData.drivingLicenseBack}
          drivingLicenseStatusUrl={!this.props.currentUser ? null : this.props.currentUser.attributes.profile.protectedData.drivingLicenseStatus}
          internationalDrivingLicenseFrontUrl={!this.props.currentUser ? null : this.props.currentUser.attributes.profile.protectedData.internationalDrivingLicenseFront}
          internationalDrivingLicenseBackUrl={!this.props.currentUser ? null : this.props.currentUser.attributes.profile.protectedData.internationalDrivingLicenseBack}
          photo={!this.props.currentUser ? null : this.props.currentUser.attributes.profile.protectedData.frontalPhoto}
          setNricFnFrontUrl={this.setNricFnFrontUrl}
          setNricFnBackUrl={this.setNricFnBackUrl}
          setDrivingLicenseFrontUrl={this.setDrivingLicenseFrontUrl}
          setDrivingLicenseBackUrl={this.setDrivingLicenseBackUrl}
          setDrivingLicenseStatusUrl={this.setDrivingLicenseStatusUrl}
          setInternationalDrivingLicenseFrontUrl={this.setInternationalDrivingLicenseFrontUrl}
          setInternationalDrivingLicenseBackUrl={this.setInternationalDrivingLicenseBackUrl}
          setPhoto={this.setPhoto}
          location={this.props.location}
          updateIdentityError={updateIdentityError}
        />
      </div>
    );
  }
}

TransactionIdentityVerificationComp.defaultProps = {
  className: null,
  rootClassName: null,
};

TransactionIdentityVerificationComp.propTypes = {
  className: string,
  rootClassName: string,
  location: object,
};

const mapStateToProps = state => {
  const {
    image,
  } = state.ProfileSettingsPage;
  const { updateIdentityError } = state.user;
  return {
    imageUpload: image,
    updateIdentityError
  };
};

const mapDispatchToProps = dispatch => ({
  updateIdentity: (userData, type) => dispatch(updateIdentity(userData, type)),
  onUpdateProfile: data => dispatch(updateProfile(data)),
});

const TransactionIdentityVerification = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(TransactionIdentityVerificationComp);

export default TransactionIdentityVerification;
