import React, { Component } from 'react';
import { injectIntl } from 'react-intl';

import css from './TrustBoxSlider.css';

const TrustBoxSliderElement = ({ title, subHeading, trustBoxRef }) => (
  <div className={css.sectionTestimonials}>
    <div className={css.sectionStoriesTitleWrapper}>
      <div className={css.sectionTitle}>{title || 'Testimonials'}</div>
      <p className={css.sectionStoriesDescription}>{subHeading}</p>
    </div>
    <div className={css.sliderWrapper}>
      <div
        ref={trustBoxRef}
        class="trustpilot-widget"
        data-locale="en-US"
        data-template-id="54ad5defc6454f065c28af8b"
        data-businessunit-id="5f9cc167ce6f0b000158c1aa"
        data-style-height="240px"
        data-style-width="100%"
        data-theme="light"
        data-stars="4,5"
        data-review-languages="en"
        data-font-family="Open Sans"
        data-text-color="#4A4A4A"
      >
        <a href="https://www.trustpilot.com/review/www.drivelah.sg" target="_blank" rel="noopener">
          Trustpilot
        </a>
      </div>
    </div>
  </div>
);

class TrustBoxSliderComponent extends Component {
  constructor(props) {
    super(props);
    this.trustBoxRef = React.createRef();
  }

  componentDidMount() {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded just yet.
    // When it is, it will automatically load the TrustBox.
    if (typeof window !== 'undefined' && window.Trustpilot) {
      window.Trustpilot.loadFromElement(this.trustBoxRef.current, true);
    }
  }

  render() {
    return (
      <TrustBoxSliderElement
        title={this.props.sectionTitle}
        subHeading={this.props.sectionSubTitle}
        trustBoxRef={this.trustBoxRef}
      />
    );
  }
}

const TrustBoxSlider = injectIntl(TrustBoxSliderComponent);
export default TrustBoxSlider;
