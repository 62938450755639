import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { Button, NamedRedirect } from '../..';
import dynamic from 'next/dynamic';
import { checkAndCreateOnfidoToken, updateFlexUserCheckId } from '../../../util/onfidoHelpers';
import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import css from './SingpassProfile.css';
import { updateUserData } from '../../../ducks/user.duck';
import VerificationIndicator from '../VerificationIndicator/VerificationIndicator';
import { LOSForm, SingPassProfileForm } from '../../../forms';
import { get } from 'lodash';
import { types as sdkTypes } from '../../../util/sdkLoader';
import {
  saveAddressDetails,
  saveUpdateUserDetails,
} from '../../UserVerificationWithOnfido/AddressDetailsPage.duck';
import { isScrollingDisabled } from '../../../ducks/UI.duck';
import { saveContactDetails } from '../../UserVerificationWithOnfido/PhoneNumber.duck';
import {
  savePersonalDetails,
} from '../../../containers/PersonalDetailsPage/PersonalDetailsPage.duck';
import { verifyIdentifyFromServer } from '../../../util/identityVerificationHelpers';
import { clearSingPassData } from '../../../util/singpass';
import { pushGTMGuestVerify } from '../../../util/gtm/gtmHelpers';
import {
  EVENT_GUEST_VERIFY_DRIVER_REJECTED,
  EVENT_GUEST_VERIFY_DRIVER_VERIFIED,
} from '../../../util/gtm/gtmConstants';
import { denormalisedEntities } from '../../../util/data';
import { getActiveTrips, updateTransactionVerification } from '../../../containers/TransactionPage/TransactionPage.duck';
import { ID_VERIFICATION_STATUSES } from '../../TripPanel/transitionHelper';
import { TRANSITION_UNVERIFIED_VERIFICATION_SENT_INSTANT_CONFIRMED,TRANSITION_UNVERIFIED_VERIFICATION_SENT_CONFIRMED,TRANSITION_UNVERIFIED_VERIFICATION_SENT_INSTANT_DECLINED,
  TRANSITION_UNVERIFIED_VERIFICATION_SENT_DECLINED,
  TRANSITION_UNVERIFIED_VERIFICATION_SENT,
  TRANSITION_UNVERIFIED_VERIFICATION_SENT_INSTANT } from '../../../util/transaction';
import {
  getEventsByBucket
} from '../../../util/gtm/gtmCreateProperties';
import { triggerAnalyticsEvent } from '../../../util/amplitudeMapEvents';
import { event_trigger_ids } from '../../../util/analyticsConstants';
import AdditionalSingpassInfo from '../../AdditionalSingpassInfo/AdditionalSingpassInfo';

const { LatLng } = sdkTypes;


let initializeOnfido = null;

const DynamicHeader = dynamic(
  () =>
    import('../../../components/OnfidoHelpers/OnfidoModalInitiator').then(comp => {
      initializeOnfido = comp;
    }),
  {}
);

const JustToModifyStep = ({ modifyStep, handleSteps, step, title }) => {
  React.useEffect(() => {
    modifyStep && handleSteps(step, title);
  }, []);
  return <></>;
};


const SingpassProfileComponent = props => {
  const {
    intl,
    onUpdateUserData,
    onSumitUserUpdateDetails,
    saveUpdateUserInProgress,
    saveUpdateUserSuccess,
    onGetActiveTrips,
    onUpdateTransition
   } = props;


  //  console.log("These are all SINGPASS PROFILE Props >>>>", props);

  const [currentUser, setCurrentUser] = useState(props.currentUser);
  const [userId, setUserId] = useState('');
  const [showSteps, setShowSteps] = useState(false);
  const modalReference = useRef(null);
  const history = useHistory();
  const userOnfidoApplicantId = get(
    currentUser,
    'attributes.profile.protectedData.onfidoUserID',
    null
  );

  const flexLosDocumentUrl = get(currentUser, 'attributes.profile.publicData.sponsorshipLetter', '');

  const [losDocumentUrl, setlosDocumentUrl] = useState(flexLosDocumentUrl);

  const [onfidoApplicatnId, setOnfidoApplicantId] = useState(userOnfidoApplicantId);
  const [singPassDataClear, setSingPassDataClear] = useState(false)

  const [step, setStep] = useState({
    stepNum: 0,
    stepTitle: '',
  });
  // const [currentPage, setCurrentPage ] = useState('singpassProfile')
  const [currentPage, setCurrentPage ] = useState('singpassProfile')
  const [onfidoInProgress, setOnfidoInProgress] = useState(false);
  const [bucketResponse, setBucketResponse] = useState({});

  useEffect(() => {
    const fetchAndTriggerEvents = async () => {
      try {
        const response = await getEventsByBucket('guestverify');
        setBucketResponse(response);
        triggerAnalyticsEvent({
          event_id: event_trigger_ids.GUEST_VERIFY_OPEN_FORM,
          eventData: response.data,
          props: {
            guest: currentUser,
            ui: {
              page: 'ProfileScreen'
            }
          },
        });
      } catch (error) {
        console.error('Error fetching events or triggering GTM event:', error);
      }
    };


    if (currentUser) {
      fetchAndTriggerEvents();
    }
  }, [currentUser]);


  // Here generating the form initial values
  const initialValues = {
    email: '',
    emailVerified: '',
    phoneCode: '+65',
    dateOfBirth: '',
    nationality: '',
    nationalityCode: '',
    passType: '',
    nricNumber: '',
    expiryDate: '',
    licenseNumber: '',
    licenseIssueDate: '',
    licenseExpiryDate: '',
    status: '',
    class: '',
    streetName: '',
    blockNumber: '',
    floorUnit: '',
    building: '',
    postalCode: ''
  };

  let attributes = {};
  if (currentUser && currentUser.attributes) {
    attributes = currentUser.attributes;
  }
  const {email, emailVerified, profile} = attributes;

  if (email) {
    initialValues.email = email;
    initialValues.emailVerified = emailVerified;
  }

  let protectedData = {};
  if (profile && profile.protectedData) {
    protectedData = profile.protectedData;
  }
  const {singpassData, singpassResult, residencyStatus, drivingLicense, location} = protectedData;


  const { selectedPlace } = location || {};
  const { address, origin } = selectedPlace || {};

  if (singpassData) {

    //Send Singpass step completed (data fetched) event
    triggerAnalyticsEvent({
      event_id: event_trigger_ids.GUEST_VERIFY_COMPLETE_SINGPASS,
      eventData: bucketResponse.data,
      props: {
        guest: currentUser,
      },
    });

    // Phone
    let phoneCode = '+65';
    let phoneNumber = null
    const mobileno = singpassData.mobileno;
    if (mobileno && mobileno.areacode && mobileno.nbr &&
        mobileno.areacode.value !== '' && mobileno.nbr.value !== '') {
        phoneCode = `+${mobileno.areacode.value}`
        phoneNumber = mobileno.nbr.value
      // phone = `${mobileno.prefix.value}${mobileno.areacode.value}${mobileno.nbr.value}`;
    }
    initialValues.phoneCode = phoneCode
    initialValues.phoneNumber = phoneNumber;

    // dob
    initialValues.dateOfBirth = singpassData.dob ? singpassData.dob.value : '';

    // Nationality
    const nationality = singpassData.nationality;
    if (nationality) {
      initialValues.nationality = nationality.desc || '';
      initialValues.nationalityCode = nationality.code || '';
    }

    // Pass Type
    const passtype = singpassData.passtype;
    if (passtype) {
      initialValues.passType = passtype.desc || '';
    }

    // address
    initialValues.location = {
      search: address,
      selectedPlace: {
        address,
        origin: new LatLng(origin && origin.lat, origin && origin.lng),
      },
    };
    initialValues.floorUnit = location && location.floorUnit;
    initialValues.building = location && location.building || '' ;
    initialValues.postalCode = location && location.postalCode ;
    initialValues.neighborhood = location && location.neighborhood ;
  }

  if (residencyStatus) {
    initialValues.nricNumber = residencyStatus.residencyNumber || '';
    initialValues.expiryDate = residencyStatus.passExpiryDate ? residencyStatus.passExpiryDate.value : '';
  }

  if (drivingLicense) {
    initialValues.licenseNumber = drivingLicense.drivingLicenseNumber || '';
    initialValues.licenseIssueDate = drivingLicense.drivingLicenseIssue || '';
    initialValues.licenseExpiryDate = drivingLicense.drivingLicenseExpiry || '';
    initialValues.status = drivingLicense.drivingLicenseStatus || '';
    initialValues.class = drivingLicense.drivingLicenseClass || '';
  }

  // this method is for stepper function
  const handleSteps = (stepNum, stepTitle) => {
    setStep({
      stepNum,
      stepTitle,
    });
    setShowSteps(true);
  };

  const handleClearData = async (userId) => {

    try {
      setSingPassDataClear(true)
      const res = await clearSingPassData(userId);

    } catch (error) {

    }
    setSingPassDataClear(false)
    history.push('/account/driving-verification');
  };

  useEffect(() => {
    let sessionUserData = JSON.parse(sessionStorage.getItem('CURRENT_USER'));
    if (!userId && currentUser && currentUser.id.uuid) {
      setUserId(currentUser.id);
      setCurrentUser(currentUser);
    } else if (!userId && sessionUserData && sessionUserData.id && sessionUserData.id.uuid) {
      setUserId(sessionUserData.id.uuid);
      setCurrentUser(sessionUserData);
    }
  }, [setUserId, userId, setCurrentUser]);

  const _handleInitiateDriverVerification = async () => {
    try {
      setOnfidoInProgress(true);
      const userId = await get(currentUser, 'id.uuid');
      const onfidoUserRes = await checkAndCreateOnfidoToken(currentUser);
      const { onfidoUserId, token } = onfidoUserRes || {};
      setOnfidoApplicantId(onfidoUserId);
      initializeOnfido.initializeOnfidoDriverVerification(token, currentUser, modalReference, onfidoUserId, _createCheckAndUpdateUserData);
    } catch (err) {

    }
  };

  const _createCheckAndUpdateUserData = async (
    reportNames,
    onfidoApplicantUniqueId,
    isLastStep = true,
    isNricCheck = false,
    isDriverVerificationCheck = false
  ) => {

    try {
      let applicantId = onfidoApplicantUniqueId;

      if (applicantId) {
        const res = await checkAndCreateOnfidoToken(currentUser);
        if (res) {
          const { onfidoUserId, token } = res || {};
          let updateUserData = await updateFlexUserCheckId({
            reportNames,
            onfidoApplicantId: onfidoUserId,
            isHost:false,
            isNricCheck,
            isDriverVerificationCheck,
          });
          if(updateUserData.publicData) {
            updateUserData.publicData.guestIdentityVerificationStatus = 'pending'
            updateUserData.publicData.guestVerificationSent = new Date().toISOString()
          }
          else {
            updateUserData.publicData = { guestIdentityVerificationStatus : 'pending', guestVerificationSent: new Date().toISOString()};
          }
          await onSumitUserUpdateDetails(updateUserData);
          verifyIdentifyFromServer({
            userId,
            submitType: 'guest',
            verificationType: 'singpass'
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
    setOnfidoInProgress(false);
  };

  const onSubmitLosForm = values => {
    const updateValues = {
      publicData: {
        sponsorshipLetter: losDocumentUrl,
        guestIdentityVerificationStatus: 'pending',
        guestVerificationSent: new Date().toISOString()
      }
    };
    onUpdateUserData(updateValues);
    verifyIdentifyFromServer({
      userId: currentUser.id.uuid,
      submitType: 'guest',
      verificationType: 'singpass'
    });
    updateTransitionAfterVerification()
  }

  const onSubmitProfileImageOnly = () => {
    const updateValues = {
      publicData: {
        guestIdentityVerificationStatus: 'confirmed',
        guestVerificationSent: new Date().toISOString()
      }
    };
    onUpdateUserData(updateValues);
    verifyIdentifyFromServer({
      userId: currentUser.id.uuid,
      submitType: 'guest',
      verificationType: 'singpass'
    });
    updateTransitionAfterVerification()
  }

  const onSubmitSingPassForm = async values => {
    // this is for form submit

    const {
      selectedPlace: { address, origin },
    } = values.location;

    let formParams = {
      privateData: {
        residentialStatus: values.passType,
      },
      protectedData: {

        singpassConfirmed: true,
        phoneNumberVerified: true,
        phoneNumber: values.phoneNumber,
        phoneCode:values.phoneCode,
        location: {
          search: address,
          selectedPlace: {
            address,
            origin: new LatLng(origin.lat, origin.lng),
          },
          building: values.building,
          floorUnit: values.floorUnit,
          neighborhood: values.neighborhood,
          postalCode: values.postalCode
        },
        floorUnit: values.floorUnit,
        postalCode: values.postalCode,
      },
    };
    // There will be one case where
    //  on one we have to open the

    if(singpassResult && singpassResult.actionNeeded && (singpassResult.actionNeeded === 'onFidoUpload')) {
      onUpdateUserData(formParams);
      setCurrentPage(singpassResult.actionNeeded)
    }

    else if ((singpassResult.result.toLowerCase() !== 'declined') && (singpassResult.actionNeeded === 'sponsershipLetterUpload' || currentUser.profileImage === null)) {
      onUpdateUserData(formParams);
      setCurrentPage("additionalStep");
    }

    else {
      if (singpassResult.result.toLowerCase() === 'declined') {
        // Call GTM rejected event EVENT_GUEST_VERIFY_DRIVER_REJECTED
        // pushGTMGuestVerify({
        //   props: props,
        //   event: EVENT_GUEST_VERIFY_DRIVER_REJECTED,
        // });

        // triggerAnalyticsEvent({
        //   event_id:   'guestverify_driver_declined',
        //   isBackendApiCall: true,
        //   eventData: bucketResponse.data,
        //   props: {
        //     user: currentUser,
        //     ...props
        //   },
        // });

        if(formParams.publicData) {
          formParams.publicData.guestIdentityVerificationStatus = 'declined'
        }
        else {
          formParams.publicData = {
            guestIdentityVerificationStatus: 'declined'
          }
        }

        // nextStepSingPass('declined', singpassResult.actionNeeded);
      } else if (singpassResult.result.toLowerCase() === 'approved') {
        // formParams.emailVerified = true;
        formParams.protectedData.phoneNumberVerified = true
        //Call GTM verify event EVENT_GUEST_VERIFY_DRIVER_VERIFIED

        // pushGTMGuestVerify({
        //   props: props,
        //   event: EVENT_GUEST_VERIFY_DRIVER_VERIFIED,
        // });

        // triggerAnalyticsEvent({
        //   event_id: 'guestverify_driver_verified',
        //   isBackendApiCall: true,
        //   eventData: bucketResponse.data,
        //   props: {
        //     user: currentUser,
        //     ...props
        //   },
        // });

        if(formParams.publicData) {
          formParams.publicData.guestIdentityVerificationStatus = 'confirmed'

        }
        else {
          formParams.publicData = {
            guestIdentityVerificationStatus: 'confirmed',
          }
        }

        // nextStepSingPass('approved');
      } else if (singpassResult.result.toLowerCase() === 'declined - snoozed') {

        // Call GTM rejected event EVENT_GUEST_VERIFY_DRIVER_REJECTED

        // pushGTMGuestVerify({
        //   props: props,
        //   event: EVENT_GUEST_VERIFY_DRIVER_REJECTED,
        // });

        // triggerAnalyticsEvent({
        //   event_id: 'guestverify_driver_declined',
        //   isBackendApiCall: true,
        //   eventData: bucketResponse.data,
        //   props: {
        //     user: currentUser,
        //     ...props
        //   },
        // });
        // nextStepSingPass('declined - snoozed');
        if(formParams.publicData) {
          formParams.publicData.guestIdentityVerificationStatus = 'declined'
        }
        else {
          formParams.publicData = {
            guestIdentityVerificationStatus: 'declined'
          }
        }
      } else if (singpassResult.result.toLowerCase() === 'pending') {
        // nextStepSingPass('pending', singpassResult.actionNeeded);

        triggerAnalyticsEvent({
          event_id: event_trigger_ids.GUEST_VERIFY_SUBMIT_FORM,
          isBackendApiCall: true,
          eventData: bucketResponse.data,
          props: {
            guest: currentUser,
          },
        });

        // formParams.emailVerified = true;
        if(formParams.publicData) {
          formParams.publicData.guestIdentityVerificationStatus = 'pending'

        }
        else {
          formParams.publicData = {
            guestIdentityVerificationStatus: 'pending'
          }

        }
      }

      if(formParams.publicData) {
        formParams.publicData.guestVerificationSent =  new Date().toISOString()
      }
      else {
        formParams.publicData = {
          guestVerificationSent : new Date().toISOString()
        }
      }

      updateTransitionAfterVerification();

      onUpdateUserData(formParams);
      verifyIdentifyFromServer({
        userId: currentUser.id.uuid,
        submitType: 'guest',
        verificationType: 'singpass'
      });
    }
  };

  const updateTransitionAfterVerification=async ()=>{
    const result=await onGetActiveTrips({ page: 1 })
    let singpassStatus='';
    if (singpassResult) {
      if (singpassResult.result.toLowerCase() === 'declined') {
        singpassStatus = ID_VERIFICATION_STATUSES.declined;
      } else if (singpassResult.result.toLowerCase() === 'approved') {
        singpassStatus = ID_VERIFICATION_STATUSES.confirmed;
      } else if (
        singpassResult.result.toLowerCase() === 'declined - snoozed'
      ) {
        singpassStatus = ID_VERIFICATION_STATUSES.declined;
      } else if (singpassResult.result.toLowerCase() === 'pending') {
        singpassStatus = ID_VERIFICATION_STATUSES.pending;
      }
    }

    if (result.trips.length > 0) {
      for (let t of result.trips) {
        const isInstant =t && t.listing && t.listing.attributes && t.listing.attributes.publicData && t.listing.attributes.publicData.instantBooking;
        let transition = '';

        if (singpassStatus) {
          if (singpassStatus === ID_VERIFICATION_STATUSES.confirmed) {
            transition = isInstant
              ? TRANSITION_UNVERIFIED_VERIFICATION_SENT_INSTANT_CONFIRMED
              : TRANSITION_UNVERIFIED_VERIFICATION_SENT_CONFIRMED;
          } else if (singpassStatus === ID_VERIFICATION_STATUSES.declined) {
            transition = isInstant
              ? TRANSITION_UNVERIFIED_VERIFICATION_SENT_INSTANT_DECLINED
              : TRANSITION_UNVERIFIED_VERIFICATION_SENT_DECLINED;
          } else {
            transition = isInstant
              ? TRANSITION_UNVERIFIED_VERIFICATION_SENT_INSTANT
              : TRANSITION_UNVERIFIED_VERIFICATION_SENT;
          }
        } else {
          transition = isInstant
            ? TRANSITION_UNVERIFIED_VERIFICATION_SENT_INSTANT
            : TRANSITION_UNVERIFIED_VERIFICATION_SENT;
        }
        let data = {
          transactionId: t.id.uuid,
          transition: transition,
        };

        await onUpdateTransition(data);
      }
    }
  }

  // There will be two steps on the bases of cases

  let totalSteps = 2;

  if((singpassResult.result.toLowerCase() === 'declined') || ((singpassResult.actionNeeded === null) && (currentUser.profileImage !== null))) {
    totalSteps = 1;
  }

  // If singpass Result is not there this page should redirect to landing page

  if(!singpassResult) {
    return <NamedRedirect name={'AccountSettingDriverVerificationPage'} />;
  }

  return (
    <>
      <div className={css.titleContainer}>
          <VerificationIndicator
            showSteps={showSteps}
            step={step}
            totalSteps={totalSteps}
            handleSteps={handleSteps}
          />
          {currentPage === 'singpassProfile' ?
          <SingPassProfileForm
            initialValues={initialValues}
            // intl={intl}
            onSubmitSingPassForm={onSubmitSingPassForm}
            step={1}
            modifyStep={true}
            inProgress={saveUpdateUserInProgress}
            ready={saveUpdateUserSuccess}
            handleSteps={handleSteps}
            currentUser={currentUser}
            handleClearData={handleClearData}
            singPassDataClear={singPassDataClear}
            userId={currentUser && currentUser.id.uuid }
        />: null}
        {currentPage === 'onFidoUpload' ?  <>
            <DynamicHeader />
            <JustToModifyStep
              modifyStep={true}
              handleSteps={handleSteps}
              step={2}
              title={'Driver license verification'}
            />
            <div className={css.identityVerificationGuide}>
              <p className={css.identityVerificationGuideText}>
                    <FormattedMessage id="driverIdentityVerification.guideLinet1" />
                <br></br>
              </p>
            </div>
            <div
              className={css.verificationModalButton}
              onClick={_handleInitiateDriverVerification}
            >
              <Button
                type="submit"
                inProgress={saveUpdateUserInProgress || onfidoInProgress}
                ready={saveUpdateUserSuccess}
              >
                Continue
              </Button>
            </div>
            <br></br>
            <div ref={modalReference} style={{ width: 200 }}>
              {' '}
            </div>
          </> : null}

        {currentPage === 'additionalStep' ?
          <>
            <JustToModifyStep
              modifyStep={true}
              handleSteps={handleSteps}
              step={2}
              title={'Need More Information'}
            />
            <AdditionalSingpassInfo 
            singpassResult={singpassResult}
            currentUser={currentUser}
            onSubmitLOSDocument={onSubmitLosForm}
            updateLosDocumentUrl={data => setlosDocumentUrl(data)}
            onSubmitProfileImageOnly = {onSubmitProfileImageOnly} />
          </>
          : null}

          {currentPage === 'sponsershipLetterUpload' ?
          <>
              <JustToModifyStep
                modifyStep={true}
                handleSteps={handleSteps}
                step={2}
                title={'Letter of sponsorship'}
              />
              <LOSForm
                inProgress={saveUpdateUserInProgress}
                ready={saveUpdateUserSuccess}
                saveUpdateUserInProgress={saveUpdateUserInProgress}
                currentUser={currentUser}
                className={css.form}
                initialValues={{ losDocumentUrl }}
                onSubmit={values => {
                  const updateValues = {
                    publicData: {
                      sponsorshipLetter: losDocumentUrl,
                      guestIdentityVerificationStatus: 'pending',
                      guestVerificationSent: new Date().toISOString()
                    }
                  };
                  onUpdateUserData(updateValues);
                  verifyIdentifyFromServer({
                    userId: currentUser.id.uuid,
                    submitType: 'guest',
                    verificationType: 'singpass'
                  });
                  updateTransitionAfterVerification()
                }}
                step={8}
                losDocumentUrl={losDocumentUrl}
                updateLosDocumentUrl={data => setlosDocumentUrl(data)}
              />
            </>
           : null}
      </div>
     </>
  );
};
SingpassProfileComponent.propTypes = {
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  // Topbar needs user info.
  const { currentUser, sendVerificationEmailInProgress, sendVerificationEmailError } = state.user;
  const { connectCalendarTabVisibility } = state.tabPanels;
  const {
    saveEmailError,
    savePhoneNumberError,
    saveContactDetailsInProgress,
    contactDetailsChanged,
  } = state.ContactDetailsPage;

  const {
    saveAddressDetailsSuccess,
    saveAddressDetailsError,
    saveAddressDetailsInProgress,
    saveUpdateUserSuccess,
    saveUpdateUserError,
    saveUpdateUserInProgress,
  } = state.AddressDetailsPage;

  return {
    saveEmailError,
    savePhoneNumberError,
    saveContactDetailsInProgress,
    currentUser,
    contactDetailsChanged,
    scrollingDisabled: isScrollingDisabled(state),
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    connectCalendarTabVisibility,
    saveAddressDetailsSuccess,
    saveAddressDetailsError,
    saveAddressDetailsInProgress,
    saveUpdateUserSuccess,
    saveUpdateUserError,
    saveUpdateUserInProgress,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdateUserData: data => dispatch(updateUserData(data)),
    onSubmitContactDetails: values => dispatch(saveContactDetails(values)),
    onSumitUserUpdateDetails: values => dispatch(saveUpdateUserDetails(values)),
    onSubmitPersonalDetailsForm: values => dispatch(savePersonalDetails(values)),
    onSubmitAddressDetailsForm: address_values => dispatch(saveAddressDetails(address_values)),
    onGetActiveTrips:(param)=>dispatch(getActiveTrips(param)),
    onUpdateTransition:(param)=>dispatch(updateTransactionVerification(param))
  };
};


const SingpassProfile = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(SingpassProfileComponent);

SingpassProfile.displayName = 'SingpassProfile';

export default SingpassProfile;
