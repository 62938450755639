import React, { useEffect, useState } from 'react';
import css from './SeoLandingPage.css';
import { ListingCardV3, SearchMap } from '../../components';
import Slider from 'react-slick';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withViewport } from '../../util/contextHelpers';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { initiateEventFromListing } from '../../util/gtm/gtmHelpers';
import { EVENT_SEARCH_PERFORMED } from '../../util/gtm/gtmConstants';
import { encodeLatLngBounds, parse } from '../../util/urlHelpers';
import classNames from 'classnames';
import { useHistory } from 'react-router';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { fetchLocalizedCar, searchMapListings } from '../SearchPage/SearchPage.duck';
import { getListingsById, getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { gSend, GTAG_ACTIONS, getHourlyFormattedTimes, getFormattedDateByDates } from '../../util/gtag';

const DEFAULT_BOUNDS = {
  _sdkType: 'LatLngBounds',
  ne: {
    _sdkType: 'LatLng',
    lat: 1.4708809,
    lng: 104.04157989999999,
  },
  sw: {
    _sdkType: 'LatLng',
    lat: 1.216611,
    lng: 103.60650989999999,
  },
};
const SectionCarsOnLocations = ({
  isMobileLayout,
  address,
  bounds,
  center,
  locationMap,
  location,
  onFetchLocationListing,
  locationListings,
  onSearchMapListings,
  mapListings,
  setMapListingArray,
}) => {
  const history = useHistory();
  const currentSearchParams = useSelector(state => state.UI.searchParams);
  const { fetchLocalizedCarRequest } = useSelector(state => state.SearchPage);
  let [onLocationChange, setOnLocationChange] = useState(null);

  useEffect(() => {
    const searchParams = {
      per_page: 8,
      bounds,
      address,
      sort: 'pub_isSuperHostSorting,pub_instantBookingSorting,meta_isDrivelahGoSorting',
    };
    onFetchLocationListing(searchParams);
    onSearchMapListings(searchParams);
  }, [onLocationChange]);

  useEffect(() => {
    setMapListingArray(mapListings);
  }, [mapListings]);

  const sliderConfig = {
    // className: css.slider,
    infinite: false,
    slidesToScroll: 1,
    slidesToShow: 4,
    initialSlide: 0,
    infinite: false,
    dots: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          initialSlide: 0,
          infinite: false,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.25,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  if (!location || !location.search) {
    location.search =
      '?bounds=1.42799587%2C103.93620414%2C1.35128581%2C103.87440605&address=Punggol%2C%20Singapore&mapSearch=true';
  }

  const addressShortened = address.split(',');
  const { mapSearch, page, sort, ...searchInURL } = parse(location.search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  const onMapMoveEnd = () => {};

  const _handleSearchBasedOnLocation = () => {
    let url;
    url = createResourceLocatorString('SearchPage', routeConfiguration(), null, {
      bounds: encodeLatLngBounds(bounds ? bounds : DEFAULT_BOUNDS),
      address: address,
    });

    // initiateEventFromListing({
    //   props: {
    //     searchParams: {
    //       ...currentSearchParams,
    //       bounds: bounds ? bounds : DEFAULT_BOUNDS,
    //     },
    //   },
    //   event: EVENT_SEARCH_PERFORMED,
    // });
    const searchParams = currentSearchParams || {};
    const hours = getHourlyFormattedTimes(searchParams.hours);
    const dates = getFormattedDateByDates(searchParams.dates);
    gSend(GTAG_ACTIONS.ACTION_SEARCH, {
      // TODO: Assign proper details.
      search_location: `${searchParams.address || ''}`,
      search_pickupdate: `${dates.start ? dates.start : ''}`,
      search_pickuptime: `${hours.start ? hours.start : ''}`,
      search_dropoffdate: `${dates.end ? dates.end : ''}`,
      search_dropofftime: `${hours.end ? hours.end : ''}`,
    });
    history.push(url);
  };

  return (
    <div className={css.sectionLocationCars}>
      <div className={css.landingContainerFluid}>
        <div className={css.landingRow}>
          <div className={css.column12} style={{ background: '#FFFFFF' }}>
            <div className={css.sectionCarsTitleWrapper}>
              <div className={css.sectionTitle}>
                Choose from the top rented cars in {addressShortened && addressShortened[0]}
              </div>
            </div>
          </div>
        </div>
        <div className={classNames(css.landingRow, css.carsListingAndMaps)}>
          <div className={classNames(css.column7, css.columnListings)}>
            {!isMobileLayout ? (
              fetchLocalizedCarRequest ? (
                <div className={css.sectionCarsLoading}>Loading car...</div>
              ) : (
                <div className={css.listingGrid}>
                  {locationListings.map(l => (
                    <div className={css.listingCardWrapper}>
                      <ListingCardV3 listing={l} />
                    </div>
                  ))}
                </div>
              )
            ) : (
              ''
            )}
            {isMobileLayout ? (
              fetchLocalizedCarRequest ? (
                <div className={css.sectionCarsLoading}>Loading car...</div>
              ) : (
                <div className={css.sliderWrapper}>
                  <Slider {...sliderConfig}>
                    {locationListings.map(l => (
                      <div className={css.listingCardWrapper}>
                        <ListingCardV3 listing={l} />
                      </div>
                    ))}
                  </Slider>
                </div>
              )
            ) : (
              ''
            )}
            <div className={css.btnWrapper}>
              <span className={css.viewAllCar} onClick={_handleSearchBasedOnLocation}>
                View all cars in {addressShortened && addressShortened[0]}
              </span>
            </div>
          </div>
          <div className={classNames(css.column5, css.mobileMapHolder)}>
            {isMobileLayout && (
              <SearchMap
                className={css.locationLandingMap}
                rootClassName={css.locationLandingMapRoot}
                mapRootClassName={css.locationLandingMapRootRoot}
                reusableContainerClassName={css.locationLandingMapLayout}
                onMapMoveEnd={onMapMoveEnd}
                bounds={bounds}
                center={center}
                location={locationMap}
                listings={mapListings || []}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const {
    currentPageResultIds,
    searchMapListingIds,
    featuredLocalizedCarIds,
    searchParams,
  } = state.SearchPage;
  const featuredLocalizedCarRefs = featuredLocalizedCarIds.map(id => ({ type: 'listing', id }));

  const listings = getMarketplaceEntities(state, featuredLocalizedCarRefs);
  const locationListings = getMarketplaceEntities(state, featuredLocalizedCarRefs);
  const mapListings = getListingsById(state, featuredLocalizedCarIds);
  return {
    scrollingDisabled: isScrollingDisabled(state),
    turnoffPromoteBar: state.UI.turnoffPromoteBar,
    mapListings,
    searchParams,
    featuredLocalizedCarIds,
    listings,
    locationListings,
  };
};

const mapDispatchToProps = dispatch => ({
  onSearchMapListings: searchParams => dispatch(searchMapListings(searchParams)),
  onFetchLocationListing: searchParams => dispatch(fetchLocalizedCar(searchParams)),
});

export default compose(
  withViewport,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(SectionCarsOnLocations);
