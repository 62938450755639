import React from 'react';
import css from './LandingPageGo.css';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { NamedLink } from '../../components';


const Gps = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="56.354" height="99.935" viewBox="0 0 56.354 99.935">
      <defs>
        <clipPath id="clip-path">
          <path id="Clip_2" data-name="Clip 2" d="M0,0H56.354V99.935H0Z" transform="translate(0 0.065)" fill="none" />
        </clipPath>
      </defs>
      <g id="Group_382" data-name="Group 382" transform="translate(0 -0.065)">
        <g id="Group_380" data-name="Group 380">
          <g id="Group_3" data-name="Group 3">
            <path id="Clip_2-2" data-name="Clip 2" d="M0,0H56.354V99.935H0Z" transform="translate(0 0.065)" fill="none" />
            <g id="Group_3-2" data-name="Group 3" clipPath="url(#clip-path)">
              <path id="Fill_1" data-name="Fill 1" d="M53.469,29.048V92.236c0,1.931-2.519,4.795-6,4.795H8.885a6.027,6.027,0,0,1-6-6.039V8.944a6.027,6.027,0,0,1,6-6.039H47.469c3.533,0,6,3,6,7.284v3.793h2.885V10.189C56.354,4.285,52.618,0,47.469,0H8.885A8.925,8.925,0,0,0,0,8.944V90.991a8.925,8.925,0,0,0,8.885,8.944H47.469c4.109,0,8.885-3.363,8.885-7.7V29.048Z" transform="translate(0 0.065)" fill="#00a3ad" />
            </g>
          </g>
        </g>
        <path id="Fill_4" data-name="Fill 4" d="M14.125,45.882h0l-1.3-2.163A175.635,175.635,0,0,1,1.164,20.083C-.8,15-.253,9.386,2.579,5.424,5.079,1.926,9.168,0,14.091,0c5,0,9.131,1.955,11.641,5.5,2.8,3.957,3.288,9.549,1.282,14.593A224.7,224.7,0,0,1,15.379,43.69l-1.254,2.191ZM14.091,2.858c-3.986,0-7.13,1.451-9.092,4.195A12.912,12.912,0,0,0,3.924,19.082,167.235,167.235,0,0,0,14.066,40.039a206.9,206.9,0,0,0,10.2-20.972c1.663-4.177,1.294-8.755-.962-11.947C21.331,4.332,18.146,2.858,14.091,2.858Z" transform="translate(14.118 20)" fill="#00a3ad" />
        <path id="Fill_6" data-name="Fill 6" d="M10.588,5.294A5.294,5.294,0,1,1,5.294,0a5.294,5.294,0,0,1,5.294,5.294" transform="translate(23.529 28.235)" fill="#00a3ad" />
      </g>
    </svg>
  )
};

const Key = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="56.729" height="99.935" viewBox="0 0 56.729 99.935">
      <g id="Group_383" data-name="Group 383" transform="translate(0 0)">
        <path id="Fill_1" data-name="Fill 1" d="M53.825,29.048V92.236c0,1.931-2.536,4.795-6.04,4.795H8.944A6.046,6.046,0,0,1,2.9,90.991V8.944A6.046,6.046,0,0,1,8.944,2.9H47.785c3.556,0,6.04,3,6.04,7.284v3.793h2.9V10.189C56.729,4.285,52.968,0,47.785,0H8.944A8.954,8.954,0,0,0,0,8.944V90.991a8.954,8.954,0,0,0,8.944,8.944H47.785c4.137,0,8.944-3.363,8.944-7.7V29.048Z" transform="translate(0 0)" fill="#00a3ad" />
        <path id="Fill_3" data-name="Fill 3" d="M16.054,58.575H8.241A1.247,1.247,0,0,1,7,57.329V55.168H6.641A6.641,6.641,0,0,1,.034,47.851L1.346,35.024a7.247,7.247,0,0,1,7.238-7.171H9.967V1.369A1.37,1.37,0,0,1,11.336,0h2.251a1.37,1.37,0,0,1,1.369,1.369V3.056a.385.385,0,0,1-.385.384h-.1a.386.386,0,0,0-.385.385V7.1a.386.386,0,0,0,.385.385h.1a.385.385,0,0,1,.385.384V10.5a.386.386,0,0,1-.385.385h-.1a.386.386,0,0,0-.385.385v1.059a.386.386,0,0,0,.385.385h.1a.385.385,0,0,1,.385.384v1.661a.385.385,0,0,1-.385.384h-.1a.386.386,0,0,0-.385.385v5.131a.385.385,0,0,0,.385.384h.1a.385.385,0,0,1,0,.77h-.1a.385.385,0,0,0-.385.384v1.06a.385.385,0,0,0,.385.384h.1a.386.386,0,0,1,.385.385v3.828h.755a7.246,7.246,0,0,1,7.238,7.171l1.312,12.827a6.641,6.641,0,0,1-6.607,7.317H17.3v2.161A1.247,1.247,0,0,1,16.054,58.575Zm-7.4-3.407v1.746h6.983V55.168ZM8.584,30.343a4.753,4.753,0,0,0-4.747,4.748l-.007.126L2.511,48.1a4.152,4.152,0,0,0,4.13,4.575H17.654a4.152,4.152,0,0,0,4.13-4.575L20.459,35.091a4.753,4.753,0,0,0-4.748-4.748Z" transform="translate(16.217 19.142)" fill="#00a3ad" />
      </g>
    </svg>
  )
}

const Control = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="56.495" height="99.522" viewBox="0 0 56.495 99.522">
      <defs>
        <clipPath id="clip-path">
          <path id="Clip_2" data-name="Clip 2" d="M0,0H56.495V99.522H0Z" transform="translate(0 0.064)" fill="none" />
        </clipPath>
      </defs>
      <g id="Group_384" data-name="Group 384" transform="translate(0 -0.413)">
        <g id="Group_3" data-name="Group 3" transform="translate(0 0.349)">
          <path id="Clip_2-2" data-name="Clip 2" d="M0,0H56.495V99.522H0Z" transform="translate(0 0.064)" fill="none" />
          <g id="Group_3-2" data-name="Group 3" clipPath="url(#clip-path)">
            <path id="Fill_1" data-name="Fill 1" d="M53.6,28.928V91.855c0,1.923-2.526,4.775-6.015,4.775H8.907a6.021,6.021,0,0,1-6.015-6.014V8.907A6.021,6.021,0,0,1,8.907,2.893h38.68c3.542,0,6.015,2.983,6.015,7.254v3.777H56.5V10.147C56.5,4.267,52.749,0,47.588,0H8.907A8.917,8.917,0,0,0,0,8.907V90.615a8.917,8.917,0,0,0,8.907,8.907h38.68c4.119,0,8.907-3.349,8.907-7.667V28.928Z" transform="translate(0 0.064)" fill="#00a3ad" />
          </g>
        </g>
        <path id="Fill_4" data-name="Fill 4" d="M0,2.481A15.4,15.4,0,0,1,14.526,17.007h2.481A17.876,17.876,0,0,0,0,0Z" transform="translate(29.074 31.688)" fill="#00a3ad" />
        <path id="Fill_6" data-name="Fill 6" d="M14.526,0A15.4,15.4,0,0,1,0,14.526v2.481A17.876,17.876,0,0,0,17.007,0Z" transform="translate(29.074 50.348)" fill="#00a3ad" />
        <path id="Fill_8" data-name="Fill 8" d="M17.007,2.481V0A17.876,17.876,0,0,0,0,17.007H2.482A15.4,15.4,0,0,1,17.007,2.481" transform="translate(10.414 31.688)" fill="#00a3ad" />
        <path id="Fill_10" data-name="Fill 10" d="M17.007,17.007V14.526A15.4,15.4,0,0,1,2.482,0H0A17.877,17.877,0,0,0,17.007,17.007" transform="translate(10.414 50.348)" fill="#00a3ad" />
        <path id="Fill_12" data-name="Fill 12" d="M21.578.381a.827.827,0,0,0-1.142-.25L11.73,5.707a2.791,2.791,0,0,0-3.89.054L1.325.84a.826.826,0,0,0-1,1.319L7.061,7.245a2.8,2.8,0,1,0,5.489-.1l8.777-5.623a.826.826,0,0,0,.25-1.142" transform="translate(18.432 41.775)" fill="#00a3ad" />
      </g>
    </svg>
  )
}

const Lock = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="56.495" height="99.522" viewBox="0 0 56.495 99.522">
      <defs>
        <clipPath id="clip-path">
          <rect width="56.495" height="99.522" fill="none" />
        </clipPath>
        <clipPath id="clip-path-2">
          <path id="Clip_2" data-name="Clip 2" d="M0,0H56.495V99.522H0Z" transform="translate(0 0.064)" fill="none" />
        </clipPath>
      </defs>
      <g id="Group_8_Copy" data-name="Group 8 Copy" transform="translate(0 -0.413)">
        <g id="Repeat_Grid_3" data-name="Repeat Grid 3" transform="translate(0 0.413)" clipPath="url(#clip-path)">
          <g transform="translate(0 -0.413)">
            <g id="Group_3" data-name="Group 3" transform="translate(0 0.349)">
              <path id="Clip_2-2" data-name="Clip 2" d="M0,0H56.495V99.522H0Z" transform="translate(0 0.064)" fill="none" />
              <g id="Group_3-2" data-name="Group 3" clipPath="url(#clip-path-2)">
                <path id="Fill_1" data-name="Fill 1" d="M53.6,28.928V91.855c0,1.923-2.526,4.775-6.015,4.775H8.907a6.021,6.021,0,0,1-6.014-6.014V8.907A6.021,6.021,0,0,1,8.907,2.893H47.588c3.541,0,6.015,2.983,6.015,7.254v3.777H56.5V10.147C56.5,4.267,52.749,0,47.588,0H8.907A8.917,8.917,0,0,0,0,8.907V90.615a8.917,8.917,0,0,0,8.907,8.907H47.588c4.119,0,8.907-3.349,8.907-7.667V28.928Z" transform="translate(0 0.064)" fill="#00a3ad" />
              </g>
            </g>
            <path id="Fill_4" data-name="Fill 4" d="M5.01,21.266v0a8.424,8.424,0,0,1-3.9-.908l-.155-.111c-.925-.833-1-2.348-.936-3.86A10.97,10.97,0,0,1,.6,13.032,3.7,3.7,0,0,1,1.765,11.69l.142-.111.035-.072c.1-.2.2-.46.317-.758a18.22,18.22,0,0,1,.9-2.007A16.745,16.745,0,0,1,7.227,3.748,16.109,16.109,0,0,1,16.16.152a30.663,30.663,0,0,1,6.711.071A9.457,9.457,0,0,1,28.9,3.19a20.418,20.418,0,0,1,1.848,2.28c.2.288.677.37,1.431.481,4.205.615,7.171,2.269,9.068,5.057a8.657,8.657,0,0,1,1.608,5.283,4.242,4.242,0,0,1-.967,2.635,5.078,5.078,0,0,1-3.194,1.4l-.137.012-7,0V18.686l6.935,0a3.55,3.55,0,0,0,2.162-.852,2.658,2.658,0,0,0,.556-1.637,7.01,7.01,0,0,0-1.325-4.258c-1.621-2.383-4.219-3.806-7.94-4.35A3.373,3.373,0,0,1,29.4,6.425a18.921,18.921,0,0,0-1.706-2.107,7.849,7.849,0,0,0-5.006-2.453,28.967,28.967,0,0,0-6.347-.07A14.43,14.43,0,0,0,8.321,4.987a15.224,15.224,0,0,0-3.7,4.536A16.75,16.75,0,0,0,3.8,11.355c-.129.33-.242.617-.369.878l-.2.413L2.777,13a2.113,2.113,0,0,0-.7.78,9.246,9.246,0,0,0-.407,2.679A4.889,4.889,0,0,0,2,18.963a6.946,6.946,0,0,0,3,.65c.256,0,.516-.014.773-.039l.348-.035.165,1.644-.349.035c-.312.031-.626.047-.935.047" transform="translate(7.152 36.239)" fill="#00a3ad" />
            <path id="Fill_6" data-name="Fill 6" d="M16.128,10.512h-.143V7.267a7.267,7.267,0,0,0-14.535,0v.349H3.929V7.267a4.788,4.788,0,0,1,9.576,0v3.245H1.855A1.856,1.856,0,0,0,0,12.367V22.852a1.855,1.855,0,0,0,1.855,1.855H16.128a1.856,1.856,0,0,0,1.856-1.855V12.367a1.856,1.856,0,0,0-1.856-1.856" transform="translate(17.781 43.98)" fill="#00a3ad" />
          </g>
        </g>
      </g>
    </svg>
  )
}

const SectionIntroducing = () => {
  return (
    <div className={css.sectionIntroducing}>
      <h2 className={classNames(css.title, css.alignLeft)}>
        <FormattedMessage id='LandingPageGo.SectionIntroducing.title' />
      </h2>
      <div className={classNames(css.description, css.alignLeft)}>
        <FormattedMessage id='LandingPageGo.SectionIntroducing.description' />
      </div>
      <div className={css.conveniences}>
        <div className={css.convenience}>
          <Gps />
          <div className={css.convenienceLabel}>
            <FormattedMessage id='LandingPageGo.SectionIntroducing.gpsLabel' />
          </div>
        </div>
        <div className={css.convenience}>
          <Key />
          <div className={css.convenienceLabel}>
            <FormattedMessage id='LandingPageGo.SectionIntroducing.keyLabel' />
          </div>
        </div>
        <div className={css.convenience}>
          <Control />
          <div className={css.convenienceLabel}>
            <FormattedMessage id='LandingPageGo.SectionIntroducing.controlLabel' />
          </div>
        </div>
        <div className={css.convenience}>
          <Lock />
          <div className={css.convenienceLabel}>
            <FormattedMessage id='LandingPageGo.SectionIntroducing.lockLabel' />
          </div>
        </div>
      </div>
      <NamedLink name='SearchPage' to={{
        search:
          '?address=Singapore&bounds=1.4708809%2C104.04157989999999%2C1.216611%2C103.60650989999999&meta_isDrivelahGo=true',
      }} className={classNames(css.goToSearchButton, css.center)}>
        <FormattedMessage id='LandingPageGo.goToSearchPage' />
      </NamedLink>
    </div>
  )
};

export default SectionIntroducing;