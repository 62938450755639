import React, { useEffect, useRef, useState } from 'react';
import css from './GrabHostYourCarLandingPage.css';
import classNames from 'classnames';
import searchIcon from '../../assets/searcIcon.png';

const datas = [
  {
    question: 'How much can I earn as a host?',
    answer:
      'It depends on the number of days you rent out your car and the price you set. On average, people who rent out once a week will likely earn around $5000 a year.',
  },
  {
    question: 'How much does it cost to list a car?',
    answer:
      'There is no charge for listing your car on Drive lah. It is free for hosts to list & share their car. Drive lah charges the guest for the trip and not the host.',
  },
  {
    question: 'Is it safe to rent out my car to a stranger?',
    answer:
      'Yes. At Drive lah, your peace of mind is of utmost importance to us. We have a robust driver screening process to ensure that your car is driven by a verified driver only. Finally, you are in control as the decision to accept a booking is always yours.',
  },
  {
    question: 'Is it compulsory to rent out if I sign up my car?',
    answer:
      'No. As a car owner it is your decision whether to accept or reject a booking request. You set the price and you decide who rents your car.',
  },
];

const QuestionAnswer = ({ question, answer, onClick, showAnswer, ...rest }) => {
  return (
    <div className={css.questionAnswer} {...rest}>
      <div className={css.question} onClick={onClick}>
        {question}
      </div>
      <div
        className={classNames(css.answer, {
          [css.showAnswer]: showAnswer,
        })}
      >
        {answer}{' '}
      </div>
    </div>
  );
};

const SectionHelp = ({ data }) => {
  const [openQuestion, setOpenQuestion] = useState(null);
  const inputRef = useRef(null);

  const handleClickQuestion = i => {
    if (openQuestion === i) setOpenQuestion(null);
    else setOpenQuestion(i);
  };

  useEffect(() => {
    inputRef.current.addEventListener('keyup', e => {
      if (e.keyCode === 13) {
        window.open(`https://help.drivelah.sg/en/?q=${e.target.value}`);
      }
    });
  }, []);

  return (
    <div className={css.sectionHelp}>
      <div className={css.sectionHelpTitle}>Frequently Asked Questions</div>
      <div className={css.searchHelp}>
        <img src={searchIcon} className={css.searchIcon} />
        <input
          className={css.searchHelpInput}
          placeholder="Eg, Who can be a host?"
          ref={inputRef}
        />
      </div>
      <div className={css.helpQuestions}>
        {(data || datas).map((d, index) => (
          <QuestionAnswer
            {...d}
            key={index}
            index={index}
            showAnswer={openQuestion === index}
            onClick={() => handleClickQuestion(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default SectionHelp;
