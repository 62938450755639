import React, { useEffect } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { PrimaryButton } from '../../components';
import css from './OnfidoVerificationHeader.css';
import Enable from '../../assets/verificationPage/Enable.svg';
import Disable from '../../assets/verificationPage/Disable.svg';
import Card from '../../assets/verificationPage/Card.svg';
import Email from '../../assets/verificationPage/Email.svg';
import CurrentLocation from '../../assets/verificationPage/CurrentLocation.svg';
import PhoneNumber from '../../assets/verificationPage/PhoneNumber.svg';
import IdentityVerification from '../../assets/verificationPage/IdentityVerification.svg';
import Calender from '../../assets/verificationPage/Calender.svg';
import DriverVerification from '../../assets/verificationPage/DriverVerification.svg';
import { getEventsByBucket } from '../../util/gtm/gtmCreateProperties';
import { triggerAnalyticsEvent } from '../../util/amplitudeMapEvents';
import { event_trigger_ids } from '../../util/analyticsConstants';

const OnfidoVerificationHeader = ({
  currentUser,
  handleContinueVerification,
  phoneNumberVerified,
  isEmailVerified,
  userLocation,
  dateOfBirth,
  isHost,
  isVerified,
  isResidencyStatus,
  residencyStatus,
  flexLosDocumentUrl,
  isSubmittedOnfidoNricVerification,
  isSubmittedOnfidoDriverVerification,
  isPendingVerification,
  key
}) => {


  useEffect(() => {
    const fetchAndTriggerEvents = async () => {
      try {
        const response = await getEventsByBucket('guestverify');

        //Send GTM Event of Complete both phone + email steps
        if(phoneNumberVerified && isEmailVerified) {
          // triggerAnalyticsEvent({
          //   event_id: event_trigger_ids.GUEST_VERIFY_ADD_CONTACT,
          //   eventData: response.data,
          //   props: {
          //     guest: currentUser
          //   },
          //   user: currentUser,
          //   userId: currentUser ? currentUser.id.uuid : null
          // });
        }

        if(isResidencyStatus) {
          // triggerAnalyticsEvent({
          //   event_id: event_trigger_ids.GUEST_VERIFY_ADD_ADDRESS,
          //   eventData: response.data,
          //   props: {
          //     guest: currentUser
          //   },
          //   user: currentUser,
          //   userId: currentUser ? currentUser.id.uuid : null
          // });
        }

        if(dateOfBirth) {
          // triggerAnalyticsEvent({
          //   event_id: event_trigger_ids.GUEST_VERIFY_Add_Dob,
          //   eventData: response.data,
          //   props: {
          //     guest: currentUser
          //   },
          //   user: currentUser,
          //   userId: currentUser ? currentUser.id.uuid : null
          // });
        }

        // console.log('Events fetched successfully guestverify:', response.data);
      } catch (error) {
        console.error('Error fetching events or triggering GTM event:', error);
      }
    };


    if (currentUser) {
      fetchAndTriggerEvents();
    }
  }, [currentUser]);

  const guidance = (
    <div className={css.verificationIndication}>
      <p>
        <FormattedMessage id="OnfidoVerificationHeader.guide" />
      </p>
    </div>
  );
  const thankYouMessagePending = (
    <div className={css.verificationIndication}>
      <p>
        <FormattedMessage
          id="AccountSettingDriverVerificationPage.thankYouPageTitle"
          values={{ name: currentUser && `${currentUser.attributes.profile.firstName}` }}
        />
      </p>
      <p>
        <FormattedMessage id="AccountSettingDriverVerificationPage.thankYouPageDescription" />
      </p>
    </div>
  );

  const thankyouMessageVerified = (
    <div className={css.verificationIndication}>
      <p>
        <FormattedMessage id="OnfidoVerificationHeader.thankyouMessageVerified" />
      </p>
    </div>
  );
  return (
    <div>
      {isVerified
        ? thankyouMessageVerified
        : isPendingVerification
        ? thankYouMessagePending
        : guidance}
      <div className={css.stepsContainer} key={key}>
        {!isHost && (
            <div className={css.singleStepContainer}>
              <div className={css.titleIconContainer}>
                <div className={css.stepIconContainer}>
                  <img src={IdentityVerification} alt="Alt text" />
                </div>
                <span className={css.stepTitle}>
                  <FormattedMessage id="OnfidoVerificationHeader.identityVerification" />
                </span>
              </div>

              {isSubmittedOnfidoNricVerification || isVerified ? (
                <img src={Enable} className={css.badgeposition} alt="Alt text" />
              ) : (
                <img src={Disable} className={css.badgeposition} alt="Alt text" />
              )}
            </div>
          )}
        <div className={css.singleStepContainer}>
          <div className={css.titleIconContainer}>
            <div className={css.stepIconContainer}>
              <img src={PhoneNumber} alt="Alt text" />
            </div>

            <span className={css.stepTitle}>
              <FormattedMessage id="OnfidoVerificationHeader.phoneNumber" />
            </span>
          </div>
          {phoneNumberVerified ? (
            <img src={Enable} className={css.badgeposition} alt="Alt text" />
          ) : (
            <img src={Disable} className={css.badgeposition} alt="Alt text" />
          )}
        </div>
        {true && (
          <div className={css.singleStepContainer}>
            <div className={css.titleIconContainer}>
              <div className={css.stepIconContainer}>
                <img src={Email} alt="Alt text" />
              </div>

              <span className={css.stepTitle}>
                <FormattedMessage id="OnfidoVerificationHeader.emailId" />
              </span>
            </div>
            {isEmailVerified ? (
              <img src={Enable} className={css.badgeposition} alt="Alt text" />
            ) : (
              <img src={Disable} className={css.badgeposition} alt="Alt text" />
            )}
          </div>
        )}
        <div className={css.singleStepContainer}>
          <div className={css.titleIconContainer}>
            <div className={css.stepIconContainer}>
              <img src={CurrentLocation} alt="Alt text" />
            </div>

            <span className={css.stepTitle}>
              <FormattedMessage id="OnfidoVerificationHeader.address" />
            </span>
          </div>
          {userLocation ? (
            <img src={Enable} className={css.badgeposition} alt="Alt text" />
          ) : (
            <img src={Disable} className={css.badgeposition} alt="Alt text" />
          )}
        </div>
        {true && (
          <div className={css.singleStepContainer}>
            <div className={css.titleIconContainer}>
              <div className={css.stepIconContainer}>
                <img src={Calender} alt="Alt text" />
              </div>
              <span className={css.stepTitle}>
                <FormattedMessage id="OnfidoVerificationHeader.dob" />
              </span>
            </div>
            {dateOfBirth ? (
              <img src={Enable} className={css.badgeposition} alt="Alt text" />
            ) : (
              <img src={Disable} className={css.badgeposition} alt="Alt text" />
            )}
          </div>
        )}

      {isHost && (
            <div className={css.singleStepContainer}>
              <div className={css.titleIconContainer}>
                <div className={css.stepIconContainer}>
                  <img src={IdentityVerification} alt="Alt text" />
                </div>
                <span className={css.stepTitle}>
                  <FormattedMessage id="OnfidoVerificationHeader.identityVerification" />
                </span>
              </div>

              {isSubmittedOnfidoNricVerification || isVerified ? (
                <img src={Enable} className={css.badgeposition} alt="Alt text" />
              ) : (
                <img src={Disable} className={css.badgeposition} alt="Alt text" />
              )}
            </div>
          )}

        {!isHost && (
          <div className={css.singleStepContainer}>
            <div className={css.titleIconContainer}>
              <div className={css.stepIconContainer}>
                <img src={Card} alt="Alt text" />
              </div>
              <span className={css.stepTitle}>
                <FormattedMessage id="OnfidoVerificationHeader.residentialStatus" />
              </span>
            </div>

            {isResidencyStatus ? (
              <img src={Enable} className={css.badgeposition} alt="Alt text" />
            ) : (
              <img src={Disable} className={css.badgeposition} alt="Alt text" />
            )}
          </div>
        )}
        {!isHost && (
          <div className={css.singleStepContainer}>
            <div className={css.titleIconContainer}>
              <div className={css.stepIconContainer}>
                <img src={DriverVerification} alt="Alt text" />
              </div>
              <span className={css.stepTitle}>
                <FormattedMessage id="OnfidoVerificationHeader.driverVerificationStatus" />
              </span>
            </div>

            {isSubmittedOnfidoDriverVerification ? (
              <img src={Enable} className={css.badgeposition} alt="Alt text" />
            ) : (
              <img src={Disable} className={css.badgeposition} alt="Alt text" />
            )}
          </div>
        )}
        {!isHost && isResidencyStatus && residencyStatus === 'Dependent Pass' && (
          <div className={css.singleStepContainer}>
            <div className={css.titleIconContainer}>
              <div className={css.stepIconContainer}>
                <img src={Card} alt="Alt text" />
              </div>
              <span className={css.stepTitle}>
                <FormattedMessage id="OnfidoVerificationHeader.losDocumentStatus" />
              </span>
            </div>

            {flexLosDocumentUrl ? (
              <img src={Enable} className={css.badgeposition} alt="Alt text" />
            ) : (
              <img src={Disable} className={css.badgeposition} alt="Alt text" />
            )}
          </div>
        )}
        <div className={css.continueVerificationButton}>
          <PrimaryButton type="button" onClick={handleContinueVerification}>
            <FormattedMessage id="OnfidoVerificationHeader.continueButton" />
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default OnfidoVerificationHeader;
