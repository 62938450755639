import classNames from 'classnames';
import { bool, func, shape, string } from 'prop-types';
import React, { Component, createRef } from 'react';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import infoCircle from '../../assets/updatelisting/info-circle-solid.svg';
import { compose } from 'redux';
import {
  Button,
  Explanation,
  FieldCurrencyInput,
  FieldSelectDay,
  FieldToggleSwitch,
  Form,
} from '../../components';
import config from '../../config';
import { formatMoney } from '../../util/currency';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { types as sdkTypes } from '../../util/sdkLoader';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import css from './EditListingPricingForm.css';
import { fetchPricelist } from '../../util/carPriceHelper';
import { get } from 'lodash';

const { Money } = sdkTypes;

class EditListingPricingFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      suggestedPriceData: {},
      minPrice: 0,
      price: 0,
      peakPrice: 0,
      peakMinPrice: 0,
      minRecommendedPrice: 0,
      maxRecommendedPrice: 0,
      recommendedPrice: 0,
      category: '',
    };
    this.currentValues = createRef();
    this.submitedValues = createRef();
    this.formRef = createRef();
  }

  getPricelist = (brandName, modelName) => {
    let normalized_brand = brandName
      .split(' ')
      .join('_')
      .toLowerCase();
    fetchPricelist(normalized_brand, modelName)
      .then(response => {
        return response.json();
      })
      .then(jsonData => {
        const getAgeOfCarData = get(this.props.listing, 'attributes.publicData.ageOfCar', 0);
        const actualPrice =
          !isNaN(Number(getAgeOfCarData)) && Number(getAgeOfCarData) > 6
            ? jsonData['price-old']
            : jsonData.price;

        const minPrice = jsonData.category ? actualPrice - jsonData.variation / 2 : 0;
        const maxPrice = actualPrice + jsonData.variation / 2;
        const peakPrice = actualPrice * 1.2;
        const peakMinPrice = jsonData.category ? peakPrice - jsonData.variation / 2 : 0;
        const peakMinPriceValue = jsonData.category ? peakPrice - jsonData.variation / 2 : 0;
        const peakMaxPriceValue = jsonData.category ? peakPrice + jsonData.variation / 2 : 0;
        this.setState({
          suggestedPriceData: jsonData,
          price: actualPrice,
          minPrice: minPrice,
          peakPrice: peakPrice,
          peakMinPrice: peakMinPrice,
          minRecommendedPrice: minPrice,
          maxRecommendedPrice: maxPrice,
          recommendedPrice: actualPrice,
          recommendedPeakPrice: peakPrice,
          minRecommendedPeakPrice: peakMinPriceValue,
          maxRecommendedPeakPrice: peakMaxPriceValue,
          category: jsonData.category || '',
        });
        const isPeakPriceAvailable = this.props.initialValues.peakPrice;
        if (!isPeakPriceAvailable && this.formRef.current) {
          const defaultPickPrice = peakMinPrice || peakPrice;
          this.formRef.current.change(
            'peakPrice',
            new Money(defaultPickPrice * 100, config.currency)
          );
        }
      });
  };
  componentDidMount = () => {
    if (this.props.initialValues.modelName && this.props.initialValues.brandName) {
      this.getPricelist(this.props.initialValues.brandName, this.props.initialValues.modelName);
    }
  };
  render() {
    return (
      <FinalForm
        initialValues={{ ...this.props.initialValues, ...this.currentValues.current }}
        {...this.props}
        render={formRenderProps => {
          const {
            className,
            disabled,
            ready,
            handleSubmit,
            intl,
            invalid,
            pristine,
            saveActionMsg,
            updated,
            updateInProgress,
            fetchErrors,
            form,
            values,
            submitButtonId,
          } = formRenderProps;

          const showRentalPriceFields = values.longTermRental;
          const showPickFields = values.isPeakPriceEnabled;
          this.formRef.current = form;

          const unitType = config.bookingUnitType;
          const isNightly = unitType === LINE_ITEM_NIGHT;
          const isDaily = unitType === LINE_ITEM_DAY;

          // Listing Regular Price (Daily) field
          const pricePlaceholderMessage = intl.formatMessage({
            id: 'EditListingPricingForm.priceInputRegularPlaceholder',
          });
          const translationKey = isNightly
            ? 'EditListingPricingForm.pricePerNight'
            : isDaily
            ? 'EditListingPricingForm.pricePerDay'
            : // : 'EditListingPricingForm.pricePerUnit';
              'EditListingPricingForm.pricePerUnitRegularText';
          const pricePerUnitMessage = intl.formatMessage({
            id: translationKey,
          });
          let valueHighLight = '';
          if (this.state.minPrice) {
            valueHighLight = (
              <strong>
                &nbsp;${this.state.minRecommendedPrice}-${this.state.maxRecommendedPrice}&nbsp;
              </strong>
            );
          } else {
            valueHighLight = <strong>&nbsp;${this.state.recommendedPrice}&nbsp;</strong>;
          }
          const regularPriceInputInformation = intl.formatMessage(
            {
              id: 'EditListingPricingForm.pricePerUnitInfoText',
            },
            { price: valueHighLight }
          );

          // Listing Peak Price (Daily) field
          const peakPriceLabel = intl.formatMessage({
            id: 'EditListingPricingForm.peakPriceLabelText',
          });
          let peakPriveValueHighLight = '';
          if (this.state.peakMinPrice) {
            peakPriveValueHighLight = (
              <strong>
                &nbsp;${parseFloat(this.state.minRecommendedPeakPrice).toFixed(2)}-$
                {parseFloat(this.state.maxRecommendedPeakPrice).toFixed(2)}
                &nbsp;
              </strong>
            );
          } else {
            peakPriveValueHighLight = (
              <strong>&nbsp;${this.state.recommendedPeakPrice}&nbsp;</strong>
            );
          }
          const peakPriceInputInformation = intl.formatMessage(
            {
              id: 'EditListingPricingForm.pealPriceInputInfo',
            },
            { price: peakPriveValueHighLight }
          );

          const enablePeakPrice = (
            <React.Fragment>
              {intl.formatMessage({
                id: 'EditListingPricingForm.enablePeakPrice',
              })}
            </React.Fragment>
          );

          // Listing Peak Price Days field
          const regularDaysLabel = (
            <React.Fragment>
              {intl.formatMessage(
                {
                  id: 'EditListingPricingForm.peakPriceDaysSelection',
                },
                {
                  limit: '(max 2)',
                }
              )}
            </React.Fragment>
          );

          // Listing Set peak price public holidays field
          const setPeakPriceHolidayLabel = (
            <React.Fragment>
              {intl.formatMessage({
                id: 'EditListingPricingForm.setPeakPriceHolidayLabel',
              })}
            </React.Fragment>
          );
          const setPeakPriceHolidayExplanationInfo = (
            <React.Fragment>
              {intl.formatMessage({
                id: 'EditListingPricingForm.setPeakPriceHolidayExplanationInfo',
              })}
            </React.Fragment>
          );

          // Listing Long Term Rental field
          const longTernRentalLabel = (
            <React.Fragment>
              {intl.formatMessage({
                id: 'EditListingPricingForm.longTernRentalLabel',
              })}
            </React.Fragment>
          );
          const longTernRentalLabelInfo = (
            <React.Fragment>
              {intl.formatMessage({
                id: 'EditListingPricingForm.longTernRentalLabelInfo',
              })}
            </React.Fragment>
          );
          const longTernRentalExplanationInfo = (
            <React.Fragment>
              {intl.formatMessage({
                id: 'EditListingPricingForm.longTernRentalExplanationInfo',
              })}
            </React.Fragment>
          );

          // Listing Long term price field
          const longTermPriceLabel = (
            <React.Fragment>
              {intl.formatMessage({
                id: 'EditListingPricingForm.longTermPriceLabel',
              })}
            </React.Fragment>
          );
          const longTermPricelLabelInfo = (
            <React.Fragment>
              {intl.formatMessage({
                id: 'EditListingPricingForm.longTermPricelLabelInfo',
              })}
            </React.Fragment>
          );

          // Price Validation
          const priceRequired = validators.required(
            intl.formatMessage({
              id: 'EditListingPricingForm.priceRequired',
            })
          );
          const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
          const minPriceRequired = validators.moneySubUnitAmountAtLeast(
            intl.formatMessage(
              {
                id: 'EditListingPricingForm.priceTooLow',
              },
              {
                minPrice: formatMoney(intl, minPrice),
              }
            ),
            config.listingMinimumPriceSubUnits
          );
          let priceValidators = config.listingMinimumPriceSubUnits
            ? validators.composeValidators(priceRequired, minPriceRequired)
            : priceRequired;
          let pricePeakValidators = config.listingMinimumPriceSubUnits
            ? validators.composeValidators(priceRequired, minPriceRequired)
            : priceRequired;

          let minMaxRecommendedPriceRequired;
          if (this.state.recommendedPrice) {
            const minRecPrice = new Money(
              0.85 * this.state.recommendedPrice * config.listingMinimumPriceSubUnits,
              config.currency
            );
            const maxRecPrice = new Money(
              1.5 * this.state.recommendedPrice * config.listingMinimumPriceSubUnits,
              config.currency
            );
            minMaxRecommendedPriceRequired = validators.moneySubUnitAmountIsInBetween(
              {
                minMessage: intl.formatMessage(
                  {
                    id: 'EditListingPricingForm.minRecommendedPriceTooLow',
                  },
                  {
                    minRecommendedPriceValue: formatMoney(intl, minRecPrice),
                  }
                ),
                maxMessage: intl.formatMessage(
                  {
                    id: 'EditListingPricingForm.maxRecommendedPriceTooHigh',
                  },
                  {
                    minRecommendedPriceValue: formatMoney(intl, maxRecPrice),
                  }
                ),
              },
              0.85 * this.state.recommendedPrice * config.listingMinimumPriceSubUnits,
              1.5 * this.state.recommendedPrice * config.listingMinimumPriceSubUnits
            );
            priceValidators = validators.composeValidators(
              priceRequired,
              minPriceRequired,
              minMaxRecommendedPriceRequired
            );
          }

          let minMaxRecommendedPeakPriceRequired;
          if (this.state.peakPrice) {
            const minRecPPrice = new Money(
              0.85 * this.state.peakPrice * config.listingMinimumPriceSubUnits,
              config.currency
            );
            const maxRecPPrice = new Money(
              1.5 * this.state.peakPrice * config.listingMinimumPriceSubUnits,
              config.currency
            );
            minMaxRecommendedPeakPriceRequired = validators.moneySubUnitAmountIsInBetween(
              {
                minMessage: intl.formatMessage(
                  {
                    id: 'EditListingPricingForm.minRecommendedPriceTooLow',
                  },
                  {
                    minRecommendedPriceValue: formatMoney(intl, minRecPPrice),
                  }
                ),
                maxMessage: intl.formatMessage(
                  {
                    id: 'EditListingPricingForm.maxRecommendedPriceTooHigh',
                  },
                  {
                    minRecommendedPriceValue: formatMoney(intl, maxRecPPrice),
                  }
                ),
              },
              0.85 * this.state.peakPrice * config.listingMinimumPriceSubUnits,
              1.5 * this.state.peakPrice * config.listingMinimumPriceSubUnits
            );
            pricePeakValidators = validators.composeValidators(
              priceRequired,
              minPriceRequired,
              minMaxRecommendedPeakPriceRequired
            );
          }

          //
          const classes = classNames(css.root, className);
          const submitReady = (updated && pristine) || ready;
          const submitInProgress = updateInProgress;
          const submitDisabled = invalid || disabled || submitInProgress;
          const { updateListingError, showListingsError } = fetchErrors || {};

          // const peakPriceLabel = intl.formatMessage({
          //   id: 'EditListingPricingForm.peakPriceLabel',
          // });

          // New design labels

          // const regularDaysLabel = (
          //   <React.Fragment>
          //     {intl.formatMessage({
          //       id: 'EditListingPricingForm.regularDaysLabel',
          //     })}
          //     <Explanation
          //       text={'Regular days are your default rental days for your regular rental price'}
          //     />
          //   </React.Fragment>
          // );

          // New design labels

          const demandType = 'Good';
          const meterDisplay =
            demandType === 'Bad'
              ? 'rotate(30deg)'
              : demandType === 'Average'
              ? 'rotate(80deg)'
              : demandType === 'Good'
              ? 'rotate(165deg)'
              : 'rotate(0)';

          const peakDaysLabel = (
            <React.Fragment>
              {intl.formatMessage({
                id: 'EditListingPricingForm.peakDaysLabel',
              })}
              <Explanation text="You can choose to price particular days of the week at a different rental price." />
            </React.Fragment>
          );

          const weekLabel = intl.formatMessage({
            id: 'EditListingPricingForm.weekLabel',
          });

          const monthLabel = intl.formatMessage({
            id: 'EditListingPricingForm.monthLabel',
          });

          const weekPlaceholder = intl.formatMessage({
            id: 'EditListingPricingForm.weekPlaceholder',
          });

          const monthPlaceholder = intl.formatMessage({
            id: 'EditListingPricingForm.monthPlaceholder',
          });

          const discountMinValueMessage = intl.formatMessage({
            id: 'EditListingPricingForm.discountMinValueMessage',
          });

          const discountMaxValueMessage = intl.formatMessage({
            id: 'EditListingPricingForm.discountMaxValueMessage',
          });

          const peakDaysAtMostSelectionMessage = intl.formatMessage({
            id: 'EditListingPricingForm.peakDaysAtMostSelection',
          });

          const discountValidate = (field, values) => value => {
            let message = undefined;
            const { price, peakPrice } = values;
            if (values.price) {
              let totalPrice = 0;
              if (field === 'week') {
                totalPrice = (price.amount * 7 * (100 - parseInt(value))) / 100;
              } else if (field === 'month') {
                totalPrice = (price.amount * 30 * (100 - parseInt(value))) / 100;
              }
              if (totalPrice < 1)
                message = intl.formatMessage({ id: 'EditListingPricingForm.invalidDiscountValue' });
            }
            if (values.peakPrice) {
              let totalPrice = 0;
              if (field === 'week') {
                totalPrice = (peakPrice.amount * 7 * (100 - parseInt(value))) / 100;
              } else if (field === 'month') {
                totalPrice = (peakPrice.amount * 30 * (100 - parseInt(value))) / 100;
              }
              if (totalPrice < 1)
                message = intl.formatMessage({ id: 'EditListingPricingForm.invalidDiscountValue' });
            }

            return message;
          };

          return (
            <Form
              onSubmit={e => {
                this.currentValues.current = values;
                this.submitedValues.current = values;
                handleSubmit(e);
              }}
              className={classes}
            >
              <FormSpy
                subscription={{ values: true, active: true }}
                onChange={input => {
                  if (input.active === 'isPeakPriceEnabled') {
                    form.batch(() => {
                      form.change('isPublicHolidayPeakDays', false);
                    });
                  }
                }}
              />
              {updateListingError ? (
                <p className={css.error}>
                  <FormattedMessage id="EditListingPricingForm.updateFailed" />
                </p>
              ) : null}

              {showListingsError ? (
                <p className={css.error}>
                  <FormattedMessage id="EditListingPricingForm.showListingFailed" />
                </p>
              ) : null}
              <div className={css.displayInlineContainer}>
                <div className={classNames(css.column, css.column12, css.inputWrapper)}>
                  <div className={css.customInputLabel}>{pricePerUnitMessage}</div>
                  <div className={css.inputWithPrefix}>
                    <FieldCurrencyInput
                      id="price"
                      name="price"
                      prefix={<span className={css.inputPrefix}>$</span>}
                      className={classNames(css.priceInput, css.field, css.newInput)}
                      // label={pricePerUnitMessage}
                      placeholder={pricePlaceholderMessage}
                      currencyConfig={config.currencyConfig}
                      validate={this.state.category ? priceValidators : false}
                    />
                  </div>
                  <div className={css.infoText}>
                    <span className={css.infoIcon}>
                      <img src={infoCircle} alt="" />
                    </span>
                    <p>{regularPriceInputInformation}</p>
                  </div>
                </div>
                <div className={classNames(css.column, css.column12)}>
                  <div className={classNames(css.customToggleInput, css.field)}>
                    <div className={css.toggleLabels}>
                      <label htmlFor="isPeakPriceEnabled">{enablePeakPrice}</label>
                      <Explanation
                        className={css.customClass}
                        text={setPeakPriceHolidayExplanationInfo}
                      />
                    </div>
                    <div className={css.toggleInput}>
                      <FieldToggleSwitch name="isPeakPriceEnabled" id="isPeakPriceEnabled" />
                    </div>
                  </div>
                </div>
                {showPickFields && (
                  <>
                    <div className={classNames(css.column, css.column12)}>
                      <div className={css.customInputLabel}>{peakPriceLabel}</div>
                      <div className={css.inputWithPrefix}>
                        <FieldCurrencyInput
                          id="peakPrice"
                          name="peakPrice"
                          className={classNames(css.priceInput, css.field, css.newInput)}
                          prefix={<span className={css.inputPrefix}>$</span>}
                          // label={peakPriceLabel}
                          placeholder={pricePlaceholderMessage}
                          currencyConfig={config.currencyConfig}
                          validate={(...priceData) => {
                            const { values } = form.getState();
                            const { peakDays = [], isPublicHolidayPeakDays = false } = values;
                            if ((peakDays && peakDays.length > 0) || isPublicHolidayPeakDays) {
                              // Validate if category is not exists, we don't want to apply any lowe/upper price boundary validator when there is no category.
                              if (!this.state.category) return;
                              return pricePeakValidators(...priceData);
                            } else if (priceData[0]) {
                              return minPriceRequired(priceData[0]);
                            } else {
                              return undefined;
                            }
                          }}
                        />
                      </div>
                      <div className={css.infoText}>
                        <span className={css.infoIcon}>
                          <img src={infoCircle} alt="" />
                        </span>
                        <p>{peakPriceInputInformation}</p>
                      </div>
                    </div>
                    <div className={classNames(css.column, css.column12)}>
                      <FieldSelectDay
                        id="regularDays"
                        className={css.field}
                        name="regularDays"
                        label={regularDaysLabel}
                        initialsOnly={true}
                        peakDays={values.peakDays}
                        validate={(value, currentState) => {
                          const { peakDays = [] } = currentState || {};
                          if (peakDays && peakDays.length > 2) {
                            return peakDaysAtMostSelectionMessage;
                          }
                          return false;
                        }}
                        customOnChange={(key, isSelected) => {
                          const { values } = form.getState();
                          const { peakDays = [] } = values;
                          if (peakDays !== null) {
                            if (!isSelected) {
                              setTimeout(() =>
                                form.change(
                                  'peakDays',
                                  peakDays.filter(day => day !== key)
                                )
                              );
                            } else {
                              peakDays.push(key);
                              setTimeout(() => form.change('peakDays', peakDays));
                            }
                          } else {
                            let peakDaystemp = [key];
                            setTimeout(() => form.change('peakDays', peakDaystemp));
                          }
                        }}
                      />
                    </div>
                    <div className={classNames(css.column, css.column12)}>
                      <div className={classNames(css.customToggleInput, css.field, css.noBorder)}>
                        <div className={css.toggleLabels}>
                          <label htmlFor="isPublicHolidayPeakDays">
                            {setPeakPriceHolidayLabel}
                          </label>
                        </div>
                        <div className={css.toggleInput}>
                          <FieldToggleSwitch
                            name="isPublicHolidayPeakDays"
                            id="isPublicHolidayPeakDays"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {/*Disable long term rental*/}
                {/*<div className={classNames(css.column, css.column12)}>*/}
                {/*  <div className={classNames(css.customToggleInput, css.field)}>*/}
                {/*    <div className={css.toggleLabels}>*/}
                {/*      <label htmlFor="longTermRental">{longTernRentalLabel}</label>*/}
                {/*      <Explanation*/}
                {/*        className={css.customClass}*/}
                {/*        text={longTernRentalExplanationInfo}*/}
                {/*      />*/}
                {/*    </div>*/}
                {/*    <div className={css.toggleInput}>*/}
                {/*      <FieldToggleSwitch name="longTermRental" id="longTermRental" />*/}
                {/*    </div>*/}
                {/*    <div className={css.infoText}>*/}
                {/*      <p>{longTernRentalLabelInfo}</p>*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</div>*/}
                {/*{showRentalPriceFields && (*/}
                {/*  <div className={classNames(css.column, css.column12)}>*/}
                {/*    <div className={css.customInputLabel}>{longTermPriceLabel}</div>*/}
                {/*    <div className={css.inputWithPrefix}>*/}
                {/*      <FieldCurrencyInput*/}
                {/*        id="longTermPrice"*/}
                {/*        name="longTermPrice"*/}
                {/*        className={classNames(css.priceInput, css.field, css.newInput)}*/}
                {/*        prefix={<span className={css.inputPrefix}>$</span>}*/}
                {/*        // label={peakPriceLabel}*/}
                {/*        placeholder={pricePlaceholderMessage}*/}
                {/*        currencyConfig={config.currencyConfig}*/}
                {/*        validate={(...priceData) => {*/}
                {/*          const { values } = form.getState();*/}
                {/*          const { peakDays = [], isPublicHolidayPeakDays = false } = values;*/}
                {/*          return peakDays.length > 0 || isPublicHolidayPeakDays*/}
                {/*            ? priceValidators(...priceData)*/}
                {/*            : priceData[0]*/}
                {/*            ? minPriceRequired(priceData[0])*/}
                {/*            : undefined;*/}
                {/*        }}*/}
                {/*      />*/}
                {/*    </div>*/}
                {/*    <div className={css.infoText}>*/}
                {/*      <span className={css.infoIcon}>*/}
                {/*        <img src={infoCircle} alt="" />*/}
                {/*      </span>*/}
                {/*      <p>{longTermPricelLabelInfo}</p>*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*)}*/}
              </div>

              {/* <FieldSelectDay
                id="peakDays"
                name="peakDays"
                className={css.field}
                label={peakDaysLabel}
                initialsOnly={true}
                customOnChange={(key, isSelected) => {
                  if (!isSelected) {
                    const { values } = form.getState();
                    const { regularDays = [] } = values;
                    setTimeout(() =>
                      form.change(
                        'regularDays',
                        regularDays.filter(day => day !== key)
                      )
                    );
                  } else {
                    const { values } = form.getState();
                    const { regularDays = [] } = values;
                    regularDays.push(key);
                    setTimeout(() => form.change('regularDays', regularDays));
                  }
                }}
              /> */}

              {/* <label htmlFor="discount">
                <FormattedMessage id="EditListingPricingForm.discountTitle" />
              </label> */}
              <div id="discount" className={css.field}>
                {/* <FieldTextInput
                  id="week"
                  name="week"
                  label={weekLabel}
                  placeholder={weekPlaceholder}
                  type="number"
                  step={1}
                  min={0}
                  max={100}
                  parse={v => parseInt(v)}
                  inline
                  className={css.field}
                  validate={validators.composeValidators(
                    discountValidate('week', values),
                    validators.minValue(discountMinValueMessage, 0),
                    validators.maxValue(discountMaxValueMessage, 100)
                  )}
                /> */}
                {/* <FieldTextInput
                  id="month"
                  name="month"
                  label={monthLabel}
                  placeholder={monthPlaceholder}
                  type="number"
                  step={1}
                  min={0}
                  max={100}
                  inline
                  parse={v => parseInt(v)}
                  validate={validators.composeValidators(
                    discountValidate('month'),
                    validators.minValue(discountMinValueMessage, 0),
                    validators.maxValue(discountMaxValueMessage, 100)
                  )}
                /> */}
              </div>
              {/*<FormSpy
                subscription={{ values: true }}
                render={({ values }) => {
                  if (values.longTermRental) {
                    return (
                      <FieldCurrencyInput
                        id="longTermPrice"
                        name="longTermPrice"
                        className={classNames(css.priceInput, css.field)}
                        label={
                          <div>
                            Monthly price in Singapore dollars{' '}
                            <Explanation text="We recommend the Monthly price to be at least 10-15% discounted as compared to Daily price" />
                          </div>
                        }
                        placeholder={pricePlaceholderMessage}
                        currencyConfig={config.currencyConfig}
                        validate={priceValidators}
                      />
                    );
                  }
                  return null;
                }}
              />*/}

              {/* <div className={css.moreDesc}>
                <FormattedMessage id="EditListingPricingForm.moreDescription" />
              </div> */}
              <div className={css.stickyButtons}>
                <div className={css.stickButtonsContainer}>
                  <div className={css.stickButtonsDescription}>
                    {/* <span className={css.descIcon}>
                      <img src={meterIcon} alt="" />
                      <img
                        src={meterNeedle}
                        alt=""
                        style={{ transform: meterDisplay }}
                        className={css.needle}
                      />
                    </span>
                    <p>
                      <strong>Good Demand:</strong> Based on your settings you are on your way to
                      attract good level of demand.
                    </p> */}
                  </div>
                  <div className={css.stickButton}>
                    <Button
                      className={css.submitButton}
                      type="submit"
                      inProgress={submitInProgress}
                      disabled={submitDisabled}
                      ready={submitReady}
                      id={submitButtonId}
                    >
                      {/* {saveActionMsg} */}
                      Next
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

EditListingPricingFormComponent.defaultProps = { fetchErrors: null };

EditListingPricingFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  submitButtonId: string,
};

export default compose(injectIntl)(EditListingPricingFormComponent);
