import React, { useEffect } from 'react';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
} from '../../components';
import css from './Career.css';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';

const Career = ({ intl }) => {
  useEffect(() => {
    if (typeof window !== 'undefined' && window) {
      window.location.replace(
        'https://drivelah.recruitee.com/'
      );
    }
  }, []);

  const schemaTitle = intl.formatMessage({ id: 'CareersPage.schemaTitle' });
  const schemaDescription = intl.formatMessage({ id: 'CareersPage.schemaDescription' });

  return (
    <StaticPage
      title={schemaTitle}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'Career',
        description: schemaDescription,
        name: schemaTitle,
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.staticPageWrapper}>Redirecting...</LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};
export default compose(injectIntl)(Career);
