import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory, useLocation, useParams, withRouter } from 'react-router-dom';
import { withViewport } from '../../util/contextHelpers';
import {
  isScrollingDisabled,
  manageDisableScrolling,
  manageTurnoffPromoteBar,
} from '../../ducks/UI.duck';
import { injectIntl, intlShape } from '../../util/reactIntl';
import config from '../../config';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
  TrustBoxSlider,
} from '../../components';
import { TopbarContainer } from '../../containers';
import { pushGTMBrowseEvent } from '../../util/gtm/gtmHelpers';
import { EVENT_BROWSE_HOMEPAGE } from '../../util/gtm/gtmConstants';

import css from './SeoLandingPage.css';
import SectionRealBenefits from './SectionRealBenefits';
import SectionHelp from './SectionHelp';
import SeoLandingWhyRentCar from './SeoLandingWhyRentCar';
import SeoLandingRentVideo from './SeoLandingRentVideo';
import SeoLandingCarRentalCategories from './SeoLandingCarRentalCategories';
import { TOP_CATEGORIES } from './seoCategoriesArrays';
import SeoLandingBannerRentalCategories from './SeoLandingBannerRentalCategories';
import DownloadAppSection from '../AntiCompetitionLandingPage/DownloadAppSection';
import { pathByRouteName } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import imageSource from '../../util/imageSource';

const MAX_MOBILE_SCREEN_WIDTH = 768;
export const SeoSegmentRentalLandingPageComponent = props => {
  const {
    intl,
    scrollingDisabled,
    onManageDisableScrolling,
    viewport,
    currentPage = 'SeoSegmentRentalLandingPage',
  } = props;
  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: `Rent your neighbour's car | Drive lah` }, { siteTitle });
  const schemaDescription = intl.formatMessage({
    id:
      'With Drive lah, you can rent from a huge variety of nearby cars at great value. Fully insured+.',
  });
  const schemaImage = `${config.canonicalRootURL}${imageSource.seoLandingPage.landingBannerImage}`;
  const history = useHistory();
  const location = useLocation();
  let { cat, subcat } = useParams();
  let [pageCategory, setPageCategory] = useState(null);
  let [brandCategory, setBrandCategory] = useState(null);
  let [selectedValues, setSelectedValues] = useState(null);
  let [onLocationChange, setOnLocationChange] = useState(null);
  let [searchFilters, setSearchFilters] = useState(null);
  const SEO_LANDING_CONSTANTS = [
    'top-car-rental-brands',
    'singapore-locations',
    'car-rental-by-segment',
  ];
  const onPushGTMEvent = (event, buttonId) => {
    // pushGTMBrowseEvent({
    //   props,
    //   event,
    //   buttonId,
    // });
  };
  useEffect(() => {
    onPushGTMEvent(EVENT_BROWSE_HOMEPAGE);
  }, [pageCategory, onLocationChange]);

  useEffect(() => {
    let removeLastChar = location.pathname;
    if (removeLastChar.charAt(removeLastChar.length - 1) === '/') {
      removeLastChar = removeLastChar.substring(0, removeLastChar.length - 1);
    }
    const getUrl = removeLastChar.substring(removeLastChar.lastIndexOf('/') + 1);
    const isValidPage =
      SEO_LANDING_CONSTANTS &&
      SEO_LANDING_CONSTANTS.length > 0 &&
      SEO_LANDING_CONSTANTS.includes(getUrl);
    if (!isValidPage) {
      const path = pathByRouteName('SeoCarRentalLandingPage', routeConfiguration());
      history.push(path);
    }
  }, []);

  let isMobileLayout;
  try {
    if (viewport) {
      isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
    }
  } catch (error) {

  }

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: imageSource.seoLandingPage.landingBannerImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${imageSource.seoLandingPage.landingBannerImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer notApplySearch={true} currentPage={currentPage} />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.contentWrapper}>
          <div style={{ zIndex: 2 }}>
            <SeoLandingBannerRentalCategories
              isMobileLayout={isMobileLayout}
              brandCategory={brandCategory}
              subCategory={subcat}
              searchFilters={searchFilters}
              pageCategory={pageCategory}
              onManageDisableScrolling={onManageDisableScrolling}
              selectedValues={selectedValues}
            />
            {!isMobileLayout && (
              <SectionRealBenefits
                subCategory={subcat}
                onManageDisableScrolling={onManageDisableScrolling}
                onPushGTMSeeVideoEvent={onPushGTMEvent}
              />
            )}
            <div className={css.rentFromOtherBrands}>
              <div className={css.rentFromOtherBrandsContainer}>
                <div className={css.rentFromOtherBrandsTitle}>
                  <span>Car rental in Singapore</span>
                  <p>
                    Looking to rent a car in Singapore? There are hundreds of cars belonging to real
                    people on Drive lah. Whether you're looking for a large car for a weekend away
                    with friends, or a something smaller for a trip to the supermarket, you can be
                    sure to find the right car for you. You can choose from over 2,000 cars around
                    Singapore. With Drive lah, you can rent from a huge variety of nearby cars at
                    great value.
                  </p>
                </div>
              </div>
            </div>
            <SeoLandingCarRentalCategories
              title={'Cars for rent based on segment'}
              text={
                'No matter the occasion we got the car for you. From taking a compact car for a quick shopping run to a SUV for a family outing, we got it all and that too right in your neighbourhood.'
              }
              selectedValues={selectedValues}
              brandCategory={brandCategory}
              setSelectedValues={val => setSelectedValues(val)}
              categoryName={TOP_CATEGORIES}
            />
            <SeoLandingWhyRentCar />
            <SeoLandingRentVideo
              onManageDisableScrolling={onManageDisableScrolling}
              onPushGTMSeeVideoEvent={onPushGTMEvent}
            />
            <TrustBoxSlider
              sectionTitle="Our users loves us"
              sectionSubTitle="People love renting and sharing cars on Drive lah"
            />
            <DownloadAppSection />
            <SectionHelp />
            {/* <SeoLandingRentalCategories /> */}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool, object, number, array, shape, func } = PropTypes;

SeoSegmentRentalLandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  mapListings: array,
  onSearchMapListings: func.isRequired,
  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const { isAuthenticated } = state.Auth;
  return {
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
    turnoffPromoteBar: state.UI.turnoffPromoteBar,
    isAuthenticated,
  };
};

const mapDispatchToProps = dispatch => ({
  onTurnoffPromoteBar: flagVal => dispatch(manageTurnoffPromoteBar(flagVal)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SeoSegmentRentalLandingPage = compose(
  withViewport,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(SeoSegmentRentalLandingPageComponent);
export default SeoSegmentRentalLandingPage;
