import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { compose } from 'redux';
import config from '../../config';
import { StaticPage, TopbarContainer } from '../../containers';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { injectIntl } from '../../util/reactIntl';
import GroupOfGoogleContacts from './GroupOfGoogleContacts';

import DrivelahCreditsIconWhite from './drivelah-credits-icon-white.png';
import FacebookIcon from './FB.png';
import FBMessengerIcon from './FBMessenger.png';
import ShareYourLinkIcon from './share-your-link.png';
import TellFriendsIcon from './tell-friends.png';
import TwitterIcon from './Twitter.png';
import WhatsAppIcon from './WhatsApp.png';

import { ensureCurrentUser } from '../../util/data';

import {
  Button,
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Modal,
} from '../../components';

import { connect } from 'react-redux';
import { fetchCurrentUser } from '../../ducks/user.duck';

import { get } from 'lodash';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import drivelahCreditsIcon from './drivelah-credits-icon.png';
import image from './refer-a-host-page-desktop.png';
import css from './ReferAHostPage.css';
import { sendInvitation } from './ReferAHostPage.duck';
import { event_trigger_ids } from '../../util/analyticsConstants';
import { getEventsByBucket } from '../../util/gtm/gtmCreateProperties';
import { triggerAnalyticsEvent } from '../../util/amplitudeMapEvents';

const VIEW_PORT_MEDIUM = 768;

const ReferAHostPage = props => {
  const {
    intl,
    currentUser,
    scrollingDisabled,
    onSendInvitation,
    sendInvitationInProgress,
    sendInvitationError,
    onManageDisableScrolling,
  } = props;

  const [copyMsg, setCopyMsg] = useState('Copy');
  const [copyStatus, setCopyStatus] = useState(true);
  const [showGmailContactsModal, setShowGmailContactsModal] = useState(false);
  const [googleContacts, setGoogleContacts] = useState([]);
  const submittedValues = useRef({});
  const history = useHistory();
  const location = useLocation();
  const loadFbSdk = () => {
    if (window) {
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: config.facebookAppId,
          cookie: true, // enable cookies to allow the server to access
          // the session
          autoLogAppEvents: true,
          xfbml: true, // parse social plugins on this page
          version: 'v2.7',
        });
      };

      // Load the SDK asynchronously
      (function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s);
        js.id = id;
        js.src = '//connect.facebook.net/en_US/sdk.js';
        fjs.parentNode.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');
    }
  };

  const onShareToFacebookTimeline = (message, url) => {
    if (!window) return;

    window.FB.ui(
      {
        method: 'share',
        quote: message,
        href: url,
      },
      function (response) {

      }
    );
  };

  const onShareToMessenger = link => {
    if (!window) return;
    var desc = 'your caption here';
    var title = 'your title here';

    return isMobileView
      ? window.open(`fb-messenger://share/?link=${link}&app_id=${config.facebookAppId}`)
      : window.FB.ui({
        method: 'send',
        link: `${link}`,
        description: 'Sharing',
        action_properties: JSON.stringify({
          object: {
            'og:title': title,
            'og:description': desc,
          },
        }),
      });
  };

  useEffect(() => {
    if(typeof window !== 'undefined'){
      loadFbSdk();
    }
  }, []);

  const loadingTitle = intl.formatMessage({ id: 'ReferAHostPage.loadingReferralCodeTitle' });

  const topbar = <TopbarContainer />;

  // if (!currentUser) {
  //   return (
  //     <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
  //       <LayoutSingleColumn className={css.pageRoot}>
  //         <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
  //         <LayoutWrapperMain>
  //           <p className={css.loadingText}>
  //             <FormattedMessage id="ReferAHostPage.loadingReferralCode" />
  //           </p>
  //         </LayoutWrapperMain>
  //         <LayoutWrapperFooter>
  //           <Footer />
  //         </LayoutWrapperFooter>
  //       </LayoutSingleColumn>
  //     </Page>
  //   );
  // }

  const ensuredUser = ensureCurrentUser(currentUser);

  // prettier-ignore
  const isMobileView = typeof window !== 'undefined' ? window.innerWidth <= VIEW_PORT_MEDIUM : false;
  const mobileLineBreak = isMobileView ? <br /> : null;
  const desktopLineBreak = !isMobileView ? <br /> : null;
  const getReferralCode = get(ensuredUser, 'attributes.profile.metadata.referralCode', '');
  const userId = get(ensuredUser, 'id', '');
  const signupReferralLink = `${config.canonicalRootURL}/signup?code=${getReferralCode}`;

  const showCopiedText = seconds => {
    setTimeout(() => setCopyMsg('Copy'), seconds * 1000);
  };

  const copyNowEventHandler = async () => {
    try {
      const response = await getEventsByBucket('browsing');
        triggerAnalyticsEvent({
          event_id: event_trigger_ids.REFER_CLICK_INVITE_NOW,
          eventData: response.data,
          props: {
            ui: {  
              button: 'Invite now',
              page:'ReferScreen'
            }
          },
          userId: currentUser ? currentUser.id.uuid : null,
        });
    } catch (error) {
      console.log("Analytics error: ", error);
    }
    if (window) {
      if (!navigator.clipboard) {
        setCopyStatus(false);
        setCopyMsg('Copy');
        return;
      }
      navigator.clipboard.writeText(sharingMsg).then(
        () => {
          setCopyStatus(true);
          setCopyMsg('Copied');
          showCopiedText(1);
          return;
        },
        err => {
          setCopyStatus(false);
          setCopyMsg('Copy');
          return;
        }
      );
    }
  }

  const redirectToSignup = () => {
    const state = { from: `${location.pathname}` };
    history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}));
  };

  const sharingMsg = intl.formatMessage(
    { id: 'ReferAHostPage.sharingMsg' },
    { signupReferralLink, myCode: getReferralCode }
  );

  const whatsappSharingMsg = intl.formatMessage(
    { id: 'ReferAHostPage.whatsappSharingMsg' },
    { signupReferralLink, myCode: getReferralCode }
  );

  const twitterLink = typeof window !== 'undefined' ?
    `https://twitter.com/intent/tweet?text=${window.encodeURI(sharingMsg)}` : "https://twitter.com/drivelah";

  const whatsAppLink = isMobileView
    ? `https://api.whatsapp.com/send?text=${whatsappSharingMsg}`
    : `https://web.whatsapp.com/send?text=${whatsappSharingMsg}`;

  const { clientId, scopes, apiKey } = config.googleContacts;

  return (
    <StaticPage
      title="Referral program"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'Referral Program',
        description: 'About Referral Program',
        name: 'Referral Program',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="ReferralPage" />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <Modal
            id="ReferAHostPage.gmailContactsModal"
            isOpen={showGmailContactsModal}
            onClose={() => setShowGmailContactsModal(false)}
            onManageDisableScrolling={onManageDisableScrolling}
            customContainerClassName={css.googleContactsContainer}
          >
            {showGmailContactsModal ? (
              <GroupOfGoogleContacts
                onManageDisableScrolling={onManageDisableScrolling}
                emails={googleContacts}
                submittedValues={submittedValues}
                error={sendInvitationError}
                inProgress={sendInvitationInProgress}
                intl={intl}
                onSubmit={values => {
                  const emails = values['googleContacts'];
                  submittedValues.current = values;
                  onSendInvitation({
                    profileImage: ensuredUser.profileImage,
                    referrerName: ensuredUser.attributes.profile.displayName,
                    referredEmails: emails,
                    referralCode: userId,
                  });
                }}
              />
            ) : null}
          </Modal>

          {/* <h1 className={css.pageTitle}>Experience the unique Finnish home sauna.</h1> */}
          <div className={css.imgWrapper}>
            <div className={css.coverImage} src={image} alt="My first ice cream."></div>
          </div>

          <div className={css.contentWrapper}>
            <div className={css.contentMain}>
              <div className={css.title}>Refer and earn</div>
              <div className={css.content}>
                <div className={css.mainInfo}>
                  Spread the love to your friends, family &amp; coworkers.
                  <br /> Invite them to join Drive lah. They'll get $25 credits when they join.{' '}
                  <br /> <br /> You’ll get, <br />
                  <br />
                  $25 when they rent a car (or) <br />
                  $100 when they list their car & host their first trip
                </div>
                <br />
                <a
                  className={css.termsAndConditions}
                  href="https://help.drivelah.sg/en/articles/4238526-what-are-the-terms-conditions-of-the-referral-program"
                  target="_blank"
                >
                  Terms & Conditions »
                </a>
              </div>
              {/* <ReferralCodeEmailForm
                onSubmit={values => {
                  const { email } = values;
                  submittedValues.current = values;
                  onSendInvitation({
                    profileImage: ensuredUser.profileImage,
                    referrerName: ensuredUser.attributes.profile.displayName,
                    referredEmails: [email],
                    referralCode: userId,
                  });
                }}
                intl={intl}
                submittedValues={submittedValues}
                error={sendInvitationError}
                inProgress={sendInvitationInProgress}
              /> */}
            </div>

            {ensuredUser && ensuredUser.id && (
              <div className={classNames(css.creditsContentSide, css.grey)}>
                <div className={css.innerContentSide}>
                  <img
                    className={css.drivelahCreditsIcon}
                    src={drivelahCreditsIcon}
                    alt="Drivelah Credits Icon"
                  />
                  <div className={css.creditsTitle}>Your Drive lah credits</div>
                  <div className={css.creditsContent}>
                    {' '}
                    Available: {ensuredUser.attributes.credits || 0}{' '}
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className={classNames(css.shareYourLink)}>
            <div className={css.shareYourLinkContentWrapper}>
              <div className={css.shareYourLinkContentMain}>
                <div className={css.shareYourLinkTitle}>
                  {intl.formatMessage({ id: 'ReferAHostPage.shareYourLink' })}
                </div>
                {ensuredUser && ensuredUser.id ? (
                  <>
                    {' '}
                    <div className={css.copyReferralCodeSection}>
                      <div value={signupReferralLink} className={css.copyReferralCode}>
                        {signupReferralLink}
                      </div>

                      <div className={css.buttonWrapper}>
                        <Button
                          className={css.copyButton}
                          type={'button'}
                          onClick={copyNowEventHandler}
                        >
                          {copyMsg}
                        </Button>
                      </div>
                    </div>
                    <div className={css.copyError}>
                      {!copyStatus
                        ? intl.formatMessage({
                          id: 'ReferAHostPage.copyError',
                        })
                        : null}
                    </div>
                    <div className={css.shareIcons}>
                      <a
                        className={css.logo}
                        onClick={() => onShareToFacebookTimeline(sharingMsg, signupReferralLink)}
                        target="_blank"
                      >
                        <img src={FacebookIcon} alt="Facebook-logo" />
                      </a>

                      <a
                        className={css.logo}
                        onClick={() => onShareToMessenger(signupReferralLink)}
                        target="_blank"
                      >
                        <img src={FBMessengerIcon} alt="Facebook-messenger" />
                      </a>

                      <a className={css.logo} href={whatsAppLink} target="_blank">
                        <img src={WhatsAppIcon} alt="Whatsapp-icon" />
                      </a>

                      <a className={css.logo} href={twitterLink} target="_blank">
                        <img src={TwitterIcon} alt="Twitter-icon" />
                      </a>
                    </div>
                  </>
                ) : (
                  <Button className={css.signUpButton} onClick={() => redirectToSignup()}>
                    Sign up now
                  </Button>
                )}
              </div>
              {/* <div className={css.shareYourLinkContentSide}>
                <div className={css.content}>
                  <div className={css.suggestionTitle}>
                    {intl.formatMessage({ id: 'ReferAHostPage.suggestionTitle' })}
                  </div>
                  <div className={css.suggestionContent}>
                    Add your contacts to find friends who aren’t on Drive lah.
                  </div>
                </div>
                <a
                  className={css.suggestionsIcon}
                  onClick={() => {
                    new GoogleContacts({ clientId, scopes, apiKey })
                      .loadContacts()
                      .then(contacts => {
                        if (Array.isArray(contacts) && contacts.length > 0) {
                          setGoogleContacts(contacts);
                          setShowGmailContactsModal(true);
                        }
                      });
                  }}
                >
                  <img className={css.gmailIcon} src={EmailIcon} alt="email-icon" />
                  <span className={css.gmail}> Gmail </span>
                </a>
              </div> */}
            </div>
          </div>
          <div className={css.howToSection}>
            <div className={css.howToTitle}>
              How to get {isMobileView ? <br /> : null} Drive lah credits
            </div>
            <div className={css.howToWrapper}>
              <div className={css.step}>
                <div className={css.stepLogo}>
                  {' '}
                  <span className={css.logoHelper}>
                    {' '}
                    <img src={ShareYourLinkIcon} alt="share-your-link-icon" />{' '}
                  </span>{' '}
                </div>
                <div className={css.stepTitle}>Share your link</div>
                <div className={css.stepContent}>
                  {' '}
                  Invite people new to {desktopLineBreak} Drive lah to join by sharing your{' '}
                  {desktopLineBreak} link with them.
                </div>
              </div>
              <div className={css.step}>
                <div className={css.stepLogo}>
                  {' '}
                  <span className={css.logoHelper}>
                    <img src={TellFriendsIcon} alt="tell-friends-icon" />
                  </span>
                </div>
                <div className={css.stepTitle}> Tell friends to rent or host a trip</div>
                <div className={css.stepContent}>
                  Friends who sign up for {desktopLineBreak} Drive lah with your code will
                  {desktopLineBreak} get $25 credits
                </div>
              </div>
              <div className={css.step}>
                <div className={css.stepLogo}>
                  {' '}
                  <span className={css.logoHelper}>
                    <img src={DrivelahCreditsIconWhite} alt="drivelah-credits-icon" />
                  </span>
                </div>
                <div className={css.stepTitle}>Earn Drive lah credits</div>
                <div className={css.stepContent}>
                  {' '}
                  When a referred user completes as trip you will get $100 or $25 depending on if the user is a host or guest.
                </div>
              </div>
            </div>
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;

  const { sendInvitationError, sendInvitationInProgress } = state.ReferAHostPage;

  const { currentUser } = state.user;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    isAuthenticated,
    sendInvitationError,
    sendInvitationInProgress,
  };
};

const mapDispatchToProps = dispatch => ({
  onSendInvitation: data => dispatch(sendInvitation(data)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

ReferAHostPage.loadData = () => {
  return fetchCurrentUser(null, null, true);
};

export default compose(injectIntl, connect(mapStateToProps, mapDispatchToProps))(ReferAHostPage);
