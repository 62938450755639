import React, { useState } from 'react';
import { PrimaryButton } from '../../components';

import { FormattedMessage } from '../../util/reactIntl';

import classNames from 'classnames';
import css from './UserResidencyForm.css';

const UserResidencyForm = ({
  userEmail,
  residencyStatus,
  modifyStep,
  handleSteps,
  step,
  onUpdateUserData,
}) => {
  const [resStatus, setResStatus] = useState(residencyStatus);
  const guidance = (
    <div className={css.verificationIndication}>
      <p>
        <FormattedMessage id="UserResidencyForm.guide" />
      </p>
    </div>
  );

  const handleSubmit = e => {
    e.preventDefault();
    onUpdateUserData({
      privateData: {
        residencyStatus: resStatus,
      },
    });
  };

  const updateResStatus = value => {
    setResStatus(resStatus === value ? '' : value);
  };

  React.useEffect(() => {
    modifyStep && handleSteps(step, 'Select your residential status');
  }, []);

  return (
    <div>
      {guidance}
      {/* <label className={css.emailLabel}>Email ID</label> */}
      <form className={''} onSubmit={handleSubmit}>
        <div className={css.mainContainer}>
          <div className={classNames(css.residencyRowContainer)}>
            <div
              className={classNames(
                css.rowResidencyItem,
                resStatus === 'Singapore Citizen / PR' ? css.selectedRes : null
              )}
              onClick={() => updateResStatus('Singapore Citizen / PR')}
            >
              Singapore Citizen / PR
            </div>
            <div
              className={classNames(
                css.rowResidencyItem,
                resStatus === 'Emplyoment Pass' ? css.selectedRes : null
              )}
              onClick={() => updateResStatus('Emplyoment Pass')}
            >
              Emplyoment Pass
            </div>
          </div>
          <div className={classNames(css.residencyRowContainer)}>
            <div
              className={classNames(
                css.rowResidencyItem,
                resStatus === 'Dependent Pass' ? css.selectedRes : null
              )}
              onClick={() => updateResStatus('Dependent Pass')}
            >
              Dependent Pass
            </div>
            <div
              className={classNames(
                css.rowResidencyItem,
                resStatus === 'Work Permit' ? css.selectedRes : null
              )}
              onClick={() => updateResStatus('Work Permit')}
            >
              Work Permit
            </div>
          </div>
          <div className={classNames(css.residencyRowContainer)}>
            <div
              className={classNames(
                css.rowResidencyItem,
                resStatus === 'None of the above' ? css.selectedRes : null
              )}
              onClick={() => updateResStatus('None of the above')}
            >
              None of the above
            </div>
          </div>
        </div>
        <div className={classNames(css.continueButtonWrapper)}>
          <PrimaryButton disabled={resStatus === '' ? true : false}>
            <FormattedMessage id="UserResidencyForm.saveDetailsButtonText" />
          </PrimaryButton>
        </div>
      </form>
    </div>
  );
};

export default UserResidencyForm;
