import isEqual from 'lodash/isEqual';
import moment from 'moment';
import React, { memo } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { daysBetween, displayDateForUser } from '../../util/dates';
import { LINE_ITEM_NIGHT, LINE_ITEM_UNITS, propTypes } from '../../util/types';
import css from './BookingInfoSection.css';
import {
  txIsAwaitUpdateBookingBeforeDropOff,
  txIsAwaitUpdateBookingBeforePickUp,
} from '../../util/transaction';
import { getLastUpdateObj } from '../../util/updateTransaction';
import pick from 'lodash/pick';

const BookingPeriod = props => {
  const { isSingleDay, startDate, endDate, timeZone } = props;
  const dateFormatOptions = {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
  };

  const timeZoneMaybe = timeZone ? { timeZone } : null;

  if (isSingleDay) {
    return <FormattedDate value={startDate} {...dateFormatOptions} {...timeZoneMaybe} />;
  }

  return (
    <FormattedMessage
      id="BookingBreakdown.bookingPeriod"
      values={{
        bookingStart: (
          <span className={css.nowrap}>
            <FormattedDate value={startDate} {...dateFormatOptions} {...timeZoneMaybe} />
          </span>
        ),
        newLine: <br />,
        bookingEnd: (
          <span className={css.nowrap}>
            <FormattedDate value={endDate} {...dateFormatOptions} {...timeZoneMaybe} />
          </span>
        ),
      }}
    />
  );
};

const BookingPeriodComponent = memo(
  props => {
    const {
      booking,
      unitType,
      timezone,
      transaction,
    } = props;

    if (!booking) {
      return null;
    }

    const { start: startDate, end: endDateRaw, displayStart, displayEnd } = booking.attributes;
    const transactionTimeZone = transaction.attributes && transaction.attributes.protectedData && transaction.attributes.protectedData.transactionTimeZone ? transaction.attributes.protectedData.transactionTimeZone : timezone;
    
    const localStartDate = displayDateForUser(startDate, transactionTimeZone);
    const localEndDateRaw = displayDateForUser(endDateRaw, transactionTimeZone);

    const realStartDate = moment(displayStart).toDate();
    const realEndDate = moment(displayEnd).toDate();

    const isNightly = unitType === LINE_ITEM_NIGHT;
    const isUnits = unitType === LINE_ITEM_UNITS;

    const dayCount = daysBetween(localStartDate, localEndDateRaw);
    const isSingleDay = !isNightly && !isUnits && dayCount === 1;

    const {
      bookingStart: updatedBookingStart,
      bookingEnd: updatedBookingEnd,
    } = getLastUpdateObj(transaction);
    return (
      <>
        <div className={css.lineItem}>
          <span className={css.bookingPeriodText}>
            <BookingPeriod
              isSingleDay={isSingleDay}
              startDate={realStartDate}
              endDate={realEndDate}
              timeZone={transactionTimeZone}
            />
          </span>
        </div>

        {(txIsAwaitUpdateBookingBeforePickUp(transaction) || txIsAwaitUpdateBookingBeforeDropOff(transaction)) && (
          <div className={css.bookingUpdatedPeriodText}>
            <span className={css.bookingUpdatedPeriodTitle}>Updated Dates</span>
            <p className={css.bookingUpdatedPeriodText}>

              <BookingPeriod
                isSingleDay={isSingleDay}
                startDate={updatedBookingStart}
                endDate={updatedBookingEnd}
                timeZone={transactionTimeZone}
              />
            </p>
          </div>
        )}
      </>

    );
  },
  (prev, next) =>
    isEqual(
      pick(prev, ['booking', 'transaction']),
      pick(next, ['booking', 'transaction'])
    )
);

BookingPeriodComponent.propTypes = {
  transaction: propTypes.transaction.isRequired,
  booking: propTypes.booking.isRequired,
};

export default BookingPeriodComponent;
