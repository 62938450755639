import React from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { ListingLink } from '../../components';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { EditListingDescriptionForm } from '../../forms';
import config from '../../config';

import css from './EditListingDescriptionPanel.css';

const EditListingDescriptionPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    submitButtonId,
    userDetails,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { description, title, publicData } = currentListing.attributes;
  const finalTitle = title === config.custom.defaultTitle ? '' : title;
  const finalDescription = description === config.custom.defaultDescription ? '' : description;
  const drivenKm = publicData && publicData.drivenKm;
  const yearOfManufacture = publicData && publicData.yearOfManufacture;
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingDescriptionPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingDescriptionPanel.createListingTitle" />
  );

  return (
    <div className={classes}>
      {/* <h1 className={css.title}>{panelTitle}</h1> */}
      <div className={css.titleSection}>
        <h1 className={css.title}>About</h1>
        <p>
          Add the basic details about your car, including the name and description that will be the
          first information the guests see.
        </p>
      </div>
      <EditListingDescriptionForm
        className={css.form}
        initialValues={{
          title: finalTitle,
          description: finalDescription,
          drivenKm,
          yearOfManufacture: yearOfManufacture || '2017',
          brandName: publicData.brandName || null,
          modelName: publicData.modelName || null,
          license_plate_number: publicData.license_plate_number,
          nearestStop: publicData.nearestStop,
          timeFromNearestMRT: publicData.timeFromNearestMRT,
        }}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          const { title, description, brandName, modelName, license_plate_number, nearestStop, timeFromNearestMRT, drivenKm, yearOfManufacture } = values;
          const finalDescription =
            !description || description === '' ? config.custom.defaultDescription : description;
          const updateValues = {
            title: title.trim(),
            description: finalDescription,
            publicData: {
              brandName,
              modelName,
              license_plate_number,
              nearestStop,
              timeFromNearestMRT,
              drivenKm
            },
          };
          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        submitButtonId={submitButtonId}
        drivenKm={config.custom.drivenKm}
        timeToNearestStop={config.custom.timeToNearestStop}
        userDetails = {userDetails}
      />
    </div>
  );
};

EditListingDescriptionPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingDescriptionPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
  submitButtonId: string,
};

export default EditListingDescriptionPanel;
