/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { NamedLink, NotificationBadge } from '../../components';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { ensureCurrentUser, userDisplayNameAsString } from '../../util/data';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';

import {
  EVENT_BROWSE_DASHBOARD,
  EVENT_BROWSE_HOWITWORKS,
  EVENT_BROWSE_RENTACAR,
  EVENT_BROWSE_SHAREACAR,
} from '../../util/gtm/gtmConstants';
import { pushGTMBrowseEvent } from '../../util/gtm/gtmHelpers';
import Avatar from '../Avatar/Avatar';
import css from './TopbarMobileMenu.css';
import { getEventsByBucket } from '../../util/gtm/gtmCreateProperties';
import { event_trigger_ids } from '../../util/analyticsConstants';
import { triggerAnalyticsEvent } from '../../util/amplitudeMapEvents';

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUser,
    notificationCount,
    onLogout,
    onClose,
  } = props;

  const user = ensureCurrentUser(currentUser);
  const [eventData, setEventData] = useState(null);

  const handleLinkClick = (eventId, label) => {
    triggerAnalyticsEvent({
      event_id: eventId,
      eventData,
      props: {
        ui: {
          button: label,
          page: 'LandingPageScreen'
        },
      },
      userId: currentUser && currentUser.id ? currentUser.id.uuid : null,
      user: currentUser
    });
  };

  useEffect(() => {
    // Function to fetch events and store in state
    const fetchEvents = async () => {
      try {
        const response = await getEventsByBucket('browsing');
        setEventData(response.data); // Store response in state
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };

    // Call fetchEvents when component mounts
    fetchEvents();
  }, []); // Empty dependency array ensures fetchEvents runs only once


  // const pushGTMBrowse = (eventGTM, eventAttribute) => {
  //   let eventCallback;
  //   if (eventAttribute) {
  //     eventAttribute.preventDefault();
  //     const href = eventAttribute.currentTarget.href;
  //     eventCallback = () => {
  //       if (typeof window !== 'undefined') {
  //         window.location.href = href;
  //       }
  //     };
  //   }
  //   pushGTMBrowseEvent({
  //     props,
  //     event: eventGTM,
  //     eventCallback,
  //   });
  // };

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const displayName = user.attributes.profile.firstName;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const staticLinks = isAuthenticated ? null : (
    <>
      <div className={css.linkWrapper}
      //  onClick={() => pushGTMBrowse(EVENT_BROWSE_HOWITWORKS)}
       >
        <NamedLink name="HowItWorkPage" className={css.link} 
        onClick={() =>
              handleLinkClick(event_trigger_ids.BROWSE_CLICK_HOW_IT_WORKS, "How it works")
            } >
          <FormattedMessage id="TopbarMenu.howItWork" />
        </NamedLink>
      </div>
      <div className={css.linkWrapper} 
      // onClick={() => pushGTMBrowse(EVENT_BROWSE_RENTACAR)}
      >
        <NamedLink name="BecomeAGuestPage" className={css.link}>
          <FormattedMessage id="TopbarMenu.rentingACar" />
        </NamedLink>
      </div>
      <div className={css.linkWrapper} 
      // onClick={() => pushGTMBrowse(EVENT_BROWSE_SHAREACAR)}
      >
        <NamedLink name="BecomeAHostPage" className={css.link}>
          <FormattedMessage id="TopbarMenu.sharingYourCar" />
        </NamedLink>
      </div>
      <div className={css.linkWrapper}>
        <NamedLink name="TrustAndSafetyPage" className={css.link}>
          <FormattedMessage id="TopbarMenu.linkTrustAndSafety" />
        </NamedLink>
      </div>
      <div className={css.linkWrapper}>
        <NamedLink name="LandingPageGo" className={css.link}>
          <FormattedMessage id="TopbarMenu.drivelahGo" />
        </NamedLink>
      </div>
      <div className={css.linkWrapper} 
      // onClick={() => pushGTMBrowse(EVENT_BROWSE_SHAREACAR)}
      >
        <NamedLink name="BecomeAHostPage" className={css.link}>
          <FormattedMessage id="TopbarMenu.sharingYourCar" />
        </NamedLink>
      </div>
      <div className={css.linkWrapper} >
        <NamedLink name="GrabHostYourCarLandingPage" className={css.link}>
          Earn more for Grab drivers
        </NamedLink>
      </div>
      <div className={css.linkWrapper}>
        <NamedLink name="GojekHostYourCarLandingPage" className={css.link}>
          Earn more for Gojek drivers
        </NamedLink>
      </div>
      <div className={css.linkWrapper} >
        <NamedLink name="RentAPHV" className={css.link}>
          Rent a PHV
        </NamedLink>
      </div>

      {/* <div className={css.linkWrapper} onClick={() => null}>
        <NamedLink name="CovidLandingPage" className={css.link}>
          <FormattedMessage id="TopbarDesktop.linkCovidProgram" />
        </NamedLink>
      </div> */}
    </>
  );

  const authenticatedLinks = isAuthenticated ? (
    <>
      <div className={css.linkWrapper} 
      // onClick={() => pushGTMBrowse(EVENT_BROWSE_DASHBOARD)}
      >
        <NamedLink name="InboxBasePage" className={css.link}>
          <FormattedMessage id="TopbarMenu.dashBoard" />
        </NamedLink>
      </div>
      <div className={css.linkWrapper}>
        <NamedLink name="ManageListingsPage" className={css.link}>
          <FormattedMessage id="TopbarMenu.yourListings" />
        </NamedLink>
      </div>
      <div className={css.linkWrapper}
      >
        <NamedLink name="ProfileSettingsPage" className={css.link} onClick={() =>  handleLinkClick(event_trigger_ids.PROFILE_CLICK_PROFILE, "Profile settings")}>
          <FormattedMessage id="TopbarMenu.profileSettings" />
        </NamedLink>
      </div>
      <div className={css.linkWrapper}>
        <NamedLink name="AccountSettingsPage" className={css.link}>
          <FormattedMessage id="TopbarMenu.accountSettings" />
        </NamedLink>
      </div>
      <div className={css.linkWrapper} 
      // onClick={() => pushGTMBrowse(EVENT_BROWSE_SHAREACAR)}
      >
        <NamedLink name="BecomeAHostPage" className={css.link}>
          <FormattedMessage id="TopbarMenu.becomeAHost" />
        </NamedLink>
      </div>
      <div className={css.linkWrapper} 
       onClick={() =>  handleLinkClick(event_trigger_ids.REFER_VIEW_REFER_AND_EARN_PAGE, "Refer and earn")}
      >
        <NamedLink name="ReferralPage" className={css.link}>
          <FormattedMessage id="TopbarMenu.referralPage" />
        </NamedLink>
      </div>
      <div className={css.linkWrapper} 
      // onClick={() => pushGTMBrowse(EVENT_BROWSE_HOWITWORKS)}
      onClick={() =>  handleLinkClick(event_trigger_ids.BROWSE_CLICK_HOW_IT_WORKS, "How it works")}
      >
        <NamedLink name="HowItWorkPage" className={css.link}>
          <FormattedMessage id="TopbarMenu.howItWork" />
        </NamedLink>
      </div>
      <div className={css.linkWrapper} 
      // onClick={() => pushGTMBrowse(EVENT_BROWSE_RENTACAR)}
      >
        <NamedLink name="BecomeAGuestPage" className={css.link}>
          <FormattedMessage id="TopbarMenu.rentingACar" />
        </NamedLink>
      </div>
      <div className={css.linkWrapper} 
      // onClick={() => pushGTMBrowse(EVENT_BROWSE_SHAREACAR)}
      >
        <NamedLink name="BecomeAHostPage" className={css.link}>
          <FormattedMessage id="TopbarMenu.sharingYourCar" />
        </NamedLink>
      </div>
      <div className={css.linkWrapper}>
        <NamedLink name="TrustAndSafetyPage" className={css.link}>
          <FormattedMessage id="TopbarMenu.linkTrustAndSafety" />
        </NamedLink>
      </div>
      <div className={css.linkWrapper}>
        <NamedLink name="LandingPageGo" className={css.link}>
          <FormattedMessage id="TopbarMenu.drivelahGo" />
        </NamedLink>
      </div>
      <div className={css.linkWrapper} onClick={() => { }}>
        <NamedLink name="GrabHostYourCarLandingPage" className={css.link}>
          Earn more for Grab drivers
        </NamedLink>
      </div>
      <div className={css.linkWrapper} onClick={() => { }}>
        <NamedLink name="GojekHostYourCarLandingPage" className={css.link}>
          Earn more for Gojek drivers
        </NamedLink>
      </div>
      <div className={css.linkWrapper} onClick={() => { }}>
        <NamedLink name="RentAPHV" className={css.link}>
          Rent a PHV
        </NamedLink>
      </div>

    </>
  ) : null;

  const logout = isAuthenticated ? (
    <div
      onClick={() => {
        onLogout();
        onClose();
      }}
      className={css.logout}
    >
      <FormattedMessage id="TopbarMenu.logout" />
    </div>
  ) : null;

  const signUp = isAuthenticated ? null : (
    <div className={classNames(css.linkWrapper, css.signUpLinkWrapper)}>
      <NamedLink name="SignupPage" className={classNames(css.link, css.authLink)}>
        <FormattedMessage id="TopbarMenu.signUp" />
      </NamedLink>
    </div>
  );

  return (
    <div className={css.root}>
      <div className={css.space} onClick={onClose} />
      <div className={css.content}>
        <div className={css.avatarWrapper}>
          <Avatar user={currentUser} />
          <div className={css.userName}>
            {isAuthenticated ? (
              userDisplayNameAsString(currentUser)
            ) : (
              <NamedLink name="LoginPage" className={classNames(css.link, css.authLink)}>
                <FormattedMessage id="TopbarMenu.login" />
              </NamedLink>
            )}
          </div>
        </div>
        <div className={css.links}>
          {signUp}
          {authenticatedLinks}
          {staticLinks}
        </div>
        {logout}
      </div>
    </div>
  );
};

TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null };

const { bool, func, number, string, object } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
  location: object,
};

export default TopbarMobileMenu;
