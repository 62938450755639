import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import css from './SingpassCallback.css';

const SingpassCallback = props => {
  const search = useLocation().search;
  const code = new URLSearchParams(search).get('code');
  const state = new URLSearchParams(search).get('state');

  const history = useHistory();

  useEffect(() => {
    const fetchClientData = async () => {
      try {
        const clientResponse = await axios.post(
          `${process.env.REACT_APP_API_SERVER_URL}/api/singpass/getIndentityPersonData`,
          {
            authCode: code,
            state: state,
          }
        );

        // alert('client response should come', clientResponse)
        if (window.ReactNativeWebView) {
          let data = JSON.stringify({
            status: true,
          });
          window.ReactNativeWebView.postMessage(window.location.href);
        } else {
          history.push('/account/driving-verification/singpass-profile');
        }
      } catch (error) {
        // alert('error should come')
        if (window.ReactNativeWebView) {
          let data = JSON.stringify({
            status: false,
          });
          window.ReactNativeWebView.postMessage(window.location.href);
        } else {

          history.push('/account/driving-verification');
        }
      }
    };
    if (code && state) {
      fetchClientData();
    }
  }, []);

  return (
    <>
      <div className=''>
        <div className={css.loader}></div>
        <h3>We are fetching your verification data from Singpass...</h3>
      </div>

    </>
  );
};

export default SingpassCallback;
