import React from 'react';
import { injectIntl, intlShape } from '../../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import config from '../../../config';
import { isScrollingDisabled, manageDisableScrolling } from '../../../ducks/UI.duck';
import {
  Footer,
  LayoutSideNavigation,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
  SingpassCallback,
  UserNav,
} from '../../../components';
import { TopbarContainer } from '../../../containers';
import css from '../AccountSettingDriverVerificationPage.css';
import { withViewport } from '../../../util/contextHelpers';
// {/* <SingpassCallback {...props}/> */}
import facebookImage from '../../../assets/facebook_drivelah_2.jpg';
import twitterImage from '../../../assets/facebook_drivelah_2.jpg';

const SingpassCallbackPageComponent = (props) => {
    const {
      history,
      intl,
      viewport,
      location,
      scrollingDisabled,
      turnoffPromoteBar,
      currentUser,
      isAuthenticated,
      onManageDisableScrolling,
      onApplyCampaign,
      applyError,
      applyInProgress,
      applySuccess,
      onLoginOrSignupClick,
      isFromTripPage
    } = props;

    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitleGo' }, { siteTitle });
    const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescriptionGo' });
    const schemaImage = `${config.canonicalRootURL}${facebookImage}`;
  
    return (
        <Page 
        className={css.root}
        scrollingDisabled={scrollingDisabled}
        contentType="website"
        description={schemaDescription}
        title={schemaTitle}
        facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
        twitterImages={[
          { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
        ]}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          description: schemaDescription,
          name: schemaTitle,
          image: [schemaImage],
        }}>
        <LayoutSideNavigation>
          <LayoutWrapperTopbar>
            <TopbarContainer
              currentPage="AccountSettingDriverVerificationPage"
              desktopClassName={css.desktopTopbar}
              mobileClassName={css.mobileTopbar}
            />
            <UserNav selectedPageName="AccountSettingDriverVerificationPage" />
          </LayoutWrapperTopbar>
          {/* <LayoutWrapperSideNav tabs={tabs} user={user} /> */}
          <LayoutWrapperMain>
            <div className={css.callBackcontent}>
                <SingpassCallback {...props}/>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSideNavigation>
      </Page>

    )
};


const { bool, object } = PropTypes;

SingpassCallbackPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
    return {
      scrollingDisabled: isScrollingDisabled(state),
    };
};

const mapDispatchToProps = dispatch => ({});


const SingpassCallbackPageComponentPage = compose(
  withViewport,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(SingpassCallbackPageComponent);


export default SingpassCallbackPageComponentPage;
