import React from 'react';
import { FormattedMessage } from 'react-intl';
import flexLogo from '../../assets/flex-plus-white-horizontal.svg';
import { ExternalLink } from '../../components';
import css from './SectionCompare.css';

const SectionCompare = () => {
  return (
    <div className={css.root}>
      <div>
        <div className={css.title}>
          <FormattedMessage id="LandingPageFlex.SectionCompare.title" />
        </div>
        <div className={css.description}>
          <FormattedMessage id="LandingPageFlex.SectionCompare.description" />
        </div>
      </div>
      <div className={css.compare}>
        <div className={css.compareTitle}>Lease Vs Drive lah flex+</div>
        <table className={css.compareTable}>
          <tr>
            <th></th>
            <th>
              <img src={flexLogo} className={css.flexLogo} />
            </th>
            <th>Lease</th>
          </tr>
          <tbody>
            <tr>
              <td>Monthly Payment*</td>
              <td className={css.total} style={{ color: '#ffcd05' }}>
                $1028
              </td>
              <td className={css.total}>$1400</td>
            </tr>
            <tr>
              <td>Drive to Malaysia</td>
              <td>
                <CircleCheck />
              </td>
              <td>
                <Close />
              </td>
            </tr>
            <tr>
              <td>Return Anytime</td>
              <td>
                <CircleCheck />
              </td>
              <td>
                <Close />
              </td>
            </tr>

            <tr>
              <td>No Deposit</td>
              <td>
                <CircleCheck />
              </td>
              <td>
                <Close />
              </td>
            </tr>
            <tr>
              <td>Full Day Test Drive</td>
              <td>
                <CircleCheck />
              </td>
              <td>
                <Close />
              </td>
            </tr>
            <tr>
              <td>Switch Cars</td>
              <td>
                <CircleCheck />
              </td>
              <td>
                <Close />
              </td>
            </tr>
            <tr>
              <td>24/7 Assistance</td>
              <td>
                <CircleCheck />
              </td>
              <td>
                <Close />
              </td>
            </tr>
          </tbody>
        </table>
        <div className={css.smallText}>
          <ExternalLink href="https://help.drivelah.sg/en/articles/6634633-flex-car-subscription-with-benefits">
            See detailed terms & conditions
          </ExternalLink>
        </div>
      </div>
    </div>
  );
};

export default SectionCompare;

const CircleCheck = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16.75" height="16.75" viewBox="0 0 16.75 16.75">
    <path
      id="check-circle-solid"
      d="M24.75,16.375A8.375,8.375,0,1,1,16.375,8,8.375,8.375,0,0,1,24.75,16.375Zm-9.344,4.434L21.62,14.6a.54.54,0,0,0,0-.764l-.764-.764a.54.54,0,0,0-.764,0l-5.068,5.067-2.366-2.366a.54.54,0,0,0-.764,0l-.764.764a.54.54,0,0,0,0,.764l3.512,3.512a.54.54,0,0,0,.764,0Z"
      transform="translate(-8 -8)"
      fill="#ffcd05"
    />
  </svg>
);

const Close = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11.535"
    height="11.535"
    viewBox="0 0 11.535 11.535"
  >
    <path
      id="close-solid"
      d="M7.954,85.767l3.279-3.279a1.031,1.031,0,0,0,0-1.458L10.5,80.3a1.031,1.031,0,0,0-1.458,0L5.767,83.581,2.488,80.3a1.031,1.031,0,0,0-1.458,0L.3,81.031a1.031,1.031,0,0,0,0,1.458l3.279,3.279L.3,89.046A1.031,1.031,0,0,0,.3,90.5l.729.729a1.031,1.031,0,0,0,1.458,0l3.279-3.279,3.279,3.279a1.031,1.031,0,0,0,1.458,0l.729-.729a1.031,1.031,0,0,0,0-1.458Z"
      transform="translate(0 -80)"
      fill="#cf5d5d"
    />
  </svg>
);
