import get from 'lodash/get';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { userDisplayNameAsString } from '../../util/data';
import { txIsDeclined, txIsExpired, txIsRequested } from '../../util/transaction';
import {
  txIsDeclinedLTF,
  txIsExpiredLTF,
  txIsRequestedLTF,
} from '../../util/transactionLongTermFirst';
import { AvatarLarge } from '../Avatar/Avatar';
import css from './TransactionPanel.css';

const getCustomerPhoneNumber = transaction => {
  return (
    get(transaction, 'attributes.protectedData.guestPhoneNumber', null) ||
    get(transaction, 'attributes.protectedData.customerPhoneNumber', null)
  );
};

const getHostPhoneNumber = transaction => {
  return get(transaction, 'attributes.protectedData.hostPhoneNumber', null);
};

const PartnerInfo = ({ otherUser, currentUserRole, transaction }) => {
  const isShowPartnerInfo =
    !txIsRequested(transaction) &&
    !txIsDeclined(transaction) &&
    !txIsExpired(transaction) &&
    !txIsRequestedLTF(transaction) &&
    !txIsDeclinedLTF(transaction) &&
    !txIsExpiredLTF(transaction);

  const otherPhoneNumber =
    currentUserRole === 'customer'
      ? getHostPhoneNumber(transaction)
      : getCustomerPhoneNumber(transaction);
  const listingRegistrationNumber = get(
    transaction,
    'listing.attributes.publicData.license_plate_number',
    null
  );
  return (
    <div className={css.partnerInfo}>
      <div className={css.avatarWrapperProviderDesktop}>
        <AvatarLarge user={otherUser} className={css.avatarDesktop} />
      </div>
      <div className={css.partnerName}>
        <h3>
          {currentUserRole === 'customer' ? (
            <FormattedMessage id="TransactionPanel.whoIsTheHost" />
          ) : (
            <FormattedMessage id="TransactionPanel.whoIsDriving" />
          )}
        </h3>
        <div>{userDisplayNameAsString(otherUser)}</div>
      </div>

      <div className={css.partnerName}>
        <h3>
          {currentUserRole === 'customer' ? (
            <FormattedMessage id="TransactionPanel.hostPhone" />
          ) : (
            <FormattedMessage id="TransactionPanel.customerPhone" />
          )}
        </h3>
        <div>{otherPhoneNumber && <a href={`tel:${otherPhoneNumber}`}>{otherPhoneNumber}</a>}</div>
      </div>
      {isShowPartnerInfo && listingRegistrationNumber && (
        <div className={css.partnerName}>
          <h3>
            <FormattedMessage id="TransactionPanel.registrationNumber" />
          </h3>
          <div>{listingRegistrationNumber}</div>
        </div>
      )}
    </div>
  );
};

export default PartnerInfo;
