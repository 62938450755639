import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import css from './GojekRentSignUpForm.css';
import { FieldTextInput, SecondaryButton } from '../../components';
import { useIntl } from 'react-intl';
import {
  composeValidators,
  emailFormatValid,
  maxLength,
  minLength,
  PASSWORD_MAX_LENGTH,
  PASSWORD_MIN_LENGTH,
  required,
  requiredStringNoTrim,
} from '../../util/validators';

const GojekRentSignUpForm = props => {
  const intl = useIntl();
  const emailRequiredMessage = intl.formatMessage({
    id: 'SignupForm.emailRequired',
  });
  const emailRequired = required(emailRequiredMessage);
  const emailInvalidMessage = intl.formatMessage({
    id: 'SignupForm.emailInvalid',
  });
  const emailValid = emailFormatValid(emailInvalidMessage);

  const passwordMaxLengthMessage = intl.formatMessage(
    {
      id: 'SignupForm.passwordTooLong',
    },
    {
      maxLength: PASSWORD_MAX_LENGTH,
    }
  );
  const passwordRequiredMessage = intl.formatMessage({
    id: 'SignupForm.passwordRequired',
  });
  const passwordMinLengthMessage = intl.formatMessage(
    {
      id: 'SignupForm.passwordTooShort',
    },
    {
      minLength: PASSWORD_MIN_LENGTH,
    }
  );
  const passwordMinLength = minLength(passwordMinLengthMessage, PASSWORD_MIN_LENGTH);
  const passwordMaxLength = maxLength(passwordMaxLengthMessage, PASSWORD_MAX_LENGTH);
  const passwordRequired = requiredStringNoTrim(passwordRequiredMessage);
  const passwordValidators = composeValidators(
    passwordRequired,
    passwordMinLength,
    passwordMaxLength
  );

  return (
    <FinalForm
      {...props}
      render={({ handleSubmit }) => {
        return (
          <div className={css.root}>
            <div className={css.field}>
              <FieldTextInput
                type="email"
                name="email"
                autoComplete="email"
                placeholder={'Enter your email'}
                label={'Email'}
                validate={composeValidators(emailRequired, emailValid)}
                className={css.input}
                id="email"
              />
            </div>
            <SecondaryButton onClick={handleSubmit} className={css.submitBtn}>
              Create account &amp; start earning
            </SecondaryButton>

            {/* <div className={css.smallText}>
              By signing up, you agree to our{' '}
              <NamedLink className={css.termOfUse} name="TermsOfServicePage">
                T&Cs
              </NamedLink>
              .
            </div> */}
            <div className={css.smallText}>
              {props.hideMessage
                ? ''
                : 'Your credits will be available to you to withdraw once you create a listing and complete your first trip as a host.'}
            </div>
          </div>
        );
      }}
    />
  );
};

export default GojekRentSignUpForm;
