import React, { Component } from 'react';
import { func, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ExternalLink, HostInsurancePolicy, InsurancePolicy, Modal } from '../../components';

import css from './InsurancePanel.css';
import insuranceLogo from '../../assets/logos/Tokio_Marine_logo.png';
import insurancePdfOldCar from './insurancePdf3rdParty.pdf';
import { checkIsOldCar } from '../../util/dates';

const INSURANCE_PRIVATE = 'private';
const INSURANCE_COMMERCIAL = 'commercial';

class InsurancePanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
    };
  }

  openInsurancePolicy = (type, url) => {
    let modalContent = null
    if (type === INSURANCE_PRIVATE) {
      modalContent = (
        <InsurancePolicy />
      )
    } else if (type === INSURANCE_COMMERCIAL) {
      modalContent = (
        <HostInsurancePolicy url={url} />
      )
    }
    this.setState({
      isModalOpen: true,
      modalContent: modalContent
    })
  }

  render() {
    const {
      rootClassName,
      className,
      showInsurance,
      insuranceType,
      listing,
      onManageDisableScrolling,
      onReadInsurance,
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);
    const isOldCar = checkIsOldCar(listing);

    return (
      <div className={classes}>
        {showInsurance && insuranceType === INSURANCE_PRIVATE &&
          <div className={css.insuranceBottom}>
            <div className={css.insuranceTitle}>
              <FormattedMessage id="InsurancePanel.title"/>
            </div>
            {
              isOldCar ? (
                <div>
                  <div className={css.insuranceBottomLine}>
                    <FormattedMessage id="InsurancePanel.insuranceBottomLine1OldCar" />
                  </div>
                  <div className={css.insuranceBottomLine}>
                    <FormattedMessage id="InsurancePanel.insuranceBottomLine2" />
                  </div>
                  <div className={css.insuranceBottomLine}>
                    <FormattedMessage id="InsurancePanel.insuranceBottomLine3" />
                  </div>
                  <div className={css.insuranceBottomLine}>
                    <FormattedMessage id="InsurancePanel.insuranceBottomLine5OldCar" />
                  </div>
                  <div className={css.insuranceBottomLine}>
                    <FormattedMessage id="InsurancePanel.insuranceBottomLine6" />
                  </div>
                  <div className={css.insuranceBottomLine}>
                    <FormattedMessage id="InsurancePanel.insuranceBottomLine7OldCar" />
                  </div>
                </div>
              ) : (
                <div>
                  <div className={css.insuranceBottomLine}>
                    <FormattedMessage id="InsurancePanel.insuranceBottomLine1" />
                  </div>
                  <div className={css.insuranceBottomLine}>
                    <FormattedMessage id="InsurancePanel.insuranceBottomLine2" />
                  </div>
                  <div className={css.insuranceBottomLine}>
                    <FormattedMessage id="InsurancePanel.insuranceBottomLine3" />
                  </div>
                  <div className={css.insuranceBottomLine}>
                    <FormattedMessage id="InsurancePanel.insuranceBottomLine4" />
                  </div>
                  <div className={css.insuranceBottomLine}>
                    <FormattedMessage id="InsurancePanel.insuranceBottomLine5" />
                  </div>
                  <div className={css.insuranceBottomLine}>
                    <FormattedMessage id="InsurancePanel.insuranceBottomLine6" />
                  </div>
                </div>
              )
            }

            <img className={css.insuranceBottomImg} src={insuranceLogo} />
            <ExternalLink
              onClick={onReadInsurance}
              className={css.insuranceBottomImgText}
              href={isOldCar ? insurancePdfOldCar : "https://drivelah-public-files.s3.ap-southeast-1.amazonaws.com/Drive+lah+protect+-+Policy.pdf"}
            >
              <FormattedMessage
                id="InsurancePanel.insuranceBottomImgText"
                values={{
                  link: (
                    <FormattedMessage id="InsurancePanel.insuranceBottomImgLink" />
                  )
                }}
              />
            </ExternalLink>
          </div>
        }
        {showInsurance && insuranceType === INSURANCE_COMMERCIAL &&
          <div className={css.noInsuranceBottom}>
            <div className={css.noInsuranceBottomTitle}>
              <FormattedMessage id="InsurancePanel.noInsuranceBottomTitle" />
            </div>
            <div className={css.noInsuranceBottomDesc}>
              {listing &&
                <p dangerouslySetInnerHTML={{ __html: listing.attributes.publicData.highlightsOfCommercialInsurance }}></p>
              }
            </div>
            {listing && listing.attributes.publicData.commercialInsuranceDetail ?
              <div
                className={classNames(css.noInsuranceBottomDesc, css.openHostInsurance, css.desktop)}
                onClick={() => {
                  this.openInsurancePolicy(INSURANCE_COMMERCIAL, listing.attributes.publicData.commercialInsuranceDetail)
                }}
              >
                <FormattedMessage id="InsurancePanel.noInsuranceBottomDescLine2" />
              </div> : null
            }
              {listing && listing.attributes.publicData.commercialInsuranceDetail ?
              <a
                onClick={onReadInsurance}
                className={classNames(css.noInsuranceBottomDesc, css.openHostInsurance, css.mobile)}
                href={listing && listing.attributes.publicData.commercialInsuranceDetail ? listing.attributes.publicData.commercialInsuranceDetail : '#'}
                target="_blank"
              >
                <FormattedMessage id="InsurancePanel.noInsuranceBottomDescLine2" />
              </a> : null
            }
          </div>
        }
        <Modal
          id="staticPageModal"
          isOpen={!!this.state.isModalOpen}
          onClose={() => {
            this.setState({
              isModalOpen: false
            })
          }}
          containerClassName={css.modalContainer}
          onManageDisableScrolling={onManageDisableScrolling}
          closeButtonMessage={(
            <FormattedMessage id="ModalMissingInformation.closeStaticModal" />
          )}
        >
          {this.state.modalContent}
        </Modal>
      </div>
    )
  }
}

InsurancePanel.defaultProps = {
  rootClassName: null,
  className: null
}

InsurancePanel.propTypes = {
  rootClassName: string,
  className: string,
  onReadInsurance: func,
}

export default InsurancePanel;
