import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import storyIcon from '../../assets/landingpage/storyIcon.png';
import avatar1 from '../../assets/SuccessStoriesImages/avatar1.jpeg';
import avatar2 from '../../assets/SuccessStoriesImages/avatar2.jpeg';
import avatar3 from '../../assets/SuccessStoriesImages/avatar3.jpeg';
import avatar4 from '../../assets/SuccessStoriesImages/avatar4.jpeg';
import avatar5 from '../../assets/SuccessStoriesImages/avatar5.jpeg';
import css from './RentAPHV.css';

const datas = [
  {
    content:
      'The best part about Drive lah is that the mileage is included in the rental cost. This makes it so much more affordable and easy to make profits.',
    name: 'Mavis',
    avatar: avatar1,
  },
  {
    content:
      'The customer service is really cooperative and patient. If I face an issue I just call them and they sort the problem immediately. Happy with the service.',
    name: 'Chew',
    avatar: avatar2,
  },
  {
    content:
      'I work on weekdays and drive for Grab on weekends. This option of renting a car from Drive lah whenever I have time helps me make money in my free time.',
    name: 'Shimei He',
    avatar: avatar3,
  },
  {
    content: 'Convenient way to rent out your car with insurance covered. Hassle free!',
    name: 'Jonathan Lai',
    avatar: avatar4,
  },
  {
    content:
      'Drive Lah is a safe, easy and convenient platform for car owners to lease their unused car 24-7 for extra income to offset their car maintenance in Singapore. Thanks Drive Lah!',
    name: 'Ben Chan',
    avatar: avatar5,
  },
];

const Story = ({ content, name, avatar }) => {
  return (
    <div className={css.story}>
      <div className={css.storyContentWrapper}>
        <img src={storyIcon} height={100} />
        <div className={css.storyContent}>{content}</div>
      </div>
      <div className={css.avatarWrapper}>
        <img src={avatar} className={css.avatar} />
        <div className={css.storyName}>{name}</div>
      </div>
    </div>
  );
};

const SectionStories = () => {
  const sliderConfig = {
    infinite: false,
    slidesToScroll: 1,
    slidesToShow: 3,
    initialSlide: 0,
    infinite: false,
    dots: false,
    arrows: true,
    adaptiveHeight: false,
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 3,
          initialSlide: 0,
          infinite: false,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          initialSlide: 0,
          infinite: false,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          initialSlide: 0,
          infinite: false,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: true,
          arrows: false,
        },
      },
    ],
  };
  return (
    <div className={css.sectionStories}>
      <div className={css.sectionStoriesTitleWrapper}>
        <div className={css.sectionTitle}>Happy Customers</div>
        <div className={css.sectionStoriesDescription}>They love sharing cars on Drive lah!</div>
      </div>
      <div className={css.sliderWrapper}>
        <Slider {...sliderConfig}>
          {datas.map(s => (
            <div className={css.storyWrapper}>
              <Story {...s} />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default SectionStories;
