import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';

import css from './BookingBreakdown.css';
import { Money } from 'sharetribe-flex-sdk/src/types';
import { LINE_ITEM_CUSTOMER_COMMISSION, LINE_ITEM_PROCESSING_FEE } from '../../util/types';
import {
  lastUpdateTransactionLineItems,
  lastUpdateTransactionPayinTotal,
} from '../../util/updateTransaction';

const LineItemUpdateBookingMaybe = props => {
  const {
    lastUpdateBookingTx,
    intl,
    isProvider,
  } = props;
  const updateBookingLineItems = lastUpdateTransactionLineItems(lastUpdateBookingTx);
  const customerCommissionLineItem = updateBookingLineItems.find(
    item => item.code === LINE_ITEM_CUSTOMER_COMMISSION
  );
  const processingFeeLineItem = updateBookingLineItems.find(
    item => item.code === LINE_ITEM_PROCESSING_FEE
  );
  const updatedBookingPayinTotal = lastUpdateTransactionPayinTotal(lastUpdateBookingTx);

  if (updatedBookingPayinTotal === null) return null;

  const finalUpdatedBookingPayinTotal = isProvider
    ? updatedBookingPayinTotal - processingFeeLineItem.unitPrice - customerCommissionLineItem.unitPrice
    : updatedBookingPayinTotal;

  const label = <FormattedMessage id="BookingBreakdown.updateBooking" />;
  const updateBookingPrice = new Money(finalUpdatedBookingPayinTotal*100, 'SGD')
  const formattedPrice = formatMoney(intl, updateBookingPrice, 1);

  return (
    <div className={css.creditsLineItem}>
      <div className={css.totalLabel}>{label}</div>
      <div className={css.totalPrice}>{formattedPrice}</div>
    </div>
  );
};

export default LineItemUpdateBookingMaybe;
