import React from 'react';
import css from './AntiCompetitionLandingPage.css';
import imageSource from '../../util/imageSource';

const CompetitionStatisticsSection = () => {

  return (
    <div className={css.statisticsWrapper}>
      <div className={css.container}>
        <div className={css.row}>
          <div className={css.column4}>
            <div className={css.statsThumb}>
              <img src={imageSource.antiCompetition1.happyUsers} alt="Happy Users" />
            </div>
            <div className={css.statsContent}>
              <h3>150k+</h3>
              <p>Happy Users</p>
            </div>
          </div>
          <div className={css.column4}>
            <div className={css.statsThumb}>
              <img src={imageSource.antiCompetition1.successfulTrips} alt="Successful trips" />
            </div>
            <div className={css.statsContent}>
              <h3>50k+</h3>
              <p>Successful trips</p>
            </div>
          </div>
          <div className={css.column4}>
            <div className={css.statsThumb}>
              <img src={imageSource.antiCompetition.carsAvailable} alt="" />
            </div>
            <div className={css.statsContent}>
              <h3>1400+</h3>
              <p>Locations</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompetitionStatisticsSection;
