import imageSource from '../../util/imageSource';

export const comparitionOptions = [
  {
    id: 0,
    thumbUrl: imageSource.antiCompetition.smallCategories,
    categoryType: 'SMALL',
    exampleCars: 'Honda Jazz, Honda Fit, Mitsubishi Attrage or similar',
    rentalDuration: 6,
    distanceDriven: 50,
    carCompanies: [
      {
        id: 0,
        companyName: 'Drive lah',
        companyLogo: imageSource.antiCompetition.drivelahSVG,
      },
      {
        id: 1,
        companyName: 'Shariot',
        companyLogo: imageSource.antiCompetition1.shariot,
      },
      {
        id: 2,
        companyName: 'Get go',
        companyLogo: imageSource.antiCompetition1.getGo,
      },
      {
        id: 3,
        companyName: 'Tribe car',
        companyLogo: imageSource.antiCompetition1.tribecar,
      },
      {
        id: 4,
        companyName: 'Blue SG',
        companyLogo: imageSource.antiCompetition.bluesg,
      },
    ],
    statistics: {
      '6to50': {
        stateConclusion: 'Closest competitor is 21% more expensive than Drive lah',
        numbers: [39, 57, 0, 47, 79],
      },
      '6to75': {
        stateConclusion: 'Closest competitor is 21% more expensive than Drive lah',
        numbers: [43, 61, 0, 52, 79],
      },
      '24to100': {
        stateConclusion: 'Closest competitor is 22% more expensive than Drive lah',
        numbers: [73, 169, 0, 89, 306],
      },
      '24to150': {
        stateConclusion: 'Closest competitor is 20% more expensive than Drive lah',
        numbers: [82, 178, 0, 98, 306],
      },
    },
    assumptions: {
      basicAssumptions:
        'Total Rental cost for a guest which includes fuel and insurance. Fuel is based on $0.18 / km. &&& The monthly subscription fee (if any) per trip is based on the average of 3 trips per month, e.g. if $8 per month, then $8/3 is added to the trip.  &&& “Lowest priced car” for a particular car category at all service providers are compared for same date and time. &&& The cost for adding $100 to wallet is also included in the price, e.g. if you add $100 in your wallet and have $98 to spend, then calculation includes 2% payment fees per trip. &&& We are comparing 6hr trips from 3 pm to 9 pm on Wednesday, Jan 12th, 2022 for given car categories on all platforms. Similarly, the 24hr trips are compared from Wednesday, Jan 12th, 2022, 3 pm until Thursday, Jan 13th, 3 pm.',
      '6HourAssumption': '6-hour trips are from 3pm to 9pm on Jan 12th 2022',
      '24HourAssumption':
        '24-hour trips are from Jan 12th 3pm until Jan 13th 3pm',
    },
    isCategorySelected: true,
  },
  {
    id: 1,
    thumbUrl: imageSource.antiCompetition1.midLargeCategories,
    categoryType: 'MID-LARGE',
    exampleCars: 'Toyota Camry, Nissan Almera, Opel Corsa, VW Golf, Toyota C-HR or similar',
    carCompanies: [
      {
        id: 0,
        companyName: 'Drive lah',
        companyLogo: imageSource.antiCompetition.drivelahSVG,
      },
      {
        id: 1,
        companyName: 'Shariot',
        companyLogo: imageSource.antiCompetition1.shariot,
      },
      {
        id: 2,
        companyName: 'Get go',
        companyLogo: imageSource.antiCompetition1.getGo,
      },
      {
        id: 3,
        companyName: 'Tribe car',
        companyLogo: imageSource.antiCompetition1.tribecar,
      },
    ],
    statistics: {
      '6to50': {
        stateConclusion: 'Closest competitor is 10% more expensive than Drive lah',
        numbers: [51, 57, 63, 56],
      },
      '6to75': {
        stateConclusion: 'Closest competitor is 9% more expensive than Drive lah',
        numbers: [55, 62, 71, 60],
      },
      '24to100': {
        stateConclusion: 'Closest competitor is 5% more expensive than Drive lah',
        numbers: [95, 169, 155, 100],
      },
      '24to150': {
        stateConclusion: 'Closest competitor is 15% more expensive than Drive lah',
        numbers: [104, 178, 173, 120],
      },
    },
    assumptions: {
      basicAssumptions:
        'Total Rental cost for a guest which includes fuel and insurance. Fuel is based on $0.18 / km. &&& The monthly subscription fee (if any) per trip is based on the average of 3 trips per month, e.g. if $8 per month, then $8/3 is added to the trip.  &&& “Lowest priced car” for a particular car category at all service providers are compared for same date and time. &&& The cost for adding $100 to wallet is also included in the price, e.g. if you add $100 in your wallet and have $98 to spend, then calculation includes 2% payment fees per trip.&&&We are comparing 6hr trips from 3 pm to 9 pm on Wednesday, Jan 12th, 2022 for given car categories on all platforms. Similarly, the 24hr trips are compared from Wednesday, Jan 12th, 2022, 3 pm until Thursday, Jan 13th, 3 pm.',
      '6HourAssumption': '6-hour trips are from 3pm to 9pm on Jan 12th 2022',
      '24HourAssumption':
        '24-hour trips are from Jan 12th 3pm until Jan 13th 3pm',
    },
    isCategorySelected: false,
  },
  {
    id: 2,
    thumbUrl: imageSource.antiCompetition.compactPremiumCategories,
    categoryType: 'Compact Premium',
    exampleCars: 'Audi A3, Audi A1 or similar',
    carCompanies: [
      {
        id: 0,
        companyName: 'Drive lah',
        companyLogo: imageSource.antiCompetition.drivelahSVG,
      },
      {
        id: 1,
        companyName: 'Shariot',
        companyLogo: imageSource.antiCompetition1.shariot,
      },
      {
        id: 2,
        companyName: 'Get go',
        companyLogo: imageSource.antiCompetition1.getGo,
      },
      {
        id: 3,
        companyName: 'Tribe car',
        companyLogo: imageSource.antiCompetition1.tribecar,
      },
    ],
    statistics: {
      '6to50': {
        stateConclusion: 'Closest competitor is 17% more expensive than Drive lah',
        numbers: [48, 0, 0, 56],
      },
      '6to75': {
        stateConclusion: 'Closest competitor is 13% more expensive than Drive lah',
        numbers: [53, 0, 0, 60],
      },
      '24to100': {
        stateConclusion: 'Closest competitor is 29% more expensive than Drive lah',
        numbers: [90, 169, 0, 116],
      },
      '24to150': {
        stateConclusion: 'Closest competitor is 26% more expensive than Drive lah',
        numbers: [99, 178, 0, 125],
      },
    },
    assumptions: {
      basicAssumptions:
        'Total Rental cost for a guest which includes fuel and insurance. Fuel is based on $0.18 / km. &&& The monthly subscription fee (if any) per trip is based on the average of 3 trips per month, e.g. if $8 per month, then $8/3 is added to the trip.  &&& “Lowest priced car” for a particular car category at all service providers are compared for same date and time. &&& The cost for adding $100 to wallet is also included in the price, e.g. if you add $100 in your wallet and have $98 to spend, then calculation includes 2% payment fees per trip.&&&We are comparing 6hr trips from 3 pm to 9 pm on Wednesday, Jan 12th, 2022 for given car categories on all platforms. Similarly, the 24hr trips are compared from Wednesday, Jan 12th, 2022, 3 pm until Thursday, Jan 13th, 3 pm.',
      '6HourAssumption': '6-hour trips are from 3pm to 9pm on Jan 12th 2022',
      '24HourAssumption':
        '24-hour trips are from Jan 12th 3pm until Jan 13th 3pm',
    },
    isCategorySelected: false,
  },
  {
    id: 3,
    thumbUrl: imageSource.antiCompetition.compactSUVCategories,
    categoryType: 'Compact SUV',
    exampleCars: 'Honda Vezel or similar',
    carCompanies: [
      {
        id: 0,
        companyName: 'Drive lah',
        companyLogo: imageSource.antiCompetition.drivelahSVG,
      },
      {
        id: 1,
        companyName: 'Shariot',
        companyLogo: imageSource.antiCompetition1.shariot,
      },
      {
        id: 2,
        companyName: 'Get go',
        companyLogo: imageSource.antiCompetition1.getGo,
      },
      {
        id: 3,
        companyName: 'Tribe car',
        companyLogo: imageSource.antiCompetition1.tribecar,
      },
    ],
    statistics: {
      '6to50': {
        stateConclusion: 'Closest competitor is 19% more expensive than Drive lah',
        numbers: [48, 57, 63, 0],
      },
      '6to75': {
        stateConclusion: 'Closest competitor is 17% more expensive than Drive lah',
        numbers: [53, 62, 71, 0],
      },
      '24to100': {
        stateConclusion: 'Closest competitor is 72% more expensive than Drive lah',
        numbers: [90, 169, 155, 196],
      },
      '24to150': {
        stateConclusion: 'Closest competitor is 75% more expensive than Drive lah',
        numbers: [99, 178, 173, 196],
      },
    },
    assumptions: {
      basicAssumptions:
        'Total Rental cost for a guest which includes fuel and insurance. Fuel is based on $0.18 / km. &&& The monthly subscription fee (if any) per trip is based on the average of 3 trips per month, e.g. if $8 per month, then $8/3 is added to the trip.  &&& “Lowest priced car” for a particular car category at all service providers are compared for same date and time. &&& The cost for adding $100 to wallet is also included in the price, e.g. if you add $100 in your wallet and have $98 to spend, then calculation includes 2% payment fees per trip.&&&We are comparing 6hr trips from 3 pm to 9 pm on Wednesday, Jan 12th, 2022 for given car categories on all platforms. Similarly, the 24hr trips are compared from Wednesday, Jan 12th, 2022, 3 pm until Thursday, Jan 13th, 3 pm.',
      '6HourAssumption': '6-hour trips are from 3pm to 9pm on Jan 12th 2022',
      '24HourAssumption':
        '24-hour trips are from Jan 12th 3pm until Jan 13th 3pm',
    },
    isCategorySelected: false,
  },
  {
    id: 4,
    thumbUrl: imageSource.antiCompetition1.fullSUVCategories,
    categoryType: 'Full SUV',
    exampleCars: 'Toyota Alphard or similar',
    carCompanies: [
      {
        id: 0,
        companyName: 'Drive lah',
        companyLogo: imageSource.antiCompetition.drivelahSVG,
      },
      {
        id: 1,
        companyName: 'Shariot',
        companyLogo: imageSource.antiCompetition1.shariot,
      },
      {
        id: 2,
        companyName: 'Get go',
        companyLogo: imageSource.antiCompetition1.getGo,
      },
      {
        id: 3,
        companyName: 'Tribe car',
        companyLogo: imageSource.antiCompetition1.tribecar,
      },
    ],
    statistics: {
      '6to50': {
        stateConclusion: 'For this selection no cars are available with our competitors',
        numbers: [66, 0, 0, 0],
      },
      '6to75': {
        stateConclusion: 'For this selection no cars are available with our competitors',
        numbers: [70, 0, 0, 0],
      },
      '24to100': {
        stateConclusion: 'Closest competitor is 19% more expensive than Drive lah',
        numbers: [122, 0, 0, 149],
      },
      '24to150': {
        stateConclusion: 'Closest competitor is 19% more expensive than Drive lah',
        numbers: [131, 0, 0, 158],
      },
    },
    assumptions: {
      basicAssumptions:
        'Total Rental cost for a guest which includes fuel and insurance. Fuel is based on $0.18 / km. &&& The monthly subscription fee (if any) per trip is based on the average of 3 trips per month, e.g. if $8 per month, then $8/3 is added to the trip.  &&& “Lowest priced car” for a particular car category at all service providers are compared for same date and time. &&& The cost for adding $100 to wallet is also included in the price, e.g. if you add $100 in your wallet and have $98 to spend, then calculation includes 2% payment fees per trip.&&&We are comparing 6hr trips from 3 pm to 9 pm on Wednesday, Jan 12th, 2022 for given car categories on all platforms. Similarly, the 24hr trips are compared from Wednesday, Jan 12th, 2022, 3 pm until Thursday, Jan 13th, 3 pm.',
      '6HourAssumption': '6-hour trips are from 3pm to 9pm on Jan 12th 2022',
      '24HourAssumption':
        '24-hour trips are from Jan 12th 3pm until Jan 13th 3pm',
    },
    isCategorySelected: false,
  },
  {
    id: 5,
    thumbUrl: imageSource.antiCompetition1.mpvCategories,
    categoryType: 'MPV',
    exampleCars: 'Mazda 5, Honda Freed, Toyota Sienta, Toyota Prius Hybrid or similar',
    carCompanies: [
      {
        id: 0,
        companyName: 'Drive lah',
        companyLogo: imageSource.antiCompetition.drivelahSVG,
      },
      {
        id: 1,
        companyName: 'Shariot',
        companyLogo: imageSource.antiCompetition1.shariot,
      },
      {
        id: 2,
        companyName: 'Get go',
        companyLogo: imageSource.antiCompetition1.getGo,
      },
      {
        id: 3,
        companyName: 'Tribe car',
        companyLogo: imageSource.antiCompetition1.tribecar,
      },
    ],
    statistics: {
      '6to50': {
        stateConclusion: 'Closest competitor is 40% more expensive than Drive lah',
        numbers: [45, 64, 63, 0],
      },
      '6to75': {
        stateConclusion: 'Closest competitor is 39% more expensive than Drive lah',
        numbers: [49, 68, 71, 0],
      },
      '24to100': {
        stateConclusion: 'Closest competitor is 53% more expensive than Drive lah',
        numbers: [83, 201, 155, 127],
      },
      '24to150': {
        stateConclusion: 'Closest competitor is 48% more expensive than Drive lah',
        numbers: [92, 210, 173, 136],
      },
    },
    assumptions: {
      basicAssumptions:
        'Total Rental cost for a guest which includes fuel and insurance. Fuel is based on $0.18 / km. &&& The monthly subscription fee (if any) per trip is based on the average of 3 trips per month, e.g. if $8 per month, then $8/3 is added to the trip.  &&& “Lowest priced car” for a particular car category at all service providers are compared for same date and time. &&& The cost for adding $100 to wallet is also included in the price, e.g. if you add $100 in your wallet and have $98 to spend, then calculation includes 2% payment fees per trip.&&&We are comparing 6hr trips from 3 pm to 9 pm on Wednesday, Jan 12th, 2022 for given car categories on all platforms. Similarly, the 24hr trips are compared from Wednesday, Jan 12th, 2022, 3 pm until Thursday, Jan 13th, 3 pm.',
      '6HourAssumption': '6-hour trips are from 3pm to 9pm on Jan 12th 2022',
      '24HourAssumption':
        '24-hour trips are from Jan 12th 3pm until Jan 13th 3pm',
    },
    isCategorySelected: false,
  },
  {
    id: 6,
    thumbUrl: imageSource.antiCompetition1.wagonCategories,
    categoryType: 'Wagon',
    exampleCars: 'Toyota Wish or similar',
    carCompanies: [
      {
        id: 0,
        companyName: 'Drive lah',
        companyLogo: imageSource.antiCompetition.drivelahSVG,
      },
      {
        id: 1,
        companyName: 'Shariot',
        companyLogo: imageSource.antiCompetition1.shariot,
      },
      {
        id: 2,
        companyName: 'Get go',
        companyLogo: imageSource.antiCompetition1.getGo,
      },
      {
        id: 3,
        companyName: 'Tribe car',
        companyLogo: imageSource.antiCompetition1.tribecar,
      },
    ],
    statistics: {
      '6to50': {
        stateConclusion: 'Closest competitor is 4% more expensive than Drive lah',
        numbers: [45, 0, 0, 47],
      },
      '6to75': {
        stateConclusion: 'Closest competitor is 6% more expensive than Drive lah',
        numbers: [49, 0, 0, 52],
      },
      '24to100': {
        stateConclusion: 'Closest competitor is 7% more expensive than Drive lah',
        numbers: [83, 0, 0, 89],
      },
      '24to150': {
        stateConclusion: 'Closest competitor is 7% more expensive than Drive lah',
        numbers: [92, 0, 0, 98],
      },
    },
    assumptions: {
      basicAssumptions:
        'Total Rental cost for a guest which includes fuel and insurance. Fuel is based on $0.18 / km. &&& The monthly subscription fee (if any) per trip is based on the average of 3 trips per month, e.g. if $8 per month, then $8/3 is added to the trip.  &&& “Lowest priced car” for a particular car category at all service providers are compared for same date and time. &&& The cost for adding $100 to wallet is also included in the price, e.g. if you add $100 in your wallet and have $98 to spend, then calculation includes 2% payment fees per trip.&&&We are comparing 6hr trips from 3 pm to 9 pm on Wednesday, Jan 12th, 2022 for given car categories on all platforms. Similarly, the 24hr trips are compared from Wednesday, Jan 12th, 2022, 3 pm until Thursday, Jan 13th, 3 pm.',
      '6HourAssumption': '6-hour trips are from 3pm to 9pm on Jan 12th 2022',
      '24HourAssumption':
        '24-hour trips are from Jan 12th 3pm until Jan 13th 3pm',
    },
    isCategorySelected: false,
  },
];
