/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { memo, useState } from 'react';
import css from './SeoLandingPage.css';
import isEqual from 'lodash/isEqual';
import SeoLandingForm from './SeoLandingForm';
import InsuranceLandingModal from '../../components/InsuranceLandingModal/InsuranceLandingModal';
import imageSource from '../../util/imageSource';

const SeoLandingBannerRentalCategories = memo(
  ({ isMobileLayout, searchFilters, onManageDisableScrolling, selectedValues }) => {
    const [isInsuranceModalOpen, setInsuranceModalOpen] = useState(false);
    return (
      <div className={css.landingPageBanner}>
        <div className={css.landingContainer}>
          <div className={css.landingRow}>
            <div className={css.bannerSectionLeft}>
              <span className={css.landingPageBannerTitle}>
                Rent your <br /> neighbours car
              </span>
              {isMobileLayout ? (
                <div className={css.landingPageBannerImage}>
                  <img
                    className={css.featureImage}
                    src={(selectedValues && selectedValues.image) || imageSource.seoLandingPage.landingBannerImage}
                  />
                </div>
              ) : (
                ''
              )}
              <p>
                With Drive lah, you can rent from a huge variety of nearby cars at great value.
                Fully insured+.
              </p>
              <SeoLandingForm
                searchFilters={searchFilters}
                isMobileLayout={isMobileLayout}
                openInsuranceModal={() => setInsuranceModalOpen(true)}
                onManageDisableScrolling={onManageDisableScrolling}
              />
            </div>
            {!isMobileLayout ? (
              <div className={css.bannerSectionRight}>
                <div className={css.landingPageBannerImage}>
                  <img
                    className={css.featureImage}
                    src={(selectedValues && selectedValues.image) || imageSource.seoLandingPage.landingBannerImage}
                  />
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        <InsuranceLandingModal
          onManageDisableScrolling={onManageDisableScrolling}
          isModalOpen={isInsuranceModalOpen}
          setModalVisibility={setInsuranceModalOpen}
        />
      </div>
    );
  },
  (prev, next) => isEqual(prev, next)
);

export default SeoLandingBannerRentalCategories;
