import classNames from 'classnames';
import React from 'react';
import css from './LandingPageGo.css';

import { FormattedMessage } from 'react-intl';
import goLogo from '../../assets/logos/GoLogo.png';
import goLogo2x from '../../assets/logos/GoLogo@2x.png';
import { NamedLink } from '../../components';

const SectionHero = () => {
  return (
    <div className={css.sectionHero}>
      <img srcSet={`${goLogo} 1x, ${goLogo2x} 2x`} className={css.goLogo} />
      <div className={css.sectionHeroBg}>
        <h1 className={css.title}>
          <FormattedMessage id='LandingPageGo.SectionHero.title' />
        </h1>
        <div className={classNames(css.description, css.sectionHeroDescription)}>
          <FormattedMessage id='LandingPageGo.SectionHero.description' />
        </div>
        <NamedLink name='SearchPage' className={classNames(css.desktop, css.goToSearchButton)} to={{
          search:
            '?address=Singapore&bounds=1.4708809%2C104.04157989999999%2C1.216611%2C103.60650989999999&meta_isDrivelahGo=true',
        }}>
          <FormattedMessage id='LandingPageGo.goToSearchPage' />
        </NamedLink>
      </div>

      <NamedLink name='SearchPage' to={{
        search:
          '?address=Singapore&bounds=1.4708809%2C104.04157989999999%2C1.216611%2C103.60650989999999&meta_isDrivelahGo=true',
      }} className={classNames(css.mobile, css.goToSearchButton)}>
        <FormattedMessage id='LandingPageGo.goToSearchPage' />
      </NamedLink>
    </div>
  )
};

export default SectionHero;