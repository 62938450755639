/**
 * Renders non-reversal line items that are not listed in the
 * `LINE_ITEMS` array in util/types.js
 *
 * The line items are rendered so that the line item code is formatted to human
 * readable form and the line total is printed as price.
 *
 * If you require another kind of presentation for your line items, add them to
 * the `LINE_ITEMS` array in util/types.js and create a specific line item
 * component for them that can be used in the `BookingBreakdown` component.
 */
import Decimal from 'decimal.js';
import isEqual from 'lodash/isEqual';
import React, { memo } from 'react';
import { formatMoney } from '../../util/currency';
import { humanizeLineItemCode } from '../../util/data';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { LINE_ITEM_TRIP_PRICE_PEAK_DAYS, LINE_ITEMS, propTypes } from '../../util/types';
import css from './BookingBreakdown.css';
import Explanation from './Explaination';
import { get } from 'lodash'

function getUnique(arr, comp) {

  // store the comparison  values in array
const unique =  arr.map(e => e[comp])

// store the indexes of the unique objects
.map((e, i, final) => final.indexOf(e) === i && i)

// eliminate the false indexes & return unique objects
.filter((e) => arr[e]).map(e => arr[e]);

return unique;
}

const replacingAmount = (items, transaction) => {

  if (!get(transaction, "attributes.protectedData.pricing", false)) return items;

  const tripAmount = get(transaction, "attributes.protectedData.commissionObj.note.baseTripPrice", "");

  for(const item of items) {
    const formattedAmount = tripAmount;
    item.lineTotal.amount = formattedAmount * item.quantity;
    item.unitPrice.amount = formattedAmount;
  }
  
  return items;
}


const LineItemUnknownItemsMaybe = memo(
  props => {
    const { transaction, intl } = props;

    // resolve unknown non-reversal line items
    const rawItems = transaction && transaction.attributes.lineItems.filter(
      item => LINE_ITEMS.indexOf(item.code) === -1 && !item.reversal
    );

    const items = getUnique(rawItems, 'code')

    if (!items) return null;

    // Replacing the amount with the amount in the protected data if available
    replacingAmount(items, transaction);

    const { hourlyBooking } = transaction.attributes.protectedData || {};

    const unitMaybe = hourlyBooking
      ? 'hours'
      : transaction.attributes.processName === 'fuel-charging'
        ? 'kms'
        : 'days'
    ;
    return items.length > 0 ? (
      <React.Fragment>
        {items.map((item, i) => {
          const label = humanizeLineItemCode(item.code);
          const formattedTotal = formatMoney(intl, item.lineTotal, 1);
          const hasQuantity = !!item.quantity;
          const unitQuantity = hasQuantity
            ? item.quantity instanceof Decimal
              ? item.quantity.toString()
              : item.quantity
            : 0;
          const explainationMaybe =
            item.code === LINE_ITEM_TRIP_PRICE_PEAK_DAYS ? (
              <Explanation text="Peak day pricing is for select high demand days set by the host." />
            ) : null;
          return (
            <div key={`${i}-item.code`} className={css.lineItem}>
              <span className={css.itemLabel}>
                {label}
                {explainationMaybe}{' '}
                {hasQuantity ? (
                  <FormattedMessage
                    id={`LineItemUnknownItemsMaybe.${unitMaybe}.quantity`}
                    values={{
                      unitPrice: formatMoney(intl, item.unitPrice, 1),
                      quantity: unitQuantity,
                    }}
                  />
                ) : null}
              </span>

              <span className={css.itemValue}>{formattedTotal}</span>
            </div>
          );
        })}
      </React.Fragment>
    ) : null;
  },
  (prev, next) => isEqual(prev.transaction, next.transaction)
);

LineItemUnknownItemsMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemUnknownItemsMaybe;
