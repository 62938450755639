import React, { useEffect, useRef, useState } from 'react';
import styles from './AdditionalSingpassInfo.module.css';
import { Button, Form, IconCheckmark } from '../../components';
import UploadImageSvg from '../../assets/verificationPage/UploadImageSvg.svg';
import { uploadFiles } from '../../util/fileUpload';
import { updateProfile, uploadImage } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';
import { useDispatch, useSelector } from 'react-redux';

const AdditionalSingpassInfo = ({ singpassResult, currentUser, onSubmitLOSDocument, updateLosDocumentUrl, onSubmitProfileImageOnly }) => {

    const dispatch = useDispatch();

    const profileSettings = useSelector(state => state.ProfileSettingsPage);

    const isLOSNeeded = singpassResult.actionNeeded === 'sponsershipLetterUpload';

    const isProfilePicNeeded = currentUser.profileImage === null;

    const fileInputRef = useRef(null);
    const imageInputRef = useRef(null);


    const [losDocumentUrl, setLosDocumentUrl] = useState("");
    const [isUploading, setIsUploading] = useState(false);
    const [uploadedLOSName, setUploadedLOSName] = useState("");
    const [uploadLOSError, setUploadLOSError] = useState(false);
    const [uploadedImageInfo, setUploadedImageInfo] = useState({});
    const [selectedImage, setSelectedImage] = useState(null);
    const [isDisabled, setIsDisabled] = useState(true);

    useEffect(() => {
        if(window) {
           window.scrollTo(0, 0)
        }   
      }, [])      

    useEffect(() => {
        setUploadedImageInfo(profileSettings.image)
    }, [profileSettings]);

    useEffect(() => {
        if(isProfilePicNeeded && isLOSNeeded) setIsDisabled(!(selectedImage && losDocumentUrl));
        else if (isProfilePicNeeded || isLOSNeeded) setIsDisabled(!(selectedImage || losDocumentUrl));

    }, [selectedImage, losDocumentUrl, isProfilePicNeeded, isLOSNeeded]);

    const handleLOSUploadBtn = () => fileInputRef.current.click();

    const handleImageUploadBtn = () => imageInputRef.current.click();

    const handleSelectedLOS = (e) => {
        const selectedFile = e.target.files[0];

        let data = new FormData();
        data.append('file', e.target.files[0], e.target.files[0].name);
        data.append('userId', currentUser.id.uuid);
        data.append('userEmail', currentUser.attributes.email);

        uploadFiles(data)
            .then(res => {
                setIsUploading(true);
                return res.json();
            })
            .then(res_data => {
                setLosDocumentUrl(res_data.url);
                setIsUploading(false);
                setUploadedLOSName(selectedFile.name);
            })
            .catch(err => {
                console.log(err);
                setUploadLOSError(true);
            });

    }

    const handleSelectedImage = e => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setSelectedImage(reader.result);
            };
            reader.readAsDataURL(file);
            setUploadedImageInfo({});
        }

        const tempID = `${file.name}_${Date.now()}`
        console.log(tempID);

        dispatch(uploadImage({ id: tempID, file: file }));
    }

    const onSubmitBtnHandler = () => {
        if ((isProfilePicNeeded && selectedImage) && (isLOSNeeded && losDocumentUrl)) {
            dispatch(updateProfile({ profileImageId: uploadedImageInfo.imageId }));
            onSubmitLOSDocument();
            updateLosDocumentUrl(losDocumentUrl);
        }
        else if (isProfilePicNeeded && selectedImage) {
            dispatch(updateProfile({ profileImageId: uploadedImageInfo.imageId }));
            onSubmitProfileImageOnly();
        }
        else if (isLOSNeeded && losDocumentUrl) {
            onSubmitLOSDocument();
            updateLosDocumentUrl(losDocumentUrl);
        }
    }

    return (
        <>
            <div className={styles.outerContainer}>
                <div className={styles.sectionDescription}>
                    <p>We need a few more pieces of information from your end to complete your verification.</p>
                </div>
                {isProfilePicNeeded && (
                    <div className={styles.addProfileImage}>
                        <div>
                            <h3>Add profile picture</h3>
                            <p>You can change the photo by clicking on it.</p>
                        </div>
                        <div>
                            <div className={styles.imageCircle}>
                                {selectedImage && (
                                    <>
                                        <img src={selectedImage} alt='selected_image' width="100%" height="100%" style={{ borderRadius: "50%" }} />
                                    </>
                                )}
                                {!selectedImage && (
                                    <>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 32 32"><path id="user-circle-solid" d="M16,8A16,16,0,1,0,32,24,16,16,0,0,0,16,8Zm0,6.194a5.677,5.677,0,1,1-5.677,5.677A5.678,5.678,0,0,1,16,14.194Zm0,22.194a12.363,12.363,0,0,1-9.452-4.4A7.193,7.193,0,0,1,12.9,28.129a1.579,1.579,0,0,1,.458.071A8.542,8.542,0,0,0,16,28.645a8.51,8.51,0,0,0,2.639-.445,1.579,1.579,0,0,1,.458-.071,7.193,7.193,0,0,1,6.355,3.858A12.363,12.363,0,0,1,16,36.387Z" transform="translate(0 -8)" fill="#00A3AD"></path></svg>
                                    </>
                                )}
                            </div>
                            <div className={styles.imageCameraCircle} onClick={handleImageUploadBtn}>
                                <svg id="Icon_feather-camera" data-name="Icon feather-camera" xmlns="http://www.w3.org/2000/svg" width="22.64985" height="19.82745" viewBox="0 0 15.857 13.169">
                                    <path id="Path_1671" data-name="Path 1671" d="M14.478,16.669H2.379A1.881,1.881,0,0,1,.5,14.789V7.4A1.881,1.881,0,0,1,2.379,5.517h2.4L5.967,3.738A.535.535,0,0,1,6.412,3.5h4.033a.535.535,0,0,1,.445.238l1.186,1.778h2.4A1.881,1.881,0,0,1,16.357,7.4v7.394A1.881,1.881,0,0,1,14.478,16.669ZM2.379,6.586a.81.81,0,0,0-.81.81v7.394a.81.81,0,0,0,.81.81h12.1a.81.81,0,0,0,.81-.81V7.4a.81.81,0,0,0-.81-.81H11.789a.535.535,0,0,1-.445-.238L10.159,4.57H6.7L5.513,6.348a.535.535,0,0,1-.445.238Z" transform="translate(-0.5 -3.5)" fill="#18a3ad" />
                                    <path id="Path_1672" data-name="Path 1672" d="M14.223,12.5A3.223,3.223,0,1,1,11,15.723,3.227,3.227,0,0,1,14.223,12.5Zm0,5.377a2.154,2.154,0,1,0-2.154-2.154A2.156,2.156,0,0,0,14.223,17.877Z" transform="translate(-6.295 -8.467)" fill="#18a3ad" />
                                </svg>
                            </div>
                            <input
                                style={{ display: "none" }}
                                type="file"
                                accept="image/*"
                                onChange={handleSelectedImage}
                                ref={imageInputRef}
                            />
                        </div>
                    </div>
                )}
                {isLOSNeeded && (
                    <div className={styles.addLetterOfSponsorship}>
                        <h3>Letter of sponsorship</h3>
                        <p>Since you are on a dependent pass, we need a letter of sponsorship from the pass holder.</p>
                        <div className={styles.category}>
                            <Button
                                type="button"
                                className={styles.uploadButton}
                                children={
                                    <div className={styles.uploadButtonContent}>
                                        {uploadedLOSName || "Upload sponsorship letter"}
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        {losDocumentUrl ? (
                                            <IconCheckmark className={styles.checkmark} />
                                        ) : (
                                            <div className={styles.stepIconContainer}>
                                                <img src={UploadImageSvg} alt="Alt text" />
                                            </div>
                                        )}
                                    </div>
                                }
                                inProgress={isUploading}
                                onClick={handleLOSUploadBtn}
                            />
                            <input
                                style={{ display: "none" }}
                                type="file"
                                name="file-upload-insurance"
                                id="file-upload-insurance"
                                onChange={handleSelectedLOS}
                                ref={fileInputRef}
                            />
                        </div>
                        {uploadLOSError && (
                            <p style={{ color: "red" }}>Error in uploading the file, Please try again!!</p>
                        )}
                    </div>
                )}
                <div className={styles.submitButton}>
                    <button 
                    type="button"
                    disabled={isDisabled}
                    className={isDisabled ? styles.disabledBtn : styles.enabledBtn}
                    onClick={onSubmitBtnHandler}
                    >
                        Complete Verification
                    </button>
                </div>
            </div>
        </>
    );
};

export default AdditionalSingpassInfo;