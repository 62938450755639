import get from 'lodash/get';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import phoneIcon from '../../assets/phone-solid.svg';
import { userDisplayNameAsString } from '../../util/data';
import { txDontShowPrivateInfo, txIsExpired } from '../../util/transaction';
import { txIsExpiredLTF } from '../../util/transactionLongTermFirst';
import { Avatar42, Avatar48 } from '../Avatar/Avatar';
import css from './PartnerInfo.css';

const getCustomerPhoneNumber = transaction => {
  return (
    get(transaction, 'attributes.protectedData.guestPhoneNumber', null) ||
    get(transaction, 'attributes.protectedData.customerPhoneNumber', null)
  );
};

const getHostPhoneNumber = transaction => {
  return get(transaction, 'attributes.protectedData.hostPhoneNumber', null);
};

const PartnerInfo = ({ otherUser, currentUserRole, transaction, isMobileLayout, userTypeText, UserInfoForDisplay, isDelivery }) => {
  const isCustomer = currentUserRole === 'customer';
  const isProvider = currentUserRole === 'provider';

  // const isShowPartnerInfo =
  //   isProvider ||
  //   (!txIsRequested(transaction) &&
  //     !txIsDeclined(transaction) &&
  //     !txIsExpired(transaction) &&
  //     !txIsRequestedLTF(transaction) &&
  //     !txIsDeclinedLTF(transaction) &&
  //     !txIsExpiredLTF(transaction));

  const lastTx = transaction && transaction.attributes && transaction.attributes.lastTransition;

  const isShowPartnerInfo =
    !txIsExpired(transaction) &&
    !txDontShowPrivateInfo(transaction) &&
    !txIsExpiredLTF(transaction);

  const otherPhoneNumber = isCustomer
    ? getHostPhoneNumber(transaction)
    : getCustomerPhoneNumber(transaction);
  const listingRegistrationNumber = get(
    transaction,
    'listing.attributes.publicData.license_plate_number',
    null
  );
  return (
    <div className={css.partnerInfo}>
      <div className={css.avatarinfoContainer}>
        <div className={css.avatarWrapperProviderDesktop}>
          {isMobileLayout ? (
            <Avatar42 user={otherUser} className={css.avatarDesktop} />
          ) : (
            <Avatar48 user={otherUser} className={css.avatarDesktop} />
          )}
        </div>
        <div className={css.userNameContainer}>
          <p className={css.userTypeText}>
            {userTypeText ? (
              userTypeText
            ) : isCustomer ? (
              <FormattedMessage id="TransactionPanel.yourHost" />
            ) : (
              <FormattedMessage id="TransactionPanel.yourDriver" />
            )}
          </p>
          <div className={css.userNameText}>{userDisplayNameAsString(otherUser)}</div>
        </div>
      </div>
      {isCustomer && UserInfoForDisplay && UserInfoForDisplay.showHostPhoneNumber == "Yes" ? (
        <div className={css.contactContainer}>
          <img src={phoneIcon} alt="telephone" />
          {otherPhoneNumber && (
            <a className={css.userTelephone} href={`tel:${otherPhoneNumber}`}>
              {otherPhoneNumber}
            </a>
          )}
        </div>
      ) : null}
      {isProvider && UserInfoForDisplay && UserInfoForDisplay.guestPhoneNumberIcon == "Yes" ? (
        <div className={css.contactContainer}>
          <img src={phoneIcon} alt="telephone" />
          {otherPhoneNumber && (
            <a className={css.userTelephone} href={`tel:${otherPhoneNumber}`}>
              {otherPhoneNumber}
            </a>
          )}
        </div>
      ) : null}
      {/* {canCall(lastTx) ? (
        <div className={css.contactContainer}>
          <img src={phoneIcon} alt="telephone" />
          {otherPhoneNumber && (
            <a className={css.userTelephone} href={`tel:${otherPhoneNumber}`}>
              {otherPhoneNumber}
            </a>
          )}
        </div>
      ) : null} */}
    </div>
  );
};

export default PartnerInfo;
