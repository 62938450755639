import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import directionsIcon from '../../assets/tripPage/directions-solid.svg';
import { ExternalLink } from '../../components';

import { computedGuestLocationMessages, computedHostLocationMessages } from './transitionHelper';
import css from './TripPanel.css';

// Functional component as a helper to build AddressLinkMaybe
const AddressLinkMaybe = props => {
  const {
    className,
    rootClassName,
    location,
    geolocation,
    showAddress,
    isProvider,
    currentTransaction,
    timeZone,
    adress,
  } = props;
  const { address, building, floorUnit, postalCode } = location || {};
  const { lat, lng } = geolocation || {};
  const isDelivery =
    (currentTransaction &&
      currentTransaction.attributes &&
      currentTransaction.attributes.protectedData &&
      currentTransaction.attributes.protectedData.isDelivery) ||
    (currentTransaction &&
      currentTransaction.attributes &&
      currentTransaction.attributes.metadata &&
      currentTransaction.attributes.metadata.isDelivery);
  const customerLocation =
    currentTransaction &&
    currentTransaction.attributes &&
    currentTransaction.attributes.protectedData &&
    currentTransaction.attributes.protectedData.customerLocation &&
    currentTransaction.attributes.protectedData.customerLocation.selectedPlace;
  const hrefToGoogleMaps = isDelivery
    ? `https://maps.google.com/?q=${customerLocation.origin.lat},${customerLocation.origin.lng}`
    : geolocation
    ? `https://maps.google.com/?q=${lat},${lng}`
    : address
    ? `https://maps.google.com/?q=${encodeURIComponent(address)}`
    : null;

  const fullAddress = isDelivery
    ? `${customerLocation.floorUnit || ''} ${customerLocation.address ||
        ''} ${customerLocation.postalCode || ''}`
    : typeof building === 'string' && building.length > 0
    ? `${floorUnit} ${building}, ${address} ${postalCode}`
    : `${floorUnit} ${address} ${postalCode}`;

  const classes = classNames(rootClassName || css.address, className);
  // return showAddress && hrefToGoogleMaps ? (
  //   <div className={classes}>
  //     {isProvider ? (
  //       <p className={css.deliveryText}>
  //         <FormattedMessage id="TransactionPanel.deliveryText" />
  //       </p>
  //     ) : null}
  //     <div className={css.addressContainer}>
  //       <ExternalLink className={css.linkAddressColor} href={hrefToGoogleMaps}>
  //         <span>{building} - {floorUnit}</span>
  //         <span>{address}</span>
  //       </ExternalLink>
  //       <ExternalLink className={css.mapImgContainer} href={hrefToGoogleMaps}>
  //         <img src={directionsIcon} alt="direction" className={css.mapImageDirection} />
  //       </ExternalLink>
  //     </div>
  //   </div>
  // ) : (
  //   <div className={classes}>
  //     <p className={css.deliveryText}>
  //       <FormattedMessage id="TransactionPanel.locationText" />
  //     </p>
  //   </div>
  // );
  let lastTx = currentTransaction.attributes.lastTransition;
  const startTimeDiff = moment(currentTransaction.booking.attributes.displayStart)
    .tz(timeZone)
    .diff(moment(), 'minutes');

  const endTimeDiff = moment(currentTransaction.booking.attributes.displayEnd)
    .tz(timeZone)
    .diff(moment(), 'minutes');

  let result = {};
  if (isProvider) {
    result = computedHostLocationMessages(lastTx, isDelivery);
  } else {
    result = computedGuestLocationMessages(lastTx, startTimeDiff, endTimeDiff, isDelivery);
  }

  if (isDelivery) {
    return (
      <div className={classes}>
        <div className={css.addressContainer}>
          <ExternalLink className={css.linkAddressColor} href={hrefToGoogleMaps}>
            <span>{fullAddress}</span>
          </ExternalLink>
          <ExternalLink className={css.mapImgContainer} href={hrefToGoogleMaps}>
            <img src={directionsIcon} alt="direction" className={css.mapImageDirection} />
          </ExternalLink>
        </div>
        <span>{result.message == '' ? adress.mapText : result.message}</span>
      </div>
    );
  } else {
    return (
      <div className={classes}>
        {/* {isProvider ? (
          <p className={css.deliveryText}>
            <FormattedMessage id="TransactionPanel.deliveryText" />
          </p>
        ) : null} */}
        <div className={css.addressContainer}>
          {adress.showIcon || result.showIcon ? (
            <ExternalLink className={css.linkAddressColor} href={hrefToGoogleMaps}>
              {/* <span>
                {building} - {floorUnit}
              </span> */}
              <span>{fullAddress}</span>
            </ExternalLink>
          ) : (
            <ExternalLink className={css.linkAddressColor} href={hrefToGoogleMaps}>
              {/* <span>{building} - {floorUnit}</span> */}
              <span>{ adress.address}</span>
            </ExternalLink>
          )}
          {adress.showIcon || result.showIcon && (
            <ExternalLink className={css.mapImgContainer} href={hrefToGoogleMaps}>
              <img src={directionsIcon} alt="direction" className={css.mapImageDirection} />
            </ExternalLink>
          )}
        </div>
        <span>{result.message == '' ? adress.mapText : result.message}</span>
      </div>
    );
  }

  // return (
  //   <div className={classes}>
  //     {/* {isProvider ? (
  //       <p className={css.deliveryText}>
  //         <FormattedMessage id="TransactionPanel.deliveryText" />
  //       </p>
  //     ) : null} */}
  //     <div className={css.addressContainer}>
  //       {adress.showIcon ? (
  //         <ExternalLink className={css.linkAddressColor} href={hrefToGoogleMaps}>
  //           {/* <span>
  //             {building} - {floorUnit}
  //           </span> */}
  //           <span>{fullAddress}</span>
  //         </ExternalLink>
  //       ) : (
  //         <ExternalLink className={css.linkAddressColor} href={hrefToGoogleMaps}>
  //           {/* <span>{building} - {floorUnit}</span> */}
  //           <span>{adress.address}</span>
  //         </ExternalLink>
  //       )}
  //       {adress.showIcon && (
  //         <ExternalLink className={css.mapImgContainer} href={hrefToGoogleMaps}>
  //           <img src={directionsIcon} alt="direction" className={css.mapImageDirection} />
  //         </ExternalLink>
  //       )}
  //     </div>
  //     <span>{message==''? adress.mapText:message}</span>
  //   </div>
  // );
  // : (
  //   <div className={classes}>
  //     <p className={css.deliveryText}>
  //       <FormattedMessage id="TransactionPanel.locationText" />
  //     </p>
  //   </div>
  // );
};

export default AddressLinkMaybe;
