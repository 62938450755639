import React, { Component } from 'react';
import { object } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  autocompletePlaceSelected,
  autocompleteSearchRequired,
  composeValidators,
  required,
  validSGID,
} from '../../util/validators';
import {
  Button,
  FieldCustomNumberInput,
  FieldNumberInput,
  FieldTextInput,
  FieldUploadFile,
  Form,
  LocationAutocompleteInputField,
} from '../../components';

import css from './TransactionVerificationForm.css';
import setFiledTouched, { getAllPaths } from '../../util/setFiledTouched';
import {
  EVENT_GUEST_VERIFY_SUBMIT_DRIVER_FORM,
  EVENT_GUEST_VERIFY_UPLOAD_DRIVING_LICENSE,
  EVENT_GUEST_VERIFY_UPLOAD_NRIC,
  GUEST_VERIFY_DRIVER_SUBMIT_BUTTON_ID,
  UPLOAD_BACK_INTL_DRIVING_LICENSE_BUTTON_ID,
  UPLOAD_BACK_NRIC_BUTTON_ID,
  UPLOAD_BACK_SG_DRIVING_LICENSE_BUTTON_ID,
  UPLOAD_FRONT_INTL_DRIVING_LICENSE_BUTTON_ID,
  UPLOAD_FRONT_NRIC_BUTTON_ID,
  UPLOAD_FRONT_SG_DRIVING_LICENSE_BUTTON_ID,
} from '../../util/gtm/gtmConstants';
import { pushGTMGuestVerify } from '../../util/gtm/gtmHelpers';

const ACCEPT_IMAGES = 'image/*';

export class TransactionVerificationFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNotUpload: true,
      isUploading: false,
      sentVerification: false,
      disableUploadInternationalLicense: false,
      disableUploadDrivingLicense: false,
      nricFnFrontUrl: props.nricFnFrontUrl,
      nricFnBackUrl: props.nricFnFrontUrl,
      drivingLicenseFrontUrl: props.drivingLicenseFrontUrl,
      drivingLicenseBackUrl: props.drivingLicenseBackUrl,
      drivingLicenseStatusUrl: props.drivingLicenseStatusUrl,
      internationalDrivingLicenseFrontUrl: props.internationalDrivingLicenseFrontUrl,
      internationalDrivingLicenseBackUrl: props.internationalDrivingLicenseBackUrl,
      photo: props.photo,
      isEditLocation: false
    };
    this.setStateUploadData = this.setStateUploadData.bind(this);
    this.setUploadState = this.setUploadState.bind(this);
  }

  setUploadState(data) {
    this.setState({
      isUploading: data.isUploading,
      isNotUpload: data.isNotUpload
    })
  }

  setStateUploadData = (url, fileType) => {

    const nricFnStartWithS = this.state.initialValues && this.state.initialValues.nricFn && (this.state.initialValues.nricFn.startsWith('s') || this.state.initialValues.nricFn.startsWith('S'));
    if (fileType === "nric-fin-front") {
      this.props.setNricFnFrontUrl(url);
      this.setState({
        nricFnFrontUrl: url
      });
    }
    else if (fileType === "nric-fin-back") {
      this.props.setNricFnBackUrl(url);
      this.setState({
        nricFnBackUrl: url
      });
    }
    else if (fileType === "front-driving-license") {
      this.props.setDrivingLicenseFrontUrl(url);
      this.setState({
        drivingLicenseFrontUrl: url,
        disableUploadInternationalLicense: nricFnStartWithS
      });
    }
    else if (fileType === "back-driving-license") {
      this.props.setDrivingLicenseBackUrl(url);
      this.setState({
        drivingLicenseBackUrl: url,
        disableUploadInternationalLicense: nricFnStartWithS
      });
    }
    else if (fileType === "status-driving-license") {
      this.props.setDrivingLicenseStatusUrl(url);
      this.setState({
        drivingLicenseStatusUrl: url
      });
    }
    else if (fileType === "international-front-driving-license") {
      this.props.setInternationalDrivingLicenseFrontUrl(url);
      this.setState({
        internationalDrivingLicenseFrontUrl: url,
        disableUploadDrivingLicense: nricFnStartWithS
      });
    }
    else if (fileType === "international-back-driving-license") {
      this.props.setInternationalDrivingLicenseBackUrl(url);
      this.setState({
        internationalDrivingLicenseBackUrl: url,
        disableUploadDrivingLicense: nricFnStartWithS
      });
    }
    else if (fileType === "photo") {
      this.props.setPhoto(url);
      this.setState({
        photo: url
      });
    }
  }

  handleOpenEditLocation = (status) => {
    this.setState({
      isEditLocation: status
    })
  }

  pushEventGTM = (buttonId) => {
    const isNRIC = buttonId && buttonId.includes('nric');
    const event = isNRIC ? EVENT_GUEST_VERIFY_UPLOAD_NRIC : EVENT_GUEST_VERIFY_UPLOAD_DRIVING_LICENSE;
    // pushGTMGuestVerify({
    //   props: this.props,
    //   event,
    //   buttonId,
    //   pageURL: '/account/driving-verification',
    // });
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        mutators={{ setFiledTouched }}
        initialValues={this.state.initialValues ? this.state.initialValues : this.props.initialValues}
        render={fieldRenderProps => {
          const {
            form,
            className,
            disabled,
            handleSubmit,
            intl,
            invalid,
            currentUser,
            saveActionMsg,
            values,
            updateIdentityError
          } = fieldRenderProps;

          if (values.floorUnit) {
            values.floorUnit.replace(/[^\d|-]/g, '');
            if (values.floorUnit.length > 2 && values.floorUnit[2] !== '-') {
              let tempVal = values.floorUnit[0] + values.floorUnit[1] + '-';
              tempVal = tempVal + values.floorUnit.slice(2, values.floorUnit.length);
              values.floorUnit = tempVal;
            }
          }

          const nricFnStartWithS = this.state.initialValues && this.state.initialValues.nricFn && (this.state.initialValues.nricFn.startsWith('s') || this.state.initialValues.nricFn.startsWith('S'));

          const isVerified = !!currentUser && !!currentUser.id && currentUser.identityStatus && currentUser.identityStatus.guestVerified;
          const underVerify = !!currentUser && !!currentUser.id && currentUser.identityStatus && currentUser.identityStatus.guestUnderVerify;

          const nricFnMessage = intl.formatMessage({ id: 'EditListingVerificationForm.nricFn' });
          const nricFnPlaceholderMessage = intl.formatMessage({
            id: 'EditListingVerificationForm.nricFnPlaceholder',
          });
          const nricFnRequiredMessage = intl.formatMessage({
            id: 'EditListingVerificationForm.nricFnRequired',
          });
          const nricFnNotValidMessage = intl.formatMessage({
            id: 'EditListingVerificationForm.nricFnNotValid',
          });


          const accountNameMessage = intl.formatMessage({ id: 'EditListingVerificationForm.accountName' });
          const accountNamePlaceholderMessage = intl.formatMessage({
            id: 'EditListingVerificationForm.accountNamePlaceholder',
          });
          const accountNameRequiredMessage = intl.formatMessage({
            id: 'EditListingVerificationForm.accountNameRequired',
          });


          const bankNameMessage = intl.formatMessage({ id: 'EditListingVerificationForm.bankName' });
          const bankNamePlaceholderMessage = intl.formatMessage({
            id: 'EditListingVerificationForm.bankNamePlaceholder',
          });
          const bankNameRequiredMessage = intl.formatMessage({
            id: 'EditListingVerificationForm.bankNameRequired',
          });

          const bankAccountNumberMessage = intl.formatMessage({ id: 'EditListingVerificationForm.bankAccountNumber' });
          const bankAccountNumberPlaceholderMessage = intl.formatMessage({
            id: 'EditListingVerificationForm.bankAccountNumberPlaceholder',
          });
          const bankAccountNumberRequiredMessage = intl.formatMessage({
            id: 'EditListingVerificationForm.bankAccountNumberRequired',
          });

          const addressRequiredMessage = intl.formatMessage({
            id: 'EditListingLocationForm.addressRequired',
          });
          const addressNotRecognizedMessage = intl.formatMessage({
            id: 'EditListingLocationForm.addressNotRecognized',
          });

          const buildingMessage = intl.formatMessage({ id: 'EditListingLocationForm.building' });
          const buildingRequiredMessage = intl.formatMessage({id: 'EditListingLocationForm.buildingNameRequired' })
          const buildingPlaceholderMessage = intl.formatMessage({
            id: 'EditListingLocationForm.buildingPlaceholder',
          });

          const cityMessage = intl.formatMessage({ id: 'EditListingLocationForm.city' });
          const cityPlaceholderMessage = intl.formatMessage({
            id: 'EditListingLocationForm.cityPlaceholder',
          });

          const countryMessage = intl.formatMessage({ id: 'EditListingLocationForm.country' });
          const countryPlaceholderMessage = intl.formatMessage({
            id: 'EditListingLocationForm.countryPlaceholder',
          });

          const streetMessage = intl.formatMessage({ id: 'EditListingLocationForm.streetName' });
          const streetPlaceholderMessage = intl.formatMessage({
            id: 'EditListingLocationForm.streetNamePlaceholder',
          });

          const blockMessage = intl.formatMessage({ id: 'EditListingLocationForm.blockNo' });
          const blockPlaceholderMessage = intl.formatMessage({
            id: 'EditListingLocationForm.blockNoPlaceholder',
          });

          const floorMessage = intl.formatMessage({ id: 'EditListingLocationForm.floor' });
          const floorPlaceholderMessage = intl.formatMessage({
            id: 'EditListingLocationForm.floorPlaceholder',
          });

          const cityRequiredMessage = intl.formatMessage({
            id: 'EditListingLocationForm.cityRequired',
          });

          const countryRequiredMessage = intl.formatMessage({
            id: 'EditListingLocationForm.countryRequired',
          });

          const postalCodeLabel = intl.formatMessage({ id: 'PayoutDetailsForm.postalCodeLabel' });
          const postalCodePlaceholder = intl.formatMessage({
            id: 'PayoutDetailsForm.postalCodePlaceholder',
          });
          const postalCodeRequired = intl.formatMessage({
            id: 'PayoutDetailsForm.postalCodeRequired',
          });


          const submitInProgress = this.state.isUploading;
          const needUploadFileNricFront = !this.state.nricFnFrontUrl || this.state.nricFnFrontUrl === '';
          const needUploadFileNricBack = !this.state.nricFnBackUrl || this.state.nricFnBackUrl === '';
          const needUploadDrivingLicenseFront = !this.state.drivingLicenseFrontUrl || this.state.drivingLicenseFrontUrl === '';
          const needUploadDrivingLicenseBack = !this.state.drivingLicenseBackUrl || this.state.drivingLicenseBackUrl === '';
          const needUploadDrivingLicenseStatus = !this.state.drivingLicenseStatusUrl || this.state.drivingLicenseStatusUrl === '';
          const needUploadInternationalDrivingLicenseFront = !this.state.internationalDrivingLicenseFrontUrl || this.state.internationalDrivingLicenseFrontUrl === '';
          const needUploadInternationalDrivingLicenseBack = !this.state.internationalDrivingLicenseBackUrl || this.state.internationalDrivingLicenseBackUrl === '';
          const needUploadDriveLicenseLocal = needUploadDrivingLicenseFront ||
            needUploadDrivingLicenseBack;
          const needUploadDrivingLicenseInternational = needUploadInternationalDrivingLicenseFront ||
            needUploadInternationalDrivingLicenseBack;
          const needUploadDrivingLicense = !needUploadDriveLicenseLocal ? false : needUploadDrivingLicenseInternational ? true : false;
          const hasProfilePhoto = currentUser && currentUser.profileImage;
          const initialPhoto = this.props.photo ? this.props.photo : hasProfilePhoto ? currentUser.profileImage.attributes.variants['square-small2x'].url : null;
          const disablesPhoto = !!this.props.photo;
          const needUploadPhoto = !hasProfilePhoto && !this.props.photo;
          const needUploadAvatar = !this.state.photo || this.state.photo === '';

          let needUploadFile;
          if (hasProfilePhoto) {
            needUploadFile =
              (needUploadFileNricFront || needUploadFileNricBack ||
                needUploadDrivingLicense);
          }
          else {
            needUploadFile =
              (needUploadFileNricFront || needUploadFileNricBack ||
                needUploadDrivingLicense || needUploadAvatar);
          }

          const submitDisabled = invalid || disabled || submitInProgress || needUploadFile;

          const classes = classNames(css.root, className);
          const uploadDrivingLicense = (
            <div className={css.fieldSet}>
              <FieldUploadFile
                id='file-upload-front-driving-license'
                name='file-upload-front-driving-license'
                typeFile='front-driving-license'
                id2='file-upload-back-driving-license'
                name2='file-upload-back-driving-license'
                typeFile2='back-driving-license'
                label={() => 'Driving license (SG only)'}
                setStateUploadData={this.setStateUploadData}
                twoColumns={true}
                currentUser={currentUser}
                disable={this.state.disableUploadDrivingLicense}
                setUploadState={this.setUploadState}
                initialValues={[
                  this.props.drivingLicenseFrontUrl,
                  this.props.drivingLicenseBackUrl
                ]}
                className={(!this.state.drivingLicenseFrontUrl || !this.state.drivingLicenseBackUrl) ? css.bottomField : css.bottomField}
                buttonId={UPLOAD_FRONT_SG_DRIVING_LICENSE_BUTTON_ID}
                buttonId2={UPLOAD_BACK_SG_DRIVING_LICENSE_BUTTON_ID}
                pushEventGTM={this.pushEventGTM}
              />
              {/* {(!this.state.drivingLicenseFrontUrl || !this.state.drivingLicenseBackUrl) &&
                <p className={css.errorRequired}>
                  <FormattedMessage id="EditListingVerificationForm.requiredDrivingLicense" />
                </p>
              } */}

              {/* <FieldUploadFile
                id='file-upload-status-driving-license'
                name='file-upload-status-driving-license'
                label={() => 'Driving license status (only applicable if SG based Driving license)'}
                typeFile='status-driving-license'
                setStateUploadData={this.setStateUploadData}
                currentUser={currentUser}
                twoColumns={true}
                setUploadState={this.setUploadState}
                initialValues={[
                  this.props.drivingLicenseStatusUrl
                ]}
                className={(this.state.drivingLicenseFrontUrl || this.state.drivingLicenseBackUrl) && !this.state.drivingLicenseStatusUrl ? css.bottomField : css.null}
              />
              {(this.state.drivingLicenseFrontUrl || this.state.drivingLicenseBackUrl) && !this.state.drivingLicenseStatusUrl &&
                <p className={css.errorRequired}>
                  <FormattedMessage id="EditListingVerificationForm.requiredDrivingLicenseStatus"/>
                  </p>
              } */}

              <FieldUploadFile
                id='file-upload-international-front-driving-license'
                name='file-upload-international-front-driving-license'
                typeFile='international-front-driving-license'
                id2='file-upload-international-back-driving-license'
                name2='file-upload-international-back-driving-license'
                typeFile2='international-back-driving-license'
                label={() => 'International driving license copy (only applicable for foreigners)'}
                setStateUploadData={this.setStateUploadData}
                twoColumns={true}
                currentUser={currentUser}
                className={css.bottomField}
                setUploadState={this.setUploadState}
                disable={this.state.disableUploadInternationalLicense || !!nricFnStartWithS}
                initialValues={[
                  this.props.internationalDrivingLicenseFrontUrl,
                  this.props.internationalDrivingLicenseBackUrl,
                ]}
                buttonId={UPLOAD_FRONT_INTL_DRIVING_LICENSE_BUTTON_ID}
                buttonId2={UPLOAD_BACK_INTL_DRIVING_LICENSE_BUTTON_ID}
                pushEventGTM={this.pushEventGTM}
                pushEventGTM2={this.pushEventGTM}
              />
            </div>
          )

          const uploadLayout = (
            <div className={css.fieldSet}>
              <FieldTextInput
                id="nricFn"
                name="nricFn"
                className={css.inputField}
                type="textarea"
                label={nricFnMessage}
                disabled={this.props.initialValues && this.props.initialValues.nricFn}
                placeholder={nricFnPlaceholderMessage}
                validate={composeValidators(required(nricFnRequiredMessage), validSGID(nricFnNotValidMessage))}
              />

              <FieldUploadFile
                id='file-upload-front-nric-fin'
                name='file-upload-front-nric-fin'
                label={() => 'Front copy'}
                typeFile='nric-fin-front'
                setStateUploadData={this.setStateUploadData}
                currentUser={currentUser}
                setUploadState={this.setUploadState}
                initialValues={[
                  this.props.nricFnFrontUrl
                ]}
                buttonId={UPLOAD_FRONT_NRIC_BUTTON_ID}
                pushEventGTM={this.pushEventGTM}
              />

              <FieldUploadFile
                id='file-upload-back-nric-fin'
                name='file-upload-back-nric-fin'
                label={() => 'Back copy'}
                typeFile='nric-fin-back'
                setStateUploadData={this.setStateUploadData}
                currentUser={currentUser}
                className={css.bottomField}
                setUploadState={this.setUploadState}
                initialValues={[
                  this.props.nricFnBackUrl
                ]}
                buttonId={UPLOAD_BACK_NRIC_BUTTON_ID}
                pushEventGTM={this.pushEventGTM}
              />
            </div>
          );


          const bankLayout = (
            <div>
              <div className={css.fieldSet}>
                <FieldTextInput
                  id="accountName"
                  name="accountName"
                  className={css.inputField}
                  type="textarea"
                  disabled={this.props.initialValues && this.props.initialValues.accountName}
                  label={accountNameMessage}
                  placeholder={accountNamePlaceholderMessage}
                  validate={composeValidators(required(accountNameRequiredMessage))}
                />

                <FieldTextInput
                  id="bankName"
                  name="bankName"
                  className={css.inputField}
                  type="textarea"
                  disabled={this.props.initialValues && this.props.initialValues.bankName}
                  label={bankNameMessage}
                  placeholder={bankNamePlaceholderMessage}
                  validate={composeValidators(required(bankNameRequiredMessage))}
                />

                <FieldNumberInput
                  id="bankAccountNumber"
                  name="bankAccountNumber"
                  className={css.inputField}
                  type="textarea"
                  disabled={this.props.initialValues && this.props.initialValues.bankAccountNumber}
                  label={bankAccountNumberMessage}
                  placeholder={bankAccountNumberPlaceholderMessage}
                  validate={composeValidators(required(bankAccountNumberRequiredMessage))}
                />
              </div>
            </div>
          );

          const locationLayout = (
            <div className={css.fieldSet}>
              <div className={css.displayInlineContainer}>
                <FieldNumberInput
                  className={css.blockNo}
                  type="text"
                  name="blockNo"
                  id="blockNo"
                  disabled={this.props.initialValues && this.props.initialValues.blockNo}
                  label={blockMessage}
                  placeholder={blockPlaceholderMessage}
                />

                <LocationAutocompleteInputField
                  rootClassName={css.streetName}
                  className={css.streetName}
                  inputClassName={css.locationAutocompleteInput}
                  iconClassName={css.locationAutocompleteInputIcon}
                  predictionsClassName={css.predictionsRoot}
                  validClassName={css.validLocation}
                  name="location"
                  disabled={this.props.initialValues && this.props.initialValues.location && !this.state.isEditLocation}
                  label={streetMessage}
                  placeholder={streetPlaceholderMessage}
                  useDefaultPredictions={false}
                  format={v => v}
                  valueFromForm={values.location}
                  validate={composeValidators(
                    autocompleteSearchRequired(addressRequiredMessage),
                    autocompletePlaceSelected(addressNotRecognizedMessage)
                  )}
                />
                {this.props.initialValues && this.props.initialValues.location && !this.state.isEditLocation &&
                  <div
                    onClick={() => this.handleOpenEditLocation(true)}
                    className={css.editLocation}
                  >
                    Edit
                  </div>
                }
              </div>

              <div
                className={css.displayInlineContainer}
              >
                <FieldTextInput
                  className={css.building}
                  type="text"
                  name="building"
                  id="building"
                  disabled={this.props.initialValues && this.props.initialValues.building}
                  label={buildingMessage}
                  placeholder={buildingPlaceholderMessage}
                  validate={composeValidators(required(buildingRequiredMessage))}
                />

                <FieldCustomNumberInput
                  className={css.floor}
                  type="text"
                  name="floorUnit"
                  id="floorUnit"
                  disabled={this.props.initialValues && this.props.initialValues.floorUnit}
                  label={floorMessage}
                  placeholder={floorPlaceholderMessage}
                />
              </div>

              <FieldTextInput
                className={css.city}
                type="text"
                name="city"
                id="city"
                disabled={this.props.initialValues && this.props.initialValues.city}
                label={cityMessage}
                placeholder={cityPlaceholderMessage}
                validate={composeValidators(required(cityRequiredMessage))}
              />

              <FieldTextInput
                className={css.city}
                type="text"
                name="country"
                id="country"
                disabled={this.props.initialValues && this.props.initialValues.country}
                label={countryMessage}
                placeholder={countryPlaceholderMessage}
                validate={composeValidators(required(countryRequiredMessage))}
              />

              <FieldTextInput
                className={classNames(css.country, css.bottomField)}
                type="text"
                name="postalCode"
                id="postalCode"
                disabled={this.props.initialValues && this.props.initialValues.postalCode}
                label={postalCodeLabel}
                placeholder={postalCodePlaceholder}
                validate={composeValidators(required(postalCodeRequired))}
              />
            </div>
          );

          const imagesLayout = (
            <div className={css.fieldSet}>
              <p className={css.photoTip}>
                <FormattedMessage id="EditListingVerificationForm.photoTip" values={{ newline: (<br />) }} />
              </p>
              <FieldUploadFile
                id='file-upload-photo'
                name='file-upload-photo'
                label={(url) => (
                  <div className={css.photoIdentify}>
                    {url && <img className={css.frontalPhoto} src={url} />}
                  </div>
                )}
                disablesPhoto={disablesPhoto}
                typeFile='photo'
                setStateUploadData={this.setStateUploadData}
                currentUser={currentUser}
                isPhoto={true}
                className={css.bottomField}
                setUploadState={this.setUploadState}
                acceptType={ACCEPT_IMAGES}
                initialValues={[initialPhoto]}
              />
            </div>
          );

          // const needNationalDrivingLicense = !(this.props.drivingLicenseFrontUrl &&
          //   this.props.drivingLicenseBackUrl);
          // const needInternationalLicense = !(this.props.internationalDrivingLicenseFrontUrl &&
          //   this.props.internationalDrivingLicenseBackUrl);

          // const needDrivingLicense = !needNationalDrivingLicense ? false : needInternationalLicense ? true : false;
          const underVerifyLayout = (
            <Form
              className={classes}
              onSubmit={e => {
                if (!invalid) {
                  handleSubmit(e);
                  this.setUploadState({ isUploading: true });
                  this.setState({
                    sentVerification: true
                  });
                  // pushGTMGuestVerify({
                  //   props: this.props,
                  //   event: EVENT_GUEST_VERIFY_SUBMIT_DRIVER_FORM,
                  //   buttonId: GUEST_VERIFY_DRIVER_SUBMIT_BUTTON_ID,
                  // });
                }
                const paths = getAllPaths(form.getState().errors);
                paths.forEach(path => form.mutators.setFiledTouched(path, true));

              }}
            >
              <FormSpy
                onChange={(formState) => {
                  this.setState({
                    initialValues: formState.values
                  })
                }}
              />
              {/* <p className={css.thankYouTextFirstTime}>
                <FormattedMessage id="TransactionIdentityVerificationForm.thankYouTextFirstTime" values={{ newline: (<br />) }} />
              </p> */}

              <div className={css.uploadLayoutWrapper}>
                {uploadLayout}
                {uploadDrivingLicense}
              </div>

              {/* {bankLayout}  */}

              {imagesLayout}

              {locationLayout}

              <p className={css.readMore}>
                <FormattedMessage id="AddressDetailsForm.readMore" />
              </p>

              <div className={css.submitWrapper}>
                <Button
                  className={css.submitButton}
                  type="submit"
                  inProgress={updateIdentityError ? false : submitInProgress}
                  disabled={submitDisabled}
                  id={GUEST_VERIFY_DRIVER_SUBMIT_BUTTON_ID}
                >
                  {saveActionMsg}
                </Button>
              </div>
              {
                updateIdentityError && <div className={css.errorRequired}>{typeof updateIdentityError === 'string' ? updateIdentityError : updateIdentityError.message}</div>
              }
            </Form>
          );

          const alreadyVerifiedLayout = (
            <p className={css.thankYouTextFirstTime}>
              <FormattedMessage id="EditListingVerificationForm.thankYou" values={{ newline: (<br />) }} />
            </p>
          )
          const pendingVerifactionLayout = (
            <p className={css.thankYouTextFirstTime}>
              <FormattedMessage id="TransactionIdentityVerificationForm.thankYouTextFirstTimeVerified" values={{ newline: (<br />), username: 'Chuan Do Tan' }} />
            </p>);

          // const verifyLayout = isVerified ? alreadyVerifiedLayout : underVerify ? pendingVerifactionLayout : underVerifyLayout;
          const verifyLayout = underVerifyLayout;
          // const finalLayout = this.state.sentVerification ? pendingVerifactionLayout : verifyLayout

          return verifyLayout;
        }}
      />
    );
  }
}

TransactionVerificationFormComponent.defaultProps = {};

TransactionVerificationFormComponent.propTypes = {
  intl: intlShape.isRequired,
  saveActionMsg: object.isRequired,
};

export default compose(injectIntl)(TransactionVerificationFormComponent);
