import { bool } from 'prop-types';
import React from 'react';
import { formatMoney } from '../../util/currency';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { types as sdkTypes } from '../../util/sdkLoader';
import { LINE_ITEM_YOUNG_CUSTOMER_COMMISSION, propTypes } from '../../util/types';
import css from './BookingBreakdown.css';
import Explanation from './Explaination';

const { Money } = sdkTypes;

// Validate the assumption that the commission exists and the amount
// is zero or positive.
const isValidCommission = commissionLineItem => {
  return commissionLineItem.lineTotal instanceof Money && commissionLineItem.lineTotal.amount >= 0;
};

const LineItemYoungCustomerCommissionMaybe = props => {
  const { transaction, isCustomer, intl, showLines = true } = props;

  const customerCommissionLineItem = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_YOUNG_CUSTOMER_COMMISSION && !item.reversal
  );

  // If commission is passed it will be shown as a fee already reduces from the total price
  let commissionItem = null;

  if (isCustomer && customerCommissionLineItem) {
    if (!isValidCommission(customerCommissionLineItem)) {
      // eslint-disable-next-line no-console
      // console.error('invalid commission line item:', customerCommissionLineItem);
      // throw new Error('Commission should be present and the value should be zero or positive');
      return null;
    }

    const commission = customerCommissionLineItem.lineTotal;

    const formattedCommission = commission ? formatMoney(intl, commission, 1) : null;

    commissionItem = showLines ? (
      <div className={css.lineItem}>
        <span className={css.itemLabel}>
          <FormattedMessage id="BookingBreakdown.youngCommission" />
          <Explanation text="Fees for drivers under the age of 24 years or with driving experience of less than 2 years" />
        </span>
        <span className={css.itemValue}>{formattedCommission}</span>
      </div>
    ) : null;
  }

  return commissionItem;
};

LineItemYoungCustomerCommissionMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isCustomer: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemYoungCustomerCommissionMaybe;
