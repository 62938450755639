import React, { memo } from 'react';
import singaporeFlag from '../../assets/host-themed-landing-page/singapore-fleg.svg';
import css from './HostLandingPage.css';
import { Column, Container, Row } from '../../components/Grids/Grid';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

const CommunitySection = memo(() => {
  return (
    <div className={css.communitySection}>
      <Container>
        <Row className={css.sectionTitle}>
          <Column large={12}>
            <h2>
              Join the largest car rental and sharing community in Singapore <img src={singaporeFlag} alt="" />
            </h2>
          </Column>
        </Row>
        <Row>
          <Column large={3} medium={6} small={6}>
            <div className={css.statsWrapper}>
              <strong>
                <CountUp end={1500} redraw={true}>
                  {({ countUpRef, start }) => (
                    <VisibilitySensor onChange={start} delayedCall>
                      <span ref={countUpRef} />
                    </VisibilitySensor>
                  )}
                </CountUp>
                +
              </strong>
              <span>Verified host</span>
            </div>
          </Column>
          <Column large={3} medium={6} small={6}>
            <div className={css.statsWrapper}>
              <strong>
                <CountUp end={80} redraw={true}>
                  {({ countUpRef, start }) => (
                    <VisibilitySensor onChange={start} delayedCall>
                      <span ref={countUpRef} />
                    </VisibilitySensor>
                  )}
                </CountUp>
                K+
              </strong>
              <span>Bookings made</span>
            </div>
          </Column>
          <Column large={3} medium={6} small={6}>
            <div className={css.statsWrapper}>
              <strong>
                <CountUp end={620} redraw={true}>
                  {({ countUpRef, start }) => (
                    <VisibilitySensor onChange={start} delayedCall>
                      <span ref={countUpRef} />
                    </VisibilitySensor>
                  )}
                </CountUp>
                K+
              </strong>
              <span>KM runs</span>
            </div>
          </Column>
          <Column large={3} medium={6} small={6}>
            <div className={css.statsWrapper}>
              <strong>
                $
                <CountUp end={3} redraw={true}>
                  {({ countUpRef, start }) => (
                    <VisibilitySensor onChange={start} delayedCall>
                      <span ref={countUpRef} />
                    </VisibilitySensor>
                  )}
                </CountUp>
                M+
              </strong>
              <span>Earned by hosts</span>
            </div>
          </Column>
        </Row>
      </Container>
    </div>
  );
});

export default CommunitySection;
