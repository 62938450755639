import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import css from './GuestPromoGroundPlan.css';
import { SignUpPromoForm } from '../../forms';
import { useHistory, useLocation } from 'react-router-dom';
import { parse } from '../../util/urlHelpers';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { formatMoney } from '../../util/currency';
import { types } from '../../util/sdkLoader';
import config from '../../config';
import { CopyToClipboard, IconSpinner } from '../../components';
import { EVENT_BROWSE_CLICKED_SIGNUP_LANDING_PAGE } from '../../util/gtm/gtmConstants';
import { initiateEventViewedFromLandingPage } from '../../util/gtm/gtmHelpers';

const { Money } = types;

const CheckMark = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18.778}
      height={14}
      viewBox="0 0 18.778 14"
      {...props}
    >
      <path
        data-name="Path 332"
        d="M8.5 13.361a2.181 2.181 0 01-3.084 0L.639 8.586a2.18 2.18 0 013.084-3.083L6.57 8.349a.552.552 0 00.779 0L15.057.641a2.18 2.18 0 113.083 3.081z"
        fill="#fecd2a"
      />
    </svg>
  );
};

const Content = ({ className }) => {
  return (
    <div className={classNames(css.heroContent, className)}>
      <p className={classNames(css.heroPara, css.paragraphMobile)}>
        {/* Save up to 50% on rental */}
      </p>
      <div className={css.row}>
        <CheckMark />
        <div className={css.heroContentText}>Instant booking</div>
      </div>
      <div className={css.row}>
        <CheckMark />
        <div className={css.heroContentText}>Unlock with phone</div>
      </div>
      <div className={css.row}>
        <CheckMark />
        <div className={css.heroContentText}>Rent by the day or hour</div>
      </div>
      <div className={css.row}>
        <CheckMark />
        <div className={css.heroContentText}>24/7 roadside assistance</div>
      </div>
    </div>
  );
};

const SectionHero = ({ isScoot, signUpCodeAmount, loading }) => {
  const location = useLocation();
  const history = useHistory();
  const copyCodeRef = useRef()
  const [clipbordText, setClipbordText] = useState('');
  const { code } = parse(location.search);
  const currentUser = useSelector(state => state.user.currentUser);
  const intl = useIntl();
  const handleSubmit = ({ email, password }) => {
    const url = createResourceLocatorString('SignupPage', routeConfiguration(), {}, {});

    //
    initiateEventViewedFromLandingPage({
      event: EVENT_BROWSE_CLICKED_SIGNUP_LANDING_PAGE,
    });
    history.push(
      code
        ? {
            pathname: url,
            search: `?code=${code}`,
            state: {
              email,
              password,
              code,
            },
          }
        : {
            pathname: url,
            state: {
              email,
              password,
            },
          }
    );
  };

  const _handleCopyClipBoard = () => {
    if(copyCodeRef && copyCodeRef.current){
      const elementRef = copyCodeRef.current
      elementRef.select();
      elementRef.setSelectionRange(0, 99999);
      navigator.clipboard.writeText(elementRef.value);
    }
  }

  return (
    <div className={css.sectionHero}>
      <h1 className={css.heroTitle}>Rent your neighbour’s car</h1>
      <Content className={css.heroContentMobile} />
      <div className={css.formWrapper}>
        <div>
          <h1 className={classNames(css.heroTitle, css.heroTitleDesk)}>
            Rent your neighbour’s car
          </h1>
          {/* <p className={classNames(css.heroPara, css.paragraphDesktop)}>
            Save up to 50% on rental
          </p> */}
          <Content className={css.heroContentDesk} />
        </div>
        <div className={css.formWrapperDesktop}>
          {loading ? (
            <div className={css.loadingWrapper}>
              <IconSpinner className={css.loadingSpinner} />
            </div>
          ) : currentUser && currentUser.id ? (
            <>
              <div className={css.signUpTitle}>
                {signUpCodeAmount && signUpCodeAmount > 0 ? (
                  <FormattedMessage
                    id={
                      isScoot
                        ? 'SignUpPromoLandingPage.scootSchemaTitle'
                        : 'SignUpPromoLandingPage.schemaTitle'
                    }
                    values={{
                      amount: signUpCodeAmount
                        ? formatMoney(intl, new Money(signUpCodeAmount, config.currency))
                        : '$0',
                    }}
                  />
                ) : (
                  <FormattedMessage id={'SignUpPromoLandingPage.signUpNow'}  />
                )}
              </div>

              <p className={css.loginDesc}>
                This promo is only available to new users. You will have to logout and create a new
                account.
              </p>
            </>
          ) : (
            <>
              <div className={css.signUpTitle}>
                {signUpCodeAmount && signUpCodeAmount > 0 ? (
                  <FormattedMessage
                    id={
                      isScoot
                        ? 'SignUpPromoLandingPage.scootSchemaTitle'
                        : 'GuestSignupGroundPlan.signUpNow'
                    }
                    values={{
                      amount: signUpCodeAmount
                        ? formatMoney(intl, new Money(signUpCodeAmount, config.currency))
                        : '$0',
                    }}
                  />
                ) : (
                  // <FormattedMessage id={'GuestSignupGroundPlan.signUpNow'} />
                  // <FormattedHTMLMessage id="GuestSignupGroundPlan.signUpNow" />
                  <div className={css.signUpTitle}>
                    Use code <CopyToClipboard className={css.clipboardText} text="DLG50" /> and get 50% off* on your first drive.
                  </div>
                )}
              </div>
              <SignUpPromoForm hideTandC={true} onSubmit={handleSubmit} />
              <em className={css.remarks}>
                *Capped at $25. Click to copy the code and apply during checkout to avail the offer.
              </em>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SectionHero;
