import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withViewport } from '../../util/contextHelpers';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES, getRouteName } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import {
  Avatar,
  InlineTextButton,
  Logo,
  Menu,
  MenuContent,
  MenuItem,
  MenuLabel,
  NamedLink,
} from '../../components';
import { TopbarSearchForm } from '../../forms';
import css from './TopbarDesktop.css';
import {
  getEventsByBucket,
} from '../../util/gtm/gtmCreateProperties';
import { createConversionEvents } from '../../util/conversions/conversionsHelpers';
import { EVENT_CONVERSION_STARTED_LISTING } from '../../util/conversions/conversionsConstants';
import { triggerAnalyticsEvent } from '../../util/amplitudeMapEvents';
import { event_trigger_ids } from '../../util/analyticsConstants';

const MIN_TABLET_LAYOUT = 768;
const MAX_TABLET_LAYOUT = 1024;

const TopbarDesktop = props => {
  const {
    className,
    notApplySearch,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    viewport,
    onLoginOrSignupClick,
    history,
  } = props;
  const [mounted, setMounted] = useState(false);
  const [eventData, setEventData] = useState(null);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    // Function to fetch events and store in state
    const fetchEvents = async () => {
      try {
        const response = await getEventsByBucket('browsing');
        setEventData(response.data); // Store response in state
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };

    // Call fetchEvents when component mounts
    fetchEvents();
  }, []); // Empty dependency array ensures fetchEvents runs only once



  const handleLinkClick = (eventId, label) => {
    // console.log("Final event calling", eventId, label)
    triggerAnalyticsEvent({
      event_id: eventId,
      eventData,
      props: {
        ui: {
          button: label,
          page: 'LandingPageScreen'
        },
      },
      userId: currentUser && currentUser.id ? currentUser.id.uuid : null,
      user: currentUser
    });
  };

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const isTabletSmall = viewport.width >= MIN_TABLET_LAYOUT && viewport.width < MAX_TABLET_LAYOUT;

  const classes = classNames(rootClassName || css.root, className);

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
    />
  );

  const noSearchBox = <div className={css.noSearchBox}></div>;

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
      // onClick={() => pushGTMBrowse(EVENT_BROWSE_DASHBOARD)}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="InboxPage">
          {isTabletSmall && (
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('InboxPage'))}
              name="InboxPage"
              params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
              // onClick={() => pushGTMBrowse(EVENT_BROWSE_DASHBOARD)}
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.inbox" />
            </NamedLink>
          )}
        </MenuItem>
        <MenuItem key="ManageListingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.yourListingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            onClick={() =>  handleLinkClick(event_trigger_ids.PROFILE_CLICK_PROFILE, "Profile settings")}
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
            // onClick={() => pushGTMBrowse(EVENT_BROWSE_ACCOUNTSETTING)}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="referralPage">
          <NamedLink
            onClick={() =>  handleLinkClick(event_trigger_ids.REFER_VIEW_REFER_AND_EARN_PAGE, "Refer and earn")}
            className={classNames(css.referralPageLink, currentPageClass('ReferralPage'))}
            name="ReferralPage"
          >
            <span className={css.menuItemBorder}/>
            <FormattedMessage id="TopbarDesktop.referralPageLink"/>
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink
      name="SignupPage"
      className={css.signupLink}
      onClick={() => onLoginOrSignupClick('signup')}
    >
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink
      name="LoginPage"
      className={css.loginLink}
      onClick={() => onLoginOrSignupClick('login')}
    >
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );


  const learnMoreMenuAuth = !isAuthenticated ? null : (
    <Menu>
      <MenuLabel className={css.learnMoreLink}>
        <FormattedMessage id="TopbarDesktop.learnMoreMenu" />
      </MenuLabel>
      <MenuContent className={css.learnMoreMenuContent}>
        <MenuItem key="LMHowItWork">
          <NamedLink
            className={classNames(css.yourListingsLink, css.LMHowItWork)}
            name="HowItWorkPage"
            onClick={() =>  handleLinkClick(event_trigger_ids.BROWSE_CLICK_HOW_IT_WORKS, "How it works")}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.linkHowItWorks" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="LMRentingACar">
          <NamedLink
            className={classNames(css.yourListingsLink, css.LMRentingACar)}
            name="BecomeAGuestPage"
            // onClick={() => pushGTMBrowse(EVENT_BROWSE_RENTACAR)}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.linkRentingACar" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="LMSharingYourCar">
          <NamedLink
            className={classNames(css.yourListingsLink, css.LMSharingYourCar)}
            name="BecomeAHostPage"
            // onClick={() => pushGTMBrowse(EVENT_BROWSE_SHAREACAR)}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.linkSharingYourCar" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="LMTrustAndSafety">
          <NamedLink
            className={classNames(css.yourListingsLink, css.LMTrustAndSafety)}
            name="TrustAndSafetyPage"
            // onClick={() => pushGTMBrowse(EVENT_BROWSE_TRUSTANDSAFE)}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.linkTrustAndSafety" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="DriveLahGo">
          <NamedLink
            className={classNames(css.yourListingsLink, css.LMTrustAndSafety)}
            name="LandingPageGo"
            // onClick={() => pushGTMBrowse(EVENT_BROWSE_DRIVELAHGO)}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.drivelahGo" />
          </NamedLink>
        </MenuItem>
        {/* <MenuItem key="InstantBookingLandingPage">
          <NamedLink
            className={classNames(css.yourListingsLink, css.LMTrustAndSafety)}
            name="InstantBookingLandingPage"
          >
            <span className={css.menuItemBorder} />
            Instant booking
          </NamedLink>
        </MenuItem> */}
        {/*
        <MenuItem key="GrabHostYourCarLandingPage">

          <NamedLink
            className={classNames(css.yourListingsLink, css.LMTrustAndSafety)}
            name="GrabHostYourCarLandingPage"
          >
            <span className={css.menuItemBorder} />
            For Grab Drives
          </NamedLink>


        </MenuItem> */}
        {/* <MenuItem key="GrabRentACarLandingPage">
          <NamedLink
            className={classNames(css.yourListingsLink, css.LMTrustAndSafety)}
            name="GrabRentACarLandingPage"
          >
            <span className={css.menuItemBorder} />
            For Grab drivers
          </NamedLink>
        </MenuItem>
        <MenuItem key="GojekRentACarLandingPage">
          <NamedLink
            className={classNames(css.yourListingsLink, css.LMTrustAndSafety)}
            name="GojekRentACarLandingPage"
          >
            <span className={css.menuItemBorder} />
            For Gojek drivers
          </NamedLink>
        </MenuItem> */}
        <MenuItem key="GrabHostYourCarLandingPage">
          <NamedLink
            className={classNames(css.yourListingsLink, css.LMTrustAndSafety)}
            name="GrabHostYourCarLandingPage"
          >
            <span className={css.menuItemBorder} />
            Earn more for Grab drivers
          </NamedLink>
        </MenuItem>
        <MenuItem key="GojekHostYourCarLandingPage">
          <NamedLink
            className={classNames(css.yourListingsLink, css.LMTrustAndSafety)}
            name="GojekHostYourCarLandingPage"
          >
            <span className={css.menuItemBorder} />
            Earn more for Gojek drivers
          </NamedLink>
        </MenuItem>
        <MenuItem key="RentAPHV">
          <NamedLink
            className={classNames(css.yourListingsLink, css.LMTrustAndSafety)}
            name="RentAPHV"
          >
            <span className={css.menuItemBorder} />
            Rent a PHV
          </NamedLink>
        </MenuItem>
        {/* <MenuItem key="LMAntiVirusProgram">
          <NamedLink
            className={classNames(css.yourListingsLink, css.LMHowItWork)}
            name="CovidLandingPage"
            onClick={() => null}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.linkCovidProgram" />
          </NamedLink>
        </MenuItem> */}
        {/* <MenuItem key="MasterCardPromos">
          <NamedLink
            className={classNames(css.yourListingsLink, css.LMTrustAndSafety)}
            name="MasterCardLandingPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.promotions" />
          </NamedLink>
        </MenuItem> */}

        {/* <MenuItem key="LMInsurance">
          <NamedLink
            className={classNames(css.yourListingsLink, css.LMInsurance)}
            name="LandingPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.linkInsurance" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="LMSmartTechnology">
          <NamedLink
            className={classNames(css.yourListingsLink, css.LMSmartTechnology)}
            name="LandingPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.linkSmartTechnology" />
          </NamedLink>
        </MenuItem> */}
      </MenuContent>
    </Menu>
  );

  const learnMoreMenuNonAuth = isAuthenticated ? null : (
    <Menu>
      <MenuLabel className={css.learnMoreLink}>
        <FormattedMessage id="TopbarDesktop.learnMoreMenu" />
      </MenuLabel>
      <MenuContent className={css.learnMoreMenuContent}>
        <MenuItem key="LMHowItWork">
          <NamedLink
            className={classNames(css.yourListingsLink, css.LMHowItWork)}
            name="HowItWorkPage"
            onClick={() =>  handleLinkClick(event_trigger_ids.BROWSE_CLICK_HOW_IT_WORKS, "How it works")}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.linkHowItWorks" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="LMRentingACar">
          <NamedLink
            className={classNames(css.yourListingsLink, css.LMRentingACar)}
            name="BecomeAGuestPage"
            // onClick={() => pushGTMBrowse(EVENT_BROWSE_RENTACAR)}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.linkRentingACar" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="LMSharingYourCar">
          <NamedLink
            className={classNames(css.yourListingsLink, css.LMSharingYourCar)}
            name="BecomeAHostPage"
            // onClick={() => pushGTMBrowse(EVENT_BROWSE_SHAREACAR)}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.linkSharingYourCar" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="LMTrustAndSafety">
          <NamedLink
            className={classNames(css.yourListingsLink, css.LMTrustAndSafety)}
            name="TrustAndSafetyPage"
            // onClick={() => pushGTMBrowse(EVENT_BROWSE_TRUSTANDSAFE)}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.linkTrustAndSafety" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="DriveLahGo">
          <NamedLink
            className={classNames(css.yourListingsLink, css.LMTrustAndSafety)}
            name="LandingPageGo"
            // onClick={() => pushGTMBrowse(EVENT_BROWSE_DRIVELAHGO)}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.drivelahGo" />
          </NamedLink>
        </MenuItem>
        {/* <MenuItem key="InstantBookingLandingPage">
          <NamedLink
            className={classNames(css.yourListingsLink, css.LMTrustAndSafety)}
            name="InstantBookingLandingPage"
          >
            <span className={css.menuItemBorder} />
            Instant booking
          </NamedLink>
        </MenuItem> */}
        {/*
        <MenuItem key="GrabHostYourCarLandingPage">

          <NamedLink
            className={classNames(css.yourListingsLink, css.LMTrustAndSafety)}
            name="GrabHostYourCarLandingPage"
          >
            <span className={css.menuItemBorder} />
            For Grab Drives
          </NamedLink>


        </MenuItem> */}
        <MenuItem key="GrabHostYourCarLandingPage">
          <NamedLink
            className={classNames(css.yourListingsLink, css.LMTrustAndSafety)}
            name="GrabHostYourCarLandingPage"
          >
            <span className={css.menuItemBorder} />
            Earn more for Grab drivers
          </NamedLink>
        </MenuItem>
        <MenuItem key="GojekHostYourCarLandingPage">
          <NamedLink
            className={classNames(css.yourListingsLink, css.LMTrustAndSafety)}
            name="GojekHostYourCarLandingPage"
          >
            <span className={css.menuItemBorder} />
            Earn more for Gojek drivers
          </NamedLink>
        </MenuItem>
        <MenuItem key="RentAPHV">
          <NamedLink
            className={classNames(css.yourListingsLink, css.LMTrustAndSafety)}
            name="RentAPHV"
          >
            <span className={css.menuItemBorder} />
            Rent a PHV
          </NamedLink>
        </MenuItem>
        {/* <MenuItem key="LMAntiVirusProgram">
          <NamedLink
            className={classNames(css.yourListingsLink, css.LMHowItWork)}
            name="CovidLandingPage"
            onClick={() => null}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.linkCovidProgram" />
          </NamedLink>
        </MenuItem> */}
        {/* <MenuItem key="MasterCardPromos">
          <NamedLink
            className={classNames(css.yourListingsLink, css.LMTrustAndSafety)}
            name="MasterCardLandingPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.promotions" />
          </NamedLink>
        </MenuItem> */}

        {/* <MenuItem key="LMInsurance">
          <NamedLink
            className={classNames(css.yourListingsLink, css.LMInsurance)}
            name="LandingPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.linkInsurance" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="LMSmartTechnology">
          <NamedLink
            className={classNames(css.yourListingsLink, css.LMSmartTechnology)}
            name="LandingPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.linkSmartTechnology" />
          </NamedLink>
        </MenuItem> */}
      </MenuContent>
    </Menu>
  );

  async function listAcarEventCallent() {
    try {
      const response = await getEventsByBucket('listacar');
      // console.log('Events fetched successfully:', response.data);
      triggerAnalyticsEvent({
        event_id: event_trigger_ids.LISTACAR_CLICKED_LIST_A_CAR, 
        eventData: response.data, 
        props: {
          ui: {
            button: "Create Listing",
            page: 'ListingScreen'
          }
        },
        userId: currentUser && currentUser.id && currentUser.id.uuid
      });
    } catch (error) {
      console.log('Error fetching events:', error);
    }
  }

  return (
    <nav className={classes}>
      <NamedLink className={css.logoLink} name={`${getRouteName(history)}`}>
        <Logo
          format="desktop"
          className={css.logo}
          alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
        />
      </NamedLink>
      {!notApplySearch ? search : noSearchBox}
      {learnMoreMenuAuth}
      {learnMoreMenuNonAuth}
      <NamedLink
        className={css.createListingLink}
        name="NewListingPage"
        onClick={() => {

          //List a car analytics event called
          listAcarEventCallent()

          // initiateListACarEventFromTab({
          //   props,
          //   eventButtonParam: [EVENT_LISTACAR_CLICKED_LIST_A_CAR]
          // })
          // const properties = createRawPropertiesForGTM({
          //   props,
          // });
          // createConversionEvents(properties, EVENT_CONVERSION_STARTED_LISTING, 'click');
        }}
      >
        <span className={css.createListing}>
          <FormattedMessage id="TopbarDesktop.createListing" />
        </span>
      </NamedLink>
      {!isTabletSmall && inboxLink}
      {profileMenu}
      {signupLink}
      {loginLink}
    </nav>
  );
};

const { bool, func, object, number, string } = PropTypes;

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  location: object,
  onLoginOrSignupClick: func.isRequired,
  history: intlShape.isRequired,
};

export default withViewport(TopbarDesktop);
