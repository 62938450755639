import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './TermsOfService.css';

const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last updated: 3rd June 2019</p>

      <div style={{ color: '#00a3ad' }}>
        <p style={{textAlign: "center"}}><strong>TERMS OF USE&nbsp;</strong></p>
        <p>Thank you for your interest in Drive lah!</p>
        <p><strong>Please read these terms of use (“Terms”) carefully as they contain important information regarding your legal rights, remedies, and obligations. This includes in particular, various limitations, exclusions and an indemnity. If you do not agree to these Terms, we will not licence use of this Platform to you and you must not use our Platform.&nbsp;</strong></p>
        <p style={{textAlign: "center"}}><strong>INTRODUCTION&nbsp;</strong></p>
        <p>Drive Lah Pte. Ltd. (“<strong>Drive Lah</strong>”, “<strong>us</strong>” or “<strong>we</strong>”) provides an online platform which, amongst other things, connects vehicle owners with people seeking to book those vehicles through Drive lah’s website (<a href="https://www.drivelah.sg">https://www.drivelah.sg/</a>) and/or mobile applications (collectively, the “<strong>Platform</strong>”). Users who have registered for an account with us to provide their vehicles for rental are “<strong>Hosts</strong>”, and can include either private individual hosts (“<strong>Private Hosts</strong>”) and/or commercial hosts who are able to make insurance available to Guests who book the vehicles they have listed on the Platform (whether such insurance is provided by third party insurers or the hosts themselves) (“<strong>Commercial Hosts</strong>”). Users who have registered for an account to book these vehicles are “<strong>Guests</strong>”.&nbsp;</p>
        <p>By accessing or using the Platform, including by communicating with us or other Drive lah users, you agree to comply with, and be legally bound by, the provisions of these Terms, whether or not you become a registered user of the Platform. These Terms govern your access to and use of the Platform, our services offered via the Platform or otherwise by us and any other software provided by us in connection with any of the foregoing (collectively, the "<strong>Services</strong>").&nbsp;</p>
        <p>We license use of the Platform to you on the basis of this agreement. We do not sell the Platform to you and we remain the owner of the Platform at all times.&nbsp;</p>
        <p>1. &nbsp;&nbsp;&nbsp;&nbsp;<strong>TERMS OF USE&nbsp;</strong></p>
        {/* <ul> */}
          <li className={css.line}>1.1 &nbsp;The provisions set out in this agreement govern your access to and your use of the Platform&nbsp; and shall constitute a legally binding agreement between you and us.</li>
          <li className={css.line}>1.2 &nbsp;Subject to you agreeing to abide by the terms of this agreement, we hereby grant to you a personal, non-exclusive and non-transferable licence to use the Platform on the terms of this agreement.</li>
          <li className={css.line}>1.3 &nbsp;To access certain features of the Platform, you must provide us with certain mandatory and voluntary information as required for a successful registration. By registering an account with us and using the Platform, you confirm that:</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(a) &nbsp;you have read the terms set out in this agreement and agree to be bound by and comply with them; and</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(b) &nbsp;you are 22 years old and above.</li>
          <li className={css.line}>1.4 &nbsp;When you list or book a vehicle via the Platform, you will be required to provide us certain information or documents about yourself or your vehicle as may be necessary for us to verify your identity and that you and/or your vehicle meet our <strong>Eligibility Requirements&nbsp;</strong>(“<strong>Verification Information</strong>”). Drive lah may at our sole and absolute discretion, undertake screenings, checks and identity authentication processes on users and/or their vehicles based on such Verification Information before a user is registered as a “Verified Host” or “Verified Driver” (collectively, “<strong>Verified Users</strong>”) or a vehicle is considered to be a “Verified Vehicle”. Only Verified Users may list or rent a vehicle via the Platform, and only Verified Vehicles may be listed on the Platform. Drive lah may, at our sole and absolute discretion, accept or reject an application to become a Verified User or revoke any Verified User or Verified Vehicle status from time to time.</li>
          <li className={css.line}>1.5 &nbsp;In providing any Verification Information to us, you represent and warrant that all such information is true and accurate and remains true and accurate at all material times. In the event of any changes or updates to any Verification Information or other information you have provided to us upon registration for an account, you agree to notify us as soon as practicable.</li>
          <li className={css.line}>1.6 &nbsp;You are responsible for maintaining the confidentiality of your account, and you are responsible for all activities that occur under your account. We are not responsible for any loss, damage or liabilities arising as a result of or in connection with the wrongful, fraudulent or illegal use of your account.</li>
          <li className={css.line}>1.7 &nbsp;You may not transfer your account or user ID to any other party without our consent in writing.</li>
          <li className={css.line}>1.8 &nbsp;Unless otherwise agreed, you are not permitted to use, or submit any content to, our Platform or any of our affiliated websites (including social media pages) to advertise, promote or market any products or services of any third party or yourself.</li>
          <li className={css.line}>1.9 &nbsp;We reserve the right to change, modify, suspend or discontinue any portion of the Services, Platform or any other products, services, affiliated websites (including social media pages) and/or other software provided by us in connection with any of the foregoing at any time. You agree that access to or operation of any of the foregoing may from time to time be interrupted or encounter technical difficulties. Due to the nature of the internet, we do not guarantee the continuous and uninterrupted availability and accessibility of the Platform and/or the Services.</li>
          <li className={css.line}>1.10 &nbsp;These Terms refer to the following additional terms, which also apply to your use of the Platform and which form part of this agreement:</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(a) &nbsp;Our <strong>Privacy Policy&nbsp;</strong>sets out our policy concerning the collection, use and disclosure of your personal data in compliance with the Personal Data Protection Act 2012 (No. 26 of 2012) (“<strong>PDPA</strong>”). By using our Platform, you consent to our collection, use and disclosure of your personal in the manner set out in our Privacy Policy and you warrant that all personal data provided by you is accurate. Should you have any feedback or enquiries relating to your personal data, please contact our Data Protection Officer at <strong>[</strong><i><strong>compliance@drivelah.sg</strong></i><strong>].&nbsp;</strong></li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(b) &nbsp;Our <strong>Acceptable Use Policy&nbsp;</strong>sets out the permitted uses and prohibited uses of our Platform. When using our Platform, you must comply with this Acceptable Use Policy.</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(c) &nbsp;Our <strong>User Policies</strong>, which sets out the key policies applicable to all users of the Platform.</li>
          <li className={css.line}>1.11 &nbsp;By registering for an account with us, the following specific terms and conditions, which form part of this agreement, could also be applicable and binding on you:</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(a) &nbsp;<strong>Terms and Conditions for Guests</strong>, which sets out specific terms applicable to Guests; and/or</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(b) &nbsp;<strong>Terms and Conditions for Hosts</strong>, which sets out specific terms applicable to Hosts.</li>
          <li className={css.line}>1.12 &nbsp;We reserve the right to, without any notice, explanation or liability and in our sole discretion&nbsp; carry out any of the following measures:</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(a) &nbsp;refuse to allow you or limit or suspend your access to the Platform (including access to your account with us) at any time;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(b) &nbsp;remove or edit content (including content submitted by you) on the Platform or on any of our affiliated websites (including social media pages);</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(c) &nbsp;cancel any pending or confirmed bookings; or</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(d) &nbsp;cancel any Services provided to you.</li>
        {/* </ul> */}
        <p>2. <strong>SCOPE OF PLATFORM&nbsp;</strong></p>
        <ul>
          <li className={css.line}>2.1 &nbsp;Drive lah provides a platform which connects Hosts and Guests for the sharing of vehicles. We do not provide rental, leasing or private hire services. All rental services are provided directly by the Host to Guests, and by making or accepting a booking, Hosts and Guests are entering into a contract directly with each other.</li>
          <li className={css.line}>2.2 &nbsp;Drive lah does not make any representation, warranty or guarantee about:</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(a) &nbsp;the safety, quality, condition, cleanliness or roadworthiness of any vehicle listed on the Platform for rental; and</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(b) &nbsp;the quality of any listings, vehicles, hosts, guests, the Services, or any content or the accuracy, timeliness, truthfulness, completeness, or reliability of any content obtained through the Services.</li>
          <li className={css.line}>2.3 &nbsp;Any references to:</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(a) &nbsp;a user being “verified” (or any similar language) simply means that the user has passed our verification checks (if any), and is in no way an endorsement, certification or guarantee of that user’s identity or background, or that such user is trustworthy, safe or suitable for any particular purposes;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(b) &nbsp;a vehicle being “verified” (or any similar language) simply means that the vehicle has passed our verification checks (if any) and is not any warranty as to appearance, safety, quality or fitness for a particular purpose; and</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(c) &nbsp;to an image bring “verified” (or similar language) simply means that the image is a photographic representation of a particular vehicle at the time the photograph was taken, and is not an endorsement of any particular user or vehicle.</li>
          <li className={css.line}>2.4 &nbsp;Drive lah is not an insurance company and does not insure Hosts or Guests. When a Guest books a vehicle from a Private Host via the Platform, Drive lah makes insurance offered by a third party insurer available with respect to such vehicle. Drive lah does not make insurance available when Guests rent a vehicle from a Commercial Host, and it is the responsibility of the Commercial Host to ensure Guests who book their Vehicles have the minimum insurance coverage mandated by law, and to ensure that the terms of the insurance they make available to Guests is captured accurately and completely on their listing on the Platform. In the event there is any conflict between these Terms or your applicable insurance plan, the terms of your insurance plan shall prevail.</li>
          <li className={css.line}>2.5 &nbsp;Drive lah may, at our sole and absolute discretion, assist with dispute resolution between users. Should we choose to do so, you agree to cooperate and assist Drive lah in good faith, and to provide Drive lah with all requested information. Should you choose to submit any dispute to Drive lah to resolution and we agree to assist, you agree that any decision made by Drive lah shall be final, and you shall take such actions as may be directed by us following such decision.</li>
          <li className={css.line}>2.6 &nbsp;Drive lah may, from time to time, in its sole and absolute discretion offer special promotions to you, the terms of which shall be notified in writing to you and/or set out on the Platform. Any such promotions shall be subject to the specific terms and conditions specified in the relevant promotion.</li>
        </ul>
        <p>3. <strong>TRIP FEES&nbsp;</strong></p>
        <p>3.1 &nbsp;In consideration of the use of our Platform, Drive lah will charge service fees to Hosts and/or Guests (“<strong>Trip Fees</strong>”) in accordance with our prevailing <strong>Trip Fees Policy</strong>.&nbsp;</p>
        <ul>
          <li className={css.line}>3.2 &nbsp;We reserve the right to change the Trip Fees at any time, and will provide users with reasonable notice of any fee changes before they become effective. Such fee changes will not affect any bookings made prior to the effective date of the fee change.</li>
          <li className={css.line}>3.3 &nbsp;You are responsible for paying any Trip Fees owed to Drive lah, which, in the case of Trip Fees payable by the Host, will be deducted from any payment made by a Guest to a Host, or in the case of Trip Fees payable by Guests, included in the total payment collected from the Guest.</li>
          <li className={css.line}>3.4 &nbsp;You shall be liable to pay any incidental payments and fines to the relevant parties in accordance with our prevailing <strong>Fees &amp; Fines Policies&nbsp;</strong>and/or <strong>Late Return Policy</strong>.</li>
        </ul>
        <p>4. <strong>RESTRICTIONS&nbsp;</strong></p>
        <ul>
          <li className={css.line}>4.1 &nbsp;Except as expressly set out in this agreement or as permitted by any local law, you undertake&nbsp; not to:</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(a) &nbsp;violate or breach any applicable law or regulation in any relevant jurisdiction in your use of the Platform or the Services;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(b) &nbsp;reproduce, copy, modify, adapt, alter, translate, publish, display, communicate, transmit, sell, exploit or use the whole or any part of any Service, the Platform or any of the contents therein for any commercial or other purposes;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(c) &nbsp;dilute, tarnish or otherwise harm the “Drive lah” brand in any way, including through registering and/or using “Drive lah” or derivative terms in domain names, trade names, trademarks or other source identifiers, or registering and/or using domain names, trade names, trademarks or other source identifiers that closely imitate or are confusingly similar to Drive lah’s domains, trademarks, taglines, promotional campaigns;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(d) &nbsp;provide or submit any false or misleading information and/or documents to us, including (but not limited to) false names, date of birth, driver’s licence, payment methods, payment details, insurance information or other personal data;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(e) &nbsp;use the Platform or Services to contact users for purposes unrelated to any listing or the rental of a vehicle via the Platform;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(f) &nbsp;collect, use, disclose any personal data made available to you via the Platform for any purposes unrelated to the rental of a vehicle via the Platform, without obtaining the consent of the owner of such personal data and us;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(g) &nbsp;retain any personal data of users where the purpose for which that personal data was collected is no longer being served by retention of the personal data, or where retention is no longer necessary for legal or business purposes;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(h) &nbsp;distribute or post spam, unsolicited or bulk electronic communications, chain letters, or pyramid schemes;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(i) &nbsp;endeavor to circumvent a suspension, termination, or closure of your Drive lah Account, including, but not limited to, creating a new Drive lah Account to circumvent an account suspension or closure;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(j) &nbsp;use the Platform to request, make or accept a booking independent of the Platform to circumvent or otherwise avoid incurring Services Fees, or for any other reason; or</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(k) &nbsp;request, accept or make payment of any fees for the rental of a vehicle listed on the Platform through any means other than the payment services provided via the Platform.</li>
          <li className={css.line}>4.2 &nbsp;You agree and acknowledge that any failure to comply with the restrictions set out in this clause 4, the <strong>Acceptable Use Policy&nbsp;</strong>or any other provisions of this agreement, may result in Drive</li>
        </ul>
        <p>lah exercising its rights as set out in clause 1.12 of these Terms or in you losing your insurance coverage, in accordance with the terms of your applicable insurance policy.&nbsp;</p>
        <p>5. <strong>INTELLECTUAL PROPERTY RIGHTS&nbsp;</strong></p>
        <ul>
          <li className={css.line}>5.1 &nbsp;You acknowledge that all intellectual property rights in the Platform anywhere in the world belong to us, that rights in the Platform are licensed (not sold) to you, and that you have no rights in, or to, the Platform other than the right to use them in accordance with the terms of this agreement.</li>
          <li className={css.line}>5.2 &nbsp;You acknowledge that you have no right to have access to the Platform in source code form.</li>
          <li className={css.line}>5.3 &nbsp;You must not modify the paper or digital copies of any materials you have printed off or downloaded from the Platform in any way, and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text.</li>
          <li className={css.line}>5.4 &nbsp;Our status (and that of any identified contributors) as the authors of content on the Platform must always be acknowledged.</li>
          <li className={css.line}>5.5 &nbsp;You must not use any part of the content on our Platform for commercial purposes without obtaining a licence to do so from us or our licensors.</li>
          <li className={css.line}>5.6 &nbsp;If you print off, copy or download any content on our Platform in breach of these Terms, your right to use the Platform will cease immediately and you must, at our option, return or destroy any copies of the materials you have made.</li>
          <li className={css.line}>5.7 &nbsp;By uploading or making available any content on or through the Services, or through Drive lah advertising or promotional campaigns, you grant Drive lah a worldwide, irrevocable, perpetual, non-exclusive, transferable, royalty-free license, with the right to sublicense, to use, view, copy, adapt, modify, distribute, transfer, publicly display, publicly perform, transmit, stream, broadcast, access, view, and otherwise exploit such content to promote or market the Platform and/or the Services or for the purpose of carrying out our obligations in this agreement and any other purpose expressly set out in this agreement or otherwise agreed between us.</li>
          <li className={css.line}>5.8 &nbsp;Drive lah may offer Hosts the option of having photographers take photographs of their vehicles and/or hosts with their vehicles (“<strong>Images</strong>”), and may charge fees for these services at our sole and absolute discretion. You alone are responsible for using the Images in connection with your Drive lah listing and you warrant that you will cease using the Images if they no longer accurately represent your vehicle. You agree that Drive lah is the sole and exclusive owner (or exclusive licensee, as the case may be) of all right, title, and interest in all copyrights, trademark rights, and any and all other intellectual property rights, including right of publicity rights, worldwide, in the Images regardless of whether you include them in your listing, and you shall take no action to challenge or object to the validity of such rights or Drive lah’s ownership or registration thereof. You acknowledge that Drive lah may use the Images for advertising, marketing, commercial, and other business purposes in any media or platform, whether in relation to your listing or otherwise, without further notice or compensation. Further, you waive any and all rights to royalties or moral rights you may have in the Images. If you use the Drive lah photography program, you agree that you will not use the Images in connection with sharing your vehicle on any platform, website, or application other than Drive lah. At Drive lah’s request, you will execute documents and take such further acts as Drive lah may reasonably request to assist Drive lah to acquire, perfect, and maintain its intellectual property rights and other legal protection in the Images.</li>
        </ul>
        <p>6. <strong>UPLOADING CONTENT TO OUR PLATFORM&nbsp;</strong></p>
        <p>6.1 &nbsp;You irrevocably and unconditionally represent and warrant that any content uploaded by yourself to our Platform complies with our <strong>Acceptable Use Policy&nbsp;</strong>and the PDPA.&nbsp;</p>
        <ul>
          <li className={css.line}>6.2 &nbsp;We have the right to disclose your identity to any third party claiming that any content posted or uploaded by you to our Platform constitutes a violation of their rights under Singapore law.</li>
          <li className={css.line}>6.3 &nbsp;We will not be responsible, or liable to any third party, for the content or accuracy of any content or data uploaded by you or any other user of our Platform.</li>
          <li className={css.line}>6.4 &nbsp;We have the right to remove any posting you make on our Platform if, in our opinion, your post does not comply with the content standards set out in our Acceptable Use Policy.</li>
        </ul>
        <p>7. <strong>WARRANTY&nbsp;</strong></p>
        <ul>
          <li className={css.line}>7.1 &nbsp;While we make all efforts to maintain the accuracy of the information on the Platform, we provide the Services, Platform and all information, content, materials, products and other services included on or otherwise made available to you through the Services (the “<strong>Related Content</strong>”) on an “as is” and “as available” basis, unless otherwise specified in writing. We make no representations or warranties of any kind, express or implied, as to the operation of any of the foregoing, unless otherwise specified in writing. You expressly agree that your use of the Services and the Platform is at your sole risk.</li>
          <li className={css.line}>7.2 &nbsp;This agreement sets out the full extent of our obligations and liabilities in respect of the supply of the Services and the Platform. Except as expressly stated in this agreement, there are no conditions, warranties, representations or other terms, express or implied, that are binding on us. Any condition, warranty, representation or other term concerning the supply of the Services and the Platform which might otherwise be implied into, or incorporated in, this agreement whether by statute, common law or otherwise, is excluded to the fullest extent permitted by law, including, without limitation, implied warranties of merchantability and fitness for a particular purpose. We do not warrant that the Services, the Platform, the Related Content, or electronic communications sent by us are free of viruses or other harmful components.</li>
        </ul>
        <p>8. <strong>LIMITATION OF LIABILITY&nbsp;</strong></p>
        <ul>
          <li className={css.line}>8.1 &nbsp;Drive lah and our subsidiaries, holding companies or ultimate holding company and any subsidiary of our ultimate holding company (as such terms are defined in the Companies Act (Cap. 50) of Singapore) and our respective directors, officers, employees, agents and representatives, independent contractors, licensees, successors and assigns (collectively, our “<strong>Related Parties</strong>”) have no liability to you, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, arising under or in connection with this Agreement (including but not limited to the use of, or inability to use, the Services, the Platform or any other website or software) for:</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(a) &nbsp;loss of profits, sales, business, or revenue;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(b) &nbsp;business interruption;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(c) &nbsp;loss of anticipated savings;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(d) &nbsp;loss or corruption of data or information;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(e) &nbsp;cost of substitute products or services from the use of or inability to use the Services;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(f) &nbsp;loss of business opportunity, goodwill or reputation; or</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(g) &nbsp;any incidental, special, indirect or consequential loss or damage.</li>
          <li className={css.line}>8.2 &nbsp;The total liability of Drive lah and/or our Related Parties arising out of or in connection with this agreement and/or your use of the Platform and the Services, whether arising by statute, contract, tort or otherwise, will not exceed the lesser of:</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(a) &nbsp;the amount of Trip Fees you have paid to us in the twelve (12) month period immediately prior to the event giving rise to any such liability; or</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(b) &nbsp;S$1000.00.</li>
        </ul>
        <p>8.3 &nbsp; Nothing in this agreement shall limit or exclude any party’s liability for:&nbsp;</p>
        <ul>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(a) &nbsp;death or personal injury resulting from negligence;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(b) &nbsp;fraud; and/or</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(c) &nbsp;any other matter in respect of which any party is prohibited under applicable law from limiting or excluding liability.</li>
        </ul>
        <p>9. <strong>INDEMNITY&nbsp;</strong></p>
        <ul>
          <li className={css.line}>You agree to indemnify and hold us and our Related Parties harmless from and against all claims, losses, expenses, damages and costs (including, but not limited to, direct, incidental, consequential, exemplary and indirect damages), and reasonable legal fees, resulting from or arising out of your act, default or omission, whether in your use of the Platform, Services and/or any websites or software in relation thereto or otherwise, and whether under this agreement (including our Acceptable Use Policy), any laws or regulations or otherwise.</li>
        </ul>
        <p>10. <strong>OTHER IMPORTANT TERMS&nbsp;</strong></p>
        <ul>
          <li className={css.line}>10.1 &nbsp;Drive lah reserves the right, at our sole discretion, to modify these Terms from time to time. While we will use our reasonable endeavours to notify you of any material changes to these Terms, you agree and acknowledge that it shall be your responsibility to review the Terms regularly. By continuing to use the Platform following the effective date of any modifications to these Terms, you shall be deemed to have accepted such modification. If you do not agree to any modifications, you must not use the Platform.</li>
          <li className={css.line}>10.2 &nbsp;Drive lah may, in its sole discretion, round up or down amounts that are payable from or to hosts or guests to the nearest whole functional base unit in which the currency is denominated where permissible under applicable law.</li>
          <li className={css.line}>10.3 &nbsp;We may transfer our rights and obligations under this agreement to another organisation, but this will not affect your rights or obligations under this agreement. You may not, without our express consent in writing, transfer any of your rights or obligations under this agreement.</li>
          <li className={css.line}>10.4 &nbsp;This Agreement and any document expressly referred to in it constitutes the entire agreement between us regarding their subject matter, and supersedes and extinguishes all previous agreements, promises, assurances, warranties, representations and understandings between us, whether written or oral, relating to that subject matter. You agree that you shall have no remedies in respect of any statement, representation, assurance or warranty (whether made innocently or negligently) that is not set out in this agreement or any document expressly referred to in it. You agree that you shall have no claim for innocent or negligent misrepresentation or negligent misstatement based on any statement in this agreement or any document expressly referred to in it.</li>
          <li className={css.line}>10.5 &nbsp;If we fail to insist that you perform any of your obligations under this Agreement, or if we do not enforce our rights against you, or if we delay in doing so, that will not mean that we have waived our rights against you and will not mean that you do not have to comply with those obligations. If we do waive a default by you, we will only do so in writing, and that will not mean that we will automatically waive any later default by you.</li>
          <li className={css.line}>10.6 &nbsp;Each of the conditions of this agreement operates separately. If any court or competent authority decides that any of them are unlawful or unenforceable, the remaining conditions will remain in full force and effect.</li>
          <li className={css.line}>10.7 &nbsp;Drive lah may provide you with a translation of the English language version of these Terms or any Policies. In the event of any inconsistencies or discrepancies between the English language version and any other versions of the Terms and any Policies, the English language version shall prevail.</li>
          <li className={css.line}>10.8 &nbsp;This Agreement, its subject matter and its formation, and any other disputes or claims in connection therewith, are governed by Singapore law. In the event of any such disputes or claims in connection with this Agreement, you agree to first engage in good faith discussions with us to resolve such dispute or claim. If such dispute or claim is not resolved within sixty (60) days, the parties irrevocably submit to the exclusive jurisdiction of the courts of Singapore.</li>
        </ul>
        <p>
          <br />
        </p>
        <p style={{textAlign: "center"}}><strong>TERMS FOR GUESTS&nbsp;</strong></p>
        <p>These terms and conditions set out terms applicable only to Guests (“<strong>Terms for Guests</strong>”). These Terms for Guests form part of the Terms, and are part of the legally binding contract between Guests and Drive lah.&nbsp;</p>
        <p><strong>YOUR RESPONSIBILITIES&nbsp;</strong></p>
        <ul>
          <li className={css.line}>1. &nbsp;You shall at all times during the Trip Period, operate the vehicle that has been booked via the Platform (including any substitutions or replacements thereof, where you have agreed to such substitution or replacement) (the “<strong>Vehicle</strong>”) with reasonable skill, care, diligence and prudence and in compliance with any requirements and/or directions stipulated by us, including our policy on <strong>Host &amp; Guest Responsibilities</strong>.</li>
          <li className={css.line}>2. &nbsp;You are required at all times to operate the Vehicle safely and in compliance with all applicable laws, including without limitation, speed limits and prohibitions on impaired or distracted driving. Except as permitted under applicable law, you shall ensure that the driver of the Vehicle and all passengers shall wear seat belts at all times when the Vehicle is in operation.</li>
          <li className={css.line}>3. &nbsp;You shall be responsible for the care and maintenance of the Vehicle during the Trip Period. Before commencing any journey in the Vehicle, you are responsible for completing a visual inspection of the Vehicle as well as photographing any pre-existing defaults and/or damage and uploading such photographs to the Platform. Should you fail to do so, any loss or damage to the Vehicle discovered upon return of the Vehicle to the Host (“<strong>Drop Off</strong>”) may be attributed to you. Upon inspection, if you believe that the Vehicle is not safe to drive you must not operate the Vehicle and should report this to us immediately.</li>
          <li className={css.line}>4. &nbsp;You agree to abide by our <strong>Pick Up and Drop Off Policy&nbsp;</strong>with regards to collection of the Vehicle from the Host (“<strong>Pick Up</strong>”) and return of the Vehicle to the Host (“<strong>Drop Off</strong>”) at the designated location or such location as may be agreed with the Host (“<strong>Designated Location</strong>”).</li>
          <li className={css.line}>5. &nbsp;You must return the Vehicle to the Host:</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (a) &nbsp;within the stipulated time frame. In the event that you are delayed for any reason in returning the Vehicle, you must inform your Host as soon as practicable and the <strong>Late Return Policy&nbsp;</strong>shall apply;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (b) &nbsp;at the Designated Location;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (c) &nbsp;in the same condition as it was received, subject to our <strong>Wear &amp; Tear Policy</strong>; and</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (d) &nbsp;in accordance with our user policies, including in particular our <strong>Vehicle Cleanliness Policy</strong>, <strong>Fuel Policy&nbsp;</strong>and <strong>Mileage Policy</strong>.</li>
          <li className={css.line}>6. &nbsp;In the event that you wish to cancel a booking (whether such booking has been confirmed or not), you must do so as soon as practicable and our <strong>Trip Cancellation Policy&nbsp;</strong>shall apply. Upon cancellation, you shall not be entitled to any refund (in whole or in part) of the Trip Cost unless such refund is provided for in accordance with this policy. You may not cancel a booking during the Trip Period,</li>
          <li className={css.line}>7. &nbsp;For the purposes of this agreement, “<strong>Trip Period</strong>” shall mean the period between Pick Up and Drop Off at the Designated Locations. Unless otherwise stipulated by Drive lah, Trip Period shall be determined by the point at which both Host and Guest have indicated the Vehicle has been picked up or dropped off via the Platform. “<strong>Trip Cost</strong>” shall mean the total Trip Fees and trip price paid to the Host for rental of the Vehicle.</li>
          <li className={css.line}>8. &nbsp;Subject to the Terms and any applicable law, you undertake not to:</li>
        </ul>
        <ul>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (a) &nbsp;make any booking of a vehicle listed for rental on the Platform, and/or drive or allow the driving of, any Vehicle without the driver having a valid driver’s licence in the jurisdiction the Vehicle is being driven in;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (b) &nbsp;allow any person other than yourself, or a Verified Driver who has been notified to the Host as a driver, to drive the Vehicle you have booked;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (c) &nbsp;leave a Vehicle unlocked and unattended at any time during the Trip Period, unless expressly instructed to do so by Drive lah;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (d) &nbsp;use the Vehicle for any commercial or non-personal use (including but not limited to using the Vehicle as a private hire vehicle) unless expressly permitted to do so in writing by us;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (e) &nbsp;make any statements which you know or have reasonable cause to believe to be false or misrepresent any facts to the Host pertaining to the booking, use or operation of the Vehicle; or</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (f) &nbsp;carry out any other acts or omissions set out in our <strong>Prohibited Uses Policy</strong>.</li>
        </ul>
        <p><strong>INSURANCE&nbsp;</strong></p>
        <ul>
          <li className={css.line}>9. &nbsp; You must, at all times during the Trip Period be covered by insurance in respect of the Vehicle as required by applicable law.</li>
          <li className={css.line}>10. &nbsp; When you book a Vehicle from a Private Host, we will make insurance for the Trip Period offered by a third party insurer available to you via the Platform. By booking a Vehicle owned by a Private Host, you are agreeing to the terms and conditions of that third party insurer, which will be made available to you via the Platform.</li>
          <li className={css.line}>11. &nbsp; When you book a Vehicle from a Commercial Host, we will not make any insurance available to you via the Platform. Your Commercial Host may make insurance available to you, and you agree and acknowledge that Drive lah shall not bear any responsibility with regards to such insurance.</li>
        </ul>
        <p><strong>INCIDENT REPORTING&nbsp;</strong></p>
        <ul>
          <li className={css.line}>12. &nbsp; You must immediately report any incident, including any malfunction, breakdown or fault in the Vehicle, or any loss, damage, injury or otherwise arising out of or in connection with your use of the Vehicle (an “<strong>Incident</strong>”) to Drive lah at&nbsp;support@drivelah.sg&nbsp;or <strong>[+65 6432 8307]&nbsp;</strong>and follow all instructions given to you by Drive lah support staff.</li>
          <li className={css.line}>13. &nbsp; If you have booked a Vehicle from a Private Host, following such Incident, you must:</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (a) &nbsp;use your best endeavours to secure evidence from available witnesses or photographic, video or other documentary evidence of the Incident and any surrounding circumstances;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (b) &nbsp;obtain identification and insurance information from any third parties involved in the Incident (if any);</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (c) &nbsp;provide a written description of the Incident to us, and fill in any necessary forms and/or paperwork required by us;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (d) &nbsp;cooperate with any investigation into the Incident carried out by Drive lah, third party claims administrators or insurers, and/or any law enforcement, governmental authority or regulatory body;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (e) &nbsp;enter into any discussion regarding liability with any third party; and</li>
        </ul>
        <p>(f) unless expressly instructed otherwise by Drive lah staff, you must not continue to use the Vehicle.&nbsp;</p>
        <ul>
          <li className={css.line}>14. &nbsp; You agree and acknowledge that Drive lah may charge an administrative fee in the event of any Incident, in accordance with its <strong>Fees &amp; Fines Policy</strong>.</li>
          <li className={css.line}>15. &nbsp; If you book a Vehicle from a Commercial Host, you may be transferred to the relevant helpline or support desk manned by the Commercial Host or their service providers and should follow all instructions given to you, in keeping with their policies.</li>
          <li className={css.line}>16. &nbsp; In the event that you fail to report any Incident in accordance with these Terms, you acknowledge that you may risk losing any coverage in accordance with the terms of your applicable insurance policy.</li>
        </ul>
        <p><strong>LIABILITY&nbsp;</strong></p>
        <ul>
          <li className={css.line}>17. &nbsp; You remain responsible for the Vehicle and all acts and/or omissions carried out in respect of the Vehicle by yourself or any passengers or drivers of the Vehicle at all times during the Trip Period (“<strong>Acts or Omissions</strong>”). You shall be personally responsible for all costs arising out such Acts or Omissions, including but not limited to:</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (a) &nbsp;citations, fines and/or fees (including but not limited to road tolls, parking tickets, wheel clamp or towing fines, penalties and fines arising out of your breach of any of applicable laws) incurred in respect of a Vehicle during the Trip Period;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (b) &nbsp;any loss or damage to the Vehicle (including the cost of roadside assistance or vehicle recovery), or to any third party in excess of, or falling outside the scope of your insurance coverage; and</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (c) &nbsp;any other fees or fines incurred, in accordance with our <strong>Traffic Fines Policy, Fees and Fines Policy&nbsp;</strong>and/or any other user policy from time to time.</li>
          <li className={css.line}>18. &nbsp; You agree and acknowledge that Drive lah may require and hold a deposit from you when you make a booking (the “<strong>Deposit</strong>”). You hereby agree that Drive lah may make any necessary deductions from this Deposit in respect of any amounts owed by you to Drive lah or your Host. Where such amounts are owed to your Host, we shall transfer all amounts owing to your Host only upon successful deduction from you.</li>
          <li className={css.line}>19. &nbsp; Where your Deposit is insufficient to cover any amount owed to Drive lah and/or your Host from time to time, you authorise us to charge your credit card or other specified payment mode (“<strong>Payment Mode</strong>”) for such outstanding amount, and in any case you shall make payments of such outstanding amount to Drive lah within <strong>[seven (7) days]&nbsp;</strong>of receipt of notice from us that such outstanding amount is due and payable.</li>
          <li className={css.line}>20. &nbsp; Any payments made by you shall be made through your Payment Mode and in accordance with our <strong>Payment Terms and Policies&nbsp;</strong>without withholding or set-off on account of disputes, counterclaims or for any other reason whatsoever, and you agree to undertake any credit card charges, bank charges or transaction fees incurred on such payment (if any).</li>
        </ul>
        <p>
          <br />
        </p>
        <p style={{textAlign: "center"}}><strong>TERMS FOR HOSTS&nbsp;</strong></p>
        <p>These terms and conditions set out terms applicable only to Hosts (“<strong>Terms for Hosts</strong>”). These Terms for Hosts form part of the Terms, and are part of the legally binding contract between Hosts and Drive lah.&nbsp;</p>
        <p>Clauses in these Terms for Hosts under the section labelled “COMMERCIAL HOSTS” shall only be applicable to Commercial Hosts, and not Private Hosts.&nbsp;</p>
        <p><strong>YOUR RESPONSIBILITIES&nbsp;</strong></p>
        <ul>
          <li className={css.line}>1. &nbsp; As a Host, you hereby represent and warrant that:&nbsp;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (a) you are the registered owner of the Vehicle or have been duly authorised by the registered owner of the Vehicle to let the Vehicle for rent on the Platform</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (b) the registered owner of the Vehicle has the right to let the Vehicle for rent on the Platform</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (c) you are not prohibited by any law, order, rule regulation, or the terms of any contract, hire purchase or other agreement from leasing or renting out the Vehicle; and</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (d) the Vehicle meets all our <strong>Eligibility Requirements&nbsp;</strong>and you have complied with all of our user policies, including in particular our <strong>Maintenance Policy, Vehicle Cleanliness Policy&nbsp;</strong>and policy on <strong>Host &amp; Guest Responsibilities</strong>.</li>
          <li className={css.line}>2. &nbsp; Once a booking has been confirmed, at Pick Up you must deliver to the Guest a vehicle which is in keeping with the specifications set out in your listing on the Platform, at the specified collection date and time and at the Designated Location. You shall ensure the availability of such vehicle for the duration of the reservation period agreed between parties. In the event that you wish to cancel a booking, you must do so as soon as practicable and our <strong>Trip Cancellation Policy&nbsp;</strong>shall apply.</li>
          <li className={css.line}>3. &nbsp; To facilitate Pick Up and Drop Off, you must supply the designated locations for Pick Up and Drop Off accurately to Drive lah and the Guest. You agree to comply with our <strong>Pick Up and Drop Off Policy&nbsp;</strong>with regards to Pick Up and Drop Off of the Vehicle.</li>
          <li className={css.line}>4. &nbsp; You are required to regularly check all vehicles you list on the Platform for any defects in its operations or safety. You promise that all Vehicles are in safe and roadworthy condition, in good mechanical condition, and in full compliance with all applicable inspection and registration requirements. You agree to respond to any applicable recall or similar safety notices and to complete any recommended action before listing the vehicle on the Platform. In the event that Drive lah believes that your vehicle does not conform to reasonable standards, Drive lah may notify you as such and reserves the right, at its sole and absolute discretion to remove or decline listing your vehicle until its concerns have been resolved.</li>
          <li className={css.line}>5. &nbsp; You agree to comply with any and all applicable laws and regulations, including applicable registration and minimum insurance requirements for the Vehicle. As a Host, you must maintain your own insurance policy and meet any minimum insurance levels required by law. You also promise to maintain registration information and proof of insurance in the Vehicle during every Trip Period. Drive lah shall obtain insurance through a third party provider in respect of the Vehicle during the Trip Period. You agree to provide Drive lah with information regarding your policy’s coverage as may be requested. You must inform Drive lah promptly in the event there is any change in the information previously provided to us.</li>
          <li className={css.line}>6. &nbsp; Subject to the Terms and any applicable law, you undertake not to:</li>
        </ul>
        <ul>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (a) provide or submit any claim for loss or damage to the Vehicle with false and/or misleading information</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (b) &nbsp;publish any false, misleading or incomplete information or misrepresent any facts in any listing on the Platform or in any communications with Guests;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (c) &nbsp;act in breach of our <strong>Material Misrepresentation Policy&nbsp;</strong>or <strong>Vehicle mis- representation Policy</strong>; or</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (d) &nbsp;list any vehicle on the Platform as available for rental during any particular period if you have any reason to believe you will not be able to make the vehicle available during such period.</li>
        </ul>
        <p><strong>PRICING AND PAYMENT&nbsp;</strong></p>
        <ul>
          <li className={css.line}>7. &nbsp; Subject to our <strong>Pricing Guidelines</strong>, Hosts shall have the discretion to set and revise the pricing&nbsp; for the rental of your vehicle.</li>
          <li className={css.line}>8. &nbsp; Any Trip Fees payable by you to Drive lah shall be deducted from the payment made by a Guest before it is transferred to you. Drive lah may further make necessary deductions from such payment for any amounts that you owe to Drive lah or to Guests from time to time, including any amounts owed by you in accordance with our <strong>Fees &amp; Fines Policy&nbsp;</strong>or other user policy. Where there are any outstanding amounts following such deduction, you authorise Drive lah to charge your Payment Mode for any outstanding amounts, and in any case shall make payment within seven (7) days of receipt of notice that such outstanding amount is due and payable. All payments made to you or deducted or collected from you shall be made in accordance with our <strong>Payment Terms and Policies</strong>.</li>
          <li className={css.line}>9. &nbsp; Drive lah shall not be responsible for any delay in receipt of payment by you and reserves the right to delay or cancel any payment for purposes of preventing unlawful activity, fraud or breach of these Terms. You shall be responsible for ensuring the accuracy and completeness of information provided by yourself regarding payments.</li>
        </ul>
        <p><strong>LOSS, DAMAGE OR MISSING VEHICLE&nbsp;</strong></p>
        <ul>
          <li className={css.line}>10. &nbsp; Private Hosts who have reason to believe that a Guest has caused any loss and/or damage to their Vehicle are required to report such damage to us as soon as practicable, and in any case within twenty-four (24) hours of the end of the Trip Period. Upon Drop Off, it is your responsibility to inspect the Vehicle for any loss and/or damage.</li>
          <li className={css.line}>11. &nbsp; Where you are a Private Host and there has been loss and/or damage to your Vehicle, you agree to cooperate fully with any investigation carried out by Drive lah or any third party claims administrator or insurer, who shall determine whether such loss and/or damage has occurred during the Trip Period, and whether it is covered by insurance (subject to the terms of your applicable insurance policy).</li>
          <li className={css.line}>12. &nbsp; Private Hosts whose Vehicles go missing during the Trip Period, or whose Vehicles are not returned within [3] hours of the agreed Drop Off time without notice from the Guest must immediately contact us and follow all instructions given by Drive lah support staff. If you are instructed by Drive lah to file a police report, you must do so within twenty-four (24) hours of receiving those instructions.</li>
          <li className={css.line}>13. &nbsp; You acknowledge and agree that you are renting your vehicle to Guests via the Platform, at your sole risk. Drive lah shall not be liable for any missing vehicle or any loss and/or damage to the Vehicle or any personal property on or within the Vehicle during the Trip Period. We recommend that you remove all personal property from your vehicle before making it available for a reservation.</li>
          <li className={css.line}>14. &nbsp; You acknowledge and agree that normal wear and tear to the Vehicle, in accordance with our <strong>Wear and Tear Policies</strong>, is to be expected.</li>
        </ul>
        <p><strong>COMMERCIAL HOSTS&nbsp;</strong></p>
        <ul>
          <li className={css.line}>15. &nbsp; Clauses 10 to 14 above shall not be applicable to Commercial Hosts. Drive lah does not have any responsibility to Commercial Hosts in the event that there is loss and/or damage to their Vehicle, or if their Vehicle has gone missing.</li>
          <li className={css.line}>16. &nbsp; As a Commercial Host, you represent and warrant that:&nbsp; (a) where you are a car rental corporation, you are duly licenced in your jurisdiction(s) of operation in accordance with applicable law and have obtained all requisite permits, authorisations or consents for your operation;&nbsp; (b) you have at all times conducted your business in accordance with all applicable laws and regulations;&nbsp; (c) any individual who has access to your account on the Platform has the authority to act on behalf of you and to bind you in connection with the listing of vehicles on the Platform; and&nbsp; (d) you are able to make insurance available to Guests in respect of any Vehicles at all times.</li>
          <li className={css.line}>17. &nbsp; Each listing of a vehicle on the Platform must be accompanied by a letter of authorisation in the form notified to you. Any listing not accompanied by such letter of authorisation shall not be published on the Platform.</li>
          <li className={css.line}>18. &nbsp; You agree and acknowledge that:</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (a) &nbsp;you will make insurance (whether provided by yourself or a third party insurer) offering sufficient coverage in accordance with applicable law (“<strong>Commercial Insurance</strong>”), available to all Guests in respect of any Vehicle which they have booked from you, and ensure that any drivers of such Vehicle are covered by the minimum insurance required by law;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (b) &nbsp;you will be responsible for handling any Incident reports from Guests or that we redirect to you, and for making your policies and procedures following any such Incident available to Guests;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (c) &nbsp;you will not impose any additional fees, deposits, costs, and/or taxes, require the Guest to sign or complete any additional agreements and/or forms, or impose any other requirements not set out in your listing on Guests; and</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (d) &nbsp;you will make details of the Commercial Insurance available on your listing, including the name of the policy provider and the coverage provided under the insurance policy.</li>
          <li className={css.line}>19. &nbsp; We may from time to time, stipulate a pricing cap for any of your listings. You agree to abide by any such pricing cap, and that any listings which are not in compliance with such pricing cap may be removed by us.</li>
        </ul>
        <p>
          <br />
        </p>
        <p style={{textAlign: "center"}}><strong>PRIVACY POLICY</strong></p>
        <p><strong>
          DRIVE LAH PTE. LTD.&nbsp;</strong>("<strong>we</strong>” or “<strong>us</strong>”) is committed to protecting and respecting your privacy.&nbsp;</p>
        <ul>
          <li className={css.line}>1. &nbsp; This policy (together with our terms of use and any other documents referred to on it) sets out the basis on which any personal data (as defined in the Personal Data Protection Act 2012 (“<strong>PDPA</strong>”)) (the “<strong>Data</strong>”) we collect from you, or that you upload, will be used, disclosed or otherwise processed by us. Please read the following carefully to understand our views and practices regarding your Data and how we will treat it.</li>
          <li className={css.line}>2. &nbsp; By accepting our Terms of Use, you consent to the collection, use, disclosure and transfer of your Data in the manner and for the purposes set out below.</li>
          <li className={css.line}>3. &nbsp;Unless otherwise provided, defined terms in this privacy policy shall have the same meaning as in our Terms of Use.</li>
        </ul>
        <p><strong>INFORMATION WE MAY COLLECT&nbsp;</strong></p>
        <p>4. &nbsp; We may collect and process the following data from you:&nbsp;</p>
        <ul>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (a) &nbsp;information that you provide by filling in forms on our Platform, including information provided at the time of registering for an account on our Platform, subscribing to any services provided by us, posting material, reporting a problem with our Platform, or requesting further services;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (b) &nbsp;documents or images that you upload onto our Platform;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (c) &nbsp;details of transactions you carry out through our Platform;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (d) &nbsp;details of your visits to our Platform and the resources that you access;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (e) &nbsp;information collected whenever you use the Platform or leave the Platform running on any device;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (f) &nbsp;if you contact us, a record of that correspondence; and</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (g) &nbsp;payment details or other financial details that you upload onto our Platform to facilitate your transactions.</li>
        </ul>
        <p><strong>IP ADDRESSES&nbsp;</strong></p>
        <p>5. &nbsp; We may also collect and process information about your computer, including where available your IP address, operating system and browser type, for system administration and to report aggregate information to our advertisers. This is statistical data about our users’ browsing actions and patterns, and does not identify any individual.&nbsp;</p>
        <p><strong>COOKIES&nbsp;</strong></p>
        <p>6. &nbsp; Our Platform uses cookies to distinguish you from other users of our Platform. This helps us to provide you with a good experience when you browse our Platform and also allows us to improve our Platform.&nbsp;</p>
        <p><strong>WHERE WE STORE YOUR DATA&nbsp;</strong></p>
        <p>7. &nbsp; The Data that we collect from you may be transferred to, and stored at, a destination outside Singapore. It may also be processed by staff operating outside Singapore who work for us or for any of our third party service providers. Such staff maybe engaged in, among other things, the fulfilment of your services ordered by you, the processing of your payment details and the provision of support services. By submitting the Data, you agree to this transfer, storing or&nbsp;</p>
        <p>processing. We will take all steps reasonably necessary to ensure that your Data is treated securely and in accordance with this privacy policy.&nbsp;</p>
        <ul>
          <li className={css.line}>8. &nbsp; All information you provide to us is stored on our secure servers. Where we have given you (or where you have chosen) a password which enables you to access certain parts of our Platform, you are responsible for keeping this password confidential. We ask you not to share the password with anyone.</li>
          <li className={css.line}>9. &nbsp; Unfortunately, the transmission of information via the internet is not completely secure. Although we will do our best to protect your Data, we cannot guarantee the security of your Data transmitted to our Platform; any transmission is at your own risk. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorised access.</li>
        </ul>
        <p><strong>USES MADE OF THE INFORMATION&nbsp;</strong></p>
        <p>10. &nbsp; We may collect, use and process your Data for any or all of the following purposes:&nbsp;</p>
        <ul>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (a) &nbsp;to verify your identify and conduct any verification checks as we may, at our discretion, deem necessary prior to registering your account or providing your any services;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (b) &nbsp;to obtain further background information about yourself and/or your vehicle, including vehicle log, status of your driving licence, status of your driver improvement points, status of outstanding traffic offences and payment of fines and other background information;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (c) &nbsp;to ensure that content from our Platform is presented in the most effective manner for you and for your computer;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (d) &nbsp;to provide you with information, products or services that you request from us, and to otherwise carry out our obligations arising from any contracts entered into between you and us;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (e) &nbsp;to send you direct marketing and promotional communications and information on such offers and/or promotions, where you have consented to be contacted for such purposes;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (f) &nbsp;to allow you to participate in interactive features of our service, when you choose to do so;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (g) &nbsp;responding to, handling and processing queries, requests, applications, complaints and feedback from you;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (h) &nbsp;to notify you about changes to our services;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (i) &nbsp;processing payment or credit transactions;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (j) &nbsp;complying with any applicable laws, regulations, codes of practice, guidelines or rules, or to assist in law enforcement and investigations conducted by any governmental and/or regulatory authority;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (k) &nbsp;transmitting to any unaffiliated third parties including our third party service providers and agents, and relevant governmental and/or regulatory authorities, whether in Singapore or abroad, for the aforementioned purposes; and</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (l) &nbsp;any other incidental business purposes related to or in connection with the above.</li>
        </ul>
        <p><strong>DISCLOSURE OF YOUR INFORMATION&nbsp;</strong></p>
        <ul>
          <li className={css.line}>11. &nbsp; We may disclose your Data to other users to facilitate your use of our Platform and our provision of the Services.</li>
          <li className={css.line}>12. &nbsp; We may disclose your Data to any member of our group, which means our subsidiaries, holding company, our ultimate holding company and its subsidiaries (as such terms are defined in the Companies Act (Cap 50)) (our “<strong>Group</strong>”) to facilitate our business operation and administration.</li>
          <li className={css.line}>13. &nbsp; We may disclose your Data to third parties:</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (a) &nbsp;for the purposes of providing products or services that you request from us, fulfilling our obligations arising from any contracts entered into between you and us, processing payments in connection therewith or otherwise in connection with your use of our Platform;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (b) &nbsp;in the event that we sell or buy any business or assets, in which case we may disclose your Data to the prospective seller or buyer of such business or assets;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (c) &nbsp;if we or substantially all of our shares or assets are acquired by a third party, in which case personal data held by us about our customers will be one of the transferred assets;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (d) &nbsp;if we are under a duty to disclose or share your personal data, in order to comply with any legal obligation (including any direction from a governmental or regulatory body or law enforcement) or in order to enforce or apply our Terms of Use; or</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (e) &nbsp;in an emergency concerning your health and/or safety for the purposes of dealing with that emergency.</li>
        </ul>
        <p><strong>YOUR RIGHTS&nbsp;</strong></p>
        <p>14. &nbsp; Our Platform may, from time to time, contain links to and from the websites of our partner networks, advertisers and affiliates. If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for these policies. Please check these policies before you submit any Data to these websites.&nbsp;</p>
        <p><strong>CONSENT&nbsp;</strong></p>
        <ul>
          <li className={css.line}>15. &nbsp; By providing your Data to us, you consent to the collection, use and disclosure of your Data by us for the purposes set out in this privacy policy (“<strong>Purposes</strong>”).</li>
          <li className={css.line}>16. &nbsp; Please ensure that you speak to others before providing their Data to us, and that they have consent to you doing so. Kindly inform them that our collection, use and disclosure of their Data is solely for the Purposes. By providing such information to us, you represent and warrant that the person whose Data you have provided consents to the collection, use and disclosure of their Data for the Purposes.</li>
          <li className={css.line}>17. &nbsp; You may withdraw your consent and request us to stop using and/or disclosing your Data for any or all of the Purposes by submitting your request to us in writing. Should you withdraw your consent to the collection, use or disclosure of your Data, it may impact our ability to proceed with your transactions, agreements or interactions with us. Prior to you exercising your choice to withdraw your consent, we will inform you of the consequences of the withdrawal of your consent. Please note that your withdrawal of consent will not prevent us from exercising our legal rights (including any remedies, or undertaking any steps as we may be entitled to at law.</li>
        </ul>
        <p><strong>ACCESS AND CORRECTION&nbsp;</strong></p>
        <p>18. &nbsp; The PDPA gives you the right to access your Data. Your right of access can be exercised in accordance with the PDPA. Any access request may be subject to a fee of an administrative fee at our rates then in force to meet our costs in providing you with details of the information we hold about you.&nbsp;</p>
        <ul>
          <li className={css.line}>19. &nbsp; In the event that you wish to correct and/or update your Data in our records, you may inform us in writing of the same. In certain cases, Data may also be corrected or updated via the Platform.</li>
          <li className={css.line}>20. &nbsp; We will respond to requests regarding access and correction as soon as reasonably possible. Should we not be able to respond to your request within thirty (30) days after receiving your request, we will inform you in writing within thirty (30) days of the time by which we will be able to respond to your request. If we are unable to provide you with any personal data or to make a correction requested by you, we shall generally inform you of the reasons why we are unable to do so (except where we are not required to do so under the PDPA).</li>
        </ul>
        <p><strong>RETENTION OF PRIVACY POLICY&nbsp;</strong></p>
        <ul>
          <li className={css.line}>21. &nbsp; We may retain your Data for as long as it is necessary to fulfil the purpose for which it was collected, or as required or permitted by applicable laws. We will cease to retain your Data, or remove the means by which the data can be associated with you, as soon as it is reasonable to assume that such retention no longer serves the purpose for which the Data was collected, and is no longer necessary for legal or business purposes.</li>
          <li className={css.line}>22. &nbsp; Please note that there is still the possibility that your Data might be retained by other parties (e.g. other users of the Platform) through various means (e.g. photos, screen captures). We do not authorise retention of your Data for purposes unrelated to the use of the Platform and/or when such Data no longer serves the purpose for which it was collected or is no longer necessary for legal or business purposes (“<strong>Unauthorised Uses</strong>”). To the fullest extent permitted by applicable law, we shall not be liable for retention of your Data by other parties for Unauthorised Uses.</li>
        </ul>
        <p><strong>CHANGES TO OUR PRIVACY POLICY&nbsp;</strong></p>
        <p>23. &nbsp; Any changes we may make to our privacy policy in the future will be posted on this page and, where appropriate, notified to you by us.&nbsp;</p>
        <p><strong>CONTACT&nbsp;</strong></p>
        <p>24. &nbsp; Questions, comments and requests regarding this privacy policy are welcomed and should be addressed to our Data Protection Officer at <strong>[</strong><i><strong>compliance@drivelah.sg</strong></i><strong>]</strong>.&nbsp;</p>
        <p>
          <br />
        </p>
        <p style={{textAlign: "center"}}><strong>ACCEPTABLE USE POLICY&nbsp;</strong></p>
        <ul>
          <li className={css.line}>1. &nbsp; This acceptable use policy sets out the terms between you and <strong>DRIVE LAH PTE. LTD.&nbsp;</strong>(“<strong>we</strong>” or “<strong>us</strong>”) under which you may access our webpage,&nbsp;<a href="https://www.drivelah.sg">https://www.drivelah.sg/</a>&nbsp;and/or mobile application (the “<strong>Platform</strong>”). This acceptable use policy applies to all users of, and visitors to, our Platform.</li>
          <li className={css.line}>2. &nbsp; Your use of our Platform means that you accept, and agree to abide by, all the policies in this acceptable use policy, which form part of and supplement our Terms of Use for our Platform.</li>
          <li className={css.line}>3. &nbsp; Unless otherwise provided, defined terms in this Acceptable Use Policy shall have the same meaning as in the Terms of Use.</li>
        </ul>
        <p><strong>PROHIBITED USES&nbsp;</strong></p>
        <ul>
          <li className={css.line}>4. &nbsp; You may use our Platform only for lawful purposes. You may not use our Platform:</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;(a) &nbsp;in any way that breaches any applicable local or international laws or regulations;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;(b) &nbsp;in any way that is unlawful or fraudulent, or has any unlawful or fraudulent purpose or effect; and</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;(c) &nbsp;to send, knowingly receive, upload, download, use or re-use any material which does not comply with our content standards as set out in our prevailing Terms as amended from time to time.</li>
          <li className={css.line}>5. &nbsp; You also agree not to:</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;(a) &nbsp;reproduce, duplicate, copy or re-sell any part of our Platform in contravention of the provisions of our Terms;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;(b) &nbsp;access without authority, interfere with, damage or disrupt:</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(i) &nbsp;any part of our Platform;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(ii) &nbsp;any equipment or network on which our Platform is stored;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(iii) &nbsp;any software used in the provision of our Platform; or</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(iv) &nbsp;any equipment or network or software owned or used by any third party;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;(c) &nbsp;distribute viruses or any other technologies such as cancel bots, Trojan horses, harmful code, flood pings, denial-of-service attacks, backdoors, packet or IP spoofing, forged routing or e-mail address information, or similar methods or technology that may disrupt or interfere with the operation or provision of our Services, or harm Drive lah or the interests or property of others;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;(d) &nbsp;bypass robot exclusion headers, interfere with the working of the Services, or impose an unreasonable or disproportionately large load on our infrastructure;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;(e) &nbsp;systematically retrieve data or other content from our Services to create or compile, directly or indirectly, in single or multiple downloads, a collection, compilation, database, directory, or the like, whether by manual methods, or through the use of bots, crawlers, spiders, or otherwise;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;(f) &nbsp;use display mirroring or framing the Services or any individual element within the Services, Drive lah’s name, any Drive lah trademark, logo, or other proprietary information, or the layout and design of any page or form contained on a page in the Services, without Drive lah’s express written consent;</li>
        </ul>
        <ul>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;(g) &nbsp;access, tamper with, or use non-public areas of the Services, Drive lah’s computer systems, or the technical delivery systems of Drive lah’s providers;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;(h) &nbsp;attempt to probe, scan, or test the vulnerability of any of Drive lah’s system or network or breach any security or authentication measures;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;(i) &nbsp;avoid, bypass, remove, deactivate, impair, descramble, or otherwise circumvent any technological measure implemented by Drive lah or any of Drive lah’s providers or any other third party (including another user) to protect the Services;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;(j) &nbsp;forge any TCP/IP packet header or any part of the header information in any email or newsgroup posting, or in any way use the Services to send altered, deceptive, or false source-identifying information; or</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;(k) &nbsp;decipher, decompile, disassemble, or reverse engineer any of the software used to provide the Services (or attempting to do so).</li>
        </ul>
        <p><strong>CONTENT STANDARDS&nbsp;</strong></p>
        <ul>
          <li className={css.line}>6, &nbsp;These content standards apply to any and all material which you upload on our Platform, including for the avoidance of doubt, any reviews or comments submitted by you (“<strong>Contributions</strong>”).</li>
          <li className={css.line}>7. &nbsp;You must comply with the spirit of the following standards as well as the letter. The standards apply to each part of any Contribution as well as to its whole.</li>
        </ul>
        <p>8. &nbsp;Contributions must:&nbsp;</p>
        <ul>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (a) &nbsp;comply with Singapore law, including but not limited to the PDPA, and the laws of any country from which they are posted; and</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (b) &nbsp;be placed in the correct and appropriate categories.</li>
        </ul>
        <ul>
          <li className={css.line}>9. &nbsp;You shall be responsible for the authenticity, truthfulness, accuracy and origin of the Contributions. You must ensure that you either have all ownership rights to the Contributions posted or all rights allowing you to post the Contributions.</li>
          <li className={css.line}>10. &nbsp;Contributions must not:</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (a) &nbsp;infringe any copyright, database right, trade mark or other intellectual property right of any other person;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (b) &nbsp;be made in breach of any legal duty owed to a third party, such as a contractual duty or a duty of confidence;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (c) &nbsp;be threatening or abusive, invade another’s privacy, or cause or be likely to cause annoyance, alarm, inconvenience or needless anxiety to any other person;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (d) &nbsp;be used to impersonate any person, or to misrepresent your identity or affiliation with any person;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (e) &nbsp;give the impression that they emanate from us, if this is not the case;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (f) &nbsp;advocate, promote or assist any unlawful act such as (by way of example only) copyright infringement or computer misuse; or</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (g) &nbsp;advertise, promote or market any products or services by third parties or yourself.</li>
          <li className={css.line}>11. &nbsp;We reserve the right to delete any of the Contributions or to request that you amend the Contributions if it is found that any of the Contributions posted by you is in contravention of this Acceptable Use Policy.</li>
          <li className={css.line}>12. &nbsp;Where you choose to terminate your account with us, you may delete all previous Contributions made by you and retain a copy of the same.</li>
        </ul>
        <p><strong>SUSPENSION AND TERMINATION&nbsp;</strong></p>
        <ul>
          <li className={css.line}>12. &nbsp; We will determine, in our discretion, whether there has been a breach of this acceptable use policy through your use of our Platform. When a breach of this policy has occurred, we may take such action as we deem appropriate.</li>
          <li className={css.line}>13. &nbsp; Failure to comply with this acceptable use policy constitutes a material breach of the terms of use upon which you are permitted to use our Platform, and may result in our taking all or any of the following actions:</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (a) &nbsp;immediate, temporary or permanent withdrawal of your right to use our Platform;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;(b) &nbsp;immediate, temporary or permanent removal of any posting or material uploaded by you to our Platform;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;(c) &nbsp;issuance of a warning to you;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;(d) &nbsp;legal proceedings against you for reimbursement of all costs on an indemnity basis (including, but not limited to, reasonable administrative and legal costs) resulting from the breach;</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;(e) &nbsp;further legal action against you; and</li>
          <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp;(f) &nbsp;disclosure of such information to law enforcement authorities as we reasonably feel is necessary.</li>
          <li className={css.line}>14. &nbsp; We exclude liability for actions taken in response to breaches of this acceptable use policy. The responses described in this policy are not limited, and we may take any other action we reasonably deem appropriate.</li>
        </ul>
        <p><strong>CHANGES TO THE ACCEPTABLE USE POLICY&nbsp;</strong></p>
        <p>15. &nbsp; We may revise this acceptable use policy at any time by amending this page. You are expected to check this page from time to time to take notice of any changes we make, as they are legally binding on you. Some of the provisions contained in this acceptable use policy may also be superseded by provisions or notices published elsewhere on our Platform.&nbsp;</p>
      </div>
    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
