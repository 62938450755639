import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import setFieldTouched from '../../util/setFiledTouched';
import { propTypes } from '../../util/types';
import infoCircle from '../../assets/updatelisting/info-circle-solid.svg';
import { Button, FieldCheckboxGroup, FieldTextInput, Form } from '../../components';
import arrayMutators from 'final-form-arrays';

import config from '../../config';

import css from './EditListingPoliciesForm.css';

const DESCRIPTION_MAX_LENGTH = 500;
export const EditListingPoliciesFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators, setFieldTouched }}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values,
        form,
        submitButtonId,
      } = formRenderProps;

      // Listing Mileage allowed per day field
      const millagePerDayLabel = intl.formatMessage({
        id: 'EditListingPoliciesForm.millagePerDayLabel',
      });
      const millagePerDayRequired = intl.formatMessage({
        id: 'EditListingPoliciesForm.millagePerDayRequired',
      });
      const millagePerDayPlaceholder = intl.formatMessage({
        id: 'EditListingPoliciesForm.millagePerDayPlaceholder',
      });
      const millagePerDayInfo = intl.formatMessage({
        id: 'EditListingPoliciesForm.millagePerDayInfo',
      });

      const guidelinesInfo = intl.formatMessage({
        id: 'EditListingPoliciesForm.guidelinesInfo',
      });

      // Listing Key rules field
      const keyRulesLabel = intl.formatMessage({
        id: 'EditListingPoliciesForm.keyRulesLabel',
      });

      // Listing Pickup Instructions field
      const rulesLabelMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.rulesLabel',
      });
      const rulesPlaceholderMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.rulesPlaceholder',
      });
      const rulesRequiredMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.rulesRequiredMessage',
      });
      const rulesInfoMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.rulesInfoMessage',
      });

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingPoliciesForm.updateFailed" />
        </p>
      ) : null;
      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingPoliciesForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = disabled || submitInProgress;

      return (
        <Form
          className={classes}
          onSubmit={e => {
            e.preventDefault();
            e.stopPropagation();
            if (!invalid) handleSubmit(e);
            const formState = form.getState();
            const { errors } = formState || {};
            Object.keys(errors).forEach(key => form.mutators.setFieldTouched(key, 'true'));
          }}
        >
          {errorMessage}
          {errorMessageShowListing}
          <div className={css.displayInlineContainer}>
            {/* <div className={classNames(css.column, css.column6, css.milageDayOuter)}>
              <FieldSelect
                className={classNames(css.newInput, css.dropDownInput)}
                name="millagePerDay"
                id="millagePerDay"
                label={millagePerDayLabel}
                placeholder={millagePerDayPlaceholder}
                validate={composeValidators(required(millagePerDayRequired))}
                errorClassName={css.customErrorClass}
              >
                <option value="100">100</option>
                <option value="150">150</option>
                <option value="200">200</option>
                <option value="unlimited">Unlimited</option>
              </FieldSelect>
              <div className={css.infoText}>
                <span className={css.infoIcon}>
                  <img src={infoCircle} alt="" />
                </span>
                {millagePerDayInfo}
              </div>
            </div> */}
            <div className={classNames(css.column, css.column12, css.keyRulesOuter)}>
              <div className={css.customRadioOuter}>
                <FieldCheckboxGroup
                  className={css.policy}
                  customClasses={css.inlineInputs}
                  id="keyRules"
                  name="keyRules"
                  label={keyRulesLabel}
                  options={config.custom.keyRules}
                />
              </div>
            </div>
            <div className={classNames(css.column, css.column12, css.specificInstructionOuter)}>
              <div className={css.fieldWrapper}>
                <div className={css.limitCounter}>
                  {(values.rules && values.rules.length) || 0}/{DESCRIPTION_MAX_LENGTH}
                </div>
                <FieldTextInput
                  id="rules"
                  name="rules"
                  maxLength={DESCRIPTION_MAX_LENGTH}
                  className={classNames(css.policy, css.newInput, css.specificInstruction)}
                  type="textarea"
                  label={rulesLabelMessage}
                  placeholder={rulesPlaceholderMessage}
                  customErrorText={
                    values.rules &&
                      values.rules.length &&
                      values.rules.length >= DESCRIPTION_MAX_LENGTH
                      ? 'You have reached the maximum character limit'
                      : ''
                  }
                  // validate={composeValidators(required(rulesRequiredMessage))}
                  errorClassName={css.customErrorClass}
                />
                <div className={css.infoText}>
                  <span className={css.infoIcon}>
                    <img src={infoCircle} alt="" />
                  </span>
                  {guidelinesInfo}
                </div>
              </div>
              {/* <div className={css.infoText}>
                <span className={css.infoIcon}>
                  <img src={infoCircle} alt="" />
                </span>
                {rulesInfoMessage}
              </div> */}
            </div>
          </div>
          <div className={css.stickyButtons}>
            <div className={css.stickButtonsContainer}>
              <div className={css.stickButtonsDescription}></div>
              <div className={css.stickButton}>
                <Button
                  className={css.submitButton}
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                  ready={submitReady}
                  id={submitButtonId}
                >
                  {/* {saveActionMsg} */}
                  Next
                </Button>
              </div>
            </div>
          </div>
        </Form>
      );
    }}
  />
);

EditListingPoliciesFormComponent.defaultProps = {
  selectedPlace: null,
  updateError: null,
};

EditListingPoliciesFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  submitButtonId: string,
};

export default compose(injectIntl)(EditListingPoliciesFormComponent);
