import React from 'react';
import css from './AntiCompetitionLandingPage.css';
import { locationRadiusBounds } from '../../util/googleMaps';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { useSelector } from 'react-redux';
import { parse } from '../../util/urlHelpers';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';
import imageSource from '../../util/imageSource';

const LIMIT_DISTANCE = 5200;
const CompetitionHomeDeliverySection = () => {
  const currentUser = useSelector(state => state.user.currentUser);
  const history = useHistory();
  const handleHomeDeliverySlide = () => {
    const searchBounds =
      '?address=Singapore&bounds=1.4708809%2C104.04157989999999%2C1.216611%2C103.60650989999999';
    const urlSearchData = parse(searchBounds);
    const queryParams = { ...urlSearchData, pub_delivery: true };
    if (currentUser) {
      const userLocation = get(
        currentUser,
        'attributes.profile.protectedData.location.selectedPlace.origin'
      );
      const newBounds = userLocation && locationRadiusBounds(userLocation, LIMIT_DISTANCE);
      newBounds && (queryParams.bounds = newBounds);
    }
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  };

  return (
    <div className={css.homeDeliverWrapper}>
      <div className={css.container}>
        <div className={css.homeDeliverContent}>
          <div className={css.deliveryBannerBlock}>
            <img src={imageSource.antiCompetition.deliveryBanner} alt="Rentals home-delivered!" />
          </div>
          <div className={css.row}>
            <div className={css.column6}>
              <div className={css.leftContent}>
                <h4>Also get your rentals home-delivered!</h4>
                <p>
                  Ensure maximum comfort and safety by getting your rental car delivered right to
                  your doorstep.
                </p>
                <button type="button" className={css.btnMore} onClick={handleHomeDeliverySlide}>
                  View Delivery-enabled Cars
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompetitionHomeDeliverySection;
