import classNames from 'classnames';
import { object, oneOfType, string } from 'prop-types';
import React from 'react';
import css from './BookingBreakdown.css';

const Explanation = props => {
  const { text, children, textClassName, className } = props;

  const handleHoverBug = e => {
    if (e.type === 'touchstart') {
      // Don't trigger mouseenter even if they hold
      if (typeof e.stopImmediatePropagation === 'function') {
        e.stopImmediatePropagation();
      }
      // If $item is a link (<a>), don't go to said link on mobile, show menu instead
      e.preventDefault();
    }
  };

  return (
    <span
      className={classNames(css.explaination, className)}
      onTouchStart={handleHoverBug}
      onMouseEnter={handleHoverBug}
      onFocus={handleHoverBug}
    >
      <span className={classNames(css.explainationText, textClassName)}>
        {text}
        {children}
      </span>
    </span>
  );
};

Explanation.defaultProps = {
  text: null,
};

Explanation.propTypes = {
  text: oneOfType([string, object]),
};

export default Explanation;
