/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { memo } from 'react';
import css from './SeoLandingPage.css';
import isEqual from 'lodash/isEqual';
import { useHistory } from 'react-router-dom';
import { TOP_BRANDS, TOP_CATEGORIES, TOP_LOCATIONS } from './seoCategoriesArrays';

const redirectToDifferentLocation = (history, locationName) => {
  history.push(locationName);
  history.go(0);
};

const SeoLandingRentalCategories = memo(
  () => {
    const history = useHistory();
    return (
      <div className={css.rentalCarCategories}>
        <div className={css.rentalCarCategoriesContainer}>
          <div className={css.rentalCarCategoriesRow}>
            <div className={css.columnEight}>
              <div className={css.columnTitle}>Car Rental by top brands</div>
              <ul className={css.categoriesListing}>
                {TOP_BRANDS.values.map(carBrand => {
                  return (
                    <li
                      key={carBrand.key}
                      onClick={() =>
                        redirectToDifferentLocation(history, TOP_BRANDS.generateRoute(carBrand.key))
                      }
                    >
                      <p className={css.linkName}>{carBrand.label}</p>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className={css.columnEight}>
              <div className={css.columnTitle}>Car Rental by top locations</div>
              <ul className={css.categoriesListing}>
                {TOP_LOCATIONS.values.map(location => {
                  return (
                    <li
                      key={location.key}
                      onClick={() =>
                        redirectToDifferentLocation(
                          history,
                          TOP_LOCATIONS.generateRoute(location.key)
                        )
                      }
                    >
                      <p className={css.linkName}>{location.label}</p>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className={css.columnTwo}>
              <div className={css.columnTitle}>Car Rental by type</div>
              <ul className={css.categoriesListing}>
                {TOP_CATEGORIES.values.map(category => {
                  return (
                    <li
                      key={category.key}
                      onClick={() =>
                        redirectToDifferentLocation(
                          history,
                          TOP_CATEGORIES.generateRoute(category.key)
                        )
                      }
                    >
                      <p className={css.linkName}>{category.label}</p>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  },
  (prev, next) => isEqual(prev, next)
);

export default SeoLandingRentalCategories;
