import React, { memo } from 'react';
import css from './HostLandingPage.css';
import { Column, Container, Row } from '../../components/Grids/Grid';
import classNames from 'classnames';

const WeHaveGotYourBack = memo(({ openSignUpModal }) => {
  return (
    <div className={css.gotYourBackWrapper}>
      <Container className={css.smallContainer}>
        <Row className={css.sectionTitle}>
          <Column large={12}>
            <h4>We have got your back <span>24X7 support. Fully insured. Verified drivers.</span></h4>
          </Column>
        </Row>
        <Row>
          <Column large={6} small={12}>
            <div className={css.contentBox}>
              <h3>Comprehensive insurance for your car</h3>
              <p>
                During every Drive lah trip, your car is comprehensively insured and there’s no impact on your NCD.
              </p>
            </div>
          </Column>
          <Column large={6} small={12}>
            <div className={css.contentBox}>
              <h3>Your car stays in safe hands</h3>
              <p>
                All our renters are verified individuals with two+ years of valid driving licence.
              </p>
            </div>
          </Column>
          <Column large={6} small={12}>
            <div className={css.contentBox}>
              <h3>Flexibility in sharing</h3>
              <p>Share your car whenever you want, as per your usage and convenience.</p>
            </div>
          </Column>
          <Column large={6} small={12}>
            <div className={css.contentBox}>
              <h3>Two-way reviews</h3>
              <p>
                Both hosts and guests review each other, so you see your guests’ reviews before
                hosting them.
              </p>
            </div>
          </Column>
          <Column large={6} small={12}>
            <div className={css.contentBox}>
              <h3>Safe & trusted community</h3>
              <p>
                We screens all guests before allowing them to book, to ensure your car is in safe hands.
              </p>
            </div>
          </Column>
          <Column large={6} small={12}>
            <div className={css.contentBox}>
              <h3>24x7 customer support</h3>
              <p>
                Call, chat or drop an email to us, we've got your back round the clock.
              </p>
            </div>
          </Column>
        </Row>
        <Row>
          <Column large={12}>
            <div className={css.btnWrapper}>
              <button onClick={openSignUpModal} className={classNames(css.btn, css.btnPrimary)}>List your Car</button>
            </div>
          </Column>
        </Row>
      </Container>
    </div>
  );
});

export default WeHaveGotYourBack;
