import React, { Component } from 'react';
import { func, string } from 'prop-types';
import { FormattedMessage } from '../../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../../util/types';
import { obfuscatedCoordinates } from '../../../util/maps';
import { LocationInfo, Map } from '../../../components';
import config from '../../../config';

import css from './ListingPageGo.css';

class SectionMapMaybe extends Component {
  constructor(props) {
    super(props);
    this.state = { isStatic: true };
  }

  render() {
    const {
      className,
      rootClassName,
      geolocation,
      publicData,
      listingId,
      distanceToUser,
      onEnableTheMap,
    } = this.props;

    if (!geolocation) {
      return null;
    }

    const address = publicData && publicData.location ? publicData.location.address : '';
    const classes = classNames(rootClassName || css.section, className);
    const cacheKey = listingId ? `${listingId.uuid}_${geolocation.lat}_${geolocation.lng}` : null;

    const mapProps = config.maps.fuzzy.enabled
      ? { obfuscatedCenter: obfuscatedCoordinates(geolocation, cacheKey) }
      : { address, center: geolocation };
    const map = <Map {...mapProps} useStaticMap={this.state.isStatic} />;

    return (
      <div className={classes}>
        <div>
          <h2 className={css.sectionTitle}>
            <FormattedMessage id="ListingPage.locationTitle" />
          </h2>
          <LocationInfo
            distanceToUser={distanceToUser}
            publicData={publicData}
            address={address}
          />
        </div>

        {this.state.isStatic ? (
          <button
            className={css.map}
            onClick={() => {
              if (this.state.isStatic) {
                onEnableTheMap();
              }
              this.setState({ isStatic: false });
            }}
          >
            {map}
          </button>
        ) : (
          <div className={css.map}>{map}</div>
        )}
      </div>
    );
  }
}

SectionMapMaybe.defaultProps = {
  rootClassName: null,
  className: null,
  geolocation: null,
  listingId: null,
  onEnableTheMap: () => {},
};

SectionMapMaybe.propTypes = {
  rootClassName: string,
  className: string,
  geolocation: propTypes.latlng,
  listingId: propTypes.uuid,
  onEnableTheMap: func,
};

export default SectionMapMaybe;
