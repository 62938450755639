import React, { Component } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ToggleSwitch } from '../../components';

import css from './SearchDateTimeFilters.css';

class SearchDateTimeFilters extends Component {
  constructor(props) {
    super(props);

    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleOnChange(value) {

    this.props.onManageVisibilitySearchMap(value)
  }

  render() {
    const {
      rootClassName,
      className,
      visibilityMap,
      isMobileLayout
    } = this.props;

    const classes = classNames(rootClassName || css.root, className)

    return (
      <div className={classes}>
        {!isMobileLayout &&
          <div className={css.toggleMapButton}>
            <FormattedMessage id="SearchDateTimeFilters.toggleMapButton"/>
            <ToggleSwitch status={visibilityMap} onChange={this.handleOnChange}/>
          </div>
        }
      </div>
    );
  }
}

SearchDateTimeFilters.defaultProps = {

};

SearchDateTimeFilters.propTypes = {

};

export default SearchDateTimeFilters;
