import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import css from './GrabHostYourCarLandingPage.css';

import storyIcon from '../../assets/landingpage/storyIcon.png';
import avatar1 from '../../assets/SuccessStoriesImages/avatar1.jpeg';
import avatar2 from '../../assets/SuccessStoriesImages/avatar2.jpeg';
import avatar3 from '../../assets/SuccessStoriesImages/avatar3.jpeg';
import avatar4 from '../../assets/SuccessStoriesImages/avatar4.jpeg';
import avatar5 from '../../assets/SuccessStoriesImages/avatar5.jpeg';

const datas = [
  {
    content:
      "I spend my time with family on weekends so I don't need my car. I list it on Drive lah and get paid for weekends.",
    name: 'Supu Xu',
    avatar: avatar1,
  },
  {
    content:
      'Because Grab has such high demand, my car is instantly booked in my breaks. I never thought I could make extra money with my car like this.',
    name: 'John',
    avatar: avatar2,
  },
  {
    content:
      'The demand is less because of COVID 19. The car was not being used so I tried Drive lah. To my surprise, it was booked almost immediately and I got the payment. Now I make extra money when not using the car.',
    name: 'Shimei He',
    avatar: avatar3,
  },
  {
    content: 'Convenient way to rent out your car with insurance covered. Hassle free!',
    name: 'Jonathan Lai',
    avatar: avatar4,
  },
  {
    content:
      'Drive Lah is a safe, easy and convenient platform for car owners to lease their unused car 24-7 for extra income to offset their car maintenance in Singapore. Thanks Drive Lah!',
    name: 'Ben Chan',
    avatar: avatar5,
  },
];

const Story = ({ content, name, avatar }) => {
  return (
    <div className={css.story}>
      <div className={css.storyContentWrapper}>
        <img src={storyIcon} height={100} />
        <div className={css.storyContent}>{content}</div>
      </div>
      <div className={css.avatarWrapper}>
        <img src={avatar} className={css.avatar} />
        <div className={css.storyName}>{name}</div>
      </div>
    </div>
  );
};

const SectionStories = () => {
  const sliderConfig = {
    slidesToScroll: 1,
    slidesToShow: 3,
    initialSlide: 0,
    infinite: false,
    dots: false,
    arrows: true,
    adaptiveHeight: false,
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 3,
          initialSlide: 0,
          infinite: false,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          initialSlide: 0,
          infinite: false,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          initialSlide: 0,
          infinite: false,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: true,
          arrows: false,
        },
      },
    ],
  };
  return (
    <div className={css.sectionStories}>
      <div className={css.sectionStoriesTitleWrapper}>
        <div className={css.sectionTitle}>Stories from our happy hosts</div>
        <div className={css.sectionStoriesDescription}>They love sharing cars on Drive lah!</div>
      </div>
      <div className={css.sliderWrapper}>
        <Slider {...sliderConfig}>
          {datas.map(s => (
            <div className={css.storyWrapper}>
              <Story {...s} />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default SectionStories;
