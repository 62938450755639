import React, { forwardRef } from 'react';
import 'react-dates/initialize';
import { DayPickerSingleDateController, isInclusivelyAfterDay } from 'react-dates';
import moment from 'moment';
import css from './SingleMonthCalender.css';
import classNames from 'classnames';
import IconArrowHead from '../IconArrowHead/IconArrowHead';

const START_DATE = 'startDate';
const END_DATE = 'endDate';

const Day = ({ day }) => {
  if (day)
    return (
      <span className="renderedDay">
        <span className="dayWrapper">{day.format('D')}</span>
      </span>
    );
  return <span />;
};

const SingleMonthCalender = forwardRef(
  ({ selectedDates, onDatesChange, className, isPickupDate, style, ...props }, ref) => {
    const selectedDateValue = isPickupDate
      ? selectedDates && selectedDates.startDate
      : selectedDates && selectedDates.endDate;

    const handleSelectDate = values => {
      onDatesChange(values);
    };

    const calculateCellWidth = () => {
      return (window.innerWidth - 60) / 7;
    };

    return (
      <div className={classNames(css.root, className)} style={style}>
        {/* <DayPickerRangeController
          {...props}
          numberOfMonths={1}
          onDatesChange={handleSelectDate}
          onFocusChange={handleFocusChange}
          focusedInput={focusedInput}
          startDate={selectedDate && selectedDate.startDate}
          endDate={selectedDate && selectedDate.endDate}
          daySize={calculateCellWidth()}
          small={false}
          customArrowIcon={<span>I will not appear</span>}
        /> */}
        <DayPickerSingleDateController
          {...props}
          navPrev={<IconArrowHead direction="left" />}
          navNext={<IconArrowHead direction="right" />}
          numberOfMonths={1}
          onDateChange={handleSelectDate}
          focused={true}
          daySize={calculateCellWidth()}
          small={false}
          date={selectedDateValue}
          hideKeyboardShortcutsPanel
        />
      </div>
    );
  }
);

export default SingleMonthCalender;
const defaultProps = {
  // example props for the demo
  autoFocusEndDate: false,
  initialStartDate: null,
  initialEndDate: null,
  startDateOffset: undefined,
  endDateOffset: undefined,
  showInputs: false,
  minDate: null,
  maxDate: null,

  // day presentation and interaction related props
  renderCalendarDay: undefined,
  renderDayContents: day => <Day day={day} />,
  minimumNights: 1,
  isDayBlocked: () => false,
  isOutsideRange: day => !isInclusivelyAfterDay(day, moment()),
  isDayHighlighted: () => false,
  enableOutsideDays: false,
  daysViolatingMinNightsCanBeClicked: false,

  // calendar presentation and interaction related props
  verticalHeight: undefined,
  withPortal: false,
  initialVisibleMonth: null,
  numberOfMonths: 1,
  onOutsideClick() {},
  keepOpenOnDateSelect: false,
  renderCalendarInfo: null,
  isRTL: false,
  renderMonthText: null,
  renderMonthElement: null,
  renderKeyboardShortcutsButton: undefined,
  renderKeyboardShortcutsPanel: undefined,

  // navigation related props
  navPrev: null,
  navNext: null,
  renderNavPrevButton: null,
  renderNavNextButton: null,
  onPrevMonthClick() {},
  onNextMonthClick() {},

  // internationalization
  monthFormat: 'MMMM YYYY',
};

SingleMonthCalender.defaultProps = {
  ...defaultProps,
};
