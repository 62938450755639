import isEmpty from 'lodash/isEmpty';
import { clearCurrentUser, fetchCurrentUser } from './user.duck';
import { storableError } from '../util/errors';
import * as log from '../util/log';
import { DEFAULT_ADMIN_FILL_VALUE } from '../util/types';
import { createContact } from '../util/lightrail';
import { sendGAEvent } from '../util/googleAnalytics';
import { logoutIntercomUser } from '../util/intercom';
import { checkSignUpCode, updateReferralCodeData } from '../util/referralProgram';
import { clearUtmParams, getUtmParams } from '../util/utm/utmHelpers';
import {
  createUserWithIdp,
  deleteAuthCookie,
  formRedirectUrl,
  replaceAuthCookie,
} from '../modules/AuthWithIdp/AuthWithIdpUtils';
import { CONFLICT_ERROR } from '../constants/errors.constants';
import { sendEventGeneral, SIGNUP_REFERRAL_CODE_SUCCESSFUL } from '../util/emailNotify';
import { triggerAnalyticsEvent } from '../util/amplitudeMapEvents';
import { getEventsByBucket } from '../util/gtm/gtmCreateProperties';
import { event_trigger_ids } from '../util/analyticsConstants';

const authenticated = authInfo => authInfo && authInfo.isAnonymous === false;

// ================ Action types ================ //

export const AUTH_INFO_REQUEST = 'app/Auth/AUTH_INFO_REQUEST';
export const AUTH_INFO_SUCCESS = 'app/Auth/AUTH_INFO_SUCCESS';

export const LOGIN_REQUEST = 'app/Auth/LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'app/Auth/LOGIN_SUCCESS';
export const LOGIN_ERROR = 'app/Auth/LOGIN_ERROR';

export const LOGOUT_REQUEST = 'app/Auth/LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'app/Auth/LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'app/Auth/LOGOUT_ERROR';

export const SIGNUP_REQUEST = 'app/Auth/SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'app/Auth/SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'app/Auth/SIGNUP_ERROR';

export const SIGNUP_UPDATE_REQUEST = 'app/Auth/SIGNUP_UPDATE_REQUEST';
export const SIGNUP_UPDATE_SUCCESS = 'app/Auth/SIGNUP_UPDATE_SUCCESS';
export const SIGNUP_UPDATE_ERROR = 'app/Auth/SIGNUP_UPDATE_ERROR';

export const SIGNUP_STEP1_REQUEST = 'app/Auth/SIGNUP_STEP1_REQUEST';
export const SIGNUP_STEP1_SUCCESS = 'app/Auth/SIGNUP_STEP1_SUCCESS';
export const SIGNUP_STEP1_ERROR = 'app/Auth/SIGNUP_STEP1_ERROR';

export const CONFIRM_REQUEST = 'app/Auth/CONFIRM_REQUEST';
export const CONFIRM_SUCCESS = 'app/Auth/CONFIRM_SUCCESS';
export const CONFIRM_ERROR = 'app/Auth/CONFIRM_ERROR';

// Generic user_logout action that can be handled elsewhere
// E.g. src/reducers.js clears store as a consequence
export const USER_LOGOUT = 'app/USER_LOGOUT';

// ================ Reducer ================ //

const initialState = {
  isAuthenticated: false,

  // scopes associated with current token
  authScopes: [],

  // auth info
  authInfoLoaded: false,

  // login
  loginError: null,
  loginInProgress: false,

  // logout
  logoutError: null,
  logoutInProgress: false,

  // signup
  signupError: null,
  signupInProgress: false,

  //with Idp
  confirmError: null,
  confirmInProgress: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case AUTH_INFO_REQUEST:
      return state;
    case AUTH_INFO_SUCCESS:
      return {
        ...state,
        authInfoLoaded: true,
        isAuthenticated: authenticated(payload),
        authScopes: payload.scopes,
      };

    case LOGIN_REQUEST:
      return {
        ...state,
        loginInProgress: true,
        loginError: null,
        logoutError: null,
        signupError: null,
      };
    case LOGIN_SUCCESS:
      return { ...state, loginInProgress: false, isAuthenticated: true };
    case LOGIN_ERROR:
      return { ...state, loginInProgress: false, loginError: payload };

    case LOGOUT_REQUEST:
      return { ...state, logoutInProgress: true, loginError: null, logoutError: null };
    case LOGOUT_SUCCESS:
      return { ...state, logoutInProgress: false, isAuthenticated: false, authScopes: [] };
    case LOGOUT_ERROR:
      return { ...state, logoutInProgress: false, logoutError: payload };

    case SIGNUP_REQUEST:
      return { ...state, signupInProgress: true, loginError: null, signupError: null };
    case SIGNUP_SUCCESS:
      return { ...state, signupInProgress: false };
    case SIGNUP_ERROR:
      return { ...state, signupInProgress: false, signupError: payload };

    case CONFIRM_REQUEST:
      return { ...state, confirmInProgress: true, confirmError: null };
    case CONFIRM_SUCCESS:
      return { ...state, confirmInProgress: false, confirmError: null };
    case CONFIRM_ERROR:
      return { ...state, confirmInProgress: false, confirmError: payload };

    default:
      return state;
  }
}

// ================ Selectors ================ //

export const authenticationInProgress = state => {
  const { loginInProgress, logoutInProgress, signupInProgress, confirmInProgress } = state.Auth;
  return loginInProgress || logoutInProgress || signupInProgress || confirmInProgress;
};

export const $isLimitedUser = state => {
  return state.Auth.isAuthenticated &&
    state.Auth.authScopes &&
    state.Auth.authScopes.length === 1 &&
    state.Auth.authScopes[0] === 'user:limited'
    ;
};

// ================ Action creators ================ //

export const authInfoRequest = () => ({ type: AUTH_INFO_REQUEST });
export const authInfoSuccess = info => ({ type: AUTH_INFO_SUCCESS, payload: info });

export const loginRequest = () => ({ type: LOGIN_REQUEST });
export const loginSuccess = () => ({ type: LOGIN_SUCCESS });
export const loginError = error => ({ type: LOGIN_ERROR, payload: error, error: true });

export const logoutRequest = () => ({ type: LOGOUT_REQUEST });
export const logoutSuccess = () => ({ type: LOGOUT_SUCCESS });
export const logoutError = error => ({ type: LOGOUT_ERROR, payload: error, error: true });

export const signupRequest = () => ({ type: SIGNUP_REQUEST });
export const signupSuccess = () => ({ type: SIGNUP_SUCCESS });
export const signupError = error => ({ type: SIGNUP_ERROR, payload: error, error: true });

export const signupUpdateRequest = () => ({ type: SIGNUP_UPDATE_REQUEST });
export const signupUpdateSuccess = () => ({ type: SIGNUP_UPDATE_SUCCESS });
export const signupUpdateError = error => ({
  type: SIGNUP_UPDATE_ERROR,
  payload: error,
  error: true,
});

export const signupStep1Success = basicData => ({
  type: SIGNUP_STEP1_SUCCESS,
  payload: basicData,
});

export const confirmRequest = () => ({ type: CONFIRM_REQUEST });
export const confirmSuccess = () => ({ type: CONFIRM_SUCCESS });
export const confirmError = error => ({ type: CONFIRM_ERROR, payload: error, error: true });

export const userLogout = () => ({ type: USER_LOGOUT });

// ================ Thunks ================ //

export const authInfo = () => (dispatch, getState, sdk) => {
  // Check if we get access token from query params from IDP auth
  // And replace previously sent token to one we get from IDP
  replaceAuthCookie();
  dispatch(authInfoRequest());
  return sdk
    .authInfo()
    .then(info => dispatch(authInfoSuccess(info)))
    .catch(e => {
      // Requesting auth info just reads the token from the token
      // store (i.e. cookies), and should not fail in normal
      // circumstances. If it fails, it's due to a programming
      // error. In that case we mark the operation done and dispatch
      // `null` success action that marks the user as unauthenticated.
      log.error(e, 'auth-info-failed');
      dispatch(authInfoSuccess(null));
    });
};

export const login = (username, password) => (dispatch, getState, sdk) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }
  dispatch(loginRequest());

  // Note that the thunk does not reject when the login fails, it
  // just dispatches the login error action.
  return sdk
    .login({ username, password })
    .then(() => {
      window.localStorage.setItem('user-verified', 'true');
      return dispatch(loginSuccess());
    })
    .then(() => dispatch(fetchCurrentUser()))
    .catch(e => dispatch(loginError(storableError(e))));
};

export const logout = () => async (dispatch, getState, sdk) => {
  // console.log("Finally the logout happening", dispatch, getState(), sdk);

  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }

  const {currentUser} = getState().user || {}

  // console.log("currentUser", currentUser)

  try {
    const response = await getEventsByBucket('browsing');
    triggerAnalyticsEvent({ 
      event_id: event_trigger_ids.LOGGED_OUT, 
      eventData: response.data,
      props: {
        ui: {
          button: 'Logout',
          page: 'Expired session'
        },
      },
      userId: currentUser ? currentUser.id.uuid : null,
      user: currentUser
    });

    dispatch(logoutRequest());

    // Note that the thunk does not reject when the logout fails, it
    // just dispatches the logout error action.
    deleteAuthCookie();
    
    await sdk.logout();

    window.localStorage.removeItem('user-verified');
    window.localStorage.removeItem('code');
    window.localStorage.removeItem('step2');
    window.localStorage.removeItem('proxyReloadCount');
    window.localStorage.removeItem('locationProxyStartTimer');
    window.localStorage.removeItem('locationProxyTimerComplete');
    window.localStorage.removeItem('locationProxyTimerID');
    window.localStorage.removeItem('locationProxyPostalCodeExists');
    
    // The order of the dispatched actions
    dispatch(logoutSuccess());
    dispatch(clearCurrentUser());
    log.clearUserId();
    dispatch(userLogout());

    // Logout intercom
    logoutIntercomUser(process.env.REACT_APP_INTERCOM_APP_ID);

  } catch (e) {
    dispatch(logoutError(storableError(e)));
  }
};


const generateUVK = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  );
};

export const signup = params => (dispatch, getState, sdk) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }
  dispatch(signupRequest());
  const { email, password, firstName, lastName, isFromCampaign, ...rest } = params;

  const createUserParams = isEmpty(rest)
    ? { email, password, firstName, lastName }
    : { email, password, firstName, lastName, protectedData: { ...rest } };

  createUserParams.privateData = createUserParams.privateData || {};
  createUserParams.publicData = createUserParams.publicData || {};
  createUserParams.privateData.canRequestBooking = false;
  createUserParams.publicData.shuUserId =
    (Math.random() + ' ').substring(2, 10) + (Math.random() + ' ').substring(2, 10);
  createUserParams.privateData.drivelahNotes = DEFAULT_ADMIN_FILL_VALUE;
  // createUserParams.privateData.drivingLicenseIssueDate = DEFAULT_ADMIN_FILL_VALUE;
  createUserParams.privateData.residencyStatus = DEFAULT_ADMIN_FILL_VALUE;
  createUserParams.privateData.numberOfClaimsIncurredTotalAsAGuest = DEFAULT_ADMIN_FILL_VALUE;
  createUserParams.privateData.numberOfClaimsIncurred12MonthAsAGuest = DEFAULT_ADMIN_FILL_VALUE;
  createUserParams.privateData.claimsAmountIncurredTotalAsAGuest = DEFAULT_ADMIN_FILL_VALUE;
  createUserParams.privateData.claimsAmountIncurred12MonthsAsAGuest = DEFAULT_ADMIN_FILL_VALUE;
  createUserParams.privateData.numberDrivelahRentalDaysCompletedTotal = 0;
  createUserParams.privateData.numberDrivelahRentalDaysCompleted12Months = 0;
  createUserParams.privateData.drivingLicenseRegistrationDate = DEFAULT_ADMIN_FILL_VALUE;
  createUserParams.privateData.isFromCampaign = isFromCampaign;
  createUserParams.privateData.isCreatedViaForm = true;

  createUserParams.privateData.userVerificationKey = generateUVK();

  createUserParams.protectedData = createUserParams.protectedData || {};

  const signupUtmLabels = getUtmParams();
  if (signupUtmLabels) {
    createUserParams.publicData['utm-params'] = signupUtmLabels;
  }

  sendGAEvent({
    eventCategory: 'SignUp',
    eventAction: 'Submit Sign Up Form',
    eventValue: 1,
  });

  if (isFromCampaign) {
    sendGAEvent({
      eventCategory: 'Campaign',
      eventAction: 'Submit Sign Up Form From Campaign',
      eventValue: 1,
    });
  }

  return sdk.currentUser
    .create(createUserParams)
    .then(() => dispatch(signupSuccess()))
    .then(() => dispatch(login(email, password)))
    .then(() => clearUtmParams())
    .catch(e => {
      dispatch(signupError(storableError(e)));
      log.error(e, 'signup-failed', {
        email: params.email,
        firstName: params.firstName,
        lastName: params.lastName,
      });
    });
};

export const update = params => (dispatch, getState, sdk) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }
  dispatch(signupUpdateRequest());
  dispatch(confirmRequest());
  const { code, ...rest } = params;

  // const age = new Date().getFullYear() - parseInt(dateOfBirth.year, 10);

  const userParams = { protectedData: { ...rest } };
  userParams.publicData = userParams.publicData || {};
  userParams.protectedData = userParams.protectedData || {};
  // userParams.protectedData.phoneCode = phoneCode;
  // userParams.protectedData.dateOfBirth = dateOfBirth;
  if (userParams.protectedData.phoneCode && userParams.protectedData.phoneNumber) {
    userParams.protectedData.phoneObj = {};
    userParams.protectedData.phoneObj.phoneCode = userParams.protectedData.phoneCode;
    userParams.protectedData.phoneObj.rawNumber = userParams.protectedData.phoneNumber;
    userParams.protectedData.phoneNumber = userParams.protectedData.phoneObj.phoneCode + userParams.protectedData.phoneObj.rawNumber;
  }

  // userParams.protectedData.gender = gender;

  // if (dateOfBirth) userParams.protectedData.dateOfBirth = dateOfBirth;

  // if (!userParams.protectedData.dateOfBirth || !userParams.protectedData.dateOfBirth.year) {
  //   return dispatch(signupError(new Error({
  //     message: 'Date of birth is null, please try again',
  //     type: 'missing_information',
  //   })));
  // }

  let codeValid = false;
  let codeType,
    details,
    userId,
    guestReferralCodeData,
    email,
    firstName,
    lastName,
    credits = 0;

  return (code ? checkSignUpCode(code) : Promise.resolve())

    .then(codeDetails => {
      const { valid, codeType: type } = codeDetails || {};

      if (codeDetails && !codeDetails.valid) {
        dispatch(confirmError('Invalid sign up / referral code'));
        throw {
          name: 'Invalid sign up / referral code',
          message: 'Invalid sign up / referral code',
        };
      }

      if (codeDetails && valid) {
        codeType = type;
        userParams.protectedData[codeType] = code; //signUpCode | referralCode;
        codeValid = true;
        details = codeDetails;
        credits = codeDetails.credits;
      }
      return sdk.currentUser.updateProfile(userParams);
    })
    .then(() => {
      const { currentUser } = getState().user;

      userId = currentUser.id.uuid;
      email = currentUser.attributes.email;
      firstName = currentUser.attributes.profile.firstName;
      lastName = currentUser.attributes.profile.lastName;

      if (codeType && codeType === 'referralCode' && codeValid) {
        guestReferralCodeData = {
          referralCode: code,
          codeValid,
          userId,
          referrerName: details && details.details,
        };
        updateReferralCodeData(guestReferralCodeData);
      }
      return createContact({ flexId: userId, email, code: codeValid && code, credits });
    })
    .then(() => {
      if (codeType && codeType === 'referralCode' && codeValid) {
        const userName = `${firstName} ${lastName}`;

        sendEventGeneral({
          refereeId: userId,
          referredName: userName,
          referredEmail: email,
          // referrerId: code,
          guestReferralCodeData,
          eventType: SIGNUP_REFERRAL_CODE_SUCCESSFUL,
        });
      }
      dispatch(fetchCurrentUser());
    })
    .then(() => {
      dispatch(signupUpdateSuccess());
      dispatch(confirmSuccess());
    })
    .catch(e => {
      dispatch(signupUpdateError(storableError(e)));
      log.error(e, 'signup-failed', {
        // email: params.email,
        // firstName: params.firstName,
        // lastName: params.lastName,
      });
    });
};

export const signupWithIdp = params => (dispatch, getState, sdk) => {
  dispatch(confirmRequest());
  const code = params.code;

  return (code ? checkSignUpCode(code) : Promise.resolve())
    .then(codeDetails => {

      const { valid, codeType } = codeDetails || {};
      if (codeDetails && !codeDetails.valid) {
        dispatch(confirmError('Invalid referral code'));
        throw {
          name: 'Invalid referral code',
          message: 'Invalid referral code',
        };
      }
      if (codeDetails && valid) {
        params.protectedData[codeType] = code; //signUpCode | referralCode;
      }
    })
    .then(() => createUserWithIdp(params))
    .then((res)=> {
      if (res.error && res.error === CONFLICT_ERROR) {
        dispatch(loginError());
        return dispatch(confirmError(res.error));
      }
      return new Promise((resolve) => {
        if (params && params.callback && typeof params.callback == 'function') {
          params.callback(res.data).finally(() => {
            resolve(res);
          })
        } else {
          resolve(res);
        }
      })
    })
    .then(res => {
      if (res.error && res.error === CONFLICT_ERROR) {
        dispatch(loginError());
        return dispatch(confirmError(res.error));
      }
      authInfo();
      dispatch(confirmSuccess());
      window.location.href = formRedirectUrl(res.data);
      return
    })
    .catch(e => {
      log.error(e, 'create-user-with-idp-failed', { params });
      dispatch(loginError());
      return dispatch(confirmError(storableError(e)));
    });
};
