import React, { Component } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { formatHourlyMoney, formatMoney } from '../../util/currency';
import { ensureListing } from '../../util/data';
import config from '../../config';
import { distanceCountHelper } from '../../util/distanceCountHelper';
import moment from 'moment';


import css from './SearchMapPriceLabel.css';

const LIMIT_DISTANCE = 6;

class SearchMapPriceLabel extends Component {
  shouldComponentUpdate(nextProps) {
    const currentListing = ensureListing(this.props.listing);
    const nextListing = ensureListing(nextProps.listing);
    const isSameListing = currentListing.id.uuid === nextListing.id.uuid;
    const hasSamePrice = currentListing.attributes.price === nextListing.attributes.price;
    const hasSameActiveStatus = this.props.isActive === nextProps.isActive;
    const hasSameRefreshToken =
      this.props.mapComponentRefreshToken === nextProps.mapComponentRefreshToken;
    const isUser = this.props.currentUser && nextProps.currentUser

    return !(isSameListing && hasSamePrice && hasSameActiveStatus && hasSameRefreshToken && isUser);
  }

  render() {
    const { className, rootClassName, intl, listing, onListingClicked, isActive, currentUser } = this.props;
    const currentListing = ensureListing(listing);
    const { price } = currentListing.attributes;
    const isDelivery = currentListing.attributes.publicData.delivery;

    const currentUserLocation = get(currentUser, 'attributes.profile.protectedData.location.selectedPlace.origin');
    const listingLocation = get(listing, 'attributes.geolocation');
    const distance = currentUserLocation && listingLocation && distanceCountHelper(currentUserLocation, listingLocation);
    const showDelivery = isDelivery && (distance <= LIMIT_DISTANCE || !currentUserLocation)

    const searchDate = this.props.searchDate;
    const searchDateArr = searchDate ? searchDate.split(',') : null;
    const validSearchDate = searchDateArr && searchDateArr.length === 2;

    const searchTimes = this.props.searchTimes;
    const searchTimesArrays = searchTimes ? searchTimes.split(',') : null;
    const isValidSearchTimes = searchTimesArrays && searchTimesArrays.length === 2;


    let startDateTimeMaybe, endDateTimeMaybe, diffHours;
    if(validSearchDate  && isValidSearchTimes) {
        startDateTimeMaybe = validSearchDate
        ? new Date(
            moment(`${searchDateArr[0]} ${searchTimesArrays[0]}`)
              .format('YYYY-MM-DD HH:mm')
          )
        : null;

        endDateTimeMaybe = validSearchDate
          ? new Date(
              moment(`${searchDateArr[1]} ${searchTimesArrays[1]}`)
                .format('YYYY-MM-DD HH:mm')
            )
          : null;

    }

    // Create formatted price if currency is known or alternatively show just the unknown currency.
    let formattedPrice =
      price && price.currency && price.currency === config.currency ? formatMoney(intl, price) : price && price.currency;

    const classes = classNames(rootClassName || css.root, className);
    const priceLabelClasses = classNames(showDelivery ? css.priceLabelDelivery : css.priceLabel, {
      [css.priceLabelActive]: isActive,
    });
    const caretClasses = classNames(showDelivery ? css.caretDelivery : css.caret, { [css.caretActive]: isActive });

    if(startDateTimeMaybe && endDateTimeMaybe) {
      diffHours = moment(endDateTimeMaybe).diff(moment(startDateTimeMaybe), 'hours', true);
    }

    let hourlyPriceSeen = false;
    if(diffHours && diffHours < 12) {
        formattedPrice = price && price.currency === config.currency ? formatHourlyMoney(intl, price, diffHours) : price && price.currency;
        hourlyPriceSeen = true;
    }

    return (
      <button className={classes} onClick={() => onListingClicked(currentListing)}>
        <div className={css.caretShadow} />
        <div className={priceLabelClasses}>{formattedPrice}</div>
        <div className={caretClasses} />
      </button>
    );
  }
}

SearchMapPriceLabel.defaultProps = {
  className: null,
  rootClassName: null,
};

const { func, string } = PropTypes;

SearchMapPriceLabel.propTypes = {
  className: string,
  rootClassName: string,
  listing: propTypes.listing.isRequired,
  onListingClicked: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(SearchMapPriceLabel);
