import React, { memo } from 'react';
import css from './HostPromoGroundPlan.css';
import classNames from 'classnames';

const SectionSharingCarsSimply = memo(() => {
  return (
    <div className={css.simplySharingCars}>
      <div className={css.landingContainer}>
        <div className={css.landingRow}>
          <div className={css.column12}>
            <div className={css.sectionTitle}>Sharing your car made simple</div>
          </div>
        </div>
        <div className={classNames(css.landingRow, css.blockList)}>
          <div className={css.column4}>
            <div className={css.columnTitle}>List your car for free</div>
            <p>
              Share any type of car without sign up charges. Promote your car and sit back and relax
              for your first booking request to come in.
            </p>
          </div>
          <div className={css.column4}>
            <div className={css.columnTitle}>Accept a booking</div>
            <p>
              You’re in full control of your pricing, availability, accepting or declining. And we
              have you covered with our insurance policy.
            </p>
          </div>
          <div className={css.column4}>
            <div className={css.columnTitle}>Get paid</div>
            <p>
              Let your car work for you. Within 3-5 days of the trip, you will receive the payment
              directly into your bank account.
            </p>
          </div>
        </div>
        {/* <div className={css.landingRow}>
          <div className={classNames(css.column12, css.textCenter)}>
            <button className={css.sectionButton}>List your car</button>
          </div>
        </div> */}
      </div>
    </div>
  );
});

export default SectionSharingCarsSimply;
