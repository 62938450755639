/* eslint-disable jsx-a11y/img-redundant-alt */
import classNames from 'classnames';
import { get } from 'lodash';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import React, { memo, useEffect, useRef, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import Slider from 'react-slick';
import Typewriter from 'typewriter-effect';
import insuranceLogo from '../../assets/insurance-logo.svg';
import { Button, LocationAutocompleteInput, TrustBox } from '../../components';
import GeocoderGoogleMaps, {
  CURRENT_LOCATION_ID,
} from '../../components/LocationAutocompleteInput/GeocoderGoogleMaps';
import SearchIconNew from '../../components/Topbar/SearchIconNew';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { EVENT_CONVERSION_PERFORMED_SEARCH } from '../../util/conversions/conversionsConstants';
import { createConversionEvents } from '../../util/conversions/conversionsHelpers';
import { getSearchedPlaces, saveSearchedPlacesMobile } from '../../util/emailNotify';
import { locationRadiusBounds } from '../../util/googleMaps';
import { EVENT_SEARCH_PERFORMED } from '../../util/gtm/gtmConstants';
import { createRawPropertiesForGTM } from '../../util/gtm/gtmCreateProperties';
import { initiateEventFromListing } from '../../util/gtm/gtmHelpers';
import { gSend, GTAG_ACTIONS } from '../../util/gtag';
import imageSource from '../../util/imageSource';
import { createResourceLocatorString } from '../../util/routes';
import { encodeLatLngBounds, parse } from '../../util/urlHelpers';
import BLandingCss from './BLandingPage.css';
import AlertBox from '../../components/AlertBox/AlertBox';
import { lazyLoadWithDimensions, withViewport } from '../../util/contextHelpers';
import { triggerAnalyticsEvent } from '../../util/amplitudeMapEvents';
import { event_trigger_ids } from '../../util/analyticsConstants';

const DEFAULT_BOUNDS = {
  _sdkType: 'LatLngBounds',
  ne: {
    _sdkType: 'LatLng',
    lat: 1.4708809,
    lng: 104.04157989999999,
  },
  sw: {
    _sdkType: 'LatLng',
    lat: 1.216611,
    lng: 103.60650989999999,
  },
};
// Distance for home delivery in meters
const LIMIT_DISTANCE = 5200;

export const defaultPredictions = (config.maps.search.suggestCurrentLocation
    ? [{ id: CURRENT_LOCATION_ID, predictionPlace: {} }]
    : []
).concat(config.maps.search.defaults);

export const defaultPredictionsExcludingCurrentLocation = config.maps.search.defaults;

export const defaultPredictionsWithoutCurrentLocation = [
  { id: CURRENT_LOCATION_ID, predictionPlace: {} },
];

const LazyImageComponent = ({ src1x, src2x, alt }) => {
  const imageExtension = src1x
    .split(/[#?]/)[0]
    .split('.')
    .pop()
    .trim();
  const image =
    imageExtension === 'gif' ? (
      <img alt={alt} src={src1x} className={BLandingCss.imageComponentImageBLanding} />
    ) : (
      <img
        alt={alt}
        srcSet={`${src1x} 1x, ${src2x} 2x`}
        className={BLandingCss.imageComponentImageBLanding}
      />
    );
  return (
    <>
      {image}
    </>
  );
}

const LazyImage = lazyLoadWithDimensions(LazyImageComponent);

const ImageComponent = ({src1x, src2x, children, alt}) => {
  return (
    <div className={BLandingCss.bannerSlideItem}>
      <LazyImage src1x={src1x} src2x={src2x} alt={alt} />
      <div className={BLandingCss.imageComponentContentBLanding}>{children}</div>
    </div>
  );
};

const SectionSearchBanner = memo(
  ({
     isMobileLayout,
     onSelectedPrediction,
     currentUser,
     openInsuranceModal,
     onSelectionPostalAddress,
     searchEvents
   }) => {
    const sliderConfig = {
      infinite: true,
      slidesToScroll: 1,
      slidesToShow: 1,
      initialSlide: 0,
      dots: false,
      autoplay: true,
      arrows: false,
      draggable: true,
      fade: false,
      autoplaySpeed: isMobileLayout ? 10000 : 5000,
    };
    const geocoder = useRef(new GeocoderGoogleMaps()).current;
    let searchDropdown = useRef(null);
    const [predictions, setPredictions] = useState([]);
    const [isUserSearching, setIsUserSearching] = useState(false);
    const [locationLoader, setLocationLoader] = useState(false);
    const [isFocused, setIsFocused] = useState(null);
    const [highlightedIndex, setHighlightedIndex] = useState(-1);
    let userSearchHistory = getSearchedPlaces();
    const currentSearchParams = useSelector(state => state.UI.searchParams);
    const history = useHistory();
    const location = useLocation();
    useEffect(() => {
      document.addEventListener('click', handleOutsideClick, true);
    }, []);

    const [alert, setAlert] = useState(false);

    if (userSearchHistory && userSearchHistory.length) {
      userSearchHistory = userSearchHistory.filter(searchPlace => {
        return searchPlace['description'] && geocoder.getPredictionId(searchPlace);
      });
    }

    const newText = (
      <div className={BLandingCss.typeEffect}>
        <Typewriter
          options={{
            strings: ['<u>by the hour</u>', '<u>by the day</u>'],
            autoStart: true,
            loop: true,
          }}
        />
      </div>
    );

    const handleShowMenu = () => {
      if (isMobileLayout) {
        const { search } = location;
        const url = createResourceLocatorString('SearchPage', routeConfiguration(), null, {
          ...parse(search),
          searchStep: 'location',
          mapSearch: false,
          timezone: location.timezone,
        });
        history.push(url);
      }
    };

    // Touch devices need to be able to distinguish touches for scrolling and touches to tap

    const handleSelectPrediction = prediction => {
      const DEFAULT_TIME_AND_DATES = {
        location: {},
        selectedDates: {
          startDate: moment(),
          endDate: moment(),
        },
        selectedTimes: {
          startTime: moment(),
          endTime: moment(),
        },
        isDefault: true,
      };
      if (!isMobileLayout) {
        setLocationLoader(true);
        if (prediction && prediction.id && prediction.id === CURRENT_LOCATION_ID) {
          navigator.permissions
            .query({
              name: 'geolocation',
            })
            .then(function(result) {
              if (result.state == 'granted') {
                setAlert(false);
                console.log(result.state);
              } else if (result.state == 'prompt') {
                console.log(result.state);
              } else if (result.state == 'denied') {
                setLocationLoader(false);
                setAlert(true);
                console.log('result', result.state);
              }
              result.onchange = function() {
                console.log('onchange', result.state);
              };
            });
        }
        geocoder.getPlaceDetails(prediction).then(location => {
          let url = '';
          const { startDate, endDate } = DEFAULT_TIME_AND_DATES.selectedDates;
          const { startTime, endTime } = DEFAULT_TIME_AND_DATES.selectedTimes;
          let updatedStartDate, updatedEndDate, updatedStartTime, updatedEndTime;
          updatedStartDate = startDate.add(1, 'day').set({ hour: 9, minute: 0 });
          updatedEndDate = endDate.add(2, 'day').set({ hour: 9, minute: 0 });
          updatedStartTime = startTime.set({ hour: 9, minute: 0 });
          updatedEndTime = endTime.set({ hour: 9, minute: 0 });
          url = createResourceLocatorString('SearchPage', routeConfiguration(), null, {
            bounds: encodeLatLngBounds(
              location && location.bounds ? location.bounds : DEFAULT_BOUNDS
            ),
            dates: `${updatedStartDate.format('YYYY-MM-DD')},${updatedEndDate.format(
              'YYYY-MM-DD'
            )}`,
            hours: `${updatedStartTime.format('HH:mm')},${updatedEndTime.format('HH:mm')}`,
            searchType: 'daily',
            searchStep: 'preview',
            address: location && location.address,
            timezone: location.timezone,
            mapSearch: false,
          });

          onSelectedPrediction(location);
          onSelectionPostalAddress && onSelectionPostalAddress(location);
          // console.log("section search banner");
          // initiateEventFromListing({
          //   props: {
          //     searchParams: {
          //       ...currentSearchParams,
          //       bounds: location && location.bounds ? location.bounds : DEFAULT_BOUNDS,
          //     },
          //   },
          //   event: EVENT_SEARCH_PERFORMED,
          // });
          // Here will be backend api 
          // console.log("Search performed event", currentSearchParams, userSearchHistory, location, history);
          
          // gSend(GTAG_ACTIONS.ACTION_SEARCH, {
          //   // TODO: Assign proper details.
          //   search_location: location && location.address,
          //   search_pickupdate: `${updatedStartDate.format('YYYY-MM-DD')}`,
          //   search_pickuptime: `${updatedStartTime.format('HH:mm')}`,
          //   search_dropoffdate: `${updatedEndDate.format('YYYY-MM-DD')}`,
          //   search_dropofftime: `${updatedEndDate.format('HH:mm')}`,
          // });
          // const properties = createRawPropertiesForGTM({
          //   props: {
          //     searchParams: {
          //       ...currentSearchParams,
          //       bounds: location && location.bounds ? location.bounds : DEFAULT_BOUNDS,
          //     },
          //   },
          // });
          // createConversionEvents(properties, EVENT_CONVERSION_PERFORMED_SEARCH, 'click');

          if (prediction && prediction.predictionPlace) {
            saveSearchedPlacesMobile(prediction.predictionPlace);
          } else {
            saveSearchedPlacesMobile(prediction);
          }
          setLocationLoader(false);
          history.push(url);
        });
      }
    };

    const handleOutsideClick = e => {
      //
      // if (searchDropdown && searchDropdown.current && !searchDropdown.current.contains(e.target)) {
      //   setIsFocused(false);
      // } else {
      //   setIsFocused(true);
      //   document.removeEventListener('click', handleOutsideClick, false);
      // }
    };

    const handleSearchFocused = value => {
      // console.log("handleSearchFocused")
      triggerAnalyticsEvent({
        event_id: event_trigger_ids.SEARCH_CLICK_SEARCH_BAR,
        eventData: searchEvents,
        props: { 
          guest: currentUser, 
          ui: {
            page: 'ExploreScreen',
            button: "Enter your location to view cars"
          }},
        userId: currentUser ? currentUser.id.uuid : null
      });
      
      setIsFocused(value);
    };

    const _handleSearchType = p => {
      if (p && p.value !== '') {
        setIsUserSearching(true);
      } else {
        setIsUserSearching(false);
      }
    };

    /* eslint-disable jsx-a11y/no-static-element-interactions */
    const item = (prediction, index) => {
      const isHighlighted = index === highlightedIndex;
      const predictionId = geocoder.getPredictionId(prediction);
      return (
        <li
          className={classNames(
            isHighlighted ? BLandingCss.highlighted : null,
            BLandingCss.prediction
          )}
          key={predictionId}
          onClick={() => handleSelectPrediction(prediction)}
        >
          {predictionId === CURRENT_LOCATION_ID ? (
            <span className={BLandingCss.currentLocation}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                <defs></defs>
                <path
                  fill="#026786"
                  class="a"
                  d="M13.9.111.9,6.108a1.515,1.515,0,0,0,.6,2.9H7v5.5a1.515,1.515,0,0,0,2.9.6l6-12.993a1.559,1.559,0,0,0-2-2Z"
                  transform="translate(-0.007 -0.001)"
                />
              </svg>
              <FormattedMessage id="LocationAutocompleteInput.currentLocation" />
            </span>
          ) : (
            geocoder.getPredictionAddress(prediction)
          )}
        </li>
      );
    };
    /* eslint-enable jsx-a11y/no-static-element-interactions */

    // Handle Home delivery search page redirect page from the slide 2
    const handleHomeDeliverySlide = () => {
      const searchBounds =
        '?address=Singapore&bounds=1.4708809%2C104.04157989999999%2C1.216611%2C103.60650989999999';
      const urlSearchData = parse(searchBounds);
      const queryParams = { ...urlSearchData, pub_delivery: true };
      if (currentUser) {
        const userLocation = get(
          currentUser,
          'attributes.profile.protectedData.location.selectedPlace.origin'
        );
        const newBounds = userLocation && locationRadiusBounds(userLocation, LIMIT_DISTANCE);
        newBounds && (queryParams.bounds = newBounds);
      }
      history.push(
        createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams)
      );
    };

    // Banner 1 content
    const banner1Content = (
      <div className={BLandingCss.bannerContentBlock}>
        {alert ? (
          <AlertBox
            title="Location error"
            message="You have not given location access on the browser so we cannot fetch your currecnt location. Either provide location permission or enter your location manually."
            type="error"
            // button={
            //   <Button
            //     onClick={() => {
            //       if (typeof window !== 'undefined') {
            //         console.log(alert);
            //         setAlert(false);
            //       }
            //     }}
            //   >
            //     Dismiss
            //   </Button>
            // }
          />
        ) : null}
        <h1
          className={classNames(
            BLandingCss.sectionImageTitleBLanding,
            BLandingCss.hourlyTitleBLanding
          )}
        >
          <span>
            <i>Rent your neighbour’s</i>
            <br />
            <i>car&nbsp;</i>
            <i>{newText}</i>
          </span>
        </h1>
        <div className={BLandingCss.searchBar} onClick={handleShowMenu}>
          <div className={BLandingCss.searchBarContent}>
            <p>
              With Drive lah, you can rent from a huge variety of nearby cars at great value. Fully
              insured+.
            </p>
            <div className={BLandingCss.searchBarOuter} ref={searchDropdown}>
              <Form
                onSubmit={() => {}}
                render={() => {
                  return (
                    <Field name="location">
                      {props => (
                        <LocationAutocompleteInput
                          {...props}
                          autoFocus={false}
                          className={BLandingCss.fieldLocationBLanding}
                          iconClassName={BLandingCss.hideSearchIconBLanding}
                          inputClassName={BLandingCss.inputClassNameBLanding}
                          predictionsClassName={BLandingCss.searchPredictionsBLanding}
                          onPredictionChange={setPredictions}
                          placeholder="Enter your location to view cars"
                          // inputRef={_handleSearchType}
                          onSearchFocused={handleSearchFocused}
                        >
                          <div className={BLandingCss.searchIconWrapper}>
                            <SearchIconNew rootClassName={BLandingCss.searchIcon} />
                          </div>
                        </LocationAutocompleteInput>
                      )}
                    </Field>
                  );
                }}
              />
              <div className={BLandingCss.searchIconWrapper}>
                {locationLoader ? (
                  <div className={BLandingCss.locationLoader}>
                    <span></span>
                  </div>
                ) : (
                  <SearchIconNew rootClassName={BLandingCss.searchIcon} />
                )}
              </div>
              <div
                className={classNames(
                  BLandingCss.predictionsDropDown,
                  isFocused && !isMobileLayout && BLandingCss.isVisible
                )}
              >
                <ul className={BLandingCss.predictionsCurrentLocations}>
                  {defaultPredictionsWithoutCurrentLocation &&
                  predictions.length > 0 &&
                  predictions[0]['description']
                    ? predictions
                      .filter(
                        item =>
                          item['description'].includes('Singapore') ||
                          item['description'].includes('singapore')
                      )
                      .map(item)
                    : defaultPredictionsWithoutCurrentLocation.map((value, index) =>
                      item(value, index)
                    )}
                </ul>
                {!isUserSearching && userSearchHistory && userSearchHistory.length ? (
                  <div className={BLandingCss.searchLabels}>
                    <FormattedMessage id="LocationAutocompleteInput.recentSearches" />
                  </div>
                ) : null}
                {!isUserSearching && userSearchHistory && userSearchHistory.length ? (
                  <ul className={BLandingCss.predictions}>
                    {userSearchHistory &&
                    userSearchHistory.length > 0 &&
                    userSearchHistory[0]['description']
                      ? userSearchHistory.map((value, index) => item(value, index))
                      : userSearchHistory.map((value, index) => item(value, index))}
                  </ul>
                ) : null}
                {predictions && predictions.length > 0 && predictions[0]['description'] ? null : (
                  <div className={BLandingCss.searchLabels}>
                    <FormattedMessage id="LocationAutocompleteInput.searchSuggestion" />
                  </div>
                )}

                {userSearchHistory && userSearchHistory.length ? (
                  <ul className={BLandingCss.predictions}>
                    {predictions && predictions.length > 0 && predictions[0]['description']
                      ? null
                      : defaultPredictionsExcludingCurrentLocation.map((value, index) =>
                        item(value, index)
                      )}
                  </ul>
                ) : (
                  <ul className={BLandingCss.predictions}>
                    {predictions && predictions.length > 0 && predictions[0]['description']
                      ? null
                      : defaultPredictionsExcludingCurrentLocation.map((value, index) =>
                        item(value, index)
                      )}
                  </ul>
                )}
                {/* <GeocoderAttribution className={BLandingCss.attributionClassName} /> */}
              </div>
            </div>
          </div>
          {!isMobileLayout && (
            <div className={BLandingCss.insuranceListingWrapper}>
              <div
                className={BLandingCss.trustPilotLogo}
                style={{ flex: '0 0 33.333%', maxWidth: '33.3333%', padding: '0 15px' }}
              >
                <TrustBox />
              </div>
              <div className={BLandingCss.comprehensiveInsuranceBlock} onClick={openInsuranceModal}>
                <div className={BLandingCss.comprehensiveInsuranceBlockWrapper}>
                  <div className={BLandingCss.cILogo}>
                    <img src={insuranceLogo} alt="Drive lah Protection" />
                  </div>
                  <div className={BLandingCss.cIContent}>
                    <span>
                      Comprehensively insured with Drive lah protection<sup>TM</sup>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );

    const banner2Content = (
      <div className={BLandingCss.bannerContentBlock}>
        <h2
          className={classNames(
            BLandingCss.sectionImageTitleBLanding,
            BLandingCss.hourlyTitleBLanding,
            BLandingCss.banner2Title
          )}
        >
          <span>
            <i>Get the car delivered</i>
            <br />
            <i>right to your doorstep</i>
          </span>
        </h2>
        <div className={BLandingCss.searchBar}>
          <div className={BLandingCss.searchBarContent}>
            <p>
              With Drive lah, you can rent from a huge variety of nearby cars at great value. Fully
              insured+.
            </p>
          </div>
          {/* <button
            className={BLandingCss.viewDeliverCar}
            onClick={() => handleHomeDeliverySlide()}
          >
            View delivery enabled cars now!
            <span>
              <img src={arrowIcon} alt="" />
            </span>
          </button> */}
          <div className={BLandingCss.searchBarOuter} ref={searchDropdown} onClick={handleShowMenu}>
            <Form
              onSubmit={() => {}}
              render={() => {
                return (
                  <Field name="location">
                    {props => (
                      <LocationAutocompleteInput
                        {...props}
                        autoFocus={false}
                        className={BLandingCss.fieldLocationBLanding}
                        iconClassName={BLandingCss.hideSearchIconBLanding}
                        inputClassName={BLandingCss.inputClassNameBLanding}
                        predictionsClassName={BLandingCss.searchPredictionsBLanding}
                        onPredictionChange={setPredictions}
                        placeholder="Enter your location to view cars"
                        // inputRef={_handleSearchType}
                        onSearchFocused={handleSearchFocused}
                      >
                        <div className={BLandingCss.searchIconWrapper}>
                          <SearchIconNew rootClassName={BLandingCss.searchIcon} />
                        </div>
                      </LocationAutocompleteInput>
                    )}
                  </Field>
                );
              }}
            />
            <div className={BLandingCss.searchIconWrapper}>
              {locationLoader ? (
                <div className={BLandingCss.locationLoader}>
                  <span></span>
                </div>
              ) : (
                <SearchIconNew rootClassName={BLandingCss.searchIcon} />
              )}
            </div>
            <div
              className={classNames(
                BLandingCss.predictionsDropDown,
                isFocused && !isMobileLayout && BLandingCss.isVisible
              )}
            >
              <ul className={BLandingCss.predictionsCurrentLocations}>
                {defaultPredictionsWithoutCurrentLocation &&
                predictions.length > 0 &&
                predictions[0]['description']
                  ? predictions
                    .filter(
                      item =>
                        item['description'].includes('Singapore') ||
                        item['description'].includes('singapore')
                    )
                    .map(item)
                  : defaultPredictionsWithoutCurrentLocation.map((value, index) =>
                    item(value, index)
                  )}
              </ul>
              {!isUserSearching && userSearchHistory && userSearchHistory.length ? (
                <div className={BLandingCss.searchLabels}>
                  <FormattedMessage id="LocationAutocompleteInput.recentSearches" />
                </div>
              ) : null}
              {!isUserSearching && userSearchHistory && userSearchHistory.length ? (
                <ul className={BLandingCss.predictions}>
                  {userSearchHistory &&
                  userSearchHistory.length > 0 &&
                  userSearchHistory[0]['description']
                    ? userSearchHistory.map((value, index) => item(value, index))
                    : userSearchHistory.map((value, index) => item(value, index))}
                </ul>
              ) : null}
              {predictions && predictions.length > 0 && predictions[0]['description'] ? null : (
                <div className={BLandingCss.searchLabels}>
                  <FormattedMessage id="LocationAutocompleteInput.searchSuggestion" />
                </div>
              )}

              {userSearchHistory && userSearchHistory.length ? (
                <ul className={BLandingCss.predictions}>
                  {predictions && predictions.length > 0 && predictions[0]['description']
                    ? null
                    : defaultPredictionsExcludingCurrentLocation.map((value, index) =>
                      item(value, index)
                    )}
                </ul>
              ) : (
                <ul className={BLandingCss.predictions}>
                  {predictions && predictions.length > 0 && predictions[0]['description']
                    ? null
                    : defaultPredictionsExcludingCurrentLocation.map((value, index) =>
                      item(value, index)
                    )}
                </ul>
              )}
              {/* <GeocoderAttribution className={BLandingCss.attributionClassName} /> */}
            </div>
          </div>
          {!isMobileLayout && (
            <div className={BLandingCss.insuranceListingWrapper}>
              <div
                className={BLandingCss.trustPilotLogo}
                style={{ flex: '0 0 33.333%', maxWidth: '33.3333%', padding: '0 15px' }}
              >
                <TrustBox />
              </div>
              <div className={BLandingCss.comprehensiveInsuranceBlock} onClick={openInsuranceModal}>
                <div className={BLandingCss.comprehensiveInsuranceBlockWrapper}>
                  <div className={BLandingCss.cILogo}>
                    <img src={insuranceLogo} alt="" />
                  </div>
                  <div className={BLandingCss.cIContent}>
                    <span>
                      Comprehensively insured with Drive lah protection<sup>TM</sup>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );

    const bannerContent = [
      {
        id: 1,
        bannerContent: banner1Content,
        bannerBackgroundMobile: imageSource.bannerImage.bannerImageMobile,
        bannerBackgroundMobile2x: imageSource.bannerImage.bannerImageMobile2x,
        bannerBackground: imageSource.bannerImage.bannerImage,
        alt: 'Long and short term car rental in Singapore with Drive lah',
        bannerBackground2x: imageSource.bannerImage.bannerImage2x,
        slideReference: searchDropdown,
      },
    ];
    return (
      <div className={BLandingCss.sectionImagesBLanding}>
        {bannerContent && bannerContent?.length > 0 && (
          <ImageComponent
            key={bannerContent[0]?.id}
            src1x={isMobileLayout ? bannerContent[0]?.bannerBackgroundMobile : bannerContent[0]?.bannerBackground}
            src2x={isMobileLayout ? bannerContent[0]?.bannerBackgroundMobile2x : bannerContent[0]?.bannerBackground2x}
            alt={bannerContent[0]?.alt}
          >
            {banner1Content}
          </ImageComponent>

        )}
        {/*<Slider {...sliderConfig}>*/}
        {/*  {bannerContent &&*/}
        {/*    bannerContent.length > 0 &&*/}
        {/*    bannerContent.map(item => {*/}
        {/*      return (*/}
        {/*        <ImageComponent*/}
        {/*          key={item.id}*/}
        {/*          src1x={isMobileLayout ? item.bannerBackgroundMobile : item.bannerBackground}*/}
        {/*          src2x={isMobileLayout ? item.bannerBackgroundMobile2x : item.bannerBackground2x}*/}
        {/*          alt={item.alt}*/}
        {/*        >*/}
        {/*          {item.bannerContent}*/}
        {/*        </ImageComponent>*/}
        {/*      );*/}
        {/*    })}*/}
        {/*</Slider>*/}
      </div>
    );
  },
  (prev, next) => isEqual(prev, next)
);

export default SectionSearchBanner;
