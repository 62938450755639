import moment from 'moment';
import { types as sdkTypes } from './sdkLoader';
import { checkIfReferralCode } from './referralProgram';
import toPairs from 'lodash/toPairs';

const { LatLng, Money } = sdkTypes;

export const POSTAL_CODE_MIN_LENGTH = 4;
export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_MAX_LENGTH = 256;

const isNonEmptyString = val => {
  return typeof val === 'string' && val.trim().length > 0;
};

/**
 * Validator functions and helpers for Final Forms
 */

// Final Form expects and undefined value for a successful validation
const VALID = undefined;

export const required = message => value => {
  if (typeof value === 'undefined' || value === null) {
    // undefined or null values are invalid
    return message;
  }
  if (typeof value === 'string') {
    // string must be nonempty when trimmed
    return isNonEmptyString(value) ? VALID : message;
  }
  return VALID;
};

export const requiredStringNoTrim = message => value => {
  return typeof value === 'string' && value.length > 0 ? VALID : message;
};

// DEPRECATED in favor of required
export const requiredBoolean = message => value => {
  return typeof value === 'boolean' ? VALID : message;
};

// DEPRECATED in favor of required
export const requiredAndNonEmptyString = message => value => {
  return isNonEmptyString(value) ? VALID : message;
};

export const requiredFieldArrayCheckbox = message => value => {
  if (!value) {
    return message;
  }

  const entries = toPairs(value);
  const hasSelectedValues = entries.filter(e => !!e[1]).length > 0;
  return hasSelectedValues ? VALID : message;
};

export const minLength = (message, minimumLength) => value => {
  const hasLength = value && typeof value.length === 'number';
  return hasLength && value.length >= minimumLength ? VALID : message;
};

export const maxLength = (message, maximumLength) => value => {
  if (!value) {
    return VALID;
  }
  const hasLength = value && typeof value.length === 'number';
  return hasLength && value.length <= maximumLength ? VALID : message;
};

export const nonEmptyArray = message => value => {
  return value && Array.isArray(value) && value.length > 0 ? VALID : message;
};

export const autocompleteSearchRequired = message => value => {
  return value && value.search ? VALID : message;
};

export const autocompletePlaceSelected = message => value => {
  const selectedPlaceIsValid =
    value &&
    value.selectedPlace &&
    value.selectedPlace.address &&
    value.selectedPlace.origin instanceof LatLng;
  return selectedPlaceIsValid ? VALID : message;
};

export const bookingDateRequired = inValidDateMessage => value => {
  const dateIsValid = value && value.date instanceof Date;
  return !dateIsValid ? inValidDateMessage : VALID;
};

export const bookingDatesRequired = (inValidStartDateMessage, inValidEndDateMessage) => value => {
  const startDateIsValid = value && value.startDate instanceof Date;
  const endDateIsValid = value && value.endDate instanceof Date;

  if (!startDateIsValid) {
    return inValidStartDateMessage;
  } else if (!endDateIsValid) {
    return inValidEndDateMessage;
  } else {
    return VALID;
  }
};

// Source: http://www.regular-expressions.info/email.html
// See the link above for an explanation of the tradeoffs.
const EMAIL_RE = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const emailFormatValid = message => value => {
  return value && EMAIL_RE.test(value) ? VALID : message;
};

export const moneySubUnitAmountAtLeast = (message, minValue) => value => {
  return value instanceof Money && value.amount >= minValue ? VALID : message;
};

export const moneySubUnitAmountIsLessThan = (message, minValue) => value => {
  return value instanceof Money && value.amount < minValue ? VALID : message;
};
export const moneySubUnitAmountIsInBetween = (messages, minValue, maxValue) => value => {
  return value instanceof Money && value.amount < minValue
    ? messages.minMessage
    : value.amount > maxValue
    ? messages.maxMessage
    : VALID;
};

export const moneySubUnitAmountIsGreaterThan = (message, maxValue) => value => {
  return value instanceof Money && value.amount > maxValue ? VALID : message;
};

const parseNum = str => {
  const num = Number.parseInt(str, 10);
  return Number.isNaN(num) ? null : num;
};

export const ageAtLeast = (message, minYears, day, month) => year => {
  const dayNum = parseNum(year.day);
  const monthNum = parseNum(year.month);
  const yearNum = parseNum(year.year);

  // day, month, and year needs to be numbers
  if (dayNum !== null && monthNum !== null && yearNum !== null) {
    const now = moment();
    const age = new Date(yearNum, monthNum - 1, dayNum);
    const ageInYears = now.diff(moment(age), 'years', true);

    return age && age instanceof Date && ageInYears >= minYears ? VALID : message;
  }
  return message;
};

export const ageAtLeastSignUp = (message, minYears) => value => {
  const { year, month, day } = value;
  const dayNum = parseNum(day);
  const monthNum = parseNum(month);
  const yearNum = parseNum(year);
  // day, month, and year needs to be numbers
  if (dayNum !== null && monthNum !== null && yearNum !== null) {
    const now = moment();
    const age = new Date(yearNum, monthNum - 1, dayNum);
    const ageInYears = now.diff(moment(age), 'years', true);

    return age && age instanceof Date && ageInYears >= minYears ? VALID : message;
  }
  return message;
};

const EMAIL_REGEXP = /([A-Z0-9._%+-])+@([A-Z0-9.-])+\.[A-Z]{2,}/i;

const PHONE_NO_REGEXP = /([0-9]?[+-.,]?)*([0-9][+-., ]*)([0-9][+-., ]*){6}[0-9]([0-9]?[+-.,]?)*/;

const DOMAIN_REGEXP = new RegExp(
  '^(https?:\\/\\/)?' + // protocol
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
  '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
  '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$',
  'i'
);

const emailRegex = /\S+@\S+\.\S+/;
const phoneRegex = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
const domainRegex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;

export const checkDomainInMessage = message => {
  if (!message) {
    return false;
  }
  const splitStr = message.split(' ');
  const domainStrings = splitStr.filter(text => !!DOMAIN_REGEXP.test(text));
  return domainStrings.length ? true : false;
};

export const listDomainInMessage = message => {
  const splitStr = message.split(' ');
  const domainStrings = splitStr.filter(text => !!DOMAIN_REGEXP.test(text));
  return domainStrings;
};

export const doesMessageContainPhoneNumberOrEmail = message => {
  
  // const messageContainsValidEmail = message.match(EMAIL_REGEXP);
  // const messageContainsValidPhoneNo = message.match(PHONE_NO_REGEXP);
  // const messageContainsValidDomain = checkDomainInMessage(message);
  // if (messageContainsValidEmail || messageContainsValidPhoneNo || messageContainsValidDomain) return true;
  if (emailRegex.test(message) || phoneRegex.test(message) || domainRegex.test(message))
    return true;
  return false;
};

export const encodeMsgContainingPhoneNoOrEmailMaybe = message => {
  const redactedMessage = message
    .replace(emailRegex, '***')
    .replace(phoneRegex, '***')
    .replace(domainRegex, '***');
  
  // if (!doesMessageContainPhoneNumberOrEmail(message)) return message;
  let encodedMessage = message;
  // // Encode email
  // while (encodedMessage.match(EMAIL_REGEXP)) {
  //   const validEmail = encodedMessage.match(EMAIL_REGEXP)[0];
  //   let asterisks = '';
  //   for (var i = 0; i < validEmail.length; i++) {
  //     asterisks = asterisks.concat('*');
  //   }
  //   encodedMessage = encodedMessage.replace(validEmail, asterisks);
  // }
  // // Encode phone number
  // while (encodedMessage.match(PHONE_NO_REGEXP)) {
  //   const validPhoneNumber = encodedMessage.match(PHONE_NO_REGEXP)[0];
  //   let asterisks = '';
  //   for (var i = 0; i < validPhoneNumber.length; i++) {
  //     asterisks = asterisks.concat('*');
  //   }
  //   encodedMessage = encodedMessage.replace(validPhoneNumber, asterisks);
  // }
  // while (checkDomainInMessage(encodedMessage)) {
  //   const validDomainNames = listDomainInMessage(encodedMessage);
  //   for (var i = 0; i < validDomainNames.length; i++) {
  //     encodedMessage = encodedMessage.replace(validDomainNames[i], validDomainNames[i].replace(/./g, '*'));
  //   }
  // }
  encodedMessage = redactedMessage.concat(
    ' (contact details cannot be shared. Once trip is confirmed, contact details can be found on the trip page)'
  );
  return encodedMessage;
};

export const validBusinessURL = message => value => {
  if (typeof value === 'undefined' || value === null) {
    return message;
  }

  const disallowedChars = /[^-A-Za-z0-9+&@#/%?=~_|!:,.;()]/;
  const protocolTokens = value.split(':');
  const includesProtocol = protocolTokens.length > 1;
  const usesHttpProtocol = includesProtocol && !!protocolTokens[0].match(/^(https?)/);

  const invalidCharacters = !!value.match(disallowedChars);
  const invalidProtocol = !(usesHttpProtocol || !includesProtocol);
  // Stripe checks against example.com
  const isExampleDotCom = !!value.match(/^(https?:\/\/example\.com|example\.com)/);
  const isLocalhost = !!value.match(/^(https?:\/\/localhost($|:|\/)|localhost($|:|\/))/);
  return invalidCharacters || invalidProtocol || isExampleDotCom || isLocalhost ? message : VALID;
};

export const validSsnLast4 = message => value => {
  return value.length === 4 ? VALID : message;
};

export const validHKID = message => value => {
  // Accept value 000000000 for testing Stripe
  if (value.length === 9 && value.match(/([0]{9})/)) {
    return VALID;
  }

  // HKID format example: AB364912(5)
  // ID can start with one or two letters and the check digit in the end can be in brackets or not
  if (value.length < 8) {
    return message;
  }

  // Handle possible brackets in value
  if (value.charAt(value.length - 3) === '(' && value.charAt(value.length - 1) === ')') {
    value = value.substring(0, value.length - 3) + value.charAt(value.length - 2);
  }
  value = value.toUpperCase();

  // Check that pattern is correct and split value to array
  const hkidPattern = /^([A-Z]{1,2})([0-9]{6})([A0-9])$/;
  const matchArray = value.match(hkidPattern);

  if (!matchArray) {
    return message;
  }

  const charPart = matchArray[1];
  const numPart = matchArray[2];
  const checkDigit = matchArray[3];

  // Calculate the checksum for character part.
  // Transfer letters to numbers so that A=10, B=11, C=12 etc.
  // If there is only one letter in the ID use 36 as the first value
  // Total calculation is weighted so that 1st digit is x9, 2nd digit x8, 3rd digit x7 etc.

  const strValidChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let checkSum = 0;

  if (charPart.length === 2) {
    checkSum += 9 * (10 + strValidChars.indexOf(charPart.charAt(0)));
    checkSum += 8 * (10 + strValidChars.indexOf(charPart.charAt(1)));
  } else {
    checkSum += 9 * 36;
    checkSum += 8 * (10 + strValidChars.indexOf(charPart));
  }

  // Calculate the checksum for numeric part

  for (let i = 0, j = 7; i < numPart.length; i++, j--) {
    checkSum += j * numPart.charAt(i);
  }

  // Verify the check digit
  const remaining = checkSum % 11;
  let verify = remaining === 0 ? 0 : 11 - remaining;
  verify = verify.toString();
  const isValid = verify === checkDigit || (verify === 10 && checkDigit === 'A');

  return isValid ? VALID : message;
};

export const isValidReferralCode = message => value => {
  if (typeof value === 'undefined') return VALID;
  if (value.trim().length === 0 || checkIfReferralCode(value.trim())) return VALID;
  return message;
};

export const validSGID = message => value => {
  // Accept value 000000000 for testing Stripe
  if (value.length === 9 && value.match(/([0]{9})/)) {
    return VALID;
  }

  if (value.length !== 9) return message;

  value = value.toUpperCase();

  let i,
    icArray = [];
  for (i = 0; i < 9; i++) {
    icArray[i] = value.charAt(i);
  }

  icArray[1] = parseInt(icArray[1], 10) * 2;
  icArray[2] = parseInt(icArray[2], 10) * 7;
  icArray[3] = parseInt(icArray[3], 10) * 6;
  icArray[4] = parseInt(icArray[4], 10) * 5;
  icArray[5] = parseInt(icArray[5], 10) * 4;
  icArray[6] = parseInt(icArray[6], 10) * 3;
  icArray[7] = parseInt(icArray[7], 10) * 2;

  let weight = 0;
  for (i = 1; i < 8; i++) {
    weight += icArray[i];
  }

  let offset = icArray[0] === 'T' || icArray[0] === 'G' ? 4 : 0;
  let temp = (offset + weight) % 11;

  let st = ['J', 'Z', 'I', 'H', 'G', 'F', 'E', 'D', 'C', 'B', 'A'];
  let fg = ['X', 'W', 'U', 'T', 'R', 'Q', 'P', 'N', 'M', 'L', 'K'];

  let theAlpha;
  if (icArray[0] === 'S' || icArray[0] === 'T') {
    theAlpha = st[temp];
  } else if (icArray[0] === 'F' || icArray[0] === 'G') {
    theAlpha = fg[temp];
  }

  return icArray[8] === theAlpha ? VALID : message;
};

export const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), VALID);

export const minValue = (message, min) => value => {
  return parseInt(value) < min ? message : VALID;
};

export const maxValue = (message, max) => value => {
  return parseInt(value) > max ? message : VALID;
};

export const notFoundValue = (message, values = []) => value => {
  if (values.find(item => item.label === value)) return VALID;
  return message;
};
