import React from 'react';
import css from './GojekHostYourCarLandingPage.css';
// import classNames from 'classnames';
// import { FormattedMessage } from 'react-intl';
import bannerLogo from '../../assets/GojekBanner/banner-logo.svg';
import bannerLogoMobile from '../../assets/GojekBanner/banner-logo-mobile@2x.png';
import GojekLogo from '../../assets/GojekBanner/gojek-rentals-desktop.svg';
import GojekLogoWhite from '../../assets/GojekBanner/gojek-rentals-mobile.svg';
import bannerBackground from '../../assets/GojekBanner/banner-background.png';
import approveBlueIcon from '../../assets/GojekBanner/approval-symbol-in-badge-blue@2x.png';
import saveMoneyIcon from '../../assets/GojekBanner/noun_Save Money_776848@2x.png';
import easyAccessIcon from '../../assets/GojekBanner/noun_easy_3428320@2x.png';
import approveBlueIconMobile from '../../assets/GojekBanner/approval-symbol-in-badgeM@2x.png';
import saveMoneyIconMobile from '../../assets/GojekBanner/noun_Save Money_776848M@2x.png';
import easyAccessIconMobile from '../../assets/GojekBanner/1024px-Dollar_sign_in_circle.svg@2x.png';

const GojekIntroBanner = ({ isMobileLayout, handleSignupRedirect }, ...props) => {
  return (
    <div className={css.bannerIntroSection}>
      <div className={css.container}>
        <div className={css.row}>
          <div className={css.column6}>
            <div className={css.bannerIntroContent}>
              <div className={css.bannerLogoBlock}>
                {!isMobileLayout && <img src={bannerLogo} alt="" />}
              </div>
              <div className={css.mobileBannerLogo}>
                {isMobileLayout && <img src={bannerLogoMobile} alt="" />}
              </div>
              <h1>Is your car sitting idle?</h1>
              <h2>Rent out your car, and make some extra money, only with Drive lah</h2>
              <ul className={css.bannerListing}>
                <li>
                  <span className={css.pointIcons}>
                    {isMobileLayout ? (
                      <img src={approveBlueIconMobile} alt="Approved by LTA" />
                    ) : (
                      <img src={approveBlueIcon} alt="Approved by LTA" />
                    )}
                  </span>
                  Approved by{' '}
                  <span className={css.contentIcon}>
                    {isMobileLayout ? (
                      <img src={GojekLogoWhite} alt="Go jek logo" />
                    ) : (
                      <img src={GojekLogo} alt="Go jek logo" />
                    )}
                  </span>
                </li>
                <li>
                  <span className={css.pointIcons}>
                    {isMobileLayout ? (
                      <img src={saveMoneyIconMobile} alt="" />
                    ) : (
                      <img src={saveMoneyIcon} alt="" />
                    )}
                  </span>
                  Earn passive income during your off days
                </li>
                <li>
                  <span className={css.pointIcons}>
                    {isMobileLayout ? (
                      <img src={easyAccessIconMobile} alt="" />
                    ) : (
                      <img src={easyAccessIcon} alt="" />
                    )}
                  </span>
                  Sign up now to get $100 Drive lah credits
                </li>
              </ul>
              <button onClick={e => handleSignupRedirect(e)} className={css.bannerButton}>
                Start earning now
              </button>

            </div>
          </div>
        </div>
      </div>
      <div className={css.bannerIntroImageSection}>
        <img src={bannerBackground} alt="Car sharing with more choice and less hassle | Drive lah" />
      </div>
    </div>
  );
};

export default GojekIntroBanner;
