import classNames from 'classnames';
import React from 'react';
import css from './GojekRentACarLandingPage.css';
// import { FormattedMessage } from 'react-intl';
import checkBlue from '../../assets/GojekBanner/approval-symbol-in-badge11@2x.png';
import checkRed from '../../assets/GojekBanner/approval-symbol-in-badge@2x1.png';
import image4 from '../../assets/GojekBanner/image4.png';
import image5 from '../../assets/GojekBanner/image5.png';
import image6 from '../../assets/GojekBanner/image6.png';
import crossBlue from '../../assets/GojekBanner/noun_cancel_1888477@2x.png';
import crossRed from '../../assets/GojekBanner/noun_cancel_1888477red@2x.png';
import insuranceLogo from '../../assets/insurance-logo.svg';
import { TrustBox } from '../../components';

const DriveLahVsCompanies = ({ isMobileLayout }) => {
  const gridContent = [
    {
      contentImage: image4,
      contentTitle: 'Flexibility',
      contentDescription:
        ' Got time on your hands? You can rent a car for a few hours, a day or a week with Drive lah. No Contract, zero commitments.',
    },
    {
      contentImage: image5,
      contentTitle: 'Affordability',
      contentDescription:
        ' Rent that is 30-40% more affordable than other rental options. Now, you don’t have to worry about profits.',
    },
    {
      contentImage: image6,
      contentTitle: 'Availability ',
      contentDescription:
        ' With a huge fleet of cars, there’ll be a car near you, always. Rent a car in minutes.',
    },
  ];
  const sliderConfig = {
    // className: css.slider,
    infinite: false,
    slidesToScroll: 1,
    slidesToShow: 4,
    initialSlide: 0,
    infinite: false,
    dots: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          initialSlide: 0,
          infinite: false,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: true,
          arrows: false,
        },
      },
    ],
  };
  return (
    <div className={css.driveLahVsCompanies}>
      <div className={css.container}>
        <div className={classNames(css.row, css.secTitle)}>
          {isMobileLayout && (
            <div className={css.insuranceListingWrapper}>
              <div
                className={css.trustPilotLogoMobile}
                style={{ flex: '0 0 45%', maxWidth: '45%' }}
              >
                <TrustBox />
              </div>
              {/* <div style={{ flex: '0 0 40%', maxWidth: '40%', padding: '0 0 0 15px' }}>
            <div style={{ marginBottom: 10 }}>
              <div className={css.insuranceListingText}>Insurance provided by</div>
              <img
                className={css.insuranceListingImage}
                src={insurance}
                alt="Tokio Marine"
              />
            </div>
            <div>
              <div className={css.insuranceListingText}>Approved by</div>
              <img
                className={css.approvedByCompanyLogo}
                src={landTransport}
                alt="Land Transport"
              />
            </div>
          </div> */}
              <div style={{ flex: '0 0 55%', maxWidth: '55%' }} className={classNames(css.comprehensiveInsuranceBlock, css.channelListingBlock)}>
                <div className={css.comprehensiveInsuranceBlockWrapper}>
                  <div className={css.cILogo}>
                    <img src={insuranceLogo} alt="Insurance Logo" />
                  </div>
                  <div className={css.cIContent}>
                    <span className={css.channelListingSpan}>
                      Comprehensively insured with Drive lah protection<sup>TM</sup>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className={classNames(css.column6, css.contentHeading)}>
            <h2>
              Drive lah vs <br /> Other Rental Companies
            </h2>
            <p>Why choosing Drive lah is a no-brainer?</p>
          </div>
          <div className={classNames(css.column6, css.contentGrid)}>
            <table className={css.comparisonTable}>
              <thead>
                <tr>
                  <th></th>
                  <th className={css.drivelahPoints} width="255">
                    <span className={css.curveBorder}>Drive lah</span>
                  </th>
                  <th className={css.otherPoints} width="255">
                    <span className={css.curveBorder}>Other Rental Companies</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className={css.pointsTitle} align="right">
                    Price
                  </td>
                  <td className={css.drivelahPoints}>
                    <strong>$4/hr &amp; $29/day</strong> no mileage cap
                  </td>
                  <td className={css.otherPoints}>$8/hr &amp; $60/day limited mileage</td>
                </tr>
                <tr>
                  <td className={css.pointsTitle} align="right">
                    Simple Rent Structure
                  </td>
                  <td className={css.drivelahPoints}>
                    <div className={css.contentData}>
                      <span className={css.icon}>
                        <img src={checkBlue} alt="Approved by LTA" />
                      </span>
                      Same all day
                    </div>
                  </td>
                  <td className={css.otherPoints}>
                    <div className={css.contentData}>
                      <span className={css.icon}>
                        <img src={crossRed} alt="Cross Tick Icon" />
                      </span>
                      Duration, km &amp; time of the day
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className={css.pointsTitle} align="right">
                    Long Lease Commitment
                  </td>
                  <td className={css.drivelahPoints}>
                    <div className={css.contentData}>
                      <span className={css.icon}>
                        <img src={crossBlue} alt="Cross Tick Icon" />
                      </span>
                      None
                    </div>
                  </td>
                  <td className={css.otherPoints}>
                    <div className={css.contentData}>
                      <span className={css.icon}>
                        <img src={checkRed} alt="Cross Tick Icon" />
                      </span>
                      Fixed Contract
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className={css.pointsTitle} align="right">
                    Wide Car Variety
                  </td>
                  <td className={css.drivelahPoints}>
                    <div className={css.contentData}>
                      <span className={css.icon}>
                        <img src={checkBlue} alt="Approved by LTA" />
                      </span>
                      50+ models
                    </div>
                  </td>
                  <td className={css.otherPoints}>
                    <div className={css.contentData}>
                      <span className={css.icon}>
                        <img src={crossRed} alt="Cross Tick Icon" />
                      </span>
                      4-5 models
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className={css.pointsTitle} align="right">
                    Car pickup points
                  </td>
                  <td className={css.drivelahPoints}>
                    <div className={css.contentData}>
                      <span className={css.icon}>
                        <img src={checkBlue} alt="Approved by LTA" />
                      </span>
                      Always nearby
                    </div>
                  </td>
                  <td className={css.otherPoints}>
                    <div className={css.contentData}>
                      <span className={css.icon}>
                        <img src={crossRed} alt="Cross Tick Icon" />
                      </span>
                      Limited
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className={css.pointsTitle} align="right">
                    Hidden Charges
                  </td>
                  <td className={css.drivelahPoints}>
                    <div className={css.contentData}>
                      <span className={css.icon}>
                        <img src={crossBlue} alt="Cross Tick Icon" />
                      </span>
                      None
                    </div>
                  </td>
                  <td className={css.otherPoints}>
                    <div className={css.contentData}>
                      <span className={css.icon}>
                        <img src={checkRed} alt="Cross Tick Icon" />
                      </span>
                      Yes
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className={css.pointsTitle} align="right">
                    Late Return Charges
                  </td>
                  <td className={css.drivelahPoints}>
                    <div className={css.contentData}>
                      <span className={css.icon}>
                        <img src={crossBlue} alt="Cross Tick Icon" />
                      </span>
                      Grace Period
                    </div>
                  </td>
                  <td className={css.otherPoints}>
                    <div className={css.contentData}>
                      <span className={css.icon}>
                        <img src={checkRed} alt="Cross Tick Icon" />
                      </span>
                      Strict Terms
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td className={css.pointsTitle} align="right"></td>
                  <td className={css.drivelahPoints}>
                    <span className={css.curveBorder}></span>
                  </td>
                  <td className={css.otherPoints}>
                    <span className={css.curveBorder}></span>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DriveLahVsCompanies;
