import React, { memo } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Modal, UserCard } from '../../components';
import { EnquiryForm } from '../../forms';

import css from './ListingPage.css';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';

const SectionHostMaybe = memo(props => {
  const {
    title,
    monthlyTimeSlots,
    listing,
    initialValues,
    authorDisplayName,
    onContactUser,
    isEnquiryModalOpen,
    onCloseEnquiryModal,
    sendEnquiryError,
    sendEnquiryInProgress,
    onSubmitEnquiry,
    onFetchTimeSlots,
    timeSlots,
    localTimeZone,
    currentUser,
    onManageDisableScrolling,
    buttonId,
    onViewProfile,
  } = props;

  if (!listing.author) {
    return null;
  }

  return (
    <div id="host" className={css.sectionHost}>
      <h2 className={css.yourHostHeading}>
        <FormattedMessage id="ListingPage.yourHostHeading" />
      </h2>
      <UserCard user={listing.author} currentUser={currentUser} onContactUser={onContactUser} onViewProfile={onViewProfile} />
      <Modal
        id="ListingPage.enquiry"
        contentClassName={css.enquiryModalContent}
        isOpen={isEnquiryModalOpen}
        onClose={onCloseEnquiryModal}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <EnquiryForm
          currentUser={currentUser}
          timeSlots={timeSlots}
          initialValues={initialValues}
          onFetchTimeSlots={onFetchTimeSlots}
          monthlyTimeSlots={monthlyTimeSlots}
          localTimeZone={localTimeZone}
          className={css.enquiryForm}
          submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
          listingTitle={title}
          listing={listing}
          authorDisplayName={authorDisplayName}
          sendEnquiryError={sendEnquiryError}
          onSubmit={onSubmitEnquiry}
          inProgress={sendEnquiryInProgress}
          buttonId={buttonId}
        />
      </Modal>
    </div>
  );
}, (prev, next) => {
    return isEqual(
      pick(prev, [
        'title',
        'listing',
        'authorDisplayName',
        'isEnquiryModalOpen',
        'sendEnquiryError',
        'sendEnquiryInProgress',
        'currentUser',
      ]),
      pick(next, [
        'title',
        'listing',
        'authorDisplayName',
        'isEnquiryModalOpen',
        'sendEnquiryError',
        'sendEnquiryInProgress',
        'currentUser',
      ]),
    );
});

export default SectionHostMaybe;
