import React, { useEffect } from 'react';
import config from '../../../config';
import { formatMoney } from '../../../util/currency';
import { propTypes } from '../../../util/types';
import css from '../LongTermRentalListingPage.css';
import { types as sdkTypes } from '../../../util/sdkLoader';

const { Money } = sdkTypes;

const LineItemTotalPrice = props => {
  const {
    listing,
    intl,
    additionalCost = 0,
    insuranceCost = 0,
    additionalDriverCost = 0,
    setTotalPrice,
  } = props;
  const { flexPlusPrice } = listing.attributes.publicData;

  useEffect(() => {

    if (flexPlusPrice) {
      const flexTotalPrice = new Money(
        ( parseFloat(flexPlusPrice) +  parseFloat( additionalCost * 100) + parseFloat( insuranceCost * 100) + parseFloat( additionalDriverCost * 100)),
        config.currency
      );

      if (flexTotalPrice) setTotalPrice(flexTotalPrice);
    }
  }, [flexPlusPrice, additionalCost, insuranceCost, additionalDriverCost]);

  if (!flexPlusPrice) {
    return null;
  }
  const flexPlusBasePrice = new Money(
    ( parseFloat(flexPlusPrice) + parseFloat(additionalCost * 100) + parseFloat(insuranceCost * 100) + parseFloat(additionalDriverCost * 100)) ,
    config.currency
  );

  return (
    <div className={css.monthWisePrice}>
      <span className={css.monthlyPrice}>Monthly price</span>
      <span className={css.price}>{formatMoney(intl, flexPlusBasePrice, 1)}</span>
    </div>
  );
};

LineItemTotalPrice.propTypes = {
  transaction: propTypes.transaction.isRequired,
  unitType: propTypes.bookingUnitType,
};

export default LineItemTotalPrice;
