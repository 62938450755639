import React, { memo, useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { FieldTextInput, SecondaryButton } from '../../components';
import Tag from '../../assets/landingPageNew/tag.svg';
import StarPromotionIcon from '../../components/Icons/StarPromotionIcon';
// import Location from '../../assets/landingPageNew/locate-fixed.svg';
import css from './GuestLandingPage.css';
import { Column, Container, Row } from '../../components/Grids/Grid';
import {
  composeValidators,
  emailFormatValid,
  maxLength,
  minLength,
  PASSWORD_MAX_LENGTH,
  PASSWORD_MIN_LENGTH,
  required,
  requiredStringNoTrim,
} from '../../util/validators';
import { useIntl } from 'react-intl';
import { formatMoney } from '../../util/currency';
import config from '../../config';
import { types } from '../../util/sdkLoader';
import classNames from 'classnames';

const { Money } = types;

const BannerSection = memo(props => {
  const [resetError, setResetError] = useState(false);
  const handleSearchFocused = value => {};
  const intl = useIntl();
  const emailRequiredMessage = intl.formatMessage({
    id: 'SignupForm.emailRequired',
  });
  const emailRequired = required(emailRequiredMessage);
  const emailInvalidMessage = intl.formatMessage({
    id: 'SignupForm.emailInvalid',
  });
  const emailValid = emailFormatValid(emailInvalidMessage);

  const passwordMaxLengthMessage = intl.formatMessage(
    {
      id: 'SignupForm.passwordTooLong',
    },
    {
      maxLength: PASSWORD_MAX_LENGTH,
    }
  );
  const passwordRequiredMessage = intl.formatMessage({
    id: 'SignupForm.passwordRequired',
  });
  const passwordMinLengthMessage = intl.formatMessage(
    {
      id: 'SignupForm.passwordTooShort',
    },
    {
      minLength: PASSWORD_MIN_LENGTH,
    }
  );

  const passwordMinLength = minLength(passwordMinLengthMessage, PASSWORD_MIN_LENGTH);
  const passwordMaxLength = maxLength(passwordMaxLengthMessage, PASSWORD_MAX_LENGTH);
  const passwordRequired = requiredStringNoTrim(passwordRequiredMessage);
  const passwordValidators = composeValidators(
    passwordRequired,
    passwordMinLength,
    passwordMaxLength
  );

  const _handleTimedError = (e, hasError) => {
    if(e && hasError){
      setTimeout(() => {
        setResetError(true)
      }, 5000)
    } else {
      setResetError(false)
    }

  }
  const amount =
    props.signUpCodeAmount && formatMoney(intl, new Money(props.signUpCodeAmount, config.currency));
  return (
    <>
      <div className={css.bannerSection}>
        <Container>
          <Row>
            <Column large={6} small={12}>
              <div className={css.searchBoxContent}>
                <div className={css.bannerContent}>
                  <h1 className={css.bannerHead}>
                    Rent your <br />
                    neighbour’s car.
                  </h1>
                  <p className={css.bannerDesc}>
                    {/* Save money, Unlock it 24/7 with the app and go! */}
                    You can rent from a huge variety of nearby cars 24X7, at great value.
                  </p>
                </div>
                <div className={css.signupForm}>
                  <FinalForm
                    {...props}
                    render={({ handleSubmit, values }) => {
                      const emailValue = values.email;
                      return (
                        <div className={css.root}>
                          { props.signUpCodeAmount && amount && (
                          
                            <div className={css.info}>
                              {/* <img src={Tag} /> */}
                              <StarPromotionIcon className={css.startPercentage} />
                              <span>Signup & get free credits worth <strong>{amount}</strong> </span>
                            </div>
                          )}
                          {/* <div className={css.field}>
                            <FieldTextInput
                              type="email"
                              name="email"
                              autoComplete="email"
                              label=""
                              getUnFocused={(e, d) => _handleTimedError(e,d)}
                              errorClassName={classNames(resetError ? css.timedError : '')}
                              placeholder="Your email"
                              validate={composeValidators(emailRequired, emailValid)}
                              className={css.input}
                              timedError={false}
                              id="email"
                            />
                          </div> */}
                          {/* <div className={css.field}>
                            <FieldTextInput
                              className={css.password}
                              type="password"
                              name="password"
                              autoComplete="new-password"
                              placeholder="Password"
                              label=""
                              validate={passwordValidators}
                              id="password"
                            />
                          </div> */}
                          <div className={css.buttonContainer}>
                            <SecondaryButton onClick={handleSubmit} className={css.submitBtn}>
                              Signup now
                            </SecondaryButton>
                          </div>

                          {/* <div className={css.smallText}>
              By signing up, you agree to our{' '}
              <NamedLink className={css.termOfUse} name="TermsOfServicePage">
                T&Cs
              </NamedLink>
              .
            </div> */}
                          {/* <div className={css.smallText}>
                            {props.pageName === 'HostSignupGroundPlan'
                              ? '*Redeemable when you host your first drive'
                              : props.hideMessage
                              ? ''
                              : 'Your credits will be available to you to withdraw once you create a listing and complete your first trip as a host.'}
                          </div> */}
                        </div>
                      );
                    }}
                  />
                </div>
              </div>
            </Column>
          </Row>
        </Container>
      </div>
    </>
  );
});

export default BannerSection;
