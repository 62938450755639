import React from 'react';
import css from './AntiCompetitionLandingPage.css';
import { IconSpinner, NamedLink } from '../../components';
import { formatMoney } from '../../util/currency';
import config from '../../config';
import { types } from '../../util/sdkLoader';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { initiateEventFromListing } from '../../util/gtm/gtmHelpers';
import { EVENT_SEARCH_PERFORMED } from '../../util/gtm/gtmConstants';
import { default as BannerSlider } from 'react-slick';
import imageSource from '../../util/imageSource';
import { gSend, GTAG_ACTIONS } from '../../util/gtag';

const { Money } = types;

const SectionHeroTexts = ({
  handleSignupRedirect,
  loading,
  signUpCodeAmount,
  slideDescription,
  slide
}) => {
  const intl = useIntl();
  const currentUser = useSelector(state => state.user.currentUser);
  const buttonText = loading ? (
    <div className={css.loadingWrapper}>
      <IconSpinner className={css.loadingSpinner} />
    </div>
  ) : currentUser && currentUser.id ? (
    'Find your ideal car'
  ) : signUpCodeAmount && signUpCodeAmount > 0 ? (
    `Sign up ${
      signUpCodeAmount
        ? `& get ${formatMoney(intl, new Money(signUpCodeAmount, config.currency))} credits`
        : '$0'
    }`
  ) : (
    <FormattedMessage id={'HostSignUpPromoLandingPage.signUpNow'} />
  );
  return (
    <div className={css.sectionHeroTexts}>
      <div className={css.title}>
        SAME PRICE, <span className={css.subText}>MORE CAR!</span>
      </div>
      <div className={css.carsSliderMobile}>
        <div className={css.carsSlider}>
          <div className={css.carThumb}>
            <img src={slide.slideCarThumbnail} alt="Our interactive pricing comparison" />
            <div className={css.carDiscount}>
              Save <strong>${slide.slideCarDiscount}</strong> per day
            </div>
          </div>
        </div>
      </div>
      <div className={css.description}>
        <p>{slideDescription}</p>
      </div>
      {currentUser && currentUser.id ? (
        <div className={css.signUpTitle}>
          <NamedLink
            name="SearchPage"
            to={{
              search:
                '?address=Singapore&bounds=1.4708809%2C104.04157989999999%2C1.216611%2C103.60650989999999',
            }}
            className={css.btnMore}
            onClick={() => {
              // console.log("content slider banner called");
              // initiateEventFromListing({
              //   props: {
              //     searchParams: {
              //       bounds: {
              //         _sdkType: 'LatLngBounds',
              //         ne: {
              //           _sdkType: 'LatLng',
              //           lat: 1.4708809,
              //           lng: 104.04157989999999,
              //         },
              //         sw: {
              //           _sdkType: 'LatLng',
              //           lat: 1.216611,
              //           lng: 103.60650989999999,
              //         },
              //       },
              //     },
              //   },
              //   event: EVENT_SEARCH_PERFORMED,
              // });
              gSend(GTAG_ACTIONS.ACTION_SEARCH, {
                // TODO: Assign proper details.
                search_location: `Singapore&bounds=1.4708809%2C104.04157989999999%2C1.216611%2C103.60650989999999`,
                search_pickupdate: `NA`,
                search_pickuptime: `NA`,
                search_dropoffdate: `NA`,
                search_dropofftime: `NA`,
              });
            }}
          >
            {buttonText}
          </NamedLink>
        </div>
      ) : (
        <div className={css.signUpTitle}>
          <NamedLink name="SignupPage" onClick={handleSignupRedirect} className={css.btnMore}>
            {buttonText}
          </NamedLink>
        </div>
      )}
    </div>
  );
};

const ContentSliderBanner = ({ handleSignupRedirect, loading, signUpCodeAmount }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1500,
    autoplaySpeed: 6500,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    autoplay: true,
    pauseOnFocus: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          speed: 1500,
          autoplaySpeed: 6500,
        },
      },
    ],
  };

  const slides = [
    {
      id: 0,
      slideTitle: 'SAME PRICE, MORE CAR!',
      slideDescription: 'Compared to Audi A3 rates on other car sharing platforms',
      slideCarDiscount: 25,
      slideCarThumbnail: imageSource.antiCompetition1.slide1Thumbnail,
    },
    {
      id: 1,
      slideTitle: 'SAME PRICE, MORE CAR!',
      slideDescription: 'Compared to Honda Vezel rates on other car sharing platforms',
      slideCarDiscount: 65,
      slideCarThumbnail: imageSource.antiCompetition1.slide2Thumbnail,
    },
    {
      id: 2,
      slideTitle: 'SAME PRICE, MORE CAR!',
      slideDescription: 'Compared to Mitsubishi Attrage rates on other car sharing platforms',
      slideCarDiscount: 40,
      slideCarThumbnail: imageSource.antiCompetition1.slide3Thumbnail,
    },
  ];

  return (
    <div className={css.bannerCarousel}>
      <BannerSlider {...settings}>
        {slides &&
          slides.length > 0 &&
          slides.map((slide, index) => {
            return (
              <div>
                <div className={css.contentSliderWrapper}>
                  <div className={css.container}>
                    <div className={css.heroContent}>
                      <div>
                        <SectionHeroTexts
                          loading={loading}
                          signUpCodeAmount={signUpCodeAmount}
                          handleSignupRedirect={handleSignupRedirect}
                          slideDescription={slide.slideDescription}
                          slide={slide}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={css.carsSlider}>
                    <div className={css.carThumb}>
                      <img src={slide.slideCarThumbnail} alt="Our interactive pricing comparison" />
                      <div className={css.carDiscount}>
                        Save <strong>${slide.slideCarDiscount}</strong> per day
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </BannerSlider>
    </div>
  );
};

export default ContentSliderBanner;
