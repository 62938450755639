import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import donationBannerImage from '../../assets/donationPage/donation-banner-bg.jpg';
import {
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Modal,
  Page,
} from '../../components';
import config from '../../config';
import { manageDisableScrolling, manageTurnoffPromoteBar } from '../../ducks/UI.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { withViewport } from '../../util/contextHelpers';
import { EVENT_BROWSE_HOMEPAGE } from '../../util/gtm/gtmConstants';
import { pushGTMBrowseEvent } from '../../util/gtm/gtmHelpers';
import { injectIntl, intlShape } from '../../util/reactIntl';
import TripDonatePage from '../TripDonatePage/TripDonatePage';
import DonationBanner from './DonationBanner';
import DonationContribute from './DonationContribute';
import DonationCrisis from './DonationCrisis';
import css from './DonationPage.css';
import DonationStorySection from './DonationStorySection';
import MakeADonation from './MakeADonation';

const MAX_MOBILE_SCREEN_WIDTH = 768;

export const DonationPageComponent = props => {
  const { intl, scrollingDisabled, viewport, onManageDisableScrolling, currentUser,isAuthenticated } = props;
  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'DonationPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'DonationPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${donationBannerImage}`;
  const [isDonateModalOpen, setIsDonateModalOpen] = useState(false);
  const onPushGTMEvent = (event, buttonId) => {
    // pushGTMBrowseEvent({
    //   props,
    //   event,
    //   buttonId,
    // });
  };

  useEffect(() => {
    onPushGTMEvent(EVENT_BROWSE_HOMEPAGE);
  }, []);

  let isMobileLayout;
  try {
    if (viewport) {
      isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
    }
  } catch (error) {

  }

  return (
    <Page
      className={classNames(css.root, css.donationPageRoot)}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: donationBannerImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${donationBannerImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          {/* <TopbarContainer notApplySearch={true} currentPage="DonationPage" /> */}
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.contentWrapper}>
          <DonationBanner currentUser={currentUser}
          isAuthenticated={isAuthenticated}setIsDonateModalOpen={setIsDonateModalOpen} />
          <DonationCrisis />
          <DonationContribute />
          <MakeADonation
            isMobileLayout={isMobileLayout}
            setIsDonateModalOpen={setIsDonateModalOpen}
            currentUser={currentUser}
            isAuthenticated={isAuthenticated}
          />
          <DonationStorySection />
          <Modal
            id="donateModal"
            isOpen={isDonateModalOpen}
            onClose={() => {
              isDonateModalOpen && setIsDonateModalOpen(false);
            }}
            containerClassName={css.modalContainer}
            onManageDisableScrolling={onManageDisableScrolling}
            closeButtonMessage="Close"
          >
            <div className={css.insuranceListingWrapper}>
              <TripDonatePage
                transactInModal={true}
                currentUser={currentUser}
                handleGoBack={() => {
                  setIsDonateModalOpen(false);
                }}
              />
            </div>
          </Modal>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>{/* <Footer /> */}</LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool, object, number, shape } = PropTypes;

DonationPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const { currentUser } = state.user;
  return {
    isAuthenticated,
    currentUser,
  };
};

const mapDispatchToProps = dispatch => ({
  onTurnoffPromoteBar: flagVal => dispatch(manageTurnoffPromoteBar(flagVal)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const DonationPage = compose(
  withViewport,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(DonationPageComponent);

DonationPage.loadData = () => {
  return fetchCurrentUser();
};

export default DonationPage;
