import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Slider from 'react-slick';
import { ListingCardV2, NamedLink } from '../../components';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import css from './SectionFeaturedFlexCar.css';

const SectionFeaturedFlexCar = () => {
  const featuredFlexCarIds = useSelector(state => state.SearchPage.featuredFlexCarIds);
  const fetchFeaturedFlexCarInProgress = useSelector(
    state => state.SearchPage.fetchFeaturedFlexCarInProgress
  );
  const refs = useMemo(() => featuredFlexCarIds.map(id => ({ type: 'listing', id })), [
    featuredFlexCarIds,
  ]);
  const listings = useSelector(state => getMarketplaceEntities(state, refs));
  const sliderConfig = {
    infinite: false,
    slidesToScroll: 1,
    slidesToShow: 4,
    initialSlide: 0,
    infinite: false,
    dots: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          initialSlide: 0,
          infinite: false,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.25,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: true,
          arrows: false,
        },
      },
    ],
  };
  return (
    <div className={css.root}>
      <div className={css.title}>Featured Cars</div>
      {fetchFeaturedFlexCarInProgress ? (
        <div className={css.sectionCarsLoading}>Loading car...</div>
      ) : (
        <>
          <div className={css.sliderWrapper}>
            <Slider {...sliderConfig}>
              {listings.map(l => (
                <div className={css.listingCardWrapper}>
                  <ListingCardV2 listing={l} perMonthPrice pageName="LongTermFlex" />
                </div>
              ))}
            </Slider>
          </div>
          <div className={css.listings}>
            {listings.map(l => (
              <div key={`FeaturedFlexCar_${l.id.uuid}`} className={css.listing}>
                <ListingCardV2 listing={l} perMonthPrice pageName="LongTermFlex" />
              </div>
            ))}
          </div>
        </>
      )}
      <NamedLink
        name="LongTermRentalSearch"
        className={css.viewAllCar}
      >
        View all cars
      </NamedLink>
    </div>
  );
};

export default SectionFeaturedFlexCar;
