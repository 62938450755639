import React from 'react';
import css from './GrabRentACarLandingPage.css';
import classNames from 'classnames';
import imageSource from '../../util/imageSource';

const DriveLahVsCompanies = ({ isMobileLayout }) => {

  return (
    <div className={css.driveLahVsCompanies}>
      <div className={css.container}>
        <div className={classNames(css.row, css.secTitle)}>
          <div className={classNames(css.column6, css.contentHeading)}>
            <h2>
              Drive lah vs <br /> Other car rental Companies
            </h2>
            <p>Why choosing Drive lah is a no-brainer?</p>
          </div>
          <div className={classNames(css.column6, css.contentGrid)}>
            <table className={css.comparisonTable}>
              <thead>
                <tr>
                  <th></th>
                  <th className={css.drivelahPoints} width="255">
                    <span className={css.curveBorder}>Drive lah</span>
                  </th>
                  <th className={css.otherPoints} width="255">
                    <span className={css.curveBorder}>Other Rental Companies</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className={css.pointsTitle} align="right">
                    Price
                  </td>
                  <td className={css.drivelahPoints}>
                    <strong>$4/hr &amp; $29/day</strong> no mileage cap
                  </td>
                  <td className={css.otherPoints}>$8/hr &amp; $60/day limited mileage</td>
                </tr>
                <tr>
                  <td className={css.pointsTitle} align="right">
                    Simple Rent Structure
                  </td>
                  <td className={css.drivelahPoints}>
                    <div className={css.contentData}>
                      <span className={css.icon}>
                        <img src={imageSource.grabBanner.checkBlue} alt="Approved by LTA" />
                      </span>
                      Same all day
                    </div>
                  </td>
                  <td className={css.otherPoints}>
                    <div className={css.contentData}>
                      <span className={css.icon}>
                        <img src={imageSource.grabBanner.crossRed} alt="Cross Tick Icon" />
                      </span>
                      Duration, km &amp; time of the day
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className={css.pointsTitle} align="right">
                    Long Lease Commitment
                  </td>
                  <td className={css.drivelahPoints}>
                    <div className={css.contentData}>
                      <span className={css.icon}>
                        <img src={imageSource.grabBanner.crossBlue} alt="Cross Tick Icon" />
                      </span>
                      None
                    </div>
                  </td>
                  <td className={css.otherPoints}>
                    <div className={css.contentData}>
                      <span className={css.icon}>
                        <img src={imageSource.grabBanner.checkRed} alt="Cross Tick Icon" />
                      </span>
                      Fixed Contract
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className={css.pointsTitle} align="right">
                    Wide Car Variety
                  </td>
                  <td className={css.drivelahPoints}>
                    <div className={css.contentData}>
                      <span className={css.icon}>
                        <img src={imageSource.grabBanner.checkBlue} alt="Approved by LTA" />
                      </span>
                      50+ models
                    </div>
                  </td>
                  <td className={css.otherPoints}>
                    <div className={css.contentData}>
                      <span className={css.icon}>
                        <img src={imageSource.grabBanner.crossRed} alt="Cross Tick Icon" />
                      </span>
                      4-5 models
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className={css.pointsTitle} align="right">
                    Car pickup points
                  </td>
                  <td className={css.drivelahPoints}>
                    <div className={css.contentData}>
                      <span className={css.icon}>
                        <img src={imageSource.grabBanner.checkBlue} alt="Approved by LTA" />
                      </span>
                      Always nearby
                    </div>
                  </td>
                  <td className={css.otherPoints}>
                    <div className={css.contentData}>
                      <span className={css.icon}>
                        <img src={imageSource.grabBanner.crossRed} alt="Cross Tick Icon" />
                      </span>
                      Limited
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className={css.pointsTitle} align="right">
                    Hidden Charges
                  </td>
                  <td className={css.drivelahPoints}>
                    <div className={css.contentData}>
                      <span className={css.icon}>
                        <img src={imageSource.grabBanner.crossBlue} alt="Cross Tick Icon" />
                      </span>
                      None
                    </div>
                  </td>
                  <td className={css.otherPoints}>
                    <div className={css.contentData}>
                      <span className={css.icon}>
                        <img src={imageSource.grabBanner.checkRed} alt="Cross Tick Icon" />
                      </span>
                      Yes
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className={css.pointsTitle} align="right">
                    Late Return Charges
                  </td>
                  <td className={css.drivelahPoints}>
                    <div className={css.contentData}>
                      <span className={css.icon}>
                        <img src={imageSource.grabBanner.crossBlue} alt="Cross Tick Icon" />
                      </span>
                      Grace Period
                    </div>
                  </td>
                  <td className={css.otherPoints}>
                    <div className={css.contentData}>
                      <span className={css.icon}>
                        <img src={imageSource.grabBanner.checkRed} alt="Cross Tick Icon" />
                      </span>
                      Strict Terms
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td className={css.pointsTitle} align="right"></td>
                  <td className={css.drivelahPoints}>
                    <span className={css.curveBorder}></span>
                  </td>
                  <td className={css.otherPoints}>
                    <span className={css.curveBorder}></span>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DriveLahVsCompanies;
