import React, { memo, useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import css from './HostLandingPage.css';
import { Column, Container, Row } from '../../components/Grids/Grid';
import bannerIllustration from '../../assets/host-themed-landing-page/banner-illustrator.png';
import { FieldTextInput, SecondaryButton } from '../../components';
import { composeValidators, emailFormatValid, required } from '../../util/validators';
import classNames from 'classnames';

const BannerSection = memo(props => {
  const [resetError, setResetError] = useState(false);
  const { intl } = props;
  const emailRequiredMessage = intl.formatMessage({
    id: 'SignupForm.emailRequired',
  });
  const emailRequired = required(emailRequiredMessage);
  const emailInvalidMessage = intl.formatMessage({
    id: 'SignupForm.emailInvalid',
  });
  const emailValid = emailFormatValid(emailInvalidMessage);

  const _handleTimedError = (e, hasError) => {
    if(e && hasError){
      setTimeout(() => {
        setResetError(true)
      }, 5000)
    } else {
      setResetError(false)
    }
    
  }
  return (
    <>
      <div className={css.hostBannerSection}>
        <Container>
          <Row alignItems="center">
            <Column large={6} medium={12} small={12}>
              <div className={css.bannerContent}>
                <h1 className={css.bannerHead}>
                  Own a car? <span>Earn over $10,000</span> per year
                </h1>
                <p>
                  Make the most out of your car. Share your car when you don’t need it and earn
                  easily. Fully insured.
                </p>
                <FinalForm
                  {...props}
                  render={({ handleSubmit }) => {
                    return (
                      <div className={css.root}>
                        <div className={css.field}>
                          <FieldTextInput
                            type="email"
                            name="email"
                            autoComplete="email"
                            placeholder="Your email"
                            label=""
                            validate={composeValidators(emailRequired, emailValid)}
                            className={css.input}
                            rootClassName={css.inputWrapper}
                            id="email"
                            getUnFocused={(e, d) => _handleTimedError(e,d)}
                            errorClassName={classNames(resetError ? css.timedError : '', css.errorMessage)}
                            timedError={false}
                          />
                          <SecondaryButton onClick={handleSubmit} className={css.submitBtn}>
                            Start Earning
                          </SecondaryButton>
                        </div>
                      </div>
                    );
                  }}
                />
              </div>
            </Column>
            <Column large={6} medium={12} small={12}>
              <div className={css.bannerIllustration}>
                <img src={bannerIllustration} alt="" />
              </div>
            </Column>
          </Row>
        </Container>
      </div>
    </>
  );
});

export default BannerSection;
