// import { loginAmplitude, logoutAmplitude } from './amplitude';
import Intercom, { shutdown, update } from '@intercom/messenger-js-sdk';
import config from '../config';

// Ensure environment variables are correctly loaded
const DOMAIN_URL = process.env.REACT_APP_DOMAIN_URL;

const apiUrl = config.apiUrl;

// Function to log in a user to Intercom
export const loginIntercomUser = async (currentUser, app_id) => {
  if (!currentUser || !currentUser.id || !currentUser.id.uuid) {
    console.error('Invalid user data provided.');
    return;
  }

  // Generate user hash for Intercom
  const userIdentifier = currentUser.id.uuid;
  const userHash = await generateUserHash(userIdentifier);

  if (!userHash) {
    console.error('Failed to generate user hash.');
    return;
  }

  // Extract user profile data
  const profile = currentUser.attributes.profile;

  // Prepare Intercom settings
  const intercomSettings = {
    app_id,
    user_id: userIdentifier,
    email: currentUser.attributes.email,
    phone: profile.protectedData?.phoneNumber || '',
    name: `${profile.firstName || ''} ${profile.lastName || ''}`.trim(),
    domain: DOMAIN_URL,
    user_hash: userHash,
    tag: `drivemate-${userIdentifier}`,
  };

  // Shut down any previous session and boot Intercom with new user
  shutdown();
  Intercom(intercomSettings);

  console.log('Intercom user logged in.');

  // loginAmplitude({
  //   userId: `${currentUser.id.uuid} - ${intercomSettings.email}`,
  //   userData: {
  //     Email: intercomSettings.email,
  //     Phone: intercomSettings.phone,
  //     Name: intercomSettings.name,
  //   }
  // });

  // Store user verification key in localStorage (if applicable)
  if (typeof window.localStorage !== 'undefined') {
    window.localStorage.setItem(
      'uvk',
      currentUser.attributes?.profile?.privateData?.userVerificationKey || ''
    );
  }
};

// Function to log out the user from Intercom
export const logoutIntercomUser = app_id => {
  shutdown();
  console.log('Intercom user logged out.');

  if (typeof window.localStorage !== 'undefined') {
    window.localStorage.removeItem('uvk'); // Clear local storage key
  }
};
/**
 * This method will toggle the visibility of intercom widget
 *
 * @param {boolean} [isVisible=true] visibility of intercom widget
 */
export const toggleIntercomVisibility = (isNotVisible = false) => {
  update({ hide_default_launcher: isNotVisible });
  console.log(`Intercom launcher visibility updated. Visible: ${!isNotVisible}`);
};

// Generate HMAC hash from server
const generateUserHash = async userIdentifier => {
  try {
    const response = await fetch(`${apiUrl}/api/intercom/generate-hash`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId: userIdentifier }),
    });
    const data = await response.json();
    if (data.hash) return data.hash;
    else return '';
  } catch (error) {
    console.error('Failed to generate user hash.', error);
    return '';
  }
};
