import React, { Component } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';

import css from './SectionReadyToGo.css';

class SectionReadyToGo extends Component {
  constructor(props) {
    super(props)
    this.state = { isOpen: false };
    this.handleOpen = this.handleOpen.bind(this);
  }

  handleOpen() {
    this.setState({ isOpen: true })
  }

  render() {
    const {
      isAuthenticated,
      onLoginOrSignupClick,
    } = this.props;

    return (
      <div className={css.root}>
        <div className={css.textWrapper}>
          <div className={css.title}>
            <FormattedMessage id="SectionReadyToGo.title" values={{ newline: (<br />) }} />
          </div>
          {isAuthenticated &&
            <NamedLink
              name="SearchPage"
              to={{
                search:
                  '?address=Singapore&bounds=1.4708809%2C104.04157989999999%2C1.216611%2C103.60650989999999',
              }}
              className={classNames(css.buttonFindACar)}
            >
              <FormattedMessage id="SectionReadyToGo.findACar" />
            </NamedLink>
          }
          {!isAuthenticated &&
            <NamedLink
              name="SignupPage"
              className={classNames(css.buttonJoinUs)}
              onClick={() => onLoginOrSignupClick('signup')}
            >
              <FormattedMessage id="SectionReadyToGo.joinUs" />
            </NamedLink>
          }
        </div>
      </div>
    )
  }
}

SectionReadyToGo.defaultProps = {

}

SectionReadyToGo.propTypes = {

}

export default SectionReadyToGo;
