import React, { memo } from 'react';
import css from './HostLandingPage.css';
import { Column, Container, Row } from '../../components/Grids/Grid';
import { Accordion, ExternalLink } from '../../components';

const FrequentlyAskedQuestions = memo(() => {
  const accordionContent = [
    {
      id: 1,
      accordionTitle: 'What is Drive lah?',
      accordionDescription:
        "Drive lah is a unique peer-to-peer car sharing platform which allows people to rent other people's cars. People who own a car can rent out their car to fellow Singaporeans when they don't need their car. Trips are comprehensively insured for the hosts and guests peace of mind.",
    },
    {
      id: 2,
      accordionTitle: 'Why should I rent with Drive lah?',
      accordionDescription:
        'Drive lah is the most convenient way to access to cars in Singapore. We offer the largest variety of cars nearby at a click of a button. Drive lah utilizes unused car hours of individual car owners making us typically 30-40% cheaper than traditional rental companies.',
    },
    {
      id: 3,
      accordionTitle: 'As a host, how is the safety of my car ensured?',
      accordionDescription: <>
        We carry stringent checks on guests who rent on Drive lah through their NRIC/FIN and driving license. Though highly unlikely, in the event of any unfortunate incident, your car is comprehensively insured through Drive lah protection<sup>TM</sup> including the following: <ul>
          <li>Death or injury to third party(unlimited)</li>
          <li>Damage to third party property(up to S$5,000,000)</li>
          <li>Loss or damage to your vehicle from accident causes (up to market value at the time of loss or damage)</li>
          <li>Loss of damage by fire or theft (up to market value at the time of loss or damage)</li>
          </ul>
        </>
    },
    {
      id: 4,
      accordionTitle:
        'What are the regulations with regards to peer to peer car sharing in Singapore?',
      accordionDescription:
        'The Land Transport Authority(LTA) has given Drive lah special approval which allows rental of private cars on both weekdays & weekends Details here',
    },
  ];
  return (
    <div className={css.faqWrapper}>
      <Container className={css.faqContainer}>
        <Row className={css.sectionTitle}>
          <Column large={12}>
            <h2>Your question, answered</h2>
          </Column>
        </Row>
        <Row alignItems="center">
          <Column large={12}>
            <Accordion content={accordionContent} autoplay={true} />
          </Column>
        </Row>
        <Row alignItems="center">
          <Column large={12}>
            <div className={css.helpCenterText}>
              <p>
                Can't find what you're looking for?{' '}
                <span>
                  Visit our{' '}
                  <ExternalLink href="https://help.drivelah.sg/">Help Centre</ExternalLink>
                </span>
              </p>
            </div>
          </Column>
        </Row>
      </Container>
    </div>
  );
});

export default FrequentlyAskedQuestions;
