import classNames from 'classnames';
import React, { memo } from 'react';
import loveDrivelahSec1 from '../../assets/landingPageNew/love-drivelah-1.jpg';
import loveDrivelahSec2 from '../../assets/landingPageNew/love-drivelah-2.jpg';
import loveDrivelahSec3 from '../../assets/landingPageNew/ld-y.jpg';
import loveDrivelahSec4 from '../../assets/landingPageNew/ld-x.jpg';
import YellowStar from '../../assets/landingPageNew/yellow-star.svg';
import { Column, Container, Row } from '../../components/Grids/Grid';
import css from './HostLandingPage.css';

const LoveDrivelah = memo(() => {
  const Ratings = () => {
    return (
      <span className={css.starReview}>
        <span>
          <img src={YellowStar} alt="" />
        </span>
        <span>
          <img src={YellowStar} alt="" />
        </span>
        <span>
          <img src={YellowStar} alt="" />
        </span>
        <span>
          <img src={YellowStar} alt="" />
        </span>
        <span>
          <img src={YellowStar} alt="" />
        </span>
      </span>
    );
  };

  const dataContent = [
    {
      id: 1,
      image: loveDrivelahSec1,
      testimonialQuote:
        'Drive lah is a safe, easy and convenient platform in Singapore. Thanks Drive lah!',
      testimonialBy: 'Ben Chan',
      ratings: <Ratings />,
    },
    {
      id: 2,
      image: loveDrivelahSec2,
      testimonialQuote: 'Convenient way to rent car with insurance covered. Hassle free!',
      testimonialBy: 'Jonathan Lai',
      ratings: <Ratings />,
    },
    {
      id: 3,
      image: loveDrivelahSec3,
      testimonialQuote:
        'The Drive lah team makes the experience of car-sharing relatively stress-free.',
      testimonialBy: 'Shareen Tan',
      ratings: <Ratings />,
    },
    {
      id: 4,
      image: loveDrivelahSec4,
      testimonialQuote: 'Drive lah is committed on building trust within the community it serves.',
      testimonialBy: 'Even Lim',
      ratings: <Ratings />,
    },
  ];

  return (
    <div className={css.loveDrivelahSection}>
      <Container>
        <Row className={css.sectionTitle}>
          <Column large={12}>
            <h5 className={css.heading}>
              #LoveDrivelah{' '}
              <p className={css.desc}>
                Read stories from our hosts who share their cars on Drive lah
              </p>
            </h5>
          </Column>
        </Row>
        <Row>
          {dataContent &&
            dataContent.length > 0 &&
            dataContent.map(testimonial => {
              return (
                <Column large={3} medium={4} small={12} className={css.loveDriveLahCard}>
                  <div className={classNames(css.card, css.loveDriveLahCard)}>
                    <div className={css.cardThumb}>
                      <img src={testimonial.image} className={css.cardImg} />
                    </div>
                    <div className={css.cardContent}>
                      <p>“{testimonial.testimonialQuote}”</p>
                      <p>
                        <strong>{testimonial.testimonialBy}</strong>
                      </p>
                      {testimonial.ratings}
                    </div>
                  </div>
                </Column>
              );
            })}
        </Row>
      </Container>
    </div>
  );
});

export default LoveDrivelah;
