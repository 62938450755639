// src/utils/user.js
import { v4 as uuidv4 } from 'uuid';

export const getUserId = () => {
  let userId = localStorage.getItem('amp-user');
  if (!userId) {
    userId = uuidv4();
    localStorage.setItem('amp-user', userId);
  }
  return userId;
};