import classNames from 'classnames';
import React, { memo } from 'react';
// import YellowStar from '../../assets/landingPageNew/yellow-star.svg';
import Times from '../../assets/landingPageNew/times.svg';
import Channel from '../../assets/landingPageNew/news-channel.svg';
import Vulcan from '../../assets/landingPageNew/vulcan.svg';
import Zaobao from '../../assets/landingPageNew/zaobao.svg';
import css from './GuestLandingPage.css';
import { Column, Container, Row } from '../../components/Grids/Grid';

const AboutDrivelah = memo(() => {
  return (
    <div className={css.aboutDrivelahSection}>
      <Container>
        <Row justifyContent="center">
          <Column large={6} small={12}>
            <h5 className={css.heading}>About Drive lah</h5>
            <p className={css.desc}>
              Drive lah is a peer-to-peer car sharing platform where you can rent a large variety of cars, always nearby at great value. The idea is simple: The world is progressing very fast and we need to care about the environment. Car ownership is expensive in Singapore (per month yet only uses the car 5% of the time – cars are mostly parked. With Drive lah you can reduce the cost of ownership by renting it out when you don’t need it in a safe way.  Renters can rent those cars when they are not used by their owners at good value.
            </p>
            <p>This is why we came with this unique platform - Drive lah.</p>
          </Column>
        </Row>
        <Row justifyContent="center">
          <Column large={12} small={12}>
            <div className={classNames(css.sectionWithBackground, css.featuredOnSection)}>
              <h5 className={css.heading}>Featured on</h5>
              <div className={css.flexContent}>
                <div>
                  <img src={Times} alt="" />
                </div>
                <div>
                  <img src={Channel} alt="" />
                </div>
                <div>
                  <img src={Vulcan} alt="" />
                </div>
                <div>
                  <img src={Zaobao} alt="" />
                </div>
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </div>
  );
});

export default AboutDrivelah;
