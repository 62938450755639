import React, { useEffect } from 'react';

import SectionCarPhotos from './SectionCarPhotos';
import SectionCarPhotosOnlyView from './SectionCarPhotosOnlyView';
import { flatten } from 'lodash';

import css from './TripPanel.css';

const PickUpSection = props => {
  const {
    currentOdometerReading,
    shouldShowPickUpSection,
    isCustomer,
    photoSectionList,
    photoSectionListCustomer,
    photoSectionListCustomerDLGO,
    setActivePhotoSection,
    activePhotoSection,
    intl,
    onManageDisableScrolling,
    isProvider,
    isPickUp,
    currentUser,
    setTripPhotos,
    state,
    allTripPhotos,
    inputOdometerInvalid,
    onNoteChange,
    onOdometerStartDataChange,
    onOdometerEndDataChange,
    isDrivelahGo,
    isPickedUp,
    isDropOff,
    isPickupRequested,
    interiorPhotoDlGo,
    odometerPhotoDlGo,
    fuelGaugePhotoDlGo,
    fuelReceiptPhotoDlGo,
    disableOdometerDataField,
    isFuelIncluded,
    setRawActionPhotoSection,
  } = props;

  useEffect(() => {
    if (isDrivelahGo && Array.isArray(allTripPhotos[0]) && Array.isArray(allTripPhotos[1])) {
      setRawActionPhotoSection(allTripPhotos);
    }
  }, []);

  const otherPhotoDlGoForProvider = allTripPhotos.filter(photo => {
    let check = photo.filter((p) => {
      return !p.exteriorPhotos && !p.selfieWithId && !p.interiorPhotos && !p.odometerPhotos && !p.fuelGaugePhotos
    })

    return check.length > 0;
  })

  const otherPhotoDlGo = otherPhotoDlGoForProvider.map(photo => {
    photo.otherPhotos = true;
    return photo;
  })

  let filterTripPhotos = flatten([
    ...allTripPhotos,
    ...interiorPhotoDlGo,
    ...odometerPhotoDlGo,
    ...fuelGaugePhotoDlGo,
  ]).filter(i => i.isPickUp);

  filterTripPhotos = [...filterTripPhotos, ...otherPhotoDlGo];

  const photoSectionOnlyViewCustomer = isDrivelahGo ? photoSectionListCustomerDLGO : photoSectionListCustomer;
  const photoSectionListForDownload = photoSectionList.filter(p => p.data !== 'otherPhotos');
  const photoSectionOnlyViewForCustomer = photoSectionList.filter(p => p.data === 'otherPhotos');
  const odometerPhotos = filterTripPhotos.filter(p => p.odometerPhotos);
  let odometerStartData = '';
  if (odometerPhotos.length > 0) {
    let odoPics = odometerPhotos[0];
    if (odoPics.odometerStartData) {
      odometerStartData = odoPics.odometerStartData;
    } else {
      odometerStartData = odoPics.note;
    }
  }

  return (
    <div className={css.pickUpPhotoSection}>
      <div className={css.titleContainer}>
        <div className={css.bookingStatusText} onClick={shouldShowPickUpSection}>
          <span>&#8249; back</span>
        </div>
        <h2 className={css.listingTitle}>
          {isCustomer ? 'Send pickup request' : 'Confirm pickup request'}
        </h2>
      </div>
      {photoSectionListForDownload.map(i => {
        if (i.data === 'fuelReceiptPhotos') {
          return false;
        }
        let canShowOthers = false;
        if (isDrivelahGo) {
          if (state['exteriorPhotos'].length >= 4 && state['selfieWithId'].length >= 1) {
            canShowOthers = true;
          } else if (state['currentTransitionInfo'] && state['currentTransitionInfo'].tripsActiveLabel === 'Ongoing') {
            canShowOthers = true;
          }
        }
        // if (i.data === 'odometerPhotos' && isDrivelahGo) {
        //   return false;
        // }

        const isShowSection =
          i.data === 'interiorPhotos' ||
            i.data === 'odometerPhotos' ||
            i.data === 'fuelGaugePhotos' ||
            i.data === 'fuelReceiptPhotos'
            ? isDrivelahGo
              ? canShowOthers
              : true
            : true;
        return isShowSection ? (
          <SectionCarPhotos
            currentOdometerReading={currentOdometerReading}
            id={i.id}
            key={i.id}
            isRemarkVisible={i.isRemarkVisible}
            setActivePhotoSection={setActivePhotoSection}
            // onClick={e => setActivePhotoSection(e, i.id)}
            holderClassName={
              activePhotoSection[i.id] === true ? css.activeSection : css.inactiveSection
            }
            activeSection={activePhotoSection[i.id] === true}
            intl={intl}
            onManageDisableScrolling={onManageDisableScrolling}
            isCustomer={isCustomer}
            isProvider={isProvider}
            currentUser={currentUser}
            isPickUp={true}
            setTripPhotos={data => setTripPhotos({ [i.data]: [...data] })}
            tripPhotos={state[i.data]}
            existedPhotos={filterTripPhotos}
            inputOdometerInvalid={inputOdometerInvalid}
            title={i.title}
            description={i.description}
            type={i.data}
            odometerData={i.odometerData}
            onNoteChange={onNoteChange}
            onOdometerStartDataChange={onOdometerStartDataChange}
            disableOdometerDataField={disableOdometerDataField}
            isFuelIncluded={isFuelIncluded}
            odometerStartData={odometerStartData}
            isDrivelahGo={isDrivelahGo}
          />
        ) : null;
      })}

      {isCustomer && otherPhotoDlGoForProvider.length > 0 &&
        photoSectionOnlyViewForCustomer.map(i => {
          if (i.data === 'fuelReceiptPhotos') {
            return false;
          }
          if (i.data === 'odometerPhotos' && isDrivelahGo) {
            return false;
          }
          const isShowSection =
            i.data === 'interiorPhotos' ||
              i.data === 'odometerPhotos' ||
              i.data === 'fuelGaugePhotos' ||
              i.data === 'fuelReceiptPhotos' ||
              i.data === 'otherPhotos'
              ? isDrivelahGo
                ? (i.data === 'interiorPhotos' && interiorPhotoDlGo && interiorPhotoDlGo.length > 0) ||
                (i.data === 'odometerPhotos' && odometerPhotoDlGo && odometerPhotoDlGo.length > 0) ||
                (i.data === 'fuelGaugePhotos' && fuelGaugePhotoDlGo && fuelGaugePhotoDlGo.length > 0) ||
                (i.data === 'fuelReceiptPhotos' && fuelReceiptPhotoDlGo && fuelReceiptPhotoDlGo.length > 0) ||
                (i.data === 'otherPhotos' && otherPhotoDlGo && otherPhotoDlGo.length > 0)
                : true
              : true;
          return isShowSection ? (
            <SectionCarPhotosOnlyView
              key={i.id}
              isRemarkVisible={i.isRemarkVisible}
              holderClassName={css.activeSection}
              intl={intl}
              onManageDisableScrolling={onManageDisableScrolling}
              isCustomer={isCustomer}
              isProvider={isProvider}
              currentUser={currentUser}
              isPickUp={true}
              setTripPhotos={data => setTripPhotos({ [i.data]: [...data] })}
              tripPhotos={state[i.data]}
              existedPhotos={filterTripPhotos}
              inputOdometerInvalid={inputOdometerInvalid}
              title={i.title}
              description={i.description}
              type={i.data}
              onNoteChange={onNoteChange}
            />
          ) : null;
        })}

      {isProvider && (
        <div className={css.uploadedTitleContainer}>
          <p className={css.uploadedTitle}>UPLOADED BY THE GUEST</p>
        </div>
      )}

      {isProvider &&
        photoSectionOnlyViewCustomer.map(i => {
          if (i.data === 'fuelReceiptPhotos') {
            return false;
          }
          const isShowSection = true;
          // i.data === 'interiorPhotos' ||
          //   i.data === 'odometerPhotos' ||
          //   i.data === 'fuelGaugePhotos' ||
          //   i.data === 'fuelReceiptPhotos' ||
          //   i.data === 'otherPhotos'
          //   ? isDrivelahGo
          //     ? (i.data === 'interiorPhotos' && interiorPhotoDlGo && interiorPhotoDlGo.length > 0) ||
          //     (i.data === 'odometerPhotos' && odometerPhotoDlGo && odometerPhotoDlGo.length > 0) ||
          //     (i.data === 'fuelGaugePhotos' && fuelGaugePhotoDlGo && fuelGaugePhotoDlGo.length > 0) ||
          //     (i.data === 'fuelReceiptPhotos' && fuelReceiptPhotoDlGo && fuelReceiptPhotoDlGo.length > 0) ||
          //     (i.data === 'otherPhotos' && otherPhotoDlGo && otherPhotoDlGo.length > 0)
          //     : true
          //   : true;
          return isShowSection ? (
            <SectionCarPhotosOnlyView
              key={i.id}
              isRemarkVisible={i.isRemarkVisible}
              holderClassName={css.activeSection}
              intl={intl}
              onManageDisableScrolling={onManageDisableScrolling}
              isCustomer={isCustomer}
              isProvider={isProvider}
              currentUser={currentUser}
              isPickUp={true}
              setTripPhotos={data => setTripPhotos({ [i.data]: [...data] })}
              tripPhotos={state[i.data]}
              existedPhotos={filterTripPhotos}
              inputOdometerInvalid={inputOdometerInvalid}
              title={i.title}
              description={i.description}
              type={i.data}
              onNoteChange={onNoteChange}
              onOdometerStartDataChange={onOdometerStartDataChange}
              onOdometerEndDataChange={onOdometerEndDataChange}
            />
          ) : null;
        })}
    </div>
  );
};

export default PickUpSection;
