import classNames from 'classnames';
import { get } from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { BookingTimeForm } from '../../forms';
import { withViewport } from '../../util/contextHelpers';
import {
  ensureTransaction,
  ensureUser,
  getBookingConfig,
  restoreTransaction,
} from '../../util/data';
import { getDefaultTimeZoneOnBrowser, timestampToDate } from '../../util/dates';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { STRIPE_T_KEY } from '../../util/stripeTerms';
import {
  getUserTxRole,
  isPickUpConfirmed,
  TRANSITION_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_NON_REFUNDABLE,
  TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_ONE_HOUR,
  TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_REFUNDABLE,
  txIsAcceptedAfterOneHour,
  txIsAcceptedNonRefundable,
  txIsAcceptedRefundable,
} from '../../util/transaction';
import { propTypes } from '../../util/types';

import {
  BookingInfoSection,
  IconSpinner,
  Logo,
  NamedLink,
  Page,
  PartnerInfo,
} from '../../components';

import { initializeCardPaymentData } from '../../ducks/stripe.duck';
import BookingPeriod from '../../forms/BookingTimeForm/BookingPeriod';
import routeConfiguration from '../../routeConfiguration';
import {
  EVENT_TRIP_MODIFICATION_REQUEST_SENT_GUEST,
  REQUEST_TO_BOOK_BUTTON_ID,
} from '../../util/gtm/gtmConstants';
import { initiateEventFromTransaction } from '../../util/gtm/gtmHelpers';
import {
  createResourceLocatorString,
  findRouteByRouteName,
  pathByRouteName,
} from '../../util/routes';
import { getUpdateObjs, makeChildUpdateBookingDates } from '../../util/updateTransaction';
import { createSlug } from '../../util/urlHelpers';
import { estimateBreakdown, resetCode } from '../ListingPage/ListingPage.duck';
import {
  fetchTimeSlots,
  loadData,
  requestToUpdateBooking,
} from '../TransactionPage/TransactionPage.duck';
import css from './EditTripPage.css';
import { event_trigger_ids } from '../../util/analyticsConstants';
import { triggerAnalyticsEvent } from '../../util/amplitudeMapEvents';
import { getEventsByBucket } from '../../util/gtm/gtmCreateProperties';
// import { TripFeesExperiment } from '../../util/constants';
// import { getPUSHTELLTripFeesExperiment } from '../../util/helpers';

const PROVIDER = 'provider';
const CUSTOMER = 'customer';
const MAX_MOBILE_SCREEN_WIDTH = 768;
const MAX_TABLET_SCREEN_WIDTH = 1024;
const TODAY = new Date();
const dateFormattingOptions = { month: 'short', day: 'numeric', weekday: 'short' };

// TransactionPage handles data loading for Sale and Order views to transaction pages in Inbox.
export const EditTripPageComponent = props => {
  const {
    scrollingDisabled,
    transaction,
    intl,
    transactionRole,
    viewport,
    currentUser,
    history,
    timeSlots,
    monthlyTimeSlots,
    unitType,
    onSubmit,
    isOwnListing,
    onFetchTimeSlots,
    onResetCode,
    checkedCode,
    checkCodeErorr,
    onCheckingVoucher,
    checkCodeInProgress,
    onEstimateBreakdown,
    estimateBreakdownInProgress,
    estimateError,
    estimatedTx,
    initialDate,
    onRequestToUpdateBooking,
    location,
  } = props;

  const [dataLoaded, setDataLoaded] = useState(false);

  // TODO: Check this page, once live, we need to get the selectedTripFeesExperiment from transaction, not from localStorage
  // const [selectedTripFeesExperiment, setTripFeesExperiment] = useState(getPUSHTELLTripFeesExperiment(TripFeesExperiment.name));
  useEffect(() => {
    // console.log('Trip Fees Experiment In React Component EditTripPageComponent:', selectedTripFeesExperiment);
  }, []);

  useEffect(() => {
    if (transaction && !dataLoaded) {
      setDataLoaded(true);
    }
  }, [transaction, dataLoaded]);

  const currentListing = transaction && transaction.listing;
  const listingTitle = currentListing && currentListing.attributes.title;
  const listingId = currentListing && currentListing.id;
  const title = intl.formatMessage({ id: 'TripDetailsPage.title' }, { title: listingTitle });
  const isCustomer = transactionRole === 'customer';
  const isProvider = transactionRole === 'provider';
  const currentTransaction = restoreTransaction(ensureTransaction(transaction));

  const price =
    currentListing && currentListing.attributes ? currentListing.attributes.price : null;
  const transactionTimezone = transaction && transaction.attributes && transaction.attributes.protectedData && transaction.attributes.protectedData.transactionTimezone;
  const localTimeZone = getDefaultTimeZoneOnBrowser(transactionTimezone);
  const timeZone =
    currentListing &&
    currentListing.attributes.availabilityPlan &&
    currentListing.attributes.availabilityPlan.timezone;
  const listingParams = currentListing
    ? {
        slug: createSlug(currentListing && currentListing.attributes.title),
        id: currentListing && currentListing.id.uuid,
      }
    : null;
  const bookingConfig = currentListing ? getBookingConfig(currentListing) : null;
  const isNewCar = currentListing ? get(currentListing, 'attributes.metadata.isNewCar') : false;
  const isLongTerm = currentListing
    ? get(transaction, 'attributes.protectedData.isLongTermRental')
    : false;

  const startDate = currentTransaction && currentTransaction.booking &&  currentTransaction.booking.attributes.displayStart;
  const endDate = currentTransaction && currentTransaction.booking && currentTransaction.booking.attributes.displayEnd;
  const startTime = currentTransaction && currentTransaction.booking && new Date(currentTransaction.booking.attributes.displayStart).getTime();
  const endTime = currentTransaction && currentTransaction.booking && new Date(currentTransaction.booking.attributes.displayEnd).getTime();

  const initialValues = {
    bookingStartDate: startDate ? { date: startDate } : null,
    bookingEndDate: endDate ? { date: endDate } : null,
    bookingStartTime: startTime ? startTime.toString() : null,
    bookingEndTime: endTime ? endTime.toString() : null,
  };

  const currentProvider = transaction && ensureUser(transaction.provider);
  const currentCustomer = transaction && ensureUser(transaction.customer);

  const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
  const isTabletLayout = viewport.width < MAX_TABLET_SCREEN_WIDTH;

  const currentUserRole =
    currentUser && currentUser.id && transaction && getUserTxRole(currentUser.id, transaction);
  const isProviderRole = currentUserRole === PROVIDER;
  const isCustomerRole = currentUserRole === CUSTOMER;

  const pageProps = {
    title,
    scrollingDisabled,
    className: css.root,
  };

  const onSubmitUpdateBooking = values => {
    const {
      history,
      callSetInitialValues,
      onInitializeCardPaymentData,
      onResetCode,
      location,
    } = props;

    const currentTransaction = ensureTransaction(transaction);
    const {
      bookingStartTime,
      bookingEndTime,
      signupCredits,
      voucherCode,
      payForFuel,
      bookingStartDate,
      bookingEndDate,
    } = values;
    const bookingStart = bookingStartDate.date; // timestampToDate(parseInt(bookingStartTime));
    const bookingEnd = bookingEndDate.date; // timestampToDate(parseInt(bookingEndTime));
    let shouldCheckout = true;
    let diffHours = 0;
    let regularHoursDiff = 0;
    let peakHoursDiff = 0;
    let updateBookingStartDateWithProperHours = null;
    let updateBookingEndDateWithProperHours = null;
    let momentTripBookingStartDate = null;
    let momentTripBookingEndDate = null;
    let momentUpdateBookingStartDate = null;
    let momentUpdateBookingEndDate = null;

    if(values){
      const updateBookingStartDate = values.bookingStartDate.date;
      const updateBookingEndDate = values.bookingEndDate.date;

      const updateBookingStartHours = timestampToDate(values.bookingStartTime).getHours();
      const updateBookingStartMinutes = timestampToDate(values.bookingStartTime).getMinutes();
      const updateBookingEndHours = timestampToDate(values.bookingEndTime).getHours();
      const updateBookingEndMinutes = timestampToDate(values.bookingEndTime).getMinutes();

      updateBookingStartDateWithProperHours = updateBookingStartDate.setHours(updateBookingStartHours, updateBookingStartMinutes, 0, 0);
      updateBookingEndDateWithProperHours = updateBookingEndDate.setHours(updateBookingEndHours, updateBookingEndMinutes, 0, 0);

      momentUpdateBookingStartDate = moment(timestampToDate(updateBookingStartDateWithProperHours), localTimeZone);
      momentUpdateBookingEndDate = moment(timestampToDate(updateBookingEndDateWithProperHours), localTimeZone);

      const updateBookingParentTransactionStartDate = transaction && transaction.booking && transaction.booking.attributes.displayStart;
      const updateBookingParentTransactionEndDate = transaction && transaction.booking && transaction.booking.attributes.displayEnd;
      momentTripBookingStartDate = moment(updateBookingParentTransactionStartDate);
      momentTripBookingEndDate = moment(updateBookingParentTransactionEndDate);

      const updateBookingDiffHours = momentUpdateBookingEndDate.diff(momentUpdateBookingStartDate, 'hours', true);
      let updateBookingParentTransactionDiffHours = moment(updateBookingParentTransactionEndDate).diff(moment(updateBookingParentTransactionStartDate), 'hours', true);

      diffHours = updateBookingDiffHours - updateBookingParentTransactionDiffHours;

      const tripBookingPeriod = new BookingPeriod(momentTripBookingStartDate, momentTripBookingEndDate);
      const updateBookingPeriod = new BookingPeriod(momentUpdateBookingStartDate, momentUpdateBookingEndDate);
      const listingPricing = get(currentListing, 'attributes.publicData.pricing', null);
      const tripBookingRegularHours = tripBookingPeriod.regularHoursCount(listingPricing);
      const tripBookingPeakHours = tripBookingPeriod.peakHoursCount(listingPricing);
      const updateBookingRegularHours = updateBookingPeriod.regularHoursCount(listingPricing);
      const updateBookingPeakHours = updateBookingPeriod.peakHoursCount(listingPricing);
      regularHoursDiff = updateBookingRegularHours - tripBookingRegularHours;
      peakHoursDiff = updateBookingPeakHours - tripBookingPeakHours;

      if(regularHoursDiff === 0 && peakHoursDiff === 0) {
        shouldCheckout = false;
      }
    }


    const currentTransition = txIsAcceptedRefundable(currentTransaction)
      ? TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_REFUNDABLE
      : txIsAcceptedNonRefundable(currentTransaction)
      ? TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_NON_REFUNDABLE
      : txIsAcceptedAfterOneHour(currentTransaction)
      ? TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_ONE_HOUR
      : isPickUpConfirmed(currentTransaction)
      ? TRANSITION_UPDATE_BOOKING_BEFORE_DROP_OFF
      : TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_REFUNDABLE;

    const updateBookingParams = {
      transaction: currentTransaction,
      transition: currentTransition,
      transactionId: currentTransaction.id,
      listing: currentTransaction.listing,
      bookingStart,
      bookingEnd,
      protectedData: { },
    };

    const routes = routeConfiguration();

    // Customize checkout page state with current listing and selected bookingDates
    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);
    const listing = currentTransaction.listing;
    const initialValues = {
      listing,
      bookingData: {
        signupCredits,
        voucherCode,
        transactionId: currentTransaction.id,
        payForFuel: false,
        isPaidAmount: true,
        // paidAmount: -(currentTransaction.attributes.payinTotal.amount),
        diffHours,
        regularHoursDiff,
        peakHoursDiff,
        currentTx: currentTransaction.id.uuid,
        tripBookingStart: new Date(momentTripBookingStartDate),
        tripBookingEnd: new Date(momentTripBookingEndDate),
        updateBookingStart: new Date(momentUpdateBookingStartDate),
        updateBookingEnd: new Date(momentUpdateBookingEndDate),
        updateBookingParams: updateBookingParams,
        stripeTKey: STRIPE_T_KEY,
      },
      bookingDates: {
        bookingStart: timestampToDate(updateBookingStartDateWithProperHours),
        bookingEnd: timestampToDate(updateBookingEndDateWithProperHours),
      },
      confirmPaymentError: null,
    };

    callSetInitialValues(setInitialValues, initialValues);

    if(!shouldCheckout) {
      const updateBookingParams = {
        transaction: currentTransaction,
        transition: currentTransition,
        transactionId: currentTransaction.id,
        listing: currentTransaction.listing,
        bookingStart,
        bookingEnd,
        protectedData: {
          childTransaction: [
            ...getUpdateObjs(currentTransaction),
            makeChildUpdateBookingDates(updateBookingStartDateWithProperHours, updateBookingEndDateWithProperHours),
          ],
        },
      };
      const orderDetailsPath = pathByRouteName('OrderDetailsPage', routes, {
        id: currentTransaction.id.uuid,
      });
      // console.log("CCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCC", );
      onRequestToUpdateBooking({...updateBookingParams }).then(async () => {
        try {
          const response = await getEventsByBucket('book');
          // console.log('Events fetched successfully:', response.data);
          triggerAnalyticsEvent({
            event_id: event_trigger_ids.BOOK_SENT_MODIFICATION_SUCCESS_GUEST,
            eventData: response.data,
            props: {
              guest: currentUser,
              listing: currentTransaction.listing,
              trip: currentTransaction,
              booking: currentTransaction,
              host: currentTransaction.provider ? currentTransaction.provider.id.uuid : null,
              ui: {
                button: 'Book Now',
                page: 'CheckoutScreen'
              },
            },
            isBackendApiCall: true,
            userId: currentUser ? currentUser.id.uuid : null,
          });
          triggerAnalyticsEvent({
            event_id: event_trigger_ids.BOOK_RECEIVED_MODIFICATION_SUCCESS_HOST,
            eventData: response.data,
            props: {
              guest: currentUser,
              listing: currentTransaction.listing,
              trip: currentTransaction,
              booking: currentTransaction,
              host: currentTransaction.provider ? currentTransaction.provider.id.uuid : null,
              ui: {
                button: 'Book Now',
                page: 'CheckoutScreen'
              },
            },
            isBackendApiCall: true,
            userId: currentTransaction.provider ? currentTransaction.provider.id.uuid : null,
          });
        } catch (error) {
          console.log('Error fetching events:', error);
        }
        // initiateEventFromTransaction({
        //   props: props,
        //   transaction: currentTransaction,
        //   event:  EVENT_TRIP_MODIFICATION_REQUEST_SENT_GUEST
        // });
        history.push(orderDetailsPath);
      });

    } else {
      // Clear previous Stripe errors from store if there is any
      onInitializeCardPaymentData();

      onResetCode();

      // Redirect to CheckoutPage
      history.push(
        createResourceLocatorString(
          'CheckoutPage',
          routes,
          { id: listing.id.uuid, slug: createSlug(listing.attributes.title) },
          { searchPageParams: location.search },
          {}
        ),
        {
          updateBooking: true,
          transactionId: currentTransaction.id,
          updateDates: {
            start: bookingStart,
            end: bookingEnd,
          },
        }
      );
    }


  };

  const topbar = (
    <div className={css.topbar}>
      <NamedLink className={css.home} name="LandingPage">
        <Logo
          className={css.logoMobile}
          title={intl.formatMessage({
            id: 'CheckoutPage.goToLandingPage',
          })}
          format="mobile"
        />
        <Logo
          className={css.logoDesktop}
          alt={intl.formatMessage({
            id: 'CheckoutPage.goToLandingPage',
          })}
          format="desktop"
        />
      </NamedLink>
    </div>
  );

  const editTripSection = (
    <>
      <div className={css.titleContainer}>
        <h3 className={css.updateBookingTitle}>
          <FormattedMessage id="BookingPanel.updateBookingTitle" />
        </h3>
        <p className={css.updateBookingSubTitle}>
          <FormattedMessage id="BookingPanel.updateBookingSubTitle" />
        </p>
        <p className={css.updateBookingSubTitle}>
          <FormattedMessage id="BookingPanel.updateBookingSubTitle2" />
        </p>
      </div>
      {currentTransaction && currentTransaction.booking ? (
        <BookingTimeForm
          currentUser={currentUser}
          timeSlots={timeSlots}
          monthlyTimeSlots={monthlyTimeSlots}
          className={css.bookingForm}
          formId="BookingPanel"
          submitButtonWrapperClassName={css.submitButtonWrapper}
          unitType={unitType}
          onSubmit={onSubmitUpdateBooking}
          price={price}
          isOwnListing={isOwnListing}
          listingId={listingId}
          onFetchTimeSlots={onFetchTimeSlots}
          startDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
          endDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
          timeZone={timeZone}
          listing={currentListing}
          onResetCode={onResetCode}
          checkedCode={checkedCode}
          checkCodeErorr={checkCodeErorr}
          checkCodeInProgress={checkCodeInProgress}
          onCheckingVoucher={onCheckingVoucher}
          onEstimateBreakdown={onEstimateBreakdown}
          // tripFeesExperiment={selectedTripFeesExperiment}
          estimateBreakdownInProgress={estimateBreakdownInProgress}
          estimatedTx={estimatedTx}
          requestButtonId={REQUEST_TO_BOOK_BUTTON_ID}
          listingParams={listingParams}
          bookingConfig={bookingConfig}
          initialDate={initialDate}
          estimateError={estimateError}
          initialValues={initialValues}
          localTimeZone={localTimeZone}
          isNewCar={isNewCar}
          isLongTerm={isLongTerm}
          transaction={currentTransaction}
          updateBooking={true}
          onRequestToUpdateBooking={onRequestToUpdateBooking}
          location={location}
        />
      ) : (
        <div className={css.loadingTimeSlotWrapper}>
          <IconSpinner />
        </div>
      )}
    </>
  );

  return (
    <Page {...pageProps}>
      {topbar}
      <div className={css.contentContainer}>
        <div className={css.contentRow}>
          <div className={css.addonsPageTitles}>
            <div className={css.addonsBreadcrumbs}>
              {transaction && (
                <NamedLink
                  className={css.arrowBack}
                  name={isCustomerRole ? 'OrderDetailsPage' : 'SaleDetailsPage'}
                  params={{ id: transaction.id.uuid }}
                >
                  <span className={css.arrowBackText}>&#8249;</span>

                  {listingTitle}
                </NamedLink>
              )}
            </div>
            <h3 className={css.policiesSubTitle}>
              <FormattedMessage id="TripPanel.editTripTitle" />
            </h3>
          </div>
        </div>
        <div className={classNames(css.contentRow, css.contentRowWithPaddings)}>
          <div className={css.addonsSectionLeft}>{editTripSection}</div>

          {isMobileLayout ? null : (
            <div className={css.addonsSectionRight}>
              <div>
                {transaction && currentListing && (
                  <BookingInfoSection
                    transaction={transaction}
                    isCustomer={isCustomerRole}
                    showAvatar={false}
                    listing={currentListing}
                    currentProvider={currentProvider}
                    intl={intl}
                  />
                )}
                <PartnerInfo
                  currentUserRole={transactionRole}
                  transaction={transaction}
                  otherUser={isProviderRole ? currentCustomer : currentProvider}
                  show={true}
                  isMobileLayout={isMobileLayout}
                  userTypeText={intl.formatMessage({
                    id: isCustomerRole
                      ? 'TripDetailsPage.userTypeText'
                      : 'TripDetailsPage.userTypeTextHost',
                  })}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </Page>
  );
};

EditTripPageComponent.defaultProps = {
  currentUser: null,
  transaction: null,
};

const { bool, func, oneOf, shape, string, arrayOf, number } = PropTypes;

EditTripPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  scrollingDisabled: bool.isRequired,
  transaction: propTypes.transaction,
  transactionRole: oneOf([PROVIDER, CUSTOMER]).isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    transactionRef,
    nextLongTermTransaction,
    childLongTermTransactions,
    currentChildLongTermTransaction,
  } = state.TransactionPage;
  const { currentUser } = state.user;
  const { monthlyTimeSlots, customerUpdateBookingError, acceptSaleError } = state.TransactionPage;
  const { estimatedTx, estimateBreakdownInProgress, timeSlots, estimateError } = state.ListingPage;

  const transactions = getMarketplaceEntities(state, transactionRef ? [transactionRef] : []);
  const transaction = transactions.length > 0 ? transactions[0] : null;
  if (nextLongTermTransaction && transaction) {
    transaction.nextTransaction = nextLongTermTransaction;
    transaction.childTransaction = childLongTermTransactions;
    transaction.currentChildTransaction = currentChildLongTermTransaction;
  }

  return {
    currentUser,
    transaction,
    monthlyTimeSlots,
    estimatedTx,
    estimateBreakdownInProgress,
    timeSlots,
    estimateError,
    customerUpdateBookingError,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    onManageDisableScrolling: (componentId, disableScrolling) =>
      dispatch(manageDisableScrolling(componentId, disableScrolling)),
    onFetchTimeSlots: (listingId, start, end, timeZone) =>
      dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
    onResetCode: () => dispatch(resetCode()),
    onEstimateBreakdown: params => dispatch(estimateBreakdown(params)),
    onRequestToUpdateBooking: params => dispatch(requestToUpdateBooking(params)),
    callSetInitialValues: (setInitialValues, values) => dispatch(setInitialValues(values)),
    onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  };
};

const EditTripPage = compose(
  withRouter,
  withViewport,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(EditTripPageComponent);

EditTripPage.loadData = loadData;

export default EditTripPage;
