import React from 'react';
import classNames from 'classnames';
import css from './LandingPageGo.css';
import { FormattedMessage } from 'react-intl';
import { NamedLink } from '../../components';

const SectionDirectAccess = () => {
  return (
    <div className={css.sectionDirectAccess}>
      <div className={css.sectionDirectAccessWrapper}>
        <h4 className={classNames(css.title, css.whiteText)}>
          <FormattedMessage id='LandingPageGo.SectionDirectAccess.title' />
        </h4>
        <div className={classNames(css.description, css.whiteText, css.alignLeft)}>
          <FormattedMessage id='LandingPageGo.SectionDirectAccess.description' />
        </div>

        <NamedLink name='SearchPage' to={{
          search:
            '?address=Singapore&bounds=1.4708809%2C104.04157989999999%2C1.216611%2C103.60650989999999&meta_isDrivelahGo=true',
        }} className={classNames(css.goToSearchButton, css.darkBackground)}>
          <FormattedMessage id='LandingPageGo.SectionDirectAccess.rentADrivelahGo' />
        </NamedLink>
      </div>

    </div>
  )
};

export default SectionDirectAccess;