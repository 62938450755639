/*
 * Renders a set of options with selected and non-selected values.
 *
 * The corresponding component when selecting the values is
 * FieldCheckboxGroup.
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import includes from 'lodash/includes';

import css from './PropertyCheckList.css';

const checkSelected = (options, selectedOptions) => {
  return options.map(option => ({
    key: option.key,
    label: option.label,
    isSelected: includes(selectedOptions, option.key),
  }));
};

const IconCheck = props => {
  const isSelected = props.isSelected;
  const classes = isSelected ? classNames(css.checkIcon, css.checked) : css.checkIcon;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
      <g id="Rectangle_54" data-name="Rectangle 54" fill="#fff" stroke="#00a3ad" strokeWidth="1" className={classes}>
        <rect width="19" height="19" rx="5" stroke="none" />
        <rect x="0.5" y="0.5" width="18" height="18" rx="4.5" fill="none" />
      </g>
    </svg>

  );
};

const Item = props => {
  const { label, isSelected } = props;
  const labelClass = isSelected ? css.selectedLabel : css.notSelectedLabel;
  return (
    <li className={css.item}>
      <div className={css.labelWrapper}>
        <span className={labelClass}>{label}</span>
      </div>
      <span className={css.iconWrapper}>
        <IconCheck isSelected={isSelected} />
      </span>
    </li>
  );
};

const PropertyCheckList = props => {
  const { rootClassName, className, id, options, selectedOptions, twoColumns } = props;
  const classes = classNames(rootClassName || css.root, className);
  const listClasses = twoColumns ? classNames(classes, css.twoColumns) : classes;

  const checked = checkSelected(options, selectedOptions);

  return (
    <ul className={listClasses}>
      {checked.map(option => (
        <Item key={`${id}.${option.key}`} label={option.label} isSelected={option.isSelected} />
      ))}
    </ul>
  );
};

PropertyCheckList.defaultProps = {
  rootClassName: null,
  className: null,
  selectedOptions: [],
  twoColumns: false,
};

const { arrayOf, bool, node, shape, string } = PropTypes;

PropertyCheckList.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  options: arrayOf(
    shape({
      key: string.isRequired,
      label: node.isRequired,
    })
  ),
  selectedOptions: arrayOf(string),
  twoColumns: bool,
};

export default PropertyCheckList;
