import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { get } from 'lodash';

import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { CUSTOMER, PROVIDER } from '../../util/constants/users';
import tripDetailsPageCss from '../../containers/TripDetailsPage/TripDetailsPage.css';
import bookingBreakdownCss from './BookingBreakdown.css';

const FuelInclusionBreakdown = (props) => {
  const {
    fuelTransaction,
    currentUserRole,
    intl,
  } = props;

  if (!fuelTransaction) {
    return null;
  }

  const isProvider = currentUserRole === PROVIDER;

  const odometerEnd = get(fuelTransaction, 'attributes.protectedData.odometerEnd', 0);
  const odometerStart = get(fuelTransaction, 'attributes.protectedData.odometerStart', 0);
  const dlGoTripDistance = get(fuelTransaction, 'attributes.protectedData.dlGoTripDistance', 0);
  const kms = (odometerEnd - odometerStart) || dlGoTripDistance;
  let total;

  if (currentUserRole === CUSTOMER) {
    const payinTotal = get(fuelTransaction, 'attributes.payinTotal', null);
    total = formatMoney(intl, payinTotal, 1);
  } else if (isProvider) {
    const payoutTotal = get(fuelTransaction, 'attributes.payoutTotal', null);
    total = formatMoney(intl, payoutTotal, 1);
  }

  return (
    <div className={tripDetailsPageCss.detailsContainerMain}>
      <h3 className={tripDetailsPageCss.containerTitle}>
        <FormattedMessage
          id={'TripDetailsPage.fuelAddonTitle'}
          values={{ date: moment(fuelTransaction.attributes.createdAt).format('Do MMM, YYYY') }}
        />
      </h3>
      <div className={bookingBreakdownCss.root}>
        <div className={bookingBreakdownCss.lineItem}>
          <span className={bookingBreakdownCss.itemLabel}>{`Fuel (${kms} kms)`}</span>
          <span className={bookingBreakdownCss.itemValue}>{total}</span>
        </div>
        <div>
          <hr className={bookingBreakdownCss.totalDivider} />
          <div className={bookingBreakdownCss.lineItemTotal}>
            <span className={bookingBreakdownCss.totalLabel}>{isProvider ? 'You Earn' : 'Total price'}</span>
            <span className={bookingBreakdownCss.totalPrice}>{total}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const { oneOf, string } = PropTypes;

FuelInclusionBreakdown.propTypes = {
  fuelTransaction: propTypes.transaction,
  currentUserRole: oneOf([PROVIDER, CUSTOMER]).isRequired,
  intl: Object.isRequired,
};

export default FuelInclusionBreakdown;
