import config from '../../config';
import { TRANSITION_CONFIRM_PAYMENT, TRANSITION_REQUEST } from '../../util/transaction';
import {
  denormalisedResponseEntities,
  ensureCurrentUser,
  ensureStripeCustomer,
} from '../../util/data';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { STRIPE_PI_USER_ACTIONS_DONE_STATUSES } from '../CheckoutPage/CheckoutPage';

import { types as sdkTypes } from '../../util/sdkLoader';
import { savePaymentMethod } from '../../ducks/paymentMethods.duck';

const { UUID } = sdkTypes;

export const INITIATE_ORDER_REQUEST = 'app/CheckEligibilityPage/INITIATE_ORDER_REQUEST';
export const INITIATE_ORDER_SUCCESS = 'app/CheckEligibilityPage/INITIATE_ORDER_SUCCESS';
export const INITIATE_ORDER_ERROR = 'app/CheckEligibilityPage/INITIATE_ORDER_ERROR';

const initialState = {
  transaction: null,
  initiateOrderError: null,
  initiateOrderInProgress: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case INITIATE_ORDER_REQUEST:
      return {
        ...state,
        initiateOrderInProgress: true,
        initiateOrderError: null,
      };
    case INITIATE_ORDER_SUCCESS:
      return {
        ...state,
        initiateOrderInProgress: false,
        transaction: payload,
      };

    case INITIATE_ORDER_ERROR:
      return {
        ...state,
        initiateOrderInProgress: false,
        initiateOrderError: payload,
      };
    default:
      return state;
  }
}

const initiateOrderRequest = () => ({ type: INITIATE_ORDER_REQUEST });

const initiateOrderSuccess = order => ({
  type: INITIATE_ORDER_SUCCESS,
  payload: order,
});

const initiateOrderError = e => ({
  type: INITIATE_ORDER_ERROR,
  error: true,
  payload: e,
});

export const initiateOrder = (card, stripe, paymentMethod, replaceCurrentCard) => (
  dispatch,
  getState,
  sdk
) => {
  const { currentUser } = getState().user;
  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const ensuredStripeCustomer = ensureStripeCustomer(ensuredCurrentUser.stripeCustomer);
  dispatch(initiateOrderRequest());
  const mustSaveCard = card && !paymentMethod && replaceCurrentCard;
  const bodyParams = {
    processAlias: config.bookingProcessAliasDrivelahGoDeposit,
    transition: TRANSITION_REQUEST,
    params: {
      listingId: new UUID(config.preauthenDrivelahGoDepositListingId),
      quantity: 1,
    },
  };

  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  let transactionId = null;
  return sdk.transactions
    .initiate(bodyParams, queryParams)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      const [transaction] = denormalisedResponseEntities(response);
      transactionId = transaction.id;
      const hasPaymentIntents =
        transaction.attributes.protectedData &&
        transaction.attributes.protectedData.stripePaymentIntents;

      if (!hasPaymentIntents) {
        throw new Error(
          `Missing StripePaymentIntents key in transaction's protectedData. Check that your transaction process is configured to use payment intents.`
        );
      }
      const { stripePaymentIntentClientSecret } = hasPaymentIntents
        ? transaction.attributes.protectedData.stripePaymentIntents.default
        : null;

      const args = paymentMethod
        ? [stripePaymentIntentClientSecret, { payment_method: paymentMethod }]
        : [stripePaymentIntentClientSecret, card];

      return stripe
        .retrievePaymentIntent(stripePaymentIntentClientSecret)
        .then(response => {
          if (response.error) {
            throw response.error;
          } else {
            const { paymentIntent } = response;
            return paymentIntent;
          }
        })
        .then(paymentIntent => {
          const hasPaymentIntentUserActionsDone =
            paymentIntent && STRIPE_PI_USER_ACTIONS_DONE_STATUSES.includes(paymentIntent.status);
          if (hasPaymentIntentUserActionsDone) return Promise.resolve({});
          else {
            return stripe.handleCardPayment(...args).then(response => {
              if (response.error) {
                return Promise.reject(response);
              } else {
                return response;
              }
            });
          }
        })
        .then(() => {
          const confirmParams = {
            id: transactionId,
            transition: TRANSITION_CONFIRM_PAYMENT,
            params: {},
          };
          return sdk.transactions.transition(confirmParams).then(response => {
            dispatch(addMarketplaceEntities(response));
            const [transaction] = denormalisedResponseEntities(response);
            dispatch(initiateOrderSuccess(transaction));
            return transaction;
          });
        })
        .then(() => {
          return mustSaveCard
            ? stripe
                .createPaymentMethod({
                  type: 'card',
                  card,
                })
                .then(result => {
                  const payment_method = result.paymentMethod.id;
                  dispatch(savePaymentMethod(ensuredStripeCustomer, payment_method));
                })
            : null;
        });
    })
    .catch(e => {

      dispatch(initiateOrderError(storableError(e)))
    });
};
