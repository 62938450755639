import React, { useEffect, useState } from 'react';
import css from './UserVerificationWithOnfido.css';
import PropTypes from 'prop-types';
import { propTypes } from '../../util/types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { updateUserData } from '../../ducks/user.duck';
import { compose } from 'redux';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { types as sdkTypes } from '../../util/sdkLoader';
import { saveContactDetails } from './PhoneNumber.duck';
import { saveAddressDetails } from './AddressDetailsPage.duck';
import { savePersonalDetails } from '../../containers/PersonalDetailsPage/PersonalDetailsPage.duck';
import UserVerificationStatusIndicator from '../UserVerificationStatusIndicator/UserVerificationStatusIndicator';
import OnfidoVerificationHeader from '../OnfidoVerificationHeader/OnfidoVerificationHeader';
import UserVerificationWithOnfidoForms from '../UserVerificationWithOnfidoForms/UserVerificationWithOnfidoForms';
import LottieElementPage from '../LottieElementPage/LottieElementPage';
import { verifyIdentifyFromServer } from '../../util/identityVerificationHelpers';
import { event_trigger_ids } from '../../util/analyticsConstants';
import { triggerAnalyticsEvent } from '../../util/amplitudeMapEvents';
import { getEventsByBucket } from '../../util/gtm/gtmCreateProperties';

const { LatLng } = sdkTypes;

const UserVerificationWithOnfidoComponent = props => {
  const { currentUser, selectVerificationProcess, fetchCurrentUser } = props;

  const [showSteps, setShowSteps] = useState(false);
  const [continueVerification, setContinueVerification] = useState(false);
  const [step, setStep] = useState({
    stepNum: 0,
    stepTitle: '',
  });

  useEffect(() => {
    if (window) {
      window.scrollTo(0, 0);
    }
  }, []);

  const handleSteps = (stepNum, stepTitle) => {
    setStep({
      stepNum,
      stepTitle,
    });
    setShowSteps(true);
  };

  const flexLosDocumentUrl = get(currentUser, 'attributes.profile.publicData.sponsorshipLetter', '');
  const verficationstatus = props.isHost
    ? get(currentUser, 'attributes.profile.publicData.hostIdentityVerificationStatus')
    : get(currentUser, 'attributes.profile.publicData.guestIdentityVerificationStatus');

  const userLocation = get(currentUser, 'attributes.profile.protectedData.location', null);
  const phoneNumberVerified = get(currentUser, 'attributes.profile.protectedData.phoneNumberVerified', null);
  const isEmailVerified = get(currentUser, 'attributes.emailVerified', null);
  const dateOfBirth = get(currentUser, 'attributes.profile.protectedData.dateOfBirth', null);
  const residencyStatus = get(currentUser, 'attributes.profile.privateData.residencyStatus');

  const totalSteps = props.isHost
    ? 5
    : residencyStatus && residencyStatus === 'Dependent Pass'
    ? 8
    : 7;
  const isResidencyStatus = residencyStatus && residencyStatus !== 'ADMIN FILL INFORMATION HERE';

  const isSubmittedOnfidoDriverVerification = Boolean(get(currentUser, 'attributes.profile.publicData.onFidoCheckId'));
  const isSubmittedOnfidoNricVerification = Boolean(get(currentUser, 'attributes.profile.publicData.onFidoCheckIdNRIC'));

  const isVerified = verficationstatus === 'confirmed';
  const isPendingVerification = verficationstatus === 'pending';
  const isDeclinedVerification = verficationstatus === 'declined';
  const isNotSubmittedVerification = !verficationstatus || verficationstatus === 'not submitted';

  const declinedDueToDuplicate = isDeclinedVerification && (get(currentUser, "attributes.profile.protectedData.singpassResult.description","") === "An account with the same NRIC/ FIN already exists");

  const stepsCount = () => {
    let count = 0;
    if (currentUser && currentUser.id) {
      if (phoneNumberVerified) count += 1;
      if (userLocation) count += 1;
      if (isSubmittedOnfidoNricVerification || isVerified) count += 1;
      if (!props.isHost) {
        if (isEmailVerified) count += 1;
        if (isResidencyStatus) count += 1;
        if (dateOfBirth) count += 1;
      }
    }
    return count;
  };

  const handleUpdateUserData = async () => {
    const publicData = props.isHost
      ? {
          hostIdentityVerificationStatus: 'pending',
          hostVerificationSent: new Date().toISOString(),
        }
      : {
          guestIdentityVerificationStatus: 'pending',
          guestVerificationSent: new Date().toISOString(),
        };

    try {
      await props.onUpdateUserData({ publicData });

      const submitType = props.isHost ? 'host' : 'guest';
      verifyIdentifyFromServer({
        userId: currentUser.id.uuid,
        submitType,
        verificationType: 'manual',
      });

      const eventBucket = 'hostverify';
      if(props.isHost) {
        const response = await getEventsByBucket(eventBucket);
        triggerAnalyticsEvent({
          event_id: event_trigger_ids.HOSTVERIFY_SUBMIT_VERIFICATION,
            // : event_trigger_ids.GUESTVERIFY_SUBMIT_VERIFICATION,
          eventData: response.data,
          props: {
            ui: {
              button: 'Submit',
              page: "VerificationScreen"
            },
            guest: currentUser,
          },
          userId: currentUser.id.uuid,
        });
      }
      
    } catch (error) {
      console.error('Error updating user data or triggering analytics event:', error);
    }
  };

  if (
    (props.isHost &&
      isNotSubmittedVerification &&
      currentUser &&
      currentUser.id &&
      isEmailVerified &&
      dateOfBirth &&
      userLocation &&
      phoneNumberVerified &&
      isSubmittedOnfidoNricVerification) ||
    (!props.isHost &&
      currentUser &&
      currentUser.id &&
      isNotSubmittedVerification &&
      isEmailVerified &&
      dateOfBirth &&
      userLocation &&
      phoneNumberVerified &&
      isSubmittedOnfidoNricVerification &&
      isSubmittedOnfidoDriverVerification &&
      residencyStatus &&
      (residencyStatus === 'Dependent Pass' ? flexLosDocumentUrl : true))
  ) {
    handleUpdateUserData();
  }

  return (
    <>
      <div className={css.titleContainer}>
        <UserVerificationStatusIndicator
          key={"User verification status indicator"}
          showSteps={showSteps}
          step={step}
          isHost={props.isHost}
          totalSteps={totalSteps}
          continueVerification={continueVerification}
          isVerified={isVerified}
          isPending={isPendingVerification}
          isDeclined={isDeclinedVerification}
          isNotSubmitted={isNotSubmittedVerification}
        />

        {isVerified || isPendingVerification || isDeclinedVerification ? (
          <LottieElementPage
            isVerified={isVerified}
            isPending={isPendingVerification}
            isDeclined={isDeclinedVerification}
            redirectToHomePage={props.redirectToHomePage}
            declinedDueToDuplicate={declinedDueToDuplicate}
          />
        ) : null}

        {!isVerified &&
          !isPendingVerification &&
          !isDeclinedVerification &&
          !continueVerification && (
            <OnfidoVerificationHeader
              key={"onfido verification header"}
              currentUser={currentUser}
              handleContinueVerification={() => setContinueVerification(true)}
              phoneNumberVerified={phoneNumberVerified}
              isEmailVerified={isEmailVerified}
              userLocation={userLocation}
              dateOfBirth={dateOfBirth}
              isHost={props.isHost}
              flexLosDocumentUrl={flexLosDocumentUrl}
              residencyStatus={residencyStatus}
              isResidencyStatus={isResidencyStatus}
              isVerified={isVerified}
              isSubmittedOnfidoNricVerification={isSubmittedOnfidoNricVerification}
              isSubmittedOnfidoDriverVerification={isSubmittedOnfidoDriverVerification}
              isPendingVerification={isPendingVerification}
            />
          )}
        {!isDeclinedVerification &&
          !isPendingVerification &&
          !isVerified &&
          continueVerification && (
            <UserVerificationWithOnfidoForms
              key={"user verification onfido forms"}
              fetchCurrentUser={fetchCurrentUser}
              selectVerificationProcess={selectVerificationProcess}
              onComplete={() => {}}
              currentUser={currentUser}
              transactionId={props.transactionId}
              transaction={props.transaction}
              onTransit={props.onTransit}
              haveNextTransit={props.haveNextTransit}
              nextInteractableTransitions={props.nextInteractableTransitions}
              pageName={props.pageName}
              isHost={props.isHost}
              flexLosDocumentUrl={flexLosDocumentUrl}
              phoneNumberVerified={phoneNumberVerified}
              isEmailVerified={isEmailVerified}
              userLocation={userLocation}
              dateOfBirth={dateOfBirth}
              handleSteps={handleSteps}
              isSubmittedOnfidoDriverVerification={isSubmittedOnfidoDriverVerification}
              isSubmittedOnfidoNricVerification={isSubmittedOnfidoNricVerification}
              residencyStatus={residencyStatus}
              isResidencyStatus={isResidencyStatus}
              onUpdateUserData={props.onUpdateUserData}
            />
          )}
      </div>
    </>
  );
};

UserVerificationWithOnfidoComponent.defaultProps = {
  saveEmailError: null,
  savePhoneNumberError: null,
  currentUser: null,
  sendVerificationEmailError: null,
  saveAddressDetailsSuccess: false,
  saveAddressDetailsError: false,
};

const { bool, func } = PropTypes;

UserVerificationWithOnfidoComponent.propTypes = {
  saveEmailError: propTypes.error,
  savePhoneNumberError: propTypes.error,
  saveContactDetailsInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  contactDetailsChanged: bool.isRequired,
  onSubmitContactDetails: func.isRequired,
  onSubmitAddressDetailsForm: func.isRequired,
  scrollingDisabled: bool.isRequired,
  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  currentUser: propTypes.currentUser,
  scrollingDisabled: bool.isRequired,
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser, sendVerificationEmailInProgress, sendVerificationEmailError } = state.user;
  const { connectCalendarTabVisibility } = state.tabPanels;
  const {
    saveEmailError,
    savePhoneNumberError,
    saveContactDetailsInProgress,
    contactDetailsChanged,
  } = state.ContactDetailsPage;

  const {
    saveAddressDetailsSuccess,
    saveAddressDetailsError,
    saveAddressDetailsInProgress,
  } = state.AddressDetailsPage;

  return {
    saveEmailError,
    savePhoneNumberError,
    saveContactDetailsInProgress,
    currentUser,
    contactDetailsChanged,
    scrollingDisabled: isScrollingDisabled(state),
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    connectCalendarTabVisibility,
    saveAddressDetailsSuccess,
    saveAddressDetailsError,
    saveAddressDetailsInProgress,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdateUserData: data => dispatch(updateUserData(data)),
    onSubmitContactDetails: values => dispatch(saveContactDetails(values)),
    onSubmitPersonalDetailsForm: values => dispatch(savePersonalDetails(values)),
    onSubmitAddressDetailsForm: address_values => dispatch(saveAddressDetails(address_values)),
  };
};

const UserVerificationWithOnfido = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(UserVerificationWithOnfidoComponent);

export default UserVerificationWithOnfido;
