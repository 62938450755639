import React from 'react';
import css from './RentAPHV.css';
import { IconSpinner } from '../../components';
import { useHistory, useLocation } from 'react-router-dom';
import { parse } from '../../util/urlHelpers';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { formatMoney } from '../../util/currency';
import { types } from '../../util/sdkLoader';
import config from '../../config';
import imageSource from '../../util/imageSource';
import logo from './../../assets/logo.png';

const { Money } = types;

const PHVIntroBanner = ({ isScoot, signUpCodeAmount, loading, isMobileLayout }) => {
  const location = useLocation();
  const history = useHistory();
  const { code } = parse(location.search);
  const currentUser = useSelector(state => state.user.currentUser);
  const intl = useIntl();
  const redirectToSignup = () => {
    const url = createResourceLocatorString('SignupPage', routeConfiguration(), {}, {});
    history.push(
      code
        ? {
          pathname: url,
          search: `?code=${code}`,
        }
        : {
          pathname: url,
        }
    );
  };
  return (
    <div className={css.bannerIntroSection}>
      <div className={css.container}>
        <div className={css.row}>
          <div className={css.column6}>
            <div className={css.bannerIntroContent}>
              {/* <div className={css.bannerLogoBlock}> */}
              {/* {!isMobileLayout && <img src={imageSource.grabBanner.bannerLogo} alt="" />} */}
              {/* </div> */}
              <h1>
                Driving a PHV? <br /> Join hundreds of Grab & Gojek drivers who are renting flexibly on Drive lah
              </h1>
              <ul className={css.bannerListing}>

                <li>
                  <span className={css.pointIcons}>
                    {isMobileLayout ? (
                      <img src={imageSource.grabBanner.saveMoneyIconMobile} alt="Piggy Saving Icon" />
                    ) : (
                      <img src={imageSource.grabBanner.saveMoneyIcon} alt="Piggy Saving Icon" />
                    )}
                  </span>
                  From only $4/Hour or $29/Day
                </li>
                <li>
                  <span className={css.pointIcons}>
                    {isMobileLayout ? (
                      <img src={imageSource.grabBanner.easyAccessIconMobile} alt="Pointer Icon" />
                    ) : (
                      <img src={imageSource.grabBanner.easyAccessIcon} alt="Pointer Icon" />
                    )}
                  </span>
                  Pay per use, no long lease contracts
                </li>
                <li>
                  <span className={css.pointIcons}>
                    {isMobileLayout ? (
                      <img src={imageSource.grabBanner.approveBlueIconMobile} alt="Approved by LTA" />
                    ) : (
                      <img src={imageSource.grabBanner.approveBlueIcon} alt="Approved by LTA" />
                    )}
                  </span>
                  Approved by LTA
                </li>
              </ul>
              <button onClick={() => redirectToSignup()} className={css.bannerButton}>
                {loading ? (
                  <div className={css.loadingWrapper}>
                    <IconSpinner className={css.loadingSpinner} />
                  </div>
                ) : currentUser && currentUser.id ? (
                  <>
                    <div className={css.signUpTitle}>
                      {signUpCodeAmount && signUpCodeAmount > 0 ? (
                        <span>
                          Sign up now to get{' '}
                          {formatMoney(intl, new Money(signUpCodeAmount, config.currency))} credits
                        </span>
                      ) : (
                        <span>Sign up now to get $30 credits</span>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <div className={css.signUpTitle}>
                      {signUpCodeAmount && signUpCodeAmount > 0 ? (
                        <span>
                          Sign up now to get{' '}
                          {formatMoney(intl, new Money(signUpCodeAmount, config.currency))} credits
                        </span>
                      ) : (
                        <span>Sign up now to get $30 credits</span>
                      )}
                    </div>
                  </>
                )}
              </button>
              <div className={css.mobileBannerLogo}>
                {isMobileLayout && <img src={logo} alt="Drive lah logo" />}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={css.bannerIntroImageSection}>
        <img src={imageSource.grabBanner.bannerBackgroundRent} alt="Flexible monthly rental hire to drive for grab with drive lah" />
      </div>
    </div>
  );
};

export default PHVIntroBanner;
