import React, { useEffect, useState } from 'react';
import axios from 'axios';
import css from './VerificationLanding.css';
import { injectIntl } from '../../util/reactIntl';

import VerificationDefault from './VerificationDefault/VerificationDefault';
import SingpassProfile from './SingpassProfile/SingpassProfile';
import { compose } from 'redux';


const VerificationLandingComponent = (props) => {
  const {intl, currentUser, selectVerificationProcess} = props
  const [userId, setUserId] = useState('');
  const [userIntialValue, setUserInitialValue] = useState(null);
  const getSingpassUserData = async (userIdNew) => {
    try {
      if (userIdNew) {
        const userResponse = await axios.get(
          `${process.env.REACT_APP_API_SERVER_URL}/api/singpass/getUserProfile/${userIdNew}`
        );

        setUserInitialValue(userResponse.data);
      }
    } catch (error) {

    }
  };

  useEffect(() => {
    if(window) {
       window.scrollTo(0, 0)
     }   
  }, [])

  useEffect(() => {
    let sessionUserData = JSON.parse(sessionStorage.getItem('CURRENT_USER'));
    let apiUserId = '';
    if(!userId) {
      if(currentUser && currentUser.id.uuid) {
        setUserId(currentUser.id.uuid);
        apiUserId = currentUser.id.uuid
      }
      if(sessionUserData && typeof sessionUserData === 'object') {
        setUserId(sessionUserData.id.uuid);
        apiUserId = sessionUserData.id.uuid
      }
    }
    getSingpassUserData(apiUserId);
  }, [setUserId, userId, setUserInitialValue]);

  const {attributes} = currentUser || {}
  const { profile} = attributes || {}

  const { protectedData } = profile || {}
  const {singpassData, singpassResult} = protectedData || {}


  return (
    <>
      <div className={css.verificationLanding}>
        {!singpassResult ?<div className={css.actionSection}>
           <VerificationDefault
                key={"verification default page"}
                currentUser={currentUser}
                selectVerificationProcess={selectVerificationProcess}
              />
        </div>: <div className={css.content}>
          <SingpassProfile
              key={"singpass verification"}
              intl={intl}
              currentUser={currentUser}
              initialValues={userIntialValue}
            />
        </div> }
      </div>
    </>
  );
};


const VerificationLanding = compose(injectIntl)(VerificationLandingComponent);

SingpassProfile.displayName = 'VerificationLanding';

export default VerificationLanding;
