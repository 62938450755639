import classNames from 'classnames';
import React, { memo } from 'react';
import Slider from 'react-slick';
import Discover1 from '../../assets/landingPageNew/discoverImg1.jpg';
import Discover2 from '../../assets/landingPageNew/discoverImg2.jpg';
import Discover3 from '../../assets/landingPageNew/discoverImg3.jpg';
import Discover4 from '../../assets/landingPageNew/discoverImg4.jpg';
import { NamedLink } from '../../components';
import { Column, Container, Row } from '../../components/Grids/Grid';
import { FormattedMessage } from '../../util/reactIntl';
import css from './GuestLandingPage.css';

const DiscoverSection = memo(({ openSignUpModal }) => {
  const sliderConfig = {
    autoplay: false,
    dots: false,
    arrows: false,
    infinite: false,
    slidesToScroll: 1,
    slidesToShow: 1.25,
  };
  return (
    <div className={classNames(css.sectionWithBackground, css.discoverBest)}>
      <Container>
        <Row>
          <Column large={12}>
            <h3 className={css.heading}>Best rental cars near you <span>Find cars at Bukit Butok, Punggol, Tampines etc</span></h3>
          </Column>
        </Row>
        <Row className={css.showDesktop}>
          <Column large={3} medium={4} small={12}>
            <NamedLink
              name="SeoLocationLandingPage"
              params={{
                cat: 'singapore-locations',
                subcat: 'car-rental-in-bukit-batok',
              }}
              className={css.card}
            >
              <div className={css.cardThumb}>
                <img src={Discover1} alt="" />
              </div>
              <div className={css.cardContent}>
                <h3>Bukit Batok</h3>
                {/* <span>1,440+ Cars Available</span> */}
              </div>
            </NamedLink>
          </Column>
          <Column large={3} medium={4} small={12}>
            <NamedLink
              name="SeoLocationLandingPage"
              params={{
                cat: 'singapore-locations',
                subcat: 'car-rental-in-tampines',
              }}
              className={css.card}
            >
              <div className={css.cardThumb}>
                <img src={Discover2} alt="" />
              </div>
              <div className={css.cardContent}>
                <h3>Tampines</h3>
                {/* <span>564+ Cars Available</span> */}
              </div>
            </NamedLink>
          </Column>
          <Column large={3} medium={4} small={12}>
            <NamedLink
              name="SeoLocationLandingPage"
              params={{
                cat: 'singapore-locations',
                subcat: 'car-rental-in-punggol',
              }}
              className={css.card}
            >
              <div className={css.cardThumb}>
                <img src={Discover3} alt="" />
              </div>
              <div className={css.cardContent}>
                <h3>Punggol</h3>
                {/* <span>800+ Cars Available</span> */}
              </div>
            </NamedLink>
          </Column>
          <Column large={3} medium={4} small={12}>
            <NamedLink
              name="SeoLocationLandingPage"
              params={{
                cat: 'singapore-locations',
                subcat: 'car-rental-in-choa-chu-kang',
              }}
              className={css.card}
            >
              <div className={css.cardThumb}>
                <img src={Discover4} alt="" />
              </div>
              <div className={css.cardContent}>
                <h3>Choa Chu Kang</h3>
                {/* <span>400+ Cars Available</span> */}
              </div>
            </NamedLink>
          </Column>
        </Row>
        <Row className={css.showMobile}>
          <Slider {...sliderConfig}>
            <Column large={12}>
              <NamedLink
                name="SeoLocationLandingPage"
                params={{
                  cat: 'singapore-locations',
                  subcat: 'car-rental-in-bukit-batokr',
                }}
                className={css.card}
              >
                <div className={css.cardThumb}>
                  <img src={Discover1} alt="" />
                </div>
                <div className={css.cardContent}>
                  <h3>Bukit Batok</h3>
                  {/* <span>1,440+ Cars Available</span> */}
                </div>
              </NamedLink>
            </Column>
            <Column large={12}>
              <NamedLink
                name="SeoLocationLandingPage"
                params={{
                  cat: 'singapore-locations',
                  subcat: 'car-rental-in-tampines',
                }}
                className={css.card}
              >
                <div className={css.cardThumb}>
                  <img src={Discover2} alt="" />
                </div>
                <div className={css.cardContent}>
                  <h3>Tampines</h3>
                  {/* <span>564+ Cars Available</span> */}
                </div>
              </NamedLink>
            </Column>
            <Column large={12}>
              <NamedLink
                name="SeoLocationLandingPage"
                params={{
                  cat: 'singapore-locations',
                  subcat: 'car-rental-in-punggol',
                }}
                className={css.card}
              >
                <div className={css.cardThumb}>
                  <img src={Discover3} alt="" />
                </div>
                <div className={css.cardContent}>
                  <h3>Punggol</h3>
                  {/* <span>800+ Cars Available</span> */}
                </div>
              </NamedLink>
            </Column>
            <Column large={12}>
              <NamedLink
                name="SeoLocationLandingPage"
                params={{
                  cat: 'singapore-locations',
                  subcat: 'car-rental-in-choa-chu-kang',
                }}
                className={css.card}
              >
                <div className={css.cardThumb}>
                  <img src={Discover4} alt="" />
                </div>
                <div className={css.cardContent}>
                  <h3>Choa Chu Kang</h3>
                  {/* <span>400+ Cars Available</span> */}
                </div>
              </NamedLink>
            </Column>
          </Slider>
        </Row>
        <Row className={css.buttonWrapper}>
          <Column alignItems="center" large={12}>
            <button onClick={openSignUpModal} className={classNames(css.btn, css.btnPrimary)}>
              <FormattedMessage id="LandingPageNew.GetStarted" />
            </button>
          </Column>
        </Row>
      </Container>
    </div>
  );
});

export default DiscoverSection;
