import React from 'react';
import classNames from 'classnames';
import css from './TrustAndSafetyPage.css';

const WhenYouDriveItem = props => {
  const {
    imageSource,
    secondSource,
    altSource = "image",
    content,
    title,
    width,
    height,
  } = props;
  return (
    <div className={css.whenYouDriveItemContainer}>
      <div className={css.whenYouDriveItemTitleContainer}>
        <div>
        <img width={width} height={height} className={css.whenYouDriveItemImage} src={imageSource} alt={altSource}/>
        {secondSource ? (<img width={width} height={height} className={classNames(css.whenYouDriveItemImage, css.driveItemImageSecondSource)} src={secondSource} alt={altSource}/>) : null}
        </div>
        <div className={classNames(css.whenYouDriveItemTitle, css.whenYouDriveItemTitleMobile)}>{title}</div>
      </div>

      <div className={css.whenYouDriveItemTextBox}>
        <p><strong className={css.whenYouDriveItemTitle}>{title}</strong>{content}</p>
      </div>
    </div>
  );
};

export default WhenYouDriveItem;
