import axios from 'axios';

export const checkAndCreateOnfidoToken = async currentUser => {
  try {
    const userId = currentUser.id.uuid;
    if (!userId) {
      console.error('Unable to create applicant: user ID is undefined');
      return;
    }

    const response = await axios.get(
      `${process.env.REACT_APP_API_SERVER_URL}/api/onfidoApplicant/${userId}`
    );

    if (response && response.data.data.token) {
      const token = response.data.data.token;
      const onfidoUserId = response.data.OnfidoUserID;
      return { onfidoUserId, token };
    }
  } catch (error) {
    console.error(error);
    return;
  }
};

export const createOnfidoCheckIds = async ({ reportNames, onfidoApplicantId }) => {
  try {
    const applicantId = onfidoApplicantId;
    const response = await axios.post(
      `${process.env.REACT_APP_API_SERVER_URL}/api/onfidoApplicant/onfidoCheck`,
      {
        applicant_id: applicantId,
        reportNames: reportNames,
      }
    );

    return {
      status: response.data.status,
      checkId: response.data.id,
      reportIds: response.data.report_ids,
    };
  } catch (error) {
    console.error(error);
    return;
  }
};

export const updateFlexUserCheckId = async ({
  onfidoApplicantId,
  reportNames,
  isHost,
  isNricCheck = false,
  isDriverVerificationCheck = false,
}) => {
  const checkResponse = await createOnfidoCheckIds({ reportNames, onfidoApplicantId });

  if (checkResponse) {
    const { status, checkId, reportIds } = checkResponse || {};


    if (status === 'in_progress') {
      let protectedData = {}
      let publicData = isHost ? {
        hostIdentityVerificationStatus: 'pending',
        hostVerificationSent: new Date().toISOString()
      } : {};
      if (isNricCheck) {
        publicData.onFidoCheckIdNRIC = checkId;
        protectedData.onfidoDocNRICIds = reportIds;
      }
      if (isDriverVerificationCheck) {
        publicData.onFidoCheckId = checkId;
        protectedData.onfidoDocIds = reportIds;
      }

      const updateUserData = {
        protectedData,
        publicData,
      };

      return updateUserData;
    }
  }
  return;
};
