import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './RentalAgreement.css';

const RentalAgreement = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <div style={{color: '#00a3ad'}}>
      <p><strong>RENTAL AGREEMENT</strong></p>
    <p>This rental agreement is entered into between the Host and the Guest (each, a “<strong>party</strong>” and collectively, the “<strong>parties</strong>”) for the rental of the Vehicle on the following terms and conditions.</p>
    <br/>
    <div>
        <li>1. INTERPRETATION&nbsp;</li>
    </div>
    <p>
        <br/>
    </p>
    <p>1.1 &nbsp;&nbsp;&nbsp;The definitions and rules of interpretation in this clause apply in this agreement.</p>
    <br/>
        <table>
            <tbody>
                <tr className={css.row}>
                    <td className={css.left}>
                        <p><strong>Authorised</strong></p>
                        <p><strong>Driver&nbsp;&nbsp;</strong></p>
                    </td>
                    <td className={css.right}>
                        <p>a person with a valid licence to drive in the jurisdiction the Vehicle is being driven in, who has been verified by Drive lah in accordance with its policies and notified to the Driver as a driver of the Vehicle;</p>
                        <p>
                            <br/>
                        </p>
                    </td>
                </tr>
                <tr className={css.row}>
                    <td className={css.left}><span><strong>Drive lah</strong></span></td>
                    <td className={css.right}>
                        <p>DRIVE LAH PTE. LTD. (UEN No.: 201900261M), a company duly incorporated in Singapore with registered address at 531A Upper Cross Street, #04-95, Hong Lim Complex, Singapore 051531;</p>
                        <p>
                            <br/>
                        </p>
                    </td>
                </tr>
                <tr className={css.row}>
                <td className={css.left}><span><strong>Drop-off Time</strong></span></td>
                <td className={css.right}>
                        <p>the time and date of which the later of the parties have confirmed via the Platform the return of the Vehicle to the Host at the Designated Location;</p>
                        <p>
                            <br/>
                        </p>
                    </td>
                </tr>
                <tr className={css.row}>
                <td className={css.left}>
                        <p><strong>Designated</strong></p>
                        <p><strong>Location</strong></p>
                    </td>
                    <td className={css.right}>
                        <p>the designated location at which the Vehicle is to be collected or returned to the Host by the Guest, or such other location as parties may agree;</p>
                        <p>
                            <br/>
                        </p>
                    </td>
                </tr>
                <tr className={css.row}>
                <td className={css.left}><span><strong>Trip Price</strong></span></td>
                <td className={css.right}>
                        <p>the fees payable to the Host for the rental of the Vehicle by the Guest, as set out on the Listing, exclusive of any service fees or other fees payable to Drive lah or any other third party;</p>
                        <p>
                            <br/>
                        </p>
                    </td>
                </tr>
                <tr className={css.row}>
                <td className={css.left}><span><strong>Listing</strong></span></td>
                <td className={css.right}>
                        <p>the listing posted on the Platform in respect of the Vehicle;</p>
                        <p>
                            <br/>
                        </p>
                    </td>
                </tr>
                <tr className={css.row}>
                <td className={css.left}><span><strong>Host</strong></span></td>
                <td className={css.right}>
                        <p>the person, whose particulars are set out in the Platform, who has a Vehicle to rent on the Platform;</p>
                        <p>
                            <br/>
                        </p>
                    </td>
                </tr>
                <tr className={css.row}>
                <td className={css.left}><span><strong>Guest</strong></span></td>
                <td className={css.right}>
                        <p>the person, whose particulars are set out in the Platform, who is intending to rent a Vehicle;</p>
                        <p>
                            <br/>
                        </p>
                    </td>
                </tr>
                <tr className={css.row}>
                <td className={css.left}><span><strong>Vehicle</strong></span></td>
                <td className={css.right}>
                        <p>a vehicle defined under the Road Traffic Act (Cap. 276) as a motor vehicle and registered with the Land Transport Authority as belonging to a Host and listed on the Platform by that Host for rent;</p>
                        <p>
                            <br/>
                        </p>
                    </td>
                </tr>
                <tr className={css.row}>
                <td className={css.left}><span><strong>PDPA</strong></span></td>
                <td className={css.right}>
                        <p>Personal Data Protection Act 2012;</p>
                        <p>
                            <br/>
                        </p>
                    </td>
                </tr>
                <tr className={css.row}>
                <td className={css.left}><span><strong>Personal Data</strong></span></td>
                <td className={css.right}>
                        <p>has the meaning given in the PDPA;</p>
                        <p>
                            <br/>
                        </p>
                    </td>
                </tr>
                <tr className={css.row}>
                <td className={css.left}><span><strong>Pick-up Time</strong></span></td>
                <td className={css.right}>
                        <p>the time and date of which the later of the parties have confirmed collection via the Platform of the Vehicle by the Guest from the Host at the Designated Location;</p>
                        <p>
                            <br/>
                        </p>
                    </td>
                </tr>
                <tr className={css.row}>
                <td className={css.left}><span><strong>Policies</strong></span></td>
                <td className={css.right}>
                        <p>any policies imposed by Drive lah on Guests and/or Hosts from time to time, as set out on the Platform;</p>
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                        <p>
                            <br/>
                        </p>
                    </td>
                </tr>
                <tr className={css.row}>
                <td className={css.left}><span><strong>Platform</strong></span></td>
                <td className={css.right}>
                        <p>Drive lah’s website (<a href="https://www.drivelah.sg/">https://www.drivelah.sg/</a>) and/or its associated mobile applications;</p>
                        <p>
                            <br/>
                        </p>
                    </td>
                </tr>
                <tr className={css.row}>
                <td className={css.left}><span><strong>Rental Period</strong></span></td>
                <td className={css.right}>
                        <p>the period of hire for the Vehicle as agreed between the parties via the Platform; and</p>
                        <p>
                            <br/>
                        </p>
                    </td>
                </tr>
                <tr className={css.row}>
                <td className={css.left}><span><strong>Terms</strong></span></td>
                <td className={css.right}>
                        <p>the terms of use of the Platform, as modified or amended by Drive lah from time to time.</p>
                        <p>
                            <br/>
                        </p>
                    </td>
                </tr>
            </tbody>
        </table>
    <p>1.2 &nbsp;&nbsp;&nbsp;Clause and paragraph headings shall not affect the interpretation of this agreement.&nbsp;</p>
    <p>
        <br/>
    </p>
    <p>1.3 &nbsp;&nbsp;&nbsp;A <strong>person</strong> includes a natural person, corporate or unincorporated body (whether or not having separate legal personality) and that person's legal and personal representatives, successors and permitted assigns.&nbsp;</p>
    <p>
        <br/>
    </p>
    <p>1.4 &nbsp;&nbsp;&nbsp;A reference to a <strong>company</strong> shall include any company, corporation or other body corporate, wherever and however incorporated or established.&nbsp;</p>
    <p>
        <br/>
    </p>
    <p>1.5 &nbsp;&nbsp;&nbsp;Unless the context otherwise requires, words in the singular shall include the plural and vice versa.&nbsp;</p>
    <p>
        <br/>
    </p>
    <p>1.6 &nbsp;&nbsp;&nbsp;Unless the context otherwise requires, a reference to one gender shall include a reference to the other genders.&nbsp;</p>
    <p>
        <br/>
    </p>
    <p>1.7 &nbsp;&nbsp;&nbsp;A reference to a statute or statutory provision is a reference to it as it is in force for the time being, taking account of any amendment, extension or re-enactment, and includes any subordinate legislation for the time being in force made under it.&nbsp;</p>
    <p>1.8 &nbsp;&nbsp;&nbsp;A reference to writing or written includes email.&nbsp;</p>
    <p>
        <br/>
    </p>
    <p>1.9 &nbsp;&nbsp;&nbsp;Any words following the terms <strong>including</strong>, <strong>include</strong>, <strong>in particular</strong> or any similar expression shall be construed as illustrative and shall not limit the sense of the words, description, definition, phrase or term preceding those terms.&nbsp;</p>
    <p>
        <br/>
    </p>
    <div>
        <li>2. RENTAL OF VEHICLE&nbsp;</li>
    </div>
    <p>
        <br/>
    </p>
    <p>2.1 &nbsp;&nbsp;&nbsp;The Host shall hire the Vehicle to the Guest for the Rental Period, subject to the terms and conditions of this agreement.&nbsp;</p>
    <p>
        <br/>
    </p>
    <p>2.2 &nbsp;&nbsp;&nbsp;The parties agree that they shall at all times abide by the Terms and the prevailing Policies.&nbsp;</p>
    <p>
        <br/>
    </p>
    <p>2.3 &nbsp;&nbsp;&nbsp;The Vehicle shall at all times remain the property of the Host, and the Guest shall have no right, title or interest in or to the Vehicle (save the right to possession and use of the Vehicle during the Rental Period, subject to the terms and conditions of this agreement).&nbsp;</p>
    <p>
        <br/>
    </p>
    <p>2.4 &nbsp;&nbsp;&nbsp;The risk of loss, theft, damage or destruction of the Vehicle shall pass to the Guest at the Pick-Up Time. The Vehicle shall remain at the sole risk of the Guest during the Rental Period and any further term during which the Vehicle is in the possession, custody or control of the Guest, until the Drop-off Time.&nbsp;</p>
    <p>
        <br/>
    </p>
    <p>2.5 &nbsp;&nbsp;&nbsp;The parties agree that they shall at all times, comply with any applicable laws or regulations, including any laws or regulations with respect to insurance coverage.</p>
    <p>
        <br/>
    </p>
    <p>2.6 &nbsp;&nbsp;&nbsp;For the avoidance of doubt, the parties agree and acknowledge that Drive lah is not a party to this agreement and is a mere platform service provider.&nbsp;</p>
    <p>
        <br/>
    </p>
    <div>
        <li>3. FEES</li>
    </div>
    <p>
        <br/>
    </p>
    <p>3.1. &nbsp;&nbsp;&nbsp;In consideration of the hire of the Vehicle to the Guest for the Rental Period, the Guest shall pay the Trip Price to the Host.</p>
    <p>
        <br/>
    </p>
    <p>3.2. &nbsp;&nbsp;&nbsp;The parties agree that the Trip Price shall be paid to the Host through the payment services offered via the Platform, in accordance with the Terms and the Policies.&nbsp;</p>
    <p>
        <br/>
    </p>
    <p>3.3. &nbsp;&nbsp;&nbsp;The parties acknowledge and agree to pay any additional fees set out in thePolicies, including any fees payable to the other party for additional mileage, late return, car breakdown or any other failure to comply with the Terms and/or the Policies.&nbsp;</p>
    <p>
        <br/>
    </p>
    <p>3.4. &nbsp;&nbsp;&nbsp;All payments to be made by either party under this agreement shall be made without withholding or set-off on account of disputes, counterclaims or for any other reason whatsoever.&nbsp;</p>
    <p>
        <br/>
    </p>
    <div>
        <li>4. GUEST’S RESPONSIBILITIES&nbsp;</li>
    </div>
    <p>
        <br/>
    </p>
    <p>4.1 The Guest shall during the term of this agreement:&nbsp;</p>
    <ol>
        <li>(a) &nbsp;&nbsp;&nbsp; ensure that the Vehicle is operated with due care and diligence, and at all times in accordance with the Policies and the Terms;&nbsp;</li>
    </ol>
    <ol>
        <li>(b) &nbsp;&nbsp;&nbsp; ensure that the Vehicle is only operated by the Authorised Driver;&nbsp;</li>
    </ol>
    <ol>
        <li>(c) &nbsp;&nbsp;&nbsp; ensure that all applicable rules and regulations, including any road traffic codes or rules of conduct are complied with;&nbsp;</li>
    </ol>
    <ol>
        <li>(d) &nbsp;&nbsp;&nbsp; maintain at its own expense the Vehicle in the same condition as at the Pick-Up Time (fair wear and tear only excepted, in accordance with the Policies);&nbsp;</li>
    </ol>
    <ol>
        <li>(e) &nbsp;&nbsp;&nbsp; keep the Host fully informed of all material matters relating to the Vehicle, including complying with any applicable Policies regarding the reporting of any accident, damage or loss to the Vehicle;&nbsp;</li>
    </ol>
    <ol>
        <li>(f) &nbsp;&nbsp;&nbsp; comply with all Policies regarding the pick-up and drop-off of the Vehicle from and with the Host;&nbsp;</li>
    </ol>
    <ol>
        <li>(g) &nbsp;&nbsp;&nbsp; not, without the prior written consent of the Host, part with control of (including for the purposes of repair or maintenance), sell or offer for sale, underlet, sublet or lend the Vehicle or allow the creation of any mortgage, charge, lien or other security interest in respect of the Vehicle;&nbsp;</li>
    </ol>
    <ol>
        <li>(h) &nbsp;&nbsp;&nbsp; not use the Vehicle for any commercial or non-personal use (including but not limited to using the Vehicle as a private hire vehicle);&nbsp;</li>
    </ol>
    <ol>
        <li>(i) &nbsp;&nbsp;&nbsp; deliver up the Vehicle at the end of the Rental Period at the Designated Location in a timely manner in accordance with the Policies, failing which the Policy on late returns shall apply; and&nbsp;</li>
    </ol>
    <ol>
        <li>(j) &nbsp;&nbsp;&nbsp; not do or permit to be done anything which could invalidate any applicable insurance in&nbsp;</li>
    </ol>
    <div>
        <li>5. REPRESENTATIONS AND WARRANTIES&nbsp;</li>
    </div>
    <p>
        <br/>
    </p>
    <p>5.1 The Host hereby irrevocably and unconditionally represents and warrants:&nbsp;</p>
    <ol>
        <li>(a) &nbsp;&nbsp;&nbsp; they are the registered owner of the Vehicle and or have been duly authorised by the registered owner of the Vehicle to enter into this agreement;&nbsp;</li>
    </ol>
    <ol>
        <li>(b) &nbsp;&nbsp;&nbsp; the registered owner of the Vehicle has the right to let the Vehicle for hire in accordance with this agreement;&nbsp;</li>
    </ol>
    <ol>
        <li>(c) &nbsp;&nbsp;&nbsp; the Vehicle is in compliance with the applicable Terms and Policies;&nbsp;</li>
    </ol>
    <ol>
        <li>(d) &nbsp;&nbsp;&nbsp; the Vehicle is in safe and roadworthy condition, in good mechanical condition, and in full compliance with all applicable inspection and registration requirements; and&nbsp;</li>
    </ol>
    <ol>
        <li>(e) &nbsp;&nbsp;&nbsp; any information or specifications set out on the Platform or provided by the Host to the Guest with respect to the Vehicle is complete, accurate and not misleading in any way.&nbsp;</li>
    </ol>
    <div>
        <li>6. LIMITATION OF LIABILITY&nbsp;</li>
    </div>
    <p>
        <br/>
    </p>
    <p>6.1 &nbsp;&nbsp;&nbsp;Subject to clause 6.2, neither party shall be liable, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, arising under or in connection with this agreement for:&nbsp;</p>
    <ol>
        <li>(a) &nbsp;&nbsp;&nbsp; loss of profits, sales, business, or revenue;&nbsp;</li>
    </ol>
    <ol>
        <li>(b) &nbsp;&nbsp;&nbsp; business interruption;&nbsp;</li>
    </ol>
    <ol>
        <li> (c) &nbsp;&nbsp;&nbsp; loss of anticipated savings;&nbsp;</li>
    </ol>
    <ol>
        <li>(d) &nbsp;&nbsp;&nbsp; loss or corruption of data or information;&nbsp;</li>
    </ol>
    <ol>
        <li>(e) &nbsp;&nbsp;&nbsp; cost of substitute products or services;&nbsp;</li>
    </ol>
    <ol>
        <li>(f) &nbsp;&nbsp;&nbsp; loss of business opportunity, goodwill or reputation; or&nbsp;</li>
    </ol>
    <ol>
        <li>(g) &nbsp;&nbsp;&nbsp; any incidental, special, indirect or consequential loss or damage.&nbsp;</li>
    </ol>
    <p>6.2 Nothing in this agreement shall limit or exclude any party’s liability for:&nbsp;</p>
    <ol>
        <li>(a) &nbsp;&nbsp;&nbsp; death or personal injury resulting from negligence;&nbsp;</li>
    </ol>
    <ol>
        <li>(b) &nbsp;&nbsp;&nbsp; fraud; and/or&nbsp;</li>
    </ol>
    <ol>
        <li>(c) &nbsp;&nbsp;&nbsp; any other matter in respect of which any party is prohibited under applicable law from limiting or excluding liability.&nbsp;</li>
    </ol>
    <div>
        <li>7 TERMINATION&nbsp;</li>
    </div>
    <p>
        <br/>
    </p>
    <p>7.1 &nbsp;&nbsp;&nbsp;This agreement shall commence upon the confirmation of the Guest’s booking of the Vehicle by the Host via the Platform, and shall continue until the Drop-Off Time, unless terminated earlier in accordance with this clause 7.&nbsp;</p>
    <p>
        <br/>
    </p>
    <p>7.2 &nbsp;&nbsp;&nbsp;A party may terminate this agreement by cancelling the trip via the Platform in accordance with the applicable Policies. This agreement shall terminate with immediate effect upon such cancellation. A trip cannot be cancelled during the Rental Period.&nbsp;</p>
    <p>
        <br/>
    </p>
    <p>7.3 &nbsp;&nbsp;&nbsp;This agreement shall be automatically terminated in the event that the trip for which the Guest has agreed to rent the Vehicle is cancelled by Drive lah.&nbsp;</p>
    <p>
        <br/>
    </p>
    <p>7.4 &nbsp;&nbsp;&nbsp;Termination of this agreement shall be without prejudice to the rights and obligations of the parties accrued up to the date of termination.&nbsp;</p>
    <p>
        <br/>
    </p>
    <p>7.5 &nbsp;&nbsp;&nbsp;Termination of this agreement shall not affect the continuation in force of clause 7 and any other clause of this agreement which is expressed or by its nature intended to continue in force notwithstanding termination of this agreement.&nbsp;</p>
    <p>
        <br/>
    </p>
    <p>7.6 &nbsp;&nbsp;Upon termination of this agreement:&nbsp;</p>
    <ol>
        <li>(a) &nbsp;&nbsp;&nbsp; the Guest shall not be entitled to any refund for the Trip Price (in whole or in part) unless provided for in the applicable Policies; and&nbsp;</li>
    </ol>
    <ol>
        <li>(b) &nbsp;&nbsp;&nbsp; where the Guest has collected the Vehicle from the Host, the Guest shall immediately return the Vehicle to the Host at the Designated Location in accordance with the Policies.&nbsp;</li>
    </ol>
    <p><strong>&nbsp; &nbsp; &nbsp;</strong></p>
    <div>
        <li>8. PERSONAL DATA&nbsp;</li>
    </div>
    <p>
        <br/>
    </p>
    <p>8.1 &nbsp;&nbsp;&nbsp;Each party acknowledges that as a result of this agreement, they may obtain Personal Data of the other party. Neither party shall, during and after termination of this agreement, without the prior written consent of the other party, use, disclose or retain such Personal Data beyond the purposes contemplated by this agreement or legal purposes.&nbsp;</p>
    <p>
        <br/>
    </p>
    <p>8.2 &nbsp;&nbsp;&nbsp;Each party shall undertake reasonable security arrangements to prevent unauthorised access, collection, use, disclosure, copying, modification, disposal or similar risks with respect to the other party’s personal data.&nbsp;</p>
    <p>
        <br/>
    </p>
    <p>8.3 &nbsp;&nbsp;&nbsp;Each party shall at all times, comply with the provisions of the PDPA in respect of the other party’s Personal Data.&nbsp;</p>
    <p>
        <br/>
    </p>
    <div>
        <li>9. GENERAL&nbsp;</li>
    </div>
    <p>
        <br/>
    </p>
    <p>9.1 &nbsp;&nbsp;&nbsp;Neither party may assign, novate or sub-contract any of its rights or obligations under this agreement.&nbsp;</p>
    <p>
        <br/>
    </p>
    <p>9.2 &nbsp;&nbsp;&nbsp;No variation of this agreement shall be effective unless it is agreed between the parties in writing.&nbsp;</p>
    <p>
        <br/>
    </p>
    <p>9.3 &nbsp;&nbsp;&nbsp;A waiver of any right under this agreement shall be in writing. Such waiver shall apply only to the person to whom the waiver is addressed and the circumstances for which it is given. Any failure to enforce any provision of this agreement shall not constitute a waiver of such or any other provision. Unless specifically provided otherwise, rights and remedies arising under this agreement are cumulative and do not exclude rights and remedies provided by law.&nbsp;</p>
    <p>
        <br/>
    </p>
    <p>9.4 &nbsp;&nbsp;&nbsp;If any provision of this agreement is or becomes invalid, illegal or unenforceable, it shall be deemed modified to the minimum extent necessary to make it valid, legal and enforceable. If such modification is not possible, the relevant provision shall be deemed deleted. Any modification to or deletion of a provision under this clause shall not affect the validity and enforceability of the rest of this agreement.&nbsp;</p>
    <p>
        <br/>
    </p>
    <p>9.5 &nbsp;&nbsp;&nbsp;This agreement and any documents referred to in it constitutes the whole agreement between the parties and supersedes all previous arrangements, understandings and agreements between the parties relating to its subject matter.&nbsp;</p>
    <p>
        <br/>
    </p>
    <p>9.6 &nbsp;&nbsp;&nbsp;The parties agree that in the event any dispute arises out of or in connection with this agreement, they shall first submit the dispute to Drive lah for resolution, and shall co-operate, in good faith, with Drive lah to resolve the dispute.&nbsp;</p>
    <p>
        <br/>
    </p>
    <p>9.7 &nbsp;&nbsp;&nbsp;This agreement is governed by and will be interpreted in accordance with the laws of Singapore. Subject to clause 9.6, each of the parties submits to the exclusive jurisdiction of the Singapore Courts as regards any claim or matter arising under this agreement.&nbsp;</p>
    <p>
        <br/>
    </p>
      </div>
    </div>
  );
};

RentalAgreement.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

RentalAgreement.propTypes = {
  rootClassName: string,
  className: string,
};

export default memo(RentalAgreement);
