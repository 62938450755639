import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import IntroPanel from '../IntroPanel/IntroPanel';
import { NamedLink } from '../../components';

import iconMoreChoices from '../../assets/landingpage/more_choices.svg';
import iconAffordable from '../../assets/landingpage/affordable.svg';
import iconAlwaysNearby from '../../assets/landingpage/always_nearby.svg';

import css from './SectionUsers.css';

const SectionUsers = props => {
  const {
    intl
  } = props

  const iconList = [{
    imgSrc: iconMoreChoices,
    text: 'more choice, no hassle'
  }, {
    imgSrc: iconAffordable,
    text: (<span className={css.smallText}>affordable<br />30-40% cheaper</span>)
  }, {
    imgSrc: iconAlwaysNearby,
    text: 'always nearby'
  }]

  const footer = (
    <div className={css.footer}>
      <NamedLink
        name="BecomeAGuestPage"
        className={classNames(css.findoutButton, css.footerButton)}
      >
        <FormattedMessage id="SectionUsers.findoutButton" />
      </NamedLink>
      <NamedLink
        name="SearchPage"
        to={{
          search:
            '?address=Singapore&bounds=1.4708809%2C104.04157989999999%2C1.216611%2C103.60650989999999',
        }}
        className={classNames(css.rentACarButton, css.footerButton)}
      >
        <FormattedMessage id="SectionUsers.rentACarButton" />
      </NamedLink>
    </div>
  );

  return (
    <IntroPanel
      className={css.root}
      iconTextStyle={css.iconTextStyle}
      questionTitle={intl.formatMessage({ id: 'SectionUsers.questionTitle' })}
      answerTitle={intl.formatMessage({ id: 'SectionUsers.answerTitle' })}
      subtitle={(<FormattedMessage id="SectionUsers.subtitle" values={{newline: (<br />)}} />)}
      iconList={iconList}
      footer={footer}
    />
  )
};

SectionUsers.defaultProps = {

};

SectionUsers.propTypes = {
  intl: PropTypes.object
};

export default SectionUsers;
