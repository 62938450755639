import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import config from '../../config';
import { twitterPageURL } from '../../util/urlHelpers';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  ExternalLink,
  Footer,
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  IconSocialMediaTwitter,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
} from '../../components';
import { triggerAnalyticsEvent } from '../../util/amplitudeMapEvents';
import css from './ContactUsPage.css';
import classNames from 'classnames';
import imageSource from '../../util/imageSource';
import { event_trigger_ids } from '../../util/analyticsConstants';
import { getEventsByBucket } from '../../util/gtm/gtmCreateProperties';

const renderSocialMediaLinks = intl => {
  const { siteFacebookPage, siteInstagramPage, siteTwitterHandle } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
  const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });

  const fbLink = siteFacebookPage ? (
    <ExternalLink key="linkToFacebook" href={siteFacebookPage} className={css.icon} title={goToFb}>
      <IconSocialMediaFacebook className={css.iconSize} />
    </ExternalLink>
  ) : null;

  const twitterLink = siteTwitterPage ? (
    <ExternalLink
      key="linkToTwitter"
      href={siteTwitterPage}
      className={css.icon}
      title={goToTwitter}
    >
      <IconSocialMediaTwitter className={css.iconSize} />
    </ExternalLink>
  ) : null;

  const instragramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteInstagramPage}
      className={css.icon}
      title={goToInsta}
    >
      <IconSocialMediaInstagram className={css.iconSize} />
    </ExternalLink>
  ) : null;
  return [fbLink, twitterLink, instragramLink].filter(v => v != null);
};

const ContactUsPage = props => {
  const { intl, currentUser } = props;
  const [eventData, setEventData] = useState(null);
  const userId = currentUser && currentUser.id ? currentUser.id.uuid : null;
  const socialMediaLinks = renderSocialMediaLinks(intl);

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const response = await getEventsByBucket('browsing');
        setEventData(response.data);
      } catch (error) {
        console.error('Error fetching event data:', error);
      }
    };

    // console.log("Current user logged in", currentUser);

    fetchEventData();
  }, []);
  const openChat = () => {
    if (typeof window !== 'undefined' || window) {
      window.Intercom('show');
    }
    // Trigger analytics event with userId
    triggerAnalyticsEvent({
      event_id: event_trigger_ids.BROWSE_NEED_HELP_INTERCOM_CHAT,
      eventData,
      props: {
          ui: {
            button: "Chat with us",
            page: 'ContactUsScreen'
          }
      },
      userId: userId
    })
    
  };

  const handlePhoneClick = () => {
    // Trigger analytics event with userId
    triggerAnalyticsEvent({
      event_id: event_trigger_ids.BROWSE_NEED_HELP_CALL,
      eventData,
      props: {
          ui: {
            button: "+ 65 6432 8307",
            page: "ContactUs Screen"
          }
      },
      userId: userId
    })
  };

  const handleEmailClick = () => {
    triggerAnalyticsEvent({
      event_id: event_trigger_ids.BROWSE_NEED_HELP_EMAIL,
      eventData,
      props: {
          ui: {
            button: "support@drivelah.sg",
            page: 'ContactUsScreen'
          }
      },
      userId: userId
    })
  }

  return (
    <StaticPage
      title="Contact Us | 24/7 car sharing & car rental services in Singapore | Drive lah"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'AboutPage',
        description: 'Get in touch with us and find out more about our car sharing and car rental services in Singapore.',
        name: 'Contact Us',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <img src={imageSource.contact.heroImage} className={css.heroImage} />
          <img src={imageSource.contact.heroImageMobile1x} srcSet={`${imageSource.contact.heroImageMobile1x} 1x, ${imageSource.contact.heroImageMobile2x} 2x`} className={css.heroImageMobile} />
          <div className={css.contentWrapper}>
            <div className={css.title}>
              <FormattedMessage id="ContactUsPage.heading" values={{ newline: (<span className={css.mobileNewline}><br /></span>) }} />
            </div>
            <div className={css.infoContactWrapper}>
              <div className={css.buttonsWrapper}>
                <a href="#" onClick={openChat} className={classNames(css.buttonLink, css.chatWithUs)}>
                  <FormattedMessage id="ContactUsPage.chatWithUs" />
                </a>
                <a href="tel:+6564328307" className={css.buttonLink} onClick={handlePhoneClick}>
                  <FormattedMessage id="ContactUsPage.phone" />
                </a>
                <a href="mailto:support@drivelah.sg" className={css.buttonLink}  onClick={handleEmailClick}>
                  <FormattedMessage id="ContactUsPage.email" />
                </a>
                <a href="tel:+6564328307" className={css.buttonLink} onClick={handlePhoneClick}>
                  <FormattedMessage id="ContactUsPage.roadSideAssistance" />
                </a>
              </div>
              <p className={css.description}>
                <span className={css.descriptionText}>
                  <FormattedMessage id="ContactUsPage.desc" values={{ newline: (<br />), bold: (<a href="https://help.drivelah.sg/faq" className={css.boldText}>FAQ</a>) }} />
                </span>
              </p>
            </div>
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

const mapStateToProps = state => ({
  currentUser: state.user.currentUser,
});

export default connect(mapStateToProps)(injectIntl(ContactUsPage));
