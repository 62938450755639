import React, { memo } from 'react';
import css from './HostPromoLandingPage.css';
import landTransport from '../../assets/landingpage/landTransport.jpeg';
import insurance from '../../assets/hostLanding/Tokio_Marine_-_White_Back@2x.png';
import arrowRight from '../../assets/hostLanding/next.png';

const SectionInsurance = memo(() => {
  return (
    <div className={css.insuranceBlock}>
      <div className={css.landingContainer}>
        <div className={css.landingRow}>
          <div className={css.column6}>
            <div className={css.blockTitle}>We have you covered!</div>
            <p>
              Your car is automatically covered with comprehensive insurance from Tokio Marine
              Insurance during the trip. Your car's original insurance and your NCD do not get
              impacted.
            </p>
            <a href="#" title="View our insurance policy">
              View our insurance policy{' '}
              <span>
                <img src={arrowRight} alt="" />
              </span>
            </a>
            <div className={css.sectionProvider}>
              <div>
                <div className={css.providerTitle}>Insurance provided by</div>
                <img src={insurance} className={css.insuranceImage} />
              </div>
            </div>
          </div>
          <div className={css.column6}>
            <div className={css.blockTitle}>Approved by the LTA</div>
            <p>Drivelah is fully approved by the Land Transport Authority.</p>
            <p>
              Further, we know it’s a priority to trust the people driving your car so only verified
              members, thoroughly vetted by the Drivelah team can book a car.
            </p>
            <div className={css.sectionProvider}>
              <div>
                <div className={css.providerTitle}>Approved by</div>
                <img src={landTransport} className={css.landTransport} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default SectionInsurance;
