import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.css';

const PrivacyPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last updated: 29th April 2022</p>
      <div style={{color: '#00a3ad'}}>
      <p className={css.mainTitle}>PRIVACY POLICY</p>

      <p>DRIVE LAH PTE. LTD.&nbsp;(&quot;we&rdquo; or &ldquo;us&rdquo;) is committed to protecting and respecting your privacy.&nbsp;</p>

      <ul>
	      <li className={css.line}>1. &nbsp; This policy (together with our terms of use and any other documents referred to on it) sets out the basis on which any personal data (as defined in the Personal Data Protection Act 2012 (&ldquo;PDPA&rdquo;)) (the &ldquo;Data&rdquo;) we collect from you, or that you upload, will be used, disclosed or otherwise processed by us. Please read the following carefully to understand our views and practices regarding your Data and how we will treat it.</li>
	      <li className={css.line}>2. &nbsp; By accepting our Terms of Use, you consent to the collection, use, disclosure and transfer of your Data in the manner and for the purposes set out below.</li>
	      <li className={css.line}>3. &nbsp;Unless otherwise provided, defined terms in this privacy policy shall have the same meaning as in our Terms of Use.</li>
      </ul>

      <p class={css.sectionTitle}>INFORMATION WE MAY COLLECT&nbsp;</p>

      <p className={css.line}>4. &nbsp; We may collect and process the following data from you:&nbsp;</p>

      <ul>
	      <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (a) &nbsp;information that you provide by filling in forms on our Platform, including information provided at the time of registering for an account on our Platform, subscribing to any services provided by us, posting material, reporting a problem with our Platform, or requesting further services;</li>
	      <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (b) &nbsp;documents or images that you upload onto our Platform;</li>
	      <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (c) &nbsp;details of transactions you carry out through our Platform;</li>
	      <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (d) &nbsp;details of your visits to our Platform and the resources that you access;</li>
	      <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (e) &nbsp;information collected whenever you use the Platform or leave the Platform running on any device;</li>
	      <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (f) &nbsp;if you contact us, a record of that correspondence; and</li>
	      <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (g) &nbsp;payment details or other financial details that you upload onto our Platform to facilitate your transactions.</li>
      </ul>

      <p class={css.sectionTitle}>IP ADDRESSES&nbsp;</p>

      <p className={css.line}>5. &nbsp; We may also collect and process information about your computer, including where available your IP address, operating system and browser type, for system administration and to report aggregate information to our advertisers. This is statistical data about our users&rsquo; browsing actions and patterns, and does not identify any individual.&nbsp;</p>

      <p class={css.sectionTitle}>COOKIES&nbsp;</p>

      <p className={css.line}>6. &nbsp; Our Platform uses cookies to distinguish you from other users of our Platform. This helps us to provide you with a good experience when you browse our Platform and also allows us to improve our Platform.&nbsp;</p>

      <p>WHERE WE STORE YOUR DATA&nbsp;</p>

      <p className={css.line}>7. &nbsp; The Data that we collect from you may be transferred to, and stored at, a destination outside Singapore. It may also be processed by staff operating outside Singapore who work for us or for any of our third party service providers. Such staff maybe engaged in, among other things, the fulfilment of your services ordered by you, the processing of your payment details and the provision of support services. By submitting the Data, you agree to this transfer, storing or &nbsp;
      processing. We will take all steps reasonably necessary to ensure that your Data is treated securely and in accordance with this privacy policy.
      </p>

      <ul>
	      <li className={css.line}>8. &nbsp; All information you provide to us is stored on our secure servers. Where we have given you (or where you have chosen) a password which enables you to access certain parts of our Platform, you are responsible for keeping this password confidential. We ask you not to share the password with anyone.</li>
	      <li className={css.line}>9. &nbsp; Unfortunately, the transmission of information via the internet is not completely secure. Although we will do our best to protect your Data, we cannot guarantee the security of your Data transmitted to our Platform; any transmission is at your own risk. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorised access.</li>
      </ul>

      <p class={css.sectionTitle}>USES MADE OF THE INFORMATION&nbsp;</p>

      <p className={css.line}>10. &nbsp; We may collect, use and process your Data for any or all of the following purposes:&nbsp;</p>

      <ul>
	      <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (a) &nbsp;to verify your identify and conduct any verification checks as we may, at our discretion, deem necessary prior to registering your account or providing your any services;</li>
	      <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (b) &nbsp;to obtain further background information about yourself and/or your vehicle, including vehicle log, status of your driving licence, status of your driver improvement points, status of outstanding traffic offences and payment of fines and other background information;</li>
	      <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (c) &nbsp;to ensure that content from our Platform is presented in the most effective manner for you and for your computer;</li>
	      <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (d) &nbsp;to provide you with information, products or services that you request from us, and to otherwise carry out our obligations arising from any contracts entered into between you and us;</li>
	      <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (e) &nbsp;to send you direct marketing and promotional communications and information on such offers and/or promotions, where you have consented to be contacted for such purposes;</li>
	      <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (f) &nbsp;to allow you to participate in interactive features of our service, when you choose to do so;</li>
	      <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (g) &nbsp;responding to, handling and processing queries, requests, applications, complaints and feedback from you;</li>
	      <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (h) &nbsp;to notify you about changes to our services;</li>
	      <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (i) &nbsp;processing payment or credit transactions;</li>
	      <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (j) &nbsp;complying with any applicable laws, regulations, codes of practice, guidelines or rules, or to assist in law enforcement and investigations conducted by any governmental and/or regulatory authority;</li>
	      <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (k) &nbsp;transmitting to any unaffiliated third parties including our third party service providers and agents, and relevant governmental and/or regulatory authorities, whether in Singapore or abroad, for the aforementioned purposes; and</li>
	      <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (l) &nbsp;any other incidental business purposes related to or in connection with the above.</li>
      </ul>

      <p class={css.sectionTitle}>DISCLOSURE OF YOUR INFORMATION&nbsp;</p>

      <ul>
	      <li className={css.line}>11. &nbsp; We may disclose your Data to other users to facilitate your use of our Platform and our provision of the Services.</li>
	      <li className={css.line}>12. &nbsp; We may disclose your Data to any member of our group, which means our subsidiaries, holding company, our ultimate holding company and its subsidiaries (as such terms are defined in the Companies Act (Cap 50)) (our &ldquo;Group&rdquo;) to facilitate our business operation and administration.</li>
	      <li className={css.line}>13. &nbsp; We may disclose your Data to third parties:</li>
	      <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (a) &nbsp;for the purposes of providing products or services that you request from us, fulfilling our obligations arising from any contracts entered into between you and us, processing payments in connection therewith or otherwise in connection with your use of our Platform;</li>
	      <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (b) &nbsp;in the event that we sell or buy any business or assets, in which case we may disclose your Data to the prospective seller or buyer of such business or assets;</li>
	      <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (c) &nbsp;if we or substantially all of our shares or assets are acquired by a third party, in which case personal data held by us about our customers will be one of the transferred assets;</li>
	      <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (d) &nbsp;if we are under a duty to disclose or share your personal data, in order to comply with any legal obligation (including any direction from a governmental or regulatory body or law enforcement) or in order to enforce or apply our Terms of Use; or</li>
	      <li className={css.line}>&nbsp;&nbsp;&nbsp;&nbsp; (e) &nbsp;in an emergency concerning your health and/or safety for the purposes of dealing with that emergency.</li>
      </ul>

      <p class={css.sectionTitle}>YOUR RIGHTS&nbsp;</p>

      <p className={css.line}>14. &nbsp; Our Platform may, from time to time, contain links to and from the websites of our partner networks, advertisers and affiliates. If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for these policies. Please check these policies before you submit any Data to these websites.&nbsp;</p>

      <p class={css.sectionTitle}>CONSENT&nbsp;</p>

      <ul>
	      <li className={css.line}>15. &nbsp; By providing your Data to us, you consent to the collection, use and disclosure of your Data by us for the purposes set out in this privacy policy (&ldquo;Purposes&rdquo;).</li>
	      <li className={css.line}>16. &nbsp; Please ensure that you speak to others before providing their Data to us, and that they have consent to you doing so. Kindly inform them that our collection, use and disclosure of their Data is solely for the Purposes. By providing such information to us, you represent and warrant that the person whose Data you have provided consents to the collection, use and disclosure of their Data for the Purposes.</li>
	      <li className={css.line}>17. &nbsp; You may withdraw your consent and request us to stop using and/or disclosing your Data for any or all of the Purposes by submitting your request to us in writing. Should you withdraw your consent to the collection, use or disclosure of your Data, it may impact our ability to proceed with your transactions, agreements or interactions with us. Prior to you exercising your choice to withdraw your consent, we will inform you of the consequences of the withdrawal of your consent. Please note that your withdrawal of consent will not prevent us from exercising our legal rights (including any remedies, or undertaking any steps as we may be entitled to at law.</li>
      </ul>

      <p class={css.sectionTitle}>ACCESS AND CORRECTION&nbsp;</p>

      <p className={css.line}>18. &nbsp; The PDPA gives you the right to access your Data. Your right of access can be exercised in accordance with the PDPA. Any access request may be subject to a fee of an administrative fee at our rates then in force to meet our costs in providing you with details of the information we hold about you.&nbsp;</p>

      <ul>
	      <li className={css.line}>19. &nbsp; In the event that you wish to correct and/or update your Data in our records, you may inform us in writing of the same. In certain cases, Data may also be corrected or updated via the Platform.</li>
	      <li className={css.line}>20. &nbsp; We will respond to requests regarding access and correction as soon as reasonably possible. Should we not be able to respond to your request within thirty (30) days after receiving your request, we will inform you in writing within thirty (30) days of the time by which we will be able to respond to your request. If we are unable to provide you with any personal data or to make a correction requested by you, we shall generally inform you of the reasons why we are unable to do so (except where we are not required to do so under the PDPA).</li>
      </ul>

      <p class={css.sectionTitle}>DATA DELETION&nbsp;</p>

      <p className={css.line}>21. &nbsp; If you have an account with us and want to get that removed, you can simply get in touch with our customer team and we will get your account closed. You will no longer be able to sign in to our website / apps or access any of your personal information.</p>
      <p className={css.line}>22. &nbsp; If you close your account, we may still retain some information associated with your account for analytical purpose and recordkeeping integrity, as well as to prevent fraud, collect any fees owed, enforce our terms and conditions, take actions we deem necessary to protect the integrity of our platform or our users, or take other actions otherwise permitted by the law.</p>

      <p>&nbsp;</p>

      <p class={css.sectionTitle}>RETENTION OF PRIVACY POLICY&nbsp;</p>

      <ul>
	      <li className={css.line}>23. &nbsp; We may retain your Data for as long as it is necessary to fulfil the purpose for which it was collected, or as required or permitted by applicable laws. We will cease to retain your Data, or remove the means by which the data can be associated with you, as soon as it is reasonable to assume that such retention no longer serves the purpose for which the Data was collected, and is no longer necessary for legal or business purposes.</li>
	      <li className={css.line}>24. &nbsp; Please note that there is still the possibility that your Data might be retained by other parties (e.g. other users of the Platform) through various means (e.g. photos, screen captures). We do not authorise retention of your Data for purposes unrelated to the use of the Platform and/or when such Data no longer serves the purpose for which it was collected or is no longer necessary for legal or business purposes (&ldquo;Unauthorised Uses&rdquo;). To the fullest extent permitted by applicable law, we shall not be liable for retention of your Data by other parties for Unauthorised Uses.</li>
      </ul>

      <p class={css.sectionTitle}>CHANGES TO OUR PRIVACY POLICY&nbsp;</p>

      <p className={css.line}>25. &nbsp; Any changes we may make to our privacy policy in the future will be posted on this page and, where appropriate, notified to you by us.&nbsp;</p>

      <p class={css.sectionTitle}>CONTACT&nbsp;</p>

      <p className={css.line}>26. &nbsp; Questions, comments and requests regarding this privacy policy are welcomed and should be addressed to our Data Protection Officer at&nbsp;[<em>compliance@drivelah.sg</em>].&nbsp;</p>

      <p>&nbsp;</p>
        
      </div>
    </div>
  )
}

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
