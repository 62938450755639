import React from 'react';
import css from './GrabBanner.css';
import { NamedLink } from '../../components';
import Slider from 'react-slick';
import classNames from 'classnames';

import moment from 'moment';
import { encodeLatLngBounds } from '../../util/urlHelpers';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import imageSource from '../../util/imageSource';

export const searchPageUrls = urlParams => {
  const address = 'Singapore';
  const DEFAULT_BOUNDS = {
    _sdkType: 'LatLngBounds',
    ne: {
      _sdkType: 'LatLng',
      lat: 1.4708809,
      lng: 104.04157989999999,
    },
    sw: {
      _sdkType: 'LatLng',
      lat: 1.216611,
      lng: 103.60650989999999,
    },
  };
  const calculateStartDate = moment()
    .add(1, 'day')
    .set({ hour: 9, minute: 0 });
  const calculateEndDate = moment()
    .add(2, 'day')
    .set({ hour: 9, minute: 0 });
  const params = {
    bounds: encodeLatLngBounds(DEFAULT_BOUNDS),
    dates: `${calculateStartDate.format('YYYY-MM-DD')},${calculateEndDate.format('YYYY-MM-DD')}`,
    hours: `${calculateStartDate.format('HH:mm')},${calculateEndDate.format('HH:mm')}`,
    address,
    ...urlParams,
  };
  const urlString = createResourceLocatorString('SearchPage', routeConfiguration(), null, params);
  return urlString;
};

const GrabBannerDesktop = ({ isMobileLayout, currentUser, inviteSectionAsComponent }) => {
  const sliderConfig = {
    infinite: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    initialSlide: 0,
    dots: true,
    autoplay: true,
    arrows: false,
    draggable: false,
    fade: true,
    autoplaySpeed: 5000,
  };

  const bannerContent = [
    {
      id: 1,
      sliderContent: inviteSectionAsComponent,
      slideImage: null,
      slideTitle: 'Same price, more car with Drive lah',
      slideDescription:
        'Save up to $65 per day on your favourite car. Try our interactive pricing comparison to compare Drive lah with other car rentals.',
      slideDescriptionMobile:
        'Save up to $65 per day on your favourite car. Try our interactive pricing comparison to compare Drive lah with other car rentals.',
      slideIcon: null,
      slideIconMobile: null,
      slideButtonText: 'Compare now',
      slideThemeClass: null,
      arrowIcon: null,
    },
    {
      id: 2,
      slideImage: imageSource.grabBanner1.donationBG,
      slideTitle: 'No surcharge for Malaysia',
      slideDescription:
        'Rent cars for driving to Malaysia with Drive lah at no extra costs! Simply pick a car you like and you are good to go.',
      slideDescriptionMobile:
        'Rent cars for driving to Malaysia with Drive lah at no extra costs! Simply pick a car you like and you are good to go.',
      slideIcon: imageSource.grabBanner1.driveToMalaysia,
      slideIconMobile: imageSource.grabBanner1.driveToMalaysia,
      slideButtonText: 'Browse cars for Malaysia',
      slideThemeClass: css.colorDark,
      arrowIcon: imageSource.grabBanner1.arrowBlue,
    },
  ];

  return (
    <div className={css.grabBannerContainer}>
      <Slider {...sliderConfig}>
        {bannerContent &&
          bannerContent.map(l => (
            <div key={l.id} className={css.grabBannerContentContent}>
              {l.id === 1 ? (
                l.sliderContent
              ) : (
                <div
                  className={classNames(css.grabBannerContent, l.slideThemeClass)}
                  style={{ backgroundImage: `url(${l.slideImage})` }}
                >
                  <div className={css.contentRow}>
                    <div className={css.contentLeft}>
                      <h5 className={css.contentTitle}>{l.slideTitle}</h5>
                      <p>{isMobileLayout ? l.slideDescriptionMobile : l.slideDescription}</p>
                      {l.id === 2 ? (
                        <NamedLink name="AntiCompetitionLandingPage" className={css.btWrap}>
                          {l.slideButtonText}
                          <span className={css.arrowIconOuter}>
                            <img src={l.arrowIcon} alt="Arrow Right Blue" />
                          </span>
                        </NamedLink>
                      ) : (
                        <NamedLink
                          name="SearchPage"
                          to={{
                            search: searchPageUrls({
                              pub_canDriveToMalaysia: true,
                            }),
                          }}
                          className={css.btWrap}
                        >
                          {l.slideButtonText}
                          <span className={css.arrowIconOuter}>
                            <img src={l.arrowIcon} alt="" />
                          </span>
                        </NamedLink>
                      )}
                    </div>
                    <div className={css.contentRight}>
                      <span
                        className={classNames(css.grabIcon, l.id === 1 ? css.adjustHeight : '')}
                      >
                        {isMobileLayout ? (
                          <img src={l.slideIconMobile} alt="Malaysia flag" />
                        ) : (
                          <img src={l.slideIcon} alt="Malaysia flag" />
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
      </Slider>
    </div>
  );
};

export default GrabBannerDesktop;
