import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { IconSpinner, ListingLink } from '../../components';
import { EditListingNextLevelForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import { types as sdkTypes } from '../../util/sdkLoader';
import { useLocation } from 'react-router-dom';

import css from './EditListingNextLevelPanel.css';
import { parse } from '../../util/urlHelpers';
import { createRawPropertiesForGTM } from '../../util/gtm/gtmCreateProperties';
import { createConversionEvents } from '../../util/conversions/conversionsHelpers';
import { EVENT_CONVERSION_PUBLISHED_LISTING } from '../../util/conversions/conversionsConstants';
import Button from '../Button/Button';
import NamedLink from '../NamedLink/NamedLink';

const { Money } = sdkTypes;

const EditListingNextLevelPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChangeInstantBooking,
    onChangeDeliveryBooking,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    currentUser,
    submitButtonId,
    showPayoutDetails
  } = props;
  const location = useLocation();
  const { search } = location;
  const { shouldSubmit } = parse(search);
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);

  const { publicData } = currentListing.attributes;

  const [sentPublish, setSentPublish] = useState(false);

  const isVerified =
    !!currentUser &&
    !!currentUser.id &&
    currentUser.identityStatus &&
    currentUser.identityStatus.hostVerified;
  const underVerified =
    !!currentUser &&
    !!currentUser.id &&
    currentUser.identityStatus &&
    currentUser.identityStatus.hostUnderVerify;
  const stripeConnected =
    !!currentUser && !!currentUser.id && currentUser.attributes.stripeConnected;

  const { instantBooking = true, delivery = false } = publicData;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;

  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingNextLevelPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingNextLevelPanel.createListingTitle" />
  );

  const form = (
    <EditListingNextLevelForm
      shouldSubmit={shouldSubmit}
      className={css.form}
      initialValues={{ instantBooking, delivery }}
      onSubmit={values => {
        const { instantBooking, delivery = false } = values;
        const instantBookingSorting = instantBooking ? 1 : 0;
        const updateValues = {
          publicData: {
            instantBooking,
            instantBookingSorting,
            isNewCarByTime: true,
            delivery,
          },
        };
        if (isPublished) {
          onSubmit(updateValues);
        } else {

          setSentPublish(true);
          setTimeout(() => {
            onSubmit(updateValues);
          }, 3500);
          const properties = createRawPropertiesForGTM({
            props,
          });
          createConversionEvents(properties, EVENT_CONVERSION_PUBLISHED_LISTING, 'click');
        }
      }}
      onChangeInstantBooking={onChangeInstantBooking}
      onChangeDeliveryBooking={onChangeDeliveryBooking}
      saveActionMsg={submitButtonText}
      disabled={disabled}
      ready={ready}
      updated={panelUpdated}
      updateInProgress={updateInProgress}
      fetchErrors={errors}
      submitButtonId={submitButtonId}
    />
  );

  const content = sentPublish ? showPayoutDetails ? (
    <div className={classNames(css.title, css.thanksContainer)}>
      <FormattedMessage id="EditListingAvailabilityPanel.noStripeVerfiedUser" values={{ newline: <br /> }} />
        <div>
          <NamedLink name="StripePayoutPage" className={css.logoLink}>
            <Button rootClassName={css.button} >
              <FormattedMessage id="LimitedAccessBanner.goToPayoutSettings" />
            </Button>
          </NamedLink>
        </div>
    </div>
  ) :  (
    <div className={classNames(css.title, css.thanksContainer)}>
      <FormattedMessage id="EditListingAvailabilityPanel.thanksText" values={{ newline: <br /> }} />
      {!stripeConnected ? (
        <div>
          {/* <FormattedMessage id="EditListingVerificationForm.waitingForStripeChecking" /> */}
           <div className={css.loader}><IconSpinner /></div>
        </div>
      ) : null}
    </div>
  ): (
    form
  );

  return (
    <div className={classes}>
      {/* <h1 className={css.title}>{panelTitle}</h1> */}
      <div className={css.titleSection}>
        <h1 className={css.title}>Easy Access</h1>
        <p>
          Enable guests to gain easy access to your car by enabling the popular Drive lah features.
        </p>
      </div>
      {content}
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingNextLevelPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingNextLevelPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
  submitButtonId: string,
  onChangeInstantBooking: func,
};

export default EditListingNextLevelPanel;
