/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { memo, useState } from 'react';
import css from './SeoLandingPage.css';
import isEqual from 'lodash/isEqual';
import SeoLandingForm from './SeoLandingForm';
import InsuranceLandingModal from '../../components/InsuranceLandingModal/InsuranceLandingModal';
import imageSource from '../../util/imageSource';

const SeoLandingBanner = memo(
  ({
    isMobileLayout,
    brandCategory,
    searchFilters,
    pageCategory,
    onManageDisableScrolling,
    selectedValues,
  }) => {
    const [isInsuranceModalOpen, setInsuranceModalOpen] = useState(false);
    let brandCat;
    switch (brandCategory) {

      //Car Brands
      case 'BMW':
        brandCat = "BMW cars for rent in Singapore";
        break;

      case 'Honda':
        brandCat = "Honda cars for rent in Singapore";
        break;

      case 'Hyundai':
        brandCat = "Hyundai cars for rent in Singapore";
        break;

      case 'Mercedes Benz':
        brandCat = "Mercedes Benz cars for rent in Singapore";
        break;

      case 'Mini':
        brandCat = "Mini cars for rent in Singapore";
        break;

      case 'Mitsubishi':
        brandCat = "Mitsubishi cars for rent in Singapore";
        break;

      case 'Porsche':
        brandCat = "Porsche Cars Rental in Singapore";
        break;

      case 'Toyota':
        brandCat = "Toyota Rental Cars in Singapore ";
        break;

      case 'Volvo':
        brandCat = "Volvo Cars Rental in Singapore";
        break;

      case 'Mazda':
        brandCat = "Mazda Rental Cars in Singapore ";
        break;

      case 'Audi':
        brandCat = "Audi cars for rent in Singapore";
        break;

      //Segments

      case 'Compact':
        brandCat = "Compact Cars for Rent";
        break;

      case 'Standard Sedan':
        brandCat = "Standard Sedan Cars for Rent";
        break;

      case 'Standard SUV':
        brandCat = "Standard SUV Cars for Rent";
        break;

      case 'Luxury Sedans':
        brandCat = "Luxury Sedan Cars for Rent";
        break;

      case 'Luxury SUV':
        brandCat = "Luxury SUV Cars for Rent";
        break;


    }
    return (
      <div className={css.landingPageBanner}>
        <div className={css.landingContainer}>
          <div className={css.landingRow}>
            <div className={css.bannerSectionLeft}>
              <span className={css.landingPageBannerTitle}>
                {brandCat}
              </span>
              {isMobileLayout ? (
                <div className={css.landingPageBannerImage}>
                  <img
                    className={css.featureImage}
                    src={(selectedValues && selectedValues.image) || imageSource.seoLandingPage.landingBannerImage}
                  />
                </div>
              ) : (
                ''
              )}
              <p>
                With Drive lah, you can rent from a huge variety of nearby {brandCategory}s at great
                value. Fully insured+.
              </p>
              <SeoLandingForm
                pageCategory={pageCategory}
                searchFilters={searchFilters}
                brandCategory={brandCategory}
                isMobileLayout={isMobileLayout}
                openInsuranceModal={() => setInsuranceModalOpen(true)}
                onManageDisableScrolling={onManageDisableScrolling}
              />
            </div>
            {!isMobileLayout ? (
              <div className={css.bannerSectionRight}>
                <div className={css.landingPageBannerImage}>
                  <img
                    className={css.featureImage}
                    src={(selectedValues && selectedValues.image) || imageSource.seoLandingPage.landingBannerImage}
                  />
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        <InsuranceLandingModal
          onManageDisableScrolling={onManageDisableScrolling}
          isModalOpen={isInsuranceModalOpen}
          setModalVisibility={setInsuranceModalOpen}
        />
      </div>
    );
  },
  (prev, next) => isEqual(prev, next)
);

export default SeoLandingBanner;
