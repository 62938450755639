import React from 'react';
import config from '../../config';
import { StaticPage, TopbarContainer } from '../../containers';
import { FormattedMessage } from '../../util/reactIntl';
import {
  ExternalLink,
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  NamedLink,
} from '../../components';
import { connect } from 'react-redux';
import classNames from 'classnames';

import css from './AboutPage.css';
import image from './people_hug.jpg';
import logoImage from '../../assets/logos/logo-footer@200.png';
import infinityImage from './infinity.png';
import { loginOrSignupClick } from '../TopbarContainer/Topbar.duck';
import imageSource from '../../util/imageSource';

const AboutPage = props => {
  const {
    isAuthenticated,
    onLoginOrSignupClick,
  } = props;
  const { siteInstagramPage, siteFacebookPage } = config;

  // prettier-ignore
  return (
    <StaticPage
      title="Safe & secured top car rentals in Singapore | Drive lah "
      schema={{
        '@context': 'http://schema.org',
        '@type': 'AboutPage',
        description: "Drive lah is Singapore's top car sharing and car rental service. HAPPY DRIVERS | HAPPIER CUSTOMERS | GREAT SERVICE | SAVING TIME AND MONEY",
        name: 'About page',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          {/* <h1 className={css.pageTitle}>Experience the unique Finnish home sauna.</h1> */}
          <div className={css.coverImage} src={image} alt="My first ice cream."></div>

          <div className={css.contentWrapper}>
            <div className={css.contentSide}>
              <img className={css.logo} src={logoImage} alt="My first ice cream." />
              <p className={css.description}>“Sharing is caring!<br />We are passionate
                about our planet and want to take care of it in a more efficient way! ”</p>
            </div>

            <div className={css.contentMain}>
              <h1 className={css.aboutUs}>
                About us
              </h1>
              <p className={classNames(css.defaultTextColor, css.aboutUsText)}>
                We are a group of people that believe in a better future & efficient use of resources.
                The world is progressing very fast and we need to care about the environment.
                We decided that we need to do something about it.
                Cars and mobility is an area with a lot of potential.
                This is why we came with this unique platform - Drive lah.

              </p>

              <p className={classNames(css.defaultTextColor, css.aboutUsText)}>
                We want to bring people together, build a community that wants to create a better environment, and use assets more efficiently.
              </p>

              <img className={css.infinityImageMobile} src={infinityImage} alt="Secure monthly car rental services with Drive lah"></img>


              <div className={css.contentSideMobile}>
                <img className={css.logo} src={logoImage} alt="My first ice cream." />
                <p className={css.description}>“Sharing is caring!<br />We are passionate
                  about our planet and want to take care of it in a more efficient way! ”</p>
              </div>

              <h2 className={css.subtitle}>
                We are founded in Singapore.
              </h2>

              <p className={css.defaultTextColor}>
                In Singapore we have 11,520,548 of unused car hours per day. That is a waste. We at Drive lah strive to reduce that number by enabling people to share their car when they don’t need it.
              </p>
              <p className={css.defaultTextColor}>
                We envision a society where sharing vehicles becomes the norm; everyone can access a variety of cars at the click of a button and smart technology makes the experience hassle free.
              </p>
              <p className={css.defaultTextColor}>
                We want to help build that future and do good for the city we love.
              </p>

              <img className={css.infinityImage} src={infinityImage}></img>
              <h2 className={classNames(css.subtitle, css.getInTouch)}>Drive lah is now available as Drive mate in Australia</h2>
              <div className={css.driveMateImage}>
                <img src={imageSource.aboutUs.driveMateLogo} alt="drive mate" />
              </div>
              <p className={css.contactText}>
                Yes that’s right. You can now experience peer to peer car sharing in Australia as well with Drive mate - <ExternalLink href="https://drive-mate.com.au/">www.drive-mate.com.au</ExternalLink>. You can also checkout our <ExternalLink href="https://www.facebook.com/drivemate20">facebook</ExternalLink> and <ExternalLink href="https://www.instagram.com/drive.mate/">instagram</ExternalLink> pages.
              </p>

              <h2 className={classNames(css.subtitle, css.getInTouch)}>Get in touch</h2>
              <p className={css.contactText}>
                We are happy to help you in anything you have in your mind.<br />Best way to reach us is by emailing us at {' '}
                <ExternalLink href="mailto:support@drivelah.sg">support@drivelah.sg</ExternalLink> or {' '}
                call us at <ExternalLink href="tel:+6564328307">+ 65 6432 8307</ExternalLink>.
              </p>
              <p className={css.contactText}>
                You can also checkout our{' '}
                <ExternalLink href={siteFacebookPage}>Facebook</ExternalLink> and{' '}
                <ExternalLink href={siteInstagramPage}>Instagram</ExternalLink> pages.
              </p>
            </div>
          </div>

          <div className={css.background}>
            <div className={css.sloganWrapper}>
              <div className={css.sloganMain}>
                <h1>We believe the future is shared! Go Drive lah!</h1>
                <p>
                  We want to work towards a future with one million fewer cars on the road, and cities with more green space than parking lots. To achieve this we believe in the power of sharing.
                </p>
                <p>
                  Drive lah goes beyond public transport. Drive lah is there for you when you need more than a one way trip. It helps you to do your shopping around town for the day, to haul your furniture or to skip town for a few days.
                </p>
              </div>
            </div>
          </div>

          <div className={css.bottomWrapper}>
            <div className={css.contentContainer}>
              <h1 className={css.slogan}>Ready to go<br />Drive lah?</h1>
              <div className={css.buttons}>
                {!isAuthenticated &&
                  <NamedLink
                    name="SignupPage"
                    className={classNames(css.secondaryButton, css.joinButton)}
                    onClick={() => onLoginOrSignupClick('signup')}
                  >
                    <FormattedMessage id="AboutPage.join" />
                  </NamedLink>
                }
                {isAuthenticated &&
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search: "bounds=1.54840194%2C103.93560548%2C1.20243284%2C103.68841309"
                    }}
                    className={css.secondaryButton}
                  >
                    <FormattedMessage id="AboutPage.findACar" />
                  </NamedLink>
                }
                {isAuthenticated &&
                  <NamedLink
                    name="NewListingPage"
                    className={css.defaultButton}
                  >
                    <FormattedMessage id="AboutPage.listYourCar" />
                  </NamedLink>
                }
              </div>
            </div>
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  return {
    isAuthenticated,
  };
};

const mapDispatchToProps = dispatch => ({
  onLoginOrSignupClick: (page) => dispatch(loginOrSignupClick({page}))
});

export default connect(mapStateToProps, mapDispatchToProps)(AboutPage);
