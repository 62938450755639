import React from 'react';

import css from '../../containers/TripDetailsPage/TripDetailsPage.css';
import { FormattedMessage } from '../../util/reactIntl';
import BookingBreakdownNew from '../BookingBreakdown/BookingBreakdownNew';
import config from '../../config';
import { ensureBooking, restoreTransaction } from '../../util/data';
import moment from 'moment-timezone';

const UpdateBookingTxs = (props) => {
  const {
    lastUpdateBookingTx,
    updateBookingTxs,
    timeZone,
    currentUserRole,
    isUsingMastercard,
  } = props;

  if (!updateBookingTxs.length) {
    return null;
  }

  return (
    <>
      {updateBookingTxs.map(tx => {
        const txBookingModificationTransaction = ensureBooking(tx.booking);
        const dateCreatedModificationTransaction = moment(tx.createdAt).format('Do MMM, YYYY');

        return (
          <div
            id={tx.id.uuid}
            className={css.detailsContainerMain}
          >
            <h3 className={css.containerTitle}>
              <FormattedMessage
                id={'TripDetailsPage.tripModificationTitle'}
                values={{ date: dateCreatedModificationTransaction }}
              />
            </h3>
            <BookingBreakdownNew
              lastUpdateBookingTx={lastUpdateBookingTx}
              timeZone={timeZone}
              className={css.bookingBreakdown}
              userRole={currentUserRole}
              unitType={config.bookingUnitType}
              transaction={restoreTransaction(tx, isUsingMastercard)}
              shouldShowMastercardPromoLineItem={isUsingMastercard}
              booking={txBookingModificationTransaction}
              isModificationTransaction={true}
              isTripDetailsPage={true}
              fuelPrice={null}
            />
          </div>
        );
      })}
    </>
  );
};

export default UpdateBookingTxs;
