// ================ Action types ================ //

import { triggerAnalyticsEvent } from "../../util/amplitudeMapEvents";
import { event_trigger_ids } from "../../util/analyticsConstants";
import { LOGIN_SUBMIT_BUTTON_ID, SIGNUP_SUBMIT_BUTTON_ID } from "../../util/gtm/gtmConstants";
import { getEventsByBucket } from "../../util/gtm/gtmCreateProperties";

const SET_REDIRECT_URL = 'app/Topbar/SET_REDIRECT_URL';
const CLEAR_REDIRECT_URL = 'app/Topbar/CLEAR_REDIRECT_URL';

// ================ Reducer ================ //

const initialState = {
  redirectUrl: null,
};

export default function topbarReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_REDIRECT_URL:
      return { ...initialState, redirectUrl: payload };
    case CLEAR_REDIRECT_URL:
      return { ...initialState, redirectUrl: null };
    default:
      return state;
  }
}

// ================ Selectors ================ //

export const selectLoginOrSugnupRedirectUrl = state => state.Topbar.redirectUrl;

// ================ Action creators ================ //
const setRedirectUrl = url => ({
  type: SET_REDIRECT_URL,
  payload: url,
});

// ================ Thunks ================ //

export const loginOrSignupClick = ({ page }) => async (dispatch, getState, sdk) => {
  // if user came from login or signup page.
  const redirectUrl = selectLoginOrSugnupRedirectUrl(getState());
  if (redirectUrl) {
    return;
  }

  const pathname = window.location.pathname;
  const search = window.location.search;
  const url = `${pathname}${search}`;
  // console.log("URL of Login or Signup", url);

  if(page) {
    try {
      if (page === 'login') {
        const response = await getEventsByBucket('browsing');
        triggerAnalyticsEvent({ 
          event_id: event_trigger_ids.LOGIN_OPEN_LOGIN_FORM, 
          eventData: response.data, 
          props: {
            ui: {
              button: LOGIN_SUBMIT_BUTTON_ID,
              page: 'OnboardingScreen'
            },
            signup: {
              mode: 'email'
            }
          }
        });
      } else {
        // console.log("Signup link clicked signup_open_email_form");
        const response = await getEventsByBucket('signup');
        // console.log('Events fetched successfully:', response.data);
        triggerAnalyticsEvent({ 
          event_id: event_trigger_ids.SIGN_UP_EMAIL_FORM_OPEN, 
          eventData: response.data, 
          props: {
            ui: {
              button: SIGNUP_SUBMIT_BUTTON_ID,
              page: 'OnboardingScreen'
            },
            signup: {
              mode: 'email'
            }
          }
        });
      }
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  }

  dispatch(setRedirectUrl(url));
};

export const clearRedirectUrl = () => (dispatch, getState, sdk) => {
  dispatch({ type: CLEAR_REDIRECT_URL });
};
