import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withViewport } from '../../util/contextHelpers';
import { injectIntl, intlShape } from '../../util/reactIntl';
import config from '../../config';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
  TrustBoxSlider,
} from '../../components';
import { TopbarContainer } from '../../containers';
import { initiateListACarEventFromTab, pushGTMBrowseEvent } from '../../util/gtm/gtmHelpers';
import {
  EVENT_BROWSE_HOMEPAGE,
  EVENT_BROWSE_USEDRENTALCAL,
  EVENT_LISTACAR_CLICKED_LIST_A_CAR,
  LISTACAR_CREATE_NEW_BUTTON_ID,
} from '../../util/gtm/gtmConstants';

import css from './AntiCompetitionLandingPage.css';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import { parse } from '../../util/urlHelpers';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import ContentSliderBanner from './ContentSliderBanner';
import CompetitionCompareSection from './CompetitionCompareSection';
import CompetitionStatisticsSection from './CompetitionStatisticsSection';
import CompetitionHomeDeliverySection from './CompetitionHomeDeliverySection';
import DownloadAppSection from './DownloadAppSection';
import { checkSignUpCode } from '../../util/referralProgram';
import { connect } from 'react-redux';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import imageSource from '../../util/imageSource';
import { getEventsByBucket } from '../../util/gtm/gtmCreateProperties';
import { triggerAnalyticsEvent } from '../../util/amplitudeMapEvents';
import { event_trigger_ids } from '../../util/analyticsConstants';

const MAX_MOBILE_SCREEN_WIDTH = 767;

export const AntiCompetitionLandingPageComponent = props => {
  const { intl, scrollingDisabled, viewport, onManageDisableScrolling } = props;

  const [signUpCodeAmount, setSignUpCodeAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const _handleSignup = async e => {
    e.preventDefault();
    const { search } = location;
    const { code } = parse(search);
    // initiateListACarEventFromTab({
    //   props,
    //   eventButtonParam: [EVENT_LISTACAR_CLICKED_LIST_A_CAR, LISTACAR_CREATE_NEW_BUTTON_ID],
    // });

    try {
      // const response = await getEventsByBucket('listacar');
      // triggerAnalyticsEvent({
      //   event_id: event_trigger_ids.LISTACAR_CLICKED_LIST_A_CAR, 
      //   eventData: response.data, 
      //   props: {
      //     ui: {
      //       button: "Create Listing"
      //     }
      //   },
      //   userId: currentUser && currentUser.id && currentUser.id.uuid
      // });
      // triggerAnalyticsEvent({
      //   event_id: 'listacar_clicked_list_a_car', eventData: response.data, props: {
      //     props,
      //   },
      // });
    } catch (error) {
      console.log('Error fetching events:', error);
    }

    const url = createResourceLocatorString('SignupPage', routeConfiguration(), {}, {});
    history.push(
      code
        ? {
            pathname: url,
            search: `?code=${code}`,
          }
        : {
            pathname: url,
          }
    );
  };
  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'Better cars at even better prices | Drive lah' }, { siteTitle });
  const schemaDescription = intl.formatMessage({
    id:
      'Rent better cars at a much more cheaper price as compared to other rental platforms. Learn how Drive lah compares to other rental platforms and wins at in every category',
  });
  const schemaImage = `${config.canonicalRootURL}${imageSource.antiCompetition.instagramImage}`;

  const onPushGTMEvent = (event, buttonId) => {
    pushGTMBrowseEvent({
      props,
      event,
      buttonId,
    });
  };

  useEffect(() => {
    // onPushGTMEvent(EVENT_BROWSE_HOMEPAGE);
    const { code } = parse(location.search);
    if (code) {
      setLoading(true);
      checkSignUpCode(code).then(res => {
        if (res.valid) {
          setSignUpCodeAmount(res.credits);
        }
        setLoading(false);
      });
    }
  }, []);

  let isMobileLayout;
  try {
    if (viewport) {
      isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
    }
  } catch (error) {

  }

  const onUseRentalCalculator = () => {
    pushGTMBrowseEvent({
      props,
      event: EVENT_BROWSE_USEDRENTALCAL,
    });
  };

  return (
    <Page
      className={(css.root, css.antiCompetitionRoot)}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: imageSource.antiCompetition.instagramImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${imageSource.antiCompetition.instagramImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer transparent={true}  notApplySearch={true} currentPage="Anti competition" />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.contentWrapper}>
          <ContentSliderBanner
            loading={loading}
            signUpCodeAmount={signUpCodeAmount}
            handleSignupRedirect={e => _handleSignup(e)}
          />
          <CompetitionCompareSection
            loading={loading}
            signUpCodeAmount={signUpCodeAmount}
            handleSignupRedirect={e => _handleSignup(e)}
            onManageDisableScrolling={onManageDisableScrolling}
            isMobileLayout={isMobileLayout}
          />
          <CompetitionStatisticsSection />
          <CompetitionHomeDeliverySection />
          <TrustBoxSlider />
          <DownloadAppSection />
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool, object, number, shape } = PropTypes;

AntiCompetitionLandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const AntiCompetitionLandingPage = compose(
  withViewport,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(AntiCompetitionLandingPageComponent);

export default AntiCompetitionLandingPage;
