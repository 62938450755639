import React, { useEffect, useRef, useState } from 'react';
import css from './SeoLandingPage.css';
import { useHistory, useLocation } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { encodeLatLngBounds } from '../../util/urlHelpers';
import { getSearchedPlaces, saveSearchedPlacesMobile } from '../../util/emailNotify';
import LocationAutocompleteInput
  from '../../components/LocationAutocompleteInput/LocationAutocompleteInput';
import GeocoderGoogleMaps, {
  CURRENT_LOCATION_ID,
} from '../../components/LocationAutocompleteInput/GeocoderGoogleMaps';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { withViewport } from '../../util/contextHelpers';
import config from '../../config';
import { SEO_LANDING_CONSTANTS } from './SeoLocationLandingPage';
import SearchIconNew from '../../components/Topbar/SearchIconNew';
import insuranceLogo from '../../assets/insurance-logo.svg';
import { TrustBox } from '../../components';

const DEFAULT_BOUNDS = {
  _sdkType: 'LatLngBounds',
  ne: {
    _sdkType: 'LatLng',
    lat: 1.4708809,
    lng: 104.04157989999999,
  },
  sw: {
    _sdkType: 'LatLng',
    lat: 1.216611,
    lng: 103.60650989999999,
  },
};

export const defaultPredictions = (config.maps.search.suggestCurrentLocation
  ? [{ id: CURRENT_LOCATION_ID, predictionPlace: {} }]
  : []
).concat(config.maps.search.defaults);

export const defaultPredictionsExcludingCurrentLocation = config.maps.search.defaults;

export const defaultPredictionsWithoutCurrentLocation = [
  { id: CURRENT_LOCATION_ID, predictionPlace: {} },
];

const SeoLandingForm = ({
  viewport,
  isMobileLayout,
  brandCategory = null,
  searchFilters,
  pageCategory = null,
  openInsuranceModal,
}) => {
  const history = useHistory();
  const location = useLocation();
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [predictions, setPredictions] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isUserSearching, setIsUserSearching] = useState(false);
  const [isFocused, setIsFocused] = useState(null);
  const geocoder = useRef(new GeocoderGoogleMaps()).current;
  const userSearchHistory = getSearchedPlaces();
  let searchDropdown = useRef(null);
  useEffect(() => {
    document.addEventListener('click', handleOutsideClick, false);
  }, []);

  const handleSelectPrediction = prediction => {
    const routes = routeConfiguration();
    if (!isMobileLayout) {
      geocoder
        .getPlaceDetails(prediction)
        .then(location => {
          const url = createResourceLocatorString('SearchPage', routes, null, {
            bounds: encodeLatLngBounds(
              location && location.bounds ? location.bounds : DEFAULT_BOUNDS
            ),
            address: location && location.address,
            pub_category:
              pageCategory === SEO_LANDING_CONSTANTS.SEO_PAGE_RENTAL_SEGMENT ? searchFilters : null,
            pub_brandName:
              pageCategory === SEO_LANDING_CONSTANTS.SEO_PAGE_RENTAL_TOP_BRANDS
                ? searchFilters
                : null,
          });

          if (prediction && prediction.predictionPlace) {
            saveSearchedPlacesMobile(prediction.predictionPlace);
          } else {
            saveSearchedPlacesMobile(prediction);
          }
          history.push(url);
        })
        .catch(() => {
          const url = createResourceLocatorString('NotFoundPage', routes);
          history.push(url);
        });
    }

    // const { address } = value;
    // const routes = routeConfiguration();
    // let locationBounds = value.bounds;
    // if (address) {
    //   saveSearchedPlacesMobile(value);
    // }
    // if (value && value.predictionPlace) {
    //   locationBounds = value.predictionPlace.bounds;
    // }

    // history.push(
    //   createResourceLocatorString('SearchPage', routes, null, {
    //     bounds: encodeLatLngBounds(value ? locationBounds : DEFAULT_BOUNDS),
    //     locationName: address,
    //     address: value.predictionPlace ? value.predictionPlace.address : address,

    //   })
    // );
  };

  /* eslint-disable jsx-a11y/no-static-element-interactions */
  const item = (prediction, index) => {
    const isHighlighted = index === highlightedIndex;
    const predictionId = geocoder.getPredictionId(prediction);
    return (
      <li
        className={classNames(isHighlighted ? css.highlighted : null, css.prediction)}
        key={predictionId}
        onClick={() => handleSelectPrediction(prediction)}
      >
        {predictionId === CURRENT_LOCATION_ID ? (
          <span className={css.currentLocation}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
              <defs></defs>
              <g transform="translate(-0.005 0)">
                <g transform="translate(6.005 0)">
                  <path
                    class="a"
                    fill="#026786"
                    d="M201.9.114a.334.334,0,0,0-.471,0L192.1,9.448a.333.333,0,0,0,.471.471L201.9.586A.334.334,0,0,0,201.9.114Z"
                    transform="translate(-192 -0.017)"
                  />
                </g>
                <g transform="translate(0.005 0)">
                  <g transform="translate(0 0)">
                    <path
                      class="a"
                      d="M15.907.1a.332.332,0,0,0-.379-.065L.195,7.366a.333.333,0,0,0,.039.617L6.075,9.93l1.947,5.842a.333.333,0,0,0,.3.227h.021a.333.333,0,0,0,.3-.189L15.972.477A.334.334,0,0,0,15.907.1ZM8.393,14.777,6.655,9.56a.333.333,0,0,0-.211-.211L1.227,7.611l13.736-6.57Z"
                      transform="translate(-0.005 0)"
                    />
                  </g>
                </g>
              </g>
            </svg>
            <FormattedMessage id="LocationAutocompleteInput.currentLocation" />
          </span>
        ) : (
          geocoder.getPredictionAddress(prediction)
        )}
      </li>
    );
  };
  /* eslint-enable jsx-a11y/no-static-element-interactions */

  const _handleSearchType = p => {
    if (p && p.value !== '') {
      setIsUserSearching(true);
    } else {
      setIsUserSearching(false);
    }
  };

  const handleOutsideClick = e => {
    if (searchDropdown && searchDropdown.current && !searchDropdown.current.contains(e.target)) {
      setIsFocused(false);
    } else {
      setIsFocused(true);
      document.removeEventListener('click', handleOutsideClick, false);
    }
  };

  return (
    <div className={css.SeoLandingForm}>
      <div
        className={css.searchFormWrapper}
        ref={searchDropdown}
        onClick={() => setDropdownOpen(!dropdownOpen)}
      >
        <Form
          onSubmit={() => {}}
          render={() => {
            return (
              <Field name="location">
                {props => (
                  <LocationAutocompleteInput
                    {...props}
                    autoFocus={true}
                    className={css.fieldLocationBLanding}
                    iconClassName={css.hideSearchIconBLanding}
                    inputClassName={css.inputClassNameBLanding}
                    predictionsClassName={css.searchPredictions}
                    onPredictionChange={setPredictions}
                    placeholder={
                      brandCategory
                        ? isMobileLayout
                          ? `Search for ${brandCategory}`
                          : `Search for ${brandCategory} in your area`
                        : 'Search for your rental car'
                    }
                  >
                    <div className={css.searchIconWrapper}>
                      <SearchIconNew rootClassName={css.searchIcon} />
                    </div>
                  </LocationAutocompleteInput>
                )}
              </Field>
            );
          }}
        />
        {/* <SearchIconBoxed className={css.searchIcon} /> */}
        {dropdownOpen && (
          <div
            className={classNames(
              css.predictionsDropDown,
              isFocused && !isMobileLayout && css.isVisible
            )}
          >
            <ul className={css.predictionsCurrentLocations}>
              {defaultPredictionsWithoutCurrentLocation &&
              predictions.length > 0 &&
              predictions[0]['description']
                ? predictions
                    .filter(
                      item =>
                        item['description'].includes('Singapore') ||
                        item['description'].includes('singapore')
                    )
                    .map(item)
                : defaultPredictionsWithoutCurrentLocation.map((value, index) =>
                    item(value, index)
                  )}
            </ul>
            {!isUserSearching && userSearchHistory && userSearchHistory.length ? (
              <div className={css.searchLabels}>
                <FormattedMessage id="LocationAutocompleteInput.recentSearches" />
              </div>
            ) : null}
            {!isUserSearching && userSearchHistory && userSearchHistory.length ? (
              <ul className={css.predictions}>
                {userSearchHistory &&
                userSearchHistory.length > 0 &&
                userSearchHistory[0]['description']
                  ? userSearchHistory.map((value, index) => item(value, index))
                  : userSearchHistory.map((value, index) => item(value, index))}
              </ul>
            ) : null}
            {predictions && predictions.length > 0 && predictions[0]['description'] ? null : (
              <div className={css.searchLabels}>
                <FormattedMessage id="LocationAutocompleteInput.searchSuggestion" />
              </div>
            )}

            {userSearchHistory && userSearchHistory.length ? (
              <ul className={css.predictions}>
                {predictions && predictions.length > 0 && predictions[0]['description']
                  ? null
                  : defaultPredictionsExcludingCurrentLocation.map((value, index) =>
                      item(value, index)
                    )}
              </ul>
            ) : (
              <ul className={css.predictions}>
                {predictions && predictions.length > 0 && predictions[0]['description']
                  ? null
                  : defaultPredictionsExcludingCurrentLocation.map((value, index) =>
                      item(value, index)
                    )}
              </ul>
            )}
            {/* <GeocoderAttribution className={css.attributionClassName} /> */}
          </div>
        )}
      </div>
      <div className={css.insuranceListingWrapper}>
        <div className={css.comprehensiveInsuranceBlock} onClick={openInsuranceModal}>
          <div className={css.trustPilotLogo}>
            <TrustBox />
          </div>
          <div className={css.comprehensiveInsuranceBlockWrapper}>
            <div className={css.cILogo}>
              <img src={insuranceLogo} alt="Drive lah Protection" />
            </div>
            <div className={css.cIContent}>
              <span>
                Comprehensively insured with Drive lah protection<sup>TM</sup>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withViewport(SeoLandingForm);
