import React, { useMemo } from 'react';
import { PropertyGroup } from '../../components';
import config from '../../config';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ListingPage.css';

const SectionDescriptionMaybe = props => {
  const {
    description,
    authorDisplayName,
    brandName,
    transmissions,
    fuelType,
    drivenKm,
    seats,
  } = props;

  const seatsLabel = typeof seats === "string" || typeof seats === "number" ? `${seats || '0'} Seats` : seats && seats.length > 0 ? `${seats ? seats[0] : '0'} - ${seats ? seats[1] : '0'} Seats` : '';

  let options = [
    ...config.custom.brands,
    ...config.custom.transmissions,
    ...config.custom.fuelTypes,
    { key: 'numberSeats', label: seatsLabel },
    ...config.custom.drivenKm,
  ];

  const selectedOptions = [
    brandName || '',
    ...(transmissions || ''),
    fuelType || '',
    'numberSeats',
    drivenKm || '',
  ];

  options = useMemo(
    () =>
      options.filter(option => {
        return selectedOptions.filter(select => select === option.key).length > 0;
      }),
    [options, selectedOptions]
  );

  return description || options.length > 0 ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage
          id="ListingPage.descriptionTitle"
          values={{ host: `${authorDisplayName}'s` }}
        />
      </h2>
      <PropertyGroup
        id="ListingPage.aboutCar"
        options={options}
        selectedOptions={selectedOptions}
        twoColumns={true}
      />
    </div>
  ) : null;
};

export default SectionDescriptionMaybe;
