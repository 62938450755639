import { types as sdkTypes } from '../util/sdkLoader';

import { getUpdateObjs } from '../util/updateTransaction';
import { addMarketplaceEntities } from './marketplaceData.duck';

const { UUID } = sdkTypes;

export const GET_CHILD_TXS_REQUEST = 'app/TransactionPage/GET_CHILD_TXS_REQUEST';
export const GET_CHILD_TXS_SUCCESS = 'app/TransactionPage/GET_CHILD_TXS_SUCCESS';
export const GET_CHILD_TXS_ERROR = 'app/TransactionPage/GET_CHILD_TXS_ERROR';

// ================ Reducer ================ //

const initialState = {
  getChildTxsInProgress: false,
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case GET_CHILD_TXS_REQUEST:
      return { ...state, getChildTxsInProgress: true };
    case GET_CHILD_TXS_SUCCESS:
      return { ...state, getChildTxsInProgress: false };
    case GET_CHILD_TXS_ERROR:
      return { ...state, getChildTxsInProgress: false };

    default:
      return state;
  }
};

// ================= Selectors ================ //



// ================ Action creators ================ //

export const getChildTxsRequest = () => ({
  type: GET_CHILD_TXS_REQUEST,
});

export const getChildTxsSuccess = txs => ({
  type: GET_CHILD_TXS_SUCCESS,
});

export const getChildTxsError = () => ({
  type: GET_CHILD_TXS_ERROR,
});

// ================ Thunks ================ //

export const getUpdateBookingTxs = parentTxId => (dispatch, getState, sdk) => {
  dispatch(getChildTxsRequest());
  sdk.transactions
    .show({
      id: new UUID(parentTxId),
    })
    .then(res => {
      const parentTx = res.data.data;
      const childTxs = getUpdateObjs(parentTx);
      const childTxIds = childTxs
        .filter(i => i.txId)
        .map(i => i.txId)
      ;
      const reqs = childTxIds.map(childTxId => {
        return sdk.transactions.show({ id: new UUID(childTxId) });
      });
      Promise.all(reqs)
        .then(responses => {
          responses.forEach(response => dispatch(addMarketplaceEntities(response)));
          dispatch(getChildTxsSuccess());
        })
        .catch(() => dispatch(getChildTxsError()))
      ;
    })
    .catch(() => dispatch(getChildTxsError()))
  ;
};
