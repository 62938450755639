// ================ Action types ================ //
import { fetchCurrentUser } from '../../ducks/user.duck';

const SAVE_ADDRESS_DETAILS_REQUEST = 'app/AddressDetailsPage/SAVE_ADDRESS_DETAILS_REQUEST';
const SAVE_ADDRESS_DETAILS_SUCCESS = 'app/AddressDetailsPage/SAVE_ADDRESS_DETAILS_SUCCESS';
const SAVE_ADDRESS_DETAILS_ERROR = 'app/AddressDetailsPage/SAVE_ADDRESS_DETAILS_ERROR';
const UPDATE_USER_DETAILS_REQUEST = 'app/AddressDetailsPage/UPDATE_USER_DETAILS_REQUEST';
const UPDATE_USER_DETAILS_SUCCESS = 'app/AddressDetailsPage/UPDATE_USER_DETAILS_SUCCESS';
const UPDATE_USER_DETAILS_ERROR = 'app/AddressDetailsPage/UPDATE_USER_DETAILS_ERROR';

// ================ Reducer ================ //

export const initialState = {
  saveAddressDetailsSuccess: null,
  saveAddressDetailsError: null,
  saveAddressDetailsInProgress: null,
  saveUpdateUserSuccess: null,
  saveUpdateUserError: null,
  saveUpdateUserInProgress: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SAVE_ADDRESS_DETAILS_REQUEST:
      return {
        ...state,
        saveAddressDetailsSuccess: false,
        saveAddressDetailsError: false,
        saveAddressDetailsInProgress: true,
      };
    case SAVE_ADDRESS_DETAILS_SUCCESS:
      return { ...state, saveAddressDetailsSuccess: true, saveAddressDetailsInProgress: false };
    case SAVE_ADDRESS_DETAILS_ERROR:
      return { ...state, saveAddressDetailsError: payload, saveAddressDetailsInProgress: false };

    case UPDATE_USER_DETAILS_REQUEST:
      return {
        ...state,
        saveUpdateUserSuccess: null,
        saveUpdateUserError: null,
        saveUpdateUserInProgress: true,
      };
    case UPDATE_USER_DETAILS_SUCCESS:
      return { ...state, saveUpdateUserSuccess: true, saveUpdateUserInProgress: false };
    case UPDATE_USER_DETAILS_ERROR:
      return { ...state, saveUpdateUserError: payload, saveUpdateUserInProgress: false };
    default:
      return state;
  }
}

// ================ Action creators ================ //

const requestSaveAddressDetails = () => ({
  type: SAVE_ADDRESS_DETAILS_REQUEST,
});

const saveAddressDetailsSuccess = () => ({
  type: SAVE_ADDRESS_DETAILS_SUCCESS,
});

const saveAddressDetailsError = e => ({
  type: SAVE_ADDRESS_DETAILS_REQUEST,
  payload: e,
});

const requestUpdateUserDetails = () => ({
  type: UPDATE_USER_DETAILS_REQUEST,
});

const saveUpdateUserSuccess = () => ({
  type: UPDATE_USER_DETAILS_SUCCESS,
});

const saveUpdateUserError = e => ({
  type: UPDATE_USER_DETAILS_ERROR,
  payload: e,
});

export const saveAddressDetails = params => (dispatch, getState, sdk) => {
  dispatch(requestSaveAddressDetails());
  const { location, floorUnit, city, postalCode, state } = params;
  const protectedData = {};
  if (location) protectedData.location = location;
  if (floorUnit) protectedData.floorUnit = floorUnit;
  if (city) protectedData.city = city;
  if (postalCode) protectedData.postalCode = postalCode;
  if (state) protectedData.state = state;
  return sdk.currentUser
    .updateProfile({ protectedData })
    .then(response => {
      dispatch(saveAddressDetailsSuccess());
      dispatch(fetchCurrentUser());
    })
    .catch(error => {
      dispatch(saveAddressDetailsError());
    });
};

export const saveUpdateUserDetails = data => (dispatch, getState, sdk) => {
  dispatch(requestUpdateUserDetails());

  return sdk.currentUser
    .updateProfile(
      {
        ...data,
      },
      {
        include: ['profileImage', 'stripeAccount'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
        expand: true,
      }
    )
    .then(response => {
      dispatch(saveUpdateUserSuccess());
      dispatch(fetchCurrentUser());
    })
    .catch(error => {
      dispatch(saveUpdateUserError());
    });
};
