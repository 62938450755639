import React from 'react';
import { formatMoney } from '../../util/currency';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { LINE_ITEM_DEDUCTIONS, propTypes } from '../../util/types';
import css from './BookingBreakdown.css';
import Explanation from './Explaination';
import { get } from 'lodash';

const LineItemProviderDeductionsRefundMaybe = props => {
  const { transaction, isProvider, intl } = props;

  const refund = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_DEDUCTIONS && item.reversal
  );

  const explanationText = get(transaction, 'attributes.protectedData.hostAmountDeductableReason', '')

  return isProvider && refund ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="BookingBreakdown.refundProviderDeductions" />
        <Explanation text={explanationText} />
      </span>
      <span className={css.itemValue}>{formatMoney(intl, refund.lineTotal, 1)}</span>
    </div>
  ) : null;
};

LineItemProviderDeductionsRefundMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemProviderDeductionsRefundMaybe;
