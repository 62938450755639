import React from 'react';
import { Channels } from '../LandingPage/SectionRealBenefits';

import css from './GuestPromoGroundPlan.css';

const SectionChannels = () => {
  return (
    <div className={css.sectionChannelsMobile}>
      <Channels />
    </div>
  );
};

export default SectionChannels;
