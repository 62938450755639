import { ensureTransaction } from './data';

import {
  LINE_ITEM_CUSTOMER_PROMO,
  LINE_ITEM_MASTERCARD_PROMO,
  LINE_ITEM_NEW_CAR_DISCOUNT,
  LINE_ITEM_PROMOTION_DISCOUNT,
} from './types';

export const PROCESS_NAME_LTR_FIRST = 'long-term-rental-first-two-months';
/**
 * Transitions
 *
 * These strings must sync with values defined in Flex API,
 * since transaction objects given by API contain info about last transitions.
 * All the actions in API side happen in transitions,
 * so we need to understand what those strings mean.
 */

export const TRANSITION_LTF_ENQUIRE = 'transition/enquire';

export const TRANSITION_LTF_WITHDRAW = 'transition/long-term-first-withdraw';
export const TRANSITION_LTF_CUSTOMER_CANCEL = 'transition/long-term-first-customer-cancel';
export const TRANSITION_LTF_PROVIDER_CANCEL = 'transition/long-term-first-provider-cancel';
export const TRANSITION_LTF_CUSTOMER_CANCEL_AFTER_PICK_UP_REQUESTED =
  'transition/long-term-first-customer-cancel-after-pick-up-requested';
export const TRANSITION_LTF_CUSTOMER_CANCEL_AFTER_PICK_UP_CONFIRMED =
  'transition/long-term-first-customer-cancel-after-pick-up-confirmed';
export const TRANSITION_LTF_REQUEST_PICK_UP = 'transition/long-term-first-request-pick-up';
export const TRANSITION_LTF_CONFIRM_PICK_UP = 'transition/long-term-first-confirm-pick-up';
export const TRANSITION_LTF_CUSTOMER_CANCEL_AFTER_ACCEPTED_NONE_REFUNDABLE =
  'transition/long-term-first-customer-cancel-after-accepted-non-refundable';
export const TRANSITION_LTF_PROVIDER_CANCEL_AFTER_ACCEPTED_NONE_REFUNDABLE =
  'transition/long-term-first-provider-cancel-after-accepted-non-refundable';
export const TRANSITION_LTF_ACCEPT = 'transition/long-term-first-accept';

export const TRANSITION_LTF_REQUEST_PAYMENT_NORMAL_COMMERCIAL =
  'transition/long-term-first-request-payment-normal-commercial';
export const TRANSITION_LTF_REQUEST_PAYMENT_NORMAL_PRIVATE =
  'transition/long-term-first-request-payment-normal-private';

//SYSTEM TRANSITION
export const TRANSITION_LTF_MARK_ACCEPTED_NON_REFUNDABLE =
  'transition/long-term-first-mark-accepted-non-refundable';
export const TRANSITION_LTF_COMPLETE = 'transition/long-term-first-complete';

export const TRANSITION_LTF_ADMIN_CANCEL = 'transition/long-term-first-admin-cancel';
export const TRANSITION_LTF_ADMIN_CANCEL_AFTER_ACCEPTED_NON_REFUNDABLE =
  'transition/long-term-first-admin-cancel-after-accepted-non-refundable';
export const TRANSITION_LTF_ADMIN_CANCEL_AFTER_PICK_UP_REQUESTED =
  'transition/long-term-first-admin-cancel-after-pick-up-requested';
export const TRANSITION_LTF_ADMIN_CANCEL_AFTER_PICK_UP_CONFIRMED =
  'transition/long-term-first-admin-cancel-after-pick-up-confirmed';
export const TRANSITION_LTF_ADMIN_CANCEL_REFUND = 'transition/admin-cancel-refund';
export const TRANSITION_LTF_ADMIN_CANCEL_NON_REFUND = 'transition/admin-cancel-non-refund';
export const TRANSITION_LTF_ADMIN_REFUND = 'transition/admin-refund';

export const TRANSITION_LTF_CONFIRM_PAYMENT = 'transition/long-term-first-confirm-payment';

// If the payment is not confirmed in the time limit set in transaction process (by default 15min)
// the transaction will expire automatically.
export const TRANSITION_LTF_EXPIRE_PAYMENT = 'transition/long-term-first-expire-payment';

// When the provider accepts or declines a transaction from the
// SalePage, it is transitioned with the accept or decline transition.
export const TRANSITION_LTF_DECLINE = 'transition/long-term-first-decline';

// The backend automatically expire the transaction.
export const TRANSITION_LTF_EXPIRE = 'transition/long-term-first-expire';

/**
 * Actors
 *
 * There are 4 different actors that might initiate transitions:
 */

// Roles of actors that perform transaction transitions
export const TX_TRANSITION_ACTOR_CUSTOMER = 'customer';
export const TX_TRANSITION_ACTOR_PROVIDER = 'provider';
export const TX_TRANSITION_ACTOR_SYSTEM = 'system';
export const TX_TRANSITION_ACTOR_OPERATOR = 'operator';

export const TX_TRANSITION_ACTORS = [
  TX_TRANSITION_ACTOR_CUSTOMER,
  TX_TRANSITION_ACTOR_PROVIDER,
  TX_TRANSITION_ACTOR_SYSTEM,
  TX_TRANSITION_ACTOR_OPERATOR,
];

/**
 * States
 *
 * These constants are only for making it clear how transitions work together.
 * You should not use these constants outside of this file.
 *
 * Note: these states are not in sync with states used transaction process definitions
 *       in Marketplace API. Only last transitions are passed along transaction object.
 */
const STATE_INITIAL = 'initial';
const STATE_ENQUIRY = 'enquiry';
const STATE_PENDING_PAYMENT = 'pending-payment';
const STATE_PAYMENT_EXPIRED = 'payment-expired';
const STATE_PREAUTHORIZED = 'preauthorized';
const STATE_DECLINED = 'declined';
const STATE_ACCEPTED_REFUNDABLE = 'accepted-refundable';
const STATE_ACCEPTED_NON_REFUNDABLE = 'accepted-non-refundable';
const STATE_PICK_UP_REQUESTED = 'pick-up-requested';
const STATE_PICK_UP_CONFIRMED = 'pick-up-confirmed';
const STATE_COMPLETED = 'completed';
const STATE_ADMIN_REVIEW_NEEDED = 'admin-review-needed';
const STATE_WAITING_FOR_REFUND = 'waiting-for-refund';
const STATE_CANCELLED = 'cancelled';

const stateDescription = {
  // id is defined only to support Xstate format.
  // However if you have multiple transaction processes defined,
  // it is best to keep them in sync with transaction process aliases.
  id: 'long-term-rental-first-two-months',

  // This 'initial' state is a starting point for new transaction
  initial: STATE_INITIAL,

  // States
  states: {
    [STATE_INITIAL]: {
      on: {
        [TRANSITION_LTF_ENQUIRE]: STATE_ENQUIRY,
        [TRANSITION_LTF_REQUEST_PAYMENT_NORMAL_COMMERCIAL]: STATE_PENDING_PAYMENT,
        [TRANSITION_LTF_REQUEST_PAYMENT_NORMAL_PRIVATE]: STATE_PENDING_PAYMENT,
      },
    },
    [STATE_ENQUIRY]: {},
    [STATE_PENDING_PAYMENT]: {
      on: {
        [TRANSITION_LTF_EXPIRE_PAYMENT]: STATE_PAYMENT_EXPIRED,
        [TRANSITION_LTF_CONFIRM_PAYMENT]: STATE_PREAUTHORIZED,
      },
    },

    [STATE_PAYMENT_EXPIRED]: {},
    [STATE_PREAUTHORIZED]: {
      on: {
        [TRANSITION_LTF_WITHDRAW]: STATE_DECLINED,
        [TRANSITION_LTF_DECLINE]: STATE_DECLINED,
        [TRANSITION_LTF_EXPIRE]: STATE_DECLINED,
        [TRANSITION_LTF_ACCEPT]: STATE_ACCEPTED_REFUNDABLE,
      },
    },

    [STATE_DECLINED]: {},
    [STATE_ACCEPTED_REFUNDABLE]: {
      on: {
        [TRANSITION_LTF_MARK_ACCEPTED_NON_REFUNDABLE]: STATE_ACCEPTED_NON_REFUNDABLE,
        [TRANSITION_LTF_ADMIN_CANCEL]: STATE_ADMIN_REVIEW_NEEDED,
        [TRANSITION_LTF_PROVIDER_CANCEL]: STATE_ADMIN_REVIEW_NEEDED,
        [TRANSITION_LTF_CUSTOMER_CANCEL]: STATE_ADMIN_REVIEW_NEEDED,
      },
    },
    [STATE_ACCEPTED_NON_REFUNDABLE]: {
      on: {
        [TRANSITION_LTF_REQUEST_PICK_UP]: STATE_PICK_UP_REQUESTED,
        [TRANSITION_LTF_CUSTOMER_CANCEL_AFTER_ACCEPTED_NONE_REFUNDABLE]: STATE_ADMIN_REVIEW_NEEDED,
        [TRANSITION_LTF_PROVIDER_CANCEL_AFTER_ACCEPTED_NONE_REFUNDABLE]: STATE_ADMIN_REVIEW_NEEDED,
        [TRANSITION_LTF_ADMIN_CANCEL_AFTER_ACCEPTED_NON_REFUNDABLE]: STATE_ADMIN_REVIEW_NEEDED,
      },
    },
    [STATE_PICK_UP_REQUESTED]: {
      on: {
        [TRANSITION_LTF_CONFIRM_PICK_UP]: STATE_PICK_UP_CONFIRMED,
        [TRANSITION_LTF_CUSTOMER_CANCEL_AFTER_PICK_UP_REQUESTED]: STATE_ADMIN_REVIEW_NEEDED,
        [TRANSITION_LTF_ADMIN_CANCEL_AFTER_PICK_UP_REQUESTED]: STATE_ADMIN_REVIEW_NEEDED,
      },
    },
    [STATE_PICK_UP_CONFIRMED]: {
      on: {
        [TRANSITION_LTF_COMPLETE]: STATE_COMPLETED,
        [TRANSITION_LTF_CUSTOMER_CANCEL_AFTER_PICK_UP_CONFIRMED]: STATE_ADMIN_REVIEW_NEEDED,
        [TRANSITION_LTF_ADMIN_CANCEL_AFTER_PICK_UP_CONFIRMED]: STATE_ADMIN_REVIEW_NEEDED,
      },
    },
    [STATE_ADMIN_REVIEW_NEEDED]: {
      on: {
        [TRANSITION_LTF_ADMIN_CANCEL_REFUND]: STATE_WAITING_FOR_REFUND,
        [TRANSITION_LTF_ADMIN_CANCEL_NON_REFUND]: STATE_CANCELLED,
      },
    },
    [STATE_WAITING_FOR_REFUND]: {
      on: {
        [TRANSITION_LTF_ADMIN_REFUND]: STATE_CANCELLED,
      },
    },
    [STATE_CANCELLED]: {},
    [STATE_COMPLETED]: { type: 'final' },
  },
};

// Note: currently we assume that state description doesn't contain nested states.
const statesFromStateDescription = description => description.states || {};

// Get all the transitions from states object in an array
export const getTransitions = states => {
  const stateNames = Object.keys(states);

  const transitionsReducer = (transitionArray, name) => {
    const stateTransitions = states[name] && states[name].on;
    const transitionKeys = stateTransitions ? Object.keys(stateTransitions) : [];
    return [
      ...transitionArray,
      ...transitionKeys.map(key => ({ key, value: stateTransitions[key] })),
    ];
  };

  return stateNames.reduce(transitionsReducer, []);
};

// This is a list of all the transitions that this app should be able to handle.
export const TRANSITIONS_LTF = getTransitions(statesFromStateDescription(stateDescription)).map(
  t => t.key
);

// This function returns a function that has given stateDesc in scope chain.
export const getTransitionsToStateFn = stateDesc => state =>
  getTransitions(statesFromStateDescription(stateDesc))
    .filter(t => t.value === state)
    .map(t => t.key);

// Get all the transitions that lead to specified state.
const getTransitionsToState = getTransitionsToStateFn(stateDescription);

// This is needed to fetch transactions that need response from provider.
// I.e. transactions which provider needs to accept or decline
export const transitionsToRequested = getTransitionsToState(STATE_PREAUTHORIZED);

/**
 * Helper functions to figure out if transaction is in a specific state.
 * State is based on lastTransition given by transaction object and state description.
 */

export const txLastTransition = tx => ensureTransaction(tx).attributes.lastTransition;

export const txIsEnquiredLTF = tx =>
  getTransitionsToState(STATE_ENQUIRY).includes(txLastTransition(tx));

export const txIsPaymentPendingLTF = tx =>
  getTransitionsToState(STATE_PENDING_PAYMENT).includes(txLastTransition(tx));

export const txIsPaymentExpiredLTF = tx =>
  getTransitionsToState(STATE_PAYMENT_EXPIRED).includes(txLastTransition(tx));

// Note: state name used in Marketplace API docs (and here) is actually preauthorized
// However, word "requested" is used in many places so that we decided to keep it.
export const txIsRequestedLTF = tx =>
  getTransitionsToState(STATE_PREAUTHORIZED).includes(txLastTransition(tx));

export const txIsAcceptedLTF = tx =>
  getTransitionsToState(STATE_ACCEPTED_REFUNDABLE).includes(txLastTransition(tx)) ||
  getTransitionsToState(STATE_ACCEPTED_NON_REFUNDABLE).includes(txLastTransition(tx));

export const txIsBeingPickedUpLTF = tx =>
  getTransitionsToState(STATE_PICK_UP_REQUESTED).includes(txLastTransition(tx));

export const txIsPickedUpLTF = tx =>
  getTransitionsToState(STATE_PICK_UP_CONFIRMED).includes(txLastTransition(tx));

export const txIsAcceptedMoreThan72HourLTF = tx => {
  const transition = txLastTransition(tx);
  return transition === TRANSITION_LTF_MARK_ACCEPTED_NON_REFUNDABLE;
};

export const txIsDeclinedLTF = tx =>
  getTransitionsToState(STATE_DECLINED).includes(txLastTransition(tx));

export const txIsCancelledLTF = tx =>
  getTransitionsToState(STATE_CANCELLED).includes(txLastTransition(tx)) ||
  getTransitionsToState(STATE_ADMIN_REVIEW_NEEDED).includes(txLastTransition(tx)) ||
  getTransitionsToState(STATE_WAITING_FOR_REFUND).includes(txLastTransition(tx));

export const txIsWaitingForRefundLTF = tx =>
  getTransitionsToState(STATE_WAITING_FOR_REFUND).includes(txLastTransition(tx));

export const txIsRefundedLTF = tx => {
  const transition = txLastTransition(tx);
  return transition === TRANSITION_LTF_ADMIN_REFUND;
};

export const txIsCompletedLTF = tx =>
  getTransitionsToState(STATE_COMPLETED).includes(txLastTransition(tx));

export const txIsWithdrawnLTF = tx => {
  const transition = txLastTransition(tx);
  return transition === TRANSITION_LTF_WITHDRAW;
};

export const txIsExpiredLTF = tx => {
  const transition = txLastTransition(tx);
  return transition === TRANSITION_LTF_EXPIRE;
};

export const txIsDeclinedOrExpiredLTF = tx => txIsDeclinedLTF(tx) || txIsExpiredLTF(tx);

export const isCancelledTransactionLTF = tx =>
  txIsDeclinedLTF(tx) || txIsExpiredLTF(tx) || txIsWithdrawnLTF(tx) || txIsCancelledLTF(tx);

export const txIsCanceledByHostLTF = tx => {
  const transition = txLastTransition(tx);
  return transition === TRANSITION_LTF_PROVIDER_CANCEL;
};

export const txIWaitingRequestPickupLTF = tx => {
  const transition = txLastTransition(tx);
  return transition === TRANSITION_LTF_MARK_ACCEPTED_NON_REFUNDABLE;
};

export const txIWaitingConfirmPickupLTF = tx => {
  const transition = txLastTransition(tx);
  return transition === TRANSITION_LTF_REQUEST_PICK_UP;
};

export const txIsAfterConfirmPickUpLTF = tx => {
  const transition = txLastTransition(tx);
  return [
    TRANSITION_LTF_COMPLETE,
    TRANSITION_LTF_CUSTOMER_CANCEL_AFTER_PICK_UP_CONFIRMED,
    TRANSITION_LTF_ADMIN_CANCEL_AFTER_PICK_UP_CONFIRMED,
  ].includes(transition);
};

const txTransitions = tx => ensureTransaction(tx).attributes.transitions || [];
export const hasPassedTransition = (transitionName, tx) =>
  !!txTransitions(tx).find(t => t.transition === transitionName);

export const hasPassedStateFn = state => tx =>
  getTransitionsToState(state).filter(t => hasPassedTransition(t, tx)).length > 0;

export const txHasBeenAcceptedLTF = tx => {
  const transition = txLastTransition(tx);
  return [
    TRANSITION_LTF_ACCEPT,
    TRANSITION_LTF_MARK_ACCEPTED_NON_REFUNDABLE,
    TRANSITION_LTF_REQUEST_PICK_UP,
    TRANSITION_LTF_CONFIRM_PICK_UP,
    TRANSITION_LTF_COMPLETE,
  ].includes(transition);
};

// Check if a transition is the kind that should be rendered
// when showing transition history (e.g. ActivityFeed)
// The first transition and most of the expiration transitions made by system are not relevant
export const isRelevantPastTransitionLTF = transition => {
  return [
    // TRANSITION_LTF_ENQUIRE,
    TRANSITION_LTF_WITHDRAW,
    TRANSITION_LTF_CUSTOMER_CANCEL,
    TRANSITION_LTF_PROVIDER_CANCEL,
    TRANSITION_LTF_CUSTOMER_CANCEL_AFTER_PICK_UP_REQUESTED,
    TRANSITION_LTF_CUSTOMER_CANCEL_AFTER_PICK_UP_CONFIRMED,
    TRANSITION_LTF_REQUEST_PICK_UP,
    TRANSITION_LTF_CONFIRM_PICK_UP,
    TRANSITION_LTF_CUSTOMER_CANCEL_AFTER_ACCEPTED_NONE_REFUNDABLE,
    TRANSITION_LTF_PROVIDER_CANCEL_AFTER_ACCEPTED_NONE_REFUNDABLE,
    TRANSITION_LTF_ACCEPT,
    TRANSITION_LTF_COMPLETE,
    TRANSITION_LTF_ADMIN_CANCEL,
    TRANSITION_LTF_ADMIN_CANCEL_AFTER_ACCEPTED_NON_REFUNDABLE,
    TRANSITION_LTF_ADMIN_CANCEL_AFTER_PICK_UP_REQUESTED,
    TRANSITION_LTF_ADMIN_CANCEL_AFTER_PICK_UP_CONFIRMED,
    TRANSITION_LTF_ADMIN_CANCEL_REFUND,
    TRANSITION_LTF_ADMIN_CANCEL_NON_REFUND,
    TRANSITION_LTF_ADMIN_REFUND,
    TRANSITION_LTF_CONFIRM_PAYMENT,
    TRANSITION_LTF_EXPIRE_PAYMENT,
    TRANSITION_LTF_DECLINE,
    TRANSITION_LTF_EXPIRE,
  ].includes(transition);
};

export const txIsNormalCommercial = tx =>
  hasPassedTransition(TRANSITION_LTF_REQUEST_PAYMENT_NORMAL_COMMERCIAL, tx);
export const txIsNormalPrivate = tx =>
  hasPassedTransition(TRANSITION_LTF_REQUEST_PAYMENT_NORMAL_PRIVATE, tx);

export const txCancelByProviderLTF = tx =>
  hasPassedTransition(TRANSITION_LTF_PROVIDER_CANCEL, tx) ||
  hasPassedTransition(TRANSITION_LTF_PROVIDER_CANCEL_AFTER_ACCEPTED_NONE_REFUNDABLE, tx);

export const txCancelByCustomerLTF = tx =>
  hasPassedTransition(TRANSITION_LTF_CUSTOMER_CANCEL, tx) ||
  hasPassedTransition(TRANSITION_LTF_CUSTOMER_CANCEL_AFTER_ACCEPTED_NONE_REFUNDABLE, tx) ||
  hasPassedTransition(TRANSITION_LTF_CUSTOMER_CANCEL_AFTER_PICK_UP_REQUESTED, tx) ||
  hasPassedTransition(TRANSITION_LTF_CUSTOMER_CANCEL_AFTER_PICK_UP_CONFIRMED, tx);

export const txGetPromoLineItem = tx => {
  //todo: a user can only have 1 kind of promo for a booking
  return tx.attributes.lineItems.find(l => {
    return [
      LINE_ITEM_CUSTOMER_PROMO,
      LINE_ITEM_MASTERCARD_PROMO,
      LINE_ITEM_NEW_CAR_DISCOUNT,
      LINE_ITEM_PROMOTION_DISCOUNT
    ].includes(l.code);
  });
};
