import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import config from '../../config';
import css from './HostPromoLandingPage.css';
import bannerBg from '../../assets/hostLanding/host-landing-bg.svg';
import bannerBg2 from '../../assets/hostLanding/host-landing-bg2.svg';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
} from '../../components';
import { connect } from 'react-redux';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import HostSectionHeroPartners from './HostSectionHeroPartners';
import SectionHelp from '../LandingPage/SectionHelp';
import SectionChannels from './SectionChannels';
import { fetchFeaturedCar } from '../SearchPage/SearchPage.duck';
import { parse } from '../../util/urlHelpers';
import { checkSignUpCode } from '../../util/referralProgram';
import { formatMoney } from '../../util/currency';
import { types } from '../../util/sdkLoader';
import HostSectionStories from './HostSectionStories';
import HostEarningCalculator from './HostEarningCalculator';
import { initiateListACarEventFromTab, pushGTMBrowseEvent } from '../../util/gtm/gtmHelpers';
import {
  EVENT_BROWSE_USEDRENTALCAL,
  EVENT_LISTACAR_CLICKED_LIST_A_CAR,
  LISTACAR_CREATE_NEW_BUTTON_ID,
} from '../../util/gtm/gtmConstants';
import SectionInsurance from './SectionInsurance';
import SectionSharingCarsSimply from './SectionSharingCarsSimply';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { useHistory, useLocation } from 'react-router';
import { getEventsByBucket } from '../../util/gtm/gtmCreateProperties';
import { triggerAnalyticsEvent } from '../../util/amplitudeMapEvents';
import { event_trigger_ids } from '../../util/analyticsConstants';

const { Money } = types;

const HostPromoLandingPartners = props => {
  const { scrollingDisabled, location, currentUser } = props;
  const intl = useIntl();
  const isScoot = location.pathname === '/scoot-drivelah-signup';
  const [signUpCodeAmount, setSignUpCodeAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const siteTitle = config.siteTitle;
  const targetRef = useRef(null);
  const locationData = useLocation();
  const history = useHistory();
  const schemaTitle = intl.formatMessage(
    {
      id:
        !signUpCodeAmount || signUpCodeAmount === 0
          ? 'HostSignUpPromoLandingPage.schemaTitleMeta'
          : isScoot
          ? 'HostSignUpPromoLandingPage.scootSchemaTitleMeta'
          : 'HostSignUpPromoLandingPage.schemaTitleMeta',
    },
    { siteTitle, amount: formatMoney(intl, new Money(signUpCodeAmount, config.currency)) }
  );
  const description = intl.formatMessage({ id: 'HostSignUpPromoLandingPage.description' });

  const onUseRentalCalculator = () => {
    // pushGTMBrowseEvent({
    //   props,
    //   event: EVENT_BROWSE_USEDRENTALCAL,
    // });
  };

  const onListYourCar = async () => {
    const { code } = parse(locationData.search);
    // initiateListACarEventFromTab({
    //   props,
    //   eventButtonParam: [EVENT_LISTACAR_CLICKED_LIST_A_CAR, LISTACAR_CREATE_NEW_BUTTON_ID],
    // });
    try {
      const response = await getEventsByBucket('listacar');
      // console.log('Events fetched successfully:', response.data);
      triggerAnalyticsEvent({
        event_id: event_trigger_ids.LISTACAR_CLICKED_LIST_A_CAR, 
        eventData: response.data, 
        props: {
          ui: {
            button: "Create Listing"
          }
        },
        userId: currentUser && currentUser.id && currentUser.id.uuid
      });
    } catch (error) {
      console.log('Error fetching events:', error);
    }

    const url = createResourceLocatorString('SignupPage', routeConfiguration(), {}, {});
    history.push(
      code
        ? {
          pathname: url,
          search: `?code=${code}`,
        }
        : {
          pathname: url,
        }
    );
  };

  useEffect(() => {
    const { code } = parse(location.search);
    if (code) {
      setLoading(true);
      checkSignUpCode(code).then(res => {
        if (res.valid) {
          setSignUpCodeAmount(res.credits);
        }
        setLoading(false);
      });
    }
  }, []);

  const handleGetToCalculatorSection = () => {
    window.scrollTo(0, targetRef.current.offsetTop - 72);
  };

  return (
    <Page
      title={schemaTitle}
      description={description}
      scrollingDisabled={scrollingDisabled}
      facebookImages={[
        {
          url: 'https://drivelah-public-files.s3-ap-southeast-1.amazonaws.com/Images/DL-image.png',
          width: 800,
          height: 450,
        },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        name: schemaTitle,
        description: description,
        image: 'https://drivelah-public-files.s3-ap-southeast-1.amazonaws.com/Images/DL-image.png',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div
            style={{
              backgroundImage: `url(${bannerBg})`,
              backgroundPosition: 'center center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}
          >
            <HostSectionHeroPartners
              isScoot={isScoot}
              signUpCodeAmount={signUpCodeAmount}
              loading={loading}
              getToCalculatorSection={handleGetToCalculatorSection}
            />
            <SectionChannels />
            <SectionInsurance />
          </div>
          <div ref={targetRef}>
            <HostEarningCalculator
              onUseRentalCalculator={onUseRentalCalculator}
              onListYourCar={onListYourCar}
              listYourCarButtonId={LISTACAR_CREATE_NEW_BUTTON_ID}
            />
          </div>
          <div
            style={{
              backgroundImage: `url(${bannerBg2})`,
              backgroundPosition: 'center center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}
          >
            <SectionSharingCarsSimply />
            <div className={css.landingContainer}>
              <HostSectionStories />
            </div>
            <SectionHelp />
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

HostPromoLandingPartners.loadData = () => {
  return fetchFeaturedCar();
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  return {
    currentUser,
  };
};

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(HostPromoLandingPartners);

