import imageSource from '../../util/imageSource';

export const TOP_BRANDS = {
  generateRoute: key => {
    return `/car-rental/top-car-rental-brands/${key}-cars-for-rent`;
  },
  values: [
    { key: 'audi', label: 'Audi', image: imageSource.seoLandingPage.cars.audi, alt: 'Rent Audi Car' },
    { key: 'bmw', label: 'BMW', image: imageSource.seoLandingPage.cars.bmw, alt: 'Rent Bmw Car' },
    { key: 'honda', label: 'Honda', image: imageSource.seoLandingPage.cars.honda, alt: 'Rent Honda Car' },
    { key: 'hyundai', label: 'Hyundai', image: imageSource.seoLandingPage.cars.hyundai, alt: 'Rent Hyundai Car' },
    { key: 'mercedes-benz', label: 'Mercedes Benz', image: imageSource.seoLandingPage.cars.mercedez, alt: 'Rent Mercedes Car' },
    { key: 'mini', label: 'Mini', image: imageSource.seoLandingPage.cars.mini, alt: 'Rent Mini Car' },
    { key: 'mitsubishi', label: 'Mitsubishi', image: imageSource.seoLandingPage.cars.mitsubishi, alt: 'Rent Mitsubishi Car' },
    { key: 'porsche', label: 'Porsche', image: imageSource.seoLandingPage.cars.porsche, alt: 'Rent Porsche Car' },
    { key: 'toyota', label: 'Toyota', image: imageSource.seoLandingPage.cars.toyota, alt: 'Rent Toyota Car' },
    { key: 'volvo', label: 'Volvo', image: imageSource.seoLandingPage.cars.volvo, alt: 'Rent Volvo Car' },
    { key: 'mazda', label: 'Mazda', image: imageSource.seoLandingPage.cars.mazda, alt: 'Rent Mazda Car' },
  ],
};

export const TOP_LOCATIONS = {
  generateRoute: key => {
    return `/car-rental/singapore-locations/car-rental-in-${key}`;
  },
  values: [
    { key: 'bukit-batok', label: 'Bukit Batok', image: imageSource.seoLandingPage.location.bukitBatok, alt: 'Bukit Batok' },
    { key: 'choa-chu-kang', label: 'Choa Chu Kang', image: imageSource.seoLandingPage.location.choaChuKang, alt: 'Chao chu kang' },
    { key: 'punggol', label: 'Punggol', image: imageSource.seoLandingPage.location.punggol, alt: 'Rent cars in Punggol' },
    { key: 'serangoon', label: 'Serangoon', image: imageSource.seoLandingPage.location.serangoon, alt: 'Rent cars in Serangoon' },
    { key: 'tampines', label: 'Tampines', image: imageSource.seoLandingPage.location.tampines, alt: 'Rent cars in Tampines' },
    { key: 'woodlands', label: 'Woodlands', image: imageSource.seoLandingPage.location.woodlands, alt: 'Rent cars in Woodlands' },
    { key: 'jurong', label: 'Jurong', image: imageSource.seoLandingPage.location.jurong, alt: 'Rent cars in Jurong' },
    { key: 'ang-mo-kio', label: 'Ang Mo Kio', image: imageSource.seoLandingPage.location.angMoKoi, alt: 'Rent cars in Ang Mo Koi' },
    { key: 'bukit-panjang', label: 'Bukit Panjang', image: imageSource.seoLandingPage.location.bukitPanjang, alt: 'Rent cars in Bukit Panjang' },
    { key: 'sengkang', label: 'Sengkang', image: imageSource.seoLandingPage.location.sengkang, alt: 'Rent cars in Sengkang' },
    { key: 'sembawang', label: 'Sembawang', image: imageSource.seoLandingPage.location.sembawang, alt: 'Rent cars in Sembawang' },
    { key: 'hougang', label: 'Hougang', image: imageSource.seoLandingPage.location.hougang, alt: 'Rent cars in  Hougang' },
    { key: 'yishun', label: 'Yishun', image: imageSource.seoLandingPage.location.yishun, alt: 'Rent cars in Yishun' },
  ],
};

export const TOP_CATEGORIES = {
  generateRoute: key => {
    return `/car-rental/car-rental-by-segment/${key}-cars-for-rent`;
  },
  values: [
    { key: 'compact', label: 'Compact', image: imageSource.seoLandingPage.segment.compact, alt: 'Rent Compact Car' },
    { key: 'standard-sedan', label: 'Standard Sedan', image: imageSource.seoLandingPage.segment.standardSedan, alt: 'Standard Sedan Car' },
    { key: 'standard-suv', label: 'Standard SUV', image: imageSource.seoLandingPage.segment.standardSuv, alt: 'Standard Suv Car' },
    { key: 'luxury-sedan', label: 'Luxury Sedans', image: imageSource.seoLandingPage.segment.luxurySedan, alt: 'Luxury Sedan Car' },
    { key: 'luxury-suv', label: 'Luxury SUV', image: imageSource.seoLandingPage.segment.luxurySuv, alt: 'Luxury Suv Car' },
  ],
};
