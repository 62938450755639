import React, { Component } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { VERIFICATION_STATUS_VERIFIED } from '../../util/types';
import { IconCheckmark, Modal, PrimaryButton, SecondaryButton } from '../../components';
import { TRANSITION_DISPUTE } from '../../util/transaction';
import css from './TripPanel.css';

const MAXIMUM_LETTERS_OF_DISPUTE_REASON = 10;

class ModalReasonDispute extends Component {
  render() {
    const {
      isOpen,
      onClose,
      onManageDisableScrolling,
      transitInProgress,
      transitError,
      onTransit,
      onSendMessage,
      transaction,
      reasonDispute,
      onReasonDisputeChange,
      tripPhotos,
      reasonDisputePhotoUrls,
      onChoosePhoto,
      submitButtonId,
      onPushDisputeEventGTM,
    } = this.props;

    const disputeButtonDisabled =
      transitInProgress ||
      !(reasonDispute && reasonDispute.length > MAXIMUM_LETTERS_OF_DISPUTE_REASON);
    const validTransaction = transaction && transaction.customer;
    const transitAllow =
      validTransaction &&
      (transaction.customer.attributes.profile.publicData.guestIdentityVerificationStatus ===
        VERIFICATION_STATUS_VERIFIED ||
        transaction.customer.attributes.profile.publicData.hostIdentityVerificationStatus ===
        VERIFICATION_STATUS_VERIFIED);

    return (
      <Modal
        id="modalReasonDispute"
        isOpen={isOpen}
        onClose={onClose}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.reasonDisputeContainer}>
          <h2 className={css.reasonDisputeTitle}>
            <FormattedMessage id="TransactionPanel.reasonDisputeTitle" />
          </h2>
          <div className={css.reasonDisputeInputWrapper}>
            <textarea
              rows="8"
              className={css.reasonDisputeInput}
              ref={node => (this.reasonInput = node)}
              onChange={e => onReasonDisputeChange(e.target.value)}
            />
            <p className={css.explainMoreWhenHostDispute}>
              <FormattedMessage id="TransactionPanel.explainMoreWhenHostDispute" />
            </p>
          </div>
          <div className={css.tripPhotos}>
            {tripPhotos.map(photo => {
              return (
                <div
                  key={photo.fileUrl}
                  className={css.tripPhoto}
                  onClick={() => onChoosePhoto(photo.fileUrl)}
                >
                  {reasonDisputePhotoUrls.find(url => url === photo.fileUrl) && (
                    <IconCheckmark className={css.checkDisputePhotoSelected} />
                  )}
                  <img src={photo.fileUrl} />
                </div>
              );
            })}
          </div>
          <PrimaryButton
            className={css.doNotDisbuteButton}
            inProgress={transitInProgress}
            onClick={onClose}
          >
            <FormattedMessage id="TransactionPanel.disputeModalDoNotDispute" />
          </PrimaryButton>
          <SecondaryButton
            className={css.disputeButton}
            inProgress={transitInProgress}
            disabled={disputeButtonDisabled || !transitAllow}
            id={submitButtonId}
            onClick={() => {
              const tripPhotosMessage =
                reasonDisputePhotoUrls.length > 0
                  ? reasonDisputePhotoUrls.length === 1
                    ? `[img]${reasonDisputePhotoUrls[0]}[/img]`
                    : `[imgs]${reasonDisputePhotoUrls.join(', ')}[/imgs]`
                  : '';

              if (transitAllow) {
                onTransit(transaction.id, TRANSITION_DISPUTE, null, [...tripPhotos]).then(() => {
                  onPushDisputeEventGTM();
                  onSendMessage(
                    transaction.id,
                    `[Reason of Dispute] ${reasonDispute} ${tripPhotosMessage} [/Reason of Dispute]`
                  );
                  onClose();
                });
              }
            }}
          >
            <FormattedMessage id="TransactionPanel.disputeModalDisputeButton" />
          </SecondaryButton>
        </div>
      </Modal>
    );
  };
};

export default ModalReasonDispute;
