import React from 'react';
import { ExternalLink, InsurancePlusSign } from '../../components';
import css from './InsuranceDisclaimer.css';

const InsuranceDisclaimer = props => {
  return (
    <div className={css.root}>
      <span>
        <InsurancePlusSign />
        All cars aged younger than or equal to 12 years are comprehensively insured.
        For more information <ExternalLink href={'https://help.drivelah.sg'} className={css.link}>
          click here.
        </ExternalLink>
      </span>
    </div>
  );
};

export default InsuranceDisclaimer;
