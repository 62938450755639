import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
  TrustBoxSlider,
} from '../../components';
import config from '../../config';
import { TopbarContainer } from '../../containers';
import {
  isScrollingDisabled,
  manageDisableScrolling,
  manageTurnoffPromoteBar,
} from '../../ducks/UI.duck';
import { withViewport } from '../../util/contextHelpers';
import { EVENT_BROWSE_HOMEPAGE } from '../../util/gtm/gtmConstants';
import { pushGTMBrowseEvent } from '../../util/gtm/gtmHelpers';
import imageSource from '../../util/imageSource';
import { injectIntl, intlShape } from '../../util/reactIntl';
import DownloadAppSection from '../AntiCompetitionLandingPage/DownloadAppSection';
import SectionHelp from './SectionHelp';
import SectionRealBenefits from './SectionRealBenefits';
import { TOP_BRANDS, TOP_CATEGORIES, TOP_LOCATIONS } from './seoCategoriesArrays';
import SeoLandingBannerRentalCategories from './SeoLandingBannerRentalCategories';
import SeoLandingCarRentalCategories from './SeoLandingCarRentalCategories';
import css from './SeoLandingPage.css';
import SeoLandingRentVideo from './SeoLandingRentVideo';
import SeoLandingWhyRentCar from './SeoLandingWhyRentCar';

const MAX_MOBILE_SCREEN_WIDTH = 768;
export const SeoCarRentalLandingPageComponent = props => {
  const {
    intl,
    scrollingDisabled,
    onManageDisableScrolling,
    viewport,
    currentPage = 'SeoCarRentalLandingPage',
  } = props;
  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: `Rent your neighbour's car | Drive lah` }, { siteTitle });
  const schemaDescription = intl.formatMessage({
    id:
      'With Drive lah, you can rent from a huge variety of nearby cars at great value. Fully insured+.',
  });
  const schemaImage = `${config.canonicalRootURL}${imageSource.seoLandingPage.landingBannerImage}`;
  let [selectedValues, setSelectedValues] = useState(null);
  let [onLocationChange, setOnLocationChange] = useState(null);
  let [searchFilters, setSearchFilters] = useState(null);
  const onPushGTMEvent = (event, buttonId) => {
    // pushGTMBrowseEvent({
    //   props,
    //   event,
    //   buttonId,
    // });
  };

  useEffect(() => {
    onPushGTMEvent(EVENT_BROWSE_HOMEPAGE);
  }, [onLocationChange]);

  let isMobileLayout;
  try {
    if (viewport) {
      isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
    }
  } catch (error) {

  }

  const onMapMoveEnd = () => {};
  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: imageSource.seoLandingPage.landingBannerImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${imageSource.seoLandingPage.landingBannerImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer notApplySearch={true} currentPage={currentPage} />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.contentWrapper}>
          <div style={{ zIndex: 2 }}>
            <SeoLandingBannerRentalCategories
              isMobileLayout={isMobileLayout}
              searchFilters={searchFilters}
              onManageDisableScrolling={onManageDisableScrolling}
              selectedValues={selectedValues}
            />
            {!isMobileLayout && (
              <SectionRealBenefits
                onManageDisableScrolling={onManageDisableScrolling}
                onPushGTMSeeVideoEvent={onPushGTMEvent}
              />
            )}
            <div className={css.carRentBrands}>
              <div className={css.carRentBrandsContainer}>
                <div className={css.carRentBrandsTitle}>
                  <span>Car rental in Singapore</span>
                  <p>
                    Looking to rent a car in Singapore? There are hundreds of cars belonging to real
                    people on Drive lah. Whether you're looking for a large car for a weekend away
                    with friends, or a something smaller for a trip to the supermarket, you can be
                    sure to find the right car for you. You can choose from over 2,000 cars around
                    Singapore. With Drive lah, you can rent from a huge variety of nearby cars at
                    great value.
                  </p>
                </div>
              </div>
            </div>
            <SeoLandingCarRentalCategories
              title={'Rent cars in all popular locations in Singapore'}
              text={
                'Drive lah hosts are from all over Singapore and no matter where you are there will always be a Drive lah car nearby for you to rent.'
              }
              selectedValues={selectedValues}
              setSelectedValues={val => setSelectedValues(val)}
              categoryName={TOP_LOCATIONS}
            />
            <SeoLandingWhyRentCar />
            <SeoLandingCarRentalCategories
              title={'Rent cars from popular brands'}
              text={
                'Owing to the fact that all cars on Drive lah are owned by real people, we are able to offer a much more diverse brands of cars and that too at highly economical price points. Rent cars from popular brands on Drive lah today.'
              }
              selectedValues={selectedValues}
              setSelectedValues={val => setSelectedValues(val)}
              categoryName={TOP_BRANDS}
            />
            <SeoLandingRentVideo
              onManageDisableScrolling={onManageDisableScrolling}
              onPushGTMSeeVideoEvent={onPushGTMEvent}
            />
            <SeoLandingCarRentalCategories
              title={'Cars for rent based on segment'}
              text={
                'No matter the occasion we got the car for you. From taking a compact car for a quick shopping run to a SUV for a family outing, we got it all and that too right in your neighbourhood.'
              }
              selectedValues={selectedValues}
              setSelectedValues={val => setSelectedValues(val)}
              categoryName={TOP_CATEGORIES}
            />
            <TrustBoxSlider
              sectionTitle="Our users loves us"
              sectionSubTitle="People love renting and sharing cars on Drive lah"
            />
            <DownloadAppSection />
            <SectionHelp />
            {/* <SeoLandingRentalCategories /> */}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool, object, number, array, shape, func } = PropTypes;

SeoCarRentalLandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  mapListings: array,
  onSearchMapListings: func.isRequired,
  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const { isAuthenticated } = state.Auth;
  return {
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
    turnoffPromoteBar: state.UI.turnoffPromoteBar,
    isAuthenticated,
  };
};

const mapDispatchToProps = dispatch => ({
  onTurnoffPromoteBar: flagVal => dispatch(manageTurnoffPromoteBar(flagVal)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SeoCarRentalLandingPage = compose(
  withViewport,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(SeoCarRentalLandingPageComponent);
export default SeoCarRentalLandingPage;
