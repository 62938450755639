import classNames from 'classnames';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { Swiper, SwiperSlide } from 'swiper/react';
import { DEFAULT_TIMEZONE, getDefaultTimeZoneOnBrowser } from '../../util/dates';
import { parse } from '../../util/urlHelpers';
import css from './SwipeTimeSelector.css';

const moment = extendMoment(Moment);
const TODAY = moment().tz(DEFAULT_TIMEZONE);
const localTimeZone = getDefaultTimeZoneOnBrowser();
const createData = (date, isPickupDate, selectedDates, selectedTimes, excludeEnd = false) => {
  if (!date) return [];
  let start;
  // If start date and end date are same, apply EndTime to be started after the time of the StartTime
  if (!isPickupDate) {
    const getStartDate = selectedDates && selectedDates.startDate;
    const getEndDate = selectedDates && selectedDates.endDate;
    if (moment(getEndDate).isSame(getStartDate, 'day')) {
      let startTimeValue = selectedTimes && selectedTimes.startTime;
      start = moment(startTimeValue)
        .clone()
        .add(1, 'hours')
        .startOf('hours');
    } else {
      start = moment(date).startOf('day');
    }
  } else {
    start =
      TODAY.format('DD/MM/YYYY') === moment(date).format('DD/MM/YYYY')
        ? TODAY.clone()
            .add(1, 'hours')
            .startOf('hours')
        : moment(date).startOf('day');
  }
  const end = start
    .clone()
    .add(1, 'days')
    .startOf('day');
  return Array.from(
    moment.range(start, end).by('minutes', {
      step: 30,
      excludeEnd,
    })
  );
};

const findIndexOfTime = (timeData, time) => {
  const selectedTimeIndex = timeData.findIndex((t, i) => {
    const timeMap = t.tz(DEFAULT_TIMEZONE, true).format('hhmma');
    const selectedTime = time && time.tz(DEFAULT_TIMEZONE, true).format('hhmma');
    if (timeMap === selectedTime) {
      return i;
    }
  });
  if (selectedTimeIndex === -1) {
    return 0;
  }
  return selectedTimeIndex;
};

const SwipeTimeSelector = ({
  date,
  onSelect,
  setSelectedTimes,
  selectedTimes,
  setSelectedDates,
  isPickupDate,
  selectedDates,
}) => {
  const timeData = date
    ? createData(date, isPickupDate, selectedDates, selectedTimes)
    : createData(moment(), isPickupDate, selectedDates, selectedTimes);
  const [selectedDate, setSelectedDate] = useState(date);
  const [selectedDateAvailableTimes, getSelectedDateAvailableTimes] = useState(timeData);
  const [selectedPickerTime, getSelectedPickerTime] = useState();
  const location = useLocation();
  const { pathname, search } = location;
  const { searchStep, ...restSearch } = parse(search);
  const prevDate = useRef();

  useEffect(() => {
    if (selectedDate !== prevDate.current) {
      prevDate.current = selectedDate;
    } else {
      // do something when matches both name
    }
  }, [selectedDate]);

  const _handleTimeSelect = () => {
    onSelect(selectedPickerTime);
  };

  return (
    <div className={css.root}>
      <div className={css.selectedTimePreviewWrapper}>
        <div className={css.selectedTimePreview}>
          <span className={css.selectedDatePreview}>{date && date.format('DD MMM')}</span>
          <span className={css.selectedTimePill}>
            {selectedPickerTime && selectedPickerTime.tz(DEFAULT_TIMEZONE, true).format('hh:mm a')}
          </span>
        </div>
      </div>
      <div
        className={css.swiperTimePicker}
        key={moment(date)
          .tz(DEFAULT_TIMEZONE)
          .format()}
      >
        <Swiper
          spaceBetween={0}
          slidesPerView={13}
          centeredSlides={true}
          initialSlide={findIndexOfTime(
            timeData,
            isPickupDate ? selectedTimes.startTime : selectedTimes.endTime
          )}
          onInit={() => {
            if (isPickupDate) {
              if (selectedTimes && selectedTimes.startTime) {
                const index = findIndexOfTime(timeData, selectedTimes.startTime);
                getSelectedPickerTime(timeData[index]);
              }
            } else {
              if (selectedTimes && selectedTimes.endTime) {
                const index = findIndexOfTime(timeData, selectedTimes.endTime);
                getSelectedPickerTime(timeData[index]);
              }
            }
          }}
          onSlideChange={e => {
            getSelectedPickerTime(timeData[e.activeIndex]);
            if (isPickupDate) {
              const startTime = timeData[e.activeIndex];
              // setSelectedTimes(prev => ({ ...prev, startTime }));
              setSelectedTimes(prev => {
                const updatedEndTime = moment(startTime)
                  .tz(localTimeZone)
                  .isAfter(prev.endTime);
                if (updatedEndTime) {
                  setSelectedDates(prev => ({ ...prev, endDate: null }));
                  return { startTime, endTime: null };
                } else {
                  return { ...prev, startTime };
                }
              });
            } else {
              const endTime = timeData[e.activeIndex];
              setSelectedTimes(prev => {
                const updatedStartTime = moment(endTime)
                  .tz(localTimeZone)
                  .isBefore(prev.startTime);
                if (updatedStartTime) {
                  setSelectedDates(prev => ({ ...prev, startDate: null }));
                  return { startTime: null, endTime };
                } else {
                  return { ...prev, endTime };
                }
              });
            }
          }}
        >
          {timeData.map(d => {
            return (
              <SwiperSlide>
                <div key={d.valueOf()} className={classNames(css.time)}>
                  <span class={css.timeStick}></span>
                  <span class={css.timeChip}>{d.tz(DEFAULT_TIMEZONE, true).format('ha')}</span>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
      <div className={css.submitFooter}>
        <div className={classNames(css.searchBtn)} onClick={_handleTimeSelect}>
          Save &amp; Continue
        </div>
      </div>
    </div>
  );
};

export default SwipeTimeSelector;
