const HISTORY_BROWSE_KEY = 'HISTORY_BROWSE_KEY';
const CURRENT_USER = 'CURRENT_USER';
const TTL_USER_INFO = 1800000;

const localStorage = typeof window !== 'undefined' ? window.localStorage : {
  getItem: () => {},
  setItem: () => {},
}

export const getHistoryBrowseKeys = () => {
  const item = localStorage.getItem(HISTORY_BROWSE_KEY);
  return JSON.parse(item || '[]');
}

export const settHistoryBrowseKeys = (historyKeys) => {
  if (historyKeys.length > 100) {
    historyKeys.splice(1, 50);
    localStorage.setItem(HISTORY_BROWSE_KEY, JSON.stringify(historyKeys));
  } else {
    localStorage.setItem(HISTORY_BROWSE_KEY, JSON.stringify(historyKeys));
  }
}

export const setCurrentUser = (currentUser) => {
  
  setItemSessionStorage(CURRENT_USER, currentUser);
}

export const getCurrentUser = () => {
  return getItemSessionStorage(CURRENT_USER);
}

export const setUserInformationGTM = (key, userInfo) => {
  const now = new Date();
  const value = {
    value: userInfo,
    expiry: now.getTime() + TTL_USER_INFO,
  };
  setItemSessionStorage(key, value);
}

export const getUserInformationGTM = (key) => {
  const now = new Date();
  const userInfo = getItemSessionStorage(key);
  const { value, expiry } = userInfo || {};
  if ( !value || now.getTime() > expiry) {
    return null;
  }
  return value;
}

export const setItemSessionStorage = (key, value) => {
  if (typeof window !== 'undefined') {
    sessionStorage.setItem(key, JSON.stringify(value));
  }
}

export const getItemSessionStorage = (key) => {
  if (typeof window !== 'undefined') {
    return JSON.parse(sessionStorage.getItem(key));
  }
}